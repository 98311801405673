import { useEditAdminOrganizationMutation } from "@/redux/services/adminOrganizationsApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import type { AddAdminOrganizationPayload } from "@/types/entities/organization"

export const useAdminEditOrganization = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch()
  const [editOrganization, response] = useEditAdminOrganizationMutation()

  const handleEditOrganization = async ({
    id,
    name,
    owner,
    url,
    catchAll,
  }: { id: string } & AddAdminOrganizationPayload) => {
    try {
      const response = await editOrganization({
        id,
        body: {
          name,
          owner,
          url,
          catchAll,
        },
      }).unwrap()
      typeof onSuccess === "function" && onSuccess()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success edit organization",
        }),
      )
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { editOrganization: handleEditOrganization, response }
}
