import { createApi } from "@reduxjs/toolkit/query/react"
import { dreamsenderApi } from "@/core/api/services"
import { buildQueryString } from "@/utils/strings"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import type {
  AddAdminOrganizationPayload,
  AdminOrganization,
  SetOrganizationStatus,
} from "@/types/entities/organization"
import type { PaginationProps } from "./types"

export const adminOrganizationApi = createApi({
  reducerPath: "adminOrganizationApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Admin Organization"],
  endpoints: (builder) => ({
    getAdminOrganizations: builder.query<
      { items: Array<AdminOrganization>; total: number },
      (PaginationProps & { owner?: string[] }) | undefined
    >({
      query: (params) => ({
        method: "GET",
        url: `admin/org/all?${params ? buildQueryString(params) : ""}`,
      }),
      transformResponse: ({
        data,
      }: {
        data: { items: AdminOrganization[]; total: number }
      }) => data,
      providesTags: (result) =>
        result
          ? [
              // Provides a tag for each org in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result.items.map(({ _id }) => ({
                type: "Admin Organization" as const,
                id: _id,
              })),
              { type: "Admin Organization", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Admin Organization", id: "PARTIAL-LIST" }],
    }),
    addAdminOrganization: builder.mutation<
      { data: AdminOrganization },
      AddAdminOrganizationPayload
    >({
      query: (body) => ({
        method: "POST",
        url: "admin/org",
        body: {
          ...body,
          division: "main", // ? have to provide for request to work
        },
      }),
      invalidatesTags: ["Admin Organization"],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled
        if (data) {
          dispatch(dreamsenderApi.util.invalidateTags(["User"]))
        }
      },
    }),
    editAdminOrganization: builder.mutation<
      { data: AdminOrganization },
      {
        id: string
        body: AddAdminOrganizationPayload
      }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `admin/org/${id}`,
        body: {
          ...body,
          division: "main", // ? have to provide for request to work
        },
      }),
    }),
    setAdminOrganizationStatus: builder.mutation<
      { data: AdminOrganization },
      { id: string; body: SetOrganizationStatus }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `admin/org/${id}/status`,
        body,
      }),
      invalidatesTags: ["Admin Organization"],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled
        if (data) {
          dispatch(dreamsenderApi.util.invalidateTags(["User"]))
        }
      },
    }),
    deleteAdminOrganization: builder.mutation<
      { data: AdminOrganization },
      { id: string }
    >({
      query: ({ id }) => ({
        method: "DELETE",
        url: `admin/org/${id}`,
      }),
    }),
  }),
})

export const {
  useGetAdminOrganizationsQuery,
  useAddAdminOrganizationMutation,
  useEditAdminOrganizationMutation,
  useSetAdminOrganizationStatusMutation,
  useDeleteAdminOrganizationMutation,
} = adminOrganizationApi
