import { FC } from "react"
import cx from "classnames"
import { IoIosWarning } from "react-icons/io"
import { twMerge } from "tailwind-merge"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const WarningIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <IoIosWarning
      className={twMerge(cx("w-5 h-5 transition-opacity", className))}
      {...rest}
    />
  )
}

export default WarningIcon
