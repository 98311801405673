import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteMasterListMutation } from "@/redux/services/masterListsApi"
import { setToast } from "@/redux/toastSlice"

export const useDeleteMasterList = () => {
  const dispatch = useAppDispatch()
  const [deleteMasterList, response] = useDeleteMasterListMutation()

  const handleDeleteMasterList = async ({
    params,
    id,
  }: {
    id: string
    params?: string
  }) => {
    try {
      const response = await deleteMasterList({
        id,
        params,
      }).unwrap()
      if (response.data) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success delete master list",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteMasterList: handleDeleteMasterList, response }
}
