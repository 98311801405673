import { FC } from "react"
import { ISequenceEmailVersion } from "@/types/entities/sequence"
import VerticalDivider from "../VerticalDivider"

type Props = {
  version: ISequenceEmailVersion
}

const SequenceEmailVersionView: FC<Props> = ({ version }: Props) => {
  return (
    <div className="flex flex-col gap-5">
      {version.subjects.length > 0 ? (
        <>
          <div className="flex flex-wrap gap-5">
            {version.subjects.map((value, i) => (
              <div key={i} className="basis-56 flex flex-col gap-0.5">
                <span className="caption">Subject #{i + 1}</span>
                <span className="text-sm leading-6">{value}</span>
              </div>
            ))}
          </div>
          <VerticalDivider />
        </>
      ) : null}
      <div className="flex flex-wrap gap-5">
        {version.greetings.map((value, i) => (
          <div key={i} className="basis-56 flex flex-col gap-0.5">
            <span className="caption">Greeting #{i + 1}</span>
            <span className="text-sm leading-6">{value}</span>
          </div>
        ))}
      </div>
      <VerticalDivider />
      <div className="flex flex-col gap-0.5">
        <span className="caption">Email Content</span>
        <div className="text-sm leading-6 whitespace-pre-line">
          {version.body}
        </div>
      </div>
      <VerticalDivider />
      <div className="flex flex-wrap gap-5">
        {version.signoffs.map((value, i) => (
          <div key={i} className="basis-56 flex flex-col gap-0.5">
            <span className="caption">Sign Off #{i + 1}</span>
            <span className="text-sm leading-6">{value}</span>
          </div>
        ))}
      </div>
      {version.unsubs?.length ? (
        <>
          <VerticalDivider />
          <div className="flex flex-wrap gap-5">
            {version.unsubs.map((value, i) => (
              <div key={i} className="basis-56 flex flex-col gap-0.5">
                <span className="caption">Unsub Text #{i + 1}</span>
                <span className="text-sm leading-6">{value}</span>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default SequenceEmailVersionView
