import { useAppDispatch } from "@/redux/hooks"
import { useDownloadSCMutation } from "@/redux/services/searchCriteriaApi"
import { setToast } from "@/redux/toastSlice"
import { useParams } from "react-router-dom"

export const useExportSearchCriteria = () => {
  const dispatch = useAppDispatch()
  const [exportSC, response] = useDownloadSCMutation()
  const { criteriaId = "" } = useParams()

  const handleExport = async () => {
    try {
      await exportSC({ id: criteriaId }).unwrap()
      dispatch(setToast({ show: true, message: "Success", variant: "success" }))
    } catch (e) {
      dispatch(
        setToast({
          show: true,
          message: "Error downloading file",
          variant: "error",
        }),
      )
    }
  }
  return {
    exportSC: handleExport,
    ...response,
  }
}
