import { FC } from "react"
import cx from "classnames"
import { twMerge } from "tailwind-merge"

type Props = {
  className?: string
  title: React.ReactNode
  bodyContent: React.ReactNode
}

const ChartStatCard: FC<Props> = ({
  className,
  title,
  bodyContent,
  ...rest
}: Props) => {
  return (
    <div
      className={twMerge(
        cx(
          "rounded-md border border-outline bg-white font-semibold px-4 py-3 flex flex-col gap-[3px] items-center",
          className,
        ),
      )}
      {...rest}
    >
      <h5 className="text-sm leading-5 opacity-60 uppercase text-center">
        {title}
      </h5>
      <h4 className="text-xl leading-7">{bodyContent}</h4>
    </div>
  )
}

export default ChartStatCard
