import { FC } from "react"
import cx from "classnames"
import { useWindowSize } from "usehooks-ts"
import { Steps, type StepsProps } from "antd"

type Props = {
  className?: string
} & StepsProps

const Stepper: FC<Props> = ({ className, ...rest }: Props) => {
  const { width } = useWindowSize()
  return (
    <Steps
      direction={width < 600 ? "vertical" : "horizontal"}
      progressDot={(_, { status }) => {
        return (
          <div
            className={cx(
              "relative -top-1.5 sm:-left-[34px] -left-1.5 z-10 bg-white flex justify-center items-center h-5 w-5 rounded-full outline outline-2 outline-outline p-0.5",
              {
                "outline-primary": status === "process" || status === "finish",
                " -top-[5px]": status === "process",
              },
            )}
          >
            <div
              className={cx("w-3.5 h-3.5 rounded-full bg-outline", {
                "bg-primary": status === "finish",
              })}
            ></div>
          </div>
        )
      }}
      className={cx(
        "[&_.ant-steps-item-content]:sm:!w-[80px] [&_.ant-steps-item-tail]:sm:!-left-[28px] [&_.ant-steps-item-active]:font-semibold",
        className,
      )}
      {...rest}
    />
  )
}

export default Stepper
