import { useParams } from "react-router-dom"
import { usePublishCampaignMutation } from "@/redux/services/campaignsApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"

export const usePublishCampaign = () => {
  const dispatch = useAppDispatch()
  const [publishCampaign, response] = usePublishCampaignMutation()
  const { campaignId = "" } = useParams()

  const handlePublishCampaign = async () => {
    try {
      const response = await publishCampaign({
        id: campaignId,
      }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success publish campaign",
        }),
      )
      if (response) {
        return response
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { publishCampaign: handlePublishCampaign, response }
}
