import { useMemo } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import dayjs from "dayjs"
import { useGetCampaignsQuery } from "@/redux/services/campaignsApi"
import { useGetReplyClassificationsQuery } from "@/redux/services/generalApi"
import {
  type DashboardExportPayload,
  useExportDashboardMutation,
} from "@/redux/services/organizationApi"
import { DashboardGrouping, findClassificationByName } from "./utils"
import { INDICATOR_VALUES } from "../features/IndicatorDropdown/constants"
import { formatDateToISOString } from "@/utils/dates"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { useDashboardValues } from "./useDashboardValues"
import type { Campaign } from "@/types/entities/campaign"

export const useDashboard = () => {
  const { id: orgId = "" } = useParams()
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()
  const {
    data: { items: campaigns } = { items: [] },
    isLoading: campaignsLoading,
    isError: campaignsError,
  } = useGetCampaignsQuery({
    orgId,
  })
  const {
    data: replyClassifications = [],
    isLoading: classificationsLoading,
    isError: classificationsError,
  } = useGetReplyClassificationsQuery()
  const { isError: valuesError, isLoading: valuesLoading } =
    useDashboardValues()
  const [exportDashboard, { isLoading: exportLoading }] =
    useExportDashboardMutation()

  const grouping = (searchParams.get("group") as DashboardGrouping) ?? "day"
  const multiple = searchParams.get("multiple") ?? "false"
  const sender = searchParams.get("sender") ?? ""
  const emailAddress = searchParams.get("emailAddress") ?? ""
  const sequence = searchParams.get("sequence") ?? ""
  const email = searchParams.get("email") ?? ""
  const version = searchParams.get("version") ?? ""
  const masterList = searchParams.get("masterList") ?? ""
  const from = useMemo(
    () =>
      new Date(
        searchParams.get("from") ?? +dayjs().subtract(9, "day").startOf("date"),
      ),
    [searchParams],
  )
  const to = useMemo(
    () => new Date(searchParams.get("to") ?? +dayjs().endOf("date")),
    [searchParams],
  )

  const selectedClassifications: Partial<Record<string, boolean>> = useMemo(
    () =>
      Array.from(searchParams.entries())
        .filter(([key]) => key.includes("classifications"))
        .map(([, v]) => v)
        .reduce(
          (acc, status) =>
            Object.assign(acc, {
              [status]: true,
            }),
          {},
        ),
    [searchParams],
  )
  const selectedCampaigns: Partial<Record<string, boolean>> = useMemo(() => {
    let campaignValues = Array.from(searchParams.entries())
      .filter(([key]) => key.includes("campaign"))
      .map(([, v]) => v)
    return campaignValues.reduce(
      (acc, status) =>
        Object.assign(acc, {
          [status]: true,
        }),
      {},
    )
  }, [searchParams])
  const handleExport = async (payload: DashboardExportPayload) => {
    try {
      await exportDashboard(payload).unwrap()
      dispatch(setToast({ show: true, message: "Success", variant: "success" }))
    } catch (e) {
      dispatch(
        setToast({
          show: true,
          message: "Error downloading file",
          variant: "error",
        }),
      )
    }
  }
  const handleChangeDate = (type: "from" | "to") => (date: Date | null) => {
    if (!date) {
      return
    }
    const dateString = formatDateToISOString(
      date,
      type === "from" ? "00:00:00.000" : "23:59:59.999",
    )
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set(type, dateString)
      return newParams
    })
  }
  const handleFieldChange = (field: string) => (newValue: string | undefined) =>
    setSearchParams(() => {
      const newParams = new URLSearchParams(window.location.search)
      if (newValue !== undefined) {
        newParams.set(field, newValue)
      } else {
        newParams.delete(field)
      }
      return newParams
    })
  const handleMultipleChange = (newMultiple: boolean) =>
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set("multiple", newMultiple.toString())
      return newParams
    })
  const handleGroupingChange = (newGrouping: string) =>
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set("group", newGrouping)
      return newParams
    })
  const handleCampaignChange =
    (value: string) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const newCampaigns = {
        ...selectedCampaigns,
        [value]: checked,
      }
      const filterCampaigns = Object.entries(newCampaigns).filter(
        ([, value]) => value,
      )

      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        Array.from(newParams.keys()).forEach((key) => {
          if (key.includes("campaign")) {
            newParams.delete(key)
          }
        })
        filterCampaigns.forEach(([key], i) => {
          newParams.set(`campaign[${i}]`, key)
        })
        return newParams
      })
    }
  const campaignsValue = useMemo<{
    label: string
    value: Campaign[] | null
  }>(() => {
    if (!campaigns.length) {
      return { value: null, label: "" }
    }
    const campaignValues = Object.values(selectedCampaigns)
    const campaignKeys = Object.keys(selectedCampaigns)

    if (
      campaignValues.every((v) => !v) ||
      (campaignValues.every((v) => !!v) &&
        campaigns.length === campaignValues.length)
    ) {
      return { label: "All campaigns", value: campaigns }
    }
    return campaignKeys.length === 1
      ? {
          label:
            campaigns.find(({ _id }) => campaignKeys[0] === _id)?.name ?? "",
          value: [campaigns.find(({ _id }) => _id === campaignKeys[0])!],
        }
      : {
          label: `${
            Object.entries(selectedCampaigns).filter(([, v]) => v).length
          } Campaigns selected`,
          value: campaignKeys.map(
            (key) => campaigns.find(({ _id }) => _id === key)!,
          ),
        }
  }, [campaigns, selectedCampaigns])
  const classificationsValue = useMemo(() => {
    if (!replyClassifications.length) {
      return []
    }
    let resultClassificationValues = Object.entries(selectedClassifications)
      .filter(([, v]) => v)
      .map(([key]) => {
        const classification = findClassificationByName(
          replyClassifications,
          key,
        )!
        return {
          value: classification._id,
          label: classification.name,
        }
      })
    return resultClassificationValues
  }, [selectedClassifications, replyClassifications])
  const selectedIndicators: Partial<Record<string, boolean>> = useMemo(() => {
    let indicatorValues = Array.from(searchParams.entries())
      .filter(([key]) => key.includes("indicator"))
      .map(([, v]) => v)
    if (searchParams.get("indicators") === "all") {
      return INDICATOR_VALUES.filter(
        ({ defaultDisplay }) => defaultDisplay,
      ).reduce(
        (acc, { value }) =>
          Object.assign(acc, {
            [value]: true,
          }),
        {},
      )
    }
    return indicatorValues.reduce(
      (acc, indicator) =>
        Object.assign(acc, {
          [indicator]: true,
        }),
      {},
    )
  }, [searchParams])
  const handleIndicatorsChange =
    (value: string) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const newIndicators = {
        ...selectedIndicators,
        [value]: checked,
      }
      const filterIndicators = Object.entries(newIndicators).filter(
        ([, value]) => value,
      )

      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        Array.from(newParams.keys()).forEach((key) => {
          if (key.includes("indicator")) {
            newParams.delete(key)
          }
        })
        filterIndicators.forEach(([key], i) => {
          newParams.set(`indicator[${i}]`, key)
        })
        return newParams
      })
    }
  const indicatorsValue = useMemo<{
    label: string
    value: { value: string; label: string }[] | null
  }>(() => {
    const indicatorValues = Object.values(selectedIndicators)
    const indicatorKeys = Object.keys(selectedIndicators)
    if (indicatorValues.every((v) => !v)) {
      return { label: "No indicators", value: [] }
    }
    if (
      indicatorValues.every((v) => !!v) &&
      INDICATOR_VALUES.length === indicatorValues.length
    ) {
      return { label: "All indicators", value: INDICATOR_VALUES }
    }
    return indicatorKeys.length === 1
      ? {
          label:
            INDICATOR_VALUES.find(({ value }) => indicatorKeys[0] === value)
              ?.label ?? "",
          value: [
            INDICATOR_VALUES.find(({ value }) => value === indicatorKeys[0])!,
          ],
        }
      : {
          label: `${
            Object.entries(selectedIndicators).filter(([, v]) => v).length
          } Indicators selected`,
          value: indicatorKeys.map(
            (key) => INDICATOR_VALUES.find(({ value }) => value === key)!,
          ),
        }
  }, [selectedIndicators])

  return {
    from,
    to,
    sender,
    emailAddress,
    sequence,
    email,
    version,
    masterList,
    campaigns,
    grouping,
    multiple,
    campaignsValue,
    classificationsValue,
    indicatorsValue,
    handleCampaignChange,
    handleChangeDate,
    handleGroupingChange,
    handleIndicatorsChange,
    handleMultipleChange,
    handleFieldChange,
    handleExport,
    selectedCampaigns,
    selectedClassifications,
    selectedIndicators,
    replyClassifications,
    exportLoading,
    isLoading: campaignsLoading || classificationsLoading || valuesLoading,
    isError: campaignsError || classificationsError || valuesError,
  }
}
