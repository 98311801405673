import { FC, useCallback } from "react"
import cx from "classnames"
import { type InputNumberProps, InputNumber as AntInputNumber } from "antd"
import type { valueType } from "antd/es/statistic/utils"

type Props = {
  className?: string
} & InputNumberProps

const InputNumber: FC<Props> = ({
  className,
  maxLength,
  onChange,
  ...rest
}: Props) => {
  const handleChange = useCallback(
    (value: valueType | null) => {
      if (
        maxLength &&
        typeof value === "number" &&
        value.toString().length > maxLength
      ) {
        return
      }
      onChange && onChange(value)
    },
    [onChange, maxLength],
  )
  return (
    <AntInputNumber
      onChange={handleChange}
      className={cx("", className)}
      maxLength={maxLength}
      type="number"
      {...rest}
    />
  )
}

export default InputNumber
