import { FC } from "react"
import cx from "classnames"
import Image1 from "../img/step2-1.png"
import Image2 from "../img/step2-2.png"

type Props = {
  className?: string
}

const InstructionStepTwo: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <div className={cx("", className)} {...rest}>
      <div>
        If you are using Google Workspace for your accounts, you will need to
        enable a few settings in your Google account before you can connect them
        to DreamSender:
      </div>
      <ol className="list-decimal list-inside space-y-2 mt-2">
        <li>
          On your computer, open <u>Gmail.</u>
        </li>
        <li>
          Click on <b>Settings</b> in the top right corner
        </li>
        <li>
          Click on <b>See all settings</b>
        </li>
        <img className="w-52" src={Image1} alt="step2-1" />
        <li>
          Click the <b>"Forwarding and POP/IMAP"</b> tab
        </li>
        <li>
          In the <b>"IMAP Access"</b> section, select <b>"Enable IMAP"</b>.
        </li>
        <img src={Image2} alt="step2-2" />
        <li>
          Click <b>Save Changes</b>
        </li>
      </ol>
    </div>
  )
}

export default InstructionStepTwo
