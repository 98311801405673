import { FC } from "react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { useGetTimezonesQuery } from "@/redux/services/generalApi"

dayjs.extend(utc)
dayjs.extend(timezone)

type Props = {
  date: string
  timezoneAbbreviation: string
  fallbackTimezone?: string
}

const TimezoneDate: FC<Props> = ({
  date,
  timezoneAbbreviation,
  fallbackTimezone = "EST",
}: Props) => {
  const { data: timezones = [], isLoading } = useGetTimezonesQuery()

  if (isLoading) {
    return (
      <span className="loading text-primary loading-spinner w-6 h-6"></span>
    )
  }

  let formattedDate, timezone
  try {
    const timezoneName =
      timezones.find(({ abbr }) => abbr === timezoneAbbreviation)?.name ??
      timezoneAbbreviation
    // Try setting the timezone using the specified identifier
    formattedDate = dayjs.utc(date).tz(timezoneName).format("M/D/YYYY HH:mm")
    timezone = timezoneAbbreviation
  } catch (error) {
    console.error(
      `Invalid timezone: ${timezoneAbbreviation}. Using fallback timezone: ${fallbackTimezone}`,
    )
    // Use the fallback timezone if setting the specified timezone fails
    formattedDate = dayjs
      .utc(date)
      .tz(fallbackTimezone)
      .format("M/D/YYYY HH:mm")
    timezone = fallbackTimezone
  }

  return (
    <div>
      {formattedDate} {timezone}
    </div>
  )
}

export default TimezoneDate
