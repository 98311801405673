import { FC, Fragment, useEffect, useState } from "react"
import OutlineButton from "@/components/OutlineButton"
import { BiPlus as AddIcon } from "react-icons/bi"
import AddEditForwardingGroup from "./AddEditForwardingGroupModal"
import Collapse from "@/components/Collapse"
import TextField from "@/components/TextField"
import VerticalLineIcon from "@/assets/vertical-line.svg"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import { MdCheck as CheckIcon } from "react-icons/md"
import CancelIcon from "@/assets/cancel.svg"
import DeleteForwardingModal from "./DeleteForwardingGroupModal"
import validationSchema from "./validationSchema"
import Loader from "@/components/Loader"
import { findValueByName } from "@/utils/arrays"
import { useGetReplyClassificationsQuery } from "@/redux/services/generalApi"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import { DEFAULT_SENDERS_CLASSIFICATIONS } from "../utils"
import { useGetCampaignQuery } from "@/redux/services/campaignsApi"
import { useParams } from "react-router-dom"
import {
  selectCampaignSetupForwarding,
  selectCampaignSetupSenders,
  setForwarding,
} from "@/redux/campaignSetupSlice"
import type {
  EditCampaignPayload,
  ForwardingGroup,
} from "@/types/entities/campaign"

type Props = {
  onChange: (body: EditCampaignPayload) => void
  onDisabledChange: (disabled: boolean) => void
}

const CampaignForwardingView: FC<Props> = ({
  onChange,
  onDisabledChange,
}: Props) => {
  const dispatch = useAppDispatch()
  const { campaignId = "" } = useParams()

  const {
    data: classificationsResponse = [],
    isLoading: classificationsLoading,
  } = useGetReplyClassificationsQuery()
  const [openAddModal, setOpenAddModal] = useState(false)
  const [editedGroupName, setEditedGroupName] = useState("")
  const [deletedGroupName, setDeletedGroupName] = useState("")
  const { data: campaign } = useGetCampaignQuery(
    { id: campaignId },
    { skip: !campaignId },
  )
  const forwarding = useAppSelector(selectCampaignSetupForwarding)
  const selectedSenders = useAppSelector(selectCampaignSetupSenders)

  useEffect(() => {
    if (
      selectedSenders.length > 0 &&
      campaign?.forwarding &&
      campaign.forwarding.length < 1
    ) {
      dispatch(
        setForwarding([
          {
            name: "Senders",
            emails: selectedSenders.map(({ email }) => email),
            classifications: DEFAULT_SENDERS_CLASSIFICATIONS,
          },
        ]),
      )
    }
  }, [dispatch, selectedSenders, campaign])

  useEffect(() => {
    try {
      validationSchema.validateSync(forwarding)
      onDisabledChange(false)
    } catch (e) {
      onDisabledChange(true)
    }
    return () => {
      onDisabledChange(false)
    }
  }, [onDisabledChange, forwarding])

  useEffect(() => {
    onChange({
      forwarding: forwarding.map(
        ({ classifications, emails, name, ...rest }) => ({
          name,
          emails,
          classifications,
        }),
      ),
    })
  }, [forwarding, onChange])
  const handleAddGroup = (data: Omit<ForwardingGroup, "emails">) => {
    dispatch(setForwarding([{ ...data, emails: [""] }, ...forwarding]))
  }
  const handleEditGroup = (editedData: ForwardingGroup & { oldName: string }) =>
    dispatch(
      setForwarding(
        forwarding.map((v) =>
          v.name === editedData.oldName
            ? {
                ...editedData,
              }
            : v,
        ),
      ),
    )
  const deleteGroup = (group: ForwardingGroup) =>
    dispatch(
      setForwarding(forwarding.filter(({ name }) => name !== group.name)),
    )

  const handleAddEmail = (groupName: string) => () => {
    dispatch(
      setForwarding(
        forwarding.map((v) =>
          groupName === v.name
            ? {
                ...v,
                emails: v.emails.concat(""),
              }
            : v,
        ),
      ),
    )
  }
  const handleEmailChange =
    (groupName: string, emailI: number) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(
        setForwarding(
          forwarding.map((group) =>
            group.name === groupName
              ? {
                  ...group,
                  emails: group.emails.map((emailV, i) =>
                    i === emailI ? value : emailV,
                  ),
                }
              : group,
          ),
        ),
      )
  const deleteEmailField = (groupName: string, emailI: number) => () =>
    dispatch(
      setForwarding(
        forwarding.map((group) =>
          group.name === groupName
            ? {
                ...group,
                emails: group.emails.filter((_, i) => i !== emailI),
              }
            : group,
        ),
      ),
    )

  const existingNames = forwarding.map(({ name }) => name)

  return (
    <>
      <div className="flex flex-col gap-5">
        <h3 className="text-lg leading-7 font-semibold">Forwarding</h3>
        {classificationsLoading ? (
          <div className="flex justify-center translate-y-10">
            <Loader />
          </div>
        ) : (
          <>
            {forwarding.length ? (
              <div className="flex flex-col gap-3">
                {forwarding.map(({ classifications, emails, name }) => (
                  <Collapse
                    defaultOpen={emails.every((v) => !v)}
                    key={name}
                    title={
                      <div className="flex justify-between">
                        <span className="text-sm leading-6 font-semibold">
                          {name}
                        </span>
                        <div className="flex gap-[15px] items-center">
                          <VerticalLineIcon />
                          <EditIcon
                            onClick={() => setEditedGroupName(name)}
                            className="z-10 w-6 hover:text-primary cursor-pointer active:scale-90 h-6 opacity-60"
                          />
                          <VerticalLineIcon />
                          <BinIcon
                            onClick={() => setDeletedGroupName(name)}
                            className="z-10 w-6 hover:text-primary cursor-pointer active:scale-90 h-6 opacity-60"
                          />
                          <VerticalLineIcon />
                        </div>
                      </div>
                    }
                    className="[&_.collapse-content]:border-t [&_.collapse-content]:border-t-outline [&_.collapse-title]:bg-others [&_.collapse-title]:pb-[15px]"
                  >
                    <div className="flex flex-col lg:flex-row gap-5 mt-5">
                      <div className="basis-1/4 space-y-2">
                        <div className="caption opacity-100">Categories</div>
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-col gap-1">
                            {classifications
                              .filter((v) => {
                                const subLength = classificationsResponse.find(
                                  ({ name }) => name === v,
                                )?.sub?.length
                                return (
                                  (subLength && subLength > 0) ||
                                  (!subLength &&
                                    classificationsResponse.find(
                                      ({ name }) => name === v,
                                    ))
                                )
                              })
                              .map((parentName) => (
                                <Fragment key={parentName}>
                                  <span className="flex items-center gap-1">
                                    <CheckIcon className="min-w-[24px] h-6 text-positive" />
                                    <span className="font-semibold text-black-secondary text-sm leading-6">
                                      {
                                        findValueByName(
                                          classificationsResponse,
                                          parentName,
                                        )?.name
                                      }
                                    </span>
                                  </span>
                                  <div className="space-y-1">
                                    {classifications
                                      .filter((value) => {
                                        return classificationsResponse
                                          .find(
                                            ({ name }) => name === parentName,
                                          )
                                          ?.sub?.find((v) => v.name === value)
                                      })
                                      .map((v) => (
                                        <div
                                          key={v}
                                          className="pl-6 space-x-2 leading-6 text-sm"
                                        >
                                          <span>•</span>
                                          <span>
                                            {
                                              findValueByName(
                                                classificationsResponse,
                                                v,
                                              )?.name
                                            }
                                          </span>
                                        </div>
                                      ))}
                                  </div>
                                </Fragment>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="border-l border-l-outline"></div>
                      <div className="basis-3/4 space-y-2">
                        <div className="caption opacity-100">
                          Email Addresses
                        </div>
                        <div className="flex flex-col gap-3">
                          {emails.map((v, emailI) => (
                            <div
                              key={emailI}
                              className="flex gap-3 items-center"
                            >
                              <TextField
                                value={v}
                                onChange={handleEmailChange(name, emailI)}
                                type="email"
                                className="input-sm"
                                placeholder={`Enter email ${emailI + 1}`}
                              />
                              {emailI > 0 && (
                                <CancelIcon
                                  onClick={deleteEmailField(name, emailI)}
                                  className="w-6 h-6 cursor-pointer active:scale-90"
                                />
                              )}
                            </div>
                          ))}
                          <OutlineButton
                            onClick={handleAddEmail(name)}
                            className="btn-base border-none btn-primary"
                          >
                            <AddIcon className="w-6 h-6" />
                            <span>Add Another</span>
                          </OutlineButton>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                ))}
              </div>
            ) : null}
            <OutlineButton
              className="btn-base"
              onClick={() => setOpenAddModal(true)}
            >
              <AddIcon className="w-6 h-6 text-black" />
              <span className="text-black font-medium leading-6">
                Add Forwarding Group
              </span>
            </OutlineButton>
          </>
        )}
      </div>
      <AddEditForwardingGroup
        open={openAddModal}
        onAddSuccess={handleAddGroup}
        onClose={() => setOpenAddModal(false)}
        existingNames={existingNames}
      />
      <AddEditForwardingGroup
        data={forwarding.find(({ name }) => name === editedGroupName)}
        open={Boolean(editedGroupName)}
        onClose={() => setEditedGroupName("")}
        onEditSuccess={handleEditGroup}
        existingNames={existingNames}
      />
      <DeleteForwardingModal
        data={forwarding.find(({ name }) => name === deletedGroupName)}
        open={Boolean(deletedGroupName)}
        onClose={() => setDeletedGroupName("")}
        onDeleteSuccess={deleteGroup}
      />
    </>
  )
}

export default CampaignForwardingView
