import { FC, useMemo, useState } from "react"
import cx from "classnames"
import SearchIcon from "@/assets/search.svg"
import AddIcon from "@/components/icons/AddIcon"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import TextField from "@/components/TextField"
import { usePagination } from "@/hooks/usePagination"
import Button from "@/components/Button"
import EmptyList from "@/components/EmptyList"
import AddEditCookieModal from "./features/AddEditCookieModal"
import DeleteCookieModal from "./features/DeleteCookieModal"
import AdminCookieTable from "./features/AdminCookieTable"
import { useGetAllAdminCookieQuery } from "@/redux/services/adminCookiesApi"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import type { Key } from "antd/es/table/interface"

type Props = {
  className?: string
}

const AdminCookiesPage: FC<Props> = ({ className, ...rest }: Props) => {
  const {
    searchTerm,
    handleSearchTermChange,
    handlePageChange,
    handlePageSizeChange,
    limit,
    skip,
  } = usePagination()
  const [openAddModal, setOpenAddModal] = useState(false)
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([])
  const [openDeleteCookieModal, setOpenDeleteCookieModal] = useState(false)
  const handleClearSelection = () => setSelectedRowsKeys([])
  const handleOpenAdd = () => setOpenAddModal(true)
  const { isLoading: valuesLoading } = useGetSystemValuesQuery()
  const {
    data: { items: cookies, total } = { items: [], total: 0 },
    isLoading: cookiesLoading,
    isFetching,
    isError,
  } = useGetAllAdminCookieQuery({ limit, skip })

  const filteredCookies = useMemo(
    () =>
      searchTerm
        ? cookies.filter(({ name }) =>
            name
              .toLocaleLowerCase()
              .includes(searchTerm.trim().toLocaleLowerCase()),
          )
        : cookies,
    [searchTerm, cookies],
  )
  if (isError) {
    return <ErrorMessage />
  }
  const isLoading = valuesLoading || cookiesLoading
  return (
    <>
      <div className={cx("", className)} {...rest}>
        <div className="flex justify-between items-center flex-wrap sm:flex-nowrap gap-1">
          <h2 className="leading-8 text-2xl font-semibold">Cookies</h2>
          <div className="flex gap-4 items-center">
            {selectedRowsKeys.length ? (
              <div onClick={() => setOpenDeleteCookieModal(true)}>
                <BinIcon className="mr-2 active:scale-90 w-6 h-6 cursor-pointer opacity-60 hover:text-primary hover:opacity-100" />
              </div>
            ) : null}
            <TextField
              value={searchTerm}
              onChange={handleSearchTermChange}
              placeholder="Search cookie"
              startIcon={<SearchIcon className="w-6 h-6" />}
              className="text-sm outline outline-outline input-sm"
              rootClassName="basis-56"
              type="search"
            />
            <Button onClick={handleOpenAdd} className="btn-primary btn-sm">
              <AddIcon className="w-6 h-6" />
              <span className="hidden sm:inline">Add Cookie</span>
            </Button>
          </div>
        </div>
        <div
          className={cx("mt-5 flex px-2 py-1 min-h-[292px]", {
            "justify-center items-center": !cookies.length,
          })}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {cookies.length ? (
                <div className="w-full">
                  <AdminCookieTable
                    cookies={filteredCookies}
                    page={skip}
                    total={total}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    selectedRowKeys={selectedRowsKeys}
                    onRowSelect={setSelectedRowsKeys}
                    loading={isFetching}
                  />
                </div>
              ) : (
                <EmptyList message="There are no cookies yet">
                  <div className="flex gap-3">
                    <Button
                      onClick={handleOpenAdd}
                      className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                    >
                      <AddIcon className="transition-colors text-primary group-hover:text-white" />
                      <span className="transition-colors font-medium text-primary group-hover:text-white">
                        Add Cookie
                      </span>
                    </Button>
                  </div>
                </EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      <AddEditCookieModal
        open={Boolean(openAddModal)}
        onClose={() => setOpenAddModal(false)}
      />
      <DeleteCookieModal
        open={openDeleteCookieModal}
        data={selectedRowsKeys
          .map(String)
          .map((deletedId) => cookies.find(({ _id }) => _id === deletedId)!)
          .filter(Boolean)}
        onClose={() => setOpenDeleteCookieModal(false)}
        onDeleteSuccess={handleClearSelection}
      />
    </>
  )
}

export default AdminCookiesPage
