import { useSetupPasswordMutation } from "@/core/api/services"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import { useAvailableParams } from "./useAvailableParams"
import type { Password } from "@/types/forms/password"

export const useSetupPassword = (onSuccess: () => void) => {
  const dispatch = useAppDispatch()
  const [setupPassword, response] = useSetupPasswordMutation()

  const params = useAvailableParams<"token" | "email">(
    ["email", "token"],
    (missingParams) => {
      dispatch(
        setToast({
          message: `${missingParams.join(", ")} are missing`,
          show: true,
          variant: "error",
        }),
      )
    },
  )

  const handleSetupPassword = async ({ password }: Password) => {
    try {
      await setupPassword({
        email: params.email.trim().replace(" ", "+"),
        password,
        token: params.token,
      }).unwrap()
      onSuccess()
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { setupPassword: handleSetupPassword, response }
}
