import { createApi } from "@reduxjs/toolkit/query/react"
import { setSelectedSequence } from "../campaignSetupSlice"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import { buildQueryString } from "@/utils/strings"
import { addAndEditArrayById } from "@/utils/arrays"
import type { RootState } from "../store"
import type {
  Sequence,
  SequenceAPIResponse,
  SequencePreviewPayload,
  SequenceVariable,
} from "@/types/entities/sequence"
import type { PaginationProps } from "./types"

export const sequencesApi = createApi({
  reducerPath: "sequencesApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Sequences", "Sequence Variables"],
  endpoints: (builder) => ({
    getSequence: builder.query<SequenceAPIResponse, { id: string }>({
      query: ({ id }) => ({
        method: "GET",
        url: `sequence/${id}`,
      }),
      transformResponse: ({ data }: { data: SequenceAPIResponse }) => data,
      providesTags: (result, error, { id }) => [{ type: "Sequences", id }],
    }),
    getSequences: builder.query<
      { items: SequenceAPIResponse[]; total: number },
      { orgId: string } & PaginationProps & { text?: string }
    >({
      query: ({ orgId, ...keys }) => ({
        method: "GET",
        url: `org/${orgId}/sequences?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: SequenceAPIResponse[]; total: number }
      }) => ({
        items,
        total,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.skip !== previousArg?.skip ||
        currentArg?.orgId !== previousArg?.orgId ||
        currentArg?.text !== previousArg?.text,
      merge: (currentCacheData, responseData, { arg: { skip, text } }) => {
        if (!skip) {
          currentCacheData.items = []
          currentCacheData.total = 0
        }
        currentCacheData.items = addAndEditArrayById({
          currentArray: currentCacheData.items,
          newItems: responseData.items,
        })
        currentCacheData.total = responseData.total
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Sequences" as const,
                id: _id,
              })),
              "Sequences",
            ]
          : ["Sequences"],
    }),
    addSequence: builder.mutation<SequenceAPIResponse, Sequence>({
      query: (body) => ({
        method: "POST",
        url: "sequence",
        body,
      }),
      transformResponse: ({ data }: { data: SequenceAPIResponse }) => data,
      onQueryStarted: async (createdSequence, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled

          dispatch(
            sequencesApi.util.updateQueryData(
              "getSequences",
              {
                orgId: createdSequence.orgId,
              },
              (draft) => {
                Object.assign(draft, {
                  items: draft.items.concat(data),
                  total: draft.total + 1,
                })
              },
            ),
          )
        } catch (e) {
          console.error(e)
        }
      },
    }),
    editSequence: builder.mutation<
      Sequence,
      { body: Partial<Omit<Sequence, "orgId">>; id: string; orgId: string }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `sequence/${id}`,
        body,
      }),
      transformResponse: ({ data }: { data: Sequence }) => data,
      onQueryStarted: async ({ id, orgId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedSequence } = await queryFulfilled
          if (updatedSequence) {
            dispatch(
              sequencesApi.util.updateQueryData(
                "getSequences",
                { orgId },
                (draft) => {
                  Object.assign(draft, {
                    items: draft.items.map((sequence) =>
                      sequence._id === id
                        ? {
                            ...updatedSequence,
                          }
                        : sequence,
                    ),
                  })
                },
              ),
            )
          }
        } catch (e) {
          console.error(e)
        }
      },
    }),
    deleteSequence: builder.mutation<
      { success: boolean },
      { id: string; orgId: string }
    >({
      query: ({ id }) => ({
        method: "DELETE",
        url: `sequence/${id}`,
      }),
      onQueryStarted: async ({ id, orgId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled
          if (response.success) {
            dispatch(
              sequencesApi.util.updateQueryData(
                "getSequences",
                { orgId },
                (draft) => {
                  let resultTotal = draft.total - 1
                  if (resultTotal) {
                    resultTotal = 0
                  }
                  Object.assign(draft, {
                    items: draft.items.filter(
                      (sequence) => sequence._id !== id,
                    ),
                    total: resultTotal,
                  })
                },
              ),
            )
          }
        } catch (e) {
          console.error(e)
        }
      },
    }),
    toggleSequenceEmail: builder.mutation<
      { data: boolean },
      {
        id: string
        body: { email: number; version?: number; enabled: boolean }
      }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `sequence/${id}/toggleEmail`,
        body,
      }),
      invalidatesTags: ["Sequences"],
      onQueryStarted: async (
        { body: { enabled, email, version } },
        { dispatch, queryFulfilled, getState },
      ) => {
        const { data } = await queryFulfilled
        if (data.data) {
          const currentSequence = (getState() as RootState).campaignSetup
            .selectedSequence
          if (!currentSequence) {
            return
          }
          dispatch(
            setSelectedSequence({
              ...currentSequence,
              emails: currentSequence.emails.map((v, emailIndex) => {
                return email === emailIndex
                  ? {
                      ...v,
                      enabled: version != null ? v.enabled : enabled,
                      versions:
                        version != null
                          ? v.versions.map((versionValue, versionIndex) =>
                              versionIndex === version
                                ? {
                                    ...versionValue,
                                    enabled,
                                  }
                                : versionValue,
                            )
                          : v.versions,
                    }
                  : {
                      ...v,
                      enabled:
                        !enabled && emailIndex > email ? false : v.enabled,
                    }
              }),
            }),
          )
        }
      },
    }),
    previewSequence: builder.mutation<
      { data: boolean },
      {
        id: string
        body: SequencePreviewPayload
      }
    >({
      query: ({ id, body }) => ({
        method: "POST",
        url: `sequence/${id}/preview`,
        body,
      }),
    }),
    getSequenceVariables: builder.query<SequenceVariable[], void>({
      query: () => ({
        url: `sequence/variables`,
      }),
      transformResponse: ({ data }: { data: SequenceVariable[] }) => data,
      providesTags: ["Sequence Variables"],
    }),
  }),
})

export const {
  useGetSequenceQuery,
  useGetSequencesQuery,
  useAddSequenceMutation,
  useEditSequenceMutation,
  useDeleteSequenceMutation,
  useToggleSequenceEmailMutation,
  usePreviewSequenceMutation,
  useGetSequenceVariablesQuery,
} = sequencesApi
