import { FC } from "react"
import cx from "classnames"
import TextField from "@/components/TextField"
import Textarea from "@/components/Textarea"
import { useSenderForm } from "@/pages/Senders/features/AddEditSenderModal/hooks/useSenderForm"
import { useCountryName } from "@/hooks/useCountryName"
import type { SenderAPIResponse } from "@/types/entities/sender"

type Props = {
  className?: string
  data: SenderAPIResponse
}

const SenderCampaignViewForm: FC<Props> = ({
  className,
  data,
  ...rest
}: Props) => {
  const {
    getFieldProps,
    values: { titles, companies, websites, email, phoneNumber, address },
  } = useSenderForm({ data, onSubmit: () => {} })
  const countryCode = address.countryCode ?? "US"
  const countryName = useCountryName(countryCode || "US")
  return (
    <div className={cx("flex flex-col gap-5", className)} {...rest}>
      <div className="flex flex-col lg:flex-row gap-4 items-end flex-wrap">
        {titles.map((_, i) => (
          <TextField
            key={i}
            readOnly
            topLeftLabel={i === 0 ? "Title" : ""}
            rootClassName="basis-[calc(33.33%-16px)]"
            className="input-sm"
            {...getFieldProps(`titles[${i}]`)}
          />
        ))}
      </div>
      <div className="flex gap-4 flex-col lg:flex-row items-end flex-wrap">
        {companies.map((_, i) => (
          <TextField
            key={i}
            readOnly
            topLeftLabel={i === 0 ? "Company Name" : ""}
            rootClassName="basis-[calc(33.33%-16px)]"
            className="input-sm"
            {...getFieldProps(`companies[${i}]`)}
          />
        ))}
      </div>
      <div className="flex gap-4 flex-col lg:flex-row items-end flex-wrap">
        {websites.map((_, i) => (
          <TextField
            key={i}
            readOnly
            topLeftLabel={i === 0 ? "Website" : ""}
            rootClassName="basis-[calc(33.33%-16px)]"
            className="input-sm"
            {...getFieldProps(`websites[${i}]`)}
          />
        ))}
      </div>
      <TextField
        readOnly
        className="input-sm"
        topLeftLabel="Email Address"
        value={email}
      />
      <TextField
        readOnly
        className="input-sm"
        topLeftLabel="Phone Number"
        value={phoneNumber}
      />
      <TextField
        readOnly
        className="input-sm"
        topLeftLabel="Country"
        value={countryName}
      />
      {(countryCode === "US" || countryCode === "CA") && (
        <>
          <TextField
            readOnly
            className="input-sm"
            topLeftLabel="Street"
            value={address.address1}
          />
          <TextField
            readOnly
            className="input-sm"
            topLeftLabel="City"
            value={address.city}
          />
          <TextField
            readOnly
            className="input-sm"
            topLeftLabel={countryCode === "US" ? "State" : "Province"}
            value={countryCode === "US" ? address.state : address.province}
          />
        </>
      )}
      {countryCode === "US" && (
        <TextField
          readOnly
          className="input-sm"
          topLeftLabel="ZIP code"
          value={address.zipCode}
        />
      )}
      {countryCode === "CA" && (
        <TextField
          readOnly
          className="input-sm"
          topLeftLabel="Postal Code"
          value={address.postalCode}
        />
      )}
      {countryCode === "Other" && (
        <Textarea
          readOnly
          topLeftLabel="Complete Address"
          className="min-h-[140px] resize-none"
          value={address.completeAddress}
        />
      )}
    </div>
  )
}

export default SenderCampaignViewForm
