import { usePreviewSequenceMutation } from "@/redux/services/sequencesApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import type { SequencePreviewPayload } from "@/types/entities/sequence"

export const usePreviewSequence = () => {
  const dispatch = useAppDispatch()
  const [previewSequence, response] = usePreviewSequenceMutation()

  const handlePreviewSequence = async ({
    body,
    id,
  }: {
    body: SequencePreviewPayload
    id: string
  }) => {
    try {
      const response = await previewSequence({
        id,
        body,
      }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success send test sequences",
          }),
        )
      }
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { previewSequence: handlePreviewSequence, response }
}
