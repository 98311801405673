import { FC, useEffect, useMemo, useState } from "react"
import cx from "classnames"
import { twMerge } from "tailwind-merge"
import {
  FaArrowLeftLong as ArrowLeftIcon,
  FaArrowRightLong as ArrowRightIcon,
} from "react-icons/fa6"
import InstructionStepOne from "./steps/1"
import InstructionStepTwo from "./steps/2"
import InstructionStepThree from "./steps/3"
import InstructionStepFour from "./steps/4"

type Props = {
  className?: string
  expanded: boolean
}

const STEP_COUNT = 4

const GoogleLoginInstructions: FC<Props> = ({
  className,
  expanded,
  ...rest
}: Props) => {
  const [step, setStep] = useState(0)

  useEffect(() => {
    setTimeout(() => setStep(0), 300)
  }, [expanded])

  const goPrevStep = () => {
    if (step - 1 >= 0) {
      setStep((prev) => prev - 1)
    }
  }
  const goNextStep = () => {
    if (step + 1 < STEP_COUNT) {
      setStep((prev) => prev + 1)
    }
  }

  const stepMap = useMemo(
    () => [
      {
        title:
          "Connecting Google Workspace/Gsuite accounts to DreamSender via App Password method",
        element: <InstructionStepOne />,
      },
      {
        title: "Enable IMAP Settings",
        element: <InstructionStepTwo />,
      },
      {
        title: "Enable 2-Step verification",
        element: <InstructionStepThree />,
      },
      {
        title: "Creating an App password",
        element: <InstructionStepFour />,
      },
    ],
    [],
  )

  return (
    <div
      className={cx(
        "relative flex flex-col h-full text-[13px] leading-[22px] text-black",
        className,
      )}
      {...rest}
    >
      <div className="overflow-x-hidden flex-1 overflow-y-auto h-[calc(100%+16px)]">
        <h3 className="text-base font-semibold pb-3">{stepMap[step].title}</h3>
        {stepMap[step].element}
      </div>
      <footer className="relative -left-4 w-[calc(100%+32px)] -bottom-4 py-3 border-t border-outline flex justify-center items-center gap-4">
        <ArrowLeftIcon
          className={twMerge(
            cx("w-6 h-6 cursor-pointer active:scale-90", {
              "opacity-40 cursor-default active:scale-100": step === 0,
            }),
          )}
          onClick={goPrevStep}
        />
        <span>
          {step + 1}/{STEP_COUNT}
        </span>
        <ArrowRightIcon
          className={twMerge(
            cx("w-6 h-6 cursor-pointer active:scale-90", {
              "opacity-40 cursor-default active:scale-100":
                step === STEP_COUNT - 1,
            }),
          )}
          onClick={goNextStep}
        />
      </footer>
    </div>
  )
}

export default GoogleLoginInstructions
