import { FC, useEffect } from "react"
import Select from "@/components/Select"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import {
  selectCampaignSetupSequence,
  setSelectedSequence,
} from "@/redux/campaignSetupSlice"
import { useEffectOnce } from "usehooks-ts"
import CampaignMessagingEmailSection from "./CampaignMessagingEmailSection"
import type { SequenceAPIResponse } from "@/types/entities/sequence"
import type { EditCampaignPayload } from "@/types/entities/campaign"

type Props = {
  sequences: SequenceAPIResponse[]
  onChange: (body: EditCampaignPayload) => void
}

const CampaignMessagingView: FC<Props> = ({ sequences, onChange }: Props) => {
  const dispatch = useAppDispatch()
  const selectedSequence = useAppSelector(selectCampaignSetupSequence)
  const handleSequenceSelect = (v: string) => {
    const sequence = sequences.find(({ name }) => name === v)!
    dispatch(setSelectedSequence(sequence))
  }
  useEffect(() => {
    if (selectedSequence) {
      onChange({
        sequenceId: selectedSequence._id,
      })
    }
  }, [onChange, selectedSequence])
  useEffectOnce(() => {
    if (!selectedSequence && sequences.length === 1) {
      handleSequenceSelect(sequences[0].name)
    }
  })

  const value = selectedSequence ? selectedSequence.name : null
  const emails = selectedSequence?.emails

  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-lg leading-7 font-semibold">Messaging</h3>
      <div>
        <Select
          showSearch
          bordered={false}
          placeholder="Select sequence"
          label="Sequence Name"
          containerClassName="[&_.label-text]:text-xs [&_.label-text]:leading-normal"
          options={sequences.map(({ name }) => ({ label: name, value: name }))}
          className="h-10 bg-input rounded-md"
          value={value}
          onChange={handleSequenceSelect}
        />
        {selectedSequence && emails ? (
          <div className="mt-6 flex flex-col gap-5">
            {emails.map((email, emailIndex) => (
              <CampaignMessagingEmailSection
                key={emailIndex}
                email={email}
                emailIndex={emailIndex}
                firstDisabledIndex={emails.findIndex(({ enabled }) => !enabled)}
                sequenceId={selectedSequence._id}
              />
            ))}
          </div>
        ) : (
          <div className="my-10 text-center text-base leading-6 opacity-60">
            No sequence selected.
          </div>
        )}
      </div>
    </div>
  )
}

export default CampaignMessagingView
