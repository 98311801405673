import { FC } from "react"
import Drawer from "@/components/Drawer"
import { prospectsStatusNameMap } from "../../utils/constants"
import { useGetProspectEmailsQuery } from "@/redux/services/prospectsApi"
import Loader from "@/components/Loader"
import EmailMessage from "@/components/EmailMessage"
import { cx } from "@/utils/strings"
import type { DrawerProps } from "antd"
import type { Prospect } from "@/types/entities/prospect"

type Props = {
  data?: Prospect
  blurEmail: boolean
} & DrawerProps

const ProspectsDetailsDrawer: FC<Props> = ({
  data,
  blurEmail,
  open,
  onClose,
}: Props) => {
  const { data: emails = [], isFetching } = useGetProspectEmailsQuery(
    { id: data?._id ?? "" },
    { skip: !data?._id },
  )
  if (!data) {
    return null
  }
  const {
    email,
    createdAt,
    firstName,
    lastName,
    status,
    location,
    title,
    companyName,
    classifications,
    classifiedAt,
    campaign,
  } = data
  return (
    <Drawer
      title={
        <div className="flex gap-4 items-center">
          <h2 className="text-lg font-semibold">Details</h2>
        </div>
      }
      width={1024}
      open={open}
      onClose={onClose}
    >
      <div className="flex flex-col text-sm text-black leading-6 gap-5">
        <div className="flex flex-col">
          <span className="caption">Result</span>
          <span>{prospectsStatusNameMap[status]}</span>
        </div>
        <div className="flex flex-col">
          <span className="caption">First Name</span>
          <span>{firstName}</span>
        </div>
        <div className="flex flex-col">
          <span className="caption">Last Name</span>
          <span>{lastName}</span>
        </div>
        {location && (
          <div className="flex flex-col">
            <span className="caption">Location</span>
            <span>{location}</span>
          </div>
        )}
        {title && (
          <div className="flex flex-col">
            <span className="caption">Title</span>
            <span>{title}</span>
          </div>
        )}
        {companyName && (
          <div className="flex flex-col">
            <span className="caption">Company</span>
            <span>{companyName}</span>
          </div>
        )}
        {blurEmail ? null : (
          <div className="flex flex-col">
            <span className="caption">Email Address</span>
            <span>{email}</span>
          </div>
        )}
        <div className="flex flex-col">
          <span className="caption">First Contacted</span>
          <span>{new Date(createdAt).toLocaleString()}</span>
        </div>
        {classifiedAt && (
          <div className="flex flex-col">
            <span className="caption">Responded</span>
            <span>{new Date(classifiedAt).toLocaleString()}</span>
          </div>
        )}
        {classifications.length > 0 && (
          <div className="flex flex-col">
            <span className="caption">Classification</span>
            <span>{classifications.join(", ")}</span>
          </div>
        )}
        {campaign.name && (
          <div className="flex flex-col">
            <span className="caption">Campaign</span>
            <span>{campaign.name}</span>
          </div>
        )}
        <div className="flex flex-col gap-1.5">
          <span className="caption">Timeline</span>
          {isFetching ? (
            <Loader className="mx-auto" />
          ) : (
            <div
              className={cx({
                "flex flex-col gap-10 border-l border-outline pl-3":
                  emails.length > 0,
                "text-base": !emails.length,
              })}
            >
              {emails.length > 0
                ? emails.map((email, i) => (
                    <EmailMessage
                      key={i}
                      className="relative before:content-[''] before:absolute before:top-0 before:-left-[24.7px] before:rounded-full before:w-6 before:h-6 before:bg-[#ced1d1] before:border-8 before:border-white"
                      contentBoxClassName="flex-col sm:flex-row"
                      {...email}
                    />
                  ))
                : "No emails"}
            </div>
          )}
        </div>
      </div>
    </Drawer>
  )
}

export default ProspectsDetailsDrawer
