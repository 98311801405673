import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useGetCurrentUserQuery } from "@/core/api/services"
import { selectUser, setUser } from "@/redux/authSlice"
import { useAppDispatch } from "@/redux/hooks"

export const useAuth = () => {
  const token = localStorage.getItem("token")
  const userFromStore = useSelector(selectUser)
  const dispatch = useAppDispatch()

  const {
    data: currentUser,
    isFetching,
    isError,
  } = useGetCurrentUserQuery(undefined, {
    skip: !token || !!userFromStore?.token,
  })

  useEffect(() => {
    if (currentUser) {
      dispatch(setUser(currentUser))
    } else if (currentUser === null) {
      dispatch(setUser(null))
    }
  }, [currentUser, dispatch])

  return {
    isLoggedIn: !!userFromStore,
    isLoading: isFetching,
    user: userFromStore,
    isAdmin: userFromStore?.isAdmin,
    token,
    isError,
  }
}
