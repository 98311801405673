import { FC } from "react"
import OAuth2Login from "react-simple-oauth2-login"
import OutlineButton from "../OutlineButton"
import OutlookIcon from "@/assets/outlook.svg"
import Loader from "../Loader"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"

type Props = {
  onSuccess: (data: Record<string, any>) => void
  scope?: string
  extraParams?: Record<string, any>
}

const MicrosoftOAuthLogin: FC<Props> = ({
  onSuccess,
  scope = "offline_access https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.ReadWrite",
  extraParams,
}: Props) => {
  const { data: { MicrosoftClientID } = { MicrosoftClientID: "" }, isLoading } =
    useGetSystemValuesQuery()
  if (isLoading) {
    return <Loader className="mx-auto" />
  }
  if (!MicrosoftClientID) {
    return null
  }
  return (
    <OAuth2Login
      clientId={MicrosoftClientID}
      redirectUri={import.meta.env.VITE_OAUTH_REDIRECT_URL}
      responseType="code"
      authorizationUrl="https://login.microsoftonline.com/common/oauth2/v2.0/authorize"
      scope={scope}
      onSuccess={onSuccess}
      extraParams={{
        access_type: "offline",
        approval_prompt: "force",
        prompt: "consent",
        ...extraParams,
      }}
      onFailure={(response) => console.error(response, "error")}
      render={({ onClick }) => (
        <OutlineButton className="btn-sm" fullWidth onClick={onClick}>
          <OutlookIcon className="w-6 h-6" />
          <span className="text-black font-semibold leading-6">
            Login with Outlook
          </span>
        </OutlineButton>
      )}
    />
  )
}

export default MicrosoftOAuthLogin
