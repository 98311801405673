import { FC } from "react"
import cx from "classnames"
import { Drawer as AntDrawer, DrawerProps } from "antd"
import { AiOutlineClose } from "react-icons/ai"

type Props = {
  className?: string
} & DrawerProps

const Drawer: FC<Props> = ({ className, open, children, ...rest }: Props) => {
  return (
    <AntDrawer
      open={open}
      closeIcon={
        <div className="absolute right-4 top-1/2 -translate-y-1/2 rounded-full bg-bg-default w-7 h-7 flex justify-center items-center">
          <AiOutlineClose className="w-5 h-5" />
        </div>
      }
      placement="right"
      className={cx("", className)}
      {...rest}
    >
      {children}
    </AntDrawer>
  )
}

export default Drawer
