import type { ReplyClassification } from "@/types/entities/reply-classification"

const CATEGORY_REGEX = /^Category (\d+) \(([^)]+)\)/gm

export const getCategoryStrings = (text: string) => {
  return Array.from(text.matchAll(CATEGORY_REGEX)).map(([v]) => v)
}

export function getClassificationName(
  classification: ReplyClassification[],
  targetName: string,
): string | null {
  for (const cls of classification) {
    if (cls.name.toLocaleLowerCase() === targetName) {
      return cls.name // If the target name matches, return the classification's own name
    }

    if (cls.sub) {
      for (const subCls of cls.sub) {
        if (subCls.name.toLocaleLowerCase() === targetName) {
          return subCls.name // If the target name matches a sub-classification, return its own name
        }
      }
    }
  }

  return null // Return null if no matching classification or sub-classification is found
}
