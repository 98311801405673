import { FC, useId, useMemo } from "react"
import cx from "classnames"
import Select from "@/components/Select"
import Checkbox from "@/components/Checkbox"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import type { SelectProps } from "antd"

type Props = {
  className?: string
  onActionToggle: (
    action: { value: string; label: string },
    checked: boolean,
  ) => void
  containerClassName?: string
} & SelectProps<string[]>

const CategoryActionDropdown: FC<Props> = ({
  className,
  value,
  onActionToggle,
  ...rest
}: Props) => {
  const id = useId()
  const {
    data: { classificationActions } = { classificationActions: [] },
    isLoading,
  } = useGetSystemValuesQuery()
  const handleActionChange =
    (action: { label: string; value: string }) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) =>
      onActionToggle(action, checked)

  const actionsValue = useMemo(() => {
    if (value?.every((v: string) => !v)) {
      return null
    }
    return value?.join(", ")
  }, [value])
  return (
    <Select
      optionRender={({ value: itemValue, label }) => (
        <div onClick={(e) => e.stopPropagation()} className="flex items-center">
          <Checkbox
            id={(itemValue as string) + id}
            onChange={handleActionChange({
              value: itemValue as string,
              label: label as string,
            })}
            checked={!!value?.find((v: string) => v === label)}
            className="checkbox-primary h-[18px] w-[18px]"
          />
          <label
            htmlFor={(itemValue as string) + id}
            className="flex-grow pl-2.5 flex gap-2 items-center cursor-pointer"
          >
            <span className="text-sm leading-6">{label}</span>
          </label>
        </div>
      )}
      options={classificationActions.map(({ display, id }) => ({
        label: display,
        value: id,
      }))}
      className={cx(
        "max-w-[224px] bg-input h-10 rounded-md [&_.ant-select-selection-placeholder]:text-black [&_.ant-select-selection-placeholder]:opacity-60",
        className,
      )}
      value={actionsValue}
      popupMatchSelectWidth={false}
      loading={isLoading}
      {...rest}
    />
  )
}

export default CategoryActionDropdown
