import { FC, useMemo } from "react"
import PdfIcon from "../icons/PdfIcon"
import JpgIcon from "../icons/JpgIcon"
import PngIcon from "../icons/PngIcon"
import CsvIcon from "../icons/CsvIcon"
import JsonIcon from "../icons/JsonIcon"
import MSDocIcon from "../icons/MSDocIcon"
import TextFileIcon from "../icons/TextFileIcon"
import FileIcon from "../icons/FileIcon"

type Props = {
  className?: string
  mime: string
}

const MIME_ICON_MAP = {
  "application/pdf": <PdfIcon />,
  "image/jpeg": <JpgIcon />,
  "image/png": <PngIcon />,
  "text/csv": <CsvIcon />,
  "application/json": <JsonIcon />,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": (
    <MSDocIcon />
  ),
  "application/msword": <MSDocIcon />,
  "text/plain": <TextFileIcon />,
}

const MimeTypeIcon: FC<Props> = ({ className, mime, ...rest }: Props) => {
  const element = useMemo<React.ReactNode>(
    () =>
      mime in MIME_ICON_MAP ? (
        MIME_ICON_MAP[mime as keyof typeof MIME_ICON_MAP]
      ) : (
        <FileIcon />
      ),
    [mime],
  )
  return <>{element}</>
}

export default MimeTypeIcon
