import type { SearchCriteriaMasterList } from "@/types/entities/master-list"
import type { SearchCriteria } from "@/types/entities/search-criteria"

export const getMLItemColor = (
  sc: SearchCriteriaMasterList["searchCriteria"],
  currentSC?: SearchCriteria,
) => {
  if (!sc || currentSC?.masterList.searchCriteria?._id === sc._id) {
    return "var(--positive)"
  }
  if (sc.status === "active") {
    return "var(--error)"
  }
  return "var(--warning)"
}
