import Button from "@/components/Button"
import EmailSentIcon from "@/assets/email-sent.svg"
import { useConfirmEmail } from "./useConfirmEmail"
import { useAvailableParams } from "@/hooks/useAvailableParams"

const ConfirmEmailPage = () => {
  const { email, token } = useAvailableParams(["email", "token"])
  const { confirmEmail } = useConfirmEmail()

  const handleConfirmEmail = () => {
    confirmEmail({ email, token })
  }

  return (
    <div className="flex flex-col gap-6 max-w-lg mx-auto mt-24">
      <div className="flex flex-col gap-5 items-center">
        <EmailSentIcon className="w-20 h-20" />
        <h4 className="font-semibold text-xl sm:text-2xl text-center">
          Confirm Email
        </h4>
        <div className="text-sm mt-2.5 mb-3 text-center">
          Press the next button to confirm your new email
        </div>
      </div>
      <Button onClick={handleConfirmEmail} fullWidth>
        Confirm Email
      </Button>
    </div>
  )
}

export default ConfirmEmailPage
