import { FC, useMemo, useState } from "react"
import cx from "classnames"
import Table from "@/components/Table"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import AddEditGsuiteModal from "../AddEditGsuiteModal"
import EmailPlatformColumnValue from "@/components/EmailPlatformColumnValue"
import DeleteGsuiteModal from "../DeleteGsuiteModal"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { DATE_FORMAT_OPTIONS } from "@/constants"
import type {
  EmailPlatform,
  GsuiteAccount,
} from "@/types/entities/gsuite-account"
import type { TablePaginationProps } from "@/redux/services/types"
import type { ColumnsType } from "antd/es/table"
import type { Key } from "antd/es/table/interface"

type Props = {
  className?: string
  gsuites: GsuiteAccount[]
  onRowSelect: (keys: Key[]) => void
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  displayName: string
  email: string
  domains: number
  maxDomains: number
  updatedAt: string
  provider: EmailPlatform
}

const GsuiteAccountsTable: FC<Props> = ({
  className,
  gsuites,
  onRowSelect,
  ...rest
}: Props) => {
  const { sm } = useBreakpoints()
  const [editedGsuteId, setEditedGsuiteId] = useState("")
  const [deletedGsuiteId, setDeletedGsuiteId] = useState("")

  const dataSource = useMemo<TableDataType[]>(
    () =>
      gsuites.map(
        ({
          _id,
          displayName,
          email,
          domains,
          maxDomains,
          updatedAt,
          provider,
        }) => ({
          key: _id,
          updatedAt: new Date(updatedAt).toLocaleDateString(
            "en-US",
            DATE_FORMAT_OPTIONS,
          ),
          displayName,
          email,
          domains,
          maxDomains,
          provider,
        }),
      ),
    [gsuites],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(
    () => [
      {
        title: "Account Name",
        key: "displayName",
        dataIndex: "displayName",
        sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      },
      {
        title: "Email Address",
        key: "email",
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        render: (value, { provider }) => (
          <EmailPlatformColumnValue provider={provider} value={value} />
        ),
      },
      {
        title: "Accounts",
        key: "domains",
        dataIndex: "domains",
        sorter: (a, b) => a.domains - b.domains,
      },
      {
        title: "Max Accounts",
        key: "maxDomains",
        dataIndex: "maxDomains",
        sorter: (a, b) => a.maxDomains - b.maxDomains,
      },
      {
        title: "Last Edited",
        key: "updatedAt",
        dataIndex: "updatedAt",
        sorter: (a, b) =>
          Number(new Date(a.updatedAt)) - Number(new Date(b.updatedAt)),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        className: "min-w-[96px]",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            <EditIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setEditedGsuiteId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer active:scale-90 hover:text-primary transition-colors"
            />
            <BinIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setDeletedGsuiteId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer active:scale-90 hover:text-primary transition-colors"
            />
          </div>
        ),
        width: 96,
      },
    ],
    [sm],
  )
  return (
    <>
      <Table
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys) => onRowSelect(selectedRowKeys),
        }}
        dataSource={dataSource}
        columns={columns}
        className={cx("", className)}
        showSizeChanger
        {...rest}
      />
      <AddEditGsuiteModal
        data={gsuites.find(({ _id }) => _id === editedGsuteId)}
        open={!!editedGsuteId}
        onClose={() => setEditedGsuiteId("")}
      />
      <DeleteGsuiteModal
        data={
          deletedGsuiteId
            ? [gsuites.find(({ _id }) => _id === deletedGsuiteId)!]
            : []
        }
        open={!!deletedGsuiteId}
        onClose={() => setDeletedGsuiteId("")}
      />
    </>
  )
}

export default GsuiteAccountsTable
