import { useCallback } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import {
  CampaignSetupState,
  resetCampaign,
  selectCampaignSetupMasterList,
  setCampaignSetupName,
  setEndDate,
  setInitialState,
  setInitialStateName,
  setSchedule,
  setSelectedMasterList,
  setSettings,
  setStartDate,
  setStatus,
  setTimeZone,
  setUseEndDate,
} from "@/redux/campaignSetupSlice"
import { formatDateToISOString } from "@/utils/dates"
import { useGetCampaignsMLQuery } from "@/redux/services/masterListsApi"
import { usePublishCampaign } from "./usePublishCampaign"
import { useEditCampaign } from "./useEditCampaign"
import { useUpdateCampaignStatus } from "./useUpdateCampaignStatus"
import type {
  CampaignStatus,
  EditCampaignPayload,
} from "@/types/entities/campaign"

export const useCampaignSetup = () => {
  const dispatch = useAppDispatch()
  const selectedMasterLists = useAppSelector(selectCampaignSetupMasterList)
  const { campaignId = "" } = useParams()
  const {
    publishCampaign,
    response: { isSuccess: publishSuccess },
  } = usePublishCampaign()
  const {
    editCampaign,
    response: { isSuccess: editSuccess },
  } = useEditCampaign()
  const { updateCampaignStatus } = useUpdateCampaignStatus()
  const { data: masterLists = [] } = useGetCampaignsMLQuery(
    { id: campaignId },
    { skip: !campaignId },
  )

  const setupCampaignName = (name: string) => {
    dispatch(setCampaignSetupName(name))
  }
  const resetSetupValues = useCallback(() => {
    dispatch(resetCampaign())
  }, [dispatch])
  const changeUseEndDate = (checked: boolean) => {
    dispatch(setUseEndDate(checked))
  }
  const changeDate = (type: "start" | "end", date: Date) => {
    const dateString = formatDateToISOString(
      date,
      type === "start" ? "00:00:00.000" : "23:59:59.999",
    )

    dispatch(
      type === "start"
        ? setStartDate(new Date(`${dateString}`))
        : setEndDate(new Date(`${dateString}`)),
    )
  }
  const changeDailyLimits = (settings: CampaignSetupState["settings"]) => {
    dispatch(setSettings(settings))
  }
  const changeSchedule = (schedule: CampaignSetupState["schedule"]) => {
    dispatch(setSchedule(schedule))
  }
  const changeTimezone = (timezone: string) => {
    dispatch(setTimeZone(timezone))
  }
  const changeStatus = (status: CampaignStatus) => {
    dispatch(
      setStatus({
        value: status,
        changed: true,
      }),
    )
  }
  const addMasterListRow = () => {
    dispatch(setSelectedMasterList(selectedMasterLists.concat(null)))
  }
  const changeSelectedMasterLists = async (
    selectedIndex: number,
    masterListId: string,
  ) => {
    dispatch(
      setSelectedMasterList(
        selectedMasterLists.map((v, i) =>
          i === selectedIndex
            ? masterLists.find((mList) => mList?._id === masterListId)!
            : v,
        ),
      ),
    )
  }
  const removeListById = async (removedId: string) => {
    dispatch(
      setSelectedMasterList(
        selectedMasterLists.filter((v) => (v ? v._id !== removedId : true)),
      ),
    )
  }
  const removeListByIndex = (removedIndex: number) => {
    dispatch(
      setSelectedMasterList(
        selectedMasterLists.filter((_, i) => removedIndex !== i),
      ),
    )
  }
  const handlePublishCampaign = async () => {
    const result = await publishCampaign()
    if (result) {
      dispatch(resetCampaign())
    }
    return result
  }
  const handleSaveCampaign = async (
    body: EditCampaignPayload,
    showToast = true,
  ) => {
    const result = await editCampaign(
      {
        id: campaignId,
        body,
      },
      showToast,
    )
    return result
  }
  const handleUpdateCampaignStatus = async (status: CampaignStatus) => {
    await updateCampaignStatus({ id: campaignId, body: { status } })
  }
  const changeInitialState = useCallback(
    (payload: Omit<CampaignSetupState, "initialState">) => {
      dispatch(setInitialState(payload))
    },
    [dispatch],
  )
  const changeInitialStateName = useCallback(
    (payload: string) => dispatch(setInitialStateName(payload)),
    [dispatch],
  )

  return {
    setupCampaignName,
    changeUseEndDate,
    changeDate,
    changeDailyLimits,
    changeSchedule,
    addMasterListRow,
    changeSelectedMasterLists,
    removeListById,
    removeListByIndex,
    handlePublishCampaign,
    handleSaveCampaign,
    handleUpdateCampaignStatus,
    changeTimezone,
    changeStatus,
    resetSetupValues,
    changeInitialState,
    changeInitialStateName,
    editSuccess,
    publishSuccess,
  }
}
