import { FC } from "react"
import cx from "classnames"
import { RiDeleteBin6Line } from "react-icons/ri"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const DeleteIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <RiDeleteBin6Line
      className={cx("w-5 h-5 active:scale-90", className)}
      {...rest}
    />
  )
}

export default DeleteIcon
