import { createApi } from "@reduxjs/toolkit/query/react"
import { buildQueryString } from "@/utils/strings"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import type { PaginationProps } from "./types"
import type {
  AdminCookie,
  AddAdminCookiePayload,
  EditAdminCookiePayload,
} from "@/types/entities/cookie"

export const adminCookiesApi = createApi({
  reducerPath: "adminCookiesApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Admin Cookies"],
  endpoints: (builder) => ({
    getAllAdminCookie: builder.query<
      { items: AdminCookie[]; total: number },
      PaginationProps
    >({
      query: ({ ...keys }) => ({
        method: "GET",
        url: `admin/linkedInCookies/all?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: AdminCookie[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Admin Cookies" as const,
                _id,
              })),
              { type: "Admin Cookies", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Admin Cookies", id: "PARTIAL-LIST" }],
    }),
    getAdminCookie: builder.query<AdminCookie, { id: string }>({
      query: ({ id }) => ({
        method: "GET",
        url: `admin/linkedInCookies/${id}`,
      }),
      transformResponse: ({ data }: { data: AdminCookie }) => data,
      providesTags: ["Admin Cookies"],
    }),
    addAdminCookie: builder.mutation<AdminCookie, AddAdminCookiePayload>({
      query: (body) => ({
        method: "POST",
        url: "admin/linkedInCookies",
        body,
      }),
      invalidatesTags: ["Admin Cookies"],
      transformResponse: ({ data }: { data: AdminCookie }) => data,
    }),
    editAdminCookie: builder.mutation<
      AdminCookie,
      { id: string; body: EditAdminCookiePayload }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `admin/linkedInCookies/${id}`,
        body,
      }),
      invalidatesTags: ["Admin Cookies"],
      transformResponse: ({ data }: { data: AdminCookie }) => data,
    }),
    deleteAdminCookie: builder.mutation<{ data: boolean }, string[]>({
      query: (id) => ({
        method: "DELETE",
        url: `admin/linkedInCookies?${buildQueryString({ id })}`,
      }),
      invalidatesTags: ["Admin Cookies"],
    }),
  }),
})

export const {
  useGetAdminCookieQuery,
  useGetAllAdminCookieQuery,
  useAddAdminCookieMutation,
  useEditAdminCookieMutation,
  useDeleteAdminCookieMutation,
} = adminCookiesApi
