import { createContext } from "react"

type Props = {
  title: string
  submitBtnText: string
}

export const SpamAccountLoginContext = createContext<Props>({
  title: "",
  submitBtnText: "",
})
