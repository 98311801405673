import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useConnectEmailOAuthMutation } from "@/redux/services/sendersApi"
import { setToast } from "@/redux/toastSlice"
import type { ConnectEmailOAuthPayload } from "@/types/entities/email-address"

export const useConnectEmailOAuth = () => {
  const dispatch = useAppDispatch()
  const [connectEmailAuth, response] = useConnectEmailOAuthMutation()

  const handleConnectEmailAuth = async ({
    body,
    id,
  }: {
    body: ConnectEmailOAuthPayload
    id: string
  }) => {
    try {
      const response = await connectEmailAuth({ body, id }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success connect email address",
          }),
        )
      }
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { connectEmailAuth: handleConnectEmailAuth, response }
}
