import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteAdminDNCMutation } from "@/redux/services/adminDncApi"
import { setToast } from "@/redux/toastSlice"

export const useAdminDeleteDNC = () => {
  const dispatch = useAppDispatch()
  const [deleteAdminDNC, response] = useDeleteAdminDNCMutation()

  const handleAdminDeleteDNC = async (records: string[]) => {
    try {
      const response = await deleteAdminDNC(records).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: `Success delete ${
            records.length > 1 ? "accounts" : "account"
          }`,
        }),
      )
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { deleteAdminDNC: handleAdminDeleteDNC, response }
}
