import React, { forwardRef, useId } from "react"
import cx from "classnames"

type Props = {
  title: React.ReactNode
  children: React.ReactNode
  className?: string
  defaultOpen?: boolean
} & Omit<React.HTMLAttributes<HTMLDivElement>, "title">

export const COLLAPSE_TITLE_X_PADDING = 68

const Collapse = forwardRef<HTMLDivElement, Props>(
  ({ title, children, className, defaultOpen, ...rest }, ref) => {
    const name = useId()
    return (
      <div
        ref={ref}
        className={cx(
          "collapse collapse-arrow rounded-md border border-outline",
          className,
        )}
        {...rest}
      >
        <input
          defaultChecked={defaultOpen}
          className="z-10"
          type="checkbox"
          name={name}
        />
        <div className="collapse-title p-5 text-sm font-semibold pr-12">
          {title}
        </div>
        <div className="collapse-content">{children}</div>
      </div>
    )
  },
)

export default Collapse
