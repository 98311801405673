import React from "react"
import cx from "classnames"
import { AiOutlineUser } from "react-icons/ai"

type Props = {
  className?: string
  src?: string | null
  alt?: string
  children?: React.ReactNode
}

const Avatar = ({ className, src, alt = "Avatar", children }: Props) => {
  return (
    <div
      className={cx(
        "w-8 h-8 rounded-full overflow-hidden flex justify-center items-center",
        className,
        {
          "bg-outline": !src,
        },
      )}
    >
      {src || !children ? (
        <>
          {src ? (
            <img className="h-full" src={src} alt={alt} />
          ) : (
            <AiOutlineUser className="w-5 h-5" />
          )}
        </>
      ) : (
        <div className="w-full h-full bg-outline flex justify-center items-center">
          {children}
        </div>
      )}
    </div>
  )
}

export default Avatar
