import { Link } from "react-router-dom"
import { useFormik } from "formik"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import { Routes } from "@/core/routing/routes"
import validationSchema from "./validation-schema"
import { useForgotPassword } from "./useForgotPassword"

const ForgotPasswordForm = () => {
  const { resetPassword, response } = useForgotPassword()
  const {
    handleSubmit,
    getFieldProps,
    errors: { email: emailError },
    touched: { email: emailTouched },
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: ({ email }) => {
      resetPassword({ email })
    },
  })
  return (
    <div className="flex flex-col gap-12 w-full">
      <form className="flex flex-col gap-5 w-full" onSubmit={handleSubmit}>
        <TextField
          type="email"
          placeholder="Enter your email address"
          topLeftLabel="Email Address"
          bottomLeftLabel={
            <FormFieldErrorMessage error={emailError} touched={emailTouched} />
          }
          {...getFieldProps("email")}
        />
        <Button
          fullWidth
          disabled={response.isLoading}
          className="mt-1"
          type="submit"
        >
          Reset My Password
        </Button>
      </form>
      <Link
        className="text-primary text-center text-base font-semibold"
        to={Routes.login}
      >
        Back to Sign In
      </Link>
    </div>
  )
}

export default ForgotPasswordForm
