import { FC, MouseEvent, ReactNode } from "react"
import cx from "classnames"
import CancelIcon from "@/assets/cancel.svg"

type Props = {
  className?: string
  children: ReactNode
  onDelete?: (v: any) => void
  dataTagIndex?: number | string
}

const Chip: FC<Props> = ({
  className,
  children,
  onDelete,
  dataTagIndex,
  ...rest
}: Props) => {
  const handleDelete = (e: MouseEvent<SVGElement>) =>
    onDelete!(e.currentTarget.parentElement?.dataset.tagIndex)

  return (
    <div
      className={cx(
        "py-[3px] px-2.5 rounded-2xl bg-semi border-outline-dark border leading-4 text-xs inline-block whitespace-nowrap",
        className,
      )}
      data-tag-index={dataTagIndex}
      {...rest}
    >
      {children}
      {onDelete && (
        <CancelIcon
          onClick={handleDelete}
          className="active:scale-90 inline ml-1.5 cursor-pointer w-4 h-4"
        />
      )}
    </div>
  )
}

export default Chip
