import { FC } from "react"
import cx from "classnames"
import { useFormik } from "formik"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import TextField from "@/components/TextField"
import Button from "@/components/Button"
import validationSchema from "./validation-schema"
import { useAdminAddDNC } from "../../hooks/useAdminAddDNC"
import { useAdminEditDNC } from "../../hooks/useAdminEditDNC"
import type { AdminDNC } from "@/types/entities/dnc"

type Props = {
  className?: string
  data?: AdminDNC
} & ModalProps

const AddEditDNCModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const isEdit = !!data
  const {
    addAdminDNC,
    response: { isLoading: addLoading },
  } = useAdminAddDNC()
  const {
    editAdminDNC,
    response: { isLoading: editLoading },
  } = useAdminEditDNC()
  const { getFieldProps, handleSubmit, resetForm, isValid, dirty } = useFormik({
    enableReinitialize: true,
    initialValues: data ?? {
      value: "",
    },
    validationSchema,
    onSubmit: async ({ value }) => {
      try {
        isEdit
          ? await editAdminDNC({ id: data._id, value })
          : await addAdminDNC({ records: [value] })
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  const submitDisabled = addLoading || editLoading || !(isValid && dirty)
  const submitBtnText = isEdit ? "Save" : "Add"
  const title = isEdit ? "Edit DNC Contact" : "Add DNC Contact"

  return (
    <Modal
      title={title}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            type="button"
            className="btn-sm text-black min-w-[86px]"
            onClick={handleClose}
            disabled={addLoading || editLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={submitDisabled}
            type="submit"
            className="btn-sm min-w-[86px]"
          >
            {submitBtnText}
          </Button>
        </div>
      }
      {...rest}
    >
      <TextField
        topLeftLabel="Email Address or Domain"
        placeholder="example@company.com or company.com"
        className="input-sm"
        type="email"
        {...getFieldProps("value")}
      />
    </Modal>
  )
}

export default AddEditDNCModal
