import { FC } from "react"
import cx from "classnames"
import Loader from "@/components/Loader"
import IconBox from "@/components/IconBox"
import CopyIcon from "@/components/icons/CopyIcon"
import { IoMdHelpCircleOutline as HelpIcon } from "react-icons/io"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import { useManualCopy } from "@/hooks/useManualCopy"
import { replaceLettersForX } from "@/utils/strings"

type Props = {
  className?: string
  onHelpClick: () => void
}

const CopyAppIdView: FC<Props> = ({
  className,
  onHelpClick,
  ...rest
}: Props) => {
  const {
    data: { GSuiteAppID, GSuiteAppIDSender } = {
      GSuiteAppID: "",
      GSuiteAppIDSender: "",
    },
    isLoading,
  } = useGetSystemValuesQuery()
  const [, copy] = useManualCopy({
    successMessage: "App ID copied successfully",
  })
  const handleCopy =
    (text: string) => (_: React.MouseEvent<HTMLOrSVGElement>) =>
      copy(text)

  return (
    <div
      className={cx(
        "text-sm leading-6",
        {
          "flex justify-center": isLoading,
        },
        className,
      )}
      {...rest}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <ol className="list-decimal list-inside space-y-5">
          <li className="space-y-2">
            <span>Copy App IDs</span>
            <IconBox
              text={replaceLettersForX(GSuiteAppID)}
              icon={
                <CopyIcon
                  onClick={handleCopy(GSuiteAppID)}
                  className="opacity-60 w-6 h-6 self-center cursor-pointer"
                />
              }
              labelText="Admin Accounts"
            />
            <IconBox
              text={replaceLettersForX(GSuiteAppIDSender)}
              icon={
                <CopyIcon
                  onClick={handleCopy(GSuiteAppIDSender)}
                  className="opacity-60 w-6 h-6 self-center cursor-pointer"
                />
              }
              labelText="Senders"
            />
          </li>
          <li>
            Go to Admin console (Security/API Controls/App Access Control) and
            add app ID to account
            <HelpIcon
              className="inline-block ml-1 w-6 h-6 cursor-pointer active:scale-90 text-black-secondary"
              onClick={onHelpClick}
            />
          </li>
        </ol>
      )}
    </div>
  )
}

export default CopyAppIdView
