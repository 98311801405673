import { FC, useMemo } from "react"
import cx from "classnames"
import Table from "@/components/Table"
import DashboardIndicatorsList from "../DashboardIndicatorsList"
import ChartStatCard from "../ChartStatCard"
import BarChart from "@/components/BarChart"
import type {
  ReportingEmailTotals,
  ReportingRepliesTotals,
} from "@/types/entities/organization"

type Props = {
  className?: string
  indicators: string[]
}

const EmptyGraphView: FC<Props> = ({
  className,
  indicators,
  ...rest
}: Props) => {
  const columns = useMemo(
    () => [
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
      },
      {
        title: "Prospects",
        key: "prospects",
        dataIndex: "prospects",
      },
      {
        title: "Follow Ups",
        key: "followUps",
        dataIndex: "followUps",
      },
      {
        title: "Replies",
        key: "replies",
        dataIndex: "replies",
      },
      {
        title: "Leads",
        key: "leads",
        dataIndex: "leads",
      },
      {
        title: "Total Emails",
        key: "totalEmails",
        dataIndex: "totalEmails",
      },
    ],
    [],
  )
  return (
    <div className="space-y-4">
      <div
        className={cx(
          "rounded-md bg-white shadow-[0px_1px_4px_0px_rgba(0,0,0,0.08)]",
          className,
        )}
        {...rest}
      >
        <div className="space-y-4">
          <DashboardIndicatorsList
            totalData={{} as ReportingEmailTotals}
            totalReplyData={{} as ReportingRepliesTotals}
            indicators={indicators}
          />
          <div className="py-4 space-y-5">
            <div className="px-4 flex gap-4 flex-wrap [&>*]:flex-1">
              <ChartStatCard title="PROSPECTS" bodyContent={0} />
              <ChartStatCard title="FOLLOW UPS" bodyContent={0} />
              <ChartStatCard title="TOTAL EMAILS" bodyContent={0} />
              <ChartStatCard title="REPLIES" bodyContent={0} />
              <ChartStatCard
                title="INTERESTED LEADS"
                className="bg-bg-default"
                bodyContent={<span className="text-primary">{0}</span>}
              />
            </div>
            <div className="w-full">
              <BarChart
                height={307}
                data={{ datasets: [], labels: [] }}
                options={{
                  layout: {
                    padding: {
                      left: 16,
                      right: 16,
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="z-0 relative bg-white px-2 py-1 rounded-md block-shadow">
        <Table dataSource={[]} columns={columns} />
      </div>
    </div>
  )
}

export default EmptyGraphView
