import { useParams } from "react-router-dom"
import {
  resetDomain,
  selectDomainSetupEmailProvider,
  selectDomainSetupName,
  selectDomainSetupProvider,
  selectDomainSetupUrlRedirect,
  setDKIM,
  setEmailProvider,
  setMissing,
  setName,
  setProvider,
  setUrlRedirect,
} from "@/redux/domainSetupSlice"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import {
  useAddDomainMutation,
  useSetupDomainMutation,
} from "@/redux/services/domainsApi"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import { useCallback } from "react"
import type { DomainMissing } from "@/types/entities/domain"

export const useDomainSetup = () => {
  const dispatch = useAppDispatch()
  const { id: orgId = "" } = useParams()
  const [addDomainMutation, addDomainResponse] = useAddDomainMutation()
  const [setupDomainMutation, setupDomainResponse] = useSetupDomainMutation()

  const name = useAppSelector(selectDomainSetupName)
  const urlRedirect = useAppSelector(selectDomainSetupUrlRedirect)
  const provider = useAppSelector(selectDomainSetupProvider)
  const emailProvider = useAppSelector(selectDomainSetupEmailProvider)

  const changeName = (v: string) => dispatch(setName(v))
  const changeUrlRedirect = (v: string) => dispatch(setUrlRedirect(v))
  const changeProvider = (v: string) => dispatch(setProvider(v))
  const changeEmailProvider = (v: string) => dispatch(setEmailProvider(v))
  const changeDKIM = (v: string) => dispatch(setDKIM(v))
  const changeMissing = (v: DomainMissing[]) => dispatch(setMissing(v))

  const resetState = useCallback(() => dispatch(resetDomain()), [dispatch])

  const createDomain = async () => {
    try {
      const result = await addDomainMutation({
        name,
        orgId,
        provider,
        urlRedirect,
      }).unwrap()
      return result
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      return null
    }
  }
  const setupDomain = async (domainId: string) => {
    try {
      const result = await setupDomainMutation({
        body: { emailProvider },
        id: domainId,
      }).unwrap()
      return result
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }
  const setupLoading =
    addDomainResponse.isLoading || setupDomainResponse.isLoading
  const setupError = addDomainResponse.isError || setupDomainResponse.isError

  return {
    changeName,
    changeUrlRedirect,
    changeProvider,
    changeEmailProvider,
    changeDKIM,
    changeMissing,
    createDomain,
    setupDomain,
    resetState,
    setupLoading,
    setupError,
    setupDomainResponse,
  }
}
