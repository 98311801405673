import { FC, useState } from "react"
import cx from "classnames"
import SearchIcon from "@/assets/search.svg"
import AddIcon from "@/components/icons/AddIcon"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineUploadFile as UploadFileIcon } from "react-icons/md"
import TextField from "@/components/TextField"
import { usePagination } from "@/hooks/usePagination"
import Button from "@/components/Button"
import EmptyList from "@/components/EmptyList"
import OutlineButton from "@/components/OutlineButton"
import AddEditDNCModal from "./features/AddEditDNCModal"
import ImportDNCModal from "./features/ImportDNCModal"
import { useGetAdminDNCQuery } from "@/redux/services/adminDncApi"
import { useExportAdminDNC } from "./hooks/useAdminExportDNC"
import AdminDNCTable from "./features/AdminDNCTable"
import DeleteDNCModal from "./features/DeleteDNCModal"
import ErrorMessage from "@/components/ErrorMessage"
import DownloadIcon from "@/components/icons/DownloadIcon"
import Loader from "@/components/Loader"
import { POLLING_INTERVAL } from "@/constants"
import type { Key } from "antd/es/table/interface"

type Props = {
  className?: string
}

const AdminDNCPage: FC<Props> = ({ className, ...rest }: Props) => {
  const {
    limit,
    searchTerm,
    text,
    skip,
    handleSearchTermChange,
    handlePageChange,
    handlePageSizeChange,
  } = usePagination()
  const {
    data: { items: dncs, total } = { items: [], total: 0 },
    isLoading,
    isFetching,
    isError,
  } = useGetAdminDNCQuery(
    { limit, skip, text },
    { pollingInterval: POLLING_INTERVAL },
  )
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([])
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openImportModal, setOpenImportModal] = useState(false)
  const [openDeleteDNCModal, setOpenDeleteDNCModal] = useState(false)
  const { exportAdminDnc, isLoading: exportLoading } = useExportAdminDNC()
  const handleOpenAddModal = () => setOpenAddModal(true)
  const handleOpenImportModal = () => setOpenImportModal(true)
  const handleClearSelection = () => setSelectedRowsKeys([])

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className={cx("", className)} {...rest}>
        <div className="flex justify-between items-center flex-wrap sm:flex-nowrap gap-1">
          <h2 className="leading-8 text-2xl font-semibold">Global DNC</h2>
          <div className="flex gap-4 items-center">
            <OutlineButton
              onClick={() => exportAdminDnc({ text: searchTerm })}
              className="btn-sm text-black"
              loading={exportLoading}
            >
              <DownloadIcon className="opacity-80 w-5 h-5" />
              <span className="opacity-80 hidden sm:inline">Export</span>
            </OutlineButton>
            {selectedRowsKeys.length ? (
              <div onClick={() => setOpenDeleteDNCModal(true)}>
                <BinIcon className="mr-2 active:scale-90 w-6 h-6 cursor-pointer opacity-60 hover:text-primary hover:opacity-100" />
              </div>
            ) : null}
            <TextField
              value={searchTerm}
              onChange={handleSearchTermChange}
              placeholder="Search DNC contact"
              startIcon={<SearchIcon className="w-6 h-6" />}
              className="text-sm outline outline-outline input-sm"
              rootClassName="basis-56"
              type="search"
            />
            <OutlineButton
              onClick={handleOpenImportModal}
              className="btn-primary btn-sm"
            >
              <UploadFileIcon className="w-6 h-6" />
              <span className="hidden sm:inline">Import</span>
            </OutlineButton>
            <Button onClick={handleOpenAddModal} className="btn-primary btn-sm">
              <AddIcon className="w-6 h-6" />
              <span className="hidden sm:inline">Add DNC Contact</span>
            </Button>
          </div>
        </div>
        <div
          className={cx("mt-5 flex px-2 py-1 min-h-[292px]", {
            "justify-center items-center": !dncs.length,
          })}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {dncs.length ? (
                <div className="w-full">
                  <AdminDNCTable
                    dncs={dncs}
                    page={skip}
                    total={total}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onRowSelect={setSelectedRowsKeys}
                    loading={isFetching}
                  />
                </div>
              ) : (
                <EmptyList message="There are no DNC contacts yet">
                  <div className="flex gap-3">
                    <OutlineButton
                      onClick={handleOpenImportModal}
                      className="btn-primary btn-base"
                    >
                      <UploadFileIcon className="w-6 h-6" />
                      <span>Import</span>
                    </OutlineButton>
                    <Button
                      onClick={handleOpenAddModal}
                      className="btn-base group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                    >
                      <AddIcon className="transition-colors text-primary group-hover:text-white" />
                      <span className="hidden sm:inline transition-colors font-medium text-primary group-hover:text-white">
                        Add DNC Contact
                      </span>
                    </Button>
                  </div>
                </EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      <AddEditDNCModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
      />
      <ImportDNCModal
        open={openImportModal}
        onClose={() => setOpenImportModal(false)}
      />
      <DeleteDNCModal
        open={openDeleteDNCModal}
        data={selectedRowsKeys
          .map(String)
          .map((deletedId) => dncs.find(({ _id }) => _id === deletedId)!)
          .filter(Boolean)}
        onClose={() => setOpenDeleteDNCModal(false)}
        onDelete={handleClearSelection}
      />
    </>
  )
}

export default AdminDNCPage
