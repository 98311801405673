import { useContext } from "react"
import { SenderModalContext } from "../context"

export const useSenderModalContext = () => {
  const ctx = useContext(SenderModalContext)
  if (!ctx) {
    throw new Error(
      "useSenderModalContext must be used within a SenderModalContext",
    )
  }
  return ctx
}
