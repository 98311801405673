import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { animated, useSpring, easings } from "@react-spring/web"
import CancelIcon from "@/assets/cancel.svg"
import cx from "classnames"

type Props = {
  id?: string
  open: boolean
  title?: string
  children: React.ReactNode
  actions?: React.ReactNode
  className?: string
  boxClassName?: string
  expanded: boolean
  paneContent: React.ReactNode
  onSubmit?: (ev: React.FormEvent<HTMLFormElement>) => void
  onClose: () => void
  onCollapse?: () => void
}

const ExpandedModal = ({
  open = false,
  title,
  children,
  actions,
  className,
  boxClassName,
  expanded,
  paneContent,
  onSubmit,
  onClose,
  onCollapse,
  id,
}: Props) => {
  const ref = useRef<HTMLDialogElement>(null)

  const springs = useSpring({
    flexBasis: expanded ? "100%" : "0%",
    width: expanded ? "auto" : 0,
    padding: expanded ? 16 : 0,
    paddingRight: expanded ? 4 : 0,
    whiteSpace: (expanded ? "normal" : "nowrap") as any,
    config: {
      duration: 300,
      easing: easings.linear,
    },
  })
  useEffect(() => {
    open ? ref.current?.show() : ref.current?.close()
  }, [open])

  if (!open) {
    return null
  }

  return createPortal(
    <dialog
      id={id}
      onKeyDown={({ key }) => key === "Escape" && onClose()}
      ref={ref}
      className={cx("modal bg-[#000] bg-opacity-30", className)}
    >
      <animated.div
        className={cx(
          "modal-box font-sans pt-0 pb-0 px-0 bg-white flex transition-[max-width] duration-300 ease-linear",
          {
            "max-w-5xl": expanded,
            "max-w-[32rem]": !expanded,
          },
          boxClassName,
        )}
      >
        <div className={cx("flex flex-col pt-4 basis-full max-w-[32rem]")}>
          <h3 className="px-6 font-semibold text-xl pb-4 border-b border-b-outline">
            {title}
          </h3>
          <div className="p-6 text-black flex-1 overflow-y-auto overflow-x-hidden">
            {children}
          </div>
          <div className="modal-action px-6 mt-0 py-4 bg-bg-default border-t border-t-outline">
            <form onSubmit={onSubmit} method="dialog">
              {actions}
            </form>
          </div>
        </div>
        <animated.div
          className="box-content overflow-x-hidden pt-4 relative overflow-y-auto border-l border-outline"
          style={{
            ...springs,
          }}
        >
          {onCollapse && expanded && (
            <CancelIcon
              onClick={onCollapse}
              className="fixed z-10 top-3 right-4 opacity-60 hover:opacity-80 w-6 h-6 cursor-pointer active:scale-90"
            />
          )}
          {paneContent}
        </animated.div>
      </animated.div>
    </dialog>,
    document.body,
  )
}

export default ExpandedModal
