import { FC } from "react"
import cx from "classnames"
import { useFormik } from "formik"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import TextField from "@/components/TextField"
import Button from "@/components/Button"
import validationSchema from "./validation-schema"
import { useUserAddDNC } from "../../hooks/useUserAddDNC"
import { useParams } from "react-router-dom"
import { useUserEditDNC } from "../../hooks/useUserEditDNC"
import type { AdminDNC } from "@/types/entities/dnc"

type Props = {
  className?: string
  data?: AdminDNC
} & ModalProps

const AddEditDNCModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const { id = "" } = useParams()
  const isEdit = !!data
  const {
    addUserDNC,
    response: { isLoading: addLoading },
  } = useUserAddDNC()
  const {
    editUserDNC,
    response: { isLoading: editLoading },
  } = useUserEditDNC()
  const { getFieldProps, handleSubmit, resetForm, isValid, dirty } = useFormik({
    enableReinitialize: true,
    initialValues: data ?? {
      value: "",
    },
    validationSchema,
    onSubmit: async ({ value }) => {
      try {
        isEdit
          ? await editUserDNC({ recordId: data._id, value })
          : await addUserDNC({ body: { records: [value] }, id })
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  const submitDisabled = editLoading || addLoading || !(isValid && dirty)
  const submitBtnText = isEdit ? "Save" : "Add"
  const title = isEdit ? "Edit DNC Contact" : "Add DNC Contact"

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            type="button"
            className="btn-sm text-black min-w-[86px]"
            onClick={handleClose}
            disabled={editLoading || addLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={submitDisabled}
            type="submit"
            className="btn-sm min-w-[86px]"
          >
            {submitBtnText}
          </Button>
        </div>
      }
      {...rest}
    >
      <TextField
        topLeftLabel="Email Address or Domain"
        placeholder="example@company.com or company.com"
        className="input-sm"
        type="email"
        {...getFieldProps("value")}
      />
    </Modal>
  )
}

export default AddEditDNCModal
