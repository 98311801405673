import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
  children: React.ReactNode
  color: string
}

const StatusText: FC<Props> = ({ className, color, children }: Props) => {
  return (
    <div className={cx("flex gap-2.5 items-center", className)}>
      <div
        className="rounded-full h-2.5 w-2.5"
        style={{ backgroundColor: color ?? "#F9F9F9" }}
      ></div>
      <span className="text-sm leading-6">{children}</span>
    </div>
  )
}

export default StatusText
