import { useAuth } from "@/hooks/useAuth"
import OrganizationHeader from "./features/Header"
import OrganizationsList from "./features/OrganizationsList"
import AdminOrganizationsList from "./features/admin/AdminOrganizationsList"

const OrganizationsPage = () => {
  const { user } = useAuth()

  return (
    <div className="flex flex-col z-0">
      <OrganizationHeader />
      {user ? (
        user.isAdmin ? (
          <AdminOrganizationsList />
        ) : (
          <OrganizationsList />
        )
      ) : null}
    </div>
  )
}

export default OrganizationsPage
