import { FC } from "react"
import cx from "classnames"
import { BiSolidFilePng } from "react-icons/bi"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const PngIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <BiSolidFilePng
      className={cx("w-5 h-5 transition-opacity", className)}
      {...rest}
    />
  )
}

export default PngIcon
