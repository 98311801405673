import { FC, useEffect } from "react"
import { IoMdRemove as HorizontalLineIcon } from "react-icons/io"
import { FaRegCalendar as CalendarIcon } from "react-icons/fa6"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import {
  selectCampaignSetupEndDate,
  selectCampaignSetupSchedule,
  selectCampaignSetupSettings,
  selectCampaignSetupStartDate,
  selectCampaignSetupStatus,
  selectCampaignSetupTimezone,
  selectCampaignSetupUseEndDate,
} from "@/redux/campaignSetupSlice"
import { useAppSelector } from "@/redux/hooks"
import { useEffectOnce } from "usehooks-ts"
import Checkbox from "@/components/Checkbox"
import OutlineButton from "@/components/OutlineButton"
import Select from "@/components/Select"
import CampaignsStatusSelect from "@/pages/Campaigns/features/CampaignsStatusSelect"
import {
  useGetSystemValuesQuery,
  useGetTimezonesQuery,
} from "@/redux/services/generalApi"
import { useCampaignSetup } from "../hooks/useCampaignSetup"
import TextField from "@/components/TextField"
import DatePicker from "@/components/DatePicker"
import TimeInput from "@/components/TimeInput"
import Loader from "@/components/Loader"
import { MAX_DAILY_VOLUME, MIN_DAILY_VOLUME, formatDate } from "./utils"
import { useGetCampaignQuery } from "@/redux/services/campaignsApi"
import { formatDateToISOString, isWeekday } from "@/utils/dates"
import type { EditCampaignPayload } from "@/types/entities/campaign"

dayjs.extend(utc)

type Props = {
  onChange: (body: EditCampaignPayload) => void
  onDisabledChange: (disabled: boolean) => void
}

const CampaignSettingsView: FC<Props> = ({ onChange, onDisabledChange }) => {
  const { campaignId = "" } = useParams()
  const { data: timezones = [], isLoading: timeZonesLoading } =
    useGetTimezonesQuery()
  const { data: systemValues, isLoading: systemValuesLoading } =
    useGetSystemValuesQuery()
  const { data: campaign, isLoading: campaignLoading } = useGetCampaignQuery(
    { id: campaignId },
    { skip: !campaignId },
  )
  const timezone = useAppSelector(selectCampaignSetupTimezone)
  const settings = useAppSelector(selectCampaignSetupSettings)
  const useEndDate = useAppSelector(selectCampaignSetupUseEndDate)
  const startDate = useAppSelector(selectCampaignSetupStartDate)
  const endDate = useAppSelector(selectCampaignSetupEndDate)
  const status = useAppSelector(selectCampaignSetupStatus)
  const {
    daysOfWeek,
    hours: { from, to },
  } = useAppSelector(selectCampaignSetupSchedule)
  const { dailyLimit } = settings
  const {
    changeTimezone,
    changeUseEndDate,
    changeDate,
    changeDailyLimits,
    changeSchedule,
    changeStatus,
  } = useCampaignSetup()
  useEffectOnce(() => {
    if (!daysOfWeek.length && systemValues) {
      changeSchedule({
        daysOfWeek: systemValues.daysOfWeek.map(({ id }) => id),
        hours: campaign?.schedule?.hours ?? {
          from,
          to,
        },
      })
    }
  })
  useEffect(() => {
    onChange(
      Object.assign(
        {
          useEndDate,
          settings: JSON.stringify({
            dailyLimit,
          }),
          schedule: {
            daysOfWeek,
            hours: {
              from,
              to,
            },
          },
          tz: timezone,
          dailyLimit,
        },
        startDate
          ? {
              startDate: formatDateToISOString(startDate),
            }
          : {},
        useEndDate && endDate
          ? {
              endDate: formatDateToISOString(endDate, "23:59:59.999"),
            }
          : {},
      ),
    )
  }, [
    dailyLimit,
    daysOfWeek,
    endDate,
    from,
    onChange,
    startDate,
    timezone,
    to,
    useEndDate,
  ])
  useEffect(() => {
    onDisabledChange(
      (useEndDate && !endDate) ||
        dailyLimit < MIN_DAILY_VOLUME ||
        !daysOfWeek.length ||
        !startDate,
    )
    return () => {
      onDisabledChange(false)
    }
  }, [onDisabledChange, useEndDate, endDate, dailyLimit, daysOfWeek, startDate])

  const handleToggleDays = (dayIndex: number) => {
    const dayPresentIndex = daysOfWeek.findIndex((v) => v === dayIndex)
    let newDays = [...daysOfWeek]
    if (dayPresentIndex === -1) {
      newDays = newDays.concat(dayIndex)
    } else {
      newDays.splice(dayPresentIndex, 1)
    }
    changeSchedule({
      hours: {
        from,
        to,
      },
      daysOfWeek: newDays,
    })
  }
  const handleDailyLimitChange = (v: number) => {
    changeDailyLimits({
      dailyLimit: v,
    })
  }
  const handleChangeDailyLimit = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value.length > MAX_DAILY_VOLUME.toString().length) {
      return
    }
    const parsedValue = value ? parseInt(value) : 0
    if (parsedValue > MAX_DAILY_VOLUME) {
      handleDailyLimitChange(MAX_DAILY_VOLUME)
      return
    }
    handleDailyLimitChange(value ? parseInt(value) : 0)
  }
  const utcDate = dayjs.utc()
  const startDateMinDate = new Date(
    Date.UTC(utcDate.year(), utcDate.month(), utcDate.date()) -
      dayjs().utcOffset() * 60000,
  )
  const loading =
    systemValuesLoading ||
    timeZonesLoading ||
    campaignLoading ||
    !systemValues ||
    !campaign

  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-lg leading-7 font-semibold">Settings</h3>
      {loading ? (
        <div className="mt-10 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="flex gap-5 flex-col lg:flex-row">
          <div className="flex flex-col gap-5 flex-1 [&_button]:!pr-2">
            <Select
              value={timezone}
              onChange={(timezone) => changeTimezone(timezone)}
              className="h-10 bg-input rounded-md"
              containerClassName="[&]:!gap-0 [&_.label-text]:py-2"
              bordered={false}
              label="Time Zone"
              placeholder="Select Timezone"
              options={timezones.map(({ display, abbr }) => ({
                label: `${abbr} (${display})`,
                value: abbr,
              }))}
            />
            <div className="flex flex-col gap-2 lg:flex-row items-center">
              <div className="flex flex-col flex-1">
                <DatePicker
                  topLeftLabel="Starting Date"
                  selected={startDate ? startDate : null}
                  onChange={(value) => {
                    if (!value) {
                      return
                    }
                    changeDate("start", value)
                  }}
                  suffixIcon={<CalendarIcon className="w-5 h-5 text-black" />}
                  disabled={Boolean(campaign.publishedAt)}
                  placeholderText={dayjs().format("M/D/YYYY")}
                  filterDate={isWeekday}
                  formatTextFieldValue={(d) => formatDate(d)}
                  minDate={startDateMinDate}
                  maxDate={
                    useEndDate && endDate
                      ? dayjs(endDate).utc().subtract(3, "days").toDate()
                      : undefined
                  }
                  showTimeInput={false}
                />
                <div className="hidden lg:flex items-center mt-2 h-6"></div>
              </div>
              <HorizontalLineIcon className="h-5 -mb-3.5 lg:-mb-0 w-5 rotate-90 lg:rotate-0" />
              <div className="flex flex-col flex-1">
                <DatePicker
                  topLeftLabel="End Date"
                  disabled={!useEndDate}
                  selected={endDate ? endDate : null}
                  filterDate={isWeekday}
                  onChange={(value) => {
                    if (!value) {
                      return
                    }
                    changeDate("end", value)
                  }}
                  placeholderText={dayjs().add(1, "day").format("M/D/YYYY")}
                  suffixIcon={<CalendarIcon className="w-5 h-5 text-black" />}
                  formatTextFieldValue={(d) => formatDate(d)}
                  minDate={dayjs(startDate).utc().add(3, "day").toDate()}
                  showTimeInput={false}
                />
                <div className="flex items-center mt-2">
                  <Checkbox
                    id="useEndDate"
                    className="checkbox-primary w-[18px] h-[18px]"
                    checked={useEndDate}
                    onChange={({ target: { checked } }) =>
                      changeUseEndDate(checked)
                    }
                  />
                  <label
                    className="pl-2.5 cursor-pointer text-sm leading-6"
                    htmlFor="useEndDate"
                  >
                    Use End Date
                  </label>
                </div>
              </div>
            </div>
            <div>
              <h4 className="label-text font-medium text-black pb-2">
                Sending Window
              </h4>
              <div className="flex flex-col gap-2 lg:flex-row items-center">
                <TimeInput
                  date={new Date(new Date().setHours(from))}
                  onChange={(value) => {
                    if (!value) {
                      return
                    }
                    changeSchedule({
                      daysOfWeek,
                      hours: {
                        from: value.hour(),
                        to,
                      },
                    })
                  }}
                  timeInputFormat="hh:00"
                />
                <HorizontalLineIcon className="h-5 w-5 rotate-90 lg:rotate-0" />
                <TimeInput
                  date={new Date(new Date().setHours(to))}
                  onChange={(value) => {
                    if (!value) {
                      return
                    }
                    changeSchedule({
                      daysOfWeek,
                      hours: {
                        to: value.hour(),
                        from,
                      },
                    })
                  }}
                  timeInputFormat="hh:00"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium">Sending Days</span>
              <div className="flex gap-2.5 flex-wrap [&>button]:flex-1">
                {systemValues.daysOfWeek.map(({ display, id }) => (
                  <OutlineButton
                    key={id}
                    active={daysOfWeek.findIndex((v) => v === id) !== -1}
                    className="btn-xs text-black font-medium"
                    onClick={() => handleToggleDays(id)}
                  >
                    {display.slice(0, 3)}
                  </OutlineButton>
                ))}
              </div>
            </div>
          </div>
          <div className="border-[0.5px] border-outline"></div>
          <div className="flex-1 flex flex-col gap-5">
            <div className="flex flex-col">
              <span className="text-sm font-medium py-2">Status</span>
              <CampaignsStatusSelect
                className="h-10 bg-input rounded-md"
                bordered={false}
                value={status.value}
                onChange={({ value }) => changeStatus(value)}
                popupMatchSelectWidth
              />
            </div>
            <TextField
              type="number"
              value={settings.dailyLimit || ""}
              onChange={handleChangeDailyLimit}
              topLeftLabel="Daily Volume"
              className="h-10 bg-input rounded-md text-sm"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default CampaignSettingsView
