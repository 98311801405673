import { useEffect, useState } from "react"

export const useElementWidth = (ref: React.RefObject<HTMLElement>) => {
  const [elementWidth, setElementWidth] = useState(0)

  useEffect(() => {
    const elementWidth = ref.current?.clientWidth
    elementWidth && setElementWidth(elementWidth)

    const handleResize = () => setElementWidth(ref.current?.clientWidth ?? 0)

    window.onresize = handleResize

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [ref])

  return elementWidth
}
