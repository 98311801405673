import { createApi } from "@reduxjs/toolkit/query/react"
import { buildQueryString } from "@/utils/strings"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import {
  getInitialFilters,
  setCriteriaFilterStrictValue,
  setCriteriaFilters,
  setSetupCriteria,
} from "../searchCriteriaSetupSlice"
import { removeUndefinedProperties } from "@/utils/objects"
import { exportFileResponseHandler } from "@/utils/api"
import type {
  AddSearchCriteriaPayload,
  GeographyType,
  SearchCriteria,
  SearchCriteriaFilter,
} from "@/types/entities/search-criteria"
import type { PaginationProps } from "./types"
import type { RootState } from "../store"

export const searchCriteriaApi = createApi({
  reducerPath: "searchCriteriaApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Search Criteria", "Search Criteria Filters"],
  endpoints: (builder) => ({
    getSearchCriterias: builder.query<
      { items: SearchCriteria[]; total: number },
      PaginationProps & { id: string } & { text?: string }
    >({
      query: ({ id, ...keys }) => ({
        method: "GET",
        url: `org/${id}/searchCriterias?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: SearchCriteria[]; total: number }
      }) => ({
        items,
        total,
      }),
      keepUnusedDataFor: 1,
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Search Criteria" as const,
                _id,
              })),
              { type: "Search Criteria", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Search Criteria", id: "PARTIAL-LIST" }],
    }),
    getCriteriaFilters: builder.query<SearchCriteriaFilter[], void>({
      query: () => ({
        url: "searchCriteria/filter/all",
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: ["Search Criteria Filters"],
      transformResponse: ({ data }: { data: SearchCriteriaFilter[] }) => data,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: filters } = await queryFulfilled
        const resultMapFilters = getInitialFilters(filters)
        dispatch(setCriteriaFilters(resultMapFilters))
        filters.forEach(({ strictSupport, type }) => {
          if (strictSupport) {
            dispatch(setCriteriaFilterStrictValue({ type, checked: false }))
          }
        })
      },
    }),
    getSearchCriteria: builder.query<SearchCriteria, { id: string }>({
      query: ({ id }) => ({
        url: `searchCriteria/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      providesTags: (result) => [{ type: "Search Criteria", id: result?._id }],
      transformResponse: ({ data }: { data: SearchCriteria }) => data,
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        const {
          data: {
            name,
            description,
            masterList,
            status,
            type,
            keywords,
            filters,
            queriesStats,
            target,
            domains,
          },
        } = await queryFulfilled
        const resultFilters = {
          ...(getState() as RootState).searchCriteriaSetup.filters,
        }
        const geographyType =
          (filters.find(
            ({ type }) => type === "REGION" || type === "POSTAL_CODE",
          )?.type as GeographyType) ?? "REGION"
        filters.forEach(({ selections, type, strict, subSelection }) => {
          if (!resultFilters[type]?.selections) {
            return
          }
          const currentSelections = { ...resultFilters[type].selections }

          if (type === "REGION" || type === "POSTAL_CODE") {
            // reseting other geography type after save
            const otherGeographyType =
              type === "REGION" ? "POSTAL_CODE" : "REGION"
            resultFilters[otherGeographyType] = removeUndefinedProperties({
              selections: {
                INCLUDED: [],
                EXCLUDED: [],
              },
            }) as any
          }
          // overriding selections
          selections.forEach(({ type, values }) => {
            currentSelections[type] = values
          })
          resultFilters[type] = removeUndefinedProperties({
            selections: currentSelections,
            strict,
            subSelection,
          }) as any
        })
        const setupPayload = {
          name,
          description,
          masterList: masterList?._id ?? "",
          status,
          type,
          queriesStats,
          geographyType,
          domains,
          target: type === "manual" ? target : undefined,
          keywords: [keywords],
          filters: resultFilters,
        }
        dispatch(
          setSetupCriteria({
            ...setupPayload,
            initialState: {
              ...setupPayload,
            },
          }),
        )
      },
    }),
    addSearchCriteria: builder.mutation<
      SearchCriteria,
      AddSearchCriteriaPayload
    >({
      query: (body) => ({
        method: "POST",
        url: "searchCriteria",
        body,
      }),
      transformResponse: ({ data }: { data: SearchCriteria }) => data,
    }),
    editSearchCriteria: builder.mutation<
      SearchCriteria,
      {
        body: Partial<AddSearchCriteriaPayload>
        id: string
      }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `searchCriteria/${id}`,
        body,
      }),
      transformResponse: ({ data }: { data: SearchCriteria }) => data,
    }),
    deleteSearchCriteria: builder.mutation<
      { success: boolean },
      {
        id: string
      }
    >({
      query: ({ id }) => ({
        method: "DELETE",
        url: `searchCriteria/${id}`,
      }),
      invalidatesTags: ["Search Criteria"],
    }),
    downloadSC: builder.mutation<unknown, { id: string }>({
      query: ({ id }) => ({
        method: "POST",
        url: `searchCriteria/${id}/download`,
        responseHandler: async (response) =>
          exportFileResponseHandler(response, "SC.csv"),
        cache: "no-cache",
      }),
    }),
  }),
})

export const {
  useGetCriteriaFiltersQuery,
  useGetSearchCriteriaQuery,
  useGetSearchCriteriasQuery,
  useAddSearchCriteriaMutation,
  useEditSearchCriteriaMutation,
  useDeleteSearchCriteriaMutation,
  useLazyGetSearchCriteriaQuery,
  useLazyGetCriteriaFiltersQuery,
  useDownloadSCMutation,
} = searchCriteriaApi
