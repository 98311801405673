import { useChangePasswordMutation } from "@/core/api/services"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"

export const useChangePassword = () => {
  const dispatch = useAppDispatch()
  const [changePassword, response] = useChangePasswordMutation()

  const handleSetupPassword = async ({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string
    newPassword: string
  }) => {
    try {
      await changePassword({
        currentPassword,
        newPassword,
      }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success reset password",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { changePassword: handleSetupPassword, response }
}
