import { FC, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import cx from "classnames"
import Table from "@/components/Table"
import DeleteDomainModal from "../DeleteDomainModal"
import ViewIcon from "@/components/icons/ViewIcon"
import EmailPlatformColumnValue from "@/components/EmailPlatformColumnValue"
import CheckCircleIcon from "@/components/icons/CheckCircleIcon"
import CancelIcon from "@/assets/cancel.svg"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { useUserAccess } from "@/hooks/useUserAccess"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import { toCapitalize } from "@/utils/strings"
import { DATE_FORMAT_OPTIONS } from "@/constants"
import { countActionsWidth } from "@/components/Table/utils"
import type { Domain } from "@/types/entities/domain"
import type { ColumnsType } from "antd/es/table"
import type { TablePaginationProps } from "@/redux/services/types"

type Props = {
  className?: string
  domains: Domain[]
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  domain: string
  emailProvider: string
  provider: string
  status: boolean
  createdAt: string
  actions: Domain["actions"] | undefined
}

const DomainsTable: FC<Props> = ({ className, domains, ...rest }: Props) => {
  const navigate = useNavigate()
  const { sm } = useBreakpoints()
  const { pageFullAccess: domainsAccess } = useUserAccess({
    pageName: "Domains",
  })
  const {
    pageAnyAccess: domainsUpdateAnyAccess,
    pageFullAccess: domainsUpdateFullAccess,
  } = useUserAccess({
    pageName: "Domains Update",
  })
  const [deletedId, setDeletedId] = useState("")
  const { data: { domainProviders } = { domainProviders: [] } } =
    useGetSystemValuesQuery()
  const dataSource = useMemo<TableDataType[]>(
    () =>
      domains.map(
        ({ _id, name, createdAt, emailProvider, provider, actions }) => ({
          key: _id,
          domain: name,
          emailProvider: emailProvider ?? "",
          provider,
          status: Boolean(actions?.readyAt),
          actions,
          createdAt: new Date(createdAt).toLocaleDateString(
            "en-US",
            DATE_FORMAT_OPTIONS,
          ),
        }),
      ),
    [domains],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(() => {
    let resultCols: ColumnsType<TableDataType> = [
      {
        title: "Domain",
        key: "domain",
        dataIndex: "domain",
        sorter: (a, b) => a.domain.localeCompare(b.domain),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        sorter: (a, b) => Number(a.status) - Number(b.status),
        render: (v) => (
          <>
            <span>{v ? "Active" : "Setup"}</span>
            {v ? (
              <CheckCircleIcon className="ml-1.5 inline-block text-positive" />
            ) : (
              <CancelIcon className="ml-[8.5px] inline-block text-error w-5 h-5" />
            )}
          </>
        ),
      },
      {
        title: "Provider",
        key: "emailProvider",
        dataIndex: "emailProvider",
        sorter: (a, b) => a.emailProvider.localeCompare(b.emailProvider),
        render: (value, { emailProvider }) => (
          <EmailPlatformColumnValue
            provider={value}
            value={toCapitalize(emailProvider)}
          />
        ),
      },
      {
        title: "Registrar",
        key: "provider",
        dataIndex: "provider",
        sorter: (a, b) => a.provider.localeCompare(b.provider),
        render: (value) => {
          return domainProviders.find(({ id }) => id === value)?.name ?? value
        },
      },
      {
        title: "Date Added",
        key: "createdAt",
        dataIndex: "createdAt",
        sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        width: 130,
      },
    ]
    if (domainsAccess || domainsUpdateAnyAccess) {
      const actionsLength =
        Number(domainsUpdateAnyAccess) + Number(domainsAccess)
      resultCols = resultCols.concat({
        title: "",
        dataIndex: "actions",
        key: "actions",
        fixed: sm ? "right" : false,
        render: (actions, { key }) => (
          <div className="actions hidden gap-4">
            {domainsUpdateFullAccess ? (
              <>
                {actions?.readyAt ? (
                  <ViewIcon
                    onClick={(e: React.MouseEvent<HTMLOrSVGElement>) => {
                      e.stopPropagation()
                      navigate(`edit/${key}?mode=view`)
                    }}
                    className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
                  />
                ) : (
                  <EditIcon
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation()
                      navigate(`edit/${key}`)
                    }}
                    className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
                  />
                )}
              </>
            ) : (
              <ViewIcon
                onClick={(e: React.MouseEvent<HTMLOrSVGElement>) => {
                  e.stopPropagation()
                  navigate(`edit/${key}?mode=view`)
                }}
                className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
              />
            )}
            {domainsAccess && (
              <BinIcon
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  setDeletedId(key)
                }}
                className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
              />
            )}
          </div>
        ),
        width: countActionsWidth(actionsLength),
      })
    }
    return resultCols
  }, [
    domainProviders,
    domainsAccess,
    domainsUpdateAnyAccess,
    domainsUpdateFullAccess,
    navigate,
    sm,
  ])
  return (
    <>
      <Table
        className={cx(className)}
        dataSource={dataSource}
        columns={columns}
        showSizeChanger
        scroll={{ x: "max-content" }}
        {...rest}
      />
      <DeleteDomainModal
        data={domains.find(({ _id }) => deletedId === _id)}
        open={Boolean(deletedId)}
        onClose={() => setDeletedId("")}
      />
    </>
  )
}

export default DomainsTable
