import { FC } from "react"
import cx from "classnames"
import { FaRegCalendar } from "react-icons/fa6"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const CalendarIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return <FaRegCalendar className={cx("w-5 h-5", className)} {...rest} />
}

export default CalendarIcon
