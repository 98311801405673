import { FC } from "react"
import { useFormik } from "formik"
import Modal from "@/components/Modal"
import TextField from "@/components/TextField"
import Button from "@/components/Button"
import OutlineButton from "@/components/OutlineButton"
import validationSchema from "../utils/validation-schema"
import Select from "@/components/Select"
import { useAdminUsersInfiniteScroll } from "@/pages/Organizations/hooks/useAdminUsersInfiniteScroll"
import { useAdminEditOrganization } from "@/pages/Organizations/hooks/useAdminEditOrganization"
import type { AdminOrganization } from "@/types/entities/organization"

type Props = {
  open: boolean
  data?: AdminOrganization
  onClose: () => void
  onEditSuccess?: (response: AdminOrganization) => void
}

const AdminEditOrganizationModal: FC<Props> = ({
  open,
  onClose,
  data,
  onEditSuccess,
}: Props) => {
  const { editOrganization, response } = useAdminEditOrganization()
  const { isLoading, onUsersScroll, paginatedOwners } =
    useAdminUsersInfiniteScroll()
  const {
    handleSubmit,
    getFieldProps,
    isValid,
    dirty,
    resetForm,
    values: { owner },
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: data?.name ?? "",
      owner: data?.owner._id ?? "",
      url: data?.url ?? "",
      catchAll: data?.catchAll ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ name, owner, url, catchAll }) => {
      try {
        const response = await editOrganization({
          id: data!._id,
          name,
          owner,
          url,
          catchAll,
        })
        onEditSuccess && response && onEditSuccess(response.data)
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  if (!data) {
    return null
  }
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  return (
    <Modal
      open={open}
      title="Edit Organization"
      onClose={handleClose}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={handleClose}
            className="text-black btn-sm !w-[86px]"
            type="button"
            disabled={response.isLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={!(isValid && dirty) || response.isLoading}
            className="btn-sm !w-[86px]"
            type="submit"
          >
            Save
          </Button>
        </div>
      }
      onSubmit={handleSubmit}
    >
      <div className="space-y-5">
        <TextField
          type="text"
          topLeftLabel="Name"
          placeholder="Enter organization name"
          rootClassName="[&_label.label]:pt-0"
          className="input-sm"
          autoComplete="off"
          {...getFieldProps("name")}
        />
        <TextField
          type="url"
          topLeftLabel="Website"
          placeholder="Enter organization URL"
          className="input-sm"
          {...getFieldProps("url")}
        />
        <Select
          label="Owner"
          className="bg-input h-10 rounded-md"
          bordered={false}
          placeholder="Select owner"
          loading={isLoading}
          options={paginatedOwners.map(({ _id, firstName, lastName }) => ({
            label: `${firstName} ${lastName}`,
            value: _id,
          }))}
          getPopupContainer={(node) => node.parentNode}
          value={owner || null}
          onChange={(v) => setFieldValue("owner", v)}
          onPopupScroll={onUsersScroll}
        />
        <TextField
          type="email"
          autoComplete="new-password"
          topLeftLabel="Catch-All Email"
          placeholder="Enter catch-all email"
          className="input-sm"
          {...getFieldProps("catchAll")}
        />
      </div>
    </Modal>
  )
}

export default AdminEditOrganizationModal
