import { FC, useMemo, useState } from "react"
import cx from "classnames"
import TextField from "@/components/TextField"
import { usePagination } from "@/hooks/usePagination"
import Button from "@/components/Button"
import SearchIcon from "@/assets/search.svg"
import AddIcon from "@/components/icons/AddIcon"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import ManageAccountsIcon from "@/assets/manage-accounts.svg"
import EmptyList from "@/components/EmptyList"
import { useGetAdminUsersQuery } from "@/redux/services/adminUsersApi"
import AdminUsersTable from "./features/AdminUsersTable"
import InviteEditUserModal from "./features/InviteEditUserModal"
import DeleteAdminUserModal from "./features/DeleteAdminUserModal"
import TransferAccountsModal from "./features/TransferAccountsModal"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import type { Key } from "antd/es/table/interface"

type Props = {
  className?: string
}

const AdminUsersPage: FC<Props> = ({ className, ...rest }: Props) => {
  const {
    searchTerm,
    limit,
    skip,
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
  } = usePagination()
  const {
    data: { items: users, total } = { items: [], total: 0 },
    isLoading,
    isFetching,
    isError,
  } = useGetAdminUsersQuery({ limit, skip })
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openDeleteUsersModal, setOpenDeleteUsersModal] = useState(false)
  const [openTransferAccountsModal, setOpenTransferAccountsModal] =
    useState(false)
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([])

  const handleOpenAddModal = () => setOpenAddModal(true)
  const handleOpenDeleteUsersModal = () => setOpenDeleteUsersModal(true)
  const handleOpenTransferAccountsModal = () =>
    setOpenTransferAccountsModal(true)
  const handleClearSelection = () => setSelectedRowsKeys([])

  const filteredUsers = useMemo(
    () =>
      searchTerm
        ? users.filter(({ firstName, lastName }) =>
            `${firstName} ${lastName}`
              .toLocaleLowerCase()
              .includes(searchTerm.trim().toLocaleLowerCase()),
          )
        : users,
    [searchTerm, users],
  )

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className={cx("", className)} {...rest}>
        <div className="flex justify-between items-center flex-wrap sm:flex-nowrap gap-1">
          <h2 className="leading-8 text-2xl font-semibold">Users</h2>
          <div className="flex gap-4 items-center">
            {selectedRowsKeys.length ? (
              <div className="flex gap-4 pr-2">
                <div className="group active:scale-90">
                  <ManageAccountsIcon
                    onClick={handleOpenTransferAccountsModal}
                    className="w-6 h-6 cursor-pointer group-hover:[&>g>g>path]:fill-primary group-hover:[&>g>g>path]:opacity-100"
                  />
                </div>
                <BinIcon
                  onClick={handleOpenDeleteUsersModal}
                  className="active:scale-90 w-6 h-6 cursor-pointer opacity-60 hover:text-primary hover:opacity-100"
                />
              </div>
            ) : null}
            <TextField
              value={searchTerm}
              onChange={handleSearchTermChange}
              placeholder="Search user"
              startIcon={<SearchIcon className="w-6 h-6" />}
              className="text-sm outline outline-outline input-sm !py-1.5"
              rootClassName="basis-56 !w-auto"
              type="search"
            />
            <Button onClick={handleOpenAddModal} className="btn-primary btn-sm">
              <AddIcon className="w-6 h-6" />
              <span className="hidden sm:inline">Invite User</span>
            </Button>
          </div>
        </div>
        <div
          className={cx("mt-5 flex px-2 py-1 min-h-[292px]", {
            "justify-center items-center": !users.length,
          })}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {users.length ? (
                <div className="w-full">
                  <AdminUsersTable
                    users={filteredUsers}
                    page={skip}
                    total={total}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onRowSelect={(keys) => setSelectedRowsKeys(keys)}
                    selectedRowKeys={selectedRowsKeys}
                    loading={isFetching}
                  />
                </div>
              ) : (
                <EmptyList message="There are no users yet">
                  <Button
                    onClick={handleOpenAddModal}
                    className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                  >
                    <AddIcon className="transition-colors text-primary group-hover:text-white" />
                    <span className="transition-colors font-medium text-primary group-hover:text-white">
                      Invite User
                    </span>
                  </Button>
                </EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      <InviteEditUserModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
      />
      <DeleteAdminUserModal
        open={openDeleteUsersModal}
        data={selectedRowsKeys
          .map(String)
          .map((deletedId) => users.find(({ _id }) => _id === deletedId)!)
          .filter(Boolean)}
        onClose={() => setOpenDeleteUsersModal(false)}
        onDelete={handleClearSelection}
      />
      <TransferAccountsModal
        open={openTransferAccountsModal}
        options={users}
        data={selectedRowsKeys
          .map(String)
          .map((selectedId) => users.find(({ _id }) => _id === selectedId)!)
          .filter(Boolean)}
        onClose={() => setOpenTransferAccountsModal(false)}
        onTransfer={handleClearSelection}
      />
    </>
  )
}

export default AdminUsersPage
