import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { useParams } from "react-router-dom"
import { useEditSearchCriteriaMutation } from "@/redux/services/searchCriteriaApi"
import {
  selectCriteriaDescription,
  selectCriteriaDomains,
  selectCriteriaFilledFilters,
  selectCriteriaKeywords,
  selectCriteriaMasterListId,
  selectCriteriaName,
  selectCriteriaStatus,
  selectCriteriaTarget,
  selectCriteriaType,
} from "@/redux/searchCriteriaSetupSlice"
import { removeUndefinedProperties } from "@/utils/objects"
import type { AddSearchCriteriaPayload } from "@/types/entities/search-criteria"

export const useEditSearchCriteria = () => {
  const dispatch = useAppDispatch()
  const [editCriteria, response] = useEditSearchCriteriaMutation()
  const { criteriaId = "", id: orgId = "" } = useParams()
  const name = useAppSelector(selectCriteriaName)
  const description = useAppSelector(selectCriteriaDescription)
  const masterList = useAppSelector(selectCriteriaMasterListId)
  const status = useAppSelector(selectCriteriaStatus)
  const type = useAppSelector(selectCriteriaType)
  const target = useAppSelector(selectCriteriaTarget)
  const keywords = useAppSelector(selectCriteriaKeywords)
  const filters = useAppSelector(selectCriteriaFilledFilters)
  const domains = useAppSelector(selectCriteriaDomains)

  const handleEditCriteria = async ({
    body,
    id,
  }: {
    body?: Partial<AddSearchCriteriaPayload>
    id?: string
  }) => {
    try {
      const resultBody =
        body ??
        removeUndefinedProperties({
          orgId,
          name,
          description,
          masterList,
          status,
          type,
          target: type === "manual" ? target : undefined,
          keywords: keywords.join(" OR "),
          filters,
          domains: domains.map((d) => d.replace("\r", "")),
        })
      const response = await editCriteria({
        body: resultBody,
        id: id ?? criteriaId,
      }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success edit search criteria",
          }),
        )
      }
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { editCriteria: handleEditCriteria, response }
}
