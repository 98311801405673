import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDisconnectAdminSpamAccountMutation } from "@/redux/services/adminSpamAccountsApi"
import { setToast } from "@/redux/toastSlice"

export const useDisconnectSpamAccount = () => {
  const dispatch = useAppDispatch()
  const [disconnectSpamAccount, response] =
    useDisconnectAdminSpamAccountMutation()

  const handleDisconnectSpamAccount = async (id: string) => {
    try {
      const response = await disconnectSpamAccount({ id }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success disconnect account",
          }),
        )
      }
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { disconnectMail: handleDisconnectSpamAccount, response }
}
