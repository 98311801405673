import { FC } from "react"
import cx from "classnames"
import { formatDateToCurrentWeekdayOrDate } from "@/utils/dates"
import type { SequenceAPIResponse } from "@/types/entities/sequence"

type Props = {
  sequence: SequenceAPIResponse
  active: boolean
  onClick: (sequence: SequenceAPIResponse) => void
}

const SequenceListItem: FC<Props> = ({
  sequence,
  active = false,
  onClick,
}: Props) => {
  const { name, emails, createdAt } = sequence
  const [firstEmail] = emails
  return (
    <div
      className={cx("group transition-colors p-3 rounded-md cursor-pointer", {
        "bg-primary-glass": active,
      })}
      onClick={() => onClick(sequence)}
    >
      <h5
        className={cx(
          "truncate transition-[font-weight] group-hover:underline text-sm font-normal leading-6 tracking-[0.17px]",
          {
            "font-semibold text-primary": active,
          },
        )}
      >
        {name}
      </h5>
      <div
        className={cx(
          "flex justify-between line-clamp-1 font-normal text-xs leading-5 tracking-[0.17px] opacity-60",
          {
            "text-primary": active,
          },
        )}
      >
        <span className="max-w-[210px] truncate">
          {firstEmail.versions[0].subjects[0]}
        </span>
        <span className="text-black opacity-60 whitespace-nowrap">
          {formatDateToCurrentWeekdayOrDate(createdAt)}
        </span>
      </div>
    </div>
  )
}

export default SequenceListItem
