import { FC, useCallback, useMemo } from "react"
import cx from "classnames"
import Checkbox from "@/components/Checkbox"
import Tooltip from "@/components/Tooltip"
import { useGetCriteriaFiltersQuery } from "@/redux/services/searchCriteriaApi"
import { selectCriteriaFilter } from "@/redux/searchCriteriaSetupSlice"
import { useAppSelector } from "@/redux/hooks"
import { useCriteriaSetup } from "@/pages/AddEditSearchCriteria/hooks/useCriteriaSetup"
import { useActiveFilterContext } from "@/pages/AddEditSearchCriteria/hooks/useActiveFilterContext"
import type {
  FilterType,
  SelectionType,
} from "@/types/entities/search-criteria"

type Props = {
  className?: string
}

const CriteriaIndustryFilter: FC<Props> = ({ className, ...rest }: Props) => {
  const FILTER_TYPE = useActiveFilterContext() as FilterType
  const { data: filters = [] } = useGetCriteriaFiltersQuery()
  const { selections: industrySelections } = useAppSelector(
    selectCriteriaFilter(FILTER_TYPE),
  )!
  const industryFilterValues = useMemo(
    () => filters.find(({ type }) => type === FILTER_TYPE)?.values ?? [],
    [FILTER_TYPE, filters],
  )
  const { onFullSelectionsChange, onSelectionChange } = useCriteriaSetup({
    all: industryFilterValues.map(({ id }) => id),
  })
  const renderCheckboxes = useCallback(
    (type: SelectionType) => {
      const values = industrySelections[type]
      const otherType = type === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
      const otherValues = industrySelections[otherType]
      const setValues = onSelectionChange(type, FILTER_TYPE)
      const setAllValues = onFullSelectionsChange(type, FILTER_TYPE)
      const tooltipTitle = `Are you sure you want to ${
        values.length === industryFilterValues.length ? "deselect" : "select"
      } ${industryFilterValues.length} industries?`

      return (
        <div className="relative overflow-y-auto max-h-[456px] space-y-3 overflow-x-hidden">
          <div className="sticky top-0 bg-white z-10">
            <Tooltip
              title={tooltipTitle}
              className="inline-flex items-center"
              overlayClassName="whitespace-nowrap"
            >
              <Checkbox
                className="checkbox-primary w-[18px] h-[18px]"
                id={"all" + type}
                checked={Boolean(values.length === industryFilterValues.length)}
                onChange={setAllValues}
              />
              <label
                className="cursor-pointer pl-2.5 leading-6 text-sm"
                htmlFor={"all" + type}
              >
                Select All
              </label>
            </Tooltip>
          </div>
          <div className="flex flex-col gap-3 rounded-md">
            {values.length > 0 && (
              <div
                className={cx(
                  "shadow-sm sticky top-[30px] max-h-[256px] bg-white overflow-y-auto space-y-3 mr-1.5 border border-outline p-3 rounded-md",
                )}
              >
                {values
                  .toSorted((a, b) => {
                    const aDesc =
                      industryFilterValues.find(
                        ({ id: currId }) => currId === a,
                      )?.description ?? a
                    const bDesc =
                      industryFilterValues.find(
                        ({ id: currId }) => currId === b,
                      )?.description ?? b
                    return aDesc?.localeCompare(bDesc)
                  })
                  .map((id) => (
                    <div className="flex items-center" key={id}>
                      <Checkbox
                        value={id}
                        checked={Boolean(
                          values.find((typeValue) => id === typeValue),
                        )}
                        onChange={setValues}
                        id={id + type}
                        className="checkbox-primary w-[18px] h-[18px]"
                      />
                      <label
                        className="cursor-pointer pl-2.5 leading-6 text-sm"
                        htmlFor={id + type}
                      >
                        {industryFilterValues.find(
                          ({ id: currId }) => currId === id,
                        )?.description ?? id}
                      </label>
                    </div>
                  ))}
              </div>
            )}
            {industryFilterValues
              .filter(
                ({ id }) =>
                  !values.find((v) => v === id) &&
                  !otherValues.find((v) => v === id),
              )
              .toSorted((a, b) => a.description.localeCompare(b.description))
              .map(({ description, id }) => (
                <div className="flex items-center" key={id}>
                  <Checkbox
                    value={id}
                    checked={Boolean(
                      values.find((typeValue) => id === typeValue),
                    )}
                    onChange={setValues}
                    id={id + type}
                    className="checkbox-primary w-[18px] h-[18px]"
                  />
                  <label
                    className="cursor-pointer pl-2.5 leading-6 text-sm"
                    htmlFor={id + type}
                  >
                    {description}
                  </label>
                </div>
              ))}
          </div>
        </div>
      )
    },
    [
      industrySelections,
      onSelectionChange,
      onFullSelectionsChange,
      industryFilterValues,
      FILTER_TYPE,
    ],
  )
  return (
    <div className={cx("space-y-4", className)} {...rest}>
      <div className="flex flex-col lg:flex-row gap-4 flex-1">
        <div className="basis-1/2 gap-y-2 flex flex-col">
          <h5 className="font-medium text-positive">Industry Include</h5>
          {renderCheckboxes("INCLUDED")}
        </div>
        <div className="basis-1/2 gap-y-2 flex flex-col">
          <h5 className="font-medium text-error">Industry Exclude</h5>
          {renderCheckboxes("EXCLUDED")}
        </div>
      </div>
    </div>
  )
}

export default CriteriaIndustryFilter
