import { FC, Fragment } from "react"
import Textarea from "@/components/Textarea"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import { useSenderModalContext } from "../hooks/useSenderModalContext"
import type { FormikErrors, FormikTouched } from "formik"
import type { OtherSender } from "@/types/entities/sender"

const OtherSenderAddressForm: FC = () => {
  const { getFieldProps, errors, touched } = useSenderModalContext()
  const addressError = errors.address as FormikErrors<OtherSender["address"]>
  const addressTouched = touched.address as FormikTouched<
    OtherSender["address"]
  >

  return (
    <Fragment>
      <Textarea
        placeholder="Enter complete address"
        topLeftLabel="Complete Address"
        className="min-h-[140px]"
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={addressError?.completeAddress}
            touched={addressTouched?.completeAddress}
          />
        }
        {...getFieldProps("address.completeAddress")}
      />
    </Fragment>
  )
}

export default OtherSenderAddressForm
