export const Routes = {
  login: "/login",
  passwordSetup: "/password-setup",
  confirmEmail: "/confirm-email",
  forgotPassword: {
    index: "/forgot-password",
    form: "/forgot-password/form",
    emailSent: "/forgot-password/email-sent",
    newPasswordForm: "/forgot-password/new-password",
    done: "/forgot-password/done",
  },
  organizations: "/organizations",
  organization: {
    index: "/organization/:id",
    dashboard: "/organization/:id/dashboard",
    campaigns: "/organization/:id/campaigns",
    senders: "/organization/:id/senders",
    sequences: "/organization/:id/sequences",
    masterLists: "/organization/:id/master-lists",
    masterListsDetails: "/organization/:id/master-lists/:master-list-id",
    domains: "/organization/:id/domains",
    emails: "/organization/:id/emails",
    prospects: "/organization/:id/prospects",
    spamTesting: "/organization/:id/spam-testing",
    dnc: "/organization/:id/dnc",
    searchCriteria: "/organization/:id/search-criteria",
    inbox: "/organization/:id/inbox",
  },
  userSettings: "/user-settings",
  admin: {
    index: "/admin",
    gsuite: "/admin/domainAdmins",
    users: "/admin/users",
    spam: "/admin/spam",
    dnc: "/admin/dnc",
    aiPrompts: "/admin/ai",
    cookies: "/admin/cookies",
    proxy: "/admin/proxy",
  },
}
