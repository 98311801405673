import CriteriaCompanyFilter from "./features/filters/CriteriaCompanyFilter"
import CriteriaCompanyHeadcountFilter from "./features/filters/CriteriaCompanyHeadcountFilter"
import CriteriaGeographyFilter from "./features/filters/CriteriaGeographyFilter"
import CriteriaIndustryFilter from "./features/filters/CriteriaIndustryFilter"
import CriteriaKeywordsFilter from "./features/filters/CriteriaKeywordsFilter"
import CriteriaSeniorityLevelFilter from "./features/filters/CriteriaSeniorityLevelFilter"
import CriteriaSpotlightsFilter from "./features/filters/CriteriaSpotlightsFilter"
import CriteriaTitleFilter from "./features/filters/CriteriaTitleFilter"
import CriteriaYearCompanyFilter from "./features/filters/CriteriaYearCompanyFilter"
import CriteriaYearPositionFilter from "./features/filters/CriteriaYearPositionFilter"
import type { FilterType } from "@/types/entities/search-criteria"

export const CRITERIA_FILTER_MAP: Record<
  string,
  { component: JSX.Element; key: FilterType | "KEYWORDS" }
> = {
  Keywords: { component: <CriteriaKeywordsFilter />, key: "KEYWORDS" },
  Spotlights: {
    component: <CriteriaSpotlightsFilter />,
    key: "LEAD_HIGHLIGHTS",
  },
  Geography: { component: <CriteriaGeographyFilter />, key: "REGION" },
  Industry: { component: <CriteriaIndustryFilter />, key: "INDUSTRY" },
  "Seniority Level": {
    component: <CriteriaSeniorityLevelFilter />,
    key: "SENIORITY_LEVEL",
  },
  "Year in Current Position": {
    component: <CriteriaYearPositionFilter />,
    key: "YEARS_IN_CURRENT_POSITION",
  },
  "Year at Current Company": {
    component: <CriteriaYearCompanyFilter />,
    key: "YEARS_AT_CURRENT_COMPANY",
  },
  Title: { component: <CriteriaTitleFilter />, key: "CURRENT_TITLE" },
  Company: { component: <CriteriaCompanyFilter />, key: "CURRENT_COMPANY" },
  "Company Headcount": {
    component: <CriteriaCompanyHeadcountFilter />,
    key: "COMPANY_HEADCOUNT",
  },
}
