import { FC } from "react"
import cx from "classnames"
import Textarea from "@/components/Textarea"
import { useAppSelector } from "@/redux/hooks"
import { selectCriteriaKeywords } from "@/redux/searchCriteriaSetupSlice"
import { useCriteriaSetup } from "@/pages/AddEditSearchCriteria/hooks/useCriteriaSetup"

type Props = {
  className?: string
}

const CriteriaKeywordsFilter: FC<Props> = ({ className, ...rest }: Props) => {
  const keywords = useAppSelector(selectCriteriaKeywords)
  const { onKeywordsChange } = useCriteriaSetup()

  const handleKeywordsChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => onKeywordsChange([value])

  return (
    <div className={cx("", className)} {...rest}>
      <Textarea
        value={keywords}
        onChange={handleKeywordsChange}
        placeholder="Add keywords"
        className="min-h-[214px]"
      />
    </div>
  )
}

export default CriteriaKeywordsFilter
