import { FC, useState } from "react"
import ExpandedModal from "@/components/ExpandedModal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import Loader from "@/components/Loader"
import TextField from "@/components/TextField"
import GoogleLoginInstructions from "./GoogleLoginInstructions"
import EmailConnectionStatus from "@/components/EmailConnectionStatus"
import GoogleOAuthLogin from "@/components/GoogleOAuthLogin"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import { useFormik } from "formik"
import { useSpamAccountLogin } from "../hooks/useSpamAccountLogin"
import { useEditSpamAccount } from "@/pages/AdminSpamTesting/hooks/useEditSpamAccount"
import { useAddSpamAccount } from "@/pages/AdminSpamTesting/hooks/useAddSpamAccount"
import { useDisconnectSpamAccount } from "@/pages/AdminSpamTesting/hooks/useDisconnectSpamAccount"
import type { ModalProps } from "@/components/Modal"
import type { AdminSpamAccount } from "@/types/entities/spam-account"

type Props = {
  className?: string
  data?: AdminSpamAccount
  onErrorClose?: () => void
} & ModalProps

const AddEditGoogleSpamAccountModal: FC<Props> = ({
  className,
  open,
  onClose,
  onErrorClose,
  data,
  ...rest
}: Props) => {
  const isEdit = Boolean(data)
  const [expanded, setExpanded] = useState(false)
  const {
    data: { GSuiteAppIDSender } = { GSuiteAppIDSender: "" },
    isLoading: clientIdLoading,
  } = useGetSystemValuesQuery()
  const {
    addSpamAccount,
    response: { isLoading: addLoading },
  } = useAddSpamAccount()
  const {
    editSpamAccount,
    response: { isLoading: editLoading },
  } = useEditSpamAccount()
  const {
    disconnectMail,
    response: { isLoading: disconnectLoading },
  } = useDisconnectSpamAccount()
  const { title, submitBtnText } = useSpamAccountLogin()
  const {
    dirty,
    getFieldProps,
    resetForm,
    handleSubmit,
    setFieldValue,
    values: { displayName, code },
  } = useFormik({
    initialValues: {
      displayName: data?.displayName ?? "",
      code: data ? "-" : "",
    },
    onSubmit: async ({ displayName, code }) => {
      try {
        if (isEdit && data) {
          const body: Record<string, any> = {
            displayName,
          }
          if (code !== "-") {
            body.code = code
          }
          await editSpamAccount({
            id: data._id,
            body,
          })
        } else {
          await addSpamAccount({ provider: "google", code, displayName })
        }
        handleClose()
      } catch (e) {
        console.error(e)
        onErrorClose && onErrorClose()
      }
    },
  })
  const handleDisconnect = async () => {
    if (data) {
      await disconnectMail(data._id)
      setFieldValue("code", "")
    }
  }
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  const isLoading =
    addLoading || editLoading || disconnectLoading || clientIdLoading
  const submitDisabled = !dirty || !displayName || !code || isLoading
  return (
    <ExpandedModal
      id="GoogleSpamAccountModal"
      title={title}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      expanded={expanded}
      onCollapse={() => setExpanded(false)}
      paneContent={<GoogleLoginInstructions expanded={expanded} />}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            type="button"
            className="btn-sm text-black min-w-[86px]"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={submitDisabled}
            type="submit"
            className="btn-sm min-w-[86px]"
          >
            {submitBtnText}
          </Button>
        </div>
      }
      {...rest}
    >
      <div className="flex flex-col gap-5">
        <TextField
          className="input-sm"
          placeholder="Enter spam test account name"
          topLeftLabel="Account Name"
          {...getFieldProps("displayName")}
        />
        {clientIdLoading ? (
          <Loader className="mx-auto" />
        ) : (
          <>
            {data?.email && data.connected ? (
              <EmailConnectionStatus
                connected={data.connected}
                connectedTo={data.email}
                onDisconnect={handleDisconnect}
              />
            ) : code && code !== "-" ? (
              <EmailConnectionStatus connected connectedTo={null} />
            ) : (
              <GoogleOAuthLogin
                clientId={GSuiteAppIDSender}
                onSuccess={(response) => {
                  const code = response.code
                  setFieldValue("code", code)
                }}
                scope="https://mail.google.com https://www.googleapis.com/auth/userinfo.email"
              />
            )}
          </>
        )}
      </div>
    </ExpandedModal>
  )
}

export default AddEditGoogleSpamAccountModal
