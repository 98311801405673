import { FC, useEffect, useMemo, useState } from "react"
import cx from "classnames"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { FiArrowLeft as ArrowLeft } from "react-icons/fi"
import { usePageTitle } from "@/hooks/usePageTitle"
import Stepper from "@/components/Stepper"
import DomainFormView from "./features/DomainFormView"
import DomainVerifyingView from "./features/DomainVerifyingView"
import DomainGoogleDKIMView from "./features/DomainGoogleDKIMView"
import DomainMicrosoftDKIMView from "./features/DomainMicrosoftDKIMView"
import DomainDNSRecordsView from "./features/DomainDNSRecordsView"
import DomainDoneView from "./features/DomainDoneView"
import Button from "@/components/Button"
import OutlineButton from "@/components/OutlineButton"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import { useDomainSetup } from "./hooks/useDomainSetup"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import {
  selectDomainSetupEmailProvider,
  selectDomainSetupId,
  selectDomainSetupProvider,
  setDomainId,
} from "@/redux/domainSetupSlice"
import { useGetDomainQuery } from "@/redux/services/domainsApi"
import { setToast } from "@/redux/toastSlice"
import { useUserAccess } from "@/hooks/useUserAccess"
import { DomainsContext } from "./context"

type View = {
  element: JSX.Element
  label: string
  disabled: boolean
  displayed: boolean
}

type Props = {
  className?: string
}

const AddEditDomainPage: FC<Props> = ({ className, ...rest }: Props) => {
  const { domainId = "" } = useParams()
  const { pageFullAccess: domainsUpdateFullAccess } = useUserAccess({
    pageName: "Domains Update",
  })
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const [dkimNavigatedTo, setDkimNavigatedTo] = useState(false)
  const isEdit = Boolean(domainId) && location.pathname.includes("edit")
  const navigate = useNavigate()
  const provider = useAppSelector(selectDomainSetupProvider)
  const emailProvider = useAppSelector(selectDomainSetupEmailProvider)
  const storeDomainId = useAppSelector(selectDomainSetupId)
  const dispatch = useAppDispatch()
  const {
    data: domain,
    isLoading,
    isFetching,
    isError,
  } = useGetDomainQuery(
    { id: domainId || storeDomainId },
    { skip: !(domainId || storeDomainId), refetchOnMountOrArgChange: true },
  )
  const [initiallyCreating] = useState(searchParams.get("mode") !== "view")
  const [currentStep, setCurrentStep] = useState(0)
  const [currentStepDisabled, setCurrentStepDisabled] = useState(false)
  const {
    createDomain,
    setupDomain,
    resetState,
    setupLoading,
    setupDomainResponse,
  } = useDomainSetup()

  const handleDiscard = () => {
    resetState()
    navigate(isEdit ? "../.." : "..", { relative: "path" })
  }

  const handlePreviousClick = () => setCurrentStep((prev) => prev - 1)
  const handleContinueClick = async () => {
    if (currentStep === 0) {
      if (storeDomainId) {
        if (domain?.emailProvider) {
          setCurrentStep((prev) => prev + 1)
        } else if (setupDomainResponse.isError || !domain?.emailProvider) {
          const setupResponse = await setupDomain(storeDomainId)

          if (setupResponse) {
            setCurrentStep((prev) => prev + 1)
          }
        }
      } else {
        // check if domain wasn't created yet
        const createResponse = await createDomain()
        if (createResponse) {
          dispatch(setDomainId(createResponse._id))
          const setupResponse = await setupDomain(createResponse._id)

          if (setupResponse) {
            setCurrentStep((prev) => prev + 1)
          }
        }
      }
    } else if (currentStep !== 0) {
      setCurrentStep((prev) => prev + 1)
    }
  }
  const handleFinishClick = () => {
    dispatch(
      setToast({
        show: true,
        message: `Success add domain`,
        variant: "success",
      }),
    )
    navigate(isEdit ? "../.." : "..", { relative: "path" })
  }
  const isCreatingDomain = initiallyCreating
  usePageTitle(() => (
    <div className="flex gap-3 items-center">
      <div
        onClick={() => handleDiscard()}
        className="cursor-pointer rounded-full bg-bg-default flex justify-center items-center w-7 h-7"
      >
        <ArrowLeft className="opacity-80 w-[22px] h-[22px]" />
      </div>
      <span>
        {isEdit ? "Edit" : "Add"} Domain
        {isEdit && domain && `: ${domain?.name}`}
      </span>
    </div>
  ))
  const views = useMemo<NonNullable<View>[]>(
    () =>
      [
        {
          element: <DomainFormView onDisabledChange={setCurrentStepDisabled} />,
          label: "Domain",
          disabled: false,
          displayed: true,
        },
        provider === "other" || !isCreatingDomain
          ? {
              element: (
                <DomainDNSRecordsView
                  onDisabledChange={setCurrentStepDisabled}
                />
              ),
              label: "DNS Records",
              disabled: !domain?._id || !domain.emailProvider,
              displayed: true,
            }
          : null,
        {
          element: (
            <DomainVerifyingView onDisabledChange={setCurrentStepDisabled} />
          ),
          label: "Verifying",
          disabled: true,
          displayed: isCreatingDomain,
        },
        {
          element:
            emailProvider === "google" ? (
              <DomainGoogleDKIMView onDisabledChange={setCurrentStepDisabled} />
            ) : (
              <DomainMicrosoftDKIMView
                onDisabledChange={setCurrentStepDisabled}
              />
            ),
          label: "DKIM",
          disabled:
            !dkimNavigatedTo &&
            !(
              domain?.actions?.emailProviderVerifiedAt &&
              domain.actions.recordsVerifiedAt
            ),
          displayed: true,
        },
        {
          element: <DomainDoneView onDisabledChange={setCurrentStepDisabled} />,
          label: "Done",
          disabled: true,
          displayed: isCreatingDomain,
        },
      ].filter(Boolean) as NonNullable<View>[],
    [emailProvider, provider, domain, isCreatingDomain, dkimNavigatedTo],
  )
  const displayedViews = useMemo(
    () => views.filter(({ displayed }) => displayed),
    [views],
  )
  useEffect(() => {
    return () => {
      resetState()
    }
  }, [resetState])

  return (
    <>
      {isError ? (
        <ErrorMessage />
      ) : (
        <DomainsContext.Provider
          value={{ dkimNavigatedTo, setDkimNavigatedTo }}
        >
          <div
            className={cx("flex flex-col text-black h-full", className)}
            {...rest}
          >
            {(isLoading && !setupLoading) ||
            (isEdit && !provider && isFetching) ? (
              <div className="h-full flex justify-center items-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="flex-1 flex flex-col bg-white rounded-md block-shadow pt-10 pb-6">
                  <div className="px-10">
                    <Stepper
                      items={displayedViews.map(({ label, disabled }) => ({
                        title: label,
                        disabled,
                      }))}
                      current={currentStep}
                      onChange={setCurrentStep}
                      className={cx("max-w-[800px] mx-auto", {
                        "justify-center [&_.ant-steps-item]:max-w-[180px]":
                          true,
                      })}
                    />
                  </div>
                  <hr className="mt-4" />
                  <div className="px-6 pt-6">
                    {displayedViews[currentStep].element}
                  </div>
                </div>
                {domainsUpdateFullAccess && (
                  <div className="flex justify-end py-4">
                    <div className="flex gap-5">
                      {currentStep > 0 && (
                        <OutlineButton
                          onClick={handlePreviousClick}
                          className="bg-white btn-sm text-black"
                        >
                          Previous
                        </OutlineButton>
                      )}
                      <Button
                        onClick={
                          currentStep === views.length - 1
                            ? handleFinishClick
                            : handleContinueClick
                        }
                        className="btn-sm min-w-[79px]"
                        disabled={
                          (currentStepDisabled && !domain?.actions?.readyAt) ||
                          setupLoading ||
                          (!isCreatingDomain &&
                            currentStep === displayedViews.length - 1)
                        }
                        loading={setupLoading}
                      >
                        {currentStep === views.length - 1
                          ? "Finish"
                          : "Continue"}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </DomainsContext.Provider>
      )}
    </>
  )
}

export default AddEditDomainPage
