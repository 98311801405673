import { FC, useState } from "react"
import cx from "classnames"
import { useParams } from "react-router-dom"
import TextField from "@/components/TextField"
import { usePageTitle } from "@/hooks/usePageTitle"
import SearchIcon from "@/assets/search.svg"
import Button from "@/components/Button"
import AddIcon from "@/components/icons/AddIcon"
import EmptyList from "@/components/EmptyList"
import AddMasterListModal from "./features/AddMasterListModal"
import MasterListsTable from "./features/MasterListsTable"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import { usePagination } from "@/hooks/usePagination"
import { POLLING_INTERVAL } from "@/constants"
import { useGetMasterListsQuery } from "@/redux/services/masterListsApi"
import { useClientRect } from "@/hooks/useClientRect"
import { useUserAccess } from "@/hooks/useUserAccess"

const MasterListsPage: FC = () => {
  usePageTitle("Master Lists")
  const { pageFullAccess } = useUserAccess({ pageName: "Master Lists" })

  const { id: orgId = "" } = useParams()
  const [rect, ref] = useClientRect(true)
  const {
    limit,
    skip,
    text,
    searchTerm,
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
  } = usePagination()
  const {
    data: { items: masterLists, total } = { items: [], total: 0 },
    isLoading,
    isFetching,
    isError,
  } = useGetMasterListsQuery(
    { orgId, limit, skip, text },
    {
      skip: !orgId,
      pollingInterval: POLLING_INTERVAL,
      refetchOnMountOrArgChange: true,
    },
  )
  const [openAddMasterListModal, setOpenAddMasterListModal] = useState(false)

  const handleOpenAddMasterListModal = () => setOpenAddMasterListModal(true)

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex gap-1 justify-between">
          <TextField
            startIcon={<SearchIcon />}
            placeholder="Search master list"
            rootClassName="basis-56"
            className="input-sm !h-9"
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          {pageFullAccess && (
            <Button onClick={handleOpenAddMasterListModal} className="btn-base">
              <AddIcon className="w-6 h-6" />
              <span className="hidden sm:inline">Add Master List</span>
            </Button>
          )}
        </div>
        <div
          className={cx(
            "flex-1 overflow-y-auto mt-5 flex px-2 py-1 rounded-md block-shadow bg-white min-h-[380px]",
            { "justify-center items-center": !masterLists.length },
          )}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {masterLists?.length ? (
                <div ref={ref} className="w-full">
                  <MasterListsTable
                    className="w-full"
                    masterLists={masterLists}
                    pageSize={limit}
                    page={skip}
                    total={total}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    maxHeight={rect?.height ?? 0}
                    loading={isFetching}
                  />
                </div>
              ) : (
                <EmptyList message="There are no master lists yet">
                  {pageFullAccess && (
                    <Button
                      onClick={handleOpenAddMasterListModal}
                      className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                    >
                      <AddIcon className="transition-colors text-primary group-hover:text-white" />
                      <span className="transition-colors font-medium text-primary group-hover:text-white">
                        Add Master List
                      </span>
                    </Button>
                  )}
                </EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      <AddMasterListModal
        open={openAddMasterListModal}
        onClose={() => setOpenAddMasterListModal(false)}
      />
    </>
  )
}

export default MasterListsPage
