import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
  color: string
  label: React.ReactNode
}

const CircleLabel: FC<Props> = ({
  className,
  color,
  label,
  ...rest
}: Props) => {
  return (
    <div className={cx("flex gap-2.5 items-center", className)} {...rest}>
      <div
        className="rounded-full h-2.5 w-2.5"
        style={{ backgroundColor: color }}
      ></div>
      <span className="text-sm leading-6">{label}</span>
    </div>
  )
}

export default CircleLabel
