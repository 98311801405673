import { FC, memo } from "react"
import { Bar, ChartProps } from "react-chartjs-2"
import cx from "classnames"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { renderTooltip } from "./constants"

ChartJS.defaults.color = "rgba(38, 49, 63, 0.6)"
ChartJS.defaults.borderColor = "#E9EAF4"
ChartJS.defaults.font.size = 10
ChartJS.defaults.font.lineHeight = 1.5
ChartJS.defaults.font.family = "Inter, sans-serif"
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type Props = {
  className?: string
} & Omit<ChartProps<"bar">, "type">

const BarChart: FC<Props> = memo(
  ({ className, data, options, ...rest }: Props) => {
    return (
      <Bar
        options={{
          interaction: { mode: "index", intersect: false },
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: false,
              position: "nearest",
              external: renderTooltip,
              mode: "x",
            },
          },
          responsive: true,
          devicePixelRatio: 5.5, // for better resolution while zooming, possible performance issue
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: { display: false },
              ticks: {
                padding: 0,
                font: {
                  size: 11,
                },
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                tickLength: 2,
              },
            },
            yRight: {
              position: "right",
              display: true,
              beginAtZero: true,
              ticks: {
                display: false,
              },
              grid: {
                display: false,
              },
            },
          },
          ...options,
        }}
        data={data}
        className={cx(
          "[&+.react-chartjs-2-tooltip]:pointer-events-none",
          className,
        )}
        {...rest}
      />
    )
  },
)

export default BarChart
