import { FC, useMemo, useState } from "react"
import cx from "classnames"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import Table from "@/components/Table"
import ProspectsDeleteModal from "../ProspectsDeleteModal"
import ProspectsStatusColor from "../ProspectsStatus"
import ProspectsDetailsDrawer from "../ProspectsDetailsDrawer"
import Tooltip from "@/components/Tooltip"
import BlurredText from "@/components/BlurredText"
import { prospectsStatusNameMap } from "../../utils/constants"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useServerSideSorting } from "@/components/Table/useServerSideSorting"
import { usePagination } from "@/hooks/usePagination"
import type { Prospect } from "@/types/entities/prospect"
import type { ColumnsType } from "antd/es/table"
import type { TablePaginationProps } from "@/redux/services/types"

type Props = {
  className?: string
  prospects: Prospect[]
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  result: {
    label: string
    value: string
  }
  createdAt: string
  campaign: string
  firstName: string
  lastName: string
  location: string
  title: string
  companyName: string
  email: string
  classifiedAt: string
  classifications: string[]
}

const ProspectsTable: FC<Props> = ({
  className,
  prospects,
  selectedRowKeys,
  onRowSelect,
  ...rest
}: Props) => {
  const { sm } = useBreakpoints()
  const [deletedProspectId, setDeletedProspectId] = useState("")
  const [selectedProspectId, setSelectedProspectId] = useState("")
  const { pageFullAccess } = useUserAccess({ pageName: "Prospects" })
  const { mapServerSortedColumns } = useServerSideSorting()
  const { handleSortFieldChange } = usePagination()
  const dataSource = useMemo<TableDataType[]>(
    () =>
      prospects.map(
        ({
          _id,
          firstName,
          lastName,
          createdAt,
          email,
          campaign,
          status,
          companyName = "",
          location = "",
          title = "",
          classifiedAt,
          classifications,
          lastContactedAt,
        }) => ({
          key: _id,
          result: { label: prospectsStatusNameMap[status], value: status },
          campaign: campaign.name,
          createdAt: new Date(createdAt).toLocaleString(),
          firstName,
          lastName,
          location,
          title,
          companyName,
          email,
          classifications,
          classifiedAt: classifiedAt
            ? new Date(classifiedAt).toLocaleString()
            : "",
          lastContactedAt: lastContactedAt
            ? new Date(lastContactedAt).toLocaleString()
            : "",
        }),
      ),
    [prospects],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(() => {
    let resultCols: ColumnsType<TableDataType> = [
      {
        title: "Result",
        dataIndex: "result",
        key: "result",
        width: sm ? 150 : 90,
        fixed: "left",
        className: "border-r border-outline",
        render: ({ value, label }) => (
          <div className="flex gap-1.5 items-center">
            <ProspectsStatusColor status={value} />
            <span className="text-sm leading-6">{label}</span>
          </div>
        ),
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
        width: 148,
        sorter: true,
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
        width: 148,
        sorter: true,
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        width: 180,
        sorter: (a, b) => a.location.localeCompare(b.location),
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        width: 230,
        sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: "Company",
        dataIndex: "companyName",
        key: "companyName",
        sorter: (a, b) => a.companyName.localeCompare(b.companyName),
        width: 200,
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        className: "border-r border-outline",
        sorter: true,
        render: (v) => <BlurredText blur={!pageFullAccess} text={v} />,
        width: 225,
      },
      {
        title: "First Contacted",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        width: 185,
      },
      {
        title: "Responded",
        dataIndex: "classifiedAt",
        key: "classifiedAt",
        sorter: true,
        width: 185,
      },
      {
        title: "Last contacted",
        dataIndex: "lastContactedAt",
        key: "lastContactedAt",
        sorter: true,
        width: 185,
      },
      {
        title: "Classification",
        dataIndex: "classifications",
        key: "classifications",
        render: (value) => (
          <Tooltip placement="topLeft" title={value.join(", ")}>
            {value.join(", ")}
          </Tooltip>
        ),
        sorter: true,
        width: 200,
      },
      {
        title: "Campaign",
        dataIndex: "campaign",
        key: "campaign",
        sorter: (a, b) => a.campaign.localeCompare(b.campaign),
        width: 200,
      },
    ]
    if (pageFullAccess) {
      resultCols = resultCols.concat({
        title: "",
        dataIndex: "actions",
        key: "actions",
        className: "min-w-[56px]",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            <BinIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setDeletedProspectId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
            />
          </div>
        ),
        width: 56,
      })
    }
    return resultCols.map(mapServerSortedColumns)
  }, [pageFullAccess, sm, mapServerSortedColumns])

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        className={cx("", className)}
        rowSelection={
          pageFullAccess
            ? {
                type: "checkbox",
                selectedRowKeys,
                onChange: (selectedRowKeys) =>
                  onRowSelect && onRowSelect(selectedRowKeys),
              }
            : undefined
        }
        onRow={({ key }) => ({
          className: "cursor-pointer",
          onClick: () => setSelectedProspectId(key),
        })}
        truncate
        showSizeChanger
        onSortChange={handleSortFieldChange}
        {...rest}
      />
      <ProspectsDeleteModal
        open={!!deletedProspectId}
        onClose={() => setDeletedProspectId("")}
        data={
          deletedProspectId
            ? [prospects.find(({ _id }) => _id === deletedProspectId)!].filter(
                Boolean,
              )
            : []
        }
      />
      <ProspectsDetailsDrawer
        data={prospects.find(({ _id }) => _id === selectedProspectId)}
        open={!!selectedProspectId}
        onClose={() => setSelectedProspectId("")}
        blurEmail={!pageFullAccess}
      />
    </>
  )
}

export default ProspectsTable
