import { FC } from "react"
import cx from "classnames"
import { useFormik } from "formik"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import { useSwitchOrganizations } from "../../hooks/useSwitchOrganizations"
import Select from "@/components/Select"
import type { AdminUser } from "@/types/entities/admin-user"

type Props = {
  className?: string
  data: AdminUser[]
  options: AdminUser[]
  onTransfer?: () => void
} & ModalProps

const TransferAccountsModal: FC<Props> = ({
  className,
  open,
  data,
  onClose,
  onTransfer,
  options,
  ...rest
}: Props) => {
  const { switchOrganizations, response } = useSwitchOrganizations()
  const {
    handleSubmit,
    resetForm,
    values: { to },
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      to: "",
    },
    onSubmit: async ({ to }) => {
      try {
        await switchOrganizations({
          from: data.map(({ _id }) => _id),
          to,
        })
        handleClose()
        onTransfer && onTransfer()
      } catch (e) {
        console.error(e)
      }
    },
  })
  if (!data.length) {
    return null
  }
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  const title =
    data.length > 1
      ? `Transfer ${data.length} Active Accounts`
      : "Transfer Active Account"
  const userText =
    data.length > 1
      ? `${data.length} users`
      : `“${data[0].firstName} ${data[0].lastName}”`

  return (
    <Modal
      title={title}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={handleClose}
            className="text-black btn-sm min-w-[86px]"
            type="button"
            disabled={response.isLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={response.isLoading || !to}
            className="btn-sm min-w-[96px]"
            type="submit"
          >
            Confirm
          </Button>
        </div>
      }
      {...rest}
      boxClassName="overflow-y-visible [&_.modal-action]:rounded-b-2xl"
    >
      <input className="w-0 h-0" type="text" tabIndex={0} />
      <span className="text-base leading-6">
        Which user should get <span className="font-semibold">{userText}</span>{" "}
        organization?
      </span>
      <Select
        className="h-10 rounded-md bg-input mt-4"
        options={options.map(({ _id, firstName, lastName }) => ({
          label: `${firstName} ${lastName}`,
          value: _id,
        }))}
        bordered={false}
        getPopupContainer={(node) => node.parentNode}
        value={to || null}
        onChange={(v) => setFieldValue("to", v)}
        placeholder="Select user"
      />
    </Modal>
  )
}

export default TransferAccountsModal
