import { FC, useCallback, useEffect, useMemo, useState } from "react"
import cx from "classnames"
import { MdCheck as CheckIcon } from "react-icons/md"
import Textarea from "@/components/Textarea"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import IconButton from "@/components/IconButton"
import CopyIcon from "@/components/icons/CopyIcon"
import Tooltip from "@/components/Tooltip"
import { useAppSelector } from "@/redux/hooks"
import {
  selectDomainSetupDKIM,
  selectDomainSetupId,
  selectDomainSetupProvider,
} from "@/redux/domainSetupSlice"
import { useDomainSetup } from "../../hooks/useDomainSetup"
import { useDomainAddExtra } from "../../hooks/useDomainAddExtra"
import {
  useGetDomainQuery,
  useVerifyDomainMutation,
} from "@/redux/services/domainsApi"
import { useManualCopy } from "@/hooks/useManualCopy"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useDomainsContext } from "../../hooks/useDomainsContext"

type Props = {
  className?: string
  onDisabledChange: (disabled: boolean) => void
}

const DomainGoogleDKIMView: FC<Props> = ({
  className,
  onDisabledChange,
  ...rest
}: Props) => {
  const { pageFullAccess: domainsUpdateFullAccess } = useUserAccess({
    pageName: "Domains Update",
  })
  const { setDkimNavigatedTo } = useDomainsContext()
  const DKIM = useAppSelector(selectDomainSetupDKIM)
  const domainId = useAppSelector(selectDomainSetupId)
  const provider = useAppSelector(selectDomainSetupProvider)
  const [, copy] = useManualCopy({
    successMessage: "Value copied successfully",
  })
  const [, emailCopy] = useManualCopy({
    successMessage: "Email copied successfully",
  })
  const {
    addExtraDomain,
    response: {
      isError: addExtraError,
      isLoading: addExtraLoading,
      data: addExtraData,
    },
  } = useDomainAddExtra()
  const [
    verifyDomain,
    { isError: verifyError, isLoading: verifyLoading, data: verifyData },
  ] = useVerifyDomainMutation()
  const { data: domain } = useGetDomainQuery(
    { id: domainId },
    { skip: !domainId },
  )
  const [errorHappened, setErrorHappened] = useState(false)
  const [verifyCalled, setVerifyCalled] = useState(false)

  const { changeDKIM } = useDomainSetup()
  const handleAddExtraDomain = async () => {
    await addExtraDomain()
    setVerifyCalled(true)
  }
  const handleRetryVerify = useCallback(async () => {
    try {
      const verifyResponse = await verifyDomain({
        id: domainId,
        type: "dnsRecords",
      }).unwrap()
      if (verifyResponse.domain.actions?.readyAt) {
        onDisabledChange(false)
      }
    } catch (e) {
      console.error(e)
    }
  }, [domainId, onDisabledChange, verifyDomain])
  useEffect(() => {
    setDkimNavigatedTo(true)
  }, [setDkimNavigatedTo])
  useEffect(() => {
    if (verifyError || addExtraError) {
      setErrorHappened(true)
    }
  }, [verifyError, addExtraError])
  const retryDisplayed = useMemo(
    () =>
      !domain?.actions?.readyAt &&
      (errorHappened ||
        verifyCalled ||
        (!verifyData?.success && Boolean(domain?.extra?.DKIM.address))),
    [
      domain?.actions?.readyAt,
      domain?.extra?.DKIM.address,
      errorHappened,
      verifyCalled,
      verifyData?.success,
    ],
  )
  useEffect(() => {
    let intervalId: number
    if (retryDisplayed) {
      intervalId = setInterval(async () => {
        await handleRetryVerify()
      }, 60000) // 1 minute
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [handleRetryVerify, retryDisplayed])
  useEffect(() => {
    onDisabledChange(Boolean(!domain?.actions?.readyAt))
  }, [domain?.actions?.readyAt, onDisabledChange])
  const activateDisabled =
    !DKIM ||
    addExtraLoading ||
    verifyLoading ||
    (Boolean(domain?.actions?.readyAt) && Boolean(domain?.extra?.DKIM.address))
  const retryDisabled = verifyLoading
  const isManualSetup = provider === "other"

  return (
    <div className={cx("flex flex-col gap-5", className)} {...rest}>
      <h2 className="text-lg font-semibold leading-7">DKIM</h2>
      <div className="flex flex-col lg:flex-row gap-5">
        <div className="flex-1 space-y-2">
          <h4 className="text-[#000] text-sm font-semibold leading-6">
            Instructions:
          </h4>
          <ol className="pl-4 space-y-1.5 list-decimal list-outside text-sm leading-6 [&>li]:pl-2.5 marker:text-[#26313f87]">
            <li>
              Please go to{" "}
              <a
                className="text-primary underline break-all"
                target="_blank"
                href="https://admin.google.com/ac/apps/gmail/authenticateemail?hl=en"
              >
                https://admin.google.com/ac/apps/gmail/authenticateemail?hl=en
              </a>{" "}
              and login with{" "}
              <span className="font-semibold inline-flex items-center gap-1 break-all">
                {domain?.admin?.email ?? "your email"}
                <Tooltip title="Copy Email" placement="right">
                  <IconButton
                    onClick={() => emailCopy(domain?.admin?.email ?? "")}
                  >
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
              </span>
            </li>
            <li>
              Select the{" "}
              <span className="font-semibold break-all">
                {domain?.name ?? "current domain"}
              </span>{" "}
              from the drop down list and click Generate Record. Copy the TXT
              record and paste it here
            </li>
            {isManualSetup ? (
              <>
                <li>Click Get DNS Record</li>
                <li>Set this record with your DNS provider & click Verify</li>
              </>
            ) : (
              <>
                <li>Click Activate & click Verify</li>
              </>
            )}
            <li>Go back to Google and press START AUTHENTICATION</li>
            <li>Wait for it to start Authenticating before finishing</li>
          </ol>
        </div>
        <div className="border-[0.5px] border-outline"></div>
        <div
          className={cx("flex-1 flex flex-col gap-1.5", {
            faded: !domainsUpdateFullAccess,
          })}
        >
          <h4 className="text-xs font-medium leading-normal">
            DKIM TXT Record
          </h4>
          <Textarea
            value={DKIM}
            onChange={({ target: { value } }) => changeDKIM(value)}
            className="h-48 resize-none text-sm leading-6 !px-3 !py-2"
            readOnly={Boolean(
              domain?.extra?.DKIM.address && domain.actions?.readyAt,
            )}
          />
          <div className="mt-2.5 flex gap-3 items-center font-semibold">
            <Button
              onClick={handleAddExtraDomain}
              className="btn-primary btn-xs"
              disabled={activateDisabled}
              loading={addExtraLoading && !Boolean(domain?.extra?.DKIM.address)}
            >
              <span>{isManualSetup ? "Get DNS Record" : "Activate"}</span>
            </Button>
            {retryDisplayed && (
              <OutlineButton
                onClick={handleRetryVerify}
                className="bg-white btn-xs text-black"
                disabled={retryDisabled}
                loading={verifyLoading}
              >
                Verify
              </OutlineButton>
            )}
            {domain?.actions?.readyAt && (
              <CheckIcon className="w-5 h-5 text-success" />
            )}
          </div>
          {isManualSetup && addExtraData?.extra?.DKIM && (
            <table
              cellPadding={12}
              className="w-full border-collapse table-fixed"
            >
              <thead className="text-xs leading-[22px] font-semibold">
                <tr className="[&>th]:text-start border-b border-outline-dark">
                  <th className="w-15">Type</th>
                  <th className="w-44">Host</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody className="text-sm leading-6 p-3">
                <tr className="border-b border-outline">
                  <td>{addExtraData.extra.DKIM.type}</td>
                  <td>{addExtraData.extra.DKIM.host}</td>
                  <td>
                    <div className="flex">
                      <div className="truncate inline-block w-full">
                        {addExtraData.extra.DKIM.address}
                      </div>
                      <IconButton
                        onClick={() =>
                          copy(addExtraData.extra?.DKIM.address ?? "")
                        }
                        className="inline-block"
                      >
                        <CopyIcon />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

export default DomainGoogleDKIMView
