import { useParams } from "react-router-dom"
import { useAddCampaignMutation } from "@/redux/services/campaignsApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"

export const useAddCampaign = () => {
  const dispatch = useAppDispatch()
  const [addCampaign, response] = useAddCampaignMutation()
  const { id: orgId = "" } = useParams()

  const handleAddCampaign = async ({ name }: { name: string }) => {
    try {
      const newCampaign = await addCampaign({
        name,
        orgId,
      }).unwrap()
      if (newCampaign.data) {
        return newCampaign.data
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { addCampaign: handleAddCampaign, response }
}
