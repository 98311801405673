import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import type { RootState } from "./store"
import type { DomainMissing } from "@/types/entities/domain"

export type DomainSetupState = {
  _id: string
  name: string
  urlRedirect: string
  provider: string
  emailProvider: string
  DKIM: string
  missing: DomainMissing[]
}

const initialState: DomainSetupState = {
  _id: "",
  name: "",
  urlRedirect: "",
  provider: "",
  emailProvider: "",
  DKIM: "",
  missing: [],
}

const domainSetupSlice = createSlice({
  name: "domainSetup",
  initialState,
  reducers: {
    setDomainId: (state, { payload }: PayloadAction<string>) => {
      state._id = payload
    },
    setName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload
    },
    setUrlRedirect: (state, { payload }: PayloadAction<string>) => {
      state.urlRedirect = payload
    },
    setProvider: (state, { payload }: PayloadAction<string>) => {
      state.provider = payload
    },
    setEmailProvider: (state, { payload }: PayloadAction<string>) => {
      state.emailProvider = payload
    },
    setDKIM: (state, { payload }: PayloadAction<string>) => {
      state.DKIM = payload
    },
    setMissing: (state, { payload }: PayloadAction<DomainMissing[]>) => {
      state.missing = payload
    },
    resetDomain: () => initialState,
  },
})

export const selectDomainSetupId = (state: RootState) => state.domainSetup._id
export const selectDomainSetupName = (state: RootState) =>
  state.domainSetup.name
export const selectDomainSetupUrlRedirect = (state: RootState) =>
  state.domainSetup.urlRedirect
export const selectDomainSetupProvider = (state: RootState) =>
  state.domainSetup.provider
export const selectDomainSetupEmailProvider = (state: RootState) =>
  state.domainSetup.emailProvider
export const selectDomainSetupDKIM = (state: RootState) =>
  state.domainSetup.DKIM
export const selectDomainSetupMissing = (state: RootState) =>
  state.domainSetup.missing

export const {
  setName,
  setUrlRedirect,
  setDKIM,
  setEmailProvider,
  setProvider,
  setDomainId,
  setMissing,
  resetDomain,
} = domainSetupSlice.actions

export default domainSetupSlice.reducer
