import { useParams } from "react-router-dom"
import { useAddMasterListMutation } from "@/redux/services/masterListsApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"

export const useAddMasterList = () => {
  const dispatch = useAppDispatch()
  const [addMasterList, response] = useAddMasterListMutation()
  const { id: orgId = "" } = useParams()

  const handleAddMasterList = async ({
    name,
    records,
  }: {
    name: string
    records: unknown[]
  }) => {
    try {
      const response = await addMasterList({
        body: {
          name,
          orgId,
          records,
        },
      }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success add master list",
        }),
      )
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { addMasterList: handleAddMasterList, response }
}
