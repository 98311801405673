import { FC, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import cx from "classnames"
import TextField from "@/components/TextField"
import SearchIcon from "@/assets/search.svg"
import Button from "@/components/Button"
import EmptyList from "@/components/EmptyList"
import { usePageTitle } from "@/hooks/usePageTitle"
import { useGetCampaignsQuery } from "@/redux/services/campaignsApi"
import AddCampaignModal from "./features/AddCampaignModal"
import { useAppDispatch } from "@/redux/hooks"
import { setCampaignSetupName } from "@/redux/campaignSetupSlice"
import ErrorMessage from "@/components/ErrorMessage"
import CampaignsTable from "./features/CampaignsTable"
import Loader from "@/components/Loader"
import AddIcon from "@/components/icons/AddIcon"
import { usePagination } from "@/hooks/usePagination"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import { POLLING_INTERVAL } from "@/constants"
import { useClientRect } from "@/hooks/useClientRect"
import { useUserAccess } from "@/hooks/useUserAccess"
import type { Campaign } from "@/types/entities/campaign"

const CampaignsPage: FC = () => {
  usePageTitle("Campaigns")
  const { pageFullAccess: campaignsUpdateFullAccess } = useUserAccess({
    pageName: "Campaigns Update",
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [rect, ref] = useClientRect(true)
  const { id: orgId = "" } = useParams()
  const {
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
    skip,
    limit,
    searchTerm,
    text,
  } = usePagination()
  const {
    data: { items: campaigns, total } = { items: [], total: 0 },
    isFetching,
    isLoading,
    isError,
  } = useGetCampaignsQuery(
    { orgId, limit, skip, text },
    { pollingInterval: POLLING_INTERVAL, refetchOnMountOrArgChange: true },
  )
  const { isLoading: systemValuesLoading } = useGetSystemValuesQuery()
  const [openAddCampaignModal, setOpenAddCampaignModal] = useState(false)

  const handleOpenAddModal = () => setOpenAddCampaignModal(true)
  const goToSetupPage = ({ name, data }: { name: string; data?: Campaign }) => {
    dispatch(setCampaignSetupName(name))
    if (data?._id) {
      navigate(`edit/${data._id}`)
    }
  }

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className="h-full flex flex-col [&_button]:!h-9 [&_button]:!py-1.5 [&_button_span]:leading-6">
        <div className="flex justify-between items-center gap-1">
          <TextField
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Search campaign"
            startIcon={<SearchIcon className="w-6 h-6" />}
            className="text-sm outline outline-outline !h-9 !py-1.5"
            rootClassName="basis-56"
          />
          {campaignsUpdateFullAccess && (
            <Button
              onClick={handleOpenAddModal}
              className="btn-primary btn-sm !h-9 !py-1.5"
            >
              <AddIcon />
              <span className="hidden sm:inline">Create Campaign</span>
            </Button>
          )}
        </div>
        <div
          className={cx(
            "flex-1 overflow-y-auto mt-5 flex bg-white rounded-md shadow-[0px_1px_4px_0px_rgba(0,0,0,0.06)] px-2 py-1 min-h-[292px]",
            { "justify-center items-center": !campaigns.length },
          )}
        >
          {isLoading || systemValuesLoading ? (
            <Loader />
          ) : (
            <>
              {campaigns.length ? (
                <div ref={ref} className="w-full">
                  <CampaignsTable
                    showHeader={campaigns.length > 0}
                    campaigns={campaigns}
                    pageSize={limit}
                    page={skip}
                    total={total}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    maxHeight={rect?.height ?? 0}
                    loading={isFetching}
                  />
                </div>
              ) : (
                <EmptyList message="There are no campaigns yet">
                  {campaignsUpdateFullAccess && (
                    <Button
                      onClick={handleOpenAddModal}
                      className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                    >
                      <AddIcon className="transition-colors text-primary group-hover:text-white" />
                      <span className="transition-colors font-medium text-primary group-hover:text-white">
                        Create Campaign
                      </span>
                    </Button>
                  )}
                </EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      <AddCampaignModal
        open={openAddCampaignModal}
        onClose={() => setOpenAddCampaignModal(false)}
        onConfirm={goToSetupPage}
      />
    </>
  )
}

export default CampaignsPage
