import { FC, useState } from "react"
import cx from "classnames"
import { getCategoryStrings } from "@/pages/ReplyClassification/utils"
import { getCategoryStringFormatted } from "../utils"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import type { AiPrompt } from "@/types/entities/ai-prompt"

type Props = {
  className?: string
  prompt: AiPrompt
}

const SubqueryCategory: FC<Props> = ({ prompt }: Props) => {
  const { data: { classificationActions } = { classificationActions: [] } } =
    useGetSystemValuesQuery()
  const [titleOpen, setTitleOpen] = useState(false)

  return (
    <div className="small-shadow flex flex-col rounded-[4px] bg-white overflow-hidden border border-outline">
      <div
        className={cx(
          "cursor-pointer text-sm font-medium leading-[30px] bg-white px-3 py-2",
          {
            truncate: !titleOpen,
            "whitespace-pre-wrap": titleOpen,
          },
        )}
        onClick={() => setTitleOpen((prev) => !prev)}
      >
        {prompt.text}
      </div>
      <div className="p-2 pl-6 flex flex-col gap-2 bg-[#F8F8F8]">
        {getCategoryStrings(prompt.text)
          .map((category, i) =>
            getCategoryStringFormatted(
              category,
              prompt,
              i,
              classificationActions,
            ),
          )
          .map((subqueryCategoryString, subqueryCategoryI) => (
            <div
              key={subqueryCategoryI}
              className="rounded-sm py-2 px-3 border border-outline bg-white font-medium text-sm leading-6 text-[#26313FCC]"
            >
              {subqueryCategoryString}
            </div>
          ))}
      </div>
    </div>
  )
}

export default SubqueryCategory
