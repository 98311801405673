import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import { buildQueryString } from "@/utils/strings"
import type { PaginationProps } from "./types"
import type { AdminUser } from "@/types/entities/admin-user"

export const adminUsersApi = createApi({
  reducerPath: "adminUsersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Admin Users"],
  endpoints: (builder) => ({
    getAdminUsers: builder.query<
      { items: AdminUser[]; total: number },
      PaginationProps & { active?: boolean }
    >({
      query: ({ ...keys }) => ({
        method: "GET",
        url: `admin/user/all?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: AdminUser[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Admin Users" as const,
                _id,
              })),
              { type: "Admin Users", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Admin Users", id: "PARTIAL-LIST" }],
    }),
    inviteUser: builder.mutation<
      AdminUser,
      {
        firstName: string
        lastName: string
        email: string
      }
    >({
      query: (body) => ({
        method: "POST",
        url: "admin/user",
        body,
      }),
      invalidatesTags: ["Admin Users"],
      transformResponse: ({ data }: { data: AdminUser }) => data,
    }),
    editAdminUser: builder.mutation<
      AdminUser,
      {
        body: Partial<{
          firstName: string
          lastName: string
          email: string
          removePendingEmail: boolean
        }>
        id: string
      }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `admin/user/${id}`,
        body,
      }),
      invalidatesTags: ["Admin Users"],
      transformResponse: ({ data }: { data: AdminUser }) => data,
    }),
    deleteAdminUser: builder.mutation<
      AdminUser,
      {
        id: string
      }
    >({
      query: ({ id }) => ({
        method: "DELETE",
        url: `admin/user/${id}`,
      }),
      invalidatesTags: ["Admin Users"],
      transformResponse: ({ data }: { data: AdminUser }) => data,
    }),
    switchOrganizations: builder.mutation<
      unknown,
      { from: string[]; to: string }
    >({
      query: (body) => ({
        url: "admin/org/switch",
        method: "PUT",
        body,
      }),
    }),
    setUserStatus: builder.mutation<
      { success: boolean },
      { body: { status: "enabled" | "disabled" }; id: string }
    >({
      query: ({ body, id }) => ({
        url: `admin/user/${id}/status`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Admin Users"],
    }),
  }),
})

export const {
  useGetAdminUsersQuery,
  useInviteUserMutation,
  useEditAdminUserMutation,
  useDeleteAdminUserMutation,
  useSwitchOrganizationsMutation,
  useSetUserStatusMutation,
} = adminUsersApi
