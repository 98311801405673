import { useCopyToClipboard } from "usehooks-ts"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"

type Props = {
  successMessage?: string
}
export const useManualCopy = ({
  successMessage = "Success Copy",
}: Props): [string | null, (text: string) => Promise<void>] => {
  const dispatch = useAppDispatch()
  const [copiedText, copy] = useCopyToClipboard()
  const handleCopy = async (text: string) => {
    const copySuccess = await copy(text)
    if (copySuccess) {
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: successMessage,
        }),
      )
    }
  }
  return [copiedText, handleCopy]
}
