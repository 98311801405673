import { useState } from "react"
import { useParams } from "react-router-dom"
import cx from "classnames"
import { usePageTitle } from "@/hooks/usePageTitle"
import TextField from "@/components/TextField"
import SearchIcon from "@/assets/search.svg"
import AddIcon from "@/components/icons/AddIcon"
import Button from "@/components/Button"
import EmptyList from "@/components/EmptyList"
import { useGetSendersQuery } from "@/redux/services/sendersApi"
import AddEditSenderModal from "./features/AddEditSenderModal"
import SendersList from "./features/SendersList"
import { usePagination } from "@/hooks/usePagination"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import { useClientRect } from "@/hooks/useClientRect"
import { useUserAccess } from "@/hooks/useUserAccess"

const SendersPage = () => {
  usePageTitle("Senders")
  const { pageFullAccess } = useUserAccess({ pageName: "Senders" })
  const { id = "" } = useParams()
  const [rect, ref] = useClientRect(true)
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const {
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
    searchTerm,
    skip,
    limit,
    text,
  } = usePagination()
  const {
    data: { items: senders, total } = { items: [], total: 0 },
    isLoading,
    isFetching,
    isError,
  } = useGetSendersQuery(
    {
      orgId: id,
      skip,
      limit,
      text,
    },
    { refetchOnMountOrArgChange: true },
  )

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className="flex flex-col h-full [&_button]:!h-9 [&_button]:!py-1.5 [&_button_span]:leading-6">
        <div className="flex justify-between items-center gap-1">
          <TextField
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Search sender"
            startIcon={<SearchIcon className="w-6 h-6" />}
            className="text-sm outline outline-outline !h-9 !py-1.5"
            rootClassName="basis-56"
          />
          {pageFullAccess && (
            <Button
              onClick={() => setOpenAddEditModal(true)}
              className="btn-primary btn-sm !h-9 !py-1.5"
            >
              <AddIcon />
              <span className="hidden sm:inline">Add Sender</span>
            </Button>
          )}
        </div>
        <div
          className={cx(
            "flex-1 overflow-y-auto mt-5 flex bg-white rounded-md shadow-[0px_1px_4px_0px_rgba(0,0,0,0.06)] px-2 py-1 min-h-[292px]",
            {
              "justify-center items-center": !senders.length,
            },
          )}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {senders.length ? (
                <div ref={ref} className="w-full">
                  <SendersList
                    page={skip}
                    total={total}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    senders={senders}
                    maxHeight={rect?.height ?? 0}
                    loading={isFetching}
                  />
                </div>
              ) : (
                <EmptyList message="There are no senders yet">
                  {pageFullAccess && (
                    <Button
                      onClick={() => setOpenAddEditModal(true)}
                      className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                    >
                      <AddIcon className="transition-colors text-primary group-hover:text-white" />
                      <span className="transition-colors font-medium text-primary group-hover:text-white">
                        Add Sender
                      </span>
                    </Button>
                  )}
                </EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      <AddEditSenderModal
        open={openAddEditModal}
        onClose={() => setOpenAddEditModal(false)}
      />
    </>
  )
}

export default SendersPage
