import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import { useDeleteDomain } from "../../hooks/useDomainDelete"
import type { Domain } from "@/types/entities/domain"

type Props = {
  className?: string
  data?: Domain
} & ModalProps

const DeleteDomainModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const {
    deleteDomain,
    response: { isLoading },
  } = useDeleteDomain()
  if (!data) {
    return null
  }
  const handleSubmit = async () => {
    await deleteDomain(data._id)
    onClose && onClose()
  }
  return (
    <Modal
      title="Delete Domain"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <CancelButton onClick={onClose} disabled={isLoading} />
          <DeleteButton disabled={isLoading} />
        </div>
      }
      {...rest}
    >
      <span className="text-base leading-6">
        Are you sure you want to delete{" "}
        <span className="font-semibold">“{data.name}”</span>?
      </span>
    </Modal>
  )
}

export default DeleteDomainModal
