import LoginForm from "./features/LoginForm"

const LoginPage = () => {
  return (
    <div className="flex justify-center">
      <div className="mt-16 flex flex-col gap-6 items-center max-w-[400px] w-full">
        <h3 className="text-center text-black text-xl sm:text-2xl font-semibold">
          Sign In to Your Account
        </h3>
        <LoginForm />
      </div>
    </div>
  )
}

export default LoginPage
