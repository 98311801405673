import { Link } from "react-router-dom"
import Button from "@/components/Button"
import { Routes } from "@/core/routing/routes"
import EmailSentIcon from "@/assets/email-sent.svg"

const ForgotPasswordSent = () => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-5 items-center">
        <EmailSentIcon className="w-20 h-20" />
        <h4 className="font-semibold text-center text-xl sm:text-2xl">
          Forgot Password
        </h4>
        <div className="text-sm mt-2.5 mb-3 text-center">
          A link to reset your password has been sent to your email.
        </div>
      </div>
      <Link to={Routes.login}>
        <Button fullWidth>Back to Login</Button>
      </Link>
    </div>
  )
}

export default ForgotPasswordSent
