import { FC } from "react"
import cx from "classnames"
import { Radio, RadioGroupProps } from "antd"

type Props = {
  className?: string
  children: React.ReactNode
} & RadioGroupProps

const RadioGroup: FC<Props> = ({ className, children, ...rest }: Props) => {
  return (
    <Radio.Group className={cx("", className)} {...rest}>
      {children}
    </Radio.Group>
  )
}

export default RadioGroup
