import { FC } from "react"
import cx from "classnames"
import image2 from "../img/image 2.png"
import image3 from "../img/image 3.png"
import image4 from "../img/image 4.png"
import image5 from "../img/image 5.png"
import image6 from "../img/image 6.png"
import image7 from "../img/image 7.png"
import image8 from "../img/image 8.png"
import image9 from "../img/image 9.png"

type Props = {
  className?: string
}

const GoogleLoginInstructions: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <div className={cx("overflow-x-hidden", className)} {...rest}>
      <h3 className="text-base font-semibold">
        Connecting Google accounts via Google oAuth method
      </h3>
      <ol className="mt-3 list-decimal list-inside text-[13px] leading-[22px] text-black-secondary space-y-2">
        <li>
          Go to <u>Admin console</u> (Security/API Controls/App Access Control)
        </li>
        <li>Click on View list, and then Add App:</li>
        <img src={image2} alt="google-login-instruction-2" />
        <li>Select OAuth App Name Or Client ID</li>
        <img className="w-40" src={image3} alt="google-login-instruction-3" />
        <li>
          Copy the ID from your DreamSender account, by going to <b>Add New</b>,
          choose <b>Google/Gsuite</b>, select Yes, <b>IMAP has been enabled</b>,
          choose <b>Option 2 oAuth</b>.
        </li>
        <img src={image4} alt="google-login-instruction-4" />
        <li>Paste the client ID into your Google Workspace and click Search</li>
        <img src={image5} alt="google-login-instruction-5" />
        <li>Select DreamSender:</li>
        <img src={image6} alt="google-login-instruction-6" />
        <li>Check the box and click on Select</li>
        <img src={image7} alt="google-login-instruction-7" />
        <li>Select Trusted and click on Configure</li>
        <img src={image8} alt="google-login-instruction-8" />
        <li>
          DreamSender oAuth v1 will appear in the list of your trusted apps.
        </li>
        <img src={image9} alt="google-login-instruction-9" />
      </ol>
    </div>
  )
}

export default GoogleLoginInstructions
