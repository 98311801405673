import { FC, useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import cx from "classnames"
import Table from "@/components/Table"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import ManageAccountsIcon from "@/assets/manage-accounts.svg"
import Switch from "@/components/Switch"
import Avatar from "@/components/Avatar"
import InviteEditUserModal from "../InviteEditUserModal"
import DeleteAdminUserModal from "../DeleteAdminUserModal"
import TransferAccountsModal from "../TransferAccountsModal"
import { Routes } from "@/core/routing/routes"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { useSetUserStatus } from "../../hooks/useSetUserStatus"
import { DATE_FORMAT_OPTIONS } from "@/constants"
import type { AdminUser } from "@/types/entities/admin-user"
import type { TablePaginationProps } from "@/redux/services/types"
import type { ColumnsType } from "antd/es/table"

type Props = {
  className?: string
  users: AdminUser[]
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  name: string
  email: string
  status: boolean
  admin: boolean
  createdAt: string
}

const AdminUsersTable: FC<Props> = ({
  className,
  users,
  onRowSelect,
  selectedRowKeys,
  ...rest
}: Props) => {
  const navigate = useNavigate()
  const { sm } = useBreakpoints()
  const [editedUserId, setEditedUserId] = useState("")
  const [transferedUserId, setTransferedUserId] = useState("")
  const [deletedUserId, setDeletedUserId] = useState("")
  const { setUserStatus } = useSetUserStatus()
  const handleChangeStatus = useCallback(
    (id: string) => {
      return async (v: boolean) => {
        await setUserStatus({
          id,
          body: { status: v ? "enabled" : "disabled" },
        })
      }
    },
    [setUserStatus],
  )
  const dataSource = useMemo<TableDataType[]>(
    () =>
      users.map(
        ({ _id, firstName, lastName, createdAt, email, admin, status }) => ({
          key: _id,
          name: `${firstName} ${lastName}`,
          createdAt: createdAt
            ? new Date(createdAt).toLocaleDateString(
                "en-US",
                DATE_FORMAT_OPTIONS,
              )
            : "",
          status: status === "enabled",
          admin,
          email,
        }),
      ),
    [users],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(
    () => [
      {
        title: "User Name",
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (value: string) => (
          <div className="group flex gap-2 items-center cursor-pointer">
            <Avatar className="[&>div]:bg-outline-dark">
              <span className="opacity-60 font-medium text-xs leading-normal">
                {`${value
                  .split(" ")
                  .slice(0, 2)
                  .map((v) => (v.length ? v[0].toUpperCase() : v))
                  .join("")}`}
              </span>
            </Avatar>
            <span className="group-hover:text-primary">{value}</span>
          </div>
        ),
        onCell: ({ key }) => ({
          className: "hover:text-primary cursor-pointer",
          onClick: () =>
            navigate({
              pathname: Routes.organizations,
              search: `owner=${key}`,
            }),
        }),
      },
      {
        title: "Email Address",
        key: "email",
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        sorter: (a, b) => +a.status - +b.status,
        render: (value, { key }) => (
          <Switch checked={value} onChange={handleChangeStatus(key)} />
        ),
      },
      {
        title: "User Type",
        key: "admin",
        dataIndex: "admin",
        sorter: (a, b) => +a.admin - +b.admin,
        render: (admin) => (admin ? "Admin" : "User"),
      },
      {
        title: "Date Added",
        key: "createdAt",
        dataIndex: "createdAt",
        sorter: (a, b) => +new Date(a.createdAt) - +new Date(b.createdAt),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        className: "min-w-[136px]",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            <div className="group">
              <ManageAccountsIcon
                onClick={(e: MouseEvent) => {
                  e.stopPropagation()
                  setTransferedUserId(key)
                }}
                className="w-6 h-6 cursor-pointer active:scale-90 group-hover:[&>g>g>path]:fill-primary"
              />
            </div>
            <EditIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setEditedUserId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer active:scale-90 hover:text-primary transition-colors"
            />
            <BinIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setDeletedUserId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer  active:scale-90 hover:text-primary transition-colors"
            />
          </div>
        ),
        width: 136,
      },
    ],
    [handleChangeStatus, navigate, sm],
  )
  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        rootClassName="w-full"
        className={cx("", className)}
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys) =>
            onRowSelect && onRowSelect(selectedRowKeys),
          selectedRowKeys,
        }}
        showSizeChanger
        {...rest}
      ></Table>
      <InviteEditUserModal
        open={!!editedUserId}
        data={users.find(({ _id }) => _id === editedUserId)}
        onClose={() => setEditedUserId("")}
      />
      <DeleteAdminUserModal
        open={!!deletedUserId}
        data={[users.find(({ _id }) => _id === deletedUserId)!].filter(Boolean)}
        onClose={() => setDeletedUserId("")}
      />
      <TransferAccountsModal
        open={!!transferedUserId}
        options={users}
        data={[users.find(({ _id }) => _id === transferedUserId)!].filter(
          Boolean,
        )}
        onClose={() => setTransferedUserId("")}
      />
    </>
  )
}

export default AdminUsersTable
