import { object, string } from "yup"
import { IPV_4_REGEX, PORT_REGEX } from "@/utils/regex"

export default object({
  ip: string().required().matches(IPV_4_REGEX),
  port: string().required().matches(PORT_REGEX),
  username: string().required(),
  password: string().required(),
  region: string().required(),
})
