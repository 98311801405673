import { FC } from "react"
import { sortIndicatorsFirst } from "../../utils"
import { getChartColorByKey } from "@/components/BarChart/constants"
import { useDashboard } from "../../hooks/useDashboard"
import type {
  ReportingEmailTotals,
  ReportingRepliesTotals,
} from "@/types/entities/organization"

type Props = {
  className?: string
  indicators: string[]
  totalData: ReportingEmailTotals
  totalReplyData: ReportingRepliesTotals
}

const DashboardIndicatorsList: FC<Props> = ({
  indicators,
  totalData,
  totalReplyData,
}: Props) => {
  const { classificationsValue, indicatorsValue } = useDashboard()
  const classificationLabels = classificationsValue.map(({ label }) => ({
    label: label,
    value: label.toLowerCase(),
  }))
  const indicatorLabels =
    indicatorsValue?.value?.map(({ value, label }) => ({ label, value })) ?? []
  const resultIndicators = classificationLabels.concat(indicatorLabels) ?? []

  return (
    <>
      {indicators.length > 0 && (
        <div className="border-b border-b-outline px-5 py-3 flex gap-2.5 items-center flex-wrap">
          {indicators.sort(sortIndicatorsFirst).map((v) => {
            const indicatorKey = resultIndicators.find(
              ({ label }) => label === v,
            )?.value
            let indicatorTotalValue = 0
            if (indicatorKey) {
              const totalValues = Object.assign(
                {},
                { ...totalData },
                { ...totalReplyData },
              )

              indicatorTotalValue =
                (totalValues[
                  indicatorKey as keyof typeof totalValues
                ] as number) ?? 0
            }
            return (
              <div key={v} className="flex gap-2 items-center">
                <div
                  className="w-3 h-3 rounded-sm"
                  style={{
                    backgroundColor: getChartColorByKey(v.toLowerCase()),
                  }}
                ></div>
                <span className="text-[11px] leading-5">
                  {v}
                  <span> ({indicatorTotalValue})</span>
                </span>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default DashboardIndicatorsList
