import { FC, useEffect } from "react"
import cx from "classnames"
import Tooltip from "@/components/Tooltip"
import IconButton from "@/components/IconButton"
import CopyIcon from "@/components/icons/CopyIcon"
import { useManualCopy } from "@/hooks/useManualCopy"
import {
  useGetDomainQuery,
  useVerifyDomainMutation,
} from "@/redux/services/domainsApi"
import { useAppSelector } from "@/redux/hooks"
import { selectDomainSetupId } from "@/redux/domainSetupSlice"
import { useDomainsContext } from "../../hooks/useDomainsContext"

type Props = {
  className?: string
  onDisabledChange: (disabled: boolean) => void
}

const DomainMicrosoftDKIMView: FC<Props> = ({
  className,
  onDisabledChange,
  ...rest
}: Props) => {
  const domainId = useAppSelector(selectDomainSetupId)
  const { setDkimNavigatedTo } = useDomainsContext()
  const [, emailCopy] = useManualCopy({
    successMessage: "Email copied successfully",
  })
  const { data: domain } = useGetDomainQuery(
    { id: domainId },
    { skip: !domainId },
  )
  const [verifyDomain] = useVerifyDomainMutation()
  useEffect(() => {
    setDkimNavigatedTo(true)
  }, [setDkimNavigatedTo])
  useEffect(() => {
    let intervalId: number
    if (!domain?.actions?.readyAt) {
      intervalId = setInterval(async () => {
        await verifyDomain({
          id: domainId,
          type: "dnsRecords",
        }).unwrap()
      }, 30000) // 30 seconds
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [domain?.actions?.readyAt, verifyDomain, domainId])
  useEffect(() => {
    onDisabledChange(Boolean(!domain?.actions?.readyAt))
  }, [domain?.actions?.readyAt, onDisabledChange])
  return (
    <div className={cx("flex flex-col gap-5", className)} {...rest}>
      <h2 className="text-lg font-semibold leading-7">DKIM</h2>
      <div className="flex-1 space-y-2">
        <h4 className="text-[#000] text-sm font-semibold leading-6">
          Instructions:
        </h4>
        <ol className="pl-4 space-y-1.5 list-decimal list-outside text-sm leading-6 [&>li]:pl-2.5 marker:text-[#26313f87]">
          <li>
            Sign in to the Microsoft 365 Defender Portal -{" "}
            <a
              className="text-primary underline break-all"
              target="_blank"
              href="https://security.microsoft.com/dkimv2"
            >
              https://security.microsoft.com/dkimv2
            </a>{" "}
            and use admin account -{" "}
            <span className="font-semibold inline-flex items-center gap-1 break-all">
              {domain?.admin?.email ?? "your email"}
              <Tooltip title="Copy Email" placement="right">
                <IconButton
                  onClick={() => emailCopy(domain?.admin?.email ?? "")}
                >
                  <CopyIcon />
                </IconButton>
              </Tooltip>
            </span>
          </li>
          <li>
            Under DomainKeys Identified Mail (DKIM), select the domain{" "}
            <span className="font-semibold break-all">
              {domain?.name ?? "current domain"}
            </span>
            . A window will open on the rightmost side.
          </li>
          <li>
            Press Create DKIM keys and wait. Close the dialog titled "Publish
            CNAMEs".
          </li>
          <li>
            Turn on the Sign messages for this domain with DKIM signatures
            toggle. Your changes will save.
          </li>
          <li>Select OK.</li>
        </ol>
      </div>
    </div>
  )
}

export default DomainMicrosoftDKIMView
