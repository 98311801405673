import { FC } from "react"
import cx from "classnames"
import Button from ".."
import { RiDeleteBin6Line as DeleteIcon } from "react-icons/ri"
import OutlineButton from "@/components/OutlineButton"

type Props = {
  className?: string
  outline?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const DeleteButton: FC<Props> = ({
  className,
  outline = false,
  ...rest
}: Props) => {
  return outline ? (
    <OutlineButton
      type="submit"
      className={cx("btn-error btn-sm", className)}
      {...rest}
    >
      <DeleteIcon className="w-5 h-5" />
      <span className="text-base font-semibold">Delete</span>
    </OutlineButton>
  ) : (
    <Button
      type="submit"
      className={cx("!btn-error !text-white btn-sm", className)}
      {...rest}
    >
      <DeleteIcon className="w-5 h-5" />
      <span className="text-base font-semibold">Delete</span>
    </Button>
  )
}

export default DeleteButton
