import { Routes } from "./routes"
import { Navigate, createBrowserRouter } from "react-router-dom"
import HeaderOnlyLayout from "@/components/layouts/HeaderOnly"
import HeaderLeftNavbarMain from "@/components/layouts/HeaderLeftNavbarMain"
import LoginPage from "@/pages/Login"
import ForgotPasswordPage from "@/pages/ForgotPassword"
import ForgotPasswordForm from "@/pages/ForgotPassword/features/ForgotPasswordForm"
import ForgotPasswordSent from "@/pages/ForgotPassword/features/ForgotPasswordSent"
import NewPasswordForm from "@/pages/ForgotPassword/features/NewPasswordForm"
import ForgotPasswordDone from "@/pages/ForgotPassword/features/ForgotPasswordDone"
import OrganizationsPage from "@/pages/Organizations"
import HeaderMain from "@/components/layouts/HeaderMain"
import RequireAuth from "@/components/providers/RequireAuthProvider"
import UserSettingsPage from "@/pages/UserSettings"
import CampaignsPage from "@/pages/Campaigns"
import DashboardPage from "@/pages/Dashboard"
import SetupPasswordPage from "@/pages/SetupPassword"
import ConfirmEmailPage from "@/pages/ConfirmEmail"
import OrganizationPage from "@/pages/Organization"
import SendersPage from "@/pages/Senders"
import SequencesPage from "@/pages/Sequences"
import AddEditSequencePage from "@/pages/AddEditSequence"
import MasterListsPage from "@/pages/MasterLists"
import MasterListDetailsPage from "@/pages/MasterListDetails"
import AddEditCampaignPage from "@/pages/AddEditCampaign"
import DomainsPage from "@/pages/Domains"
import AddEditDomainPage from "@/pages/AddEditDomain"
import EmailAddressesPage from "@/pages/EmailAddresses"
import ProspectsPage from "@/pages/Prospects"
import DNCPage from "@/pages/DNC"
import SpamTestingPage from "@/pages/SpamTesting"
import RequireAdminProvider from "@/components/providers/RequireAdminProvider"
import AdminPage from "@/pages/Admin"
import GsuitePage from "@/pages/Gsuite"
import AdminUsersPage from "@/pages/AdminUsers"
import AdminSpamTestingPage from "@/pages/AdminSpamTesting"
import AdminDNCPage from "@/pages/AdminDNC"
import ReplyClassificationPage from "@/pages/ReplyClassification"
import SearchCriteriaPage from "@/pages/SearchCriteria"
import AddEditSearchCriteriaPage from "@/pages/AddEditSearchCriteria"
import AdminCookiesPage from "@/pages/AdminCookies"
import AdminProxyPage from "@/pages/AdminProxy"
import OAuthRedirectPage from "@/pages/OAuthRedirect"
import InboxPage from "@/pages/Inbox"

export const router = createBrowserRouter([
  {
    path: Routes.login,
    element: (
      <HeaderOnlyLayout>
        <LoginPage />
      </HeaderOnlyLayout>
    ),
  },
  {
    path: Routes.passwordSetup,
    element: (
      <HeaderOnlyLayout>
        <SetupPasswordPage />
      </HeaderOnlyLayout>
    ),
  },
  {
    path: Routes.confirmEmail,
    element: (
      <HeaderOnlyLayout>
        <ConfirmEmailPage />
      </HeaderOnlyLayout>
    ),
  },
  {
    path: Routes.forgotPassword.index,
    element: (
      <HeaderOnlyLayout>
        <ForgotPasswordPage />
      </HeaderOnlyLayout>
    ),
    children: [
      {
        path: "form",
        element: <ForgotPasswordForm />,
      },
      {
        path: "email-sent",
        element: <ForgotPasswordSent />,
      },
      {
        path: "new-password",
        element: <NewPasswordForm />,
      },
      {
        path: "done",
        element: <ForgotPasswordDone />,
      },
    ],
  },
  {
    path: Routes.organizations,
    element: (
      <RequireAuth>
        <HeaderMain>
          <OrganizationsPage />
        </HeaderMain>
      </RequireAuth>
    ),
  },
  {
    path: Routes.userSettings,
    element: (
      <RequireAuth>
        <HeaderMain>
          <UserSettingsPage />
        </HeaderMain>
      </RequireAuth>
    ),
  },
  {
    path: Routes.organization.index,
    element: (
      <RequireAuth>
        <HeaderLeftNavbarMain>
          <OrganizationPage />
        </HeaderLeftNavbarMain>
      </RequireAuth>
    ),
    children: [
      {
        path: "dashboard",
        element: <DashboardPage />,
      },
      {
        path: "campaigns",
        element: <CampaignsPage />,
      },
      {
        path: "campaigns/create",
        element: <AddEditCampaignPage />,
      },
      {
        path: "campaigns/edit/:campaignId",
        element: <AddEditCampaignPage />,
      },
      {
        path: "senders",
        element: <SendersPage />,
      },
      {
        path: "sequences",
        element: <SequencesPage />,
      },
      {
        path: "sequences/create",
        element: <AddEditSequencePage />,
      },
      {
        path: "sequences/edit/:sequenceId",
        element: <AddEditSequencePage />,
      },
      {
        path: "master-lists",
        element: <MasterListsPage />,
      },
      {
        path: "master-lists/:masterListId",
        element: <MasterListDetailsPage />,
      },
      {
        path: "domains",
        element: <DomainsPage />,
      },
      {
        path: "domains/create",
        element: <AddEditDomainPage />,
      },
      {
        path: "domains/edit/:domainId",
        element: <AddEditDomainPage />,
      },
      {
        path: "emails",
        element: <EmailAddressesPage />,
      },
      {
        path: "prospects",
        element: <ProspectsPage />,
      },
      {
        path: "dnc",
        element: <DNCPage />,
      },
      {
        path: "spam-testing",
        element: <SpamTestingPage />,
      },
      {
        path: "search-criteria",
        element: <SearchCriteriaPage />,
      },
      {
        path: "search-criteria/add",
        element: <AddEditSearchCriteriaPage />,
      },
      {
        path: "search-criteria/edit/:criteriaId",
        element: <AddEditSearchCriteriaPage />,
      },
      {
        path: "inbox",
        element: <InboxPage />,
      },
    ],
  },
  {
    path: Routes.admin.index,
    element: (
      <RequireAdminProvider>
        <HeaderMain>
          <AdminPage />
        </HeaderMain>
      </RequireAdminProvider>
    ),
    children: [
      {
        path: "domainAdmins",
        element: <GsuitePage />,
      },
      {
        path: "users",
        element: <AdminUsersPage />,
      },
      {
        path: "spam",
        element: <AdminSpamTestingPage />,
      },
      {
        path: "dnc",
        element: <AdminDNCPage />,
      },
      {
        path: "ai",
        element: <ReplyClassificationPage />,
      },
      {
        path: "cookies",
        element: <AdminCookiesPage />,
      },
      {
        path: "proxy",
        element: <AdminProxyPage />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to={Routes.organizations} replace />,
  },
  {
    path: "oauth",
    element: <OAuthRedirectPage />,
  },
  {
    path: "*",
    element: <div>Not Found 404</div>,
  },
])
