import { FC, Fragment } from "react"
import { useGetCanadaProvincesQuery } from "@/redux/services/generalApi"
import Select from "@/components/Select"
import { useSenderModalContext } from "../hooks/useSenderModalContext"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import TextField from "@/components/TextField"
import type { FormikErrors, FormikTouched } from "formik"
import type { CanadaSender } from "@/types/entities/sender"

const CanadaSenderAddressForm: FC = () => {
  const { values, setFieldValue, getFieldProps, errors, touched } =
    useSenderModalContext()
  const { data: canadaProvinces = [], isLoading: provincesLoading } =
    useGetCanadaProvincesQuery()
  const addressError = errors.address as FormikErrors<CanadaSender["address"]>
  const addressTouched = touched.address as FormikTouched<
    CanadaSender["address"]
  >

  return (
    <Fragment>
      <TextField
        type="text"
        placeholder="Enter street name"
        topLeftLabel="Street"
        className="input-sm"
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={addressError?.address1}
            touched={addressTouched?.address1}
          />
        }
        {...getFieldProps("address.address1")}
      />
      <TextField
        type="text"
        placeholder="Enter city"
        topLeftLabel="City"
        className="input-sm"
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={addressError?.city}
            touched={addressTouched?.city}
          />
        }
        {...getFieldProps("address.city")}
      />
      <Select
        options={canadaProvinces.map(({ abbr, display }) => ({
          label: display,
          value: abbr,
        }))}
        getPopupContainer={(node) => node.parentNode}
        loading={provincesLoading}
        label="Province"
        bordered={false}
        value={values.address.province || null}
        onChange={(v) => setFieldValue("address.province", v)}
        className="h-10 bg-input rounded-md"
        placeholder="Select province"
        bottomLabel={
          <FormFieldErrorMessage
            error={addressError?.province}
            touched={addressTouched?.province}
          />
        }
      />
      <TextField
        type="text"
        placeholder="Enter Postal Code"
        topLeftLabel="Postal Code"
        className="input-sm"
        maxLength={10}
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={addressError?.postalCode}
            touched={addressTouched?.postalCode}
          />
        }
        {...getFieldProps("address.postalCode")}
      />
    </Fragment>
  )
}

export default CanadaSenderAddressForm
