import { FC } from "react"
import cx from "classnames"
import { useFormik } from "formik"
import validationSchema from "./validationSchema"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import TextField from "@/components/TextField"
import Button from "@/components/Button"
import Select from "@/components/Select"
import { useAddAdminCookie } from "../../hooks/useAddAdminCookie"
import { useEditAdminCookie } from "../../hooks/useEditAdminCookie"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import type { AdminCookie } from "@/types/entities/cookie"

type Props = {
  className?: string
  data?: AdminCookie
} & ModalProps

const AddEditCookieModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const isEdit = !!data
  const { data: { regions } = { regions: [] }, isLoading: valuesLoading } =
    useGetSystemValuesQuery()
  const {
    addAdminCookie,
    response: { isLoading: addLoading },
  } = useAddAdminCookie()
  const {
    editAdminCookie,
    response: { isLoading: editLoading },
  } = useEditAdminCookie()
  const {
    getFieldProps,
    handleSubmit,
    resetForm,
    isValid,
    dirty,
    setFieldValue,
    values: { region },
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? "",
      li_a: data?.li_a ?? "",
      li_at: data?.li_at ?? "",
      JSESSIONID: data?.JSESSIONID ?? "",
      region: data?.region ?? "",
    },
    validationSchema,
    onSubmit: async (body) => {
      try {
        isEdit
          ? await editAdminCookie({
              id: data._id,
              body,
            })
          : await addAdminCookie(body)
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  const submitDisabled = addLoading || editLoading || !(isValid && dirty)
  const submitBtnText = isEdit ? "Save" : "Add"
  const title = isEdit ? "Edit Cookie" : "Add Cookie"

  return (
    <Modal
      title={title}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            type="button"
            className="btn-sm text-black min-w-[86px]"
            onClick={handleClose}
            disabled={addLoading || editLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={submitDisabled}
            type="submit"
            className="btn-sm min-w-[86px]"
          >
            {submitBtnText}
          </Button>
        </div>
      }
      {...rest}
    >
      <div className="space-y-5">
        <TextField
          topLeftLabel="Cookie Name"
          placeholder="Enter cookie name"
          className="input-sm"
          {...getFieldProps("name")}
        />
        <TextField
          topLeftLabel="li_a"
          placeholder="Paste li_a here"
          className="input-sm"
          {...getFieldProps("li_a")}
        />
        <TextField
          topLeftLabel="li_at"
          placeholder="Paste li_at here"
          className="input-sm"
          {...getFieldProps("li_at")}
        />
        <TextField
          topLeftLabel="JSESSIONID"
          placeholder="Paste JSESSIONID here"
          className="input-sm"
          {...getFieldProps("JSESSIONID")}
        />
        <Select
          value={region || null}
          onChange={(v) => setFieldValue("region", v)}
          label="Region"
          placeholder="Select region"
          className="h-10 bg-input rounded-md"
          bordered={false}
          options={regions.map(({ id, name }) => ({
            label: name,
            value: id,
          }))}
          getPopupContainer={(node) => node.parentNode}
          loading={valuesLoading}
        />
      </div>
    </Modal>
  )
}

export default AddEditCookieModal
