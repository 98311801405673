import { FC, ReactNode, useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import cx from "classnames"
import Loader from "../Loader"
import { ModalProps } from "../Modal"

type Props = {
  children?: ReactNode
  className?: string
} & ModalProps

const ApiCallLoader: FC<Props> = ({
  open = false,
  children,
  className,
}: Props) => {
  const ref = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    open ? ref.current?.showModal() : ref.current?.close()
  }, [open])

  if (!open) {
    return null
  }

  return createPortal(
    <dialog
      onKeyDown={(e) => e.key === "Escape" && e.preventDefault()}
      ref={ref}
      className={cx("modal", className)}
    >
      <div className="modal-box rounded-md w-fit font-sans pt-4 pb-3 px-3 bg-white text-black flex flex-col gap-1.5 items-center">
        <Loader />
        <div>{children}</div>
      </div>
    </dialog>,
    document.body,
  )
}

export default ApiCallLoader
