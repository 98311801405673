import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import EmailConnectionStatus from "@/components/EmailConnectionStatus"
import GoogleOAuthLogin from "@/components/GoogleOAuthLogin"
import MicrosoftOAuthLogin from "@/components/MicrosoftOAuthLogin"
import CopyIcon from "@/components/icons/CopyIcon"
import Tooltip from "@/components/Tooltip"
import Loader from "@/components/Loader"
import IconButton from "@/components/IconButton"
import { useManualCopy } from "@/hooks/useManualCopy"
import { useConnectEmailOAuth } from "../../hooks/useConnectEmailOAuth"
import { useDisconnectEmailOAuth } from "../../hooks/useDisconnectEmailOAuth"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import type { AddEmailAddressResponse } from "@/types/entities/email-address"

type Props = {
  className?: string
  data?: AddEmailAddressResponse | null
} & ModalProps

const LoginEmailModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const { data: { GSuiteAppIDSender } = { GSuiteAppIDSender: "" }, isLoading } =
    useGetSystemValuesQuery()
  const { connectEmailAuth } = useConnectEmailOAuth()
  const { disconnectEmailAuth } = useDisconnectEmailOAuth()
  const [, copyEmail] = useManualCopy({
    successMessage: "Email successfuly copied",
  })
  const [, copyPassword] = useManualCopy({
    successMessage: "Password successfuly copied",
  })
  const handleLogin = async (codeResponse: Record<string, any>) => {
    const code = codeResponse.code
    if (!(code && data)) {
      return
    }
    const response = await connectEmailAuth({
      id: data.sender,
      body: {
        code,
        emailId: data._id,
      },
    })
    if (response) {
      onClose && onClose()
    }
  }
  const handleDisconnect = async () => {
    if (!data) {
      return
    }
    await disconnectEmailAuth({
      id: data.sender,
      body: {
        emailId: data._id,
      },
    })
  }
  if (!data) {
    return null
  }
  const { email, password, connected, provider } = data
  const title = connected ? "Re-login Account" : "Login to New Account"
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      actions={
        <OutlineButton
          type="button"
          onClick={onClose}
          className="btn-sm text-black min-w-[86px]"
        >
          Cancel
        </OutlineButton>
      }
      className={cx("", className)}
      {...rest}
    >
      <div className="flex flex-col gap-5">
        {connected ? (
          <EmailConnectionStatus
            connected={connected}
            connectedTo={email}
            onDisconnect={handleDisconnect}
          />
        ) : (
          <>
            <div className="flex flex-col gap-1">
              <div className="text-sm leading-6">
                In order to complete the setup, please login to the account with
                the following credentials:
              </div>
              <div className="text-sm leading-6 space-y-1">
                <div className="flex gap-1 items-center">
                  <span>
                    username: <span className="font-bold">{email}</span>
                  </span>
                  <Tooltip title="Copy Email">
                    <IconButton
                      className="flex justify-center items-center"
                      onClick={() => copyEmail(email)}
                    >
                      <CopyIcon className="inline-block cursor-pointer" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="flex gap-1 items-center">
                  <span>
                    password: <span className="font-bold">{password}</span>
                  </span>
                  <Tooltip title="Copy Password">
                    <IconButton
                      className="flex justify-center items-center"
                      onClick={() => copyPassword(password)}
                    >
                      <CopyIcon className="inline-block cursor-pointer" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            {isLoading ? (
              <Loader className="mx-auto" />
            ) : (
              <>
                {provider === "google" ? (
                  <GoogleOAuthLogin
                    clientId={GSuiteAppIDSender}
                    onSuccess={handleLogin}
                    scope="https://mail.google.com https://www.googleapis.com/auth/userinfo.email"
                  />
                ) : provider === "microsoft" ? (
                  <MicrosoftOAuthLogin
                    onSuccess={handleLogin}
                    scope="offline_access https://graph.microsoft.com/Mail.Send https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.ReadWrite"
                  />
                ) : null}
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  )
}

export default LoginEmailModal
