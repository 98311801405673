import { FC, useEffect, useState } from "react"
import cx from "classnames"
import { Table as AntTable, TableProps } from "antd"
import ChevronRightIcon from "@/assets/chevron-right.svg"
import Select from "../Select"
import type {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface"

export const HEADER_HEIGHT = 49
export const PAGINATION_HEIGHT = 56

type Props = {
  className?: string
  truncate?: boolean
  showSizeChanger?: boolean
  pageSize?: number
  total?: number
  maxHeight?: number
  onPageSizeChange?: (pageSize: number) => void
  onPageChange?: (page: number) => void
  onSortChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => void
} & TableProps<any>

const Table: FC<Props> = ({
  className,
  showSizeChanger,
  pageSize: initPageSize = 100,
  pagination,
  onPageSizeChange,
  onPageChange,
  onSortChange,
  showHeader = true,
  total,
  truncate,
  maxHeight,
  scroll,
  ...rest
}: Props) => {
  const [pageSize, setPageSize] = useState(initPageSize)
  useEffect(() => setPageSize(initPageSize), [initPageSize])

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize)
    onPageSizeChange && onPageSizeChange(pageSize)
  }
  return (
    <div className="flex flex-col relative">
      <AntTable
        className={cx(
          "[&_.ant-pagination-prev]:mr-0 sm:[&_.ant-pagination-prev]:mr-2 [&_.ant-pagination-simple-pager]:!mr-0 sm:[&_.ant-pagination-simple-pager]:mr-2",
          {
            "whitespace-nowrap [&_td]:max-w-[275px] [&_td]:truncate": truncate,
          },
          {
            "[&_.ant-table-body]:h-[2000px] [&_.ant-table-body]:!overflow-y-auto":
              Boolean(maxHeight),
          },
          className,
        )}
        pagination={{
          pageSize,
          simple: true,
          hideOnSinglePage: false,
          itemRender: (_, type, element) => {
            if (type === "prev") {
              return <ChevronRightIcon className="rotate-180 translate-x-2" />
            }
            if (type === "next") {
              return <ChevronRightIcon />
            }
            if (type === "page") {
              return null
            }
            return element
          },
          showSizeChanger: false, // shows by default when total > 50
          showPrevNextJumpers: false,
          total: total ?? 0,
          showTotal: (total, [start, end]) => (
            <span className="hidden sm:inline">
              {start}-{end} / {total}
            </span>
          ),
          onChange: (page, pageSize) =>
            onPageChange && onPageChange((page - 1) * pageSize),
          ...pagination,
        }}
        onChange={(p, f, s) => {
          if (s && onSortChange) {
            const sort = s as SorterResult<any>
            onSortChange(p, f, sort)
          }
        }}
        scroll={{
          y: maxHeight
            ? maxHeight - HEADER_HEIGHT - PAGINATION_HEIGHT
            : undefined,
          ...scroll,
        }}
        showHeader={showHeader}
        showSorterTooltip={false}
        {...rest}
      />
      {showSizeChanger && showHeader && (
        <div className="text-black absolute bottom-3 left-3 flex items-center [&_.ant-select-selection-item]:!pr-6">
          <span className="hidden sm:inline text-sm leading-6 opacity-60 tracking-[0.4px] -mr-1.5">
            Rows per page:
          </span>
          <Select
            bordered={false}
            popupMatchSelectWidth={75}
            options={[
              {
                label: 15,
                value: 15,
              },
              {
                label: 50,
                value: 50,
              },
              {
                label: 75,
                value: 75,
              },
              {
                label: 100,
                value: 100,
              },
            ]}
            value={pageSize}
            onChange={handlePageSizeChange}
          />
        </div>
      )}
    </div>
  )
}

export default Table
