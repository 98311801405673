import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import { useAdminDeleteOrganization } from "@/pages/Organizations/hooks/useAdminDeleteOrganization"
import type { AdminOrganization } from "@/types/entities/organization"

type Props = {
  className?: string
  data?: AdminOrganization
  onDeleteSuccess?: (id: string) => void
} & ModalProps

const AdminDeleteOrganizationModal: FC<Props> = ({
  className,
  open,
  onClose,
  onDeleteSuccess,
  data,
  ...rest
}: Props) => {
  const {
    deleteOrganization,
    response: { isLoading },
  } = useAdminDeleteOrganization()
  if (!data) {
    return null
  }
  const handleSubmit = async () => {
    await deleteOrganization(data._id)
    onDeleteSuccess && onDeleteSuccess(data._id)
    onClose && onClose()
  }
  return (
    <Modal
      title="Delete Organization"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <CancelButton onClick={onClose} disabled={isLoading} />
          <DeleteButton disabled={isLoading} />
        </div>
      }
      {...rest}
    >
      <span className="text-base leading-6">
        Are you sure you want to delete{" "}
        <span className="font-semibold">“{data.name}”</span>?
      </span>
    </Modal>
  )
}

export default AdminDeleteOrganizationModal
