export const INDICATOR_VALUES: {
  label: string
  value: string
  defaultDisplay: boolean
}[] = [
  {
    label: "Total",
    value: "total",
    defaultDisplay: false,
  },
  {
    label: "Follow ups",
    value: "followup",
    defaultDisplay: true,
  },
  {
    label: "Prospects",
    value: "new",
    defaultDisplay: true,
  },
  {
    label: "Replies",
    value: "reply",
    defaultDisplay: true,
  },
  {
    label: "Leads",
    value: "lead",
    defaultDisplay: true,
  },
]
