import React, { useEffect } from "react"
import { useSnackbar } from "notistack"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import { selectToast, setToast } from "@/redux/toastSlice"

type Props = {
  children: React.ReactNode
}

const ToastProvider = ({ children }: Props) => {
  const { show, options } = useAppSelector(selectToast)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (show && options) {
      enqueueSnackbar(options.message, { variant: options.variant })
      // reset toast
      dispatch(setToast({ message: "", show: false, variant: undefined }))
    }
  }, [dispatch, enqueueSnackbar, options, show])

  return <>{children}</>
}

export default ToastProvider
