import { useAddAdminOrganizationMutation } from "@/redux/services/adminOrganizationsApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import { AddAdminOrganizationPayload } from "@/types/entities/organization"

export const useAdminAddOrganization = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch()
  const [addOrganization, response] = useAddAdminOrganizationMutation()

  const handleAddOrganization = async (
    payload: AddAdminOrganizationPayload,
  ) => {
    try {
      await addOrganization(payload).unwrap()
      typeof onSuccess === "function" && onSuccess()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success add organization",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { addOrganization: handleAddOrganization, response }
}
