import { FC, useMemo } from "react"
import cx from "classnames"
import RadioGroup from "@/components/RadioGroup"
import Radio from "@/components/Radio"
import Select from "@/components/Select"
import { useGetCriteriaFiltersQuery } from "@/redux/services/searchCriteriaApi"
import TagsTextarea from "@/components/TagsTextarea"
import { twMerge } from "tailwind-merge"
import { useAppSelector } from "@/redux/hooks"
import {
  selectCriteriaFilter,
  selectCriteriaGeographyType,
} from "@/redux/searchCriteriaSetupSlice"
import { useCriteriaSetup } from "@/pages/AddEditSearchCriteria/hooks/useCriteriaSetup"
import type {
  GeographyType,
  SelectionType,
} from "@/types/entities/search-criteria"
import type { RadioChangeEvent } from "antd"

type Props = {
  className?: string
}

const CriteriaGeographyFilter: FC<Props> = ({ className, ...rest }: Props) => {
  const { data: filters = [] } = useGetCriteriaFiltersQuery()
  const { selections: regionSelections } = useAppSelector(
    selectCriteriaFilter("REGION"),
  )!
  const { subSelection, selections: postalSelections } = useAppSelector(
    selectCriteriaFilter("POSTAL_CODE"),
  )!
  const filterType = useAppSelector(selectCriteriaGeographyType)
  const {
    onTextFieldChange,
    onPostalCodeSubSelectionChange,
    onGeographyTypeChange,
  } = useCriteriaSetup()
  const geographyFilterValues = useMemo(
    () => filters.find(({ type }) => type === "POSTAL_CODE"),
    [filters],
  )
  const geographyRadiusFilterValues = useMemo(
    () => geographyFilterValues?.subSelection!.values ?? [],
    [geographyFilterValues],
  )
  const handleTypeChange = ({ target }: RadioChangeEvent) => {
    const newFilterType = target.value as GeographyType
    onGeographyTypeChange(newFilterType)
  }
  const handleSelectionTypeValuesChange =
    (type: SelectionType) => (value: string[]) => {
      onTextFieldChange({ type, value, filterType })
      if (
        // reset radius
        filterType === "POSTAL_CODE" &&
        ((!typeSelections.INCLUDED.length && !value.length) ||
          (!typeSelections.EXCLUDED.length && !value.length))
      ) {
        onPostalCodeSubSelectionChange(null)
      }
    }

  const typeSelections =
    filterType === "REGION" ? regionSelections : postalSelections

  const typeFormatted = filterType === "REGION" ? "Region" : "Postal Code"

  return (
    <div className={cx("flex flex-col gap-4 flex-grow", className)} {...rest}>
      <div className="flex gap-4 flex-wrap">
        <div className="space-y-1.5">
          <h6 className="text-xs font-medium leading-[18px]">Type</h6>
          <RadioGroup
            value={filterType}
            onChange={handleTypeChange}
            className="flex pt-1.5"
          >
            <div className="py-1.5 pr-[14px]">
              <Radio className="mr-0" value="REGION">
                Region
              </Radio>
            </div>
            <div className="py-1.5 pr-[14px]">
              <Radio className="mr-0" value="POSTAL_CODE">
                ZipCode
              </Radio>
            </div>
          </RadioGroup>
        </div>
        <div
          className={twMerge(
            cx("space-y-2 opacity-0 h-0 transition-opacity invisible", {
              "visible opacity-100 h-auto": filterType === "POSTAL_CODE",
            }),
          )}
        >
          <h6 className="text-xs font-medium leading-[18px]">
            Radius for ZipCode
          </h6>
          <Select
            value={subSelection?.value}
            onChange={onPostalCodeSubSelectionChange}
            bordered={false}
            className="bg-input rounded-md h-10 min-w-[182px] [&_.ant-select-selection-item]:!pr-12"
            placeholder="Select radius"
            popupMatchSelectWidth={false}
            options={geographyRadiusFilterValues.map(({ description, id }) => ({
              value: id,
              label: description,
            }))}
          />
        </div>
      </div>
      <div className="flex gap-4 flex-1 flex-col lg:flex-row [&_.relative]:h-full">
        <div className="basis-1/2 gap-y-2 flex flex-col">
          <h5 className="font-medium text-positive">{typeFormatted} Include</h5>
          <TagsTextarea
            placeholder={
              filterType === "POSTAL_CODE"
                ? "Example formatting: 90210 United States"
                : "Example formatting: Beverly Hills, Los Angeles"
            }
            className="resize-none h-full flex-grow"
            value={typeSelections.INCLUDED}
            onTagsChange={handleSelectionTypeValuesChange("INCLUDED")}
            type="text"
          />
        </div>
        <div className="basis-1/2 gap-y-2 flex flex-col">
          <h5 className="font-medium text-error">{typeFormatted} Exclude</h5>
          <TagsTextarea
            placeholder={
              filterType === "POSTAL_CODE"
                ? "Example formatting: 90210 United States"
                : "Example formatting: Beverly Hills, Los Angeles"
            }
            className="resize-none h-full flex-grow"
            value={typeSelections.EXCLUDED}
            onTagsChange={handleSelectionTypeValuesChange("EXCLUDED")}
            type="text"
          />
        </div>
      </div>
    </div>
  )
}

export default CriteriaGeographyFilter
