export const getNumberEnding = (number: number) => {
  if (number === 1) {
    return "st"
  }
  if (number === 2) {
    return "nd"
  }
  if (number === 3) {
    return "rd"
  }
  return "th"
}

export const getRange = (start: number, end: number) => {
  const arr = []
  for (let i = start; i <= end; i++) {
    arr.push(i)
  }
  return arr
}

export const generateValuesWithInterval = (
  start: number,
  end: number,
  interval: number,
) => {
  if (start > end || interval <= 0) {
    console.error(
      "Invalid input: ensure start is less than or equal to end, and interval is greater than 0",
    )
    return []
  }

  const result = []
  for (let i = start; i <= end; i += interval) {
    result.push(i)
  }

  return result
}

const NUMBER_TRAILING_ZEROS_REGEX = /(\.\d*[1-9])0+$|\.0*$/

export const getPercentage = (a: number, b: number, fractionDigits = 2) => {
  if (b === 0) {
    return 0
  }
  return parseFloat(
    ((a / b) * 100)
      .toFixed(fractionDigits)
      .replace(NUMBER_TRAILING_ZEROS_REGEX, "$1"),
  )
}

export function toFixedTrimmed(num: number, decimals: number) {
  const fixed = num.toFixed(decimals)
  if (Number.isNaN(num)) {
    return ""
  }
  return fixed.replace(NUMBER_TRAILING_ZEROS_REGEX, "$1")
}
