import { FC } from "react"
import cx from "classnames"
import Select from "@/components/Select"
import { INDICATOR_VALUES } from "./constants"
import Checkbox from "@/components/Checkbox"
import { useDashboard } from "../../hooks/useDashboard"

type Props = {
  className?: string
}

const IndicatorDropdown: FC<Props> = ({ className, ...rest }: Props) => {
  const { indicatorsValue, handleIndicatorsChange, selectedIndicators } =
    useDashboard()
  return (
    <Select
      value={indicatorsValue.label || null}
      onChange={handleIndicatorsChange}
      options={INDICATOR_VALUES}
      className={cx("h-9 min-w-[172px]", className)}
      popupMatchSelectWidth={false}
      optionRender={({ value, label }) => (
        <div onClick={(e) => e.stopPropagation()} className="flex items-center">
          <Checkbox
            id={value as string}
            onChange={handleIndicatorsChange(value as string)}
            checked={Boolean(selectedIndicators[value as string])}
            className="checkbox-primary h-[18px] w-[18px]"
          />
          <label
            htmlFor={value as string}
            className="flex-grow pl-2.5 flex gap-2 items-center cursor-pointer"
          >
            <span className="text-sm leading-6">{label}</span>
          </label>
        </div>
      )}
      {...rest}
    />
  )
}

export default IndicatorDropdown
