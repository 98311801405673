import { FC, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import cx from "classnames"
import { FaRegCalendar as CalendarIcon } from "react-icons/fa6"
import dayjs from "dayjs"
import { usePageTitle } from "@/hooks/usePageTitle"
import { usePagination } from "@/hooks/usePagination"
import TextField from "@/components/TextField"
import SearchIcon from "@/assets/search.svg"
import OutlineButton from "@/components/OutlineButton"
import ProspectCampaignsDropdown from "./features/ProspectCampaignsDropdown"
import DatePicker from "@/components/DatePicker"
import { useProspects } from "./hooks/useProspects"
import ProspectsTable from "./features/ProspectsTable"
import EmptyList from "@/components/EmptyList"
import ClassificationsDropdown from "@/components/ClassificationsDropdown"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import DownloadIcon from "@/components/icons/DownloadIcon"
import ProspectsDeleteModal from "./features/ProspectsDeleteModal"
import DeleteButton from "@/components/Button/DeleteButton"
import { useExportProspectsMutation } from "@/redux/services/prospectsApi"
import { removeUndefinedProperties } from "@/utils/objects"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { useClientRect } from "@/hooks/useClientRect"
import { useUserAccess } from "@/hooks/useUserAccess"
import type { Key } from "antd/es/table/interface"

type Props = {
  className?: string
}

const ProspectsPage: FC<Props> = ({ className, ...rest }: Props) => {
  usePageTitle("Prospects")
  const { id: orgId = "" } = useParams()
  const [rect, ref] = useClientRect(true)
  const dispatch = useAppDispatch()
  const { pageFullAccess } = useUserAccess({ pageName: "Prospects" })
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    searchTerm,
    limit,
    skip,
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
  } = usePagination()
  const from = new Date(
    searchParams.get("from") ?? +dayjs().subtract(29, "day").toDate(),
  )
  const to = new Date(searchParams.get("to") ?? Date.now())
  const {
    prospects,
    campaign,
    classifications,
    isLoading,
    isError,
    total,
    text,
    isFetching,
  } = useProspects()
  const [exportProspects] = useExportProspectsMutation()
  const [openDeleteContactsModal, setOpenDeleteContactModal] = useState(false)
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([])

  const handleExportProspects = async () => {
    const response = await exportProspects({
      body: Object.assign(
        {
          orgId,
        },
        removeUndefinedProperties({
          from: searchParams.get("from") ?? undefined,
          to: searchParams.get("to") ?? undefined,
          campaign: campaign.length ? campaign : undefined,
          classifications: classifications.length ? classifications : undefined,
          text: text.length > 2 ? text : undefined,
        }),
      ),
    }).unwrap()
    if ((response as Response).status === 204) {
      dispatch(
        setToast({
          message: "No content available",
          show: true,
          variant: "warning",
        }),
      )
    }
  }
  const handleChangeDate = (type: "from" | "to") => (date: Date | null) => {
    if (!date) {
      return
    }
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set(type, date.toISOString())
      return newParams
    })
  }

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className={cx("h-full flex flex-col", className)} {...rest}>
        <div className="flex gap-3 flex-wrap">
          <TextField
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Search prospect"
            startIcon={<SearchIcon className="w-6 h-6" />}
            className="text-sm outline outline-outline !h-9 !py-1.5"
            rootClassName="basis-56"
          />
          <ProspectCampaignsDropdown />
          <ClassificationsDropdown />
          <div className="flex items-center">
            <DatePicker
              selected={from}
              onChange={handleChangeDate("from")}
              formatTextFieldValue={(v) => `${dayjs(v).format("M/D/YYYY")}`}
              inputClassName="!h-9 !w-[120px] sm:!w-36 rounded-md bg-white border-outline-dark border"
              suffixIcon={
                <CalendarIcon className="w-5 h-5 opacity-60 text-black" />
              }
              maxDate={dayjs(to).subtract(1, "day").toDate()}
            />
            <span className="opacity-60 text-sm leading-6 mx-2">to</span>
            <DatePicker
              selected={to}
              onChange={handleChangeDate("to")}
              formatTextFieldValue={(v) => `${dayjs(v).format("M/D/YYYY")}`}
              inputClassName="!h-9 !w-[120px] sm:!w-36 rounded-md bg-white border-outline-dark border"
              suffixIcon={
                <CalendarIcon className="w-5 h-5 opacity-60 text-black" />
              }
              minDate={dayjs(from).add(1, "day").toDate()}
            />
          </div>
          {pageFullAccess && (
            <>
              <OutlineButton
                onClick={handleExportProspects}
                className="btn-base text-black"
              >
                <DownloadIcon className="opacity-80 w-5 h-5" />
                <span className="opacity-80">Export</span>
              </OutlineButton>
              {selectedRowsKeys.length > 0 && (
                <DeleteButton
                  onClick={() => setOpenDeleteContactModal(true)}
                  className="btn-base"
                />
              )}
            </>
          )}
        </div>
        <div
          className={cx(
            "flex-1 mt-5 flex bg-white rounded-md shadow-[0px_1px_4px_0px_rgba(0,0,0,0.06)] px-2 py-1 min-h-[380px]",
            { "justify-center items-center": !prospects.length },
          )}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {prospects.length ? (
                <div ref={ref} className="w-full">
                  <ProspectsTable
                    page={skip}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    total={total}
                    prospects={prospects}
                    maxHeight={rect?.height ?? 0}
                    loading={isFetching}
                    selectedRowKeys={selectedRowsKeys}
                    onRowSelect={setSelectedRowsKeys}
                  />
                </div>
              ) : (
                <EmptyList message="There are no prospects yet"></EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      <ProspectsDeleteModal
        open={openDeleteContactsModal}
        data={selectedRowsKeys
          .map(String)
          .map((deletedId) => prospects.find(({ _id }) => _id === deletedId)!)
          .filter(Boolean)}
        onClose={() => setOpenDeleteContactModal(false)}
        onDeleteSuccess={() => setSelectedRowsKeys([])}
      />
    </>
  )
}

export default ProspectsPage
