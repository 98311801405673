import { FC } from "react"
import { useFormik } from "formik"
import Modal from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import validationSchema from "./validationSchema"
import { useChangePassword } from "@/hooks/useChangePassword"

type Props = {
  onClose: () => void
  open: boolean
}

const ResetPasswordModal: FC<Props> = ({ onClose, open }: Props) => {
  const { changePassword, response } = useChangePassword()
  const {
    getFieldProps,
    errors: {
      confirmNewPassword: confirmNewPasswordError,
      password: newPasswordError,
      currentPassword: currentPasswordError,
    },
    touched: {
      confirmNewPassword: confirmNewPasswordTouched,
      password: newPasswordTouched,
      currentPassword: currentPasswordTouched,
    },
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmNewPassword: "",
    },
    validationSchema,
    onSubmit: async ({ confirmNewPassword, currentPassword }) => {
      await changePassword({ currentPassword, newPassword: confirmNewPassword })
      resetForm()
      onClose()
    },
  })
  const closeModal = () => {
    resetForm()
    onClose()
  }
  return (
    <Modal
      open={open}
      title="Change Password"
      onSubmit={handleSubmit}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            disabled={response.isLoading}
            onClick={closeModal}
            className="text-black btn-sm !w-[86px]"
            type="button"
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={response.isLoading}
            className="btn-sm !w-[86px]"
            type="submit"
          >
            Save
          </Button>
        </div>
      }
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-5">
        <TextField
          type="password"
          placeholder="Enter your password"
          topLeftLabel="Current Password"
          bottomLeftLabel={
            <FormFieldErrorMessage
              error={currentPasswordError}
              touched={currentPasswordTouched}
            />
          }
          {...getFieldProps("currentPassword")}
        />
        <TextField
          type="password"
          placeholder="Enter your new password"
          topLeftLabel="New Password"
          bottomLeftLabel={
            <FormFieldErrorMessage
              error={newPasswordError}
              touched={newPasswordTouched}
            />
          }
          {...getFieldProps("password")}
        />
        <TextField
          type="password"
          placeholder="Re-type your new password"
          topLeftLabel="Confirm New Password"
          bottomLeftLabel={
            <FormFieldErrorMessage
              error={confirmNewPasswordError}
              touched={confirmNewPasswordTouched}
            />
          }
          {...getFieldProps("confirmNewPassword")}
        />
      </form>
    </Modal>
  )
}

export default ResetPasswordModal
