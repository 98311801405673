import { FC } from "react"
import cx from "classnames"
import { AiOutlineClose as CloseIcon } from "react-icons/ai"
import Modal, { ModalProps } from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import Button from "@/components/Button"
import type { MasterList } from "@/types/entities/master-list"
import { useCampaignSetup } from "../../hooks/useCampaignSetup"

type Props = {
  className?: string
  data: MasterList
} & ModalProps

const CampaignMasterListRowDeleteModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const { removeListById } = useCampaignSetup()
  const handleRemoveList = () => {
    removeListById(data._id)
    onClose!()
  }
  return (
    <Modal
      title="Remove List Item"
      actions={
        <div className="flex gap-4">
          <CancelButton onClick={onClose} />
          <Button
            type="submit"
            className={cx("!btn-error !text-white btn-sm", className)}
            {...rest}
          >
            <CloseIcon className="w-5 h-5" />
            <span className="text-base font-semibold leading-6">Remove</span>
          </Button>
        </div>
      }
      open={open}
      onClose={onClose}
      className={cx("", className)}
      onSubmit={handleRemoveList}
      {...rest}
    >
      Are you sure you want to delete{" "}
      <span className="font-semibold">{data.name}</span>?
    </Modal>
  )
}

export default CampaignMasterListRowDeleteModal
