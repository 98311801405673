import { FC, useMemo, useState } from "react"
import type { ColumnsType } from "antd/es/table"
import type { SenderAPIResponse } from "@/types/entities/sender"
import Table from "@/components/Table"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import Chip from "@/components/Chip"
import AddEditSenderModal from "../AddEditSenderModal"
import DeleteSenderModal from "../DeleteSenderModal"
import SenderDetailsDrawer from "../SenderDetailsDrawer"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { useUserAccess } from "@/hooks/useUserAccess"
import { MAX_CHIPS_COUNT } from "@/components/Table/constants"
import type { TablePaginationProps } from "@/redux/services/types"

type Props = {
  senders: SenderAPIResponse[]
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  senderName: string
  titles: string[]
  companies: string[]
  email: string
  dailyCapacity: number
  dailyVolume: number
}

const SendersList: FC<Props> = ({ senders, ...rest }: Props) => {
  const { sm } = useBreakpoints()
  const [edittedItemId, setEdittedItemId] = useState<string | null>(null)
  const [deletedItemId, setDeletedItemId] = useState<string | null>(null)
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null)
  const { pageFullAccess } = useUserAccess({ pageName: "Senders" })
  const columns: ColumnsType<TableDataType> = useMemo(() => {
    let resultCols: ColumnsType<TableDataType> = [
      {
        title: "Sender Name",
        dataIndex: "senderName",
        sorter: (a, b) => a.senderName.localeCompare(b.senderName),
        key: "senderName",
        className: "min-w-[150px]",
      },
      {
        title: "Capacity",
        dataIndex: "dailyCapacity",
        key: "dailyCapacity",
        sorter: (a, b) => a.dailyCapacity - b.dailyCapacity,
        width: 110,
      },
      {
        title: "Daily Volume",
        dataIndex: "dailyVolume",
        key: "dailyVolume",
        sorter: (a, b) =>
          a.dailyVolume && b.dailyVolume
            ? a.dailyVolume - b.dailyVolume
            : !a.dailyVolume
            ? -1
            : 1,
        width: 135,
      },
      {
        title: "Title",
        dataIndex: "titles",
        key: "titles",
        render: (_, { titles }) => (
          <div className="flex gap-1.5">
            {titles.slice(0, MAX_CHIPS_COUNT).map((title, i) => (
              <Chip key={i}>{title}</Chip>
            ))}
            {titles.length > MAX_CHIPS_COUNT && (
              <span className="text-sm leading-6 opacity-60">
                +{titles.length - MAX_CHIPS_COUNT}
              </span>
            )}
          </div>
        ),
        sorter: (a, b) => a.titles.length - b.titles.length,
        className: "min-w-[350px]",
      },
      {
        title: "Company",
        dataIndex: "companies",
        key: "companies",
        render: (_, { companies }) => {
          const resultCompanies = companies.filter(Boolean)
          return (
            <div className="flex gap-1.5">
              {resultCompanies.length > 0 ? (
                <>
                  {resultCompanies
                    .slice(0, MAX_CHIPS_COUNT)
                    .map((company, i) => (
                      <Chip key={i}>{company}</Chip>
                    ))}
                  {resultCompanies.length > MAX_CHIPS_COUNT && (
                    <span className="text-sm leading-6 opacity-60">
                      +{resultCompanies.length - MAX_CHIPS_COUNT}
                    </span>
                  )}
                </>
              ) : (
                <span className="text-sm leading-6 opacity-40">None</span>
              )}
            </div>
          )
        },
        sorter: (a, b) => a.companies.length - b.companies.length,
        className: "min-w-[155px]",
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => b.email.localeCompare(a.email),
      },
    ]
    if (pageFullAccess) {
      resultCols = resultCols.concat({
        title: "",
        dataIndex: "actions",
        key: "actions",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            <EditIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setEdittedItemId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
            />
            <BinIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setDeletedItemId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
            />
          </div>
        ),
        width: 96,
      })
    }
    return resultCols
  }, [pageFullAccess, sm])
  const tableData: TableDataType[] = senders.map(
    ({
      firstName,
      lastName,
      titles,
      companies,
      email,
      _id,
      dailyCapacity,
      dailyVolume,
    }) => ({
      senderName: `${firstName} ${lastName}`,
      companies,
      titles,
      email,
      dailyCapacity,
      dailyVolume,
      key: _id,
    }),
  )

  return (
    <>
      <Table
        dataSource={tableData}
        columns={columns}
        onRow={({ key }) => ({
          className: "cursor-pointer",
          onClick: () => setSelectedItemId(key),
        })}
        showSizeChanger
        truncate
        scroll={{ x: "max-content" }}
        {...rest}
      />
      <SenderDetailsDrawer
        open={!!selectedItemId}
        onClose={() => setSelectedItemId(null)}
        onEdit={(id) => setEdittedItemId(id)}
        data={senders.find(({ _id }) => _id === selectedItemId)}
        showEdit={pageFullAccess}
      />
      <AddEditSenderModal
        open={!!edittedItemId}
        onClose={() => setEdittedItemId(null)}
        data={senders.find(({ _id }) => _id === edittedItemId)}
      />
      <DeleteSenderModal
        open={!!deletedItemId}
        onClose={() => setDeletedItemId(null)}
        data={senders.find(({ _id }) => _id === deletedItemId)}
      />
    </>
  )
}

export default SendersList
