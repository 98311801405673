import { FC } from "react"
import { useFormik } from "formik"
import Modal from "@/components/Modal"
import TextField from "@/components/TextField"
import Button from "@/components/Button"
import OutlineButton from "@/components/OutlineButton"
import { useAdminAddOrganization } from "@/pages/Organizations/hooks/useAdminAddOrganization"
import validationSchema from "../utils/validation-schema"
import Select from "@/components/Select"
import { useAdminUsersInfiniteScroll } from "@/pages/Organizations/hooks/useAdminUsersInfiniteScroll"

type Props = {
  open: boolean
  onClose: () => void
}

const AdminAddOrganizationModal: FC<Props> = ({ open, onClose }: Props) => {
  const { addOrganization, response } = useAdminAddOrganization()
  const { isLoading, onUsersScroll, paginatedOwners } =
    useAdminUsersInfiniteScroll()
  const {
    handleSubmit,
    getFieldProps,
    isValid,
    dirty,
    resetForm,
    values: { owner },
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      owner: "",
      url: "",
      catchAll: "",
    },
    validationSchema,
    onSubmit: async ({ name, owner, url, catchAll }) => {
      try {
        await addOrganization({ name, owner, url, catchAll })
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  return (
    <Modal
      open={open}
      title="Add Organization"
      onClose={handleClose}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={handleClose}
            className="text-black btn-sm !w-[86px]"
            type="button"
            disabled={response.isLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={!(isValid && dirty) || response.isLoading}
            className="btn-sm !w-[86px]"
            type="submit"
          >
            Add
          </Button>
        </div>
      }
      onSubmit={handleSubmit}
      boxClassName="overflow-y-visible [&_.modal-action]:rounded-b-2xl"
    >
      <div className="space-y-3">
        <TextField
          type="text"
          topLeftLabel="Name"
          placeholder="Enter organization name"
          rootClassName="[&_label.label]:pt-0"
          className="input-sm"
          autoComplete="off"
          {...getFieldProps("name")}
        />
        <TextField
          type="url"
          topLeftLabel="Website"
          placeholder="Enter organization URL"
          className="input-sm"
          {...getFieldProps("url")}
        />
        <Select
          label="Owner"
          className="bg-input h-10 rounded-md z-50"
          bordered={false}
          placeholder="Select owner"
          loading={isLoading}
          options={paginatedOwners.map(({ _id, firstName, lastName }) => ({
            label: `${firstName} ${lastName}`,
            value: _id,
          }))}
          getPopupContainer={(node) => node.parentNode}
          value={owner || null}
          onChange={(v) => setFieldValue("owner", v)}
          onPopupScroll={onUsersScroll}
        />
        <TextField
          type="email"
          autoComplete="new-password"
          topLeftLabel="Catch-All Email"
          placeholder="Enter catch-all email"
          className="input-sm"
          {...getFieldProps("catchAll")}
        />
      </div>
    </Modal>
  )
}

export default AdminAddOrganizationModal
