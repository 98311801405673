import { FC } from "react"
import cx from "classnames"
import Tooltip from "../Tooltip"

type Props = {
  blur: boolean
  className?: string
  text: string
  showTooltip?: boolean
}

const BlurredText: FC<Props> = ({
  className,
  blur,
  text = "",
  showTooltip = true,
  ...rest
}: Props) => {
  const placeholderText = Array.from({ length: text?.length })
    .fill("*")
    .join("")
  return (
    <Tooltip placement="topLeft" title={showTooltip && !blur ? text : null}>
      <span className={cx({ "blur-sm": blur }, className)} {...rest}>
        {blur ? placeholderText : text}
      </span>
    </Tooltip>
  )
}

export default BlurredText
