import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteAdminCookieMutation } from "@/redux/services/adminCookiesApi"
import { setToast } from "@/redux/toastSlice"

export const useDeleteAdminCookie = () => {
  const dispatch = useAppDispatch()
  const [deleteAdminCookie, response] = useDeleteAdminCookieMutation()

  const handleDeleteAdminCookie = async (ids: string[]) => {
    try {
      const response = await deleteAdminCookie(ids).unwrap()
      if (response.data) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success delete cookie",
          }),
        )
      }
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(message)
    }
  }

  return { deleteAdminCookie: handleDeleteAdminCookie, response }
}
