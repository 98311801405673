import { getDisplayClassification } from "../../utils"
import type { Inbox } from "@/types/entities/inbox"
import type { SortOptions } from "@/types/shared"

export const sortInboxes = (a: Inbox, b: Inbox, sort: SortOptions) => {
  const aDate = new Date(a.receivedAt),
    bDate = new Date(b.receivedAt)
  return sort < 0 ? (aDate < bDate ? 1 : -1) : aDate > bDate ? 1 : -1
}
export const filterInboxesByClassification = (
  inbox: Inbox,
  filters: Record<string, string[]>,
): boolean => {
  const classifications = filters.classifications
  if (!classifications?.length) {
    return true
  }
  const displayClassification = getDisplayClassification(inbox)
  return Boolean(classifications.find((c) => displayClassification === c))
}
