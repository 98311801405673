import { useCallback, useEffect, useState } from "react"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { useAppDispatch } from "@/redux/hooks"
import { v4 as createId } from "uuid"
import {
  useGetNotificationsQuery,
  useUpdateNotificationsMutation,
  useDeleteNotificationsMutation,
  useGetTotalUnreadNotificationsQuery,
  dreamsenderApi,
} from "@/core/api/services"
import type {
  UpdateNotificationPayload,
  DeleteNotificationsPayload,
} from "@/types/entities/user"

export const NOTIFICATIONS_LIMIT = 20

export const useNotifications = () => {
  const dispatch = useAppDispatch()
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const [start, setStart] = useState<string | undefined>(undefined)
  const [refetch, setRefetch] = useState("")
  const {
    data: { items: notifications, total } = { items: [], total: 0 },
    isError,
    isFetching,
    ...rest
  } = useGetNotificationsQuery(
    {
      start,
      refetch,
    },
    {
      skip: !notificationsOpen,
    },
  )

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    hasNextPage: notifications.length < total,
    loading: isFetching,
    onLoadMore: () =>
      setStart(notifications[notifications.length - 1]?._id ?? undefined),
    disabled: isError,
    rootMargin: "0px 0px 50px 0px",
  })
  const { data: unreadNotificationsTotal = 0 } =
    useGetTotalUnreadNotificationsQuery(undefined, {
      pollingInterval: 30000, // 30 seconds
    })
  useEffect(() => {
    if (!notificationsOpen) {
      setRefetch(createId())
      setStart(undefined)
      dispatch(
        dreamsenderApi.util.updateQueryData(
          "getNotifications",
          { start: undefined },
          (draft) => {
            Object.assign(draft, {
              items: [],
            })
          },
        ),
      )
    }
  }, [notificationsOpen, dispatch])
  const [updateNotifications] = useUpdateNotificationsMutation()
  const [deleteNotifications] = useDeleteNotificationsMutation()

  const handleUpdateNotifications = useCallback(
    async (body: UpdateNotificationPayload) => {
      try {
        const response = await updateNotifications(body).unwrap()
        return response
      } catch (e) {
        console.error(e)
      }
    },
    [updateNotifications],
  )
  const handleDeleteNotifications = useCallback(
    async (body: DeleteNotificationsPayload) => {
      try {
        const response = await deleteNotifications(body).unwrap()
        return response
      } catch (e) {
        console.error(e)
      }
    },
    [deleteNotifications],
  )

  return {
    notifications,
    updateNotifications: handleUpdateNotifications,
    deleteNotifications: handleDeleteNotifications,
    unreadNotificationsTotal,
    notificationsOpen,
    setNotificationsOpen,
    sentryRef,
    rootRef,
    isError,
    isFetching,
    ...rest,
  }
}
