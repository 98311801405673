import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useAddAdminCookieMutation } from "@/redux/services/adminCookiesApi"
import { setToast } from "@/redux/toastSlice"
import type { AddAdminCookiePayload } from "@/types/entities/cookie"

export const useAddAdminCookie = () => {
  const dispatch = useAppDispatch()
  const [addAdminCookie, response] = useAddAdminCookieMutation()

  const handleAddAdminCookie = async (body: AddAdminCookiePayload) => {
    try {
      const response = await addAdminCookie(body).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success add cookie",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(message)
    }
  }

  return { addAdminCookie: handleAddAdminCookie, response }
}
