import { FC } from "react"
import cx from "classnames"
import { MdOutlineRemoveRedEye } from "react-icons/md"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const ViewIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <MdOutlineRemoveRedEye
      className={cx("w-5 h-5 transition-opacity", className)}
      {...rest}
    />
  )
}

export default ViewIcon
