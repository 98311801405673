import { FC, useMemo } from "react"
import Table from "@/components/Table"
import { ColumnsType } from "antd/es/table"
import { DATE_FORMAT_OPTIONS } from "@/constants"
import type { Campaign } from "@/types/entities/campaign"

type Props = {
  campaigns: Campaign[]
}

type TableDataType = {
  key: string
  name: string
  date: string
}

const CampaignsTable: FC<Props> = ({ campaigns }: Props) => {
  const dataSource: TableDataType[] = useMemo(
    () =>
      campaigns.map(({ name, _id, createdAt }) => ({
        key: _id,
        name,
        date: createdAt,
      })),
    [campaigns],
  )
  const columns: ColumnsType<TableDataType> = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      key: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => Number(new Date(a.date)) - Number(new Date(b.date)),
      key: "date",
      render: (v) =>
        new Date(v).toLocaleDateString("en-US", DATE_FORMAT_OPTIONS),
    },
  ]
  return (
    <Table
      sortDirections={["ascend", "descend"]}
      dataSource={dataSource}
      columns={columns}
      showSizeChanger={false}
      pagination={{
        position: ["none"],
      }}
    />
  )
}

export default CampaignsTable
