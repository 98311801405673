import { FC, useState } from "react"
import cx from "classnames"
import { TimePicker } from "antd"
import { twMerge } from "tailwind-merge"
import OutlineButton from "../OutlineButton"
import dayjs, { type Dayjs } from "dayjs"

type Props = {
  className?: string
  date: Date
  onChange: (date: Dayjs) => void
  timeInputFormat?: string
  timeInputClassName?: string
}

const TimeInput: FC<Props> = ({
  className,
  date,
  onChange,
  timeInputFormat,
  timeInputClassName,
  ...rest
}: Props) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={cx("flex gap-2 items-center", className)}>
      <TimePicker
        value={dayjs(new Date(date))}
        onSelect={(time) => {
          onChange(time)
          setOpen(false)
        }}
        format={timeInputFormat ?? "hh : mm"}
        use12Hours
        showNow={false}
        showSecond={false}
        className={twMerge(
          cx(
            "bg-input caret-transparent border-none w-[62px] [&_input]:!leading-7 [&_input]:!font-medium flex justify-center h-8 [&_input]:text-sm",
            timeInputClassName,
          ),
        )}
        open={open}
        onOpenChange={setOpen}
        popupClassName="[&_.ant-picker-time-panel-column:last-child]:hidden" // removing AM/PM column
        suffixIcon={null}
        allowClear={false}
        inputReadOnly
        {...rest}
      />
      <div className="flex gap-0.5">
        <OutlineButton
          onClick={() => {
            const currentDate = dayjs(date)
            if (currentDate.hour() < 12) {
              return
            }
            const newDate = currentDate.subtract(12, "hour")
            onChange(newDate)
          }}
          active={new Date(date).getHours() < 12}
          className={cx("w-[51px] btn-xs text-black font-medium")}
        >
          AM
        </OutlineButton>
        <OutlineButton
          onClick={() => {
            const currentDate = dayjs(date)
            if (currentDate.hour() >= 12) {
              return
            }
            const newDate = currentDate.add(12, "hour")
            onChange(newDate)
          }}
          active={new Date(date).getHours() >= 12}
          className={cx("w-[51px] btn-xs text-black font-medium ")}
        >
          PM
        </OutlineButton>
      </div>
    </div>
  )
}

export default TimeInput
