import { FC } from "react"
import cx from "classnames"
import Select from "../Select"
import { SelectProps } from "antd"
import TextField from "../TextField"
import SearchIcon from "@/assets/search.svg"

type Props = {
  className?: string
  searchTerm: string
  onSearch: (term: string) => void
  containerClassName?: string
} & SelectProps

const SearchSelect: FC<Props> = ({
  className,
  placeholder,
  searchTerm,
  onSearch,
  ...rest
}: Props) => {
  return (
    <Select
      dropdownRender={(menu) => (
        <>
          <div className="py-2 border-b bg-bg-default border-b-outline">
            <TextField
              value={searchTerm}
              onChange={({ target }) => onSearch(target.value)}
              placeholder={placeholder as string}
              suffixIcon={<SearchIcon className="w-5 h-5" />}
              className="!outline-none pl-3.5 pr-8 focus:!bg-transparent bg-transparent h-6 text-sm leading-6"
            />
          </div>
          <div className="max-h-72 overflow-y-auto">{menu}</div>
        </>
      )}
      popupMatchSelectWidth={false}
      placeholder={placeholder}
      className={cx("", className)}
      rootClassName="p-0"
      searchValue={searchTerm}
      {...rest}
    />
  )
}

export default SearchSelect
