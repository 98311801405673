import { MEDIA_BREAKPOINTS } from "@/constants"
import { useWindowSize } from "usehooks-ts"

export const useBreakpoints = () => {
  const { width } = useWindowSize()

  return {
    sm: width > MEDIA_BREAKPOINTS.sm,
    md: width > MEDIA_BREAKPOINTS.md,
  }
}
