import { logoutUserAction } from "@/redux/authSlice"
import { Routes } from "@/core/routing/routes"
import { useAppDispatch } from "@/redux/hooks"
import { useNavigate } from "react-router-dom"

export const useLogout = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const logoutCurrentUser = () => {
    dispatch(logoutUserAction())
    navigate(Routes.login)
  }

  return logoutCurrentUser
}
