import { FC } from "react"
import cx from "classnames"
import { MdOutlineNotificationsActive as BellActiveIcon } from "react-icons/md"
import Checkbox from "@/components/Checkbox"
import dayjs from "dayjs"
import type { Notification } from "@/types/entities/user"

type Props = {
  className?: string
  notification: Notification
  isRead?: boolean
  checked: boolean
  onToggle: (checked: boolean, item: Notification) => void
  onUnreadClicked: (notification: Notification) => void
}

const NotificationItem: FC<Props> = ({
  className,
  notification,
  isRead = false,
  checked,
  onToggle,
  onUnreadClicked,
  ...rest
}: Props) => {
  return (
    <div
      className={cx(
        "px-4 py-3 border-b border-b-outline flex gap-2 items-start",
        {
          "bg-bg-default": !isRead,
        },
      )}
      {...rest}
    >
      <Checkbox
        checked={checked}
        onChange={({ target: { checked } }) => onToggle(checked, notification)}
        className="checkbox-primary h-[18px] w-[18px] mt-[7px]"
      />
      {isRead ? (
        <BellActiveIcon className="opacity-60 h-6 basis-6 translate-y-1" />
      ) : (
        <BellActiveIcon
          onClick={() => onUnreadClicked(notification)}
          className="active:scale-90 cursor-pointer h-8 basis-6 text-primary"
        />
      )}
      <div className="space-y-1 flex-1 break-all">
        <span
          className={cx("text-sm leading-6", {
            "font-semibold": !isRead,
          })}
        >
          {notification.text}
        </span>
        <p className="block opacity-60 text-xs">
          {dayjs(notification.createdAt).format("M/D/YYYY [at] HH:mm")}
        </p>
      </div>
    </div>
  )
}

export default NotificationItem
