import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import { buildQueryString } from "@/utils/strings"
import { exportFileResponseHandler } from "@/utils/api"
import type {
  CreateEditMasterListPayload,
  MasterList,
  SearchCriteriaMasterList,
  SingleMasterList,
} from "@/types/entities/master-list"
import type { PaginationProps } from "./types"

export type ImportCSVPayload = {
  orgId: string
  name: string
  csv: File | Blob
}

export const masterListsApi = createApi({
  reducerPath: "masterListsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Master Lists", "Search Criteria ML", "Campaigns ML"],
  endpoints: (builder) => ({
    getMasterList: builder.query<
      SingleMasterList,
      {
        id: string
        recordStatus: string[]
        refetch?: string
        text?: string
      } & PaginationProps
    >({
      query: ({ id, ...rest }) => ({
        method: "GET",
        url: `masterlist/${id}?${buildQueryString(rest)}`,
      }),
      transformResponse: ({ data }: { data: SingleMasterList }) => data,
      providesTags: (result) =>
        result
          ? [
              ...result.records.map(({ _id }) => ({
                type: "Master Lists" as const,
                _id,
              })),
              { type: "Master Lists", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Master Lists", id: "PARTIAL-LIST" }],
    }),
    getMasterLists: builder.query<
      { items: MasterList[]; total: number },
      { orgId: string } & PaginationProps & { text?: string }
    >({
      query: ({ orgId, ...keys }) => ({
        method: "GET",
        url: `org/${orgId}/masterlists?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: MasterList[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Master Lists" as const,
                id: _id,
              })),
              "Master Lists",
            ]
          : ["Master Lists"],
    }),
    addMasterList: builder.mutation<
      MasterList,
      { body: { name: string; orgId: string; records: any[] } }
    >({
      query: ({ body }) => ({
        method: "POST",
        url: "masterlist/records",
        body,
      }),
      transformResponse: ({ data }: { data: MasterList }) => data,
      invalidatesTags: ["Master Lists", "Search Criteria ML"],
    }),
    addMasterListCSV: builder.mutation<{ data: unknown }, { body: FormData }>({
      query: ({ body }) => ({
        method: "POST",
        url: "masterlist/csv",
        body,
      }),
      invalidatesTags: ["Master Lists"],
    }),
    editMasterList: builder.mutation<
      { data: MasterList },
      { body: CreateEditMasterListPayload; id: string }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `masterlist/${id}`,
        body,
      }),
      invalidatesTags: ["Master Lists"],
    }),
    editMasterListImportCSV: builder.mutation<
      { data: MasterList },
      { body: FormData; id: string }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `masterlist/${id}/csv`,
        body,
      }),
      invalidatesTags: ["Master Lists"],
    }),
    deleteMasterList: builder.mutation<
      { data: boolean },
      { id: string; params?: string }
    >({
      query: ({ id, params = "" }) => ({
        method: "DELETE",
        url: `masterlist/${id}?${params}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Master Lists", id },
        { type: "Master Lists", id: "PARTIAL-LIST" },
      ],
    }),
    associateCampaign: builder.mutation<
      { data: boolean },
      { id: string; body: { campaign: string } }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `masterlist/${id}/associate`,
        body,
      }),
    }),
    disassociateCampaign: builder.mutation<
      { data: boolean },
      { id: string; body: { campaign: string } }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `masterlist/${id}/disassociate`,
        body,
      }),
    }),
    getSearchCriteriaML: builder.query<
      SearchCriteriaMasterList[],
      { org: string; searchCriteria?: string }
    >({
      query: ({ org, searchCriteria }) => ({
        url: `searchCriteria/masterlists/all?${buildQueryString({
          org,
          searchCriteria,
        })}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      providesTags: ["Search Criteria ML"],
      transformResponse: ({ data }: { data: SearchCriteriaMasterList[] }) =>
        data,
    }),
    getCampaignsML: builder.query<MasterList[], { id: string }>({
      query: ({ id }) => ({
        url: `campaign/${id}/masterlists/all`,
        method: "GET",
      }),
      providesTags: ["Campaigns ML"],
      transformResponse: ({ data }: { data: MasterList[] }) => data,
    }),
    exportML: builder.mutation<
      unknown,
      { id: string; body: Partial<{ text: string; recordStatus: string[] }> }
    >({
      query: ({ id, body }) => ({
        url: `masterlist/${id}/export`,
        method: "POST",
        responseHandler: async (response) =>
          await exportFileResponseHandler(response, "Master List.csv"),
        cache: "no-cache",
        body,
      }),
    }),
  }),
})

export const {
  useGetMasterListQuery,
  useGetMasterListsQuery,
  useAddMasterListMutation,
  useAddMasterListCSVMutation,
  useEditMasterListMutation,
  useEditMasterListImportCSVMutation,
  useDeleteMasterListMutation,
  useAssociateCampaignMutation,
  useDisassociateCampaignMutation,
  useGetSearchCriteriaMLQuery,
  useLazyGetMasterListQuery,
  useGetCampaignsMLQuery,
  useExportMLMutation,
} = masterListsApi
