import { FC, useMemo, useState } from "react"
import cx from "classnames"
import TextField from "@/components/TextField"
import { usePagination } from "@/hooks/usePagination"
import Button from "@/components/Button"
import SearchIcon from "@/assets/search.svg"
import AddIcon from "@/components/icons/AddIcon"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import EmptyList from "@/components/EmptyList"
import { useGetAdminSpamAccountsQuery } from "@/redux/services/adminSpamAccountsApi"
import AddEditSpamAccountModal from "./features/AddEditSpamAccountModal"
import AdminSpamTestingTable from "./features/AdminSpamTestingTable"
import ErrorMessage from "@/components/ErrorMessage"
import DeleteSpamTestingModal from "./features/AddEditSpamAccountModal/DeleteSpamAccountModal"
import Loader from "@/components/Loader"
import type { Key } from "antd/es/table/interface"

type Props = {
  className?: string
}

const AdminSpamTestingPage: FC<Props> = ({ className, ...rest }: Props) => {
  const {
    searchTerm,
    limit,
    skip,
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
  } = usePagination()
  const {
    data: { items: spams, total } = { items: [], total: 0 },
    isLoading,
    isFetching,
    isError,
  } = useGetAdminSpamAccountsQuery({ limit, skip })
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([])

  const handleOpenAddModal = () => setOpenAddModal(true)

  const filteredSpams = useMemo(
    () =>
      searchTerm
        ? spams.filter(({ displayName }) =>
            displayName
              .trim()
              .toLocaleLowerCase()
              .includes(searchTerm.trim().toLocaleLowerCase()),
          )
        : spams,
    [searchTerm, spams],
  )

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className={cx("", className)} {...rest}>
        <div className="flex justify-between items-center flex-wrap sm:flex-nowrap gap-1">
          <h2 className="leading-8 text-2xl font-semibold">
            Spam Test Accounts
          </h2>
          <div className="flex gap-4 items-center">
            {selectedRowsKeys.length ? (
              <div className="flex gap-4 pr-2">
                <BinIcon
                  onClick={() => setOpenDeleteModal(true)}
                  className="active:scale-90 w-6 h-6 cursor-pointer opacity-60 hover:text-primary hover:opacity-100"
                />
              </div>
            ) : null}
            <TextField
              value={searchTerm}
              onChange={handleSearchTermChange}
              placeholder="Search account"
              startIcon={<SearchIcon className="w-6 h-6" />}
              className="text-sm outline outline-outline input-sm !py-1.5"
              rootClassName="basis-56"
              type="search"
            />
            <Button onClick={handleOpenAddModal} className="btn-primary btn-sm">
              <AddIcon className="w-6 h-6" />
              <span className="hidden sm:inline">Add Account</span>
            </Button>
          </div>
        </div>
        <div
          className={cx("mt-5 flex px-2 py-1 min-h-[292px]", {
            "justify-center items-center": !spams.length,
          })}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {spams.length ? (
                <div className="w-full">
                  <AdminSpamTestingTable
                    spams={filteredSpams}
                    page={skip}
                    total={total}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onRowSelect={setSelectedRowsKeys}
                    selectedRowKeys={selectedRowsKeys}
                    loading={isFetching}
                  />
                </div>
              ) : (
                <EmptyList message="There are no spam test accounts yet">
                  <Button
                    onClick={handleOpenAddModal}
                    className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                  >
                    <AddIcon className="transition-colors text-primary group-hover:text-white" />
                    <span className="transition-colors font-medium text-primary group-hover:text-white">
                      Add Account
                    </span>
                  </Button>
                </EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      <AddEditSpamAccountModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
      />
      <DeleteSpamTestingModal
        data={selectedRowsKeys
          .map((key) => spams.find(({ _id }) => _id === key)!)
          .filter(Boolean)}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
      />
    </>
  )
}

export default AdminSpamTestingPage
