import React, { FC, useEffect, useRef } from "react"
import cx from "classnames"

type Props = {
  className?: string
  indeterminate?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const Checkbox: FC<Props> = ({
  checked,
  className,
  indeterminate,
  ...rest
}: Props) => {
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (indeterminate) {
      ref.current!.indeterminate = true
    } else {
      ref.current!.indeterminate = false
    }
  }, [indeterminate])
  return (
    <input
      type="checkbox"
      checked={checked}
      className={cx(
        "checkbox border-[1.5px] border-outline-dark rounded-[4px] disabled:bg-transparent disabled:border-outline disabled:opacity-100",
        className,
      )}
      ref={ref}
      {...rest}
    />
  )
}

export default Checkbox
