import { FC } from "react"
import Drawer from "@/components/Drawer"
import Loader from "@/components/Loader"
import CriteriaSummaryPane from "@/pages/AddEditSearchCriteria/features/CriteriaSummaryPane"
import type { DrawerProps } from "antd"

type Props = {
  className?: string
  loading: boolean
  name: string
} & DrawerProps

const SearchCriteriaDrawer: FC<Props> = ({
  open,
  onClose,
  loading,
  name,
  ...rest
}: Props) => {
  return (
    <Drawer
      title={
        <div className="flex gap-4 items-center">
          <h2 className="text-lg font-semibold">Details</h2>
        </div>
      }
      open={open}
      onClose={onClose}
      width={600}
      {...rest}
    >
      {loading ? (
        <div className="h-full w-full flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <CriteriaSummaryPane name={name} className="shadow-none" />
      )}
    </Drawer>
  )
}

export default SearchCriteriaDrawer
