import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import DeleteButton from "@/components/Button/DeleteButton"
import CancelButton from "@/components/OutlineButton/CancelButton"
import { useDeleteAiPrompt } from "../../hooks/useDeleteAIPrompt"
import type { AiPrompt } from "@/types/entities/ai-prompt"

type Props = {
  className?: string
  data?: AiPrompt
} & ModalProps

const DeletePromptModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const { deleteAiPrompt, response } = useDeleteAiPrompt()
  if (!data) {
    return null
  }
  const handleDelete = async () => {
    await deleteAiPrompt(data._id)
    onClose && onClose()
  }
  return (
    <Modal
      title="Delete Prompt"
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={response.isLoading} onClick={onClose} />
          <DeleteButton disabled={response.isLoading} />
        </div>
      }
      {...rest}
    >
      <span className="leading-6 text-base">
        Are you sure you want to delete{" "}
        <span className="font-semibold">
          “{data.text.slice(0, 30) + "..."}”
        </span>
        ?
      </span>
    </Modal>
  )
}

export default DeletePromptModal
