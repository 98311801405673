import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import { useAddExtraDomainMutation } from "@/redux/services/domainsApi"
import { setToast } from "@/redux/toastSlice"
import {
  selectDomainSetupDKIM,
  selectDomainSetupId,
} from "@/redux/domainSetupSlice"

export const useDomainAddExtra = () => {
  const dispatch = useAppDispatch()
  const DKIM = useAppSelector(selectDomainSetupDKIM)
  const domainId = useAppSelector(selectDomainSetupId)
  const [addExtraDomain, response] = useAddExtraDomainMutation()

  const handleAddExtraDomain = async () => {
    try {
      const addExtraResponse = await addExtraDomain({
        body: { DKIM },
        id: domainId,
      }).unwrap()
      return addExtraResponse
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { addExtraDomain: handleAddExtraDomain, response }
}
