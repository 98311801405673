import { useEffect } from "react"
import { useAppDispatch } from "@/redux/hooks"
import { setPageTitle as setPageTitleAction } from "@/redux/appSlice"

export const usePageTitle = (pageTitle: string | (() => React.ReactNode)) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setPageTitleAction(pageTitle))
  }, [pageTitle, dispatch])

  const setPageTitle = (pageTitle: string) => {
    dispatch(setPageTitleAction(pageTitle))
  }

  return setPageTitle
}
