import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import DeleteButton from "@/components/Button/DeleteButton"
import CancelButton from "@/components/OutlineButton/CancelButton"
import { useDeleteAdminAccount } from "../../hooks/useDeleteGsuiteAccount"
import type { GsuiteAccount } from "@/types/entities/gsuite-account"

type Props = {
  className?: string
  data: GsuiteAccount[]
} & ModalProps

const DeleteGsuiteModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const {
    deleteAdminAccount,
    response: { isLoading },
  } = useDeleteAdminAccount()
  const handleDelete = async () => {
    try {
      await Promise.all(data.map(({ _id }) => deleteAdminAccount(_id)))
      onClose && onClose()
    } catch (e) {
      console.error(e)
    }
  }
  if (!data.length) {
    return null
  }
  const title =
    data.length > 1
      ? `Delete ${data.length} Admin Accounts`
      : "Delete Admin Account"
  const accountsText =
    data.length > 1
      ? `${data.length} admin accounts`
      : `"${data[0].displayName}"`
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={isLoading} onClick={onClose} />
          <DeleteButton disabled={isLoading} />
        </div>
      }
      {...rest}
    >
      <span className="leading-6 text-base">
        Are you sure you want to delete{" "}
        <span className="font-semibold">{accountsText}</span>?
      </span>
    </Modal>
  )
}

export default DeleteGsuiteModal
