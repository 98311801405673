import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
  value: number | string
  max?: number
}

const Progress: FC<Props> = ({
  className,
  value,
  max = 100,
  ...rest
}: Props) => {
  return (
    <progress
      className={cx("w-full h-1.5", className)}
      value={value}
      max={max}
      {...rest}
    ></progress>
  )
}

export default Progress
