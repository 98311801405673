import { Fragment, useId, useRef, useState } from "react"
import classnames from "classnames"
import { cx } from "@/utils/strings"
import { NavLink, useParams, useNavigate } from "react-router-dom"
import LogoIcon from "../icons/LogoIcon"
import { DASHBOARD_PARAM, LINKS } from "./constants"
import Select from "@/components/Select"
import ArrowDropdownIcon from "@/assets/arrow-drop-down.svg"
import OrganizationsSelect from "./OrganizationsSelect"
import { useEffectOnce } from "usehooks-ts"
import { Routes } from "@/core/routing/routes"
import { filterOption } from "../Select/constants"
import { useStatusOrganizations } from "@/hooks/useStatusOrganizations"

type Props = {
  className?: string
  showLogo?: boolean
  onLinkChange?: () => void
}

const Navbar = ({ className, showLogo = true, onLinkChange }: Props) => {
  const orgSelectId = useId()
  const [searchTerm, setSearchTerm] = useState("")
  const textFieldRef = useRef<HTMLInputElement>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [renderId, setRenderId] = useState(1)
  const { id } = useParams()
  const navigate = useNavigate()

  useEffectOnce(() => {
    document.getElementById(orgSelectId)?.remove() // this fixes search field not focusing
  })

  const handleDropdownOpenChange = (open: boolean) => {
    setRenderId((prev) => prev + 1)
    setDropdownOpen(open)
    setSearchTerm("")
  }
  const { currentOrgActive, active, disabled } = useStatusOrganizations()
  const organizations = currentOrgActive ? active : disabled

  const organizationsSelectOptions = organizations
    .toSorted(
      (a, b) => Number(new Date(a.createdAt)) - Number(new Date(b.createdAt)),
    )
    .map(({ name, _id }) => ({
      label: name,
      value: _id,
    }))
  const selectedOrganization = organizations.find(({ _id }) => _id === id)

  return (
    <nav
      className={cx(
        "hidden md:w-44 lg:w-64 bg-white border-r border-r-outline h-screen overflow-x-hidden overflow-auto px-2 pt-6 pb md:flex flex-col gap-6",
        className,
      )}
    >
      {showLogo && (
        <div className="self-center">
          <LogoIcon className="h-9 w-[146px]" />
        </div>
      )}
      <ul className="list-none flex flex-col gap-2 flex-1">
        {LINKS.map(({ Icon, title, to }, i) => (
          <NavLink
            to={to?.replace(":id", id!) ?? i.toString()}
            onClick={() => onLinkChange && onLinkChange()}
            key={i}
            className={({ isActive }) =>
              classnames(
                "flex items-center gap-2 cursor-pointer p-2 rounded-md text-black-secondary hover:text-black-secondary hover:bg-primary-outline",
                { "!bg-primary !text-white": isActive },
              )
            }
          >
            <Icon className="w-6 h-6" />
            <span className="font-medium text-sm">{title}</span>
          </NavLink>
        ))}
      </ul>
      <div className="py-4 flex flex-col gap-2">
        <Select
          id={orgSelectId}
          options={organizationsSelectOptions}
          searchValue={searchTerm}
          dropdownRender={(menu) => (
            <Fragment key={renderId}>
              <OrganizationsSelect
                searchTerm={searchTerm}
                onSearch={setSearchTerm}
                menu={menu}
                textFieldRef={textFieldRef}
              />
            </Fragment>
          )}
          open={dropdownOpen}
          onDropdownVisibleChange={handleDropdownOpenChange}
          className="h-11"
          popupMatchSelectWidth={true}
          placeholder="Select organization"
          suffixIcon={<ArrowDropdownIcon />}
          value={selectedOrganization?._id ?? ""}
          onChange={(_id) => {
            navigate(
              Routes.organization.dashboard.replace(":id", _id) +
                DASHBOARD_PARAM,
            )
            onLinkChange && onLinkChange()
          }}
          filterOption={filterOption}
        />
      </div>
    </nav>
  )
}

export default Navbar
