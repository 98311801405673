import { createContext } from "react"

type Props = {
  dkimNavigatedTo: boolean
  setDkimNavigatedTo: React.Dispatch<React.SetStateAction<boolean>>
}

export const DomainsContext = createContext<Props>({
  dkimNavigatedTo: false,
  setDkimNavigatedTo: () => {},
})
