import { FC } from "react"
import cx from "classnames"
import { FaLinkedin } from "react-icons/fa6"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const LinkedInIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <FaLinkedin
      className={cx("w-5 h-5 transition-opacity", className)}
      {...rest}
    />
  )
}

export default LinkedInIcon
