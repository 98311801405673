import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import cx from "classnames"

type Props = {
  open: boolean
  title?: string
  children: React.ReactNode
  actions?: React.ReactNode
  className?: string
  boxClassName?: string
  contentClassName?: string
  onSubmit?: (ev: React.FormEvent<HTMLFormElement>) => void
  onClose?: () => void
}
export type ModalProps = Pick<Props, "open" | "onClose">

const Modal = ({
  open = false,
  title,
  children,
  actions,
  className,
  boxClassName,
  contentClassName,
  onSubmit,
  onClose,
}: Props) => {
  const ref = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    open ? ref.current?.show() : ref.current?.close()
  }, [open])

  if (!open) {
    return null
  }

  return createPortal(
    <dialog
      onKeyDown={({ key }) => key === "Escape" && onClose && onClose()}
      ref={ref}
      className={cx("modal bg-[#000] bg-opacity-30", className)}
    >
      <div
        className={cx(
          "modal-box flex flex-col font-sans pt-4 pb-0 px-0 bg-white overflow-hidden",
          boxClassName,
        )}
      >
        <h3 className="px-6 font-semibold text-xl pb-4 border-b border-b-outline">
          {title}
        </h3>
        <div
          className={cx(
            "p-6 text-black overflow-y-auto max-h-[420px]",
            contentClassName,
          )}
        >
          {children}
        </div>
        <div className="modal-action px-6 mt-0 py-4 bg-bg-default border-t border-t-outline">
          <form
            onSubmit={(e) => {
              document.querySelector("dialog")?.focus()
              onSubmit && onSubmit(e)
            }}
            method="dialog"
          >
            {actions}
          </form>
        </div>
      </div>
    </dialog>,
    document.body,
  )
}

export default Modal
