import { FC } from "react"
import Modal from "@/components/Modal"
import Button from "@/components/Button"
import OutlineButton from "@/components/OutlineButton"
import { useAdminSetOrganizationStatus } from "../../../hooks/useAdminSetOrganizationStatus"
import type {
  AdminOrganization,
  OrganizationStatus,
} from "@/types/entities/organization"

type Props = {
  open: boolean
  onClose: () => void
  initialName: string
  organizationId: string
  currentStatus: OrganizationStatus
  onUpdateStatusSuccess?: (response: AdminOrganization) => void
}

const AdminSetOrganizationStatusModal: FC<Props> = ({
  initialName,
  open,
  onClose,
  currentStatus,
  organizationId,
  onUpdateStatusSuccess,
}: Props) => {
  const { setAdminOrganizationStatus, response } =
    useAdminSetOrganizationStatus()

  const newStatus: OrganizationStatus =
    currentStatus === "disabled" ? "enabled" : "disabled"
  const newStatusActionTitle =
    currentStatus === "disabled" ? "Enable" : "Disable"

  const handleUpdateOrganizationStatus = async () => {
    const response = await setAdminOrganizationStatus({
      id: organizationId,
      status: newStatus,
    })
    onUpdateStatusSuccess && response && onUpdateStatusSuccess(response.data)
    onClose()
  }

  return (
    <Modal
      open={open}
      title={`${newStatusActionTitle} Organization`}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={onClose}
            className="text-black btn-sm min-w-[86px]"
            type="button"
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={response.isLoading}
            onClick={handleUpdateOrganizationStatus}
            className="btn-sm min-w-[86px]"
            type="submit"
          >
            {newStatusActionTitle}
          </Button>
        </div>
      }
    >
      <span className="text-base">
        Are you sure you want to {newStatusActionTitle.toLowerCase()}
        <span className="font-semibold"> “{initialName}”</span>?
      </span>
    </Modal>
  )
}

export default AdminSetOrganizationStatusModal
