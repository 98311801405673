import { useAppDispatch } from "@/redux/hooks"
import {
  type ExportAdminDNCPayload,
  useExportAdminDNCMutation,
} from "@/redux/services/adminDncApi"
import { setToast } from "@/redux/toastSlice"

export const useExportAdminDNC = () => {
  const dispatch = useAppDispatch()
  const [exportAdminDNC, response] = useExportAdminDNCMutation()

  const handleExport = async (body: ExportAdminDNCPayload) => {
    try {
      await exportAdminDNC({ body }).unwrap()
      dispatch(setToast({ show: true, message: "Success", variant: "success" }))
    } catch (e) {
      dispatch(
        setToast({
          show: true,
          message: "Error downloading file",
          variant: "error",
        }),
      )
    }
  }
  return {
    exportAdminDnc: handleExport,
    ...response,
  }
}
