import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import type { SenderPayload, SenderAPIResponse } from "@/types/entities/sender"
import type {
  AddEmailAddressPayload,
  AddEmailAddressResponse,
  ConnectEmailOAuthPayload,
  EditEmailAddressPayload,
  EmailAddress,
} from "@/types/entities/email-address"
import { buildQueryString } from "@/utils/strings"

export const sendersApi = createApi({
  reducerPath: "sendersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Senders", "Email Addresses"],
  endpoints: (builder) => ({
    getSenders: builder.query<
      { items: SenderAPIResponse[]; total: number },
      { orgId: string; skip?: number; limit?: number; text?: string }
    >({
      query: ({ orgId, ...keys }) => ({
        method: "GET",
        url: `org/${orgId}/senders?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: SenderAPIResponse[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: ["Senders"],
    }),
    getEmails: builder.query<
      { items: EmailAddress[]; total: number },
      {
        orgId: string
        skip?: number
        limit?: number
        senderId?: string
        text?: string
      }
    >({
      query: ({ orgId, ...keys }) => ({
        method: "GET",
        url: `org/${orgId}/emailAddresses?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: EmailAddress[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Email Addresses" as const,
                _id,
              })),
              { type: "Email Addresses", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Email Addresses", id: "PARTIAL-LIST" }],
    }),
    addSender: builder.mutation<{ data: SenderAPIResponse }, SenderPayload>({
      query: (body) => ({
        method: "POST",
        url: "sender",
        body,
      }),
      invalidatesTags: ["Senders"],
    }),
    editSender: builder.mutation<
      { data: SenderAPIResponse },
      { body: Partial<SenderPayload>; id: string }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `sender/${id}`,
        body,
      }),
      invalidatesTags: ["Senders"],
    }),
    deleteSender: builder.mutation<
      { data: { success: boolean } },
      { id: string }
    >({
      query: ({ id }) => ({
        method: "DELETE",
        url: `sender/${id}`,
      }),
      invalidatesTags: ["Senders"],
    }),
    addEmailAddress: builder.mutation<
      AddEmailAddressResponse,
      { id: string; body: AddEmailAddressPayload }
    >({
      query: ({ body, id }) => ({
        method: "POST",
        url: `sender/${id}/email`,
        body,
      }),
      invalidatesTags: ["Email Addresses"],
      transformResponse: ({ data }: { data: AddEmailAddressResponse }) => data,
    }),
    editEmailAddress: builder.mutation<
      { data: boolean },
      { id: string; body: EditEmailAddressPayload }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `sender/${id}/email`,
        body,
      }),
      invalidatesTags: ["Email Addresses"],
    }),
    deleteEmailAddress: builder.mutation<{ data: boolean }, { id: string }>({
      query: ({ id }) => ({
        method: "DELETE",
        url: `sender/email/${id}`,
      }),
      invalidatesTags: ["Email Addresses"],
    }),
    connectEmailOAuth: builder.mutation<
      { data: boolean },
      {
        id: string
        body: ConnectEmailOAuthPayload
      }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `sender/${id}/email/oauth`,
        body,
      }),
      invalidatesTags: ["Email Addresses"],
    }),
    disconnectEmailOAuth: builder.mutation<
      { data: boolean },
      {
        id: string
        body: { emailId: string }
      }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `sender/${id}/email/oauth/disconnect`,
        body,
      }),
      invalidatesTags: ["Email Addresses"],
    }),
  }),
})

export const {
  useGetSendersQuery,
  useGetEmailsQuery,
  useAddSenderMutation,
  useEditSenderMutation,
  useDeleteSenderMutation,
  useAddEmailAddressMutation,
  useEditEmailAddressMutation,
  useDeleteEmailAddressMutation,
  useConnectEmailOAuthMutation,
  useDisconnectEmailOAuthMutation,
} = sendersApi
