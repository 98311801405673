import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
}

const InstructionStepOne: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <div className={cx("", className)} {...rest}>
      <div>
        If you are using Google Workspace for your accounts, you will need to
        enable a few settings in your Google account before you can connect them
        to DreamSender:
      </div>
      <ol className="list-decimal list-inside mt-2 space-y-2">
        <li>Enable IMAP settings</li>
        <li>Enable 2-Step verification</li>
        <li>Create an App password</li>
      </ol>
    </div>
  )
}

export default InstructionStepOne
