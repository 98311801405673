import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"

type Props = {
  className?: string
  version?: number
  onDeleteSuccess: (version: number) => void
} & ModalProps

const VersionDeleteModal: FC<Props> = ({
  className,
  open,
  version,
  onClose,
  onDeleteSuccess,
  ...rest
}: Props) => {
  if (version === undefined || version < 0) {
    return null
  }
  const handleSubmit = () => {
    onDeleteSuccess(version)
    onClose && onClose()
  }

  return (
    <Modal
      title="Delete Version"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            className={cx("text-black btn-sm !w-16", className)}
            type="button"
            onClick={onClose}
            {...rest}
          >
            No
          </OutlineButton>
          <Button onClick={handleSubmit} type="button" className="!w-16 btn-sm">
            Yes
          </Button>
        </div>
      }
      className={cx("", className)}
      {...rest}
    >
      <span className="text-sm">
        Are you sure you want to delete{" "}
        <span className="font-semibold">Version {version + 1}?</span>
      </span>
    </Modal>
  )
}

export default VersionDeleteModal
