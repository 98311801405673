import { FC } from "react"
import { useFormik } from "formik"
import Button from "@/components/Button"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import TextField from "@/components/TextField"
import { useAddCampaign } from "../../hooks/useAddCampaign"
import type { Campaign } from "@/types/entities/campaign"

type Props = {
  onConfirm: ({ name, data }: { name: string; data?: Campaign }) => void
} & ModalProps

const AddCampaignModal: FC<Props> = ({ open, onClose, onConfirm }: Props) => {
  const { addCampaign, response } = useAddCampaign()
  const {
    values: { name },
    getFieldProps,
    handleSubmit,
    resetForm,
  } = useFormik<{ name: string }>({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    onSubmit: async ({ name }) => {
      const data = await addCampaign({
        name,
      })
      onConfirm({ name, data })
      handleClose()
    },
  })
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }

  return (
    <Modal
      title="Campaign Name"
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={handleClose}
            disabled={response.isLoading}
            className="text-black btn-sm !w-[86px]"
            type="button"
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={!name || response.isLoading}
            className="btn-sm !w-[86px]"
            type="submit"
          >
            Add
          </Button>
        </div>
      }
    >
      <TextField
        topLeftLabel="Campaign Name"
        placeholder="Enter campaign name"
        className="input-sm"
        rootClassName="-mt-2"
        {...getFieldProps("name")}
      />
    </Modal>
  )
}

export default AddCampaignModal
