import { useContext } from "react"
import { SpamAccountLoginContext } from "../context"

export const useSpamAccountLogin = () => {
  const ctx = useContext(SpamAccountLoginContext)
  if (!ctx) {
    throw new Error(
      "useSpamAccountLogin must be used within a SpamAccountLoginContext",
    )
  }
  return ctx
}
