import { FC, useMemo } from "react"
import cx from "classnames"
import Checkbox from "@/components/Checkbox"
import { useGetCriteriaFiltersQuery } from "@/redux/services/searchCriteriaApi"
import { useAppSelector } from "@/redux/hooks"
import { selectCriteriaFilter } from "@/redux/searchCriteriaSetupSlice"
import { useCriteriaSetup } from "@/pages/AddEditSearchCriteria/hooks/useCriteriaSetup"
import { useActiveFilterContext } from "@/pages/AddEditSearchCriteria/hooks/useActiveFilterContext"
import type { FilterType } from "@/types/entities/search-criteria"

type Props = {
  className?: string
}

const CriteriaYearPositionFilter: FC<Props> = ({
  className,
  ...rest
}: Props) => {
  const FILTER_TYPE = useActiveFilterContext() as FilterType
  const { data: filters = [] } = useGetCriteriaFiltersQuery()
  const { selections: yearPositionSelections } = useAppSelector(
    selectCriteriaFilter(FILTER_TYPE),
  )!
  const { onSingleSelectionChange } = useCriteriaSetup()
  const yearPositionFilterValues = useMemo(
    () => filters.find(({ type }) => type === FILTER_TYPE)?.values ?? [],
    [FILTER_TYPE, filters],
  )
  return (
    <div className={cx("flex flex-col gap-4", className)} {...rest}>
      {yearPositionFilterValues.map(({ id, description }) => (
        <div className="flex items-center" key={id}>
          <Checkbox
            id={id}
            value={id}
            checked={Boolean(
              yearPositionSelections.INCLUDED.findIndex((v) => v === id) !== -1,
            )}
            onChange={onSingleSelectionChange("INCLUDED", FILTER_TYPE)}
            className="checkbox-primary w-[18px] h-[18px]"
          />
          <label
            className="cursor-pointer pl-2.5 leading-6 text-sm"
            htmlFor={id}
          >
            {description}
          </label>
        </div>
      ))}
    </div>
  )
}

export default CriteriaYearPositionFilter
