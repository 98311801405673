import { useState } from "react"
import { useGetSequencesQuery } from "@/redux/services/sequencesApi"
import { useParams } from "react-router-dom"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { PAGINATION_LIMIT, usePagination } from "@/hooks/usePagination"

export const useInfiniteScrollSequences = () => {
  const { id } = useParams()
  const [skip, setSkip] = useState(0)
  const { text } = usePagination()
  const {
    data: { items, total } = { items: [], total: 0 },
    isLoading,
    isFetching,
    isError,
    ...rest
  } = useGetSequencesQuery(
    {
      orgId: id!,
      skip,
      text,
      limit: PAGINATION_LIMIT,
    },
    { refetchOnMountOrArgChange: true },
  )
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading || isFetching,
    hasNextPage: skip + PAGINATION_LIMIT < total,
    onLoadMore: () => setSkip((skip) => skip + PAGINATION_LIMIT),
    disabled: isError,
    rootMargin: "0px 0px 200px 0px",
  })

  return {
    sentryRef,
    rootRef,
    items,
    total,
    isLoading,
    isFetching,
    isError,
    ...rest,
  }
}
