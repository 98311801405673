import { FC, useEffect, useRef, useState } from "react"
import OutlineButton from "@/components/OutlineButton"
import { BiPlus as AddIcon } from "react-icons/bi"
import { useAppSelector } from "@/redux/hooks"
import { useEffectOnce } from "usehooks-ts"
import { selectCampaignSetupMasterList } from "@/redux/campaignSetupSlice"
import CampaignMasterListRow from "./CampaignMasterListRow"
import { useCampaignSetup } from "../hooks/useCampaignSetup"
import { useElementWidth } from "@/hooks/useElementWidth"
import CampaignMasterListRowDeleteModal from "./CampaignMasterListRowDeleteModal"
import type { MasterList } from "@/types/entities/master-list"
import type { EditCampaignPayload } from "@/types/entities/campaign"

type Props = {
  masterLists: MasterList[]
  onChange: (body: EditCampaignPayload) => void
}

const CampaignMasterListView: FC<Props> = ({
  masterLists,
  onChange,
}: Props) => {
  const firstColumnRef = useRef<HTMLDivElement>(null)
  const width = useElementWidth(firstColumnRef)
  const selectedLists = useAppSelector(selectCampaignSetupMasterList)
  const { addMasterListRow, changeSelectedMasterLists, removeListByIndex } =
    useCampaignSetup()
  const [removedListItemToken, setRemovedListItemToken] = useState<
    string | number
  >("")

  useEffect(
    () =>
      onChange({
        masterlist: selectedLists.filter(Boolean).map((v) => v!._id),
      }),
    [onChange, selectedLists],
  )
  useEffectOnce(() => {
    if (!selectedLists.filter(Boolean).length && masterLists.length === 1) {
      changeSelectedMasterLists(0, masterLists[0]._id)
    }
  })

  const handleRemoveList = (removeToken: number | string) => {
    if (typeof removeToken === "string") {
      setRemovedListItemToken(removeToken)
    } else {
      removeListByIndex(removeToken)
    }
  }

  const availableLists = masterLists.filter(({ name }) => {
    return !selectedLists.find((v) => {
      if (!v) {
        return false
      }
      return v.name === name
    })
  })

  return (
    <>
      <div className="flex flex-col gap-5">
        <h3 className="text-lg leading-7 font-semibold">Master List</h3>
        <div className="flex flex-col gap-0">
          <div className="flex py-2.5 gap-3 border-b border-b-outline-dark">
            <div ref={firstColumnRef} className="basis-2/6 sm:basis-1/4">
              <div className="text-xs font-semibold leading-[22px]">
                List Name
              </div>
            </div>
            <div className="basis-4/6 sm:basis-3/4">
              <div className="text-xs font-semibold leading-[22px]">Usage</div>
            </div>
          </div>
          {selectedLists.map((selectedList, i) => (
            <CampaignMasterListRow
              key={i}
              index={i}
              availableMasterLists={availableLists}
              selectedList={selectedList}
              onMasterListSelect={(selectedId) =>
                changeSelectedMasterLists(i, selectedId)
              }
              onMasterListRemove={handleRemoveList}
              firstColumnWidth={width}
            />
          ))}
          <div className="flex py-2.5 border-b border-b-outline-dark">
            <OutlineButton
              onClick={() => addMasterListRow()}
              disabled={selectedLists.length >= masterLists.length}
              className="btn-base"
            >
              <AddIcon className="w-6 h-6 text-black" />
              <span className="text-black font-medium leading-6">
                Add Another
              </span>
            </OutlineButton>
          </div>
        </div>
      </div>
      {removedListItemToken && (
        <CampaignMasterListRowDeleteModal
          data={
            selectedLists.find((v) =>
              v ? v._id === removedListItemToken : false,
            )!
          }
          open={!!removedListItemToken}
          onClose={() => setRemovedListItemToken("")}
        />
      )}
    </>
  )
}

export default CampaignMasterListView
