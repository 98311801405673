import { useAppDispatch } from "@/redux/hooks"
import {
  type ExportUserDNC,
  useExportUserDNCMutation,
} from "@/redux/services/userDncApi"
import { setToast } from "@/redux/toastSlice"
import { useParams } from "react-router-dom"

export const useExportUserDNC = () => {
  const dispatch = useAppDispatch()
  const [exportUserDNC, response] = useExportUserDNCMutation()
  const { id = "" } = useParams()

  const handleExport = async (body: ExportUserDNC) => {
    try {
      await exportUserDNC({ orgId: id, body }).unwrap()
      dispatch(setToast({ show: true, message: "Success", variant: "success" }))
    } catch (e) {
      dispatch(
        setToast({
          show: true,
          message: "Error downloading file",
          variant: "error",
        }),
      )
    }
  }
  return {
    exportUserDnc: handleExport,
    ...response,
  }
}
