import { FC } from "react"
import cx from "classnames"
import Modal, { type ModalProps } from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import { useDeleteInbox } from "../../hooks/useInboxDelete"
import type { Inbox } from "@/types/entities/inbox"

type Props = {
  className?: string
  data: Inbox[]
  onDelete?: () => void
} & ModalProps

const DeleteInboxModal: FC<Props> = ({
  className,
  open,
  data,
  onClose,
  onDelete,
  ...rest
}: Props) => {
  const {
    deleteInbox,
    response: { isLoading },
  } = useDeleteInbox()
  if (!data.length || !onClose) {
    return null
  }
  const handleDelete = async () => {
    try {
      await deleteInbox(data.map(({ _id }) => _id))
      onClose()
      onDelete && onDelete()
    } catch (e) {
      console.error(e)
    }
  }
  const title =
    data.length > 1 ? `Delete ${data.length} Messages` : "Delete Message"
  const messagesText =
    data.length > 1 ? `${data.length} Messages` : "selected message"
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      className={cx("", className)}
      onSubmit={handleDelete}
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={isLoading} onClick={onClose} />
          <DeleteButton disabled={isLoading} />
        </div>
      }
      {...rest}
    >
      <span className="leading-6 text-base">
        Are you sure you want to delete{" "}
        <span
          className={cx({
            "font-semibold": data.length > 1,
          })}
        >
          {messagesText}
        </span>
        ?
      </span>
    </Modal>
  )
}

export default DeleteInboxModal
