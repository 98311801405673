import React, { FC } from "react"
import EmptyListIcon from "@/assets/empty-list.svg"
import cx from "classnames"

type Props = {
  message: string
  children?: React.ReactNode
  className?: string
}

const EmptyList: FC<Props> = ({ message, className, children }: Props) => {
  return (
    <div className={cx("flex flex-col gap-[13px] items-center", className)}>
      <EmptyListIcon />
      <span className="opacity-60 text-base text-center">{message}</span>
      {children}
    </div>
  )
}

export default EmptyList
