import { FC } from "react"
import cx from "classnames"
import { useCriteriaSetup } from "@/pages/AddEditSearchCriteria/hooks/useCriteriaSetup"
import { useAppSelector } from "@/redux/hooks"
import { selectCriteriaFilter } from "@/redux/searchCriteriaSetupSlice"
import TagsTextarea from "@/components/TagsTextarea"
import { useActiveFilterContext } from "@/pages/AddEditSearchCriteria/hooks/useActiveFilterContext"
import type {
  FilterType,
  SelectionType,
} from "@/types/entities/search-criteria"

type Props = {
  className?: string
}

const CriteriaTitleFilter: FC<Props> = ({ className, ...rest }: Props) => {
  const FILTER_TYPE = useActiveFilterContext() as FilterType
  const { selections: titleSelections } = useAppSelector(
    selectCriteriaFilter(FILTER_TYPE),
  )!
  const { onTextFieldChange } = useCriteriaSetup()
  const handleSelectionTypeValuesChange =
    (type: SelectionType) => (value: string[]) =>
      onTextFieldChange({ type, value, filterType: FILTER_TYPE })

  return (
    <div className={cx("flex flex-col gap-4 flex-grow", className)} {...rest}>
      <div className="flex flex-col lg:flex-row gap-4 flex-1 [&_.relative]:h-full">
        <div className="basis-1/2 gap-y-2 flex flex-col">
          <h5 className="font-medium text-positive">Title Include</h5>
          <TagsTextarea
            value={titleSelections.INCLUDED}
            onTagsChange={handleSelectionTypeValuesChange("INCLUDED")}
            placeholder="Add title include"
            className="resize-none h-full flex-grow"
          />
        </div>
        <div className="basis-1/2 gap-y-2 flex flex-col">
          <h5 className="font-medium text-error">Title Exclude</h5>
          <TagsTextarea
            value={titleSelections.EXCLUDED}
            onTagsChange={handleSelectionTypeValuesChange("EXCLUDED")}
            placeholder="Add title exclude"
            className="resize-none h-full flex-grow"
          />
        </div>
      </div>
    </div>
  )
}

export default CriteriaTitleFilter
