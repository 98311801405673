import { FC, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { FiArrowLeft as ArrowLeft } from "react-icons/fi"
import { usePageTitle } from "@/hooks/usePageTitle"
import DiscardModal from "@/components/DiscardModal"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import {
  getCampaignSetupInitialState,
  resetCampaign,
  selectCampaignSetupInitialState,
  selectCampaignSetupName,
  selectCampaignSetupState,
  selectCampaignSetupStatus,
} from "@/redux/campaignSetupSlice"
import { useCampaignSetup } from "./hooks/useCampaignSetup"
import TextField from "@/components/TextField"
import { MdCheck as CheckIcon } from "react-icons/md"
import Stepper from "@/components/Stepper"
import CampaignSenderView from "./CampaignSenderView"
import CampaignForwardingView from "./CampaignForwardingView"
import CampaignMasterListView from "./CampaignMasterListView"
import CampaignMessagingView from "./CampaignMessagingView"
import CampaignSettingsView from "./CampaignSettingsView"
import Button from "@/components/Button"
import OutlineButton from "@/components/OutlineButton"
import ErrorMessage from "@/components/ErrorMessage"
import { useGetSendersQuery } from "@/redux/services/sendersApi"
import { useGetCampaignsMLQuery } from "@/redux/services/masterListsApi"
import { useGetSequencesQuery } from "@/redux/services/sequencesApi"
import { useGetCampaignQuery } from "@/redux/services/campaignsApi"
import { useUserAccess } from "@/hooks/useUserAccess"
import { cx } from "@/utils/strings"
import stringify from "json-stable-stringify"
import Tooltip from "@/components/Tooltip"
import Loader from "@/components/Loader"
import ReactRouterPrompt from "react-router-prompt"
import type { EditCampaignPayload } from "@/types/entities/campaign"

const AddEditCampaignPage: FC = () => {
  const { id: orgId = "", campaignId = "" } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const isEdit = Boolean(campaignId) && location.pathname.includes("edit")
  const dispatch = useAppDispatch()
  const campaignName = useAppSelector(selectCampaignSetupName)
  const setupState = useAppSelector(selectCampaignSetupState)
  const initialState = useAppSelector(selectCampaignSetupInitialState)
  const { pageFullAccess: campaignsUpdateFullAccess } = useUserAccess({
    pageName: "Campaigns Update",
  })
  const {
    isLoading: campaignLoading,
    isError: campaignError,
    data: campaign,
  } = useGetCampaignQuery(
    { id: campaignId },
    { skip: !campaignId, refetchOnMountOrArgChange: true },
  )
  const {
    data: { items: senders } = { items: [] },
    isLoading: sendersLoading,
    isError: sendersError,
  } = useGetSendersQuery(
    {
      orgId,
    },
    { refetchOnMountOrArgChange: true },
  )
  const {
    data: masterLists = [],
    isFetching: mlLoading,
    isError: masterListsError,
  } = useGetCampaignsMLQuery(
    { id: campaignId },
    { skip: !campaignId, refetchOnMountOrArgChange: true },
  )
  const {
    data: { items: sequences } = { items: [] },
    isLoading: sequencesLoading,
    isError: sequencesError,
  } = useGetSequencesQuery({ orgId }, { refetchOnMountOrArgChange: true })
  const status = useAppSelector(selectCampaignSetupStatus)
  const [currentStep, setCurrentStep] = useState(0)
  const {
    setupCampaignName,
    handlePublishCampaign,
    handleSaveCampaign,
    handleUpdateCampaignStatus,
    resetSetupValues,
    changeInitialState,
    changeInitialStateName,
    editSuccess,
    publishSuccess,
  } = useCampaignSetup()
  const [editingCampaignName, setEditingCampaignName] = useState(false)

  const [campaignRenameValue, setCampaignRenameValue] = useState(campaignName)
  useEffect(() => {
    resetSetupValues() // reseting on mount
  }, [resetSetupValues])
  useEffect(() => {
    if (!campaignId) {
      changeInitialState(getCampaignSetupInitialState())
    }
  }, [campaignId, changeInitialState])
  useEffect(() => setCampaignRenameValue(campaignName), [campaignName])
  useEffect(() => {
    return () => {
      resetSetupValues()
    }
  }, [resetSetupValues])
  const [currentStepDisabled, setCurrentStepDisabled] = useState(false)
  const [currentStepUpdateState, setCurrentStepUpdateState] =
    useState<EditCampaignPayload>({ sendersId: [] })

  usePageTitle(() => (
    <div className="flex items-center gap-3">
      <div
        onClick={() => navigate({ pathname: "../.." }, { relative: "path" })}
        className="cursor-pointer rounded-full bg-bg-default flex justify-center items-center w-7 h-7"
      >
        <ArrowLeft className="opacity-80 w-[22px] h-[22px]" />
      </div>
      <span className="flex items-center gap-2">
        <span className="whitespace-nowrap">
          {isEdit ? "Edit" : "Create"} Campaign:
        </span>
        {campaignsUpdateFullAccess && editingCampaignName ? (
          <div className="flex items-center gap-2.5">
            <Tooltip
              placement="bottom"
              open={campaignRenameValue !== campaignName}
              title="Don't forget to save!"
            >
              <CheckIcon
                onClick={handleRenameCampaign}
                className="cursor-pointer rounded-md active:scale-90 min-w-[24px] w-6 h-6 text-primary"
              />
            </Tooltip>
            <TextField
              value={campaignRenameValue}
              onChange={({ target: { value } }) =>
                setCampaignRenameValue(value)
              }
              size={campaignRenameValue.length}
              maxLength={40}
              className="input-sm max-w-sm focus:outline-[0px] !h-auto !p-0 text-lg bg-transparent focus:outline-none rounded-none border-x-[0px] border-t-[0px] focus:border-b-0 border-b-[1.5px] border-b-outline"
            />
          </div>
        ) : (
          <span
            onClick={() => setEditingCampaignName(true)}
            className="cursor-pointer transition-colors border-b-[1.5px] border-b-outline hover:border-b-outline-dark"
          >
            {campaignName}
          </span>
        )}
      </span>
    </div>
  ))
  const handleRenameCampaign = async () => {
    if (!campaignRenameValue.length) {
      return
    }
    setEditingCampaignName(false)
    if (campaignName !== campaignRenameValue) {
      setupCampaignName(campaignRenameValue)
      await handleSaveCampaign({
        name: campaignRenameValue,
      })
      changeInitialStateName(campaignRenameValue)
    }
  }
  const handleSaveAndClose = async () => {
    if (currentStep === 4 && status.changed) {
      // check status on last step
      await handleUpdateCampaignStatus(status.value)
    }
    const result = await handleSaveCampaign(currentStepUpdateState)
    if (result) {
      navigate({ pathname: "../.." }, { relative: "path" })
      dispatch(resetCampaign())
    }
  }
  const handlePublishClick = async () => {
    if (status.changed) {
      await handleUpdateCampaignStatus(status.value)
    }
    const saveResult = await handleSaveCampaign(currentStepUpdateState, false)
    if (saveResult) {
      const result = await handlePublishCampaign()
      if (result) {
        navigate(`../..`, { relative: "path" })
      }
    }
  }
  const handlePreviousClick = () => setCurrentStep((prev) => prev - 1)
  const handleContinueClick = async () => {
    const saveResult = await handleSaveCampaign(currentStepUpdateState)
    if (saveResult) {
      setCurrentStep((prev) => prev + 1)
    }
  }
  const views = useMemo(
    () => [
      <CampaignSenderView
        onChange={setCurrentStepUpdateState}
        senders={senders}
      />,
      <CampaignForwardingView
        onChange={setCurrentStepUpdateState}
        onDisabledChange={setCurrentStepDisabled}
      />,
      <CampaignMasterListView
        onChange={setCurrentStepUpdateState}
        masterLists={masterLists}
      />,
      <CampaignMessagingView
        onChange={setCurrentStepUpdateState}
        sequences={sequences}
      />,
      <CampaignSettingsView
        onChange={setCurrentStepUpdateState}
        onDisabledChange={setCurrentStepDisabled}
      />,
    ],
    [masterLists, senders, sequences],
  )
  const isError =
    campaignError || sendersError || masterListsError || sequencesError
  const isLoading =
    campaignLoading || sendersLoading || mlLoading || sequencesLoading
  const isLastStep = currentStep === views.length - 1
  const dirty = stringify(setupState) !== stringify(initialState)
  const discardModalOpen = dirty && !publishSuccess && !editSuccess

  return (
    <>
      {isError ? (
        <ErrorMessage />
      ) : (
        <div className="flex flex-col h-full">
          <div className="flex-1 bg-white rounded-md block-shadow pt-10 pb-6">
            <div className="px-10">
              <Stepper
                items={[
                  { title: "Sender" },
                  { title: "Forwarding" },
                  { title: "Master List" },
                  { title: "Messaging" },
                  { title: "Settings" },
                ]}
                current={currentStep}
                onChange={setCurrentStep}
              />
            </div>
            <hr className="mt-4" />
            {isLoading ? (
              <div className="h-full flex justify-center items-center">
                <Loader />
              </div>
            ) : (
              <div
                className={cx("px-6 pt-6", {
                  faded: !campaignsUpdateFullAccess,
                })}
              >
                {views[currentStep]}
              </div>
            )}
          </div>
          {campaignsUpdateFullAccess && (
            <div className="flex justify-between py-4">
              <OutlineButton
                onClick={handleSaveAndClose}
                className="bg-white btn-sm btn-primary"
                disabled={currentStepDisabled}
              >
                Save & Close
              </OutlineButton>
              <div className="flex gap-5">
                {currentStep > 0 && (
                  <OutlineButton
                    onClick={handlePreviousClick}
                    className="bg-white btn-sm text-black"
                  >
                    Previous
                  </OutlineButton>
                )}
                <Button
                  onClick={
                    isLastStep ? handlePublishClick : handleContinueClick
                  }
                  disabled={Boolean(
                    (isLastStep && campaign?.publishedAt) ||
                      currentStepDisabled,
                  )}
                  className="btn-sm"
                >
                  {isLastStep ? "Publish" : "Continue"}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      <ReactRouterPrompt when={discardModalOpen}>
        {({ isActive, onConfirm, onCancel }) => (
          <DiscardModal
            open={isActive}
            onConfirm={onConfirm}
            onClose={onCancel}
            canBeActivated
          />
        )}
      </ReactRouterPrompt>
    </>
  )
}

export default AddEditCampaignPage
