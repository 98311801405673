const emailKey = "email"

const order: string[] = [
  "firstName",
  "lastName",
  "location",
  "title",
  "company",
]

export const ML_RECORDS_SORTED_FIELDS = [
  "firstName",
  "lastName",
  "email",
  "status",
  "employeeCount",
  "industry",
  "createdAt",
]

export const sortMasterListRecords = (aKey: string, bKey: string): number => {
  const aFirstKey = aKey || ""
  const bFirstKey = bKey || ""

  if (aFirstKey === emailKey) return 1
  if (bFirstKey === emailKey) return -1
  if (order.includes(aFirstKey) && !order.includes(bFirstKey)) return -1
  if (!order.includes(aFirstKey) && order.includes(bFirstKey)) return 1
  if (order.includes(aFirstKey) && order.includes(bFirstKey)) {
    if (order.indexOf(aFirstKey) < order.indexOf(bFirstKey)) return -1
    if (order.indexOf(aFirstKey) > order.indexOf(bFirstKey)) return 1
    return aKey.localeCompare(bKey)
  }
  return aFirstKey.localeCompare(bFirstKey)
}
