import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import type { AiPrompt, AiPromptPayload } from "@/types/entities/ai-prompt"

export const aiPromptsApi = createApi({
  reducerPath: "aiPromptsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["AI Prompts"],
  endpoints: (builder) => ({
    getAIPrompts: builder.query<AiPrompt[], void>({
      query: () => ({
        method: "GET",
        url: `admin/openai`,
      }),
      transformResponse: ({ data }: { data: AiPrompt[] }) => data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: "AI Prompts" as const,
                _id,
              })),
              { type: "AI Prompts", id: "PARTIAL-LIST" },
            ]
          : [{ type: "AI Prompts", id: "PARTIAL-LIST" }],
    }),
    addAIPrompt: builder.mutation<AiPrompt, AiPromptPayload>({
      query: (body) => ({
        method: "POST",
        url: "admin/openai",
        body,
      }),
      invalidatesTags: ["AI Prompts"],
      transformResponse: ({ data }: { data: AiPrompt }) => data,
    }),
    editAIPrompt: builder.mutation<
      AiPrompt,
      {
        body: AiPromptPayload
        id: string
      }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `admin/openai/${id}`,
        body,
      }),
      invalidatesTags: ["AI Prompts"],
      transformResponse: ({ data }: { data: AiPrompt }) => data,
    }),
    deleteAIPrompt: builder.mutation<{ success: boolean }, { id: string }>({
      query: ({ id }) => ({
        url: `admin/openai/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AI Prompts"],
    }),
  }),
})

export const {
  useGetAIPromptsQuery,
  useAddAIPromptMutation,
  useEditAIPromptMutation,
  useDeleteAIPromptMutation,
} = aiPromptsApi
