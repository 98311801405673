import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
  label: string
  icon: React.ReactNode
} & React.HTMLProps<HTMLDivElement>

const Tile: FC<Props> = ({
  className,
  label,
  icon,
  onClick,
  ...rest
}: Props) => {
  return (
    <div
      className={cx(
        "rounded-md border border-outline-dark bg-white tiny-shadow py-6 px-4 flex flex-col gap-1 items-center transition-[background-color,transform]",
        {
          "cursor-pointer hover:bg-primary-glass active:scale-95":
            Boolean(onClick),
        },
        className,
      )}
      onClick={onClick}
      {...rest}
    >
      <div className="h-10 w-10">{icon}</div>
      <span className="text-sm leading-6 font-medium">{label}</span>
    </div>
  )
}

export default Tile
