import { useContext } from "react"
import { ActiveFilterContext } from "../context"

export const useActiveFilterContext = () => {
  const ctx = useContext(ActiveFilterContext)
  if (!ctx) {
    throw new Error(
      "useActiveFilterContext must be used within a ActiveFilterContext",
    )
  }
  return ctx
}
