import { FC } from "react"
import OAuth2Login from "react-simple-oauth2-login"
import OutlineButton from "../OutlineButton"
import GmailIcon from "@/assets/gmail.svg"

type Props = {
  onSuccess: (data: Record<string, any>) => void
  scope?: string
  extraParams?: Record<string, any>
  clientId: string
}
const GoogleOAuthLogin: FC<Props> = ({
  onSuccess,
  scope,
  extraParams,
  clientId,
}: Props) => {
  if (!clientId) {
    return null
  }
  return (
    <OAuth2Login
      clientId={clientId}
      redirectUri={import.meta.env.VITE_OAUTH_REDIRECT_URL}
      responseType="code"
      authorizationUrl="https://accounts.google.com/o/oauth2/auth"
      scope={scope}
      onSuccess={onSuccess}
      extraParams={{
        access_type: "offline",
        approval_prompt: "force",
        ...extraParams,
      }}
      onFailure={(response) => console.error(response, "error")}
      render={({ onClick }) => (
        <OutlineButton
          onClick={onClick}
          className="btn-sm text-black !gap-2"
          fullWidth
        >
          <GmailIcon className="w-6 h-[17px]" />
          <span className="font-semibold text-sm leading-6">
            Login With Gmail
          </span>
        </OutlineButton>
      )}
    />
  )
}

export default GoogleOAuthLogin
