import { FC } from "react"
import { useFormik } from "formik"
import Modal, { ModalProps } from "@/components/Modal"
import TextField from "@/components/TextField"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import validationSchema from "./validationSchema"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import { camelCaseToWords } from "@/utils/strings"
import { useMasterListRecordActions } from "../../hooks/useMasterListRecordActions"
import type { MasterListRecord } from "@/types/entities/master-list"

const PRESENT_FIELDS = ["firstName", "lastName", "email", "status", "_id"]

type Props = {
  data?: MasterListRecord
  blurEmail?: boolean
} & ModalProps

const AddEditContactModal: FC<Props> = ({
  open,
  onClose,
  data,
  blurEmail,
}: Props) => {
  const { updateRecord } = useMasterListRecordActions()
  const isEdit = Boolean(data)
  const fields = data
    ? Object.entries(data)
        .filter(
          ([key]) =>
            !PRESENT_FIELDS.find((presentField) => presentField === key),
        )
        .map(([key, value]) => ({
          label: camelCaseToWords(key),
          value,
          key,
        }))
    : []

  const fieldsObj = fields.reduce(
    (acc, { key, value }) =>
      Object.assign(acc, {
        [key]: value,
      }),
    {},
  )

  const {
    handleSubmit,
    getFieldProps,
    errors: {
      email: emailError,
      firstName: firstNameError,
      lastName: lastNameError,
    },
    touched: {
      email: emailTouched,
      firstName: firstNameTouched,
      lastName: lastNameTouched,
    },
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: data
      ? Object.assign(
          {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
          },
          fieldsObj,
        )
      : Object.assign(
          {
            firstName: "",
            lastName: "",
            email: "",
          },
          fieldsObj,
        ),
    validationSchema,
    enableReinitialize: true,
    onSubmit: async ({ firstName, lastName, email, ...values }) => {
      const fields = Object.entries(values).map(([key, value]) => ({
        name: key,
        value: value as string,
      }))
      await updateRecord([
        {
          firstName,
          lastName,
          email: blurEmail ? (undefined as any) : email,
          fields,
          _id: data?._id ?? "",
        },
      ])
      onClose && onClose()
    },
  })
  if (!data) {
    return null
  }
  const title = isEdit ? "Edit Contact" : "Add Contact"
  const submitBtnText = isEdit ? "Save" : "Add"

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={title}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={onClose}
            disabled={isSubmitting}
            className="text-black btn-sm !w-[86px]"
            type="button"
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={!isValid || isSubmitting}
            className="btn-sm !w-[86px]"
            type="submit"
          >
            {submitBtnText}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-5">
        <div className="flex flex-col sm:flex-row gap-4">
          <TextField
            topLeftLabel="First Name"
            placeholder="Contact’s first name"
            className="input-sm"
            bottomLeftLabel={
              <FormFieldErrorMessage
                error={firstNameError}
                touched={firstNameTouched}
              />
            }
            {...getFieldProps("firstName")}
          />
          <TextField
            topLeftLabel="Last Name"
            placeholder="Contact’s last name"
            className="input-sm"
            bottomLeftLabel={
              <FormFieldErrorMessage
                error={lastNameError}
                touched={lastNameTouched}
              />
            }
            {...getFieldProps("lastName")}
          />
        </div>
        {blurEmail ? null : (
          <TextField
            topLeftLabel="Email Address"
            placeholder="sender@mail.com"
            className="input-sm"
            type="email"
            bottomLeftLabel={
              <FormFieldErrorMessage
                error={emailError}
                touched={emailTouched}
              />
            }
            {...getFieldProps("email")}
          />
        )}
        {fields.map(({ key, label }) => (
          <TextField
            key={key}
            placeholder={"Enter " + label}
            topLeftLabel={label}
            className="input-sm"
            {...getFieldProps(key)}
          />
        ))}
      </div>
    </Modal>
  )
}

export default AddEditContactModal
