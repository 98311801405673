import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import { useDeleteEmailAddress } from "../../hooks/useDeleteEmailAddress"
import type { EmailAddress } from "@/types/entities/email-address"

type Props = {
  className?: string
  data?: EmailAddress
} & ModalProps

const DeleteEmailModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const {
    deleteEmailAddress,
    response: { isLoading },
  } = useDeleteEmailAddress()
  if (!data) {
    return null
  }
  const handleSubmit = async () => {
    try {
      await deleteEmailAddress({ id: data._id })
    } catch (e) {
      console.error(e)
    } finally {
      onClose && onClose()
    }
  }
  return (
    <Modal
      title="Delete Email Address"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={isLoading} onClick={onClose} />
          <DeleteButton disabled={isLoading} />
        </div>
      }
      {...rest}
    >
      <span className="text-base leading-6">
        Are you sure you want to delete{" "}
        <span className="font-semibold">“{data.email}”</span>?
      </span>
    </Modal>
  )
}

export default DeleteEmailModal
