import { FC } from "react"
import cx from "classnames"
import { MdContentCopy } from "react-icons/md"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const CopyIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <MdContentCopy
      className={cx("scale-[1,-1] active:scale-90", className)}
      {...rest}
    />
  )
}

export default CopyIcon
