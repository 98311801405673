import cx from "classnames"
import PasswordsForm from "./features/PasswordsForm"
import { useAvailableParams } from "@/hooks/useAvailableParams"

type Mode = "invite" | "reset"

const SetupPasswordPage = () => {
  const { email, mode } = useAvailableParams<"email" | "mode">([
    "email",
    "mode",
  ])

  const title =
    (mode as Mode) === "invite" ? "Account Setup" : "Create New Password"
  return (
    <div className={cx("flex justify-center")}>
      <div className="mt-16 flex flex-col gap-6 items-center max-w-[400px] w-full">
        <h3 className="text-black text-xl sm:text-2xl font-semibold">
          {title}
        </h3>
        <div className="-mt-3.5 text-sm text-center">
          Setup password for{" "}
          <b className="font-semibold">{email?.trim().replace(" ", "+")}</b>
        </div>
        <PasswordsForm />
      </div>
    </div>
  )
}

export default SetupPasswordPage
