import { useFormik } from "formik"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import validationSchema from "./validation-schema"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import { useSetupPassword } from "@/hooks/useSetupPassword"
import { useNavigate } from "react-router-dom"
import { Routes } from "@/core/routing/routes"

const RegisterForm = () => {
  const navigate = useNavigate()
  const { setupPassword, response } = useSetupPassword(() =>
    navigate(Routes.login),
  )
  const {
    handleSubmit,
    getFieldProps,
    errors: { confirmPassword: confirmPasswordError, password: passwordError },
    touched: {
      confirmPassword: confirmPasswordTouched,
      password: passwordTouched,
    },
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: ({ password }) => {
      setupPassword({ password })
    },
  })
  return (
    <form className="flex flex-col gap-5 w-full" onSubmit={handleSubmit}>
      <TextField
        type="password"
        placeholder="Enter your password"
        topLeftLabel="Password"
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={passwordError}
            touched={passwordTouched}
          />
        }
        {...getFieldProps("password")}
      />
      <TextField
        type="password"
        placeholder="Re-type your password"
        topLeftLabel="Confirm Password"
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={confirmPasswordError}
            touched={confirmPasswordTouched}
          />
        }
        {...getFieldProps("confirmPassword")}
      />
      <Button
        fullWidth
        disabled={response.isLoading}
        className="mt-1"
        type="submit"
      >
        Set Password
      </Button>
    </form>
  )
}

export default RegisterForm
