import React from "react"
import cx from "classnames"

type Props = {
  className?: string
  children: React.ReactNode
  fullWidth?: boolean
  active?: boolean
  loading?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({
  className,
  children,
  fullWidth = false,
  active = false,
  loading,
  ...rest
}: Props) => {
  return (
    <button
      className={cx(
        "transition-colors btn btn-primary border-none min-w-[44px] bg-primary rounded-md py-3 px-4 text-white normal-case disabled:opacity-40 disabled:text-white disabled:bg-primary",
        fullWidth ? "w-full" : "w-fit",
        { "bg-primary-glass !text-primary border border-primary": active },
        className,
      )}
      {...rest}
    >
      {loading && <span className="loading text-white loading-spinner"></span>}
      {children}
    </button>
  )
}

export default Button
