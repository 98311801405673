import { FC } from "react"
import cx from "classnames"
import Drawer from "@/components/Drawer"
import { useWindowSize } from "usehooks-ts"
import { MEDIA_BREAKPOINTS } from "@/constants"
import DashboardFiltersRow from "../DashboardFiltersRow"

type Props = {
  className?: string
  open: boolean
  onClose: () => void
}

const MobileDashboardFiltersDrawer: FC<Props> = ({
  className,
  open,
  onClose,
  ...rest
}: Props) => {
  const { width } = useWindowSize()
  return (
    <Drawer
      title="Dashboard Filters"
      open={width < MEDIA_BREAKPOINTS.xl && open}
      onClose={onClose}
      className={cx("xl:hidden relative text-black", className)}
      {...rest}
    >
      <DashboardFiltersRow />
    </Drawer>
  )
}

export default MobileDashboardFiltersDrawer
