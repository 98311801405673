import { FC } from "react"
import Modal, { ModalProps } from "@/components/Modal"
import { useDeleteSequence } from "@/pages/AddEditSequence/hooks/useDeleteSequence"
import type { SequenceAPIResponse } from "@/types/entities/sequence"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"

type Props = ModalProps & {
  sequence?: Pick<SequenceAPIResponse, "_id" | "name"> | null
  sequenceId?: string
  onSubmit?: () => void
}

const SequenceDeleteModal: FC<Props> = ({
  open,
  onClose,
  sequence,
  onSubmit,
}: Props) => {
  const { deleteSequence, response } = useDeleteSequence()
  if (!sequence) {
    return null
  }
  const handleDelete = async () => {
    await deleteSequence({ id: sequence._id })
    onClose && onClose()
    onSubmit && onSubmit()
  }
  return (
    <Modal
      open={open}
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={response.isLoading} onClick={onClose} />
          <DeleteButton
            type="submit"
            tabIndex={0}
            className="bg-error"
            disabled={response.isLoading}
          />
        </div>
      }
      title="Delete Sequence"
      onClose={onClose}
      onSubmit={handleDelete}
    >
      <span className="text-base font-normal leading-6">
        Are you sure you want to delete{" "}
        <span className="font-semibold">“{sequence.name}”</span>?
      </span>
    </Modal>
  )
}

export default SequenceDeleteModal
