import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
}

const VerticalDivider: FC<Props> = ({ className }: Props) => {
  return <hr className={cx("border-outline", className)} />
}

export default VerticalDivider
