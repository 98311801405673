import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import { dreamsenderApi } from "@/core/api/services"
import { buildQueryString } from "@/utils/strings"
import { exportFileResponseHandler } from "@/utils/api"
import type {
  AddOrganizationPayload,
  DashboardReporting,
  Organization,
  SetOrganizationStatus,
} from "@/types/entities/organization"

export type DashboardPayload = {
  id: string
  group: string
  multiple?: boolean | string
  classifications?: string[]
  campaigns?: string[]
  from?: string
  to?: string
  sender?: string
  emailAddress?: string
  sequence?: string
  email?: string
  version?: string
  masterList?: string
}

export type DashboardExportPayload = DashboardPayload & {
  indicators?: string[]
}

export const organizationApi = createApi({
  reducerPath: "organizationApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Organization", "Dashboard"],
  endpoints: (builder) => ({
    getOrganizations: builder.query<Organization[], void>({
      query: () => ({
        method: "GET",
        url: "org/all",
      }),
      transformResponse: ({ data }: { data: Organization[] }) => {
        return data
      },
      providesTags: ["Organization"],
    }),
    addOrganization: builder.mutation<
      { data: Organization },
      AddOrganizationPayload
    >({
      query: (body) => ({
        method: "POST",
        url: "org",
        body: {
          ...body,
          division: "main", // ? have to provide for request to work
        },
      }),
      invalidatesTags: ["Organization"],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled
        if (data) {
          dispatch(dreamsenderApi.util.invalidateTags(["User"]))
        }
      },
    }),
    editOrganization: builder.mutation<
      { data: Organization },
      { id: string; body: AddOrganizationPayload }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `org/${id}`,
        body: {
          ...body,
          division: "main", // ? have to provide for request to work
        },
      }),
      invalidatesTags: ["Organization"],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled
        if (data) {
          dispatch(dreamsenderApi.util.invalidateTags(["User"]))
        }
      },
    }),
    setOrganizationStatus: builder.mutation<
      { data: Organization },
      { id: string; body: SetOrganizationStatus }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `org/${id}/status`,
        body,
      }),
      invalidatesTags: ["Organization"],
    }),
    getDashboardReporting: builder.query<DashboardReporting, DashboardPayload>({
      query: ({ id, ...keys }) => ({
        method: "GET",
        url: `reporting/${id}/dashboard?${buildQueryString(keys)}`,
      }),
      providesTags: ["Dashboard"],
      transformResponse: ({ data }: { data: DashboardReporting }) => data,
    }),
    exportDashboard: builder.mutation<Response, DashboardExportPayload>({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `reporting/${id}/dashboard/pdf`,
        responseHandler: async (response) =>
          await exportFileResponseHandler(response, "Dashboard Report.pdf"),
        cache: "no-cache",
        body,
      }),
    }),
    deleteOrganization: builder.mutation<
      { data: Organization },
      { id: string }
    >({
      query: ({ id }) => ({
        method: "DELETE",
        url: `org/${id}`,
      }),
      invalidatesTags: ["Organization"],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled
        if (data) {
          dispatch(dreamsenderApi.util.invalidateTags(["User"]))
        }
      },
    }),
  }),
})

export const {
  useGetOrganizationsQuery,
  useAddOrganizationMutation,
  useEditOrganizationMutation,
  useSetOrganizationStatusMutation,
  useGetDashboardReportingQuery,
  useDeleteOrganizationMutation,
  useExportDashboardMutation,
} = organizationApi
