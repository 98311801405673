import { useNavigate } from "react-router-dom"
import { usePasswordResetMutation } from "@/core/api/services"
import { Routes } from "@/core/routing/routes"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"

export const useForgotPassword = () => {
  const dispatch = useAppDispatch()
  const [resetPassword, response] = usePasswordResetMutation()
  const navigate = useNavigate()

  const handleResetPassword = async ({ email }: { email: string }) => {
    try {
      await resetPassword({
        email,
      }).unwrap()
      navigate(Routes.forgotPassword.emailSent)
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { resetPassword: handleResetPassword, response }
}
