import { FC } from "react"
import cx from "classnames"
import TextField from "@/components/TextField"
import type { SequenceEmailVersionAPIResponse } from "@/types/entities/sequence"
import Textarea from "@/components/Textarea"

type Props = {
  className?: string
  version: SequenceEmailVersionAPIResponse
}

const CampaignMessagingEmailVersion: FC<Props> = ({
  className,
  version,
  ...rest
}: Props) => {
  return (
    <div className={cx("flex flex-col gap-5", className)} {...rest}>
      <div className="flex gap-3 flex-wrap items-end">
        {version.subjects.map((subject, i) => (
          <TextField
            key={i}
            value={subject}
            readOnly
            rootClassName="basis-80"
            className="input-sm"
            topLeftLabel={i === 0 ? "Subject" : ""}
          />
        ))}
      </div>
      <div className="flex gap-3 flex-wrap items-end">
        {version.greetings.map((greeting, i) => (
          <Textarea
            key={i}
            value={greeting}
            readOnly
            rootClassName="basis-80"
            className="input-sm resize-none leading-6 !h-20"
            topLeftLabel={i === 0 ? "Greeting" : ""}
          />
        ))}
      </div>
      <Textarea
        value={version.body}
        readOnly
        topLeftLabel="Email Content"
        className="min-h-[240px] resize-none"
      />
      <div className="flex gap-3 flex-wrap items-end">
        {version.signoffs.map((signoff, i) => (
          <Textarea
            key={i}
            value={signoff}
            readOnly
            rootClassName="basis-80"
            className="input-sm resize-none leading-6 !h-20"
            topLeftLabel={i === 0 ? "Sign Off" : ""}
          />
        ))}
      </div>
      {version.unsubs?.length ? (
        <div className="flex gap-3 flex-wrap items-end">
          {version.unsubs.map((unsub, i) => (
            <Textarea
              key={i}
              value={unsub}
              readOnly
              rootClassName="basis-80"
              className="input-sm resize-none leading-6 !h-20"
              topLeftLabel={i === 0 ? "Unsub Text" : ""}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default CampaignMessagingEmailVersion
