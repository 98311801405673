import LogoIcon from "@/assets/logo.svg"

const LogoHeader = () => {
  return (
    <div className="h-[60px] py-3.5 border-b-outline border-b bg-white flex justify-center items-center">
      <LogoIcon className="h-8 w-[130px]" />
    </div>
  )
}

export default LogoHeader
