import type { ProspectStatus } from "@/types/entities/prospect"

export const prospectsStatusColorMap: Record<ProspectStatus, string> = {
  interested: "var(--positive)",
  uninterested: "var(--error)",
  no_reply: "var(--outline-dark)",
}

export const prospectsStatusNameMap: Record<ProspectStatus, string> = {
  interested: "Interested",
  uninterested: "Not Interested",
  no_reply: "No Reply",
}
