import { createApi } from "@reduxjs/toolkit/query/react"
import { buildQueryString } from "@/utils/strings"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import { exportFileResponseHandler } from "@/utils/api"
import type { PaginationProps } from "./types"
import type { AdminDNC } from "@/types/entities/dnc"

export type ExportAdminDNCPayload = { text?: string }

export const adminDncApi = createApi({
  reducerPath: "adminDncApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Admin DNC"],
  endpoints: (builder) => ({
    getAdminDNC: builder.query<
      { items: AdminDNC[]; total: number },
      PaginationProps & { text?: string }
    >({
      query: ({ ...keys }) => ({
        method: "GET",
        url: `admin/dnc?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { records, total },
      }: {
        data: { records: AdminDNC[]; total: number }
      }) => ({
        items: records,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Admin DNC" as const,
                _id,
              })),
              { type: "Admin DNC", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Admin DNC", id: "PARTIAL-LIST" }],
    }),
    addAdminDNC: builder.mutation<
      AdminDNC,
      {
        records: string[]
      }
    >({
      query: (body) => ({
        method: "POST",
        url: "admin/dnc",
        body,
      }),
      invalidatesTags: ["Admin DNC"],
      transformResponse: ({ data }: { data: AdminDNC }) => data,
    }),
    editAdminDNC: builder.mutation<
      AdminDNC,
      {
        id: string
        value: string
      }
    >({
      query: (body) => ({
        method: "PUT",
        url: "admin/dnc",
        body,
      }),
      invalidatesTags: ["Admin DNC"],
      transformResponse: ({ data }: { data: AdminDNC }) => data,
    }),
    importAdminDNC: builder.mutation<AdminDNC, FormData>({
      query: (body) => ({
        method: "POST",
        url: "admin/dnc/import",
        body,
      }),
      invalidatesTags: ["Admin DNC"],
      transformResponse: ({ data }: { data: AdminDNC }) => data,
    }),
    deleteAdminDNC: builder.mutation<unknown, string[]>({
      query: (records) => ({
        method: "DELETE",
        url: `admin/dnc?${buildQueryString({ records })}`,
      }),
      invalidatesTags: ["Admin DNC"],
      transformResponse: ({ data }: { data: AdminDNC }) => data,
    }),
    exportAdminDNC: builder.mutation<unknown, { body: ExportAdminDNCPayload }>({
      query: ({ body }) => ({
        url: `admin/dnc/export`,
        method: "POST",
        responseHandler: async (response) =>
          await exportFileResponseHandler(response, "Admin DNC.csv"),
        cache: "no-cache",
        body,
      }),
    }),
  }),
})

export const {
  useGetAdminDNCQuery,
  useAddAdminDNCMutation,
  useEditAdminDNCMutation,
  useImportAdminDNCMutation,
  useDeleteAdminDNCMutation,
  useExportAdminDNCMutation,
} = adminDncApi
