import { FC } from "react"
import Select from "@/components/Select"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import type { SelectProps } from "antd"
import type { CampaignStatus } from "@/types/entities/campaign"

export const campaignsStatusColorMap: Record<CampaignStatus, string> = {
  active: "var(--positive)",
  paused: "var(--info)",
  stopped: "var(--error)",
  completed: "var(--warning)",
}

type Props = SelectProps & {
  value: CampaignStatus
}

const CampaignsStatusSelect: FC<Props> = ({
  value,
  placeholder,
  ...rest
}: Props) => {
  const { data } = useGetSystemValuesQuery()
  if (!data?.campaignStatuses) {
    return null
  }
  const { campaignStatuses } = data

  return (
    <Select
      bordered
      options={campaignStatuses.map(({ display, type }) => ({
        label: display,
        value: type,
        color: campaignsStatusColorMap[type] ?? "var(--light-gray)",
      }))}
      optionRender={({ data, label }) => (
        <div className="flex gap-2.5 items-center">
          <div
            className="rounded-full h-2.5 w-2.5"
            style={{ backgroundColor: data.color }}
          ></div>
          <span className="text-sm leading-6">{label}</span>
        </div>
      )}
      value={{
        value,
        label: value ? (
          <div className="flex gap-2.5 items-center">
            <div
              className="rounded-full h-2.5 w-2.5"
              style={{
                backgroundColor:
                  campaignsStatusColorMap[value as CampaignStatus] ??
                  "var(--light-gray)",
              }}
            ></div>
            <span className="text-sm leading-6">
              {campaignStatuses.find(({ type }) => type === value)?.display}
            </span>
          </div>
        ) : (
          <>{placeholder}</>
        ),
      }}
      labelInValue
      placeholder={placeholder}
      {...rest}
    />
  )
}

export default CampaignsStatusSelect
