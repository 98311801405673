import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useEditSequenceMutation } from "@/redux/services/sequencesApi"
import { setToast } from "@/redux/toastSlice"
import { useParams } from "react-router-dom"
import type { Sequence } from "@/types/entities/sequence"

export const useEditSequence = () => {
  const dispatch = useAppDispatch()
  const { id: orgId = "" } = useParams()
  const [editSequence, response] = useEditSequenceMutation()

  const handleEditSequence = async ({
    body,
    id,
  }: {
    body: Omit<Sequence, "orgId">
    id: string
  }) => {
    try {
      const response = await editSequence({ body, id, orgId }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success edit sequence",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { editSequence: handleEditSequence, response }
}
