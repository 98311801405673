import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
  text: string
  labelText?: string
  icon: React.ReactNode
}

const IconBox: FC<Props> = ({
  className,
  text,
  labelText,
  icon,
  ...rest
}: Props) => {
  return (
    <div className={cx("flex flex-col gap-1.5", className)}>
      {labelText && <span className="font-medium text-sm">{labelText}</span>}
      <div
        className={cx(
          "px-3 py-2 flex gap-3 bg-input rounded-md overflow-x-hidden",
        )}
        {...rest}
      >
        <div className="break-all overflow-hidden flex-1">{text}</div>
        <div className="border-l border-outline opacity-60"></div>
        {icon}
      </div>
    </div>
  )
}

export default IconBox
