import { useCallback } from "react"

export const useDownloadFile = () => {
  const downloadFile = useCallback(async (url: string, fileName: string) => {
    const response = await fetch(url)
    if (!response.ok) {
      return
    }
    const blob = await response.blob()
    const fileString = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = fileString
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(fileString)
  }, [])
  return downloadFile
}
