import { useContext } from "react"
import { AdminAccountLoginContext } from "../context"

export const useAdminAccountLogin = () => {
  const ctx = useContext(AdminAccountLoginContext)
  if (!ctx) {
    throw new Error(
      "useAdminAccountLogin must be used within a AdminAccountLoginContext",
    )
  }
  return ctx
}
