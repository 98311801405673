import { FC, useState } from "react"
import cx from "classnames"
import Collapse from "../Collapse"
import Tabs from "../Tabs"
import type { EmailAPIResponse } from "@/types/entities/sequence"
import Tab from "../Tab"
import SequenceEmailVersionView from "../SequenceEmailVersionView"
import { getNumberEnding } from "@/utils/numbers"

type Props = {
  email: EmailAPIResponse
  emailIndex: number
}

const EmailAccordionCard: FC<Props> = ({ email, emailIndex }: Props) => {
  const [activeVersionIndex, setActiveVersionIndex] = useState(0)

  return (
    <Collapse
      className="[&_.collapse-content]:px-0 [&_.collapse-content]:overflow-x-auto"
      title={
        <div className="flex justify-between items-center gap-3">
          <div className="flex flex-col gap-1">
            <span>
              {emailIndex + 1}
              {getNumberEnding(emailIndex + 1)} Email
            </span>
            <span className="caption font-normal">{email.description}</span>
          </div>
          {email.delay && (
            <div className="flex text-xs font-normal opacity-60">
              Send {email.delay} {email.delayUnit} after previous
            </div>
          )}
        </div>
      }
    >
      <Tabs>
        {email.versions.map((_, versionIndex) => (
          <Tab
            key={versionIndex}
            tabKey={versionIndex}
            className={cx("tab-lifted font-semibold w-32", {
              "ml-5": !versionIndex,
              "cursor-default": email.versions.length === 1,
            })}
            active={activeVersionIndex === versionIndex}
            onSelect={(tabKey) => setActiveVersionIndex(+tabKey)}
            label={<span>Version {versionIndex + 1}</span>}
          >
            <SequenceEmailVersionView version={email.versions[versionIndex]} />
          </Tab>
        ))}
      </Tabs>
    </Collapse>
  )
}

export default EmailAccordionCard
