import { FC, useEffect, useState } from "react"
import cx from "classnames"
import { Link } from "react-router-dom"
import SequenceList from "./features/SequenceList"
import TextField from "@/components/TextField"
import Button from "@/components/Button"
import { BiPlus as AddIcon } from "react-icons/bi"
import SearchIcon from "@/assets/search.svg"
import { usePageTitle } from "@/hooks/usePageTitle"
import IconButton from "@/components/IconButton"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import {
  MdOutlineModeEdit as EditIcon,
  MdOutlineDynamicFeed,
} from "react-icons/md"
import SendIcon from "@/assets/send.svg"
import SequenceView from "./features/SequenceView"
import SequenceDeleteModal from "./features/SequenceDeleteModal"
import TestSequenceModal from "./features/TestSequenceModal"
import Tooltip from "@/components/Tooltip"
import Loader from "@/components/Loader"
import ErrorMessage from "@/components/ErrorMessage"
import SelectionEmpty from "@/components/layouts/SelectionEmpty"
import dayjs from "dayjs"
import DuplicateIcon from "@/components/icons/DuplicateIcon"
import SequenceDuplicateConfirmModal from "./features/SequenceDuplicateModal"
import { useDuplicateSequence } from "./hooks/useDuplicateSequence"
import { useInfiniteScrollSequences } from "./hooks/useInfiniteScrollSequences"
import { usePagination } from "@/hooks/usePagination"
import { useUserAccess } from "@/hooks/useUserAccess"
import type { SequenceAPIResponse } from "@/types/entities/sequence"

type SequenceModalData = SequenceAPIResponse | null

const SequencesPage: FC = () => {
  usePageTitle("Sequences")
  const { pageFullAccess } = useUserAccess({ pageName: "Sequences" })
  const {
    pageFullAccess: sequencesUpdateFullAccess,
    pageAnyAccess: sequencesUpdateAnyAccess,
  } = useUserAccess({
    pageName: "Sequences Update",
  })
  const { searchTerm, handleSearchTermChange } = usePagination()
  const { duplicateSequence } = useDuplicateSequence()
  const { items: sequences, isError, isLoading } = useInfiniteScrollSequences()
  const [selectedSequenceId, setSelectedSequenceId] = useState("")
  const [duplicatedSequence, setDuplicatedSequence] =
    useState<null | SequenceAPIResponse>(null)
  const [testEmailModalData, setTestEmailModalData] =
    useState<SequenceModalData>(null)
  const [deleteModalData, setDeleteModalData] =
    useState<SequenceModalData>(null)

  useEffect(() => {
    const sortedSequences = sequences.toSorted(
      ({ createdAt: a }, { createdAt: b }) =>
        dayjs(a).isAfter(dayjs(b)) ? -1 : 1,
    )
    let lastUpdatedSequenceId = ""
    if (sortedSequences.length > 0) {
      lastUpdatedSequenceId = sortedSequences[0]._id
    }
    setSelectedSequenceId(lastUpdatedSequenceId)
  }, [sequences])

  const selectedSequence = sequences.find(
    ({ _id }) => _id === selectedSequenceId,
  )

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className="bg-white block-shadow rounded-md flex flex-col md:flex-row h-full w-full">
        <div className="flex flex-col md:max-w-[220px] lg:max-w-[329px] md:border-r border-outline">
          <div className="flex gap-4 p-3 border-b border-outline h-15">
            <TextField
              startIcon={<SearchIcon />}
              className="input-base text-sm"
              rootClassName={cx({
                "md:!w-44": sequencesUpdateFullAccess,
                "w-full": !sequencesUpdateFullAccess,
              })}
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
            {sequencesUpdateFullAccess && (
              <Link to="create">
                <Button className="btn-base">
                  <AddIcon className="h-6 w-6" />
                  <span className="hidden lg:inline">Add New</span>
                </Button>
              </Link>
            )}
          </div>
          {isLoading ? (
            <div className="h-full flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <SequenceList
              className="flex-1"
              selectedSequenceId={selectedSequenceId}
              sequences={sequences}
              onSequenceSelect={(sequence) =>
                setSelectedSequenceId(sequence._id)
              }
            />
          )}
        </div>
        {!isLoading && (
          <>
            {selectedSequence ? (
              <div className="flex flex-col flex-1 md:max-w-[calc(100%-220px)] lg:max-w-full">
                <div
                  className={cx(
                    "px-6 py-3.5 flex justify-between h-15 gap-1.5",
                    {
                      "border-b border-outline": selectedSequence,
                    },
                  )}
                >
                  <div className="text-lg font-semibold truncate">
                    {selectedSequence?.name}
                  </div>
                  {selectedSequence &&
                    (pageFullAccess || sequencesUpdateAnyAccess) && (
                      <div className="flex gap-1 lg:gap-3">
                        {pageFullAccess && (
                          <Tooltip title="Duplicate Sequence">
                            <IconButton
                              onClick={() =>
                                setDuplicatedSequence(selectedSequence)
                              }
                            >
                              <DuplicateIcon className="w-6 h-6" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {pageFullAccess && (
                          <Tooltip title="Test Sequence">
                            <IconButton
                              onClick={() =>
                                setTestEmailModalData(selectedSequence)
                              }
                            >
                              <SendIcon className="w-6 h-6" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {sequencesUpdateAnyAccess && (
                          <Link
                            to={{
                              pathname: `edit/${selectedSequence._id}`,
                            }}
                          >
                            <IconButton>
                              <EditIcon className="w-6 h-6" />
                            </IconButton>
                          </Link>
                        )}
                        {pageFullAccess && (
                          <IconButton
                            onClick={() => setDeleteModalData(selectedSequence)}
                          >
                            <BinIcon className="w-6 h-6 text-error" />
                          </IconButton>
                        )}
                      </div>
                    )}
                </div>
                <div className="flex-1 overflow-y-auto">
                  {selectedSequence && (
                    <SequenceView selectedSequence={selectedSequence} />
                  )}
                </div>
              </div>
            ) : (
              <SelectionEmpty
                message="No sequence selected"
                icon={<MdOutlineDynamicFeed className="w-10 h-10" />}
              />
            )}
          </>
        )}
      </div>
      <SequenceDeleteModal
        open={!!deleteModalData}
        onClose={() => setDeleteModalData(null)}
        sequence={deleteModalData}
      />
      <TestSequenceModal
        open={!!testEmailModalData}
        onClose={() => setTestEmailModalData(null)}
        sequence={testEmailModalData}
      />
      <SequenceDuplicateConfirmModal
        open={!!duplicatedSequence}
        data={duplicatedSequence}
        onConfirm={duplicateSequence}
        onClose={() => setDuplicatedSequence(null)}
      />
    </>
  )
}

export default SequencesPage
