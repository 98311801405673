import { useEditMasterListMutation } from "@/redux/services/masterListsApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import { useParams } from "react-router-dom"
import type { CreateEditMasterListPayload } from "@/types/entities/master-list"

export const useEditMasterList = () => {
  const dispatch = useAppDispatch()
  const [editMasterListCSV, response] = useEditMasterListMutation()
  const { id: orgId = "" } = useParams()

  const handleEditMasterList = async (
    payload: Omit<CreateEditMasterListPayload, "orgId"> & {
      masterListId: string
    },
  ) => {
    try {
      const { masterListId, ...rest } = payload
      await editMasterListCSV({
        id: masterListId,
        body: { ...rest, orgId },
      }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success edit master list",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { editMasterList: handleEditMasterList, response }
}
