import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteSenderMutation } from "@/redux/services/sendersApi"
import { setToast } from "@/redux/toastSlice"

export const useDeleteSender = () => {
  const dispatch = useAppDispatch()
  const [deleteSender, response] = useDeleteSenderMutation()

  const handleDeleteSender = async ({ id }: { id: string }) => {
    try {
      const response = await deleteSender({ id }).unwrap()
      if (response.data.success) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success delete sender",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteSender: handleDeleteSender, response }
}
