import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteUserDNCMutation } from "@/redux/services/userDncApi"
import { setToast } from "@/redux/toastSlice"

export const useUserDeleteDNC = () => {
  const dispatch = useAppDispatch()
  const [deleteUserDNC, response] = useDeleteUserDNCMutation()

  const handleUserDeleteDNC = async ({
    records,
    id,
  }: {
    records: string[]
    id: string
  }) => {
    try {
      const response = await deleteUserDNC({ id, records }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: `Success delete ${
            records.length > 1 ? "accounts" : "account"
          }`,
        }),
      )
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { deleteUserDNC: handleUserDeleteDNC, response }
}
