import { RootState } from "@/redux/store"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type ToastVariants =
  | "default"
  | "error"
  | "success"
  | "warning"
  | "info"
  | undefined

export type ToastState = {
  show: boolean
  options: {
    message: string
    variant: ToastVariants
  } | null
}

const initialState: ToastState = {
  show: false,
  options: null,
}

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToast: (
      state,
      {
        payload,
      }: PayloadAction<{
        show: boolean
        message: string
        variant: ToastVariants
      }>,
    ) => {
      state.show = payload.show
      state.options = {
        message: payload.message,
        variant: payload.variant,
      }
    },
  },
})

export const selectToast = (state: RootState) => state.toast

export const { setToast } = toastSlice.actions

export default toastSlice.reducer
