import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { useDeleteSearchCriteriaMutation } from "@/redux/services/searchCriteriaApi"

export const useDeleteSearchCriteria = () => {
  const dispatch = useAppDispatch()
  const [deleteCriteria, response] = useDeleteSearchCriteriaMutation()

  const handleDeleteCriteria = async (id: string) => {
    try {
      const response = await deleteCriteria({ id }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success delete search criteria",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteCriteria: handleDeleteCriteria, response }
}
