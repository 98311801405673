import { createApi } from "@reduxjs/toolkit/query/react"
import { buildQueryString } from "@/utils/strings"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import {
  getCampaignSetupInitialState,
  setCampaignSetupName,
  setEndDate,
  setForwarding,
  setInitialState,
  setOptimization,
  setSchedule,
  setSelectedMasterList,
  setSelectedSender,
  setSelectedSenders,
  setSelectedSequence,
  setSettings,
  setStartDate,
  setStatus,
  setTimeZone,
  setUseEndDate,
} from "../campaignSetupSlice"
import type {
  Campaign,
  CampaignResponse,
  CampaignStatus,
  EditCampaignPayload,
} from "@/types/entities/campaign"
import type { PaginationProps } from "./types"
import { formatDateToISOString } from "@/utils/dates"

export const campaignsApi = createApi({
  reducerPath: "campaignsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Campaigns"],
  endpoints: (builder) => ({
    getCampaign: builder.query<CampaignResponse, { id: string }>({
      query: ({ id }) => ({
        method: "GET",
        url: `campaign/${id}`,
      }),
      transformResponse: ({ data }: { data: CampaignResponse }) => data,
      providesTags: (result, error, { id }) => [{ type: "Campaigns", id }],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled
        dispatch(setCampaignSetupName(data.name))
        dispatch(setTimeZone(data.timezone))
        dispatch(setUseEndDate(data.useEndDate))
        dispatch(setStatus({ value: data.status, changed: false }))
        if (data.senders.length) {
          dispatch(setSelectedSenders(data.senders))
          dispatch(setSelectedSender(data.senders[0]))
        }
        if (data.masterLists.length) {
          dispatch(setSelectedMasterList(data.masterLists))
        }
        if (data.sequence) {
          dispatch(setSelectedSequence(data.sequence))
        }
        if (data.schedule) {
          const { daysOfWeek, hours } = data.schedule
          dispatch(
            setSchedule({
              daysOfWeek,
              hours,
            }),
          )
        }
        if (data.optimization) {
          dispatch(setOptimization({ ...data.optimization }))
        }
        if (data.forwarding) {
          dispatch(
            setForwarding(
              data.forwarding.map((v) => ({
                ...v,
                emails: v.email,
              })),
            ),
          )
        }
        const { dailyLimit } = data
        dispatch(
          setSettings({
            dailyLimit: dailyLimit ?? 5,
          }),
        )
        if (data.startDate) {
          dispatch(
            setStartDate(
              new Date(
                formatDateToISOString(
                  new Date(data.startDate.replace("Z", "")),
                ),
              ),
            ),
          )
        }
        if (data.endDate) {
          dispatch(
            setEndDate(
              new Date(
                formatDateToISOString(
                  new Date(data.endDate.replace("Z", "")),
                  "23:59:59.999",
                ),
              ),
            ),
          )
        }
        const {
          forwarding,
          optimization,
          schedule,
          startDate,
          status,
          endDate,
        } = getCampaignSetupInitialState()
        dispatch(
          setInitialState({
            forwarding: data.forwarding
              ? data.forwarding.map(({ classifications, name, email }) => ({
                  name,
                  email,
                  emails: email,
                  classifications,
                }))
              : forwarding,
            name: data.name,
            optimization: data.optimization ?? optimization,
            schedule: data.schedule ?? schedule,
            selectedMasterList: data.masterLists.length
              ? data.masterLists
              : [null],
            selectedSender: data.senders.length ? data.senders[0] : null,
            selectedSenders: data.senders,
            selectedSequence: data.sequence ? data.sequence : null,
            settings: {
              dailyLimit: data.dailyLimit ?? 5,
            },
            startDate: data.startDate
              ? new Date(
                  formatDateToISOString(
                    new Date(data.startDate.replace("Z", "")),
                  ),
                )
              : startDate,
            status: data.status
              ? { value: data.status, changed: false }
              : status,
            tz: data.timezone,
            useEndDate: data.useEndDate,
            endDate: data.endDate
              ? new Date(
                  formatDateToISOString(
                    new Date(data.endDate.replace("Z", "")),
                    "23:59:59.999",
                  ),
                )
              : endDate,
          }),
        )
      },
    }),
    getCampaigns: builder.query<
      { items: Campaign[]; total: number },
      { orgId: string } & PaginationProps & { text?: string }
    >({
      query: ({ orgId, ...keys }) => ({
        method: "GET",
        url: `org/${orgId}/campaigns?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: Campaign[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Campaigns" as const,
                id: _id,
              })),
              "Campaigns",
            ]
          : ["Campaigns"],
    }),
    addCampaign: builder.mutation<
      { data: Campaign },
      { name: string; orgId: string }
    >({
      query: (body) => ({
        method: "POST",
        url: "campaign",
        body,
      }),
      invalidatesTags: ["Campaigns"],
    }),
    editCampaign: builder.mutation<
      { data: Campaign },
      { body: EditCampaignPayload; id: string }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `campaign/${id}`,
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Campaigns", id }],
    }),
    updateCampaignStatus: builder.mutation<
      { data: boolean },
      { id: string; body: { status: CampaignStatus } }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `campaign/${id}/status`,
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Campaigns", id }],
    }),
    publishCampaign: builder.mutation<{ data: boolean }, { id: string }>({
      query: ({ id }) => ({
        method: "PUT",
        url: `campaign/${id}/publish`,
      }),
    }),
    deleteCampaign: builder.mutation<unknown, { id: string }>({
      query: ({ id }) => ({
        method: "DELETE",
        url: `campaign/${id}`,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Campaigns", id }],
    }),
  }),
})

export const {
  useGetCampaignQuery,
  useGetCampaignsQuery,
  useAddCampaignMutation,
  useEditCampaignMutation,
  useUpdateCampaignStatusMutation,
  usePublishCampaignMutation,
  useDeleteCampaignMutation,
} = campaignsApi
