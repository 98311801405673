import { RxHamburgerMenu } from "react-icons/rx"
import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const BurgerIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <RxHamburgerMenu
      className={cx("w-5 h-5 active:scale-90", className)}
      {...rest}
    />
  )
}

export default BurgerIcon
