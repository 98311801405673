import { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useGetAdminOrganizationsQuery } from "@/redux/services/adminOrganizationsApi"
import { addAndEditArrayById } from "@/utils/arrays"
import { adminOrganizationApi } from "@/redux/services/adminOrganizationsApi"
import { useAppDispatch } from "@/redux/hooks"
import { PAGINATION_LIMIT } from "@/hooks/usePagination"
import useInfiniteScroll from "react-infinite-scroll-hook"
import type {
  AdminOrganization,
  OrganizationStatus,
} from "@/types/entities/organization"

export const useAdminOrganizationsInfiniteScroll = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const owner = useMemo(
    () => (searchParams.get("owner") ? [searchParams.get("owner")!] : []),
    [searchParams],
  )
  const [skip, setSkip] = useState(0)
  const {
    currentData,
    data: { items: orgs, total } = { items: [], total: 0 },
    isLoading,
    isFetching,
    isError,
    ...rest
  } = useGetAdminOrganizationsQuery({ skip, limit: PAGINATION_LIMIT, owner })
  const [paginatedOrgs, setPaginatedOrgs] = useState(orgs)

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading || isFetching,
    hasNextPage: skip + PAGINATION_LIMIT < total,
    onLoadMore: () => setSkip((prev) => prev + PAGINATION_LIMIT),
    disabled: isError,
    rootMargin: "0px 0px 200px 0px",
  })

  useEffect(() => {
    if (skip === 0) {
      dispatch(
        adminOrganizationApi.endpoints.getAdminOrganizations.initiate(
          { limit: PAGINATION_LIMIT, owner, skip },
          { subscribe: false, forceRefetch: true },
        ),
      )
        .unwrap()
        .then(({ items: newItems }) =>
          setPaginatedOrgs((currentArray) =>
            addAndEditArrayById<AdminOrganization>({
              currentArray,
              newItems,
            }),
          ),
        )
    }
  }, [skip, dispatch, owner])
  useEffect(() => setSkip(0), [owner, total])
  useEffect(() => {
    if (!currentData) {
      return
    }
    setPaginatedOrgs((currentArray) =>
      addAndEditArrayById<AdminOrganization>({
        currentArray,
        newItems: currentData.items,
      }),
    )
  }, [currentData])

  const updateOrganization = useCallback(
    ({ id, body }: { id: string; body: AdminOrganization }) => {
      setPaginatedOrgs((prev) =>
        prev.map((v) =>
          v._id === id
            ? {
                ...v,
                ...body,
              }
            : v,
        ),
      )
    },
    [],
  )
  const updateOrganizationStatus = useCallback(
    ({ id, body }: { id: string; body: OrganizationStatus }) => {
      setPaginatedOrgs((prev) =>
        prev.map((v) =>
          v._id === id
            ? {
                ...v,
                status: body,
              }
            : v,
        ),
      )
    },
    [],
  )
  const deleteOrganization = useCallback((id: string) => {
    setPaginatedOrgs((prev) => prev.filter((v) => v._id !== id))
  }, [])

  return {
    paginatedOrgs,
    total,
    updateOrganization,
    updateOrganizationStatus,
    deleteOrganization,
    isLoading,
    isFetching,
    isError,
    sentryRef,
    ...rest,
  }
}
