import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
  children: React.ReactNode
}

const AlertMessage: FC<Props> = ({ className, children, ...rest }: Props) => {
  return (
    <span
      className={cx("rounded-md p-1 inline-flex gap-1 text-[11px]", className)}
      {...rest}
    >
      {children}
    </span>
  )
}

export default AlertMessage
