import React, { FC, useId } from "react"
import cx from "classnames"
import type { TextFieldProps } from "../TextField"

type Props = TextFieldProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>

const Textarea: FC<Props> = ({
  className,
  topLeftLabel,
  topRightLabel,
  bottomLeftLabel,
  bottomRightLabel,
  startIcon,
  rootClassName,
  suffixIcon,
  ...rest
}: Props) => {
  const id = useId()

  return (
    <div className={cx("form-control", rootClassName)}>
      {(topLeftLabel || topRightLabel) && (
        <label htmlFor={id} className="label pl-0">
          {topLeftLabel && (
            <span className="label-text text-black font-medium">
              {topLeftLabel}
            </span>
          )}
          {topRightLabel && (
            <span className="label-text-alt text-black">{topRightLabel}</span>
          )}
        </label>
      )}
      <div className="relative">
        {startIcon && (
          <div className="absolute left-2 top-1/2 -translate-y-1/2">
            {startIcon}
          </div>
        )}
        <textarea
          id={id}
          className={cx(
            "textarea w-full focus:bg-white transition-colors px-4 py-3 bg-input placeholder:text-black placeholder:opacity-40 read-only:cursor-text read-only:focus:bg-input read-only:outline-none read-only:border-outline read-only:border read-only:bg-semi read-only:opacity-60 focus:outline-outline-dark focus:outline-[1.5px] focus:outline-offset-0",
            className,
          )}
          {...rest}
        ></textarea>
        {suffixIcon && (
          <div className="absolute top-0 right-0">{suffixIcon}</div>
        )}
      </div>
      {(bottomLeftLabel || bottomRightLabel) && (
        <label htmlFor={id} className="label py-0">
          {bottomLeftLabel && (
            <span className="label-text text-black font-medium">
              {bottomLeftLabel}
            </span>
          )}
          {bottomRightLabel && (
            <>
              {!bottomLeftLabel && <span></span>}
              <span className="label-text-alt text-black">
                {bottomRightLabel}
              </span>
            </>
          )}
        </label>
      )}
    </div>
  )
}

export default Textarea
