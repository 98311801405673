import { ButtonHTMLAttributes, FC } from "react"
import cx from "classnames"

type Props = {} & ButtonHTMLAttributes<HTMLButtonElement>

const IconButton: FC<Props> = ({ children, className, ...rest }: Props) => {
  return (
    <button
      className={cx(
        "group active:scale-90 transition-shadow hover:shadow-lg rounded-md border border-outline p-[3px] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.04)]",
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  )
}

export default IconButton
