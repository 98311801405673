import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useEditAdminProxyMutation } from "@/redux/services/adminProxyApi"
import { setToast } from "@/redux/toastSlice"
import type { EditProxyPayload } from "@/types/entities/proxy"

export const useEditAdminProxy = () => {
  const dispatch = useAppDispatch()
  const [editAdminProxy, response] = useEditAdminProxyMutation()

  const handleEditAdminProxy = async ({
    body,
    id,
  }: {
    body: EditProxyPayload
    id: string
  }) => {
    try {
      const response = await editAdminProxy({ body, id }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success edit proxy",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(message)
    }
  }

  return { editAdminProxy: handleEditAdminProxy, response }
}
