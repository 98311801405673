import { FC } from "react"
import cx from "classnames"
import { BiSolidFileDoc } from "react-icons/bi"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const MSDocIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <BiSolidFileDoc
      className={cx("w-5 h-5 transition-opacity", className)}
      {...rest}
    />
  )
}

export default MSDocIcon
