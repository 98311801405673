import dayjs from "dayjs"
import { getWeekDateRange } from "@/utils/dates"
import { INDICATOR_VALUES } from "../features/IndicatorDropdown/constants"
import type {
  ReportingData,
  ReportingEmailData,
  ReportingRepliesData,
} from "@/types/entities/organization"
import type { Campaign } from "@/types/entities/campaign"

type GroupedCampaignData = Record<string, ReportingData[]>
type ReportingValues = {
  new: number[]
  followup: number[]
  total: number[]
  lead: number[]
  reply: number[]
}
type DashboardDataset = ReportingValues & { [key: string]: number[] }

export const groupByCampaign = (
  data: ReportingEmailData,
  replies: ReportingRepliesData,
): GroupedCampaignData => {
  const groupedData: GroupedCampaignData = {}

  data.forEach((entry) => {
    const campaignId = entry._id.campaign!
    const correspondingReply = replies.find(
      ({ _id }) => JSON.stringify(_id) === JSON.stringify(entry._id),
    )

    if (!groupedData[campaignId]) {
      groupedData[campaignId] = []
    }
    let resultData = { ...entry }
    if (correspondingReply) {
      resultData = Object.assign(resultData, {
        ...correspondingReply,
      })
    }
    groupedData[campaignId].push(resultData)
  })

  return groupedData
}
export const extractValues = (
  data: Record<keyof ReportingData, number>[],
): DashboardDataset => {
  let allValues: DashboardDataset = {
    total: [],
    followup: [],
    new: [],
    reply: [],
    lead: [],
  }

  data.forEach((entry) => {
    const classificationsValues = extractClassificationsValues(entry)
    allValues.new.push(entry.new)
    allValues.followup.push(entry.followup)
    allValues.total.push(entry.total)
    allValues.lead.push(entry.lead)
    allValues.reply.push(entry.reply)

    // if there classification values, set them
    if (Object.keys(classificationsValues).length) {
      Object.entries(classificationsValues).forEach(([key, value]) => {
        if (!Array.isArray(allValues[key])) {
          allValues[key] = []
        }
        allValues[key].push(value)
      })
    }
  })

  return allValues
}

export const getDateStringFromObject = (
  dateObject: ReportingEmailData[0]["_id"],
) => {
  if (dateObject.week) {
    return getWeekDateRange(dateObject.year, dateObject.week)
  }
  if (dateObject.hour) {
    return `${dateObject.day}/${dateObject.month}/${dateObject.year} ${dayjs()
      .set("hour", dateObject.hour)
      .format("h A")}`
  }
  return Object.entries(dateObject)
    .filter(([dateUnit]) => dateUnit !== "campaign")
    .map(([, value]) => value)
    .join("/")
}

export const sortDashboardDateStrings = (a: string, b: string): number => {
  // Helper function to parse date strings into comparable values
  const parseDate = (dateString: string): Date => {
    const parts = dateString.split("/")
    const day = parts[0] ? parseInt(parts[0], 10) : 0
    const month = parts[1] ? parseInt(parts[1], 10) : 0
    const year = parts[2] ? parseInt(parts[2], 10) : 0
    return new Date(year, month - 1, day)
  }

  // Parse dates for comparison
  const dateA = parseDate(a)
  const dateB = parseDate(b)

  // Compare the parsed dates
  return dateB.getTime() - dateA.getTime()
}

export const sortIndicatorsFirst = (a: string, b: string): number => {
  const indexA = INDICATOR_VALUES.findIndex((item) => item.label === a)
  const indexB = INDICATOR_VALUES.findIndex((item) => item.label === b)

  if (indexA === -1 && indexB === -1) return 0 // Both strings not found in INDICATOR_VALUES
  if (indexA === -1) return 1 // If 'a' is not found, push it to the end
  if (indexB === -1) return -1 // If 'b' is not found, push it to the end

  return indexA - indexB
}

export const extractClassificationsValues = (
  obj: Record<string, number>,
): { [key: string]: number } => {
  const {
    new: prospects,
    followup,
    total,
    date,
    _id,
    reply,
    lead,
    ...rest
  } = obj
  return rest as { [key: string]: number }
}

const labelOrder = ["Total", "Follow ups", "Prospects", "Replies", "Leads"]

export const sortChartDataset = (a: any, b: any) => {
  const aIndex = labelOrder.indexOf(a.label)
  const bIndex = labelOrder.indexOf(b.label)

  // If both labels are in the labelOrder array, sort based on their indices
  if (aIndex !== -1 && bIndex !== -1) {
    return aIndex - bIndex
  }
  // If only one label is in the labelOrder array, prioritize it over others
  else if (aIndex !== -1) {
    return -1
  } else if (bIndex !== -1) {
    return 1
  }
  // Sort the rest based on the sum of their data values in descending order
  else {
    const sumA = a.data?.reduce((acc: any, val: any) => acc + val, 0)
    const sumB = b.data?.reduce((acc: any, val: any) => acc + val, 0)
    return sumB - sumA
  }
}

export const sortMultipleCampaigns = (
  [aId]: [string, ReportingData[]],
  [bId]: [string, ReportingData[]],
  campaigns: Campaign[],
): number => {
  const aCampaign = campaigns.find(({ _id }) => _id === aId)
  const bCampaign = campaigns.find(({ _id }) => _id === bId)
  if (!(aCampaign && bCampaign)) {
    return 0
  }
  return (
    Number(new Date(bCampaign.createdAt)) -
    Number(new Date(aCampaign.createdAt))
  )
}
