import { FC } from "react"
import cx from "classnames"
import { usePageTitle } from "@/hooks/usePageTitle"
import TextField from "@/components/TextField"
import { usePagination } from "@/hooks/usePagination"
import SearchIcon from "@/assets/search.svg"
import EmptyList from "@/components/EmptyList"

type Props = {
  className?: string
}

const SpamTestingPage: FC<Props> = ({ className, ...rest }: Props) => {
  usePageTitle("Spam Testing")
  const { searchTerm, handleSearchTermChange } = usePagination()
  return (
    <div className={cx("", className)} {...rest}>
      <div className="flex justify-between items-center gap-1">
        <TextField
          value={searchTerm}
          onChange={handleSearchTermChange}
          placeholder="Search spam solver"
          startIcon={<SearchIcon className="w-6 h-6" />}
          className="text-sm outline outline-outline !h-9 !py-1.5"
          rootClassName="basis-56"
          type="search"
        />
      </div>
      <div
        className={cx(
          "mt-5 flex bg-white rounded-md shadow-[0px_1px_4px_0px_rgba(0,0,0,0.06)] px-2 py-1 min-h-[380px]",
          { "justify-center items-center": ![].length },
        )}
      >
        {false ? (
          <span className="loading text-primary loading-spinner w-12"></span>
        ) : (
          <>
            {[].length ? (
              <div className="w-full">
                <div></div>
              </div>
            ) : (
              <EmptyList message="There are no spam testing yet"></EmptyList>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default SpamTestingPage
