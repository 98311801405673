import { FC } from "react"
import cx from "classnames"
import { Tooltip as AntdTooltip, type TooltipProps } from "antd"

type Props = {
  className?: string
  children: React.ReactNode
} & TooltipProps

const Tooltip: FC<Props> = ({ className, children, ...rest }: Props) => {
  return (
    <AntdTooltip
      color="#26313FCC"
      overlayInnerStyle={{
        paddingBlock: 4,
        paddingInline: 8,
        borderRadius: 4,
        fontSize: 10,
        lineHeight: 1.4,
        fontWeight: 500,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
      }}
      className={cx("", className)}
      {...rest}
    >
      {children}
    </AntdTooltip>
  )
}

export default Tooltip
