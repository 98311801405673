import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteAdminProxyMutation } from "@/redux/services/adminProxyApi"
import { setToast } from "@/redux/toastSlice"

export const useDeleteAdminProxy = () => {
  const dispatch = useAppDispatch()
  const [deleteAdminProxy, response] = useDeleteAdminProxyMutation()

  const handleDeleteAdminProxy = async (ids: string[]) => {
    try {
      const response = await deleteAdminProxy(ids).unwrap()
      if (response.data.success) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success delete proxy",
          }),
        )
      }
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(message)
    }
  }

  return { deleteAdminProxy: handleDeleteAdminProxy, response }
}
