import { FC } from "react"
import { SelectProps } from "antd"
import Select from "@/components/Select"
import type { MasterListRecord } from "@/types/entities/master-list"

type Props = SelectProps

export const MLStatusColorMap: Record<
  MasterListRecord["status"],
  { color: string; label: string }
> = {
  none: { label: "Available", color: "var(--positive)" },
  skip: { label: "Skip", color: "var(--error)" },
  used: { label: "Used", color: "var(--info)" },
}

const MasterListRecordStatusSelect: FC<Props> = ({
  value,
  placeholder,
  ...rest
}: Props) => {
  return (
    <Select
      bordered
      options={[
        {
          label: "Available",
          value: "none",
          color: "var(--positive)",
        },
        { label: "Used", value: "used", color: "var(--info)" },
        { label: "Skip", value: "skip", color: "var(--error)" },
      ]}
      optionRender={({ data, label }) => (
        <div className="flex gap-2.5 items-center">
          <div
            className="rounded-full h-2.5 w-2.5"
            style={{ backgroundColor: data.color }}
          ></div>
          <span className="text-sm leading-6">{label}</span>
        </div>
      )}
      value={{
        value,
        label: value ? (
          <div className="flex gap-2.5 items-center">
            <div
              className="rounded-full h-2.5 w-2.5"
              style={{
                backgroundColor:
                  MLStatusColorMap[value as MasterListRecord["status"]]?.color,
              }}
            ></div>
            <span className="text-sm leading-6">
              {MLStatusColorMap[value as MasterListRecord["status"]]?.label}
            </span>
          </div>
        ) : (
          <>{placeholder}</>
        ),
      }}
      labelInValue
      popupMatchSelectWidth={150}
      placeholder={placeholder}
      {...rest}
    />
  )
}

export default MasterListRecordStatusSelect
