import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import type { Country } from "@/types/entities/country"
import type { Timezone } from "@/types/entities/timezone"
import type { USState } from "@/types/entities/us-state"
import type { SystemValuesResponse } from "@/types/entities/system-values"
import type { ReplyClassification } from "@/types/entities/reply-classification"
import type { CanadaProvince } from "@/types/entities/province"

export const generalApi = createApi({
  reducerPath: "generalApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Timezones",
    "Countries",
    "US States",
    "System Values",
    "Reply Classifications",
    "Canada Provinces",
  ],
  endpoints: (builder) => ({
    getTimezones: builder.query<Timezone[], void>({
      providesTags: ["Timezones"],
      query: () => ({
        url: "/general/timezones",
        method: "GET",
      }),
      transformResponse: ({ data }: { data: Timezone[] }) => {
        return data
      },
    }),
    getCountries: builder.query<Country[], void>({
      providesTags: ["Countries"],
      query: () => ({
        url: "/general/countries",
        method: "GET",
      }),
      transformResponse: ({ data }: { data: Country[] }) => {
        return data
      },
    }),
    getUsStates: builder.query<USState[], void>({
      providesTags: ["US States"],
      query: () => ({
        url: "/general/usStates",
        method: "GET",
      }),
      transformResponse: ({ data }: { data: USState[] }) => {
        return data
      },
    }),
    getSystemValues: builder.query<SystemValuesResponse, void>({
      query: () => ({
        url: "general/values",
        method: "GET",
      }),
      transformResponse: ({ data }: { data: SystemValuesResponse }) => {
        return data
      },
      providesTags: ["System Values"],
    }),
    getReplyClassifications: builder.query<ReplyClassification[], void>({
      query: () => ({
        url: "general/classifications",
        method: "GET",
      }),
      transformResponse: ({ data }: { data: ReplyClassification[] }) => data,
      providesTags: ["Reply Classifications"],
    }),
    getCanadaProvinces: builder.query<CanadaProvince[], void>({
      query: () => ({
        url: "general/canadaProvinces",
        method: "GET",
      }),
      transformResponse: ({ data }: { data: CanadaProvince[] }) => data,
      providesTags: ["Canada Provinces"],
    }),
  }),
})

export const {
  useGetCountriesQuery,
  useGetTimezonesQuery,
  useGetUsStatesQuery,
  useGetSystemValuesQuery,
  useGetReplyClassificationsQuery,
  useGetCanadaProvincesQuery,
} = generalApi
