import { FC } from "react"
import TextField from "@/components/TextField"
import EmailConnectionStatus from "@/components/EmailConnectionStatus"
import GoogleOAuthLogin from "@/components/GoogleOAuthLogin"
import Loader from "@/components/Loader"
import { useAdminAccountLogin } from "../../hooks/useAdminAccountLogin"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import type { GsuiteAccount } from "@/types/entities/gsuite-account"

type Props = {
  data?: GsuiteAccount
}

const GoogleLoginView: FC<Props> = ({ data }: Props) => {
  const { data: { GSuiteAppID } = { GSuiteAppID: "" }, isLoading } =
    useGetSystemValuesQuery()
  const { getFieldProps, setFieldValue, disconnectMail } =
    useAdminAccountLogin()

  const handleDisconnect = async () => {
    if (data) {
      await disconnectMail(data._id)
      setFieldValue("code", "")
    }
  }
  const handleLogin = async (codeResponse: Record<string, any>) =>
    setFieldValue("code", codeResponse.code)

  const code = getFieldProps("code").value

  return (
    <div className="flex flex-col gap-5 [&_.label]:pt-0">
      <TextField
        className="input-sm"
        placeholder="Enter gsuite account name"
        topLeftLabel="Account Name"
        {...getFieldProps("displayName")}
      />
      {isLoading ? (
        <Loader className="mx-auto" />
      ) : (
        <div className="flex flex-col gap-1.5">
          <span className="text-sm leading-normal font-medium">
            Email Address
          </span>
          {data?.email && data.connected ? (
            <EmailConnectionStatus
              connected={data.connected}
              connectedTo={data.email}
              onDisconnect={handleDisconnect}
            />
          ) : code && code !== "-" ? (
            <EmailConnectionStatus connected connectedTo={null} />
          ) : (
            <GoogleOAuthLogin
              clientId={GSuiteAppID}
              onSuccess={handleLogin}
              scope="https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/siteverification https://www.googleapis.com/auth/admin.directory.user https://www.googleapis.com/auth/admin.directory.domain"
            />
          )}
        </div>
      )}
      <TextField
        className="input-sm"
        placeholder="10"
        topLeftLabel="Max Accounts"
        {...getFieldProps("maxDomains")}
      />
    </div>
  )
}

export default GoogleLoginView
