import { FC, useMemo, useState } from "react"
import cx from "classnames"
import TextField from "@/components/TextField"
import SearchIcon from "@/assets/search.svg"
import AiRobotIcon from "@/assets/ai-robot.svg"
import { BiPlus as AddIcon } from "react-icons/bi"
import { usePagination } from "@/hooks/usePagination"
import OutlineButton from "@/components/OutlineButton"
import { useGetAIPromptsQuery } from "@/redux/services/aiPromptsApi"
import AddEditPromptModal from "./features/AddEditPromptModal"
import AIPromptAccordion from "./features/AIPromptAccordion"
import DeletePromptModal from "./features/DeletePromptModal"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import type { AiPrompt } from "@/types/entities/ai-prompt"

type Props = {
  className?: string
}

const ReplyClassificationPage: FC<Props> = ({ className, ...rest }: Props) => {
  const { searchTerm, handleSearchTermChange } = usePagination()
  const { data: prompts = [], isLoading, isError } = useGetAIPromptsQuery()
  const [openAddModal, setOpenAddModal] = useState(false)

  const [edittedPromptId, setEditedPromptId] = useState("")
  const [deletedPromptId, setDeletedPromptId] = useState("")
  const [addingSubQueryPrompt, setAddingSubqueryPrompt] =
    useState<AiPrompt | null>(null)
  const [editingSubQueryPrompt, setEditingSubqueryPrompt] =
    useState<AiPrompt | null>(null)
  const handleOpenAddModal = () => setOpenAddModal(true)

  const topLevelQuery = useMemo(
    () => prompts.find(({ parent }) => !parent),
    [prompts],
  )

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className={cx("", className)} {...rest}>
        <div className="flex justify-between items-center flex-wrap sm:flex-nowrap gap-1">
          <h2 className="leading-8 text-2xl font-semibold">
            Reply Classification
          </h2>
          <TextField
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Search prompt"
            startIcon={<SearchIcon className="w-6 h-6" />}
            className="text-sm outline outline-outline input-sm"
            rootClassName="basis-56"
            type="search"
          />
        </div>
        <div
          className={cx("mt-5 flex", {
            "justify-center items-center min-h-[292px]": !prompts.length,
          })}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {prompts.length && topLevelQuery ? (
                <div className="flex flex-col gap-4 w-full">
                  <AIPromptAccordion
                    prompt={topLevelQuery}
                    onEdit={setEditedPromptId}
                    onDelete={setDeletedPromptId}
                    onAddSubquery={setAddingSubqueryPrompt}
                    onEditSubquery={setEditingSubqueryPrompt}
                  />
                </div>
              ) : (
                <div className="flex justify-center items-center flex-col gap-[13px]">
                  <AiRobotIcon />
                  <span className="text-base leading-6 opacity-60">
                    There are no AI prompts yet
                  </span>
                  <OutlineButton
                    onClick={handleOpenAddModal}
                    className="btn-primary btn-base"
                  >
                    <AddIcon className="w-6 h-6" />
                    <span>Add Main AI Query</span>
                  </OutlineButton>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <AddEditPromptModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
      />
      <AddEditPromptModal
        open={!!edittedPromptId}
        onClose={() => setEditedPromptId("")}
        data={prompts.find(({ _id }) => _id === edittedPromptId)}
      />
      <AddEditPromptModal
        open={!!addingSubQueryPrompt}
        onClose={() => setAddingSubqueryPrompt(null)}
        data={addingSubQueryPrompt}
        isSubquery
        textFieldLabel="Send the following query"
      />
      <AddEditPromptModal
        open={!!editingSubQueryPrompt}
        onClose={() => setEditingSubqueryPrompt(null)}
        data={editingSubQueryPrompt}
        isSubquery
        editSubquery
        textFieldLabel="Send the following query"
      />
      <DeletePromptModal
        open={!!deletedPromptId}
        onClose={() => setDeletedPromptId("")}
        data={prompts.find(({ _id }) => _id === deletedPromptId)}
      />
    </>
  )
}

export default ReplyClassificationPage
