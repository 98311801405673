import React from "react"
import cx from "classnames"
import CaretDownIcon from "@/assets/caret-down.svg"
import { useFocusedElement } from "@/hooks/useFocusedElement"

type Props = {
  children: React.ReactNode
  items: React.ReactNode[]
  className?: string
  bordered?: boolean
  noCaret?: boolean
}

const Menu = ({
  children,
  className,
  items = [],
  noCaret,
  bordered = false,
}: Props) => {
  const focusedElement = useFocusedElement()

  const handleClick = () => focusedElement?.blur()

  return (
    <div className={cx("dropdown", className)}>
      <label
        tabIndex={0}
        className={cx("gap-1 flex items-center md:gap-2 cursor-pointer", {
          "border-outline-dark border rounded-md shadow-sm pl-3 pr-2 py-2":
            bordered,
        })}
      >
        {children}
        {!noCaret && <CaretDownIcon className="opacity-60" />}
      </label>
      <ul
        tabIndex={0}
        className="menu bg-white dropdown-content z-50 px-4 py-3 shadow rounded-box min-w-[200px] w-max max-w-[275px] mt-4"
        onClick={handleClick}
      >
        {items.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    </div>
  )
}

export default Menu
