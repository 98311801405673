import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
  message: string
  icon: React.ReactNode
}

const SelectionEmpty: FC<Props> = ({
  className,
  message,
  icon,
  ...rest
}: Props) => {
  return (
    <div
      className={cx(
        "flex-1 h-full flex justify-center items-center opacity-60",
        className,
      )}
      {...rest}
    >
      <div className="flex flex-col items-center gap-2">
        {icon}
        <span>{message}</span>
      </div>
    </div>
  )
}

export default SelectionEmpty
