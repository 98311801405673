import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteAIPromptMutation } from "@/redux/services/aiPromptsApi"
import { setToast } from "@/redux/toastSlice"

export const useDeleteAiPrompt = () => {
  const dispatch = useAppDispatch()
  const [deleteAiPrompt, response] = useDeleteAIPromptMutation()

  const handleDeleteAiPrompt = async (id: string) => {
    try {
      await deleteAiPrompt({ id }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success delete prompt",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteAiPrompt: handleDeleteAiPrompt, response }
}
