import { useNavigate } from "react-router-dom"
import { useConfirmEmailMutation } from "@/core/api/services"
import { Routes } from "@/core/routing/routes"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import type { UserTokenPayload } from "@/core/api/types"

export const useConfirmEmail = () => {
  const dispatch = useAppDispatch()
  const [confirmEmail, response] = useConfirmEmailMutation()
  const navigate = useNavigate()

  const handleConfirmEmail = async ({ email, token }: UserTokenPayload) => {
    try {
      const response = await confirmEmail({
        email: email?.trim().replace(" ", "+"),
        token,
      }).unwrap()
      if (response.success) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Your email address was successfully confirmed",
          }),
        )
      }
      setTimeout(() => {
        navigate(Routes.login)
      }, 2100)
    } catch (error: any) {
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message: "We couldn't confirm your email address",
        }),
      )
    }
  }

  return { confirmEmail: handleConfirmEmail, response }
}
