import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import { RootState } from "./store"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import type { SenderAPIResponse } from "@/types/entities/sender"
import type { MasterList } from "@/types/entities/master-list"
import type { SequenceAPIResponse } from "@/types/entities/sequence"
import type { CampaignStatus, ForwardingGroup } from "@/types/entities/campaign"

export type OptimizationType = "manual" | "auto"

export type CampaignSetupState = {
  name: string
  selectedSenders: SenderAPIResponse[]
  selectedSender: SenderAPIResponse | null
  selectedMasterList: Array<MasterList | null>
  selectedSequence: SequenceAPIResponse | null
  optimization: {
    copy: OptimizationType
    targeting: OptimizationType
    volume: OptimizationType
    leadsWeeklyMin: number
    leadsWeeklyMax: number
  }
  tz: string
  useEndDate: boolean
  startDate: Date | null
  endDate?: Date | null
  forwarding: ForwardingGroup[]
  settings: {
    dailyLimit: number
  }
  schedule: {
    daysOfWeek: number[]
    hours: {
      from: number
      to: number
    }
  }
  status: { value: CampaignStatus; changed: boolean }
  initialState: Omit<CampaignSetupState, "initialState">
}

dayjs.extend(utc)
dayjs.extend(timezone)
// const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
// const localTime = dayjs().utc().tz(localTimezone)
const localTime = new Date()

const initialState: CampaignSetupState = {
  name: "",
  selectedSenders: [],
  selectedSender: null,
  selectedMasterList: [null],
  selectedSequence: null,
  optimization: {
    copy: "auto",
    targeting: "auto",
    volume: "auto",
    leadsWeeklyMin: 0,
    leadsWeeklyMax: 0,
  },
  tz: "",
  settings: {
    dailyLimit: 5,
  },
  useEndDate: false,
  startDate: dayjs().startOf("date").toDate(),
  endDate: null,
  forwarding: [],
  schedule: {
    daysOfWeek: [1, 2, 3, 4, 5],
    hours: {
      from: localTime.getHours() + 2,
      to: localTime.getHours() + 4,
    },
  },
  status: { value: "active", changed: false },
  initialState: {} as CampaignSetupState,
}

export const getCampaignSetupInitialState = () => {
  const { initialState: init, ...rest } = { ...initialState }
  return {
    ...rest,
  }
}

const campaignSetupSlice = createSlice({
  name: "campaignSetup",
  initialState,
  reducers: {
    setCampaignSetupName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload
    },
    setSelectedSenders: (
      state,
      { payload }: PayloadAction<SenderAPIResponse[]>,
    ) => {
      state.selectedSenders = payload
    },
    setSelectedMasterList: (
      state,
      { payload }: PayloadAction<Array<MasterList | null>>,
    ) => {
      state.selectedMasterList = payload
    },
    setSelectedSequence: (
      state,
      { payload }: PayloadAction<SequenceAPIResponse>,
    ) => {
      state.selectedSequence = payload
    },
    setOptimization: (
      state,
      { payload }: PayloadAction<(typeof initialState)["optimization"]>,
    ) => {
      state.optimization = payload
    },
    setSettings: (
      state,
      { payload }: PayloadAction<(typeof initialState)["settings"]>,
    ) => {
      state.settings = payload
    },
    setSchedule: (
      state,
      { payload }: PayloadAction<(typeof initialState)["schedule"]>,
    ) => {
      state.schedule = payload
    },
    setUseEndDate: (state, { payload }: PayloadAction<boolean>) => {
      state.useEndDate = payload
    },
    setStartDate: (state, { payload }: PayloadAction<Date>) => {
      state.startDate = payload
    },
    setEndDate: (state, { payload }: PayloadAction<Date | null>) => {
      state.endDate = payload
    },
    setTimeZone: (state, { payload }: PayloadAction<string>) => {
      state.tz = payload
    },
    setStatus: (
      state,
      { payload }: PayloadAction<(typeof initialState)["status"]>,
    ) => {
      state.status = payload
    },
    setForwarding: (state, { payload }: PayloadAction<ForwardingGroup[]>) => {
      state.forwarding = payload
    },
    resetCampaign: () => initialState,
    setSelectedSender: (
      state,
      { payload }: PayloadAction<SenderAPIResponse | null>,
    ) => {
      state.selectedSender = payload
    },
    setInitialState: (
      state,
      { payload }: PayloadAction<Omit<CampaignSetupState, "initialState">>,
    ) => {
      state.initialState = payload
    },
    setInitialStateName: (state, { payload }: PayloadAction<string>) => {
      state.initialState.name = payload
    },
  },
})

export const selectCampaignSetupName = (state: RootState) =>
  state.campaignSetup.name
export const selectCampaignSetupSenders = (state: RootState) =>
  state.campaignSetup.selectedSenders
export const selectCampaignSetupMasterList = (state: RootState) =>
  state.campaignSetup.selectedMasterList
export const selectCampaignSetupSequence = (state: RootState) =>
  state.campaignSetup.selectedSequence
export const selectCampaignSetupOptimization = (state: RootState) =>
  state.campaignSetup.optimization
export const selectCampaignSetupSettings = (state: RootState) =>
  state.campaignSetup.settings
export const selectCampaignSetupSchedule = (state: RootState) =>
  state.campaignSetup.schedule
export const selectCampaignSetupUseEndDate = (state: RootState) =>
  state.campaignSetup.useEndDate
export const selectCampaignSetupStartDate = (state: RootState) =>
  state.campaignSetup.startDate
export const selectCampaignSetupEndDate = (state: RootState) =>
  state.campaignSetup.endDate
export const selectCampaignSetupTimezone = (state: RootState) =>
  state.campaignSetup.tz
export const selectCampaignSetupStatus = (state: RootState) =>
  state.campaignSetup.status
export const selectCampaignSetupForwarding = (state: RootState) =>
  state.campaignSetup.forwarding
export const selectCampaignSetupSelectedSender = (state: RootState) =>
  state.campaignSetup.selectedSender
export const selectCampaignSetupInitialState = (state: RootState) =>
  state.campaignSetup.initialState
export const selectCampaignSetupState = createSelector(
  (state: RootState) => state.campaignSetup,
  ({ initialState, ...rest }) => ({ ...rest }),
)

export const {
  setCampaignSetupName,
  setSelectedMasterList,
  setSelectedSenders,
  setSelectedSequence,
  setOptimization,
  setSettings,
  setSchedule,
  setEndDate,
  setStartDate,
  setUseEndDate,
  setTimeZone,
  setStatus,
  setForwarding,
  resetCampaign,
  setSelectedSender,
  setInitialState,
  setInitialStateName,
} = campaignSetupSlice.actions

export default campaignSetupSlice.reducer
