import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import { Routes } from "@/core/routing/routes"
import validationSchema from "./validation-schema"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import { useSetupPassword } from "@/hooks/useSetupPassword"

const NewPasswordForm = () => {
  const navigate = useNavigate()
  const { setupPassword, response } = useSetupPassword(() =>
    navigate(Routes.forgotPassword.done),
  )

  const {
    handleSubmit,
    getFieldProps,
    touched: {
      confirmPassword: confirmPasswordTouched,
      password: passwordTouched,
    },
    errors: { confirmPassword: confirmPasswordError, password: passwordError },
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: ({ password }) => {
      setupPassword({ password })
    },
  })
  return (
    <div className="flex flex-col gap-8 w-full">
      <h4 className="text-xl sm:text-2xl font-semibold text-center">
        Create New Password
      </h4>
      <form className="flex flex-col gap-5 w-full" onSubmit={handleSubmit}>
        <TextField
          type="password"
          placeholder="Enter your password"
          topLeftLabel="Password"
          bottomLeftLabel={
            <FormFieldErrorMessage
              error={passwordError}
              touched={passwordTouched}
            />
          }
          {...getFieldProps("password")}
        />
        <TextField
          type="password"
          placeholder="Re-type your new password"
          topLeftLabel="Confirm Password"
          bottomLeftLabel={
            <FormFieldErrorMessage
              error={confirmPasswordError}
              touched={confirmPasswordTouched}
            />
          }
          {...getFieldProps("confirmPassword")}
        />
        <Button
          fullWidth
          disabled={response.isLoading}
          className="mt-1"
          type="submit"
        >
          Save New Password
        </Button>
      </form>
    </div>
  )
}

export default NewPasswordForm
