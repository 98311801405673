import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import FileInput from "@/components/FileInput"
import ApiCallLoader from "@/components/ApiCallLoader"
import { useAddMasterListCSV } from "../../hooks/useAddMasterListCSV"
import { useAddMasterList } from "../../hooks/useAddMasterList"

type Props = {
  data?: null | { name: string }
} & ModalProps

const ImportMasterListModal: FC<Props> = ({ open, onClose, data }: Props) => {
  const {
    addMasterListCVS,
    response: { isLoading: addMasterListCVSLoading },
  } = useAddMasterListCSV()
  const {
    addMasterList,
    response: { isLoading: addMasterListLoading },
  } = useAddMasterList()
  const [files, setFiles] = useState<FileList | null>(null)
  const { id: orgId = "" } = useParams()

  const handleSubmit = async () => {
    const file = files!.item(0)
    if (!file || !data?.name) {
      return
    }
    try {
      await addMasterListCVS({ orgId, name: data.name, csv: file })
      onClose && onClose()
    } catch (e) {
      console.error(e)
    }
  }
  const handleSkip = async () => {
    if (!data?.name) {
      return
    }
    try {
      await addMasterList({ name: data.name, records: [] })
      onClose && onClose()
    } catch (e) {
      console.error(e)
    }
  }
  const isLoading = addMasterListLoading || addMasterListCVSLoading

  return (
    <>
      <Modal
        actions={
          <div className="flex justify-between w-full">
            <Button
              disabled={isLoading}
              className="btn-sm mr-auto !w-[86px]"
              type="button"
              onClick={handleSkip}
            >
              Skip
            </Button>
            <div className="flex gap-4">
              <OutlineButton
                disabled={isLoading}
                onClick={onClose}
                className="text-black btn-sm !w-[86px]"
                type="button"
              >
                Cancel
              </OutlineButton>
              <Button
                disabled={!files || isLoading}
                className="btn-sm !w-[86px]"
                type="submit"
              >
                Import
              </Button>
            </div>
          </div>
        }
        title="Import List Names"
        open={open}
        onClose={onClose}
        onSubmit={handleSubmit}
        className="[&_.modal-action>form]:w-full"
      >
        <div className="text-sm font-normal leading-6">
          <p>
            You can import by uploading an Excel file with the data. In case
            your file contains header fields, they will be automatically
            detected. Otherwise, you can specify them manually.{" "}
            <span className="font-medium underline text-primary">
              File Example
            </span>
          </p>
          <p className="mt-3.5">Following fields are available for import:</p>
        </div>
        <ul className="mt-3 list-disc list-inside marker:text-[#26313f66]">
          <li className="text-sm leading-6">
            <span className="-ml-1">List Name</span>
          </li>
        </ul>
        <FileInput
          onFileSelect={(v) => setFiles(v)}
          className="mt-4"
          accept=".xlsx, .xls, .csv"
        />
        <div className="caption font-normal mt-2">
          Supported files .XLS, .XLSX, .CSV; Maximum size 10mb
        </div>
      </Modal>
      <ApiCallLoader open={isLoading}>
        <span className="opacity-80 text-xs leading-normal font-medium">
          Adding
          <span className="font-semibold">
            {data?.name ? ` "${data.name}"` : ""}
          </span>
          ...
        </span>
      </ApiCallLoader>
    </>
  )
}

export default ImportMasterListModal
