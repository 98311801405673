import { FC, useState } from "react"
import cx from "classnames"
import OutlineButton from "@/components/OutlineButton"
import InboxMessage from "../InboxMessage"
import AddIcon from "@/components/icons/AddIcon"
import InfoIcon from "@/components/icons/InfoIcon"
import CloseIcon from "@/components/icons/CloseIcon"
import CancelIcon from "@/components/icons/CancelIcon"
import TextField from "@/components/TextField"
import Button from "@/components/Button"
import validationSchema from "./validationSchema"
import { useFormik } from "formik"
import { useInboxForward } from "../../hooks/useInboxForward"
import { useSpring, animated, easings } from "@react-spring/web"
import { useClientRect } from "@/hooks/useClientRect"
import { useUserAccess } from "@/hooks/useUserAccess"
import type { Inbox } from "@/types/entities/inbox"

type Props = {
  className?: string
  inbox: Inbox
}

const SelectedInboxView: FC<Props> = ({ className, inbox, ...rest }: Props) => {
  const [infoOpened, setInfoOpened] = useState(false)
  const { pageFullAccess } = useUserAccess({ pageName: "Inbox" })
  const [rect, ref] = useClientRect()
  const springs = useSpring({
    height: infoOpened ? rect?.height : 0,
    config: {
      duration: 300,
      easing: easings.linear,
    },
  })
  const {
    forwardInbox,
    response: { isLoading },
  } = useInboxForward()
  const {
    handleSubmit,
    isValid,
    getFieldProps,
    dirty,
    resetForm,
    setFieldValue,
    values: { emails },
  } = useFormik({
    initialValues: {
      emails: [""],
    },
    validationSchema,
    onSubmit: async ({ emails }) => {
      try {
        const response = await forwardInbox({
          id: inbox._id,
          emails: emails.filter(Boolean),
        })
        if (response) {
          resetForm()
        }
      } catch (e) {
        console.error(e)
      }
    },
  })

  return (
    <div
      className={cx(
        "p-2 md:p-6 flex-1 flex flex-col gap-4 h-full overflow-x-hidden",
        className,
      )}
      {...rest}
    >
      <InboxMessage inbox={inbox} className="flex-1 overflow-hidden" />
      {pageFullAccess && (
        <div className="flex flex-col gap-2">
          <OutlineButton
            onClick={() => setInfoOpened((prev) => !prev)}
            className="btn-base text-black"
          >
            {infoOpened ? (
              <>
                <CloseIcon />
                <span>Close</span>
              </>
            ) : (
              <>
                <InfoIcon />
                <span>Info & Actions</span>
              </>
            )}
          </OutlineButton>
          <animated.div
            className="overflow-y-auto"
            style={{
              ...springs,
            }}
          >
            <div
              ref={ref}
              className="rounded-md border border-outline p-3 flex flex-col md:flex-row gap-4"
            >
              <div className="space-y-4 basis-1/4">
                <div className="space-y-0.5">
                  <div className="text-xs leading-[18px] opacity-60 font-medium">
                    Campaign
                  </div>
                  <div
                    className={cx("text-sm leading-6", {
                      "opacity-60": !inbox.campaign,
                      "text-primary": inbox.campaign,
                    })}
                  >
                    {inbox.campaign?.name ?? "None"}
                  </div>
                </div>
                <div className="space-y-0.5">
                  <div className="text-xs leading-[18px] opacity-60 font-medium">
                    Sequence
                  </div>
                  <div
                    className={cx("text-sm leading-6", {
                      "opacity-60": !inbox.sequence,
                      "text-primary": inbox.sequence,
                    })}
                  >
                    {inbox.sequence?.name ?? "None"}
                  </div>
                </div>
                <div className="space-y-0.5">
                  <div className="text-xs leading-[18px] opacity-60 font-medium">
                    Master List
                  </div>
                  <div
                    className={cx("text-sm leading-6", {
                      "opacity-60": !inbox.masterList,
                      "text-primary": inbox.masterList,
                    })}
                  >
                    {inbox.masterList?.name ?? "None"}
                  </div>
                </div>
              </div>
              <div className="flex-1 space-y-4 md:pl-4 md:border-l border-outline">
                <div className="space-y-1.5">
                  <div className="text-xs leading-[18px] opacity-60 font-medium">
                    Forward
                  </div>
                  <div className="space-y-3">
                    {emails.map((_, i) => (
                      <div key={i} className="relative">
                        {emails.length > 1 ? (
                          <CancelIcon
                            onClick={() =>
                              setFieldValue(
                                `emails`,
                                emails.filter((_, removeI) => i !== removeI),
                              )
                            }
                            className="cursor-pointer absolute hover:fill-error bottom-8 right-0 z-10"
                          />
                        ) : null}
                        <TextField
                          placeholder={`Email address ${i + 1}`}
                          className="input-sm"
                          type="email"
                          {...getFieldProps(`emails[${i}]`)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-wrap gap-1 justify-between">
                  <Button
                    disabled={!(isValid && dirty) || isLoading}
                    onClick={() => handleSubmit()}
                    className="btn-base min-w-[86px]"
                    type="submit"
                  >
                    Send
                  </Button>
                  <OutlineButton
                    onClick={() => setFieldValue("emails", emails.concat(""))}
                    className="btn-base flex text-black w-36"
                    type="button"
                  >
                    <AddIcon className="w-6 h-6 text-black" />
                    <span>Add Another</span>
                  </OutlineButton>
                </div>
              </div>
            </div>
          </animated.div>
        </div>
      )}
    </div>
  )
}

export default SelectedInboxView
