import { useParams } from "react-router-dom"
import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useEditUserDNCMutation } from "@/redux/services/userDncApi"
import { setToast } from "@/redux/toastSlice"

export const useUserEditDNC = () => {
  const dispatch = useAppDispatch()
  const { id: orgId = "" } = useParams()
  const [editUserDNC, response] = useEditUserDNCMutation()

  const handleEditAdminDNC = async (body: {
    recordId: string
    value: string
  }) => {
    try {
      const response = await editUserDNC({ body, orgId }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success edit contact",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(message)
    }
  }

  return { editUserDNC: handleEditAdminDNC, response }
}
