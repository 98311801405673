import { FC } from "react"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"

type Props = {
  className?: string
  value: string
}

const CampaignsStatusText: FC<Props> = ({ value }: Props) => {
  const { data } = useGetSystemValuesQuery()
  if (!data) {
    return null
  }
  return (
    <>
      {data.campaignStatuses.find(({ type }) => type === value)?.display ??
        value}
    </>
  )
}

export default CampaignsStatusText
