import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
  children?: React.ReactNode
  message?: React.ReactNode
}

const ErrorMessage: FC<Props> = ({
  className,
  children,
  message = "Error loading resource. Please try again later.",
  ...rest
}: Props) => {
  return (
    <div className={cx("rounded-md p-2", className)} {...rest}>
      <span className="text-error font-medium text-lg">{message}</span>
      {children}
    </div>
  )
}

export default ErrorMessage
