import React from "react"
import cx from "classnames"
import { twMerge } from "tailwind-merge"

type Props = {
  className?: string
  children: React.ReactNode
  fullWidth?: boolean
  active?: boolean
  loading?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const OutlineButton = ({
  className,
  children,
  fullWidth = false,
  active = false,
  disabled,
  loading,
  ...rest
}: Props) => {
  return (
    <button
      className={twMerge(
        cx(
          "transition-colors btn btn-outline min-w-[44px] rounded-md py-3 px-4 normal-case border-outline-dark disabled:opacity-60",
          fullWidth ? "w-full" : "w-fit",
          {
            "btn-primary bg-primary-glass border-primary border-[1.5px] font-medium":
              active,
            "btn-disabled !border-opacity-100": disabled,
          },
          className,
        ),
      )}
      disabled={disabled}
      {...rest}
    >
      {loading && (
        <span className="loading text-primary loading-spinner"></span>
      )}
      {children}
    </button>
  )
}

export default OutlineButton
