import { createContext, useContext } from "react"
import type { SequenceFormValues } from "./useEmailSequenceFormik"

export const EmailSequenceFormContext = createContext<
  SequenceFormValues["formik"]
>({} as SequenceFormValues["formik"])

export const useEmailSequenceValues = () => {
  const ctx = useContext(EmailSequenceFormContext)

  return ctx
}
