import { FC } from "react"
import cx from "classnames"
import { Popover as AntPopover, PopoverProps } from "antd"

type Props = {
  className?: string
} & PopoverProps

const Popover: FC<Props> = ({
  className,
  children,
  trigger = "click",
  ...rest
}: Props) => {
  return (
    <AntPopover trigger={trigger} className={cx("", className)} {...rest}>
      {children}
    </AntPopover>
  )
}

export default Popover
