import { createApi } from "@reduxjs/toolkit/query/react"
import {
  setDKIM,
  setDomainId,
  setEmailProvider,
  setName,
  setProvider,
  setUrlRedirect,
} from "../domainSetupSlice"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import { buildQueryString } from "@/utils/strings"
import type {
  Domain,
  DomainDNS,
  DomainVerifyResponse,
} from "@/types/entities/domain"

export const domainsApi = createApi({
  reducerPath: "domainsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Domains", "DNS Records", "Email Domains"],
  endpoints: (builder) => ({
    getDomains: builder.query<
      { items: Domain[]; total: number },
      { orgId: string; skip?: number; limit?: number; text?: string }
    >({
      query: ({ orgId, ...keys }) => ({
        method: "GET",
        url: `org/${orgId}/domains?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: Domain[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Domains" as const,
                _id,
              })),
              { type: "Domains", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Domains", id: "PARTIAL-LIST" }],
    }),
    getDomain: builder.query<Domain, { id: string }>({
      query: ({ id }) => ({
        method: "GET",
        url: `domain/${id}`,
      }),
      transformResponse: ({ data }: { data: Domain }) => data,
      providesTags: (result, error, { id }) => [{ type: "Domains", id }],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled

        dispatch(setDomainId(data._id))
        dispatch(setName(data.name))
        dispatch(setProvider(data.provider))
        if (data.urlRedirect) {
          dispatch(setUrlRedirect(data.urlRedirect))
        }
        if (data.emailProvider) {
          dispatch(setEmailProvider(data.emailProvider))
        }
        if (data.extra?.DKIM) {
          dispatch(setDKIM(data.extra.DKIM.address))
        }
      },
    }),
    getDNSRecords: builder.query<DomainDNS, { id: string }>({
      query: ({ id }) => ({
        method: "GET",
        url: `domain/${id}/dnsRecords`,
      }),
      transformResponse: ({ data }: { data: DomainDNS }) => data,
      providesTags: ["DNS Records"],
    }),
    addDomain: builder.mutation<
      Domain,
      { orgId: string; name: string; provider: string; urlRedirect: string }
    >({
      query: (body) => ({
        method: "POST",
        url: "domain",
        body,
      }),
      invalidatesTags: ["Domains"],
      transformResponse: ({ data }: { data: Domain }) => data,
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setDomainId(data._id))
          }
        } catch (e) {
          console.error(e)
        }
      },
    }),
    setupDomain: builder.mutation<
      Domain,
      { id: string; body: { emailProvider: string } }
    >({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `domain/${id}/setup`,
        body,
      }),
      invalidatesTags: ["Domains"],
      transformResponse: ({ data }: { data: Domain }) => data,
    }),
    addExtraDomain: builder.mutation<
      Domain,
      { body: { DKIM: string }; id: string }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `domain/${id}/extra`,
        body,
      }),
      transformResponse: ({ data }: { data: Domain }) => data,
    }),
    verifyDomain: builder.mutation<
      DomainVerifyResponse,
      { id: string; type: "ownership" | "dnsRecords" }
    >({
      query: ({ id, type }) => ({
        method: "PUT",
        url: `domain/${id}/verify?${buildQueryString({ type })}`,
      }),
      invalidatesTags: ["Domains"],
      transformResponse: ({ data }: { data: DomainVerifyResponse }) => data,
    }),
    deleteDomain: builder.mutation<{ data: boolean }, { id: string }>({
      query: ({ id }) => ({
        method: "DELETE",
        url: `domain/${id}`,
      }),
      invalidatesTags: ["Domains"],
    }),
    getEmailDomains: builder.query<
      { items: Domain[]; total: number },
      { id: string }
    >({
      query: ({ id }) => ({
        method: "GET",
        url: `org/${id}/domains/available`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: Domain[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: ["Email Domains"],
    }),
  }),
})

export const {
  useGetDomainsQuery,
  useAddDomainMutation,
  useGetDomainQuery,
  useGetDNSRecordsQuery,
  useSetupDomainMutation,
  useAddExtraDomainMutation,
  useVerifyDomainMutation,
  useDeleteDomainMutation,
  useGetEmailDomainsQuery,
} = domainsApi
