import { FC, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import cx from "classnames"
import EmptyList from "@/components/EmptyList"
import AddOrganizationModal from "../AddOrganizationModal"
import EditOrganizationModal from "../EditOrganizationModal"
import AddIcon from "@/components/icons/AddIcon"
import OrganizationCard from "../OrganizationCard"
import { useGetOrganizationsQuery } from "@/redux/services/organizationApi"
import { useAuth } from "@/hooks/useAuth"
import SetOrganizationStatusModal from "../SetOrganizationStatusModal"
import { alphabetDateSortBy } from "../../utils"
import DeleteOrganizationModal from "../DeleteOrganizationModal"
import ErrorMessage from "@/components/ErrorMessage"
import Button from "@/components/Button"
import Loader from "@/components/Loader"

type OrganizationModal = null | "edit" | "delete" | "set status"

const OrganizationsList: FC = () => {
  const [searchParams] = useSearchParams()
  const isActive = searchParams.get("isActive")
    ? searchParams.get("isActive") === "true"
    : "true"
  const navigate = useNavigate()
  const { data: orgs = [], isLoading, isError } = useGetOrganizationsQuery()
  const { user } = useAuth()
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    null | string
  >(null)
  const [openedModal, setOpenedModal] = useState<OrganizationModal>(null)

  const emptyMessage = isActive
    ? "There is no active organization."
    : "There is no inactive organization."
  const [openAddModal, setOpenAddModal] = useState(false)
  const [deletedId, setDeletedId] = useState("")

  const handleOpenAddModal = () => setOpenAddModal(true)
  const closeAddModal = () => setOpenAddModal(false)

  const orgsFiltered = useMemo(() => {
    if (!orgs.length) {
      return []
    }
    const searchedOrgsTerm = searchParams.get("term")
    let resultOrgs = isActive
      ? orgs.filter(({ status }) => status === "enabled")
      : orgs.filter(({ status }) => status === "disabled")
    if (searchedOrgsTerm) {
      resultOrgs = resultOrgs.filter(({ name }) =>
        name.trim().toLowerCase().includes(searchedOrgsTerm.toLowerCase()),
      )
    }
    const sortBy = searchParams.get("sortBy") ?? "Newest"
    if (sortBy) {
      resultOrgs = alphabetDateSortBy(sortBy, resultOrgs)
    }
    return resultOrgs
  }, [isActive, orgs, searchParams])

  const openOrganizationModal = (id: string) => {
    setSelectedOrganizationId(id)
  }

  const selectedOrganization = orgsFiltered.find(
    ({ _id }) => _id === selectedOrganizationId,
  )

  const closeModal = () => {
    setSelectedOrganizationId(null)
    setOpenedModal(null)
  }

  const onOrganizationModalOpen = (
    id: string,
    modalType: OrganizationModal,
  ) => {
    return () => {
      openOrganizationModal(id)
      setOpenedModal(modalType)
    }
  }

  const editModalOpened =
    selectedOrganizationId && selectedOrganization && openedModal === "edit"
  const setStatusModalOpened =
    selectedOrganization &&
    selectedOrganizationId &&
    openedModal === "set status"

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <div
      className={cx("grid gap-5 mt-4 flex-1", {
        "grid-cols-1": !orgsFiltered.length,
        "sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4": orgsFiltered.length,
        "place-items-center min-h-[300px]": !orgs.length,
      })}
    >
      {isLoading ? (
        <Loader />
      ) : orgsFiltered.length ? (
        <>
          {orgsFiltered.map(({ name, status, _id }) => (
            <OrganizationCard
              key={_id}
              name={name}
              enabled={status === "enabled"}
              footerName={`${user?.firstName} ${user?.lastName}`}
              onEdit={onOrganizationModalOpen(_id, "edit")}
              onDelete={() => setDeletedId(_id)}
              onChangeStatus={onOrganizationModalOpen(_id, "set status")}
              onClick={() =>
                navigate(`/organization/${_id}/dashboard?indicators=all`)
              }
            />
          ))}
        </>
      ) : (
        <EmptyList message={emptyMessage} className="mt-[88px]">
          {isActive && (
            <Button
              onClick={handleOpenAddModal}
              className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
            >
              <AddIcon className="transition-colors text-primary group-hover:text-white" />
              <span className="transition-colors font-medium text-primary group-hover:text-white">
                Add New Organization
              </span>
            </Button>
          )}
        </EmptyList>
      )}
      <AddOrganizationModal open={openAddModal} onClose={closeAddModal} />
      {editModalOpened && (
        <EditOrganizationModal
          organizationId={selectedOrganizationId}
          initialName={selectedOrganization.name}
          initialUrl={selectedOrganization.url}
          initialCatchAll={selectedOrganization.catchAll}
          open={!!selectedOrganizationId}
          onClose={closeModal}
        />
      )}
      {setStatusModalOpened && (
        <SetOrganizationStatusModal
          currentStatus={selectedOrganization.status}
          organizationId={selectedOrganizationId}
          initialName={selectedOrganization.name}
          open={!!selectedOrganizationId}
          onClose={closeModal}
        />
      )}
      <DeleteOrganizationModal
        data={orgs.find(({ _id }) => _id === deletedId)}
        open={!!deletedId}
        onClose={() => setDeletedId("")}
      />
    </div>
  )
}

export default OrganizationsList
