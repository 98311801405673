type Props = {
  touched?: boolean
  error?: string | null
}

const FormFieldErrorMessage = ({ error, touched }: Props) => {
  return (
    <>
      {touched && error ? (
        <div className="text-error text-sm py-2">{error}</div>
      ) : null}
    </>
  )
}

export default FormFieldErrorMessage
