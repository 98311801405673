import type { Organization } from "@/types/entities/organization"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "./store"

export type OrganizationState = {
  selectedOrganization: Organization | null
}

const initialState: OrganizationState = {
  selectedOrganization: null,
}

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setSelectedOrganization: (
      state,
      { payload }: PayloadAction<Organization | null>,
    ) => {
      state.selectedOrganization = payload
    },
  },
})

export const selectSelectedOrganization = (state: RootState) =>
  state.organization.selectedOrganization

export const { setSelectedOrganization } = organizationSlice.actions

export default organizationSlice.reducer
