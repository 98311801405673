import { Link, NavLink, useLocation } from "react-router-dom"
import cx from "classnames"
import LogoIcon from "@/components/icons/LogoIcon"
import { Routes } from "@/core/routing/routes"
import UserInfo from "@/components/UserInfo"
import { useAuth } from "@/hooks/useAuth"
import Menu from "@/components/Menu"
import ErrorMessage from "@/components/ErrorMessage"
import { useWindowSize } from "usehooks-ts"

type Props = {
  children: React.ReactNode
}

const HeaderMain = ({ children }: Props) => {
  const { isAdmin, isError } = useAuth()
  const location = useLocation()
  const isAdminRoute = location.pathname.includes("/admin/")
  const { width } = useWindowSize()
  const smallScreen = width < 350
  return (
    <div
      id="main"
      className="h-screen w-screen overflow-x-hidden bg-white text-black flex flex-col"
    >
      {isError ? (
        <ErrorMessage />
      ) : (
        <>
          <div className="h-[60px] py-3.5 border-b-outline border-b bg-white flex justify-between items-center pl-3 pr-1 md:px-6">
            <div className="flex gap-3 md:gap-9">
              <LogoIcon
                showName={width >= 768}
                className="h-8 w-auto md:w-[130px]"
              />
              {isAdmin && (
                <div className="flex gap-2 md:gap-9 items-center">
                  <NavLink
                    to={Routes.organizations}
                    className={({ isActive }) =>
                      cx("text-xs md:text-sm leading-6 font-medium", {
                        "text-primary font-semibold": isActive,
                      })
                    }
                  >
                    {smallScreen ? "Org..." : "Organization"}
                  </NavLink>
                  <Menu
                    items={[
                      <Link to={Routes.admin.users}>
                        <span>Users</span>
                      </Link>,
                      <Link to={Routes.admin.spam}>
                        <span>Spam Test Accounts</span>
                      </Link>,
                      <Link to={Routes.admin.gsuite}>
                        <span>Admin Accounts</span>
                      </Link>,
                      <Link to={Routes.admin.aiPrompts}>
                        <span>Reply Classification</span>
                      </Link>,
                      <Link to={Routes.admin.dnc}>
                        <span>Global DNC</span>
                      </Link>,
                      <Link to={Routes.admin.cookies}>
                        <span>Cookies</span>
                      </Link>,
                      <Link to={Routes.admin.proxy}>
                        <span>Proxy</span>
                      </Link>,
                    ]}
                    className="text-xs md:text-sm leading-6"
                  >
                    <span
                      className={cx("font-medium", {
                        "text-primary font-semibold": isAdminRoute,
                      })}
                    >
                      {smallScreen ? "Set..." : "Settings"}
                    </span>
                  </Menu>
                </div>
              )}
            </div>
            <UserInfo className="gap-1.5" />
          </div>
          <main className="flex-1 p-3 md:p-6">{children}</main>
        </>
      )}
    </div>
  )
}

export default HeaderMain
