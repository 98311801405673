import { FC } from "react"
import { Link, useParams } from "react-router-dom"
import cx from "classnames"
import AddIcon from "@/components/icons/AddIcon"
import SearchIcon from "@/assets/search.svg"
import { usePageTitle } from "@/hooks/usePageTitle"
import TextField from "@/components/TextField"
import { usePagination } from "@/hooks/usePagination"
import Button from "@/components/Button"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import { useGetSearchCriteriasQuery } from "@/redux/services/searchCriteriaApi"
import EmptyList from "@/components/EmptyList"
import SearchCriteriaTable from "./features/SearchCriteriaTable"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import { POLLING_INTERVAL } from "@/constants"
import { useClientRect } from "@/hooks/useClientRect"
import { useUserAccess } from "@/hooks/useUserAccess"

type Props = {
  className?: string
}

const SearchCriteriaPage: FC<Props> = ({ className, ...rest }: Props) => {
  usePageTitle("Search Criteria")
  const { id: orgId = "" } = useParams()
  const [rect, ref] = useClientRect(true)
  const {
    searchTerm,
    skip,
    limit,
    text,
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
  } = usePagination()
  const { pageFullAccess: targetingUpdateFullAccess } = useUserAccess({
    pageName: "Targeting Update",
  })
  const { isLoading: systemValuesLoading } = useGetSystemValuesQuery()
  const {
    isError,
    isLoading: criteriasLoading,
    isFetching,
    data: { items: criterias, total } = { items: [], total: 0 },
  } = useGetSearchCriteriasQuery(
    {
      id: orgId,
      limit,
      skip,
      text,
    },
    { pollingInterval: POLLING_INTERVAL, refetchOnMountOrArgChange: true },
  )

  if (isError) {
    return <ErrorMessage />
  }
  const isLoading = systemValuesLoading || criteriasLoading

  return (
    <div className={cx("h-full flex flex-col", className)} {...rest}>
      <div className="flex gap-1 justify-between">
        <TextField
          startIcon={<SearchIcon />}
          placeholder="Search for search criteria"
          rootClassName="basis-56"
          className="input-sm !h-9"
          value={searchTerm}
          onChange={handleSearchTermChange}
        />
        {targetingUpdateFullAccess && (
          <Link to="add">
            <Button className="btn-base">
              <AddIcon className="w-6 h-6" />
              <span className="hidden sm:inline">Add Search Criteria</span>
            </Button>
          </Link>
        )}
      </div>
      <div
        className={cx(
          "flex-1 mt-5 flex bg-white rounded-md block-shadow px-2 py-1 min-h-[380px]",
          { "justify-center items-center": !criterias.length },
        )}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {criterias.length ? (
              <div ref={ref} className="w-full">
                <SearchCriteriaTable
                  total={total}
                  page={skip}
                  pageSize={limit}
                  criterias={criterias}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  maxHeight={rect?.height ?? 0}
                  loading={isFetching}
                />
              </div>
            ) : (
              <EmptyList message="There are no search criterias yet">
                {targetingUpdateFullAccess && (
                  <Link to="add">
                    <Button className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary">
                      <AddIcon className="transition-colors text-primary group-hover:text-white" />
                      <span className="transition-colors font-medium text-primary group-hover:text-white">
                        Add Search Criteria
                      </span>
                    </Button>
                  </Link>
                )}
              </EmptyList>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default SearchCriteriaPage
