import { FC, useEffect } from "react"
import Navbar from "@/components/Navbar"
import UserInfo from "@/components/UserInfo"
import ErrorMessage from "@/components/ErrorMessage"
import Drawer from "@/components/Drawer"
import LogoIcon from "@/components/icons/LogoIcon"
import IconButton from "@/components/IconButton"
import BurgerIcon from "@/components/icons/BurgerIcon"
import ViewOnlyBadge from "@/components/ViewOnlyBadge"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import { useMobileNavbar } from "./useMobileNavbar"
import { selectPageTitle, setPageTitle } from "@/redux/appSlice"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import { useAuth } from "@/hooks/useAuth"
import { useStatusOrganizations } from "@/hooks/useStatusOrganizations"

type Props = {
  children: React.ReactNode
}

const HeaderLeftNavbarMain: FC<Props> = ({ children }: Props) => {
  const pageTitle = useAppSelector(selectPageTitle)
  const dispatch = useAppDispatch()
  const [openMobileNavbar, setOpenMobileNavbar] = useMobileNavbar()
  const { currentOrgActive, isLoading } = useStatusOrganizations()
  const { isError: valuesError } = useGetSystemValuesQuery()
  const { isError: userError } = useAuth()
  useEffect(() => {
    return () => {
      dispatch(setPageTitle(""))
    }
  }, [dispatch])
  const isError = valuesError || userError

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className="flex h-screen md:h-auto">
        <Navbar />
        <div className="flex flex-col flex-1 max-w-full md:max-w-[calc(100%-11rem)] lg:max-w-[calc(100%-16rem)]">
          <header className="w-full bg-white z-20 border-b border-b-outline h-[60px] p-2 md:px-6 md:py-3.5 flex justify-between items-center">
            <div className="flex gap-1.5 sm:gap-3 items-center">
              <div className="inline-block md:hidden">
                <IconButton onClick={() => setOpenMobileNavbar(true)}>
                  <BurgerIcon />
                </IconButton>
              </div>
              <h3 className="text-base sm:text-lg font-semibold">
                {typeof pageTitle === "string" ? pageTitle : pageTitle()}
              </h3>
              {!currentOrgActive && !isLoading && pageTitle && (
                <ViewOnlyBadge />
              )}
            </div>
            <UserInfo />
          </header>
          <main className="px-2 py-4 md:p-6 flex-1 bg-bg-default text-black max-h-[calc(100vh-60px)] overflow-y-auto overflow-x-hidden">
            {children}
          </main>
        </div>
      </div>
      <Drawer
        title={<LogoIcon className="h-9 w-[146px] -translate-x-2.5" />}
        placement="left"
        open={openMobileNavbar}
        onClose={() => setOpenMobileNavbar(false)}
        className="[&_.ant-drawer-body]:overflow-y-hidden"
      >
        <Navbar
          showLogo={false}
          className="flex border-r-0 p-0 max-h-full"
          onLinkChange={() => setOpenMobileNavbar(false)}
        />
      </Drawer>
    </>
  )
}

export default HeaderLeftNavbarMain
