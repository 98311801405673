import { AiPrompt } from "@/types/entities/ai-prompt"

export const QUERY_INDEX_REGEX = /^#\d+: /

export const getCategoryStringFormatted = (
  category: string,
  prompt: AiPrompt,
  i: number,
  classifications: {
    id: string
    display: string
  }[],
) => {
  const categoryNumberMatch = category.match(/\d/)
  const contentInsideParenthesesMatch = category.match(/\(([^)]+)\)/)
  let categoryIndex = 0,
    categoryName = ""

  if (categoryNumberMatch) {
    categoryIndex = Number(categoryNumberMatch[0])
  }
  if (contentInsideParenthesesMatch) {
    categoryName = contentInsideParenthesesMatch[1]
  }

  return `#${categoryIndex}: ${categoryName} - ${
    prompt.actions.find(({ category }) => categoryIndex === category)
      ? prompt.actions
          .find(({ category }) => categoryIndex === category)
          ?.values.map(
            (v) => classifications.find(({ id }) => id === v)?.display,
          )
          .join(", ")
      : "No action"
  }`
}
