import { FC, useState } from "react"
import Button from "@/components/Button"
import FileInput from "@/components/FileInput"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import ApiCallLoader from "@/components/ApiCallLoader"
import { useUserImportDNC } from "../../hooks/useUserImportDNC"

type Props = ModalProps & {}

const ImportDNCModal: FC<Props> = ({ open, onClose }: Props) => {
  const [files, setFiles] = useState<FileList | null>(null)
  const { importUserDNC, response } = useUserImportDNC()
  const handleSubmit = async () => {
    const file = files!.item(0)
    if (!file) {
      return
    }
    await importUserDNC(file)
    handleClose()
  }
  const handleClose = () => {
    setFiles(null)
    onClose && onClose()
  }

  return (
    <>
      <Modal
        actions={
          <div className="flex gap-4">
            <OutlineButton
              disabled={response.isLoading}
              onClick={handleClose}
              className="text-black btn-sm !w-[86px]"
              type="button"
            >
              Cancel
            </OutlineButton>
            <Button
              disabled={!files || response.isLoading}
              className="btn-sm !w-[86px]"
              type="submit"
            >
              Import
            </Button>
          </div>
        }
        title="Import DNC Contacts"
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
      >
        <div className="text-sm font-normal leading-6">
          <p>
            You can import by uploading an Excel file with the data. In case
            your file contains header fields, they will be automatically
            detected. Otherwise, you can specify them manually.{" "}
            <span className="font-medium underline text-primary">
              File Example
            </span>
          </p>
          <p className="mt-3.5">Following fields are available for import:</p>
        </div>
        <ul className="mt-3 flex flex-wrap gap-y-1 [&_li]:basis-1/3 [&_li]:leading-6 [&_li]:text-sm list-disc list-inside marker:text-[#26313f66] ">
          <li>
            <span className="-ml-1">EmailAddress</span>
          </li>
        </ul>
        <FileInput
          onFileSelect={(v) => setFiles(v)}
          className="mt-4"
          accept=".xlsx, .xls, .csv"
        />
        <div className="caption font-normal mt-2">
          Supported files .XLS, .XLSX, .CSV; Maximum size 10mb
        </div>
      </Modal>
      <ApiCallLoader open={response.isLoading}>
        <span className="opacity-80 text-xs leading-normal font-medium">
          Adding contact
        </span>
      </ApiCallLoader>
    </>
  )
}

export default ImportDNCModal
