import { useEditOrganizationMutation } from "@/redux/services/organizationApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import type { AddOrganizationPayload } from "@/types/entities/organization"

export const useEditOrganization = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch()
  const [editOrganization, response] = useEditOrganizationMutation()

  const handleEditOrganization = async ({
    id,
    name,
    url,
    catchAll,
  }: { id: string } & AddOrganizationPayload) => {
    try {
      await editOrganization({
        id,
        body: {
          name,
          url,
          catchAll,
        },
      }).unwrap()
      typeof onSuccess === "function" && onSuccess()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success edit organization",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { editOrganization: handleEditOrganization, response }
}
