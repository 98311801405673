import { FC } from "react"
import cx from "classnames"
import { useParams } from "react-router-dom"
import Modal, { ModalProps } from "@/components/Modal"
import DeleteButton from "@/components/Button/DeleteButton"
import { useUserDeleteDNC } from "../../hooks/useUserDeleteDNC"
import CancelButton from "@/components/OutlineButton/CancelButton"
import type { AdminDNC } from "@/types/entities/dnc"

type Props = {
  className?: string
  data?: AdminDNC
  onDelete?: () => void
} & ModalProps

const DeleteDNCModal: FC<Props> = ({
  className,
  open,
  data,
  onClose,
  onDelete,
  ...rest
}: Props) => {
  const { id = "" } = useParams()
  const {
    deleteUserDNC,
    response: { isLoading },
  } = useUserDeleteDNC()
  if (!data) {
    return null
  }
  const handleDelete = async () => {
    try {
      const response = await deleteUserDNC({
        id,
        records: [data._id],
      })
      if (response) {
        onDelete && onDelete()
      }
    } catch (e) {
      console.error(e)
    } finally {
      onClose && onClose()
    }
  }
  const title = "Delete Account"
  const usersText = `“${data.value}”`
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      className={cx("", className)}
      onSubmit={handleDelete}
      actions={
        <div className="flex gap-4">
          <CancelButton onClick={onClose} disabled={isLoading} />
          <DeleteButton disabled={isLoading} />
        </div>
      }
      {...rest}
    >
      <span className="leading-6 text-base break-all">
        Are you sure you want to delete{" "}
        <span className="font-semibold">{usersText}</span>?
      </span>
    </Modal>
  )
}

export default DeleteDNCModal
