import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import {
  type DeleteProspectsPayload,
  useDeleteProspectsMutation,
} from "@/redux/services/prospectsApi"
import { setToast } from "@/redux/toastSlice"
import { useParams } from "react-router-dom"

export const useDeleteProspects = () => {
  const dispatch = useAppDispatch()
  const [deleteProspects, response] = useDeleteProspectsMutation()
  const { id: orgId = "" } = useParams()

  const handleDeleteProspects = async (params: DeleteProspectsPayload) => {
    try {
      const response = await deleteProspects({
        orgId,
        params,
      }).unwrap()
      if ((response as any).data) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success delete",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteProspects: handleDeleteProspects, response }
}
