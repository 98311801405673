import { AppThunk, RootState } from "@/redux/store"
import { User } from "@/types/entities/user"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type AuthState = {
  user?: User | null
}

const initialState: AuthState = {
  user: undefined,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null | undefined>) => {
      state.user = payload
    },
  },
})

export const selectUser = (state: RootState) => state.auth.user

export const { setUser } = authSlice.actions

export const loginUserAction =
  (user: User): AppThunk =>
  (dispatch) => {
    dispatch(setUser(user))
    localStorage.setItem("token", user.token)
  }
export const logoutUserAction = (): AppThunk => (dispatch) => {
  dispatch(setUser(null))
  localStorage.removeItem("token")
}

export default authSlice.reducer
