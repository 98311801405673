import { FC, FormEvent, useEffect } from "react"
import Modal, { type ModalProps } from "../Modal"
import OutlineButton from "../OutlineButton"
import Button from "../Button"

type Props = {
  onConfirm: () => void
  canBeActivated?: boolean
} & ModalProps

const DiscardModal: FC<Props> = ({
  open,
  onClose,
  onConfirm,
  canBeActivated = true,
}: Props) => {
  const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    onConfirm()
  }
  useEffect(() => {
    if (open && !canBeActivated) {
      onConfirm()
      onClose && onClose()
    }
  }, [canBeActivated, onClose, onConfirm, open])
  if (open && !canBeActivated) {
    return null
  }
  return (
    <Modal
      title="Discard Changes"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={onClose}
            className="text-black btn-sm w-[86px]"
            type="button"
          >
            Cancel
          </OutlineButton>
          <Button className="btn-sm min-w-[86px]" type="submit">
            Confirm
          </Button>
        </div>
      }
    >
      <span className="text-base leading-6 font-normal">
        All data that you have changed will be deleted, are you sure you want to
        move to another page?
      </span>
    </Modal>
  )
}

export default DiscardModal
