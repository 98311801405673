import { FC } from "react"
import cx from "classnames"
import { MdCancel } from "react-icons/md"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const CancelIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <MdCancel className={cx("w-5 h-5 active:scale-90", className)} {...rest} />
  )
}

export default CancelIcon
