import { FC } from "react"
import cx from "classnames"
import { Radio as AntRadio, RadioProps } from "antd"

type Props = {
  className?: string
} & RadioProps

const Radio: FC<Props> = ({ className, children, ...rest }: Props) => {
  return (
    <AntRadio className={cx("", className)} {...rest}>
      {children}
    </AntRadio>
  )
}

export default Radio
