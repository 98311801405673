import { createSelector } from "reselect"
import {
  PayloadAction,
  createSlice,
  createSelector as createSelectorToolkit,
} from "@reduxjs/toolkit"
import { removeUndefinedProperties } from "@/utils/objects"
import type { RootState } from "./store"
import type {
  AddSearchCriteriaPayload,
  FilterType,
  GeographyType,
  SearchCriteriaFilter,
  SelectionType,
} from "@/types/entities/search-criteria"

export type UIFilters = Record<
  FilterType,
  {
    strict?: boolean
    selections: Record<SelectionType, string[]>
    subSelection?: {
      id: string
      value: string | null
    }
  }
>

export type SearchCriteriaSetupState = Omit<
  AddSearchCriteriaPayload,
  "orgId" | "filters" | "keywords"
> & {
  queriesStats?: {
    completed: number
    total: number
    processing?: number
  }
  keywords: string[]
  filters: UIFilters
  geographyType: "REGION" | "POSTAL_CODE"
  initialState: Omit<SearchCriteriaSetupState, "initialState">
}

export const getInitialFilters = (
  filters: SearchCriteriaFilter[],
): UIFilters => {
  return filters.reduce((acc, { type, selectionTypes, subSelection }) => {
    acc[type] = Object.assign(
      {
        selections: selectionTypes.reduce((selectionsAcc, selectionType) => {
          selectionsAcc[selectionType] = []
          return selectionsAcc
        }, {} as Record<SelectionType, string[]>),
      },
      subSelection
        ? {
            subSelection: {
              id: "",
              value: null,
            },
          }
        : undefined,
    )
    return acc
  }, {} as UIFilters)
}

const initialState: SearchCriteriaSetupState = {
  name: "",
  description: "",
  masterList: "",
  status: "draft",
  type: "auto",
  target: undefined,
  queriesStats: undefined,
  geographyType: "REGION",
  keywords: [],
  domains: [],
  filters: {} as UIFilters,
  initialState: {} as SearchCriteriaSetupState,
}

export const getCriteriaSetupInitialState = () => {
  const { initialState: init, ...rest } = { ...initialState }
  return {
    ...rest,
  }
}

const searchCriteriaSetupSlice = createSlice({
  name: "searchCriteriaSetupSlice",
  initialState,
  reducers: {
    setCriteriaName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload
    },
    setCriteriaDescription: (state, { payload }: PayloadAction<string>) => {
      state.description = payload
    },
    setCriteriaMasterListId: (state, { payload }: PayloadAction<string>) => {
      state.masterList = payload
    },
    setCriteriaStatus: (state, { payload }: PayloadAction<string>) => {
      state.status = payload
    },
    setCriteriaType: (state, { payload }: PayloadAction<string>) => {
      state.type = payload
    },
    setCriteriaTarget: (
      state,
      { payload }: PayloadAction<number | undefined>,
    ) => {
      state.target = payload
    },
    setCriteriaKeywords: (state, { payload }: PayloadAction<string[]>) => {
      state.keywords = payload
    },
    setCriteriaDomains: (state, { payload }: PayloadAction<string[]>) => {
      state.domains = payload
    },
    setCriteriaFilters: (
      state,
      { payload }: PayloadAction<SearchCriteriaSetupState["filters"]>,
    ) => {
      state.filters = payload
    },
    setCriteriaFilterSelectionValue: (
      state,
      {
        payload: { type, value: newValues, selectionType },
      }: PayloadAction<{
        type: FilterType
        value: string[]
        selectionType: SelectionType
      }>,
    ) => {
      const newSelectionsMap = state.filters[type]?.selections
      const { subSelection, strict } = state.filters[type]
      newSelectionsMap[selectionType] = newValues
      state.filters[type] = Object.assign(
        { selections: newSelectionsMap },
        removeUndefinedProperties({
          subSelection,
          strict,
        }),
      )
    },
    setCriteriaFilterSubSelectionValue: (
      state,
      {
        payload: { type, value },
      }: PayloadAction<{
        type: FilterType
        value: {
          id: string
          value: string | null
        }
      }>,
    ) => {
      const typeFilter = state.filters[type]
      const newSelectionsMap = {
        ...typeFilter,
      }
      newSelectionsMap.subSelection = value
      if (newSelectionsMap) {
        state.filters[type] = newSelectionsMap
      }
    },
    setCriteriaFilterStrictValue: (
      state,
      {
        payload: { type, checked },
      }: PayloadAction<{
        type: FilterType
        checked: boolean
      }>,
    ) => {
      const typeFilter = state.filters[type]
      const newSelectionsMap = {
        ...typeFilter,
        strict: true,
      }
      newSelectionsMap.strict = checked
      if (newSelectionsMap) {
        state.filters[type] = newSelectionsMap
      }
    },
    setSetupCriteria: (
      _,
      { payload }: PayloadAction<SearchCriteriaSetupState>,
    ) => payload,
    setQueriesStats: (
      state,
      {
        payload,
      }: PayloadAction<{
        completed: number
        total: number
        processing?: number
      }>,
    ) => {
      state.queriesStats = payload
    },
    setGeographyType: (state, { payload }: PayloadAction<GeographyType>) => {
      state.geographyType = payload
    },
    setInitialState: (
      state,
      {
        payload,
      }: PayloadAction<Omit<SearchCriteriaSetupState, "initialState">>,
    ) => {
      state.initialState = payload
    },
    setDuplicateFields: (
      state,
      {
        payload: { name },
      }: PayloadAction<{
        name: string
      }>,
    ) => {
      state.name = `${name} Copy`
      state.masterList = ""
      state.description = ""
      state.status = "draft"
      state.type = "auto"
      state.target = undefined
      state.queriesStats = undefined
    },
    resetCriteria: () => initialState,
  },
})

export const selectCriteriaName = (state: RootState) =>
  state.searchCriteriaSetup.name
export const selectCriteriaDescription = (state: RootState) =>
  state.searchCriteriaSetup.description
export const selectCriteriaMasterListId = (state: RootState) =>
  state.searchCriteriaSetup.masterList
export const selectCriteriaStatus = (state: RootState) =>
  state.searchCriteriaSetup.status
export const selectCriteriaType = (state: RootState) =>
  state.searchCriteriaSetup.type
export const selectCriteriaTarget = (state: RootState) =>
  state.searchCriteriaSetup.target
export const selectCriteriaKeywords = (state: RootState) =>
  state.searchCriteriaSetup.keywords
export const selectCriteriaFilters = (state: RootState) =>
  state.searchCriteriaSetup.filters
export const selectCriteriaDomains = (state: RootState) =>
  state.searchCriteriaSetup.domains
export const selectCriteriaQueriesStats = (state: RootState) =>
  state.searchCriteriaSetup.queriesStats
export const selectCriteriaGeographyType = (state: RootState) =>
  state.searchCriteriaSetup.geographyType
export const selectCriteriaFilter =
  (filterType: FilterType) => (state: RootState) => {
    const filtersMap = state.searchCriteriaSetup.filters
    return filtersMap[filterType]
  }
export const selectCriteriaFilledFilters = createSelector(
  [selectCriteriaFilters, selectCriteriaGeographyType],
  (filtersMap, geographyType) => {
    const filledFilters = Object.entries(filtersMap)
      .filter(([type, { selections }]) => {
        const selectionEntries = Object.values(selections)
        const filledValues =
          selectionEntries.reduce((acc, curr) => acc + curr.length, 0) > 0
        if (type === "REGION" || type === "POSTAL_CODE") {
          return geographyType === type && filledValues
        }
        return filledValues
      })
      .map(([type, { selections, subSelection, strict }]) => {
        const selectionEntries = Object.entries(selections)
        const selectionsFilled = selectionEntries
          .filter(([, values]) => values.length)
          .map(([type, values]) => ({
            type,
            values,
          }))
        let baseFilledFilters = {
          type,
          selections: selectionsFilled,
        }
        if (type === "POSTAL_CODE" && subSelection?.value) {
          baseFilledFilters = Object.assign(baseFilledFilters, {
            subSelection,
          })
        }
        if (strict !== undefined) {
          baseFilledFilters = Object.assign(baseFilledFilters, {
            strict,
          })
        }
        return baseFilledFilters
      })
    return filledFilters
  },
)
export const selectCriteriaInitialState = (state: RootState) =>
  state.searchCriteriaSetup.initialState
export const selectCriteriaSetupState = createSelectorToolkit(
  (state: RootState) => state.searchCriteriaSetup,
  ({ initialState, ...rest }) => ({ ...rest }),
)

export const {
  resetCriteria,
  setCriteriaName,
  setCriteriaDescription,
  setCriteriaMasterListId,
  setCriteriaStatus,
  setCriteriaType,
  setCriteriaTarget,
  setCriteriaKeywords,
  setCriteriaFilters,
  setCriteriaFilterSelectionValue,
  setCriteriaFilterSubSelectionValue,
  setCriteriaFilterStrictValue,
  setSetupCriteria,
  setQueriesStats,
  setCriteriaDomains,
  setGeographyType,
  setInitialState,
  setDuplicateFields,
} = searchCriteriaSetupSlice.actions

export default searchCriteriaSetupSlice.reducer
