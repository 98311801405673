import { useEffect, useState } from "react"

export const useMobileNavbar = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [openMobileNavbar, setOpenMobileNavbar] = useState(false)

  useEffect(() => {
    const handleResize = (e: UIEvent) => {
      if ((e.target as Window).outerWidth >= 768) {
        setOpenMobileNavbar(false)
      }
    }

    window.onresize = handleResize

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return [openMobileNavbar, setOpenMobileNavbar]
}
