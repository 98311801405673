import { FC, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import cx from "classnames"
import EmptyList from "@/components/EmptyList"
import AddIcon from "@/components/icons/AddIcon"
import OrganizationCard from "../../OrganizationCard"
import ErrorMessage from "@/components/ErrorMessage"
import Button from "@/components/Button"
import AdminAddOrganizationModal from "../AdminAddOrganizationModal"
import AdminEditOrganizationModal from "../AdminEditOrganizationModal"
import AdminSetOrganizationStatusModal from "../AdminSetOrganizationStatusModal"
import AdminDeleteOrganizationModal from "../AdminDeleteOrganizationModal"
import Loader from "@/components/Loader"
import { alphabetDateSortBy } from "../../../utils"
import { useAdminOrganizationsInfiniteScroll } from "./useAdminOrganizationsInfiniteScroll"
import type { AdminOrganization } from "@/types/entities/organization"

type OrganizationModal = null | "edit" | "delete" | "set status"

const AdminOrganizationsList: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isActive = searchParams.get("isActive")
    ? searchParams.get("isActive") === "true"
    : "true"
  const owner = searchParams.get("owner")
  const {
    paginatedOrgs: orgs,
    isLoading,
    isFetching,
    isError,
    updateOrganization,
    updateOrganizationStatus,
    deleteOrganization,
    sentryRef,
  } = useAdminOrganizationsInfiniteScroll()
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    null | string
  >(null)
  const [openedModal, setOpenedModal] = useState<OrganizationModal>(null)

  const emptyMessage = isActive
    ? `There is no active organization${owner ? " for this owner" : ""}.`
    : `There is no inactive organization${owner ? " for this owner" : ""}.`
  const [openAddModal, setOpenAddModal] = useState(false)
  const [deletedId, setDeletedId] = useState("")

  const handleOpenAddModal = () => setOpenAddModal(true)
  const closeAddModal = () => setOpenAddModal(false)

  const handleUpdateOrganization = (body: AdminOrganization) =>
    updateOrganization({ body, id: body._id })
  const handleUpdateOrganizationStatus = (payload: AdminOrganization) =>
    updateOrganizationStatus({ body: payload.status, id: payload._id })

  const handleCardClick = (id: string) => () => {
    navigate(`/organization/${id}/dashboard?indicators=all`)
  }

  const orgsFiltered = useMemo<AdminOrganization[]>(() => {
    if (!orgs.length) {
      return []
    }
    const searchedOrgsTerm = searchParams.get("term")
    let resultOrgs = []
    resultOrgs = isActive
      ? orgs.filter(({ status }) => status === "enabled")
      : orgs.filter(({ status }) => status === "disabled")
    if (searchedOrgsTerm) {
      resultOrgs = resultOrgs.filter(({ name }) =>
        name.trim().toLowerCase().includes(searchedOrgsTerm.toLowerCase()),
      )
    }
    const sortBy = searchParams.get("sortBy") ?? "Newest"
    if (sortBy) {
      resultOrgs = alphabetDateSortBy(sortBy, resultOrgs) as AdminOrganization[]
    }
    if (owner) {
      return resultOrgs.filter(({ owner: { _id } }) => {
        return _id === owner
      })
    }
    return resultOrgs
  }, [isActive, orgs, searchParams, owner])

  const openOrganizationModal = (id: string) => {
    setSelectedOrganizationId(id)
  }

  const selectedOrganization = orgsFiltered.find(
    ({ _id }) => _id === selectedOrganizationId,
  )

  const closeModal = () => {
    setSelectedOrganizationId(null)
    setOpenedModal(null)
  }

  const onOrganizationModalOpen = (
    id: string,
    modalType: OrganizationModal,
  ) => {
    return () => {
      openOrganizationModal(id)
      setOpenedModal(modalType)
    }
  }

  const editModalOpened =
    selectedOrganizationId && selectedOrganization && openedModal === "edit"
  const setStatusModalOpened =
    selectedOrganization &&
    selectedOrganizationId &&
    openedModal === "set status"

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div
        className={cx("grid gap-5 mt-4 flex-1", {
          "grid-cols-1": !orgsFiltered.length,
          "sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4": orgsFiltered.length,
          "place-items-center min-h-[300px]": !orgs.length,
        })}
      >
        {isLoading ? (
          <Loader />
        ) : orgsFiltered.length ? (
          <>
            {orgsFiltered.map(({ name, status, _id, owner }) => (
              <OrganizationCard
                key={_id}
                name={name}
                enabled={status === "enabled"}
                footerName={`${owner?.firstName} ${owner?.lastName}`}
                onEdit={onOrganizationModalOpen(_id, "edit")}
                onDelete={() => setDeletedId(_id)}
                onChangeStatus={onOrganizationModalOpen(_id, "set status")}
                onClick={handleCardClick(_id)}
              />
            ))}
          </>
        ) : (
          <EmptyList message={emptyMessage} className="mt-[88px]">
            {isActive && (
              <Button
                onClick={handleOpenAddModal}
                className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
              >
                <AddIcon className="transition-colors text-primary group-hover:text-white" />
                <span className="transition-colors font-medium text-primary group-hover:text-white">
                  Add New Organization
                </span>
              </Button>
            )}
          </EmptyList>
        )}
      </div>
      {isFetching && !isLoading && <Loader className="mx-auto mt-4" />}
      <div ref={sentryRef} className="mb-20"></div>
      <AdminAddOrganizationModal open={openAddModal} onClose={closeAddModal} />
      {editModalOpened && (
        <AdminEditOrganizationModal
          data={orgs.find(({ _id }) => _id === selectedOrganizationId)}
          open={!!selectedOrganizationId}
          onClose={closeModal}
          onEditSuccess={handleUpdateOrganization}
        />
      )}
      {setStatusModalOpened && (
        <AdminSetOrganizationStatusModal
          currentStatus={selectedOrganization.status}
          organizationId={selectedOrganizationId}
          initialName={selectedOrganization.name}
          open={!!selectedOrganizationId}
          onClose={closeModal}
          onUpdateStatusSuccess={handleUpdateOrganizationStatus}
        />
      )}
      <AdminDeleteOrganizationModal
        data={orgs.find(({ _id }) => _id === deletedId)}
        open={!!deletedId}
        onClose={() => setDeletedId("")}
        onDeleteSuccess={deleteOrganization}
      />
    </>
  )
}

export default AdminOrganizationsList
