import { FC } from "react"
import cx from "classnames"
import Image1 from "../img/step3-1.png"
import Image2 from "../img/step3-2.png"
import Image3 from "../img/step3-3.png"

type Props = {
  className?: string
}

const InstructionStepThree: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <div className={cx("", className)} {...rest}>
      <ol className="list-decimal list-inside space-y-2">
        <li>
          Go to{" "}
          <a
            className="underline"
            target="_blank"
            href="https://myaccount.google.com/u/1/security"
          >
            https://myaccount.google.com/u/1/security
          </a>{" "}
          and click on <b>"Two Step verification"</b>.
        </li>
        <img src={Image1} alt="step3-1" />
        <li>
          Click <b>GET STARTED</b>
        </li>
        <li>It will prompt you to login again.</li>
        <li>Set up your phone for Two Factor authentication</li>
        <img src={Image2} alt="step3-2" />
        <li>Enter the code you’ve received to your phone number to confirm.</li>
        <li>Once you’ve confirmed click Turn On</li>
        <img src={Image3} alt="step3-3" />
      </ol>
      <div className="mt-2">2-Factor Verification has been enabled now.</div>
    </div>
  )
}

export default InstructionStepThree
