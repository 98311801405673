import { useEditMasterListImportCSVMutation } from "@/redux/services/masterListsApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import type { ImportCSVPayload } from "@/redux/services/masterListsApi"
import { useParams } from "react-router-dom"

export const useEditMasterListImportCSV = () => {
  const dispatch = useAppDispatch()
  const [editMasterListCSV, response] = useEditMasterListImportCSVMutation()
  const { masterListId = "" } = useParams()

  const handleEditMasterListCSV = async (
    payload: Pick<ImportCSVPayload, "csv">,
  ) => {
    try {
      const formData = new FormData()
      formData.append("csv", payload.csv)
      await editMasterListCSV({ body: formData, id: masterListId }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success edit master lists",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { editMasterListCVS: handleEditMasterListCSV, response }
}
