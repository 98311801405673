import { FC, useMemo, useState } from "react"
import cx from "classnames"
import Tooltip from "@/components/Tooltip"
import BarChart from "@/components/BarChart"
import ChartStatCard from "../ChartStatCard"
import DashboardIndicatorsList from "../DashboardIndicatorsList"
import { useDashboard } from "../../hooks/useDashboard"
import { getChartColorByKey } from "@/components/BarChart/constants"
import { setOpacityToHexColor } from "@/utils/strings"
import {
  extractValues,
  getDateStringFromObject,
  sortDashboardDateStrings,
} from "../../utils"
import { INDICATOR_VALUES } from "../IndicatorDropdown/constants"
import { generateLabels } from "../../hooks/utils"
import { convertDateMonthYearStringToMonthDateYear } from "@/utils/dates"
import { getPercentage } from "@/utils/numbers"
import type { ChartData, ScriptableContext } from "chart.js"
import type { ChartProps } from "react-chartjs-2"
import type {
  ReportingData,
  ReportingEmailTotals,
  ReportingRepliesTotals,
} from "@/types/entities/organization"

type Props = {
  className?: string
  indicators: string[]
  emails: ReportingData[]
  totalData: ReportingEmailTotals
  totalReplyData: ReportingRepliesTotals
  showIndicators?: boolean
}

const CampaignsChart: FC<Props> = ({
  className,
  indicators,
  emails,
  totalData,
  totalReplyData,
  showIndicators = true,
  ...rest
}: Props) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1)
  const { selectedIndicators, selectedClassifications, from, to, grouping } =
    useDashboard()
  const dataset = useMemo<ChartData<"bar">>(() => {
    const labels = generateLabels(from, to, grouping)
    const indicatorFilters = Object.keys(selectedIndicators)
    const classificationFilters = Object.keys(selectedClassifications)
    const initClassifications: Record<string, number> = classificationFilters
      .map((c) => c.toLowerCase())
      .reduce(
        (acc, curr) =>
          Object.assign(acc, {
            [curr]: 0,
          }),
        {},
      )

    const sortedEmails = labels
      .map((label) => {
        const dataForLabel = emails.find(({ _id }) => {
          const dateString = getDateStringFromObject(_id)
          return dateString === label
        })
        return dataForLabel
          ? {
              date: getDateStringFromObject(dataForLabel._id),
              reply: dataForLabel.reply ?? 0,
              lead: dataForLabel.lead ?? 0,
              ...initClassifications,
              // classifications come here
              ...dataForLabel,
              _id: dataForLabel._id,
            }
          : Object.assign(
              {
                _id: null,
                date: label,
                reply: 0,
                lead: 0,
                followup: 0,
                new: 0,
                total: 0,
              },
              initClassifications,
            )
      })
      .sort(({ date: aDate }, { date: bDate }) =>
        sortDashboardDateStrings(bDate, aDate),
      )
    const datasets = extractValues(
      sortedEmails.map(
        ({
          followup,
          lead,
          new: prospects,
          reply,
          total,
          date,
          _id,
          ...rest
        }) => ({
          followup,
          lead,
          new: prospects,
          reply,
          total,
          ...rest,
        }),
      ),
    )

    return {
      labels: labels.map((v) =>
        grouping === "day" ? convertDateMonthYearStringToMonthDateYear(v) : v,
      ),
      datasets: Object.entries(datasets)
        .filter(([key]) => {
          let resultFilters = indicatorFilters
          resultFilters = resultFilters.concat(
            classificationFilters.length > 0 ? classificationFilters : [],
          )

          return resultFilters.length > 0
            ? Boolean(
                resultFilters.find(
                  (selectedFilter) =>
                    selectedFilter.toLowerCase() === key.toLowerCase(),
                ),
              )
            : false
        })
        .map(([key, values]) => {
          const color = getChartColorByKey(key)
          const lessOpacityColor = setOpacityToHexColor(color)

          const getBackgroundColor = ({
            dataIndex,
          }: ScriptableContext<"bar">) =>
            hoveredIndex === dataIndex ? lessOpacityColor : color
          return {
            label:
              INDICATOR_VALUES.find(({ value }) => value === key)?.label ??
              classificationFilters.find(
                (v) => v.toLowerCase() === key.toLowerCase(),
              ) ??
              key,
            data: values,
            barPercentage: values.length > 3 ? 0.9 : 0.95,
            categoryPercentage: values.length > 3 ? 0.925 : 0.2,
            maxBarThickness: 45,
            backgroundColor: getBackgroundColor,
            hoverBackgroundColor: getBackgroundColor,
          }
        }),
    }
  }, [
    emails,
    selectedIndicators,
    selectedClassifications,
    from,
    to,
    grouping,
    hoveredIndex,
  ])
  const options = useMemo<ChartProps<"bar">["options"]>(
    () => ({
      layout: {
        padding: {
          left: 16,
          right: 16,
        },
      },
      onHover: (_, elements) =>
        setHoveredIndex(elements.length > 0 ? elements[0].index : -1),
    }),
    [],
  )
  const totalValues = useMemo(() => {
    return {
      followup: totalData?.followup ?? 0,
      new: totalData?.new ?? 0,
      total: totalData?.total ?? 0,
      replies: totalReplyData?.reply ?? 0,
      leads: totalReplyData?.lead ?? 0,
    }
  }, [totalData, totalReplyData])

  return (
    <div
      className={cx(
        "rounded-md bg-white shadow-[0px_1px_4px_0px_rgba(0,0,0,0.08)]",
        className,
      )}
      onMouseLeave={() => setHoveredIndex(-1)}
      {...rest}
    >
      {showIndicators && (
        <DashboardIndicatorsList
          totalData={totalData}
          totalReplyData={totalReplyData}
          indicators={indicators}
        />
      )}
      <div className="py-4 space-y-5">
        <div className="px-4 flex gap-4 flex-wrap [&>*]:flex-1">
          <ChartStatCard title="PROSPECTS" bodyContent={totalValues.new} />
          <ChartStatCard
            title="FOLLOW UPS"
            bodyContent={totalValues.followup}
          />
          <ChartStatCard title="TOTAL EMAILS" bodyContent={totalValues.total} />
          <ChartStatCard
            title="REPLIES"
            bodyContent={
              <Tooltip
                placement="bottom"
                title={
                  <span className="text-sm">
                    {getPercentage(totalValues.replies, totalValues.new)}% of
                    prospects
                  </span>
                }
              >
                {totalValues.replies}
              </Tooltip>
            }
          />
          <ChartStatCard
            title="INTERESTED LEADS"
            className="bg-bg-default"
            bodyContent={
              <Tooltip
                placement="bottom"
                title={
                  <div className="flex flex-col items-center text-sm">
                    <span>
                      {getPercentage(totalValues.leads, totalValues.new)}% of
                      prospects
                    </span>
                    <span>
                      {getPercentage(totalValues.leads, totalValues.replies)}%
                      of replies
                    </span>
                  </div>
                }
              >
                <span className="text-primary">{totalValues.leads}</span>
              </Tooltip>
            }
          />
        </div>
        <div className="w-full">
          <BarChart height={307} data={dataset} options={options} />
        </div>
      </div>
    </div>
  )
}

export default CampaignsChart
