import { FC } from "react"
import cx from "classnames"
import { BiPlus } from "react-icons/bi"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const AddIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <BiPlus className={cx("w-5 h-5 active:scale-90", className)} {...rest} />
  )
}

export default AddIcon
