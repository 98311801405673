import {
  useUpdateUserMutation,
  useDeleteProfilePictureMutation,
} from "@/core/api/services"
import { useAppDispatch } from "@/redux/hooks"
import type { UpdateUserShape } from "@/types/entities/user"
import { getErrorMessage } from "@/core/api/helpers"
import { setToast } from "@/redux/toastSlice"

export const useUpdateUser = () => {
  const dispatch = useAppDispatch()
  const [updateUser, response] = useUpdateUserMutation()
  const [deleteUserProfile] = useDeleteProfilePictureMutation()

  const handleUserUpdate = async ({
    email,
    firstName,
    lastName,
    profileImage,
    removePendingEmail,
  }: UpdateUserShape) => {
    try {
      const formData = new FormData()
      if (email) {
        formData.append("email", email)
      }
      if (firstName) {
        formData.append("firstName", firstName)
      }
      if (lastName) {
        formData.append("lastName", lastName)
      }
      if (profileImage) {
        formData.append("profileImage", profileImage)
      } else if (profileImage === null) {
        await deleteUserProfile().unwrap()
      }
      if (removePendingEmail) {
        formData.append("removePendingEmail", "true")
      }
      await updateUser(formData).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success edit user settings",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { updateUser: handleUserUpdate, response }
}
