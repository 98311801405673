export const addAndEditArrayById = <T>({
  currentArray,
  newItems,
}: {
  currentArray: Array<T & { _id: string }>
  newItems: Array<T & { _id: string }>
}): T[] => {
  const newArray = [...currentArray]
  newItems?.forEach((newItem) => {
    // Find the index of the item with the same _id in the current array
    const index = newArray.findIndex((item) => item._id === newItem._id)

    if (index === -1) {
      newArray.push(newItem)
    } else {
      newArray[index] = newItem
    }
  })
  return newArray
}

type TreeNode = {
  value: string
  title: string
  children?: TreeNode[]
}
export const getLengthOfTree = (indicators: TreeNode[]): number => {
  let length = 0

  const countNodes = (node: TreeNode): void => {
    length++

    if (node.children) {
      node.children.forEach(countNodes)
    }
  }

  indicators.forEach(countNodes)

  return length
}
export const extractLabelValuePairs = (
  nodes: TreeNode[],
): { title: string; value: string }[] => {
  const result: { title: string; value: string }[] = []

  const extractPairs = (node: TreeNode): void => {
    const { title, value } = node
    result.push({ title, value })

    if (node.children) {
      node.children.forEach(extractPairs)
    }
  }

  nodes.forEach(extractPairs)

  return result
}
type NestedObject = {
  _id: string
  name: string
  sub?: NestedObject[]
}

export function findValueByName(
  array: NestedObject[],
  targetId: string,
): NestedObject | undefined {
  for (const root of array) {
    const result = findValueByNameRecursive(root, targetId)
    if (result) {
      return result
    }
  }

  return undefined
}

function findValueByNameRecursive(
  root: NestedObject,
  targetId: string,
): NestedObject | undefined {
  if (root.name === targetId) {
    return root
  }

  if (root.sub) {
    for (const nestedObj of root.sub) {
      const result = findValueByNameRecursive(nestedObj, targetId)
      if (result) {
        return result
      }
    }
  }

  return undefined
}
