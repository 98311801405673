import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "./store"

export type AppState = {
  pageTitle: string | (() => React.ReactNode)
}

const initialState: AppState = {
  pageTitle: "",
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setPageTitle: (
      state,
      { payload }: PayloadAction<string | (() => React.ReactNode)>,
    ) => {
      state.pageTitle = payload
    },
  },
})

export const selectPageTitle = (state: RootState) => state.app.pageTitle

export const { setPageTitle } = appSlice.actions

export default appSlice.reducer
