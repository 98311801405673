import React from "react"
import LogoHeader from "../LogoHeader"

type Props = {
  children: React.ReactNode
}

const HeaderOnlyLayout = ({ children }: Props) => {
  return (
    <div className="h-screen w-screen overflow-x-hidden bg-white text-black flex flex-col">
      <LogoHeader />
      <main className="flex-1 px-2">{children}</main>
      <footer className="text-xs font-normal opacity-60 text-center pb-5 px-2">
        Copyright © Receptive Marketing Inc. 2023. All Rights Reserved.
      </footer>
    </div>
  )
}

export default HeaderOnlyLayout
