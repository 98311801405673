import { FC } from "react"
import cx from "classnames"
import { useFormik } from "formik"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import validationSchema from "./validationSchema"
import { useEditEmailAddress } from "../../hooks/useEditEmailAddress"
import type { EmailAddress } from "@/types/entities/email-address"

type Props = {
  className?: string
  data?: EmailAddress
} & ModalProps

const EditEmailModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const {
    editEmailAddress,
    response: { isLoading },
  } = useEditEmailAddress()
  const { dirty, isValid, getFieldProps, resetForm, handleSubmit } = useFormik({
    initialValues: {
      dailyLimit: data?.dailyLimit ?? 25,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ dailyLimit }) => {
      try {
        if (!data) {
          handleClose()
          return
        }
        await editEmailAddress({
          body: {
            dailyLimit,
            emailId: data._id,
          },
          id: data.sender._id,
        })
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  if (!data) {
    return null
  }
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  const submitDisabled = isLoading || !(dirty && isValid)

  return (
    <>
      <Modal
        title="Edit Email Address"
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        className={cx("z-[1000]", className)}
        actions={
          <div className="flex gap-4">
            <OutlineButton
              type="button"
              className="btn-sm text-black min-w-[86px]"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </OutlineButton>
            <Button
              type="submit"
              disabled={submitDisabled}
              className="btn-sm min-w-[70px]"
            >
              Save
            </Button>
          </div>
        }
        {...rest}
      >
        <div className="flex gap-5 flex-col [&_.label-text]:pl-0 [&_.label]:pl-0 [&_.label]:pt-0 [&_.label-text]:text-xs [&_.label-text]:leading-normal">
          <TextField
            type="number"
            topLeftLabel="Daily New Volume"
            className="input-sm rounded-md"
            {...getFieldProps("dailyLimit")}
          />
        </div>
      </Modal>
    </>
  )
}

export default EditEmailModal
