import { useAddMasterListCSVMutation } from "@/redux/services/masterListsApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import type { ImportCSVPayload } from "@/redux/services/masterListsApi"

export const useAddMasterListCSV = () => {
  const dispatch = useAppDispatch()
  const [addMasterListCSV, response] = useAddMasterListCSVMutation()

  const handleAddMasterListCSV = async (payload: ImportCSVPayload) => {
    try {
      const formData = new FormData()
      formData.append("orgId", payload.orgId)
      formData.append("name", payload.name)
      formData.append("csv", payload.csv)
      await addMasterListCSV({ body: formData }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success add master lists",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { addMasterListCVS: handleAddMasterListCSV, response }
}
