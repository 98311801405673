import * as yup from "yup"

yup.addMethod(yup.string, "testSpintax", function () {
  return this.test("testSpintax", "Field can't have { or }", (v) => {
    if (!v) {
      return false
    }
    if (v.includes("{") || v.includes("}")) {
      return false
    }
    return true
  })
})

declare module "yup" {
  interface StringSchema {
    testSpintax(): this
  }
}
