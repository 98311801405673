import { FC, useMemo } from "react"
import cx from "classnames"
import Checkbox from "@/components/Checkbox"
import { useGetCriteriaFiltersQuery } from "@/redux/services/searchCriteriaApi"
import { useAppSelector } from "@/redux/hooks"
import { selectCriteriaFilter } from "@/redux/searchCriteriaSetupSlice"
import { useCriteriaSetup } from "@/pages/AddEditSearchCriteria/hooks/useCriteriaSetup"
import { useActiveFilterContext } from "@/pages/AddEditSearchCriteria/hooks/useActiveFilterContext"
import type { FilterType } from "@/types/entities/search-criteria"

type Props = {
  className?: string
}

const CriteriaSpotlightsFilter: FC<Props> = ({ className, ...rest }: Props) => {
  const FILTER_TYPE = useActiveFilterContext() as FilterType
  const { selections: spotlightSelections } = useAppSelector(
    selectCriteriaFilter(FILTER_TYPE),
  )!
  const { onSingleSelectionChange } = useCriteriaSetup()
  const { data: filters = [] } = useGetCriteriaFiltersQuery()
  const spotlightFilterValues = useMemo(
    () => filters.find(({ type }) => type === FILTER_TYPE)?.values ?? [],
    [FILTER_TYPE, filters],
  )

  return (
    <div
      className={cx("space-y-3 [&>div]:flex [&>div]:items-center", className)}
      {...rest}
    >
      {spotlightFilterValues.map(({ id, description }) => (
        <div key={id}>
          <Checkbox
            checked={
              spotlightSelections.INCLUDED.findIndex((v) => v === id) !== -1
            }
            value={id}
            onChange={onSingleSelectionChange("INCLUDED", FILTER_TYPE)}
            id={id}
            className="checkbox-primary w-[18px] h-[18px]"
          />
          <label
            className="cursor-pointer pl-2.5 leading-6 text-sm"
            htmlFor={id}
          >
            {description}
          </label>
        </div>
      ))}
    </div>
  )
}

export default CriteriaSpotlightsFilter
