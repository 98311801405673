import {
  MdOutlineGridView,
  MdOutlineCampaign,
  MdOutlineAssignmentInd,
  MdOutlineLanguage,
  MdOutlineAlternateEmail,
  MdOutlineDynamicFeed,
  MdOutlineDescription,
  MdOutlineGroup,
  MdOutlineManageSearch,
} from "react-icons/md"
import UnsubscribeIcon from "@/assets/unsubscribe.svg"
import InboxIcon from "@/assets/inbox.svg"
import { Routes } from "@/core/routing/routes"

export const DASHBOARD_PARAM = "?indicators=all"

export const LINKS = [
  {
    title: "Dashboard",
    Icon: MdOutlineGridView,
    to: Routes.organization.dashboard + DASHBOARD_PARAM,
  },
  {
    title: "Inbox",
    Icon: InboxIcon,
    to: Routes.organization.inbox,
  },
  {
    title: "Prospects",
    Icon: MdOutlineGroup,
    to: Routes.organization.prospects,
  },
  {
    title: "Campaigns",
    Icon: MdOutlineCampaign,
    to: Routes.organization.campaigns,
  },
  {
    title: "Sequences",
    Icon: MdOutlineDynamicFeed,
    to: Routes.organization.sequences,
  },
  {
    title: "Search Criteria",
    Icon: MdOutlineManageSearch,
    to: Routes.organization.searchCriteria,
  },
  {
    title: "Master Lists",
    Icon: MdOutlineDescription,
    to: Routes.organization.masterLists,
  },
  {
    title: "Senders",
    Icon: MdOutlineAssignmentInd,
    to: Routes.organization.senders,
  },
  {
    title: "Email Addresses",
    Icon: MdOutlineAlternateEmail,
    to: Routes.organization.emails,
  },
  {
    title: "Domains",
    Icon: MdOutlineLanguage,
    to: Routes.organization.domains,
  },
  { title: "DNC List", Icon: UnsubscribeIcon, to: Routes.organization.dnc },
]
