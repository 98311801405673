import { FC } from "react"
import cx from "classnames"
import { useNavigate, useParams } from "react-router-dom"
import { usePageTitle } from "@/hooks/usePageTitle"
import { usePagination } from "@/hooks/usePagination"
import TextField from "@/components/TextField"
import SearchIcon from "@/assets/search.svg"
import Button from "@/components/Button"
import AddIcon from "@/components/icons/AddIcon"
import EmptyList from "@/components/EmptyList"
import { useGetDomainsQuery } from "@/redux/services/domainsApi"
import DomainsTable from "./features/DomainsTable"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import { useClientRect } from "@/hooks/useClientRect"
import { useUserAccess } from "@/hooks/useUserAccess"

type Props = {
  className?: string
}

const DomainsPage: FC<Props> = ({ className, ...rest }: Props) => {
  usePageTitle("Domains")
  const { pageFullAccess: domainsUpdateFullAccess } = useUserAccess({
    pageName: "Domains Update",
  })
  const navigate = useNavigate()
  const [rect, ref] = useClientRect(true)
  const { id: orgId = "" } = useParams()
  const {
    searchTerm,
    handleSearchTermChange,
    handlePageChange,
    handlePageSizeChange,
    limit,
    skip,
    text,
  } = usePagination()
  const {
    data: { items: domains, total } = { items: [], total: 0 },
    isLoading,
    isFetching,
    isError,
  } = useGetDomainsQuery(
    { orgId, limit, skip, text },
    { skip: !orgId, refetchOnMountOrArgChange: true },
  )

  const goToAddEditPage = () => navigate("create", { relative: "path" })

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <div className={cx("h-full flex flex-col", className)} {...rest}>
      <div className="flex justify-between items-center gap-1">
        <TextField
          value={searchTerm}
          onChange={handleSearchTermChange}
          placeholder="Search domain"
          startIcon={<SearchIcon className="w-6 h-6" />}
          className="text-sm outline outline-outline !h-9 !py-1.5"
          rootClassName="basis-56"
        />
        {domainsUpdateFullAccess && (
          <Button
            onClick={goToAddEditPage}
            className="btn-primary btn-sm !h-9 !py-1.5"
          >
            <AddIcon />
            <span className="hidden sm:inline">Add Domain</span>
          </Button>
        )}
      </div>
      <div
        className={cx(
          "flex-1 overflow-y-auto mt-5 flex bg-white rounded-md shadow-[0px_1px_4px_0px_rgba(0,0,0,0.06)] px-2 py-1 min-h-[380px]",
          { "justify-center items-center": !domains.length },
        )}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {domains.length ? (
              <div ref={ref} className="w-full">
                <DomainsTable
                  domains={domains}
                  page={skip}
                  pageSize={limit}
                  total={total}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  maxHeight={rect?.height ?? 0}
                  loading={isFetching}
                />
              </div>
            ) : (
              <EmptyList message="There are no domains yet">
                {domainsUpdateFullAccess && (
                  <Button
                    onClick={goToAddEditPage}
                    className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                  >
                    <AddIcon className="transition-colors text-primary group-hover:text-white" />
                    <span className="transition-colors font-medium text-primary group-hover:text-white">
                      Add Domain
                    </span>
                  </Button>
                )}
              </EmptyList>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DomainsPage
