import { email } from "@/core/validation"
import { object, string, array } from "yup"

export default object({
  sender: string().required(),
  emails: array().min(1).required(),
  destEmails: array()
    .of(email)
    .min(1, "At least one string value is required in the array"),
})
