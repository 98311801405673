import { FC, useId, useRef, useState } from "react"
import cx from "classnames"
import OutlineButton from "../OutlineButton"

type Props = {
  className?: string
  onFileSelect?: (files: FileList | null) => void
} & React.InputHTMLAttributes<HTMLInputElement>

const FileInput: FC<Props> = ({ className, accept, onFileSelect }: Props) => {
  const id = useId()
  const ref = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<FileList>()
  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = target.files
    if (!selectedFiles?.length) {
      return null
    }
    setFiles(selectedFiles)
    onFileSelect && onFileSelect(selectedFiles)
  }
  return (
    <div
      className={cx(
        "p-1 rounded-md bg-input flex gap-1.5 items-center",
        className,
      )}
    >
      <label htmlFor={id}>
        <OutlineButton
          onClick={() => ref.current?.click()}
          className="btn-xs tiny-shadow !pr-2 bg-white btn-primary !h-[34px]"
        >
          Select File
        </OutlineButton>
      </label>
      <input
        onChange={handleChange}
        ref={ref}
        id={id}
        type="file"
        className="w-0 h-0 invisible"
        accept={accept}
      />
      {files && files.item(0) && (
        <span className="text-sm leading-6">{files.item(0)!.name}</span>
      )}
    </div>
  )
}

export default FileInput
