import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import { exportFileResponseHandler } from "@/utils/api"
import { buildQueryString } from "@/utils/strings"
import type { Prospect, ProspectEmail } from "@/types/entities/prospect"

type ProspectParams = {
  orgId: string
  skip?: number
  limit?: number
  from?: string | null
  to?: string | null
  campaign?: string[]
  classifications?: string[]
  text?: string
  sort?: string
  sortOrder?: string
}

export type DeleteProspectsPayload = { id: string[] }

export const prospectsApi = createApi({
  reducerPath: "prospectsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Prospects", "Prospect Emails"],
  endpoints: (builder) => ({
    getProspects: builder.query<
      { items: Prospect[]; total: number },
      ProspectParams
    >({
      query: ({ orgId, ...keys }) => ({
        method: "GET",
        url: `org/${orgId}/prospects?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: Prospect[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Prospects" as const,
                _id,
              })),
              { type: "Prospects", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Prospects", id: "PARTIAL-LIST" }],
    }),
    exportProspects: builder.mutation<
      unknown,
      { body: Omit<ProspectParams, "skip" | "limit"> }
    >({
      query: ({ body }) => ({
        url: `prospects/export`,
        method: "POST",
        responseHandler: async (response) =>
          await exportFileResponseHandler(response, "Prospects.csv"),
        cache: "no-cache",
        body,
      }),
    }),
    deleteProspects: builder.mutation<
      { success: boolean },
      { orgId: string; params: DeleteProspectsPayload }
    >({
      query: ({ params, orgId }) => ({
        url: `prospects/${orgId}?${buildQueryString(params)}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Prospects"],
    }),
    getProspectEmails: builder.query<ProspectEmail[], { id: string }>({
      query: ({ id }) => ({
        url: `prospects/${id}/emails`,
        method: "GET",
      }),
      transformResponse: (response: { data: ProspectEmail[] }) => response.data,
      providesTags: (_, err, { id }) => [{ type: "Prospect Emails", id }],
    }),
  }),
})

export const {
  useGetProspectsQuery,
  useExportProspectsMutation,
  useDeleteProspectsMutation,
  useGetProspectEmailsQuery,
} = prospectsApi
