import { FC } from "react"
import Modal from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import type { ModalProps } from "@/components/Modal"
import type { ForwardingGroup } from "@/types/entities/campaign"

type Props = ModalProps & {
  data?: ForwardingGroup
  onDeleteSuccess: (data: ForwardingGroup) => void
}

const DeleteForwardingModal: FC<Props> = ({
  open,
  onClose,
  data,
  onDeleteSuccess,
}: Props) => {
  if (!data) {
    return null
  }
  const handleDelete = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    onDeleteSuccess(data)
    onClose && onClose()
  }
  return (
    <Modal
      actions={
        <div className="flex gap-4">
          <CancelButton onClick={onClose} />
          <DeleteButton tabIndex={0} />
        </div>
      }
      title="Delete Forwarding Group"
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
    >
      <span className="text-base">
        Are you sure you want to delete “
        <span className="font-semibold">{data.name}</span>
        ”?
      </span>
    </Modal>
  )
}

export default DeleteForwardingModal
