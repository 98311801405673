import { useContext } from "react"
import { DomainsContext } from "../context"

export const useDomainsContext = () => {
  const ctx = useContext(DomainsContext)
  if (!ctx) {
    throw new Error("useDomainsContext must be used within a DomainsContext")
  }
  return ctx
}
