export const removeUndefinedProperties = <T extends object>(obj: T) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined))

export const removeEmptyStrings = <T extends Record<string, any>>(v: T): T => {
  return Object.entries(v).reduce((acc, [key, value]) => {
    if (value === "") {
      return acc
    }
    acc[key as keyof T] = value
    return acc
  }, {} as T)
}
