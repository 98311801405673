import { FC } from "react"
import CampaignChartTable from "../CampaignChartTable"
import CampaignsChart from "../CampaignsChart"
import type {
  DashboardReporting,
  ReportingData,
} from "@/types/entities/organization"

type Props = {
  className?: string
  label: string
  indicators: string[]
  reporting: DashboardReporting
}

const SingleGraphView: FC<Props> = ({
  label,
  indicators,
  reporting,
}: Props) => {
  const resultEmails = reporting.emails.data
    .concat(
      reporting.replies.data.map(({ _id }) => ({
        _id,
      })) as any,
    )
    .filter(
      (item, i, arr) =>
        i ===
        arr.findIndex(
          (t) => JSON.stringify(item._id) === JSON.stringify(t._id),
        ),
    )
    .map((email) => {
      let resultData: ReportingData = {
        ...email,
      }
      const correspondingReply = reporting.replies.data.find(
        (reply) => JSON.stringify(reply._id) === JSON.stringify(email._id),
      )
      if (correspondingReply) {
        resultData = Object.assign(resultData, {
          ...correspondingReply,
        })
      }
      return resultData
    })
  return (
    <div className="space-y-4">
      <h2 className="text-base font-semibold">{label}</h2>
      <CampaignsChart
        emails={resultEmails}
        totalData={reporting.emails.totals[0]}
        totalReplyData={reporting.replies.totals[0]}
        indicators={indicators}
      />
      <CampaignChartTable emails={resultEmails} />
    </div>
  )
}

export default SingleGraphView
