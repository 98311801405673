import { useAddOrganizationMutation } from "@/redux/services/organizationApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import type { AddOrganizationPayload } from "@/types/entities/organization"

export const useAddOrganization = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch()
  const [addOrganization, response] = useAddOrganizationMutation()

  const handleAddOrganization = async (payload: AddOrganizationPayload) => {
    try {
      await addOrganization(payload).unwrap()
      typeof onSuccess === "function" && onSuccess()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success add organization",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { addOrganization: handleAddOrganization, response }
}
