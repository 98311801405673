import { FC, useMemo, useState } from "react"
import cx from "classnames"
import Table from "@/components/Table"
import DeleteDNCModal from "../DeleteDNCModal"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import AddEditDNCModal from "../AddEditDNCModal"
import { DATE_FORMAT_OPTIONS } from "@/constants"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import type { AdminDNC } from "@/types/entities/dnc"
import type { TablePaginationProps } from "@/redux/services/types"
import type { ColumnsType } from "antd/es/table"

type Props = {
  className?: string
  dncs: AdminDNC[]
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  value: string
  reason: string
  updatedAt: string
}

const AdminDNCTable: FC<Props> = ({
  className,
  dncs,
  onRowSelect,
  selectedRowKeys,
  ...rest
}: Props) => {
  const { sm } = useBreakpoints()
  const [editedUserId, setEditedUserId] = useState("")
  const [deletedUserId, setDeletedUserId] = useState("")
  const dataSource = useMemo<TableDataType[]>(
    () =>
      dncs.map(({ _id, value, reason, createdAt }) => ({
        key: _id,
        value,
        reason,
        updatedAt: createdAt
          ? new Date(createdAt).toLocaleDateString("en-US", DATE_FORMAT_OPTIONS)
          : "",
      })),
    [dncs],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(
    () => [
      {
        title: "Value",
        key: "value",
        dataIndex: "value",
        sorter: (a, b) => a.value.localeCompare(b.value),
      },
      {
        title: "Reason",
        key: "reason",
        dataIndex: "reason",
        sorter: (a, b) => a.reason.localeCompare(b.reason),
        render: (v: string) => (v.length ? v[0].toUpperCase() + v.slice(1) : v),
      },
      {
        title: "Last Edited",
        key: "updatedAt",
        dataIndex: "updatedAt",
        sorter: (a, b) => +a.updatedAt - +b.updatedAt,
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        className: "min-w-[96px]",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            <EditIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setEditedUserId(key)
              }}
              className="w-6 h-6 opacity-60 active:scale-90 cursor-pointer hover:text-primary transition-colors"
            />
            <BinIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setDeletedUserId(key)
              }}
              className="w-6 h-6 opacity-60 active:scale-90 cursor-pointer hover:text-primary transition-colors"
            />
          </div>
        ),
        width: 96,
      },
    ],
    [sm],
  )
  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        rootClassName="w-full"
        className={cx("", className)}
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys) =>
            onRowSelect && onRowSelect(selectedRowKeys),
          selectedRowKeys,
        }}
        showSizeChanger
        {...rest}
      ></Table>
      <AddEditDNCModal
        data={dncs.find(({ _id }) => _id === editedUserId)}
        open={!!editedUserId}
        onClose={() => setEditedUserId("")}
      />
      <DeleteDNCModal
        open={!!deletedUserId}
        data={[dncs.find(({ _id }) => _id === deletedUserId)!].filter(Boolean)}
        onClose={() => setDeletedUserId("")}
      />
    </>
  )
}

export default AdminDNCTable
