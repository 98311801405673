import { object, string } from "yup"

export default object({
  firstName: string()
    .required("Required")
    .min(2, "Must be 2 characters or more")
    .max(100, "Must be 100 characters or less"),
  lastName: string()
    .required("Required")
    .min(2, "Must be 2 characters or more")
    .max(100, "Must be 100 characters or less"),
  email: string().required("Required").email("Invalid email address"),
})
