import { FC, useMemo, useState } from "react"
import cx from "classnames"
import SearchIcon from "@/assets/search.svg"
import AddIcon from "@/components/icons/AddIcon"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { usePagination } from "@/hooks/usePagination"
import TextField from "@/components/TextField"
import Button from "@/components/Button"
import EmptyList from "@/components/EmptyList"
import AdminProxyTable from "./features/AdminProxyTable"
import AddEditProxyModal from "./features/AddEditProxyModal"
import { useGetAllAdminProxyQuery } from "@/redux/services/adminProxyApi"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import DeleteProxyModal from "./features/DeleteProxyModal"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import type { Key } from "antd/es/table/interface"

type Props = {
  className?: string
}

const AdminProxyPage: FC<Props> = ({ className, ...rest }: Props) => {
  const {
    searchTerm,
    handleSearchTermChange,
    handlePageChange,
    handlePageSizeChange,
    limit,
    skip,
  } = usePagination()
  const { isLoading: valuesLoading } = useGetSystemValuesQuery()
  const {
    data: { items: proxies, total } = { items: [], total: 0 },
    isLoading: proxiesLoading,
    isError,
    isFetching,
  } = useGetAllAdminProxyQuery({ limit, skip })
  const [openAddModal, setOpenAddModal] = useState(false)
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([])
  const [openDeleteProxyModal, setOpenDeleteProxyModal] = useState(false)
  const handleClearSelection = () => setSelectedRowsKeys([])
  const handleOpenAdd = () => setOpenAddModal(true)
  const filteredProxies = useMemo(
    () =>
      searchTerm
        ? proxies.filter(({ ip }) =>
            ip
              .toLocaleLowerCase()
              .includes(searchTerm.trim().toLocaleLowerCase()),
          )
        : proxies,
    [searchTerm, proxies],
  )
  if (isError) {
    return <ErrorMessage />
  }
  const isLoading = valuesLoading || proxiesLoading
  return (
    <>
      <div className={cx("", className)} {...rest}>
        <div className="flex justify-between items-center flex-wrap sm:flex-nowrap gap-1">
          <h2 className="leading-8 text-2xl font-semibold">Proxy</h2>
          <div className="flex gap-4 items-center">
            {selectedRowsKeys.length ? (
              <div onClick={() => setOpenDeleteProxyModal(true)}>
                <BinIcon className="mr-2 active:scale-90 w-6 h-6 cursor-pointer opacity-60 hover:text-primary hover:opacity-100" />
              </div>
            ) : null}
            <TextField
              value={searchTerm}
              onChange={handleSearchTermChange}
              placeholder="Search proxy"
              startIcon={<SearchIcon className="w-6 h-6" />}
              className="text-sm outline outline-outline input-sm"
              rootClassName="basis-56"
              type="search"
            />
            <Button onClick={handleOpenAdd} className="btn-primary btn-sm">
              <AddIcon className="w-6 h-6" />
              <span className="hidden sm:inline">Add Proxy</span>
            </Button>
          </div>
        </div>
        <div
          className={cx("mt-5 flex px-2 py-1 min-h-[292px]", {
            "justify-center items-center": !proxies.length,
          })}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {proxies.length ? (
                <div className="w-full">
                  <AdminProxyTable
                    proxies={filteredProxies}
                    page={skip}
                    total={total}
                    pageSize={limit}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    selectedRowKeys={selectedRowsKeys}
                    onRowSelect={setSelectedRowsKeys}
                    loading={isFetching}
                  />
                </div>
              ) : (
                <EmptyList message="There are no proxy yet">
                  <div className="flex gap-3">
                    <Button
                      onClick={handleOpenAdd}
                      className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                    >
                      <AddIcon className="transition-colors text-primary group-hover:text-white" />
                      <span className="transition-colors font-medium text-primary group-hover:text-white">
                        Add Proxy
                      </span>
                    </Button>
                  </div>
                </EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      <AddEditProxyModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
      />
      <DeleteProxyModal
        open={openDeleteProxyModal}
        data={selectedRowsKeys
          .map(String)
          .map((deletedId) => proxies.find(({ _id }) => _id === deletedId)!)
          .filter(Boolean)}
        onClose={() => setOpenDeleteProxyModal(false)}
        onDeleteSuccess={handleClearSelection}
      />
    </>
  )
}

export default AdminProxyPage
