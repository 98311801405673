import { FC, useCallback, useMemo } from "react"
import cx from "classnames"
import Checkbox from "@/components/Checkbox"
import { useGetCriteriaFiltersQuery } from "@/redux/services/searchCriteriaApi"
import { useCriteriaSetup } from "@/pages/AddEditSearchCriteria/hooks/useCriteriaSetup"
import { useAppSelector } from "@/redux/hooks"
import { selectCriteriaFilter } from "@/redux/searchCriteriaSetupSlice"
import { useActiveFilterContext } from "@/pages/AddEditSearchCriteria/hooks/useActiveFilterContext"
import type {
  FilterType,
  SelectionType,
} from "@/types/entities/search-criteria"

type Props = {
  className?: string
}

const CriteriaSeniorityLevelFilter: FC<Props> = ({
  className,
  ...rest
}: Props) => {
  const FILTER_TYPE = useActiveFilterContext() as FilterType
  const { data: filters = [] } = useGetCriteriaFiltersQuery()
  const seniorityFilterValues = useMemo(
    () => filters.find(({ type }) => type === FILTER_TYPE)?.values ?? [],
    [FILTER_TYPE, filters],
  )
  const { selections: senioritySelections } = useAppSelector(
    selectCriteriaFilter(FILTER_TYPE),
  )!
  const { onSelectionChange } = useCriteriaSetup()
  const renderCheckboxes = useCallback(
    (type: SelectionType) => {
      const values = senioritySelections[type]
      const setValues = onSelectionChange(type, FILTER_TYPE)

      return (
        <div className="relative overflow-y-auto max-h-[456px] space-y-3">
          {seniorityFilterValues.map(({ id, description }) => (
            <div className="flex items-center" key={id}>
              <Checkbox
                value={id}
                checked={Boolean(values?.find((typeValue) => id === typeValue))}
                onChange={setValues}
                id={id + type}
                className="checkbox-primary w-[18px] h-[18px]"
              />
              <label
                className="cursor-pointer pl-2.5 leading-6 text-sm"
                htmlFor={id + type}
              >
                {description}
              </label>
            </div>
          ))}
        </div>
      )
    },
    [
      senioritySelections,
      onSelectionChange,
      seniorityFilterValues,
      FILTER_TYPE,
    ],
  )

  return (
    <div className={cx("flex gap-4 flex-1", className)} {...rest}>
      <div className="basis-1/2 gap-y-2 flex flex-col">
        <h5 className="font-medium text-positive">Seniority Level Include</h5>
        {renderCheckboxes("INCLUDED")}
      </div>
      <div className="basis-1/2 gap-y-2 flex flex-col">
        <h5 className="font-medium text-error">Seniority Level Exclude</h5>
        {renderCheckboxes("EXCLUDED")}
      </div>
    </div>
  )
}

export default CriteriaSeniorityLevelFilter
