import {
  useUpdateInboxMutation,
  type UpdateInboxPayload,
} from "@/redux/services/inboxApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"

export const useUpdateInbox = () => {
  const dispatch = useAppDispatch()
  const [updateInbox, response] = useUpdateInboxMutation()

  const handleUpdateInbox = async (payload: UpdateInboxPayload) => {
    try {
      const response = await updateInbox(payload).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success update inbox",
        }),
      )
      if (response) {
        return response
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { updateInbox: handleUpdateInbox, response }
}
