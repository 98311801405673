import { FC, useRef } from "react"
import cx from "classnames"
import { Select as AntSelect, RefSelectProps, SelectProps } from "antd"
import CaretDownIcon from "@/assets/caret-down.svg"
import CheckIcon from "@/assets/check.svg"

type Props = {
  className?: string
  label?: React.ReactNode
  bottomLabel?: React.ReactNode
  containerClassName?: string
} & SelectProps

const Select: FC<Props> = ({
  className,
  label,
  bottomLabel,
  containerClassName,
  loading,
  ...rest
}: Props) => {
  const ref = useRef<RefSelectProps>(null)

  const focusSelect = () => ref.current?.focus()

  return (
    <div className={cx("flex flex-col", containerClassName)}>
      {label && (
        <h6
          onClick={focusSelect}
          className="label-text text-black font-medium pb-2"
        >
          {label}
        </h6>
      )}
      <AntSelect
        suffixIcon={
          loading ? (
            <span className="loading text-primary loading-spinner w-6 h-6"></span>
          ) : (
            <CaretDownIcon />
          )
        }
        className={cx(
          "[&.ant-select-disabled_.ant-select-selection-placeholder]:opacity-20 [&_.ant-select-selection-placeholder]:opacity-40 [&_.ant-select-selection-placeholder]:text-black [&.ant-select-disabled_.ant-select-arrow]:opacity-40",
          className,
        )}
        menuItemSelectedIcon={<CheckIcon />}
        ref={ref}
        showAction={["click", "focus"]}
        popupMatchSelectWidth={false}
        {...rest}
      />
      {bottomLabel && (
        <span className="label-text text-black font-medium px-1">
          {bottomLabel}
        </span>
      )}
    </div>
  )
}

export default Select
