import { Outlet } from "react-router-dom"

const ForgotPasswordPage = () => {
  return (
    <div className="flex justify-center">
      <div className="mt-16 flex flex-col gap-6 items-center max-w-[400px] w-full">
        <Outlet />
      </div>
    </div>
  )
}

export default ForgotPasswordPage
