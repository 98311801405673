import { useGetMasterListsQuery } from "@/redux/services/masterListsApi"
import {
  useGetEmailsQuery,
  useGetSendersQuery,
} from "@/redux/services/sendersApi"
import { useGetSequencesQuery } from "@/redux/services/sequencesApi"
import { useParams, useSearchParams } from "react-router-dom"

export const useDashboardValues = () => {
  const { id: orgId = "" } = useParams()
  const [searchParams] = useSearchParams()
  const sender = searchParams.get("sender") ?? ""
  const {
    data: { items: masterLists } = { items: [] },
    isLoading: MLLoading,
    isError: MLError,
  } = useGetMasterListsQuery({
    orgId,
  })
  const {
    data: { items: senders } = { items: [] },
    isLoading: sendersLoading,
    isError: sendersError,
  } = useGetSendersQuery({
    orgId,
  })
  const {
    data: { items: sequences } = { items: [] },
    isLoading: sequencesLoading,
    isError: sequencesError,
  } = useGetSequencesQuery({
    orgId,
  })
  const {
    data: { items: emailAddresses } = { items: [] },
    isError: emailAddressesError,
  } = useGetEmailsQuery({ orgId, senderId: sender }, { skip: !sender })

  return {
    masterLists,
    senders,
    sequences,
    emailAddresses,
    isLoading: MLLoading || sendersLoading || sequencesLoading,
    isError: MLError || sendersError || sequencesError || emailAddressesError,
  }
}
