import { FC, useState } from "react"
import Button from "@/components/Button"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import TextField from "@/components/TextField"
import { useFormik } from "formik"
import ImportMasterListModal from "../ImportMasterListModal"
import { useEditMasterList } from "../../hooks/useEditMasterList"
import { useAddMasterList } from "../../hooks/useAddMasterList"
import type { MasterList } from "@/types/entities/master-list"

type Props = {
  data?: MasterList
  addRecords?: any[]
  onAddRecordsSuccess?: (masterList: MasterList) => void
} & ModalProps

const AddMasterListModal: FC<Props> = ({
  open,
  onClose,
  data,
  addRecords,
  onAddRecordsSuccess,
}: Props) => {
  const isEdit = Boolean(data)
  const { addMasterList, response: addResponse } = useAddMasterList()
  const { editMasterList, response: editResponse } = useEditMasterList()
  const {
    values: { name },
    getFieldProps,
    handleSubmit,
    resetForm,
  } = useFormik<{ name: string }>({
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? "",
    },
    onSubmit: async (formData) => {
      if (!isEdit) {
        if (addRecords) {
          const createdMasterList = await addMasterList({
            name: formData.name,
            records: addRecords,
          })
          if (createdMasterList && onAddRecordsSuccess) {
            onAddRecordsSuccess(createdMasterList)
          }
        } else {
          setOpenImportModal({
            data: formData,
            open: true,
          })
        }
        handleClose()
      } else if (data) {
        await editMasterList({
          masterListId: data._id,
          name: formData.name,
        })
        handleClose()
      }
    },
  })
  const [openImportModal, setOpenImportModal] = useState<{
    open: boolean
    data: null | { name: string }
  }>({
    open: false,
    data: null,
  })

  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }

  const title = isEdit ? "Edit Master Lists" : "Add Master Lists"
  const isLoading = addResponse.isLoading || editResponse.isLoading
  return (
    <>
      <Modal
        title={title}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        actions={
          <div className="flex gap-4">
            <OutlineButton
              onClick={handleClose}
              disabled={isLoading}
              className="text-black btn-sm !w-[86px]"
              type="button"
            >
              Cancel
            </OutlineButton>
            <Button
              disabled={!name || isLoading}
              className="btn-sm !w-[86px]"
              type="submit"
            >
              {isEdit ? "Save" : "Add"}
            </Button>
          </div>
        }
      >
        <TextField
          topLeftLabel="Master List Name"
          placeholder="Enter master name"
          className="input-sm"
          rootClassName="-mt-2"
          {...getFieldProps("name")}
        />
      </Modal>
      <ImportMasterListModal
        data={openImportModal.data}
        open={openImportModal.open}
        onClose={() =>
          setOpenImportModal({
            data: null,
            open: false,
          })
        }
      />
    </>
  )
}

export default AddMasterListModal
