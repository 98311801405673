import { FC } from "react"
import cx from "classnames"

type Props = {
  className?: string
  contentBoxClassName?: string
  from: string
  sentAt: string
  subject: string
  text: string
}

const EmailMessage: FC<Props> = ({
  className,
  contentBoxClassName,
  from,
  sentAt,
  subject,
  text,
  ...rest
}: Props) => {
  return (
    <div className={cx("flex flex-col gap-1", className)} {...rest}>
      <div
        className={cx(
          "flex gap-1 border-b border-b-outline",
          contentBoxClassName,
        )}
      >
        <span className="min-w-[56px] font-medium">From:</span>
        <span>{from}</span>
      </div>
      <div
        className={cx(
          "flex gap-1 border-b border-b-outline",
          contentBoxClassName,
        )}
      >
        <span className="min-w-[56px] font-medium">Sent at:</span>
        <span>{new Date(sentAt).toLocaleString()}</span>
      </div>
      <div
        className={cx(
          "flex gap-1 border-b border-b-outline",
          contentBoxClassName,
        )}
      >
        <span className="min-w-[56px] font-medium">Subject:</span>
        <span>{subject}</span>
      </div>
      <div className={cx("flex gap-1", contentBoxClassName)}>
        <span className="min-w-[56px] font-medium">Text:</span>
        <span className="">{text}</span>
      </div>
    </div>
  )
}

export default EmailMessage
