import { FC } from "react"
import cx from "classnames"
import Image1 from "../img/step4-1.png"

type Props = {
  className?: string
}

const InstructionStepFour: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <div className={cx("", className)} {...rest}>
      <ol className="list-decimal list-inside space-y-2">
        <li>
          Go back to the Security page.
          <a
            target="_blank"
            className="underline"
            href="https://myaccount.google.com/u/1/security"
          >
            https://myaccount.google.com/u/1/security
          </a>{" "}
          and type <b>App passwords</b> in the search bar.
        </li>
        <img src={Image1} alt="step4-1" />
        <li>
          Select App and Device. For the App click on Mail. And for Device, you
          can simply click on Other (Custom)
        </li>
        <li>
          Select a name. The name doesn’t really matter but for organizational
          purposes, you can type in DreamSender - Email Connection and then
          click on Generate.
        </li>
        <li>
          An app password will be automatically generated. Copy it and save it
          somewhere safe. Once you’ve copied the app password click on DONE.
        </li>
      </ol>
    </div>
  )
}

export default InstructionStepFour
