import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "@/redux/store"
import { router } from "@/core/routing/router"
import AppProvider from "@/components/providers/AppProvider"
import "./index.css"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </Provider>,
)
