import { ThemeConfig } from "antd"

export const theme: ThemeConfig = {
  token: {
    colorPrimary: "#EE2181",
    motionDurationSlow: "0.4s",
    motionDurationFast: "0.25s",
    motionDurationMid: "0.05s",
    colorBorder: "var(--outline-dark)",
    borderRadiusLG: 6,
    colorText: "var(--black)",
    fontFamily: "Inter",
  },
  components: {
    Select: {
      optionSelectedColor: "var(--primary)",
      optionSelectedFontWeight: 600,
      optionSelectedBg: "transparent",
      optionPadding: "6px 10px",
    },
    Table: {
      cellPaddingBlock: 12,
      cellPaddingInline: 12,
      rowHoverBg: "var(--bg-default)",
      headerBg: "#fff",
      controlHeight: 24,
      lineHeight: 1.714,
      headerColor: "var(--black)",
      headerSplitColor: "transparent",
      borderColor: "var(--outline)",
      colorBorderSecondary: "var(--outline-dark)",
      colorText: "var(--black)",
      rowSelectedBg: "#FFF3F9",
      selectionColumnWidth: 42,
    },
    Drawer: {
      paddingLG: 16,
    },
    Steps: {
      fontSizeLG: 14,
    },
    Switch: {
      colorPrimary: "var(--positive)",
      colorPrimaryHover: "var(--positive)",
      trackHeight: 24,
      trackPadding: 3,
    },
    Radio: {
      buttonBg: "#0f0",
      buttonCheckedBg: "#000000",
      radioSize: 20,
      dotSize: 12,
      wrapperMarginInlineEnd: 14,
    },
    Popover: {
      borderRadiusLG: 6,
      boxShadowSecondary: "0px 8px 24px 0px rgba(0, 0, 0, 0.20)",
    },
  },
}
