import { FC } from "react"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import Drawer from "@/components/Drawer"
import OutlineButton from "@/components/OutlineButton"
import Chip from "@/components/Chip"
import { useCountryName } from "@/hooks/useCountryName"
import type { DrawerProps } from "antd"
import type { SenderAPIResponse } from "@/types/entities/sender"

type Props = {
  data?: SenderAPIResponse
  onEdit: (senderId: string) => void
  showEdit: boolean
} & DrawerProps

const SenderDetailsDrawer: FC<Props> = ({
  data,
  open,
  onClose,
  onEdit,
  showEdit,
}: Props) => {
  const countryName = useCountryName(data?.address.countryCode ?? "US")
  if (!data) {
    return null
  }
  const {
    firstName,
    lastName,
    titles,
    companies,
    websites,
    email,
    phoneNumber,
    _id,
    address,
  } = data
  const resultTitles = titles.filter(Boolean)
  const resultCompanies = companies.filter(Boolean)
  const resultWebsites = websites.filter(Boolean)
  return (
    <Drawer
      title={
        <div className="flex gap-4 items-center">
          <h2 className="text-lg font-semibold">Details</h2>
          {showEdit && (
            <OutlineButton
              onClick={() => onEdit(_id)}
              className="text-black btn-xs"
            >
              <EditIcon className="w-5 h-5 opacity-80" />
              <span className="opacity-80 text-sm font-semibold">Edit</span>
            </OutlineButton>
          )}
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <span className="caption">Sender Name</span>
          <span className="text-sm">
            {firstName} {lastName}
          </span>
        </div>
        {resultTitles.length > 0 && (
          <div className="flex flex-col gap-1">
            <span className="caption">Title</span>
            <div className="flex gap-2 flex-wrap">
              {resultTitles.map((title, i) => (
                <Chip key={i}>{title}</Chip>
              ))}
            </div>
          </div>
        )}
        {resultCompanies.length > 0 && (
          <div className="flex flex-col gap-1">
            <span className="caption">Company</span>
            <div className="flex gap-2 flex-wrap">
              {resultCompanies.map((companyName, i) => (
                <Chip key={i}>{companyName}</Chip>
              ))}
            </div>
          </div>
        )}
        {resultWebsites.length > 0 && (
          <div className="flex flex-col gap-1">
            <span className="caption">Website</span>
            <div className="flex gap-2 flex-wrap">
              {resultWebsites.map((websiteName, i) => (
                <Chip key={i}>{websiteName}</Chip>
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-col">
          <span className="caption">Email Address</span>
          <span className="text-sm">{email}</span>
        </div>
        <div className="flex flex-col">
          <span className="caption">Phone Number</span>
          <span className="text-sm">{phoneNumber}</span>
        </div>
        {countryName && (
          <div className="flex flex-col">
            <span className="caption">Country</span>
            <span className="text-sm">{countryName}</span>
          </div>
        )}
        {(!address.countryCode || address.countryCode === "US") && (
          <>
            <div className="flex flex-col">
              <span className="caption">Street</span>
              <span className="text-sm">{address.address1}</span>
            </div>
            <div className="flex flex-col">
              <span className="caption">State</span>
              <span className="text-sm">{address.state}</span>
            </div>
            <div className="flex flex-col">
              <span className="caption">City</span>
              <span className="text-sm">{address.city}</span>
            </div>
            <div className="flex flex-col">
              <span className="caption">ZIP code</span>
              <span className="text-sm">{address.zipCode}</span>
            </div>
          </>
        )}
        {address.countryCode === "CA" && (
          <>
            <div className="flex flex-col">
              <span className="caption">Street</span>
              <span className="text-sm">{address.address1}</span>
            </div>
            <div className="flex flex-col">
              <span className="caption">City</span>
              <span className="text-sm">{address.city}</span>
            </div>
            <div className="flex flex-col">
              <span className="caption">Province</span>
              <span className="text-sm">{address.province}</span>
            </div>
            <div className="flex flex-col">
              <span className="caption">Postal Code</span>
              <span className="text-sm">{address.postalCode}</span>
            </div>
          </>
        )}
        {address.countryCode === "Other" && (
          <>
            <div className="flex flex-col">
              <span className="caption">Complete Address</span>
              <span className="text-sm">{address.completeAddress}</span>
            </div>
          </>
        )}
      </div>
    </Drawer>
  )
}

export default SenderDetailsDrawer
