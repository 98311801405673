import { FC, useMemo, useState } from "react"
import cx from "classnames"
import Table from "@/components/Table"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import AddEditSpamAccountModal from "../AddEditSpamAccountModal"
import DeleteSpamTestingModal from "../AddEditSpamAccountModal/DeleteSpamAccountModal"
import EmailPlatformColumnValue from "@/components/EmailPlatformColumnValue"
import { DATE_FORMAT_OPTIONS } from "@/constants"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import type { AdminSpamAccount } from "@/types/entities/spam-account"
import type { TablePaginationProps } from "@/redux/services/types"
import type { ColumnsType } from "antd/es/table"
import type { Key } from "antd/es/table/interface"
import type { EmailPlatform } from "@/types/entities/gsuite-account"

type Props = {
  className?: string
  spams: AdminSpamAccount[]
  onRowSelect: (keys: Key[]) => void
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  displayName: string
  email: string
  updatedAt: string
  provider: EmailPlatform
}

const AdminSpamTestingTable: FC<Props> = ({
  className,
  spams,
  onRowSelect,
  ...rest
}: Props) => {
  const { sm } = useBreakpoints()
  const [editedSpamAccountId, setEditedSpamAccountId] = useState("")
  const [deletedSpamAccountId, setDeletedSpamAccountId] = useState("")

  const dataSource = useMemo<TableDataType[]>(
    () =>
      spams.map(({ _id, displayName, email, updatedAt, provider }) => ({
        key: _id,
        updatedAt: new Date(updatedAt).toLocaleDateString(
          "en-US",
          DATE_FORMAT_OPTIONS,
        ),
        displayName,
        email,
        provider,
      })),
    [spams],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(
    () => [
      {
        title: "Account Name",
        key: "displayName",
        dataIndex: "displayName",
        sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      },
      {
        title: "Email Address",
        key: "email",
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        render: (value, { provider }) => (
          <EmailPlatformColumnValue provider={provider} value={value} />
        ),
      },
      {
        title: "Last Edited",
        key: "updatedAt",
        dataIndex: "updatedAt",
        sorter: (a, b) =>
          Number(new Date(a.updatedAt)) - Number(new Date(b.updatedAt)),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        className: "min-w-[96px]",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            <EditIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setEditedSpamAccountId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer active:scale-90 hover:text-primary transition-colors"
            />
            <BinIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setDeletedSpamAccountId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer active:scale-90 hover:text-primary transition-colors"
            />
          </div>
        ),
        width: 96,
      },
    ],
    [sm],
  )
  return (
    <>
      <Table
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys) => onRowSelect(selectedRowKeys),
        }}
        dataSource={dataSource}
        columns={columns}
        className={cx("", className)}
        showSizeChanger
        {...rest}
      />
      <AddEditSpamAccountModal
        data={spams.find(({ _id }) => _id === editedSpamAccountId)}
        open={!!editedSpamAccountId}
        onClose={() => setEditedSpamAccountId("")}
      />
      <DeleteSpamTestingModal
        data={
          deletedSpamAccountId
            ? [spams.find(({ _id }) => _id === deletedSpamAccountId)!]
            : []
        }
        open={!!deletedSpamAccountId}
        onClose={() => setDeletedSpamAccountId("")}
      />
    </>
  )
}

export default AdminSpamTestingTable
