import { useSetOrganizationStatusMutation } from "@/redux/services/organizationApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import type { SetOrganizationStatus } from "@/types/entities/organization"

export const useSetOrganizationStatus = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch()
  const [setOrganizationStatus, response] = useSetOrganizationStatusMutation()

  const handleSetOrganizationStatus = async ({
    status,
    id,
  }: SetOrganizationStatus & { id: string }) => {
    try {
      await setOrganizationStatus({
        body: { status },
        id,
      }).unwrap()
      typeof onSuccess === "function" && onSuccess()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: `Success ${
            status === "disabled" ? "disable" : "enable"
          } organization`,
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { setOrganizationStatus: handleSetOrganizationStatus, response }
}
