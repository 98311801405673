import React, { FC } from "react"
import cx from "classnames"
import { AiOutlineClose as CloseIcon } from "react-icons/ai"

type Props = {
  tabKey: string | number // key identifies a tab
  className?: string
  containerClassName?: string
  active?: boolean
  children: React.ReactNode
  onClose?: ((value: string | number) => void) | null
  onSelect?: (value: string | number) => void
  label: React.ReactNode
} & React.HTMLAttributes<HTMLSpanElement>

const Tab: FC<Props> = ({
  className,
  active = false,
  children,
  onClose,
  onSelect,
  tabKey,
  label,
  containerClassName,
  ...rest
}: Props) => {
  const handleClick = () => {
    onSelect && onSelect(tabKey)
  }

  return (
    <>
      <span
        className={cx(
          "tab !bg-white before:hidden after:hidden h-10 inline-flex gap-3 text-black row-start-1",
          { "tab-active": active },
          {
            "rounded-none border-outline !py-2 !px-4 leading-6":
              className?.includes("tab-lifted"),
          },
          {
            "!border-t-[3px] !border-t-primary !border-x-outline !pt-[4px] !pb-[9px]":
              className?.includes("tab-lifted") && active,
          },
          {
            "!pt-[7px]": className?.includes("tab-lifted") && !active,
          },
          className,
        )}
        onClick={handleClick}
        {...rest}
      >
        <>{label}</>
        {onClose && (
          <CloseIcon
            onClick={(e: React.MouseEvent<HTMLOrSVGElement>) => {
              e.stopPropagation()
              onClose(tabKey)
            }}
            className="w-4 h-4 opacity-40 hover:opacity-100 transition-opacity"
          />
        )}
      </span>
      {active && (
        <div
          style={{
            gridColumn: "1/-1",
            gridColumnEnd: "span 9999",
          }}
          className={cx(
            "-mt-[1px] row-start-2 w-full border-outline border-t p-5",
            containerClassName,
          )}
        >
          {children}
        </div>
      )}
    </>
  )
}

export default Tab
