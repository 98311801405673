import { FC, useMemo, useRef } from "react"
import cx from "classnames"
import Collapse, { COLLAPSE_TITLE_X_PADDING } from "@/components/Collapse"
import VerticalLineIcon from "@/assets/vertical-line.svg"
import SubqueryCategory from "./SubqueryCategory"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import { QUERY_INDEX_REGEX, getCategoryStringFormatted } from "./utils"
import { getCategoryStrings } from "../../utils"
import { useGetAIPromptsQuery } from "@/redux/services/aiPromptsApi"
import { useElementWidth } from "@/hooks/useElementWidth"
import { usePagination } from "@/hooks/usePagination"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import type { AiPrompt } from "@/types/entities/ai-prompt"

type Props = {
  className?: string
  prompt: AiPrompt
  onEdit?: (id: string) => void
  onDelete?: (id: string) => void
  onAddSubquery?: (prompt: AiPrompt & { categoryName: string }) => void
  onEditSubquery?: (prompt: AiPrompt & { categoryName: string }) => void
}

const AIPromptAccordion: FC<Props> = ({
  className,
  prompt,
  onEdit,
  onDelete,
  onAddSubquery,
  onEditSubquery,
  ...rest
}: Props) => {
  const { searchTerm } = usePagination()
  const { data: prompts = [] } = useGetAIPromptsQuery()
  const { data: { classificationActions } = { classificationActions: [] } } =
    useGetSystemValuesQuery()
  const childrenQueries = useMemo(
    () => prompts.filter(({ parent }) => parent),
    [prompts],
  )

  const collapseRef = useRef<HTMLDivElement>(null)
  const collapseWidth = useElementWidth(collapseRef)
  const categories = useMemo(() => getCategoryStrings(prompt.text), [prompt])
  const categoriesStrings = useMemo(
    () =>
      categories.map((category, i) =>
        getCategoryStringFormatted(category, prompt, i, classificationActions),
      ),
    [categories, classificationActions, prompt],
  )

  const hasSubqueries = (queryI: number) =>
    childrenQueries.filter(
      ({ parentCategory }) => parentCategory === queryI + 1,
    ).length > 0

  return (
    <Collapse
      defaultOpen
      ref={collapseRef}
      title={
        <div
          className="flex gap-[15px] justify-between"
          style={{
            maxWidth: collapseWidth - COLLAPSE_TITLE_X_PADDING,
          }}
        >
          <span className="text-base truncate leading-6 font-medium">
            {prompt.text}
          </span>
          <div className="flex gap-[15px] items-center">
            <VerticalLineIcon />
            <EditIcon
              onClick={() => onEdit && onEdit(prompt._id)}
              className="z-10 w-6 hover:text-primary cursor-pointer active:scale-90 h-6 opacity-60"
            />
            <VerticalLineIcon />
            <BinIcon
              onClick={() => onDelete && onDelete(prompt._id)}
              className="z-10 w-6 hover:text-primary cursor-pointer active:scale-90 h-6 opacity-60"
            />
            <VerticalLineIcon />
          </div>
        </div>
      }
      className={cx(
        `[&_.collapse-content]:p-0 bg-[#F6F6F9] [&_.collapse-title]:bg-white`,
        className,
      )}
      {...rest}
    >
      <div className="border-t border-outline space-y-3 py-3 px-6 bg-[#F8F8F8]">
        {categoriesStrings.map((category, queryI) => (
          <div
            className={cx(
              "small-shadow flex flex-col rounded-[4px] bg-[#F8F8F8] overflow-hidden border border-outline",
              {
                hidden: searchTerm
                  ? !category
                      .toLocaleLowerCase()
                      .includes(searchTerm.trim().toLocaleLowerCase())
                  : false,
              },
            )}
            key={queryI}
          >
            <div className="flex text-sm font-medium leading-[30px] justify-between py-2 px-4 border-b border-outline bg-white">
              <span>{category}</span>
              <div className="flex gap-4 items-center">
                {hasSubqueries(queryI) && (
                  <BinIcon
                    onClick={() => {
                      const subqueryData = childrenQueries.find(
                        ({ parentCategory }) => parentCategory === queryI + 1,
                      )!
                      onDelete && onDelete(subqueryData._id)
                    }}
                    className="z-10 w-6 text-black hover:text-primary cursor-pointer active:scale-90 h-6 opacity-60"
                  />
                )}
                <span
                  onClick={() => {
                    const body = {
                      ...prompt,
                      parentCategory: queryI + 1, // categoryNumber | logic is wrong here, if e.g. first one sub-query 1 was deleted, this won't work
                      categoryName: category.replace(QUERY_INDEX_REGEX, ""),
                    }
                    const subqueryData = childrenQueries.find(
                      ({ parentCategory }) => parentCategory === queryI + 1, // categoryNumber
                    )!
                    hasSubqueries(queryI)
                      ? onEditSubquery &&
                        onEditSubquery({
                          ...body,
                          _id: subqueryData._id,
                          text: subqueryData.text,
                          actions: subqueryData.actions,
                        })
                      : onAddSubquery &&
                        onAddSubquery({
                          ...body,
                          text: "",
                          actions: [],
                        })
                  }}
                  className="text-primary cursor-pointer active:scale-95"
                >
                  {hasSubqueries(queryI) ? "Edit sub-query" : "Add sub-query"}
                </span>
              </div>
            </div>
            {hasSubqueries(queryI) && (
              <div
                className="p-2 pl-6 flex flex-col gap-2"
                style={{
                  maxWidth: collapseWidth - COLLAPSE_TITLE_X_PADDING,
                }}
              >
                {childrenQueries
                  .filter(({ parentCategory }) => parentCategory === queryI + 1)
                  .map((prompt, promptI) => (
                    <SubqueryCategory prompt={prompt} key={promptI} />
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </Collapse>
  )
}

export default AIPromptAccordion
