import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useEditSenderMutation } from "@/redux/services/sendersApi"
import { setToast } from "@/redux/toastSlice"
import type { Sender } from "@/types/entities/sender"

export const useEditSender = () => {
  const dispatch = useAppDispatch()
  const [editSender, response] = useEditSenderMutation()

  const handleEditSender = async ({
    body,
    id,
  }: {
    id: string
    body: Sender
  }) => {
    try {
      const response = await editSender({
        body,
        id,
      }).unwrap()
      if (response.data) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success edit sender",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error, true)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { editSender: handleEditSender, response }
}
