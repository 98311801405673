import type { Sender, SenderForm } from "@/types/entities/sender"

export const getBodyByCountryCode = (form: SenderForm) => {
  const {
    address: {
      countryCode,
      address1,
      address2,
      city,
      completeAddress,
      postalCode,
      province,
      state,
      zipCode,
    },
  } = form
  if (!countryCode) {
    return {
      ...form,
    }
  }
  const body = {
    ...form,
  } as Sender
  if (countryCode === "CA") {
    body.address = {
      countryCode,
      address1,
      address2,
      city,
      postalCode,
      province,
    }
  }
  if (countryCode === "US") {
    body.address = {
      countryCode,
      address1,
      address2,
      city,
      state,
      zipCode,
    }
  }
  if (countryCode === "Other") {
    body.address = {
      countryCode,
      completeAddress,
    }
  }
  return body
}
