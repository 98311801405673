import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteDomainMutation } from "@/redux/services/domainsApi"
import { setToast } from "@/redux/toastSlice"

export const useDeleteDomain = () => {
  const dispatch = useAppDispatch()
  const [deleteDomain, response] = useDeleteDomainMutation()

  const handleDeleteOrganization = async (id: string) => {
    try {
      await deleteDomain({ id }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success delete domain",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteDomain: handleDeleteOrganization, response }
}
