import { FC, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { cx } from "@/utils/strings"
import { filterOption } from "@/components/Select/constants"
import { useAdminUsersInfiniteScroll } from "../../hooks/useAdminUsersInfiniteScroll"
import SearchSelect from "@/components/SearchSelect"
import type { AdminUser } from "@/types/entities/admin-user"
import type { SelectProps } from "antd"

type Props = {
  className?: string
} & SelectProps<AdminUser[]>

const OwnersDropdown: FC<Props> = ({ className, ...rest }: Props) => {
  const { isLoading, onUsersScroll, paginatedOwners } =
    useAdminUsersInfiniteScroll()

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedOwnerId = searchParams.get("owner") ?? ""

  const [ownerSearchTerm, setOwnerSearchTerm] = useState("")

  const handleOwnerSelect = (ownerId: string) =>
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      ownerId ? newParams.set("owner", ownerId) : newParams.delete("owner")
      return newParams
    })

  return (
    <SearchSelect
      searchTerm={ownerSearchTerm}
      onSearch={setOwnerSearchTerm}
      value={isLoading ? null : selectedOwnerId}
      onChange={handleOwnerSelect}
      onPopupScroll={onUsersScroll}
      bordered
      className={cx("", className)}
      containerClassName="basis-32 md:basis-44"
      placeholder="Search by name"
      filterOption={filterOption}
      loading={isLoading}
      options={[
        { label: "All owners", value: "" },
        ...paginatedOwners.map(({ firstName, lastName, _id }) => ({
          label: `${firstName} ${lastName}`,
          value: _id,
        })),
      ]}
      {...rest}
    />
  )
}

export default OwnersDropdown
