import { FC, KeyboardEvent, SyntheticEvent, useState } from "react"
import cx from "classnames"
import { twMerge } from "tailwind-merge"
import { Autocomplete, TextField } from "@mui/material"
import Chip from "../Chip"
import Tooltip from "../Tooltip"

type Props = {
  className?: string
  placeholder?: string
  type?: string
  value: any[]
  minLength?: number
  maxLength?: number
  regex?: RegExp
  onTagsChange: (v: any) => void
  onPaste?: (v: React.ClipboardEvent<HTMLDivElement>) => void
}

const TagsTextarea: FC<Props> = ({
  className,
  value,
  placeholder,
  type,
  minLength,
  maxLength,
  regex,
  onTagsChange,
  onPaste,
}: Props) => {
  const [inputText, setInputText] = useState("")
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const inputValue = (event.target as HTMLInputElement).value
    const inputLength = inputValue.length
    const key = event.key
    if (key === "Enter") {
      if (
        (minLength && inputLength < minLength) ||
        (maxLength && inputLength > maxLength) ||
        (regex && !regex.test(inputValue))
      ) {
        event.stopPropagation()
        event.preventDefault()
        return
      }
    }
    if (
      maxLength &&
      inputLength >= maxLength &&
      key !== "Delete" &&
      key !== "Backspace"
    ) {
      event.preventDefault()
      return
    }
    switch (key) {
      default:
    }
  }
  const handleChange = (_: SyntheticEvent<Element, Event>, value: any) =>
    onTagsChange(value)
  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const text = e.clipboardData.getData("Text")
    if (text.includes("\n")) {
      const tags = text.split("\n")
      onTagsChange([...value, ...tags])
      e.preventDefault()
    }
    onPaste && onPaste(e)
  }

  return (
    <Tooltip title="Don't forget to hit enter!" open={Boolean(inputText)}>
      <Autocomplete
        clearIcon={false}
        freeSolo
        multiple
        options={[]}
        renderTags={(value, props) =>
          value.map((option, index) => (
            <Chip
              {...props({ index })}
              dataTagIndex={props({ index })["data-tag-index"]}
            >
              {option}
            </Chip>
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              onKeyDown: handleKeyDown,
            }}
            onPaste={handlePaste}
            sx={{
              "& fieldset": { border: "none" },
              fontSize: 37,
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: 14,
                lineHeight: "24px",
                padding: 12,
              },
            }}
            type={type}
            placeholder={placeholder}
          />
        )}
        className={twMerge(
          cx(
            "cursor-text bg-input outline-outline-dark transition-[outline] rounded-md [&_input]:!p-0",
            className,
          ),
        )}
        sx={{
          "&.Mui-focused": {
            backgroundColor: "#FBFBFD",
            outlineWidth: 1.5,
            outlineStyle: "solid",
          },
        }}
        value={value}
        onChange={handleChange}
        onInputChange={(_, v) => setInputText(v)}
      />
    </Tooltip>
  )
}

export default TagsTextarea
