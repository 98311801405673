import { FC } from "react"
import Select from "@/components/Select"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import type { SelectProps } from "antd"
import type { SearchCriteriaStatus } from "@/types/entities/search-criteria"

export const searchCriteriaStatusColorMap: Record<
  SearchCriteriaStatus,
  string
> = {
  active: "var(--positive)",
  completed: "var(--purple)",
  stopped: "var(--error)",
  draft: "var(--warning)",
} as const

type Props = SelectProps & {
  value: string
  onCriteriaStatusChange: (value: SearchCriteriaStatus) => void
  label?: string
  containerClassName?: string
}

const CriteriaStatusSelect: FC<Props> = ({
  value,
  onCriteriaStatusChange,
  placeholder,
  label,
  ...rest
}: Props) => {
  const { data: { searchCriteriaStatuses } = { searchCriteriaStatuses: [] } } =
    useGetSystemValuesQuery()

  return (
    <Select
      options={searchCriteriaStatuses.map(({ display, id }) => ({
        label: display,
        value: id,
        color:
          searchCriteriaStatusColorMap[
            id as keyof typeof searchCriteriaStatusColorMap
          ] ?? "#000",
      }))}
      optionRender={({ data, label }) => (
        <div className="flex gap-2.5 items-center">
          <div
            className="rounded-full h-2.5 w-2.5"
            style={{ backgroundColor: data.color }}
          ></div>
          <span className="text-sm leading-6">{label}</span>
        </div>
      )}
      value={{
        value,
        label: value ? (
          <div className="flex gap-2.5 items-center">
            <div
              className="rounded-full h-2.5 w-2.5"
              style={{
                backgroundColor:
                  searchCriteriaStatusColorMap[value as SearchCriteriaStatus],
              }}
            ></div>
            <span className="text-sm leading-6">
              {searchCriteriaStatuses.find((v) => v.id === value)?.display}
            </span>
          </div>
        ) : (
          <>{placeholder}</>
        ),
      }}
      labelInValue
      bordered={false}
      className="h-10 bg-input rounded-md"
      label={label}
      placeholder={placeholder}
      popupMatchSelectWidth={false}
      onChange={({ value }) => onCriteriaStatusChange(value)}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    />
  )
}

export default CriteriaStatusSelect
