import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import { buildQueryString } from "@/utils/strings"
import { exportFileResponseHandler } from "@/utils/api"
import type { PaginationProps } from "./types"
import type { AdminDNC, UserDNC } from "@/types/entities/dnc"

export type ExportUserDNC = { text?: string; bounced?: boolean }

export const userDncApi = createApi({
  reducerPath: "userDncApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["User DNC"],
  endpoints: (builder) => ({
    getUserDNC: builder.query<
      { items: AdminDNC[]; total: number },
      { orgId: string } & PaginationProps & { text?: string; bounced?: boolean }
    >({
      query: ({ orgId, ...keys }) => ({
        method: "GET",
        url: `org/${orgId}/dnc?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { records, total },
      }: {
        data: { records: AdminDNC[]; total: number }
      }) => ({
        items: records,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "User DNC" as const,
                _id,
              })),
              { type: "User DNC", id: "PARTIAL-LIST" },
            ]
          : [{ type: "User DNC", id: "PARTIAL-LIST" }],
    }),
    addUserDNC: builder.mutation<
      AdminDNC,
      {
        id: string
        body: {
          records: string[]
        }
      }
    >({
      query: ({ id, body }) => ({
        method: "POST",
        url: `org/${id}/dnc`,
        body,
      }),
      invalidatesTags: ["User DNC"],
      transformResponse: ({ data }: { data: AdminDNC }) => data,
    }),
    editUserDNC: builder.mutation<
      UserDNC,
      {
        body: {
          recordId: string
          value: string
        }
        orgId: string
      }
    >({
      query: ({ body, orgId }) => ({
        method: "PUT",
        url: `org/${orgId}/dnc`,
        body,
      }),
      invalidatesTags: ["User DNC"],
      transformResponse: ({ data }: { data: AdminDNC }) => data,
    }),
    importUserDNC: builder.mutation<
      AdminDNC,
      { orgId: string; body: FormData }
    >({
      query: ({ body, orgId }) => ({
        method: "POST",
        url: `org/${orgId}/dnc/import`,
        body,
      }),
      invalidatesTags: ["User DNC"],
      transformResponse: ({ data }: { data: AdminDNC }) => data,
    }),
    deleteUserDNC: builder.mutation<unknown, { id: string; records: string[] }>(
      {
        query: ({ id, records }) => ({
          method: "DELETE",
          url: `org/${id}/dnc?${buildQueryString({ records })}`,
        }),
        invalidatesTags: ["User DNC"],
        transformResponse: ({ data }: { data: AdminDNC }) => data,
      },
    ),
    exportUserDNC: builder.mutation<
      unknown,
      { orgId: string; body: ExportUserDNC }
    >({
      query: ({ orgId, body }) => ({
        url: `org/${orgId}/dnc/export`,
        method: "POST",
        responseHandler: async (response) =>
          await exportFileResponseHandler(response, "User DNC.csv"),
        cache: "no-cache",
        body,
      }),
    }),
  }),
})

export const {
  useGetUserDNCQuery,
  useAddUserDNCMutation,
  useEditUserDNCMutation,
  useImportUserDNCMutation,
  useDeleteUserDNCMutation,
  useExportUserDNCMutation,
} = userDncApi
