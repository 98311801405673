import React, { FC } from "react"
import cx from "classnames"
import OutlineButton from ".."

type Props = {
  className?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const CancelButton: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <OutlineButton
      className={cx(
        "text-black btn-sm min-w-[86px] disabled:text-black",
        className,
      )}
      type="button"
      {...rest}
    >
      Cancel
    </OutlineButton>
  )
}

export default CancelButton
