import { FC } from "react"
import GoogleIcon from "@/assets/google.svg"
import OutlookIcon from "@/assets/outlook.svg"
import type { EmailPlatform } from "@/types/entities/gsuite-account"

type Props = {
  className?: string
  provider: EmailPlatform
  value: string
}

const EmailPlatformColumnValue: FC<Props> = ({
  provider,
  value,
  ...rest
}: Props) => {
  return (
    <div className="flex gap-2.5 items-center" {...rest}>
      {provider === "google" ? (
        <GoogleIcon className="text-sm leading-6" />
      ) : provider === "microsoft" ? (
        <OutlookIcon className="text-sm leading-6" />
      ) : null}
      <span>{value}</span>
    </div>
  )
}

export default EmailPlatformColumnValue
