import { FC } from "react"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import Drawer from "@/components/Drawer"
import OutlineButton from "@/components/OutlineButton"
import Table from "@/components/Table"
import { DATE_FORMAT_OPTIONS } from "@/constants"
import type { DrawerProps } from "antd"
import type { EmailAddress } from "@/types/entities/email-address"
import type { ColumnsType } from "antd/es/table"

type Props = {
  data?: EmailAddress
  onEdit: (emailAddressId: string) => void
  showEdit: boolean
} & DrawerProps

type TableDataType = {
  key: string
  name: string
  date: string
}

const EmailAddressDetailsDrawer: FC<Props> = ({
  data,
  open,
  onClose,
  onEdit,
  showEdit,
}: Props) => {
  if (!data) {
    return null
  }
  const columns: ColumnsType<TableDataType> = [
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      width: 88,
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ]
  const { email, sender, followUpQueue, campaigns } = data
  const campaignsDataSource: TableDataType[] = campaigns?.length
    ? campaigns.map(({ _id, name, createdAt }) => ({
        key: _id,
        name,
        date: new Date(createdAt).toLocaleDateString(
          "en-US",
          DATE_FORMAT_OPTIONS,
        ),
      }))
    : []
  return (
    <Drawer
      title={
        <div className="flex gap-4 items-center">
          <h2 className="text-lg font-semibold">Details</h2>
          {showEdit && (
            <OutlineButton
              onClick={() => onEdit(data._id)}
              className="text-black btn-xs"
            >
              <EditIcon className="w-5 h-5 opacity-80" />
              <span className="opacity-80 text-sm font-semibold">Edit</span>
            </OutlineButton>
          )}
        </div>
      }
      open={open}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4 border-b-8 border-b-bg-default pb-6">
        <div className="flex gap-4">
          <div className="flex flex-col flex-1">
            <span className="caption">Email Address</span>
            <span className="text-sm">{email}</span>
          </div>
          <div className="flex flex-col flex-1">
            <span className="caption">Sender</span>
            <span className="text-sm">
              {sender.firstName} {sender.lastName}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="caption">Follow Up Queue</span>
          <span className="text-sm">{followUpQueue}</span>
        </div>
      </div>
      {campaignsDataSource.length ? (
        <div className="flex flex-col mt-4">
          <h5 className="font-semibold leading-6 text-sm">
            Campaigns ({campaigns.length})
          </h5>
          <Table
            sortDirections={["ascend", "descend"]}
            dataSource={campaignsDataSource}
            columns={columns}
            className="[&_.ant-table-cell]:!pl-0"
          />
        </div>
      ) : (
        <h5 className="font-semibold leading-6 text-sm">No campaigns</h5>
      )}
    </Drawer>
  )
}

export default EmailAddressDetailsDrawer
