import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import DeleteButton from "@/components/Button/DeleteButton"
import CancelButton from "@/components/OutlineButton/CancelButton"
import { useDeleteProspects } from "../../hooks/useDeleteProspects"
import type { Prospect } from "@/types/entities/prospect"

type Props = {
  className?: string
  data?: Prospect[]
  onDeleteSuccess?: () => void
} & ModalProps

const ProspectsDeleteModal: FC<Props> = ({
  className,
  open,
  data,
  onClose,
  onDeleteSuccess,
  ...rest
}: Props) => {
  const {
    deleteProspects,
    response: { isLoading },
  } = useDeleteProspects()
  if (!data) {
    return null
  }
  const handleDelete = async () => {
    try {
      await deleteProspects({ id: data.map(({ _id }) => _id) })
      onClose && onClose()
      onDeleteSuccess && onDeleteSuccess()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal
      title="Delete Prospects"
      open={open}
      onClose={onClose}
      className={cx("", className)}
      onSubmit={handleDelete}
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={isLoading} onClick={onClose} />
          <DeleteButton disabled={isLoading} />
        </div>
      }
      {...rest}
    >
      <span className="leading-6 text-base break-all">
        Are you sure you want to delete selected record
        {data.length > 1 ? "s" : ""}?
      </span>
    </Modal>
  )
}

export default ProspectsDeleteModal
