import { FC, useCallback, useMemo, useState } from "react"
import cx from "classnames"
import Table from "@/components/Table"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import Switch from "@/components/Switch"
import { useEditAdminProxy } from "../../hooks/useEditProxy"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import { DATE_FORMAT_OPTIONS } from "@/constants"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import AddEditProxyModal from "../AddEditProxyModal"
import DeleteProxyModal from "../DeleteProxyModal"
import type { TablePaginationProps } from "@/redux/services/types"
import type { ColumnsType } from "antd/es/table"
import type { Proxy } from "@/types/entities/proxy"

type Props = {
  className?: string
  proxies: Proxy[]
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  enabled: boolean
  ip: string
  region: string
  updatedAt: string
}

const AdminProxyTable: FC<Props> = ({
  className,
  proxies,
  onRowSelect,
  selectedRowKeys,
  ...rest
}: Props) => {
  const { sm } = useBreakpoints()
  const [editedProxyId, setEditedProxyId] = useState("")
  const [deletedProxyId, setDeletedProxyId] = useState("")
  const { editAdminProxy } = useEditAdminProxy()
  const { data: { regions } = { regions: [] } } = useGetSystemValuesQuery()
  const handleUpdateStatus = useCallback(
    (id: string) => async (checked: boolean) => {
      await editAdminProxy({
        body: {
          enabled: checked,
        },
        id,
      })
    },
    [editAdminProxy],
  )
  const dataSource = useMemo<TableDataType[]>(
    () =>
      proxies.map(({ _id, ip, enabled, region, updatedAt }) => ({
        key: _id,
        enabled,
        ip,
        region,
        updatedAt: updatedAt
          ? new Date(updatedAt).toLocaleDateString("en-US", DATE_FORMAT_OPTIONS)
          : "",
      })),
    [proxies],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(
    () => [
      {
        title: "",
        key: "enabled",
        dataIndex: "enabled",
        render: (enabled, { key }) => (
          <Switch checked={enabled} onChange={handleUpdateStatus(key)} />
        ),
        width: 70,
      },
      {
        title: "IP Address",
        key: "ip",
        dataIndex: "ip",
        sorter: (a, b) => a.ip.localeCompare(b.ip),
      },
      {
        title: "Region",
        key: "region",
        dataIndex: "region",
        sorter: (a, b) => a.region.localeCompare(b.region),
        render: (v: string) => regions.find(({ id }) => id === v)?.name ?? "",
      },
      {
        title: "Last Update",
        key: "updatedAt",
        dataIndex: "updatedAt",
        sorter: (a, b) => +a.updatedAt - +b.updatedAt,
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        className: "min-w-[96px]",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            <EditIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setEditedProxyId(key)
              }}
              className="w-6 h-6 opacity-60 active:scale-90 cursor-pointer hover:text-primary transition-colors"
            />
            <BinIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setDeletedProxyId(key)
              }}
              className="w-6 h-6 opacity-60 active:scale-90 cursor-pointer hover:text-primary transition-colors"
            />
          </div>
        ),
        width: 96,
      },
    ],
    [handleUpdateStatus, regions, sm],
  )
  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        rootClassName="w-full"
        className={cx("", className)}
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys) =>
            onRowSelect && onRowSelect(selectedRowKeys),
          selectedRowKeys,
        }}
        showSizeChanger
        {...rest}
      ></Table>
      <AddEditProxyModal
        data={proxies.find(({ _id }) => _id === editedProxyId)}
        open={!!editedProxyId}
        onClose={() => setEditedProxyId("")}
      />
      <DeleteProxyModal
        open={!!deletedProxyId}
        data={[proxies.find(({ _id }) => _id === deletedProxyId)!].filter(
          Boolean,
        )}
        onClose={() => setDeletedProxyId("")}
      />
    </>
  )
}

export default AdminProxyTable
