import type { UIFilters } from "@/redux/searchCriteriaSetupSlice"
import type {
  FilterType,
  SelectionType,
} from "@/types/entities/search-criteria"

export function countPresentValues(shape: UIFilters): number {
  let count = 0

  for (const key in shape) {
    if (Object.prototype.hasOwnProperty.call(shape, key)) {
      const filter = shape[key as FilterType]
      for (const type in filter.selections) {
        if (Object.prototype.hasOwnProperty.call(filter.selections, type)) {
          const values = filter.selections[type as SelectionType]
          count += values.filter(
            (value: any) => value !== null && value !== undefined,
          ).length
        }
      }
      if (
        filter.subSelection?.value !== null &&
        filter.subSelection?.value !== undefined
      ) {
        count++
      }
    }
  }

  return count
}
