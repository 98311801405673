import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import { useFormik } from "formik"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import { useGetReplyClassificationsQuery } from "@/redux/services/generalApi"
import validationSchema from "./validationSchema"
import ClassificationCheckbox from "../ClassificationCheckbox"
import type { ForwardingGroup } from "@/types/entities/campaign"

type Props = {
  className?: string
  data?: ForwardingGroup
  existingNames: string[]
  onAddSuccess?: (group: Omit<ForwardingGroup, "emails">) => void
  onEditSuccess?: (group: ForwardingGroup & { oldName: string }) => void
} & ModalProps

const AddEditForwardingGroup: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  onAddSuccess,
  onEditSuccess,
  existingNames,
  ...rest
}: Props) => {
  const isEdit = Boolean(data)
  const { data: classifications = [] } = useGetReplyClassificationsQuery()

  const {
    dirty,
    isValid,
    values: { name, categories },
    getFieldProps,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik<{ name: string; categories: string[] }>({
    initialValues: {
      name: data?.name ?? "",
      categories: data?.classifications ?? [],
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ name, categories }) => {
      const body = {
        name,
        classifications: categories,
      }
      isEdit
        ? onEditSuccess!({
            ...body,
            emails: data!.emails,
            oldName: data!.name,
          })
        : onAddSuccess!(body)
      handleClose()
    },
  })

  const handleSubCategoryToggle =
    (parent: string) =>
    ({ target: { checked, value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFieldValue(
        "categories",
        checked
          ? [...new Set(categories.concat(value, parent))]
          : [
              ...new Set(
                categories
                  .filter((v) => v !== value)
                  .filter((current, _, array) => {
                    if (current === parent) {
                      const sub = classifications.find(
                        ({ name }) => name === parent,
                      )?.sub
                      if (!sub) {
                        return true
                      }
                      const currentFromSub = sub.filter(({ name }) =>
                        array.find((v) => v === name),
                      )
                      return currentFromSub.length !== 0
                    }
                    return true
                  }),
              ),
            ],
      )
  const handleCategoryToggle = ({
    target: { checked, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      "categories",
      checked
        ? [
            ...new Set(
              categories.concat([
                value,
                ...(classifications
                  .find(({ name }) => name === value)!
                  .sub?.map(({ name }) => name) ?? []),
              ]),
            ),
          ]
        : categories.filter((v) => {
            if (v === value) {
              return false
            }
            const subCategoriesToRemove = classifications
              .find(({ name }) => name === value)!
              .sub?.map(({ name }) => name)

            return !subCategoriesToRemove?.find((deletedV) => deletedV === v)
          }),
    )
  }

  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }

  const title = isEdit
    ? `Edit ${data!.name} Forwarding Group`
    : "Add Forwarding Group"
  const submitBtnText = isEdit ? "Save" : "Add"
  const addDisabled =
    !(dirty && isValid) ||
    existingNames.findIndex((existingName) => existingName === name) !== -1
  const editDisabled = !(
    isValid &&
    dirty &&
    (!existingNames.find((v) => v === name) ||
      existingNames.find((v) => v === name) === data?.name)
  )

  return (
    <Modal
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={handleClose}
            className="text-black btn-sm !w-[86px]"
            type="button"
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={isEdit ? editDisabled : addDisabled}
            className="btn-sm !w-[86px]"
            type="submit"
          >
            {submitBtnText}
          </Button>
        </div>
      }
      title={title}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      className={cx("", className)}
      {...rest}
    >
      <div className="space-y-4">
        <TextField
          topLeftLabel="Group Name"
          className="input-sm rounded-md"
          rootClassName="[&_.label-text]:leading-[18px] [&_.label-text]:text-xs"
          placeholder="Enter group name"
          {...getFieldProps("name")}
        />
        <div className="space-y-1.5">
          <div className="caption opacity-100">Categories</div>
          <div className="flex flex-col gap-2.5">
            {classifications.map((data) => (
              <ClassificationCheckbox
                key={data._id}
                categories={categories}
                data={data}
                onSubToggle={handleSubCategoryToggle}
                onTopLevelToggle={handleCategoryToggle}
              />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddEditForwardingGroup
