import { FC, memo, useMemo, useState } from "react"
import cx from "classnames"
import { useNavigate } from "react-router-dom"
import Table from "@/components/Table"
import Chip from "@/components/Chip"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import AddMasterListModal from "../AddMasterListModal"
import DeleteMasterListModal from "../DeleteMasterListModal"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { MAX_CHIPS_COUNT } from "@/components/Table/constants"
import type { TablePaginationProps } from "@/redux/services/types"
import type { MasterList } from "@/types/entities/master-list"
import type { ColumnsType } from "antd/es/table"

type Props = {
  masterLists: MasterList[]
  className?: string
  total?: number
  pageSize: number
  page: number
  onPageSizeChange?: (pageSize: number) => void
  onPageChange?: (page: number) => void
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  name: string
  total: number
  available: number
  skipOrUsed: number
  campaigns: string[]
  searchCriteria: string
}

const MasterListsTable: FC<Props> = memo(
  ({ className, masterLists, ...rest }: Props) => {
    const { sm } = useBreakpoints()
    const navigate = useNavigate()
    const [edittedItemId, setEdittedItemId] = useState("")
    const [deletedItemId, setDeletedItemId] = useState("")
    const { pageFullAccess: MLAccess } = useUserAccess({
      pageName: "Master Lists",
    })
    const { pageAnyAccess: MLUpdateAnyAccess } = useUserAccess({
      pageName: "Master Lists Update",
    })
    const dataSource: TableDataType[] = useMemo(
      () =>
        masterLists.map(
          ({
            name,
            total,
            available,
            skip,
            used,
            campaign,
            _id,
            searchCriteria,
          }) => ({
            name,
            total,
            available,
            campaigns: campaign ? [campaign.name] : [],
            skipOrUsed: skip + used,
            searchCriteria: searchCriteria?.name ?? "",
            key: _id,
          }),
        ),
      [masterLists],
    )
    const columns: ColumnsType<TableDataType> = useMemo(() => {
      let resultCols: ColumnsType<TableDataType> = [
        {
          title: "List Name",
          dataIndex: "name",
          sorter: (a, b) => a.name.localeCompare(b.name),
          key: "name",
          width: 200,
        },
        {
          title: "Available",
          dataIndex: "available",
          sorter: (a, b) => a.available - b.available,
          key: "available",
          width: 140,
        },
        {
          title: "Used or Skipped",
          dataIndex: "skipOrUsed",
          sorter: (a, b) => a.skipOrUsed - b.skipOrUsed,
          key: "skipOrUsed",
          width: 160,
        },
        {
          title: "List Total",
          dataIndex: "total",
          sorter: (a, b) => a.total - b.total,
          key: "total",
          width: 140,
        },
        {
          title: "Search Criteria",
          key: "searchCriteria",
          dataIndex: "searchCriteria",
          sorter: (a, b) => a.searchCriteria.localeCompare(b.searchCriteria),
          width: 200,
        },
        {
          title: "Campaigns",
          dataIndex: "campaigns",
          sorter: (a, b) => a.campaigns.length - b.campaigns.length,
          key: "campaigns",
          width: 200,
          render: (_, { campaigns }) =>
            campaigns?.filter(Boolean).length ? (
              <div className="flex gap-1.5">
                {campaigns.slice(0, MAX_CHIPS_COUNT).map((campaign, i) => (
                  <Chip
                    className="hover:text-primary whitespace-nowrap"
                    key={i}
                  >
                    {campaign}
                  </Chip>
                ))}
                {campaigns.length > MAX_CHIPS_COUNT && (
                  <span className="text-sm leading-6 opacity-60">
                    +{campaigns.length - MAX_CHIPS_COUNT}
                  </span>
                )}
              </div>
            ) : (
              <span className="text-sm leading-6 opacity-40">None</span>
            ),
        },
      ]
      if (MLAccess) {
        resultCols = resultCols.concat({
          title: "",
          dataIndex: "actions",
          key: "actions",
          fixed: sm ? "right" : false,
          render: (_, { key }) => (
            <div className="actions hidden gap-4">
              <EditIcon
                onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
                  e.stopPropagation()
                  setEdittedItemId(key)
                }}
                className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
              />
              <BinIcon
                onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
                  setDeletedItemId(key)
                  e.stopPropagation()
                }}
                className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
              />
            </div>
          ),
          width: 96,
        })
      }
      return resultCols
    }, [MLAccess, sm])
    return (
      <>
        <Table
          dataSource={dataSource}
          columns={columns}
          showSizeChanger
          showHeader
          onRow={({ key }) => ({
            className: cx({
              "[&>td:first-child]:hover:text-primary cursor-pointer":
                MLUpdateAnyAccess,
            }),
            onClick: () => (MLUpdateAnyAccess ? navigate(key) : null),
          })}
          truncate
          {...rest}
        />
        <AddMasterListModal
          open={!!edittedItemId}
          onClose={() => setEdittedItemId("")}
          data={masterLists.find(({ _id }) => _id === edittedItemId)}
        />
        <DeleteMasterListModal
          open={!!deletedItemId}
          onClose={() => setDeletedItemId("")}
          data={masterLists.find(({ _id }) => _id === deletedItemId)}
        />
      </>
    )
  },
)

export default MasterListsTable
