import { useId, useState } from "react"
import cx from "classnames"
import { MdMoreVert as MoreIcon } from "react-icons/md"
import BellIcon from "@/assets/bell.svg"
import Popover from "../Popover"
import Checkbox from "../Checkbox"
import VerticalLineIcon from "@/assets/vertical-line.svg"
import Menu from "../Menu"
import NotificationItem from "./NotificationItem"
import { useNotifications } from "@/hooks/useNotifications"
import type {
  DeleteNotificationsPayload,
  Notification,
  UpdateNotificationPayload,
} from "@/types/entities/user"
import Loader from "../Loader"
import { useWindowSize } from "usehooks-ts"

const Notifications = () => {
  const {
    notifications,
    isError,
    isFetching,
    isLoading,
    updateNotifications,
    deleteNotifications,
    unreadNotificationsTotal,
    notificationsOpen,
    setNotificationsOpen,
    rootRef,
    sentryRef,
    isUninitialized,
  } = useNotifications()

  const selectAllId = useId()
  const { width: screenWidth } = useWindowSize()
  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<
    string[]
  >([])
  const fewSelected =
    selectedNotificationsIds.length !== 0 &&
    selectedNotificationsIds.length !== notifications.length
  const handleToggleAll = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedNotificationsIds(() =>
      checked ? notifications.map(({ _id }) => _id) : [],
    )

  const updateNotificationsStatus = (read: boolean) => async () => {
    const body: UpdateNotificationPayload = {
      read,
    }
    body.id = selectedNotificationsIds
    if (fewSelected) {
      body.all = false
    } else {
      body.id = notifications.map(({ _id }) => _id)
      body.all = true
    }
    const response = await updateNotifications(body)
    if (response?.success) {
      setSelectedNotificationsIds([])
    }
  }
  const handleMarkAsReadNotification = async (notification: Notification) => {
    try {
      await updateNotifications({
        read: true,
        id: [notification._id],
        all: false,
      })
    } catch (e) {
      console.error(e)
    }
  }
  const handleDeleteNotifications = async () => {
    const body: DeleteNotificationsPayload = {}
    body.id = selectedNotificationsIds
    if (fewSelected) {
      body.all = false
    } else {
      body.id = notifications.map(({ _id }) => _id)
      body.all = true
    }
    const response = await deleteNotifications(body)
    if (response?.success) {
      setSelectedNotificationsIds([])
    }
  }
  const toUpdateText = (read: boolean) =>
    fewSelected
      ? `Mark selected as ${read ? "read" : "unread"}`
      : `Mark all as ${read ? "read" : "unread"}`
  const toDeleteText = () => (fewSelected ? `Delete selected` : `Delete all`)

  if (isError) {
    return null
  }

  return (
    <div>
      <Popover
        rootClassName="w-[calc(100%-16px)] max-w-[400px] md:w-[400px]"
        title={<span className="text-xl font-semibold">Notifications</span>}
        open={notificationsOpen}
        placement={
          screenWidth >= 768 || screenWidth < 500 ? "bottom" : "bottomLeft"
        }
        onOpenChange={setNotificationsOpen}
        content={
          <>
            {isLoading ? (
              <div className="text-center pb-6">
                <Loader />
              </div>
            ) : (
              <div ref={rootRef}>
                <>
                  {notifications.length ? (
                    <div className="flex flex-col max-h-[300px] sm:max-h-[450px] min-h-[175px] overflow-y-auto">
                      <div className="sticky top-0 z-10 bg-white py-[9px] px-4 border-y border-y-outline flex justify-between">
                        <div className="flex items-center">
                          <Checkbox
                            id={selectAllId}
                            className="checkbox-primary h-[18px] w-[18px]"
                            checked={
                              selectedNotificationsIds.filter(
                                (selectedNotification) =>
                                  notifications.find(
                                    ({ _id }) => _id === selectedNotification,
                                  ),
                              ).length === notifications.length
                            }
                            indeterminate={
                              selectedNotificationsIds.length > 0 &&
                              selectedNotificationsIds.length <
                                notifications.length
                            }
                            onChange={handleToggleAll}
                          />
                          <label
                            className="ml-2 cursor-pointer opacity-60 text-xs sm:text-sm"
                            htmlFor={selectAllId}
                          >
                            {selectedNotificationsIds.length
                              ? `${selectedNotificationsIds.length} item${
                                  selectedNotificationsIds.length === 1
                                    ? ""
                                    : "s"
                                }`
                              : `Select all`}
                          </label>
                        </div>
                        <div className="flex gap-2 items-center">
                          {unreadNotificationsTotal > 0 && (
                            <span
                              onClick={updateNotificationsStatus(true)}
                              className="text-xs sm:text-sm text-primary cursor-pointer hover:underline"
                            >
                              {toUpdateText(true)}
                            </span>
                          )}
                          <VerticalLineIcon />
                          <Menu
                            className="[&_ul]:right-4"
                            items={[
                              <span
                                onClick={updateNotificationsStatus(false)}
                                className="text-sm leading-6"
                              >
                                {toUpdateText(false)}
                              </span>,
                              <span
                                onClick={handleDeleteNotifications}
                                className="text-sm leading-6"
                              >
                                {toDeleteText()}
                              </span>,
                            ]}
                            noCaret
                          >
                            <MoreIcon className="w-5 h-5 opacity-60 hover:opacity-100" />
                          </Menu>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        {notifications.map((notification) => (
                          <NotificationItem
                            key={notification._id}
                            notification={notification}
                            checked={Boolean(
                              selectedNotificationsIds.find(
                                (selectedNotification) =>
                                  selectedNotification === notification._id,
                              ),
                            )}
                            isRead={Boolean(notification.readAt)}
                            onToggle={(checked, { _id }) => {
                              setSelectedNotificationsIds((prev) =>
                                checked
                                  ? prev.concat(_id)
                                  : prev.filter((curr) => curr !== _id),
                              )
                            }}
                            onUnreadClicked={handleMarkAsReadNotification}
                          />
                        ))}
                        {isFetching && (
                          <div className="flex justify-center py-1">
                            <Loader className="text-center" />
                          </div>
                        )}
                        <div ref={sentryRef}></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {notifications.length === 0 &&
                      !(isLoading || isFetching || isUninitialized) ? (
                        <div className="border-t border-outline text-sm py-[9px] leading-6 text-center">
                          You don't have any notifications yet.
                        </div>
                      ) : (
                        <div className="text-center pb-6">
                          <Loader />
                        </div>
                      )}
                      <div ref={sentryRef}></div>
                    </>
                  )}
                </>
              </div>
            )}
          </>
        }
      >
        <div>
          <BellIcon
            className={cx(
              "cursor-pointer [&_circle]:transition-opacity [&_circle]:duration-200 ease-in",
              {
                "[&_circle]:opacity-100": unreadNotificationsTotal > 0,
                "[&_circle]:opacity-0": !unreadNotificationsTotal,
              },
            )}
          />
        </div>
      </Popover>
    </div>
  )
}

export default Notifications
