import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export const useDelayedNavigate = (path: string, delay = 3000) => {
  const [canNavigate, setCanNavigate] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      setCanNavigate(true)
    }, delay)
    if (canNavigate) {
      navigate(path)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [canNavigate, delay, navigate, path])

  return { canNavigate }
}
