import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDisconnectGsuiteAccountMutation } from "@/redux/services/gsuitesApi"
import { setToast } from "@/redux/toastSlice"

export const useDisconnectGsuiteAccount = () => {
  const dispatch = useAppDispatch()
  const [disconnectGsuiteAccount, response] =
    useDisconnectGsuiteAccountMutation()

  const handleDisconnectGsuiteAccount = async (id: string) => {
    try {
      const response = await disconnectGsuiteAccount({ id }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success disconnect account",
          }),
        )
      }
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { disconnectMail: handleDisconnectGsuiteAccount, response }
}
