import { FC, useState } from "react"
import cx from "classnames"
import Collapse from "@/components/Collapse"
import { getNumberEnding } from "@/utils/numbers"
import Tabs from "@/components/Tabs"
import Tab from "@/components/Tab"
import CampaignMessagingEmailVersion from "../CampaignMessagingEmailVersion"
import type { EmailAPIResponse } from "@/types/entities/sequence"

type Props = {
  className?: string
  email: EmailAPIResponse
  emailIndex: number
  firstDisabledIndex: -1 | number
  sequenceId: string
}

const CampaignMessagingEmailSection: FC<Props> = ({
  email,
  emailIndex,
  firstDisabledIndex = -1,
  sequenceId,
}: Props) => {
  const [activeVersionIndex, setActiveVersionIndex] = useState(0)

  return (
    <Collapse
      className="[&_.collapse-content]:overflow-x-auto [&_.collapse-content]:px-0"
      title={
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-1">
            <span className="flex gap-2.5 items-center">
              <span>
                {emailIndex + 1}
                {getNumberEnding(emailIndex + 1)} Email
              </span>
            </span>
          </div>
          {email.delay && (
            <div className="flex text-xs font-normal opacity-60">
              Send {email.delay} {email.delayUnit} after previous
            </div>
          )}
        </div>
      }
    >
      <Tabs className="mt-6">
        {email.versions.map((version, versionIndex) => (
          <Tab
            key={versionIndex}
            tabKey={versionIndex}
            className={cx("tab-lifted font-semibold min-w-[150px]", {
              "ml-5": !versionIndex,
              "cursor-default": email.versions.length === 1,
            })}
            active={activeVersionIndex === versionIndex}
            onSelect={(tabKey) => setActiveVersionIndex(+tabKey)}
            label={
              <span className="flex gap-3 whitespace-nowrap">
                <span>Version {versionIndex + 1}</span>
              </span>
            }
          >
            <CampaignMessagingEmailVersion version={version} />
          </Tab>
        ))}
      </Tabs>
    </Collapse>
  )
}

export default CampaignMessagingEmailSection
