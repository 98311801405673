import { createApi } from "@reduxjs/toolkit/query/react"
import { buildQueryString } from "@/utils/strings"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import { addAndEditArrayById } from "@/utils/arrays"
import type { PaginationProps } from "./types"
import type { Inbox } from "@/types/entities/inbox"

export type UpdateInboxPayload = {
  id: string[]
  read: boolean
  all?: boolean
}

export const inboxApi = createApi({
  reducerPath: "inboxApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Inbox"],
  endpoints: (builder) => ({
    getInboxes: builder.query<
      { items: Inbox[]; total: number },
      {
        org: string
        campaign?: string[]
        classifications?: string[]
        sort?: 1 | -1
      } & PaginationProps & { text?: string }
    >({
      query: ({ ...keys }) => ({
        method: "GET",
        url: `inbox/all?${buildQueryString(keys)}`,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.org !== previousArg?.org ||
          currentArg?.sort !== previousArg?.sort ||
          currentArg?.skip !== previousArg?.skip ||
          currentArg?.campaign !== previousArg?.campaign ||
          currentArg?.classifications !== previousArg?.classifications ||
          currentArg?.text !== previousArg?.text
        )
      },
      merge: (currentCacheData, responseData) => {
        currentCacheData.items = addAndEditArrayById({
          currentArray: currentCacheData.items,
          newItems: responseData.items,
        })
        currentCacheData.total = responseData.total
      },
      onQueryStarted: async (arg, { dispatch }) => {
        if (arg.skip === 0) {
          dispatch(
            inboxApi.util.updateQueryData("getInboxes", arg, () => ({
              items: [],
              total: 0,
            })),
          )
        }
      },
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: Inbox[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Inbox" as const,
                _id,
              })),
              { type: "Inbox", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Inbox", id: "PARTIAL-LIST" }],
    }),
    forwardInbox: builder.mutation<
      Inbox,
      { id: string; body: { emails: string[] } }
    >({
      query: ({ body, id }) => ({
        method: "POST",
        url: `inbox/${id}/forward`,
        body,
      }),
      invalidatesTags: ["Inbox"],
      transformResponse: ({ data }: { data: Inbox }) => data,
    }),
    deleteInboxResponse: builder.mutation<Inbox, string[]>({
      query: (id) => ({
        url: `inbox?${buildQueryString({ id })}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Inbox"],
      transformResponse: ({ data }: { data: Inbox }) => data,
    }),
    updateInbox: builder.mutation<{ success: boolean }, UpdateInboxPayload>({
      query: (body) => ({
        url: "inbox",
        method: "PUT",
        body,
      }),
      transformResponse: ({ data }: { data: { success: boolean } }) => data,
      onQueryStarted: async (
        { id: ids, read },
        { dispatch, queryFulfilled },
      ) => {
        try {
          const { data: response } = await queryFulfilled
          if (response.success) {
            dispatch(
              inboxApi.util.updateQueryData(
                "getInboxes",
                { org: "" },
                (draft) => {
                  Object.assign(draft, {
                    items: draft.items.map((inbox) =>
                      ids?.find((updatedId) => updatedId === inbox._id)
                        ? {
                            ...inbox,
                            readAt: read ? new Date().toISOString() : undefined,
                          }
                        : inbox,
                    ),
                  })
                },
              ),
            )
          }
        } catch (e) {
          console.error(e)
        }
      },
    }),
  }),
})

export const {
  useGetInboxesQuery,
  useForwardInboxMutation,
  useDeleteInboxResponseMutation,
  useUpdateInboxMutation,
} = inboxApi
