import { FC, useEffect, useState } from "react"
import cx from "classnames"
import Drawer from "@/components/Drawer"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import RadioGroup from "@/components/RadioGroup"
import Radio from "@/components/Radio"
import Checkbox from "@/components/Checkbox"
import TextField from "@/components/TextField"
import SearchIcon from "@/assets/search.svg"
import ClassificationCheckbox from "@/pages/AddEditCampaign/CampaignForwardingView/ClassificationCheckbox"
import { useInboxFilters } from "../../hooks/useInboxFilters"
import { useGetCampaignsQuery } from "@/redux/services/campaignsApi"
import { useParams } from "react-router-dom"
import { useGetReplyClassificationsQuery } from "@/redux/services/generalApi"
import { INBOX_FILTERS_KEY, INBOX_SORT_KEY } from "../../context"
import { MAX_CLASSIFICATIONS_LENGTH } from "@/constants"
import { useUserAccess } from "@/hooks/useUserAccess"
import type { SortOptions } from "@/types/shared"

type Props = {
  className?: string
  open: boolean
  onClose: () => void
}

const InboxFiltersDrawer: FC<Props> = ({
  className,
  open,
  onClose,
  ...rest
}: Props) => {
  const { id: orgId = "" } = useParams()
  const { filters, setFilters, sort, setSort, setSkip } = useInboxFilters()
  const { data: { items: campaigns } = { items: [] } } = useGetCampaignsQuery({
    orgId,
  })
  const { data: classifications = [] } = useGetReplyClassificationsQuery()
  const [campaignsSearchTerm, setCampaignsSearchTerm] = useState("")
  const [localFilters, setLocalFilters] = useState<Record<string, any>>({})
  const [localSort, setLocalSort] = useState<SortOptions>(-1)
  const { pageFullAccess } = useUserAccess({ pageName: "Inbox" })
  useEffect(() => {
    if (open) {
      setLocalFilters(filters)
      setLocalSort(sort)
    } else {
      setCampaignsSearchTerm("")
      resetFilters()
    }
  }, [open, filters, sort])
  const handleFiltersChange = ({
    target: { checked, value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setLocalFilters((oldPrev: Record<string, any>) => {
      const prev = JSON.parse(JSON.stringify(oldPrev))
      if (checked) {
        if (!prev[name]) {
          prev[name] = []
        }
        prev[name].push(value)
        prev[name] = new Set(prev[name]) // filtering same values
        prev[name] = [...prev[name]]
      } else {
        prev[name] = prev[name].filter((v: string) => v !== value)
        if (!prev[name].length) {
          delete prev[name]
        }
      }
      return prev
    })
  const handleClassificationToggle = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { checked, value },
    } = e
    const newClassifications = checked
      ? [
          ...new Set(
            (localFilters.classifications ?? []).concat([
              value,
              ...(classifications
                .find(({ name }) => name === value)!
                .sub?.map(({ name }) => name) ?? []),
            ]),
          ),
        ]
      : ((localFilters.classifications ?? []) as string[]).filter((v) => {
          if (v === value) {
            return false
          }
          const subCategoriesToRemove = classifications
            .find(({ name }) => name === value)!
            .sub?.map(({ name }) => name)

          return !subCategoriesToRemove?.find((deletedV) => deletedV === v)
        })
    if (newClassifications.length > MAX_CLASSIFICATIONS_LENGTH) {
      e.preventDefault()
      return
    }
    setLocalFilters((prev) => ({
      ...prev,
      classifications: newClassifications,
    }))
  }
  const handleSubClassificationToggle =
    (parent: string) =>
    ({ target: { checked, value } }: React.ChangeEvent<HTMLInputElement>) => {
      const subCategoriesOfParent = classifications
        .find(({ name }) => name === parent)!
        .sub?.map(({ name }) => name)

      const newClassifications = checked
        ? [
            ...new Set(
              (localFilters.classifications ?? []).concat(
                value,
                (localFilters.classifications ?? [])
                  .concat(value)
                  .reduce((acc: number, curr: string) => {
                    if (subCategoriesOfParent?.find((v) => v === curr)) {
                      return acc + 1
                    }
                    return acc
                  }, 0) === subCategoriesOfParent?.length
                  ? parent
                  : [],
              ),
            ),
          ]
        : [
            ...new Set(
              ((localFilters.classifications ?? []) as string[])
                .filter((v) => v !== value)
                .filter((current) => {
                  if (current === parent) {
                    return false
                  }
                  return true
                }),
            ),
          ]
      if (newClassifications.length > MAX_CLASSIFICATIONS_LENGTH) {
        return
      }
      setLocalFilters((prev) => ({
        ...prev,
        classifications: newClassifications,
      }))
    }

  const resetFilters = () => {
    setLocalFilters({})
    setLocalSort(-1)
  }
  const applyFilters = () => {
    setFilters(localFilters)
    sessionStorage.setItem(INBOX_FILTERS_KEY, JSON.stringify(localFilters))
    setSort(localSort)
    sessionStorage.setItem(INBOX_SORT_KEY, JSON.stringify(localSort))
    // dispatch(
    //   inboxApi.util.updateQueryData("getInboxes", { org: "" }, (draft) => {
    //     Object.assign(draft, {
    //       items: [],
    //     })
    //   }),
    // )
    setSkip(0)
    onClose()
  }

  return (
    <Drawer
      title="Filters"
      open={open}
      onClose={onClose}
      className={cx("relative text-black", className)}
      {...rest}
    >
      <div className="flex flex-col gap-4 pb-15">
        <div>
          <h4 className="text-lg font-semibold">Sort by</h4>
          <div className="mt-2">
            <RadioGroup
              value={localSort}
              onChange={({ target: { value } }) => setLocalSort(value)}
              className="flex flex-col gap-3.5"
            >
              <Radio value={-1}>Newest first</Radio>
              <Radio value={1}>Oldest first</Radio>
            </RadioGroup>
          </div>
        </div>
        <div>
          <h4 className="text-lg font-semibold">Classifications</h4>
          <div className="mt-2 flex flex-col gap-2.5">
            {classifications.map((classification) => (
              <ClassificationCheckbox
                key={classification._id}
                name="classifications"
                categories={localFilters.classifications ?? []}
                data={{
                  ...classification,
                  sub: pageFullAccess
                    ? classification.sub
                    : classification.sub?.filter(
                        (v) => !v.name.toLowerCase().includes("hostile"),
                      ),
                }}
                onSubToggle={handleSubClassificationToggle}
                onTopLevelToggle={handleClassificationToggle}
              />
            ))}
          </div>
        </div>
        <div className="border-b border-outline relative -left-4 w-[calc(100%+32px)]"></div>
        <div className="flex flex-col gap-2">
          <h4 className="text-lg font-semibold">Campaigns</h4>
          <TextField
            value={campaignsSearchTerm}
            onChange={({ target: { value } }) => setCampaignsSearchTerm(value)}
            className="input-base text-sm"
            startIcon={<SearchIcon />}
            placeholder="Search"
          />
          <div className="flex flex-col gap-2">
            {campaigns
              .filter(({ name }) =>
                campaignsSearchTerm
                  ? name
                      .toLocaleLowerCase()
                      .includes(campaignsSearchTerm.trim().toLocaleLowerCase())
                  : true,
              )
              .map(({ name, _id }) => (
                <span key={_id} className="mt-2.5 flex items-center">
                  <Checkbox
                    id={_id + "inbox-filter-campaign"}
                    className="checkbox-primary w-[18px] h-[18px] mr-3"
                    name="campaigns"
                    checked={Boolean(
                      localFilters?.campaigns?.find(
                        (v: string) => v === _id.toString(),
                      ),
                    )}
                    value={_id}
                    onChange={handleFiltersChange}
                  />
                  <label
                    className="cursor-pointer"
                    htmlFor={_id + "inbox-filter-campaign"}
                  >
                    {name}
                  </label>
                </span>
              ))}
          </div>
        </div>
      </div>
      <footer className="absolute bottom-0 left-0 right-0 bg-bg-default py-3 px-4 flex justify-end gap-3">
        <OutlineButton
          onClick={resetFilters}
          className="btn-base text-black !px-4"
        >
          Reset
        </OutlineButton>
        <Button onClick={applyFilters} className="btn-base !px-4">
          Apply Filter
        </Button>
      </footer>
    </Drawer>
  )
}

export default InboxFiltersDrawer
