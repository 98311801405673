import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

export const useDuplicateSequence = () => {
  const navigate = useNavigate()

  const duplicateSequence = useCallback(
    async (id: string) => {
      navigate(`create?duplicate=${id}`)
    },
    [navigate],
  )

  return { duplicateSequence }
}
