import { useUpdateCampaignStatusMutation } from "@/redux/services/campaignsApi"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import type { CampaignStatus } from "@/types/entities/campaign"

export const useUpdateCampaignStatus = () => {
  const dispatch = useAppDispatch()
  const [updateCampaignStatus, response] = useUpdateCampaignStatusMutation()

  const handleUpdateCampaignStatus = async ({
    id,
    body,
  }: {
    id: string
    body: {
      status: CampaignStatus
    }
  }) => {
    try {
      const response = await updateCampaignStatus({ id, body }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success update campaign status",
        }),
      )
      if (response) {
        return response
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { updateCampaignStatus: handleUpdateCampaignStatus, response }
}
