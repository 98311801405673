import USSenderAddressForm from "./USSenderAddressForm"
import CanadaSenderAddressForm from "./CanadaSenderAddressForm"
import OtherSenderAddressForm from "./OtherSenderAddressForm"
import type { SenderCountryCode } from "@/types/entities/sender"

export const senderCountriesMap: Record<
  SenderCountryCode | "",
  React.ReactNode
> = {
  "": null,
  US: <USSenderAddressForm />,
  CA: <CanadaSenderAddressForm />,
  Other: <OtherSenderAddressForm />,
}
