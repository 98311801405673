import { useParams } from "react-router-dom"
import { useGetCurrentUserQuery } from "@/core/api/services"
import type { Organization } from "@/types/entities/organization"

export const useStatusOrganizations = () => {
  const { data: user = { organizations: [] }, ...rest } =
    useGetCurrentUserQuery({
      orgStatus: "all",
    })
  const { id = "" } = useParams()

  const organizations = user.organizations as Organization[]

  const currentOrg = organizations.find(({ _id }) => _id === id) ?? null

  return {
    currentOrg,
    currentOrgActive: currentOrg?.status === "enabled",
    all: organizations,
    active: organizations.filter(({ status }) => status === "enabled"),
    disabled: organizations.filter(({ status }) => status === "disabled"),
    ...rest,
  }
}
