import { FC, useEffect } from "react"
import cx from "classnames"
import TextField from "@/components/TextField"
import Select from "@/components/Select"
import Loader from "@/components/Loader"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import { useAppSelector } from "@/redux/hooks"
import {
  selectDomainSetupEmailProvider,
  selectDomainSetupId,
  selectDomainSetupName,
  selectDomainSetupProvider,
  selectDomainSetupUrlRedirect,
} from "@/redux/domainSetupSlice"
import { useDomainSetup } from "../../hooks/useDomainSetup"
import { useGetDomainQuery } from "@/redux/services/domainsApi"
import { useUserAccess } from "@/hooks/useUserAccess"
import { url } from "@/core/validation"

type Props = {
  className?: string
  onDisabledChange: (disabled: boolean) => void
}

const DomainFormView: FC<Props> = ({
  className,
  onDisabledChange,
  ...rest
}: Props) => {
  const { pageFullAccess: domainsUpdateFullAccess } = useUserAccess({
    pageName: "Domains Update",
  })
  const name = useAppSelector(selectDomainSetupName)
  const urlRedirect = useAppSelector(selectDomainSetupUrlRedirect)
  const provider = useAppSelector(selectDomainSetupProvider)
  const emailProvider = useAppSelector(selectDomainSetupEmailProvider)
  const domainId = useAppSelector(selectDomainSetupId)
  const {
    changeName,
    changeUrlRedirect,
    changeProvider,
    changeEmailProvider,
    setupError,
  } = useDomainSetup()
  const {
    data: { domainProviders, emailProviders } = {
      domainProviders: [],
      emailProviders: [],
    },
    isLoading: systemValuesLoading,
  } = useGetSystemValuesQuery()
  const { data: domain } = useGetDomainQuery(
    { id: domainId },
    { skip: !domainId },
  )
  useEffect(() => {
    try {
      onDisabledChange(
        !name || !provider || !emailProvider || !url.isValidSync(urlRedirect),
      )
    } catch (e) {
      onDisabledChange(true)
    }
  }, [onDisabledChange, emailProvider, name, provider, urlRedirect])

  return (
    <div
      className={cx(
        "flex flex-col gap-5",
        {
          "items-center": systemValuesLoading,
          faded: !domainsUpdateFullAccess,
        },
        className,
      )}
      {...rest}
    >
      {systemValuesLoading ? (
        <Loader />
      ) : (
        <>
          <h2 className="text-lg font-semibold leading-7">Domain</h2>
          <div
            className={cx(
              "flex flex-col gap-5 [&_.label-text]:text-xs [&_.label]:pl-0 [&_.label-text]:pl-0",
            )}
          >
            <TextField
              value={name}
              onChange={({ target: { value } }) => changeName(value)}
              topLeftLabel="Domain Name"
              placeholder="sample.com"
              className="input-sm disabled:cursor-not-allowed"
              disabled={!setupError && Boolean(domain?.name)}
            />
            <TextField
              value={urlRedirect}
              onChange={({ target: { value } }) => changeUrlRedirect(value)}
              topLeftLabel="URL Redirect"
              placeholder="https://sample.com"
              className="input-sm disabled:cursor-not-allowed"
              disabled={!setupError && Boolean(domain?.urlRedirect)}
              type="url"
            />
            <Select
              value={provider || null}
              onChange={changeProvider}
              label="Registrar"
              className="h-10 bg-input rounded-md [&.ant-select-disabled]:!bg-semi [&.ant-select-disabled]:border-outline [&.ant-select-disabled]:border "
              placeholder="Select provider"
              bordered={false}
              disabled={!setupError && Boolean(domain?.provider)}
              options={domainProviders.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
            <Select
              value={emailProvider || null}
              onChange={changeEmailProvider}
              label="Email Provider"
              className="h-10 bg-input rounded-md [&.ant-select-disabled]:!bg-semi [&.ant-select-disabled]:border-outline [&.ant-select-disabled]:border "
              placeholder="Select email provider"
              bordered={false}
              disabled={
                !setupError &&
                Boolean(
                  domain?.actions?.recordsSetupAt || domain?.emailProvider,
                )
              }
              options={emailProviders.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default DomainFormView
