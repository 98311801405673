import { FC } from "react"
import TextField from "../TextField"
import OutlineButton from "../OutlineButton"
import { BiPlus as AddIcon } from "react-icons/bi"
import Textarea from "../Textarea"
import CancelIcon from "@/assets/cancel.svg"
import FormFieldErrorMessage from "../FormFieldErrorMessage"
import { useEmailSequenceValues } from "@/hooks/useEmailSequenceValues"
import { useUserAccess } from "@/hooks/useUserAccess"
import { cx } from "@/utils/strings"
import { getNestedFieldErrorMessage } from "./utils"
import type {
  SequenceEmailError as eR,
  SequenceEmailVersionError as eVR,
} from "@/hooks/useEmailSequenceFormik"

type Props = {
  emailIndex: number
  versionIndex: number
}

const SequenceEmailVersion: FC<Props> = ({
  emailIndex,
  versionIndex,
}: Props) => {
  const {
    getFieldProps,
    values,
    setValues,
    errors: { emails: emailsError },
    touched: { emails: emailsTouched },
  } = useEmailSequenceValues()

  const { pageFullAccess: sequencesUpdateFullAccess } = useUserAccess({
    pageName: "Sequences Update",
  })

  const handleAddField = (fieldName: string) => {
    return () => {
      setValues((prev) => ({
        ...prev,
        emails: prev.emails.map((email, emailI) =>
          emailI === emailIndex
            ? {
                ...email,
                versions: email.versions.map((version, versionI) =>
                  versionI === versionIndex
                    ? {
                        ...version,
                        [fieldName]: (version[
                          fieldName as keyof typeof version
                        ] as Array<string>)!.concat(""),
                      }
                    : version,
                ),
              }
            : email,
        ),
      }))
    }
  }
  const handleDeleteField = (fieldName: string, removedIndex: number) => {
    return () => {
      setValues((prev) => ({
        ...prev,
        emails: prev.emails.map((email, emailI) =>
          emailI === emailIndex
            ? {
                ...email,
                versions: email.versions.map((version, versionI) =>
                  versionI === versionIndex
                    ? {
                        ...version,
                        [fieldName]: (version[
                          fieldName as keyof typeof version
                        ] as Array<string>)!.filter(
                          (_, i) => i !== removedIndex,
                        ),
                      }
                    : version,
                ),
              }
            : email,
        ),
      }))
    }
  }
  const version = values.emails[emailIndex].versions[versionIndex]
  const versionPath = `emails[${emailIndex}].versions[${versionIndex}]`

  return (
    <div
      className={cx("flex flex-col gap-5", {
        faded: !sequencesUpdateFullAccess,
      })}
    >
      {emailIndex === 0 && (
        <div className="flex flex-col gap-3 text-black">
          <div className="flex gap-3 flex-wrap">
            {version.subjects.map((_, subjectIndex, subjects) => (
              <TextField
                key={subjectIndex}
                rootClassName="basis-80"
                className="input-sm"
                topLeftLabel="Subject"
                placeholder="Email subject"
                autoComplete="new-password"
                type="text"
                suffixIcon={
                  subjects.length > 1 ? (
                    <CancelIcon
                      onClick={handleDeleteField("subjects", subjectIndex)}
                      className="w-5 h-5 cursor-pointer transition-colors hover:text-error -translate-y-3 translate-x-3"
                    />
                  ) : null
                }
                bottomLeftLabel={
                  <FormFieldErrorMessage
                    error={getNestedFieldErrorMessage({
                      emailIndex,
                      emailsError,
                      versionIndex,
                      itemIndex: subjectIndex,
                      fieldName: "subjects",
                    })}
                    touched={
                      emailsTouched &&
                      Array.isArray(emailsTouched[emailIndex]?.versions) &&
                      Array.isArray(
                        (emailsTouched[emailIndex].versions as eVR)[
                          versionIndex
                        ]?.subjects,
                      )
                        ? !!(emailsTouched[emailIndex].versions as eVR)[
                            versionIndex
                          ].subjects![subjectIndex]
                        : false
                    }
                  />
                }
                {...getFieldProps(`${versionPath}.subjects[${subjectIndex}]`)}
              />
            ))}
          </div>
          <OutlineButton
            type="button"
            onClick={handleAddField("subjects")}
            className="btn-base"
          >
            <AddIcon className="h-6 w-6 text-black" />
            <span className="text-black text-sm leading-6">Add Another</span>
          </OutlineButton>
        </div>
      )}
      <div className="flex flex-col gap-3 text-black">
        <div className="flex gap-3 flex-wrap">
          {version.greetings.map((_, greetingIndex, greetings) => (
            <Textarea
              key={greetingIndex}
              rootClassName="basis-80"
              topLeftLabel="Greeting"
              placeholder="Hi #Recipient_FirstName#"
              className="w-full resize-none leading-6 !h-20"
              suffixIcon={
                greetings.length > 1 ? (
                  <CancelIcon
                    onClick={handleDeleteField("greetings", greetingIndex)}
                    className="w-5 h-5 cursor-pointer transition-colors hover:text-error -translate-y-1 translate-x-1"
                  />
                ) : null
              }
              bottomLeftLabel={
                <FormFieldErrorMessage
                  error={getNestedFieldErrorMessage({
                    emailIndex,
                    emailsError,
                    versionIndex,
                    itemIndex: greetingIndex,
                    fieldName: "greetings",
                  })}
                  touched={
                    emailsTouched &&
                    Array.isArray(emailsTouched[emailIndex]?.versions) &&
                    Array.isArray(
                      (emailsTouched[emailIndex].versions as eVR)[versionIndex]
                        ?.greetings,
                    )
                      ? !!(emailsTouched[emailIndex].versions as eVR)[
                          versionIndex
                        ].greetings![greetingIndex]
                      : false
                  }
                />
              }
              {...getFieldProps(`${versionPath}.greetings[${greetingIndex}]`)}
            />
          ))}
        </div>
        <OutlineButton
          type="button"
          onClick={handleAddField("greetings")}
          className="btn-base"
        >
          <AddIcon className="h-6 w-6 text-black" />
          <span className="text-black text-sm leading-6">Add Another</span>
        </OutlineButton>
      </div>
      <Textarea
        topLeftLabel="Email Content"
        placeholder="Enter email content here"
        className="min-h-[240px]"
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={
              Array.isArray(emailsError)
                ? Array.isArray((emailsError as eR)[emailIndex]?.versions)
                  ? ((emailsError as eR)[emailIndex]?.versions as eVR)[
                      versionIndex
                    ]?.body
                  : null
                : null
            }
            touched={
              emailsTouched &&
              Array.isArray(emailsTouched[emailIndex]?.versions) &&
              !!(emailsTouched[emailIndex].versions as eVR)[versionIndex]?.body
            }
          />
        }
        {...getFieldProps(`${versionPath}.body`)}
      />
      <div className="flex flex-col gap-3 text-black">
        <div className="flex gap-3 flex-wrap">
          {version.signoffs.map((_, signoffIndex, signoffs) => (
            <Textarea
              key={signoffIndex}
              rootClassName="basis-80"
              topLeftLabel="Sign Off"
              placeholder={"Thank you,\n#Sender_FirstName#"}
              className="w-full resize-none leading-6 !h-20"
              suffixIcon={
                signoffs.length > 1 ? (
                  <CancelIcon
                    onClick={handleDeleteField("signoffs", signoffIndex)}
                    className="w-5 h-5 cursor-pointer transition-colors hover:text-error -translate-y-1 translate-x-1"
                  />
                ) : null
              }
              bottomLeftLabel={
                <FormFieldErrorMessage
                  error={getNestedFieldErrorMessage({
                    emailIndex,
                    emailsError,
                    versionIndex,
                    itemIndex: signoffIndex,
                    fieldName: "signoffs",
                  })}
                  touched={
                    emailsTouched &&
                    Array.isArray(emailsTouched[emailIndex]?.versions) &&
                    Array.isArray(
                      (emailsTouched[emailIndex].versions as eVR)[versionIndex]
                        ?.signoffs,
                    )
                      ? !!(emailsTouched[emailIndex].versions as eVR)[
                          versionIndex
                        ].signoffs![signoffIndex]
                      : false
                  }
                />
              }
              {...getFieldProps(`${versionPath}.signoffs[${signoffIndex}]`)}
            />
          ))}
        </div>
        <OutlineButton
          type="button"
          onClick={handleAddField("signoffs")}
          className="btn-base"
        >
          <AddIcon className="h-6 w-6 text-black" />
          <span className="text-black text-sm leading-6">Add Another</span>
        </OutlineButton>
      </div>
      {emailIndex === 0 && (
        <div className="flex flex-col gap-3 text-black">
          <div className="flex gap-3 flex-wrap">
            {version.unsubs?.map((_, unsubIndex, unsubs) => (
              <Textarea
                key={unsubIndex}
                rootClassName="basis-80"
                topLeftLabel="Unsub Text"
                placeholder="Unsubscription message"
                className="w-full resize-none leading-6 !h-20"
                suffixIcon={
                  unsubs.length > 1 ? (
                    <CancelIcon
                      onClick={handleDeleteField("unsubs", unsubIndex)}
                      className="w-5 h-5 cursor-pointer transition-colors hover:text-error -translate-y-1 translate-x-1"
                    />
                  ) : null
                }
                bottomLeftLabel={
                  <FormFieldErrorMessage
                    error={getNestedFieldErrorMessage({
                      emailIndex,
                      emailsError,
                      versionIndex,
                      itemIndex: unsubIndex,
                      fieldName: "unsubs",
                    })}
                    touched={
                      emailsTouched &&
                      Array.isArray(emailsTouched[emailIndex]?.versions) &&
                      Array.isArray(
                        (emailsTouched[emailIndex].versions as eVR)[
                          versionIndex
                        ]?.unsubs,
                      )
                        ? !!(emailsTouched[emailIndex].versions as eVR)[
                            versionIndex
                          ].unsubs![unsubIndex]
                        : false
                    }
                  />
                }
                {...getFieldProps(`${versionPath}.unsubs[${unsubIndex}]`)}
              />
            ))}
          </div>
          <OutlineButton
            type="button"
            onClick={handleAddField("unsubs")}
            className="btn-base"
          >
            <AddIcon className="h-6 w-6 text-black" />
            <span className="text-black text-sm leading-6">Add Another</span>
          </OutlineButton>
        </div>
      )}
    </div>
  )
}

export default SequenceEmailVersion
