import { ErrorResponse } from "@/types/error-response"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
  error: unknown,
): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as any).message === "string"
  )
}

export function extractErrorsToString(
  errors: { msg: string; param: string }[],
) {
  return errors.map(({ msg, param }) => `${msg} (param: ${param})`).join(", ")
}

export function getErrorMessage(error: unknown, showInvalidFields = false) {
  if (isFetchBaseQueryError(error)) {
    if (showInvalidFields && Array.isArray((error as any).data.errors)) {
      return `${
        (error.data as ErrorResponse).message
      } - ${extractErrorsToString((error as any).data.errors)}`
    }
    const errMsg =
      "error" in error ? error.error : (error.data as ErrorResponse).message
    return errMsg
  } else if (isErrorWithMessage(error)) {
    return error.message
  }
  return "Something went wrong"
}
