import { saveAs } from "file-saver"
import { getContentDispositionFileName } from "./strings"

export const exportFileResponseHandler = async (
  response: Response,
  defaultFileName: string,
) => {
  if (response.status === 200) {
    const fileName = getContentDispositionFileName(response, defaultFileName)
    const blobResponse = await response.blob()
    saveAs(blobResponse, fileName)
  }
  return response
}
