import { FC, useEffect, useState } from "react"
import SelectPlatformModal from "@/components/SelectPlatformModal"
import { SpamAccountLoginContext } from "./context"
import AddEditGoogleSpamAccountModal from "./AddEditGoogleSpamAccountModal"
import AddEditMicrosoftSpamAccountModal from "./AddEditMicrosoftSpamAccountModal"
import type { EmailPlatform } from "@/types/entities/gsuite-account"
import type { ModalProps } from "@/components/Modal"
import type { AdminSpamAccount } from "@/types/entities/spam-account"

type Props = {
  className?: string
  data?: AdminSpamAccount
} & ModalProps

const AddEditSpamAccountModal: FC<Props> = ({ open, onClose, data }: Props) => {
  const [selectedPlatform, setSelectedPlatform] = useState<"" | EmailPlatform>(
    "",
  )
  const isEdit = Boolean(data)
  useEffect(() => {
    if (data) {
      setSelectedPlatform(data.provider)
    }
  }, [data])
  const handleClose = () => {
    setSelectedPlatform("")
    onClose && onClose()
  }
  const handleSelectPlatform = (v: EmailPlatform) => {
    setSelectedPlatform(v)
    onClose && onClose()
  }
  const title = isEdit ? "Edit Spam Test Account" : "Add Spam Test Account"
  const submitBtnText = isEdit ? "Save" : "Add"
  return (
    <SpamAccountLoginContext.Provider
      value={{
        title,
        submitBtnText,
      }}
    >
      <SelectPlatformModal
        open={open && !isEdit}
        onClose={handleClose}
        onPlatformSelect={handleSelectPlatform}
      />
      {selectedPlatform === "google" && (
        <AddEditGoogleSpamAccountModal
          data={data}
          open
          onClose={handleClose}
          onErrorClose={() => {
            const modal = document.querySelector(
              "dialog[id=GoogleSpamAccountModal]",
            ) as HTMLDialogElement
            if (modal) {
              modal.focus()
            }
          }}
        />
      )}
      {selectedPlatform === "microsoft" && (
        <AddEditMicrosoftSpamAccountModal
          data={data}
          open
          onClose={handleClose}
        />
      )}
    </SpamAccountLoginContext.Provider>
  )
}

export default AddEditSpamAccountModal
