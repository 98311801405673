import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useImportAdminDNCMutation } from "@/redux/services/adminDncApi"
import { setToast } from "@/redux/toastSlice"

export const useAdminImportDNC = () => {
  const dispatch = useAppDispatch()
  const [importAdminDNC, response] = useImportAdminDNCMutation()

  const handleImportAdminDNC = async (file: File | Blob) => {
    try {
      const formData = new FormData()
      formData.append("csv", file)
      const response = await importAdminDNC(formData).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success add contacts",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(message)
    }
  }

  return { importAdminDNC: handleImportAdminDNC, response }
}
