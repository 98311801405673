import { useParams, useSearchParams } from "react-router-dom"
import { useGetProspectsQuery } from "@/redux/services/prospectsApi"
import { usePagination } from "@/hooks/usePagination"
import { POLLING_INTERVAL } from "@/constants"

export const useProspects = () => {
  const { id: orgId = "" } = useParams()
  const { limit, skip, text, sort, sortOrder } = usePagination()
  const [searchParams] = useSearchParams()
  const from = searchParams.get("from")
  const to = searchParams.get("to")
  const campaign = Array.from(searchParams.entries())
    .filter(([key]) => key.includes("campaign"))
    .map(([, v]) => v)
  const classifications = Array.from(searchParams.entries())
    .filter(([key]) => key.includes("classifications"))
    .map(([, v]) => v)
  const {
    data: { items: prospects, total } = { items: [], total: 0 },
    ...rest
  } = useGetProspectsQuery(
    {
      orgId,
      skip,
      limit,
      campaign,
      from,
      to,
      classifications,
      text,
      sort,
      sortOrder,
    },
    {
      skip: !orgId,
      pollingInterval: POLLING_INTERVAL,
      refetchOnMountOrArgChange: true,
    },
  )

  return {
    prospects,
    total,
    campaign,
    classifications,
    text,
    ...rest,
  }
}
