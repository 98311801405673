import { createApi } from "@reduxjs/toolkit/query/react"
import { buildQueryString } from "@/utils/strings"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import type { PaginationProps } from "./types"
import type {
  AddProxyPayload,
  EditProxyPayload,
  Proxy,
} from "@/types/entities/proxy"

export const adminProxyApi = createApi({
  reducerPath: "adminProxyApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Admin Proxy"],
  endpoints: (builder) => ({
    getAllAdminProxy: builder.query<
      { items: Proxy[]; total: number },
      PaginationProps
    >({
      query: ({ ...keys }) => ({
        method: "GET",
        url: `admin/proxy/all?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: Proxy[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Admin Proxy" as const,
                _id,
              })),
              { type: "Admin Proxy", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Admin Proxy", id: "PARTIAL-LIST" }],
    }),
    getAdminProxy: builder.query<Proxy, { id: string }>({
      query: ({ id }) => ({
        method: "GET",
        url: `admin/proxy/${id}`,
      }),
      transformResponse: ({ data }: { data: Proxy }) => data,
      providesTags: ["Admin Proxy"],
    }),
    addAdminProxy: builder.mutation<Proxy, AddProxyPayload>({
      query: (body) => ({
        method: "POST",
        url: "admin/proxy",
        body,
      }),
      invalidatesTags: ["Admin Proxy"],
      transformResponse: ({ data }: { data: Proxy }) => data,
    }),
    editAdminProxy: builder.mutation<
      Proxy,
      { id: string; body: EditProxyPayload }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `admin/proxy/${id}`,
        body,
      }),
      invalidatesTags: ["Admin Proxy"],
      transformResponse: ({ data }: { data: Proxy }) => data,
    }),
    deleteAdminProxy: builder.mutation<
      { data: { success: boolean } },
      string[]
    >({
      query: (id) => ({
        method: "DELETE",
        url: `admin/proxy?${buildQueryString({ id })}`,
      }),
      invalidatesTags: ["Admin Proxy"],
    }),
  }),
})

export const {
  useGetAdminProxyQuery,
  useGetAllAdminProxyQuery,
  useAddAdminProxyMutation,
  useEditAdminProxyMutation,
  useDeleteAdminProxyMutation,
} = adminProxyApi
