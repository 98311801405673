import { useCallback } from "react"
import { cx } from "@/utils/strings"
import type { ColumnsType } from "antd/es/table"
import type { SortOrder } from "antd/es/table/interface"

export const useServerSideSorting = () => {
  const mapServerSortedColumns = useCallback(<T,>(col: ColumnsType<T>[0]) => {
    return col.sorter === true
      ? {
          ...col,
          sortIcon: ({ sortOrder }: { sortOrder: SortOrder }) => (
            <span className="server-sorting ant-table-column-sorter-inner">
              <span
                role="img"
                className={cx(
                  "anticon anticon-caret-up ant-table-column-sorter-up opacity-40 transition",
                  {
                    active: sortOrder === "ascend",
                    "!text-positive": sortOrder === "ascend",
                  },
                )}
              >
                <svg
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                >
                  <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                </svg>
              </span>
              <span
                role="img"
                className={cx(
                  "anticon anticon-caret-down ant-table-column-sorter-down opacity-40 transition -mt-[0.3em]",
                  {
                    active: sortOrder === "descend",
                    "!text-positive": sortOrder === "descend",
                  },
                )}
              >
                <svg
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                >
                  <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                </svg>
              </span>
            </span>
          ),
        }
      : col
  }, [])

  return {
    mapServerSortedColumns,
  }
}
