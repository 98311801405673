import { useCallback, useEffect, useRef, useState } from "react"

type Rect = DOMRect | null
type RefCallback = (node: Element | null) => void

export const useClientRect = (scroll = false): [Rect, RefCallback] => {
  const [rect, setRect] = useState<Rect>(null)
  const ref = useRef<Element | null>(null)

  const handleResize = useCallback(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect())
    }
  }, [])

  useEffect(() => {
    if (scroll) {
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [handleResize, scroll])

  const setRef: RefCallback = useCallback((node) => {
    if (node !== null) {
      ref.current = node
      setRect(node.getBoundingClientRect())
    }
  }, [])

  return [rect, setRef]
}
