import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useAddSenderMutation } from "@/redux/services/sendersApi"
import { setToast } from "@/redux/toastSlice"
import { useParams } from "react-router-dom"
import type { Sender } from "@/types/entities/sender"

export const useAddSender = () => {
  const dispatch = useAppDispatch()
  const [addSender, response] = useAddSenderMutation()
  const { id } = useParams()

  const handleAddSender = async (body: Sender) => {
    try {
      const response = await addSender({
        ...body,
        orgId: id!,
      }).unwrap()
      if (response.data) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success add sender",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error, true)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { addSender: handleAddSender, response }
}
