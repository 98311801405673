import { FC, useCallback, useEffect, useMemo, useState } from "react"
import cx from "classnames"
import { MdCached as RetryIcon } from "react-icons/md"
import {
  useGetDomainQuery,
  useVerifyDomainMutation,
} from "@/redux/services/domainsApi"
import OutlineButton from "@/components/OutlineButton"
import StepProgress, { ProgressStatus } from "@/components/StepProgress"
import WarningIcon from "@/components/icons/WarningIcon"
import { useAppSelector } from "@/redux/hooks"
import {
  selectDomainSetupEmailProvider,
  selectDomainSetupId,
  selectDomainSetupProvider,
} from "@/redux/domainSetupSlice"
import { toCapitalize } from "@/utils/strings"
import { useDomainSetup } from "../../hooks/useDomainSetup"
import { useEffectOnce } from "usehooks-ts"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useDomainsContext } from "../../hooks/useDomainsContext"

type VerifyStatuses = {
  ownership: ProgressStatus
  dnsRecords: ProgressStatus
}

type Props = {
  className?: string
  onDisabledChange: (disabled: boolean) => void
}

const DomainVerifyingView: FC<Props> = ({
  className,
  onDisabledChange,
  ...rest
}: Props) => {
  const { pageFullAccess: domainsUpdateFullAccess } = useUserAccess({
    pageName: "Domains Update",
  })
  const domainId = useAppSelector(selectDomainSetupId)
  const emailProvider = useAppSelector(selectDomainSetupEmailProvider)
  const provider = useAppSelector(selectDomainSetupProvider)
  const { dkimNavigatedTo } = useDomainsContext()
  const [
    verifyDomain,
    {
      isError: verifyError,
      isLoading: verifyLoading,
      isSuccess: verifySuccess,
      data: verifyData,
    },
  ] = useVerifyDomainMutation()
  const { changeMissing } = useDomainSetup()
  const [errorHappened, setErrorHappened] = useState(false)
  const [verifyStatuses, setVerifyStatuses] = useState<VerifyStatuses>({
    ownership: "waiting",
    dnsRecords: "waiting",
  })
  const [retry, setRetry] = useState<
    Partial<{
      ownership: boolean
      dnsRecords: boolean
    }>
  >({})
  const { data: domain } = useGetDomainQuery(
    { id: domainId },
    {
      skip: !domainId,
    },
  )
  const startVerify = useCallback(async () => {
    setVerifyStatuses({
      ownership: "waiting",
      dnsRecords: "waiting",
    })
    if (!domain?.actions?.emailProviderVerifiedAt) {
      try {
        const ownershipResponse = await verifyDomain({
          id: domainId,
          type: "ownership",
        }).unwrap()
        if (ownershipResponse.success) {
          setVerifyStatuses((prev) => ({
            ...prev,
            ownership: "done",
          }))
          setRetry({ ownership: false, dnsRecords: false })
        } else {
          setVerifyStatuses((prev) => ({
            ...prev,
            ownership: "error",
          }))
          setRetry({ ownership: true, dnsRecords: false })
        }
      } catch (e) {
        setVerifyStatuses((prev) => ({
          ...prev,
          ownership: "error",
        }))
        setRetry({ ownership: true, dnsRecords: false })
        return
      }
    }
    if (!domain?.actions?.recordsVerifiedAt) {
      try {
        const dnsRecordsResponse = await verifyDomain({
          id: domainId,
          type: "dnsRecords",
        }).unwrap()
        if (dnsRecordsResponse.success) {
          setVerifyStatuses(() => ({
            ownership: "done",
            dnsRecords: "done",
          }))
          setRetry({ ownership: false, dnsRecords: false })
        }
        if (!dnsRecordsResponse.success && dnsRecordsResponse.missing.length) {
          setVerifyStatuses((prev) => ({
            ...prev,
            dnsRecords: "error",
          }))
          setRetry({ ownership: false, dnsRecords: true })
        }
      } catch (e) {
        setVerifyStatuses((prev) => ({
          ...prev,
          dnsRecords: "error",
        }))
        setRetry({ ownership: false, dnsRecords: true })
      }
    }
  }, [
    domain?.actions?.emailProviderVerifiedAt,
    domain?.actions?.recordsVerifiedAt,
    verifyDomain,
    domainId,
  ])
  useEffect(() => {
    if (!errorHappened && verifyError) {
      setErrorHappened(verifyError)
    }
  }, [errorHappened, verifyError])
  useEffectOnce(() => {
    startVerify()
  })
  useEffect(() => {
    let intervalId: number
    if (retry.ownership || retry.dnsRecords) {
      intervalId = setInterval(async () => {
        await startVerify()
      }, 60000) // every 1 minute
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [retry.dnsRecords, retry.ownership, startVerify])
  useEffect(() => {
    if (retry.dnsRecords && verifyData?.missing.length) {
      changeMissing(verifyData.missing)
    } else if (!retry.dnsRecords || !verifyData?.missing.length) {
      changeMissing([])
    }
  }, [changeMissing, retry.dnsRecords, verifyData?.missing])
  const ownershipVerificationAtProgress = useMemo<ProgressStatus>(() => {
    if (verifyStatuses.ownership === "error") {
      return "error"
    }
    if (domain?.actions?.emailProviderVerifiedAt) {
      return "done"
    }
    if (verifyError || verifySuccess) {
      return "waiting"
    }
    return "loading"
  }, [
    domain?.actions?.emailProviderVerifiedAt,
    verifyError,
    verifyStatuses.ownership,
    verifySuccess,
  ])
  const dnsVerifyingAtProgress = useMemo<ProgressStatus>(() => {
    if (verifyStatuses.dnsRecords === "error") {
      return "error"
    }
    if (!domain?.actions?.emailProviderVerifiedAt) {
      return "waiting"
    }
    if (
      (domain?.actions?.emailProviderVerifiedAt &&
        domain?.actions?.recordsVerifiedAt) ||
      (verifyStatuses.ownership === "done" &&
        verifyStatuses.dnsRecords === "done")
    ) {
      return "done"
    }
    if (verifyError || verifySuccess) {
      return "waiting"
    }
    return "loading"
  }, [
    domain?.actions?.emailProviderVerifiedAt,
    domain?.actions?.recordsVerifiedAt,
    verifyStatuses.ownership,
    verifyStatuses.dnsRecords,
    verifyError,
    verifySuccess,
  ])
  useEffect(() => {
    if (dkimNavigatedTo) {
      onDisabledChange(false)
      return
    }
    if (!domain?.actions) {
      onDisabledChange(true)
      return
    }
    const { emailProviderVerifiedAt, recordsVerifiedAt } = domain.actions
    onDisabledChange(!(emailProviderVerifiedAt && recordsVerifiedAt))
  }, [domain, onDisabledChange, dkimNavigatedTo])
  return (
    <div
      className={cx(
        "flex flex-col gap-5",
        {
          faded: !domainsUpdateFullAccess,
        },
        className,
      )}
      {...rest}
    >
      <h2 className="text-lg font-semibold leading-7">Verifying</h2>
      <span className="flex gap-3 items-center">
        <StepProgress value={ownershipVerificationAtProgress} />
        <span className="text-sm leading-6">
          Domain ownership verified by {toCapitalize(emailProvider ?? "")}
        </span>
      </span>
      <span className="flex gap-3 items-center">
        <StepProgress value={dnsVerifyingAtProgress} />
        <span className="text-sm leading-6">Verifying DNS records</span>
      </span>
      {(errorHappened ||
        (verifySuccess &&
          domain?.emailProvider === "microsoft" &&
          !domain.actions?.readyAt) ||
        (verifySuccess &&
          (!domain?.actions?.emailProviderVerifiedAt ||
            !domain?.actions?.recordsVerifiedAt)) ||
        retry.ownership ||
        retry.dnsRecords) && (
        <OutlineButton
          onClick={startVerify}
          className="bg-white btn-xs text-black font-semibold"
          disabled={verifyLoading}
        >
          <RetryIcon className="w-5 h-5" />
          <span>Retry</span>
        </OutlineButton>
      )}
      {retry.dnsRecords && verifyData?.missing.length ? (
        <div className="font-semibold flex items-center gap-2">
          <span>
            <WarningIcon className="text-warning" />
          </span>
          <span>
            {provider === "other"
              ? "DNS records not found, please go back and add them"
              : "DNS records haven't been propagated yet, please try again"}
          </span>
        </div>
      ) : null}
    </div>
  )
}

export default DomainVerifyingView
