import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import cx from "classnames"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import Tabs from "@/components/Tabs"
import Select from "@/components/Select"
import AddIcon from "@/components/icons/AddIcon"
import SearchIcon from "@/assets/search.svg"
import AddOrganizationModal from "../AddOrganizationModal"
import { useAuth } from "@/hooks/useAuth"
import OwnersDropdown from "../OwnersDropdown"
import AdminAddOrganizationModal from "../admin/AdminAddOrganizationModal"

type TrueFalse = "true" | "false"

const OrganizationHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState<TrueFalse>(
    (searchParams.get("isActive") as TrueFalse) ?? "true",
  )
  const [openAddModal, setOpenAddModal] = useState(false)
  const { user } = useAuth()

  const openModal = () => setOpenAddModal(true)
  const closeModal = () => setOpenAddModal(false)

  const handleTabClick = (value: TrueFalse) => {
    return () =>
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        newParams.set("isActive", value)
        setActiveTab(value)
        return newParams
      })
  }
  const handleSearchInput = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const value = target.value
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      if (value) {
        newParams.set("term", value)
      } else {
        newParams.delete("term")
      }
      return newParams
    })
  }
  const handleSortSelect = (value: string) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      if (value) {
        newParams.set("sortBy", value)
      } else {
        newParams.delete("sortBy")
      }
      return newParams
    })
  }
  const searchValue = searchParams.get("term") ?? ""
  const sortValue = searchParams.get("sortBy") ?? "Newest"

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col md:flex-row justify-between flex-wrap gap-4">
        <h3 className="text-2xl font-semibold">Organization</h3>
        <div className="flex gap-4 flex-1 justify-end flex-wrap">
          <TextField
            type="search"
            placeholder="Search by name"
            className="input-sm py-2 pr-3 pl-2"
            rootClassName="basis-40 md:basis-52"
            startIcon={<SearchIcon />}
            onChange={handleSearchInput}
            value={searchValue}
          />
          {user?.isAdmin && <OwnersDropdown className="h-10" />}
          <Button onClick={openModal} className="btn-sm">
            <AddIcon />
            <span className="hidden sm:inline">Add New</span>
          </Button>
        </div>
      </div>
      {user?.isAdmin ? (
        <AdminAddOrganizationModal open={openAddModal} onClose={closeModal} />
      ) : (
        <AddOrganizationModal open={openAddModal} onClose={closeModal} />
      )}
      <div className="flex justify-between items-center border-b border-b-outline">
        <Tabs className="grid-cols-2">
          <span
            onClick={handleTabClick("true")}
            className={cx(
              "text-black tab tab-bordered !px-2 md:!px-4 text-xs md:text-sm",
              {
                "tab-active": activeTab === "true",
              },
            )}
          >
            Active
          </span>
          <span
            onClick={handleTabClick("false")}
            className={cx(
              "text-black tab tab-bordered !px-2 md:!px-4 text-xs md:text-sm",
              {
                "tab-active": activeTab === "false",
              },
            )}
          >
            Inactive
          </span>
        </Tabs>
        <div className="flex gap-1 items-center">
          <span className="text-xs opacity-60 md:text-sm">Sort by:</span>
          <Select
            value={sortValue}
            onChange={handleSortSelect}
            bordered={false}
            popupMatchSelectWidth={140}
            options={[
              { value: "A~Z", label: "A~Z" },
              { value: "Z~A", label: "Z~A" },
              { value: "Newest", label: "Newest" },
              { value: "Oldest", label: "Oldest" },
            ]}
            className="[&_.ant-select-selection-item]:text-xs [&_.ant-select-selection-item]:md:text-sm"
            containerClassName="-ml-[11px]"
          />
        </div>
      </div>
    </div>
  )
}

export default OrganizationHeader
