import { FC, Fragment } from "react"
import cx from "classnames"
import Chip from "@/components/Chip"
import { useCriteriaSetup } from "@/pages/AddEditSearchCriteria/hooks/useCriteriaSetup"
import { useGetCriteriaFiltersQuery } from "@/redux/services/searchCriteriaApi"
import type { UIFilters } from "@/redux/searchCriteriaSetupSlice"
import type {
  FilterType,
  SelectionType,
} from "@/types/entities/search-criteria"

type Props = {
  className?: string
  filterKey: FilterType
  filterValue: UIFilters["COMPANY_HEADCOUNT"]
  canDelete?: boolean
}

const CriteriaSummaryItem: FC<Props> = ({
  className,
  filterKey,
  filterValue,
  canDelete,
}: Props) => {
  const { data: filters = [] } = useGetCriteriaFiltersQuery()
  const { onSelectionDelete } = useCriteriaSetup()

  const filterName =
    filters.find(({ type }) => type === filterKey)?.display ?? filterKey
  return (
    <div className={cx("flex flex-col gap-1.5", className)} key={filterKey}>
      <div className="flex flex-col gap-4">
        {Object.entries(filterValue.selections).map(([key, values]) => (
          <Fragment key={key}>
            {values.length ? (
              <div className="flex flex-col gap-1.5">
                <h5 className="text-sm leading-6 font-semibold">
                  {filterName} (<span className="lowercase">{key}</span>
                  ):
                </h5>
                <div className="flex flex-wrap gap-2">
                  {values.map((v) => (
                    <Chip
                      dataTagIndex={v}
                      onDelete={
                        canDelete
                          ? (v) =>
                              onSelectionDelete(
                                v,
                                key as SelectionType,
                                filterKey,
                              )
                          : undefined
                      }
                      key={v}
                    >
                      {filters
                        .find(({ type }) => type === filterKey)
                        ?.values?.find(({ id }) => id === v)?.description ?? v}
                    </Chip>
                  ))}
                </div>
              </div>
            ) : null}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default CriteriaSummaryItem
