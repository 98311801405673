import dayjs from "dayjs"

export const formatDate = (d: Date) => {
  const dayD = dayjs(d)
  return `${dayD.format("M/D/YYYY")}`
}

export const formatTime = (d: Date) => {
  const dayD = dayjs(d)
  return `${dayD.format("h A")}`
}

export const MIN_DAILY_VOLUME = 1
export const MAX_DAILY_VOLUME = 10000
