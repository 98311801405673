import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import { useDeleteAdminCookie } from "../../hooks/useDeleteAdminCookie"
import type { AdminCookie } from "@/types/entities/cookie"

type Props = {
  className?: string
  data: AdminCookie[]
  onDeleteSuccess?: () => void
} & ModalProps

const DeleteCookieModal: FC<Props> = ({
  className,
  open,
  data,
  onClose,
  onDeleteSuccess,
  ...rest
}: Props) => {
  const {
    deleteAdminCookie,
    response: { isLoading },
  } = useDeleteAdminCookie()
  if (!data.length) {
    return null
  }
  const handleDelete = async () => {
    try {
      const response = await deleteAdminCookie(data.map(({ _id }) => _id))
      if (response) {
        onDeleteSuccess && onDeleteSuccess()
      }
    } catch (e) {
      console.error(e)
    } finally {
      onClose && onClose()
    }
  }
  const title =
    data.length > 1 ? `Delete ${data.length} Cookies` : "Delete Cookie"
  const targetText =
    data.length > 1 ? `${data.length} cookies` : `“${data[0].name}”`
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      className={cx("", className)}
      onSubmit={handleDelete}
      actions={
        <div className="flex gap-4">
          <CancelButton onClick={onClose} disabled={isLoading} />
          <DeleteButton disabled={isLoading} />
        </div>
      }
      {...rest}
    >
      <span className="leading-6 text-base break-all">
        Are you sure you want to delete{" "}
        <span className="font-semibold">{targetText}</span>?
      </span>
    </Modal>
  )
}

export default DeleteCookieModal
