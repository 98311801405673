import { FC, useEffect } from "react"
import cx from "classnames"
import Loader from "@/components/Loader"
import { FaCheckCircle as CheckCircleIcon } from "react-icons/fa"
import { MdCancel as CancelIcon } from "react-icons/md"
import {
  useGetDomainQuery,
  useVerifyDomainMutation,
} from "@/redux/services/domainsApi"
import { useAppSelector } from "@/redux/hooks"
import { selectDomainSetupId } from "@/redux/domainSetupSlice"

type Props = {
  className?: string
  onDisabledChange: (disabled: boolean) => void
}

const DomainDoneView: FC<Props> = ({
  className,
  onDisabledChange,
  ...rest
}: Props) => {
  const domainId = useAppSelector(selectDomainSetupId)
  const { data: { actions } = { actions: undefined } } = useGetDomainQuery(
    {
      id: domainId,
    },
    {
      skip: !domainId,
    },
  )
  const [verifyDomain, { isLoading: verifyLoading }] = useVerifyDomainMutation()
  useEffect(() => {
    if (actions && !actions.readyAt) {
      verifyDomain({ id: domainId, type: "dnsRecords" })
    }
  }, [actions, domainId, verifyDomain])
  useEffect(() => {
    onDisabledChange(!actions?.readyAt)
  }, [actions, onDisabledChange])

  const isLoading = verifyLoading || !actions

  return (
    <div className={cx("flex justify-center py-6", className)} {...rest}>
      <div className="flex flex-col items-center gap-1 text-sm leading-6">
        {isLoading ? (
          <>
            <Loader />
            <span>Please wait...</span>
          </>
        ) : actions.readyAt ? (
          <>
            <CheckCircleIcon className="w-12 h-12 text-positive" />
            <span>Domain setup complete</span>
          </>
        ) : (
          <>
            <CancelIcon className="w-12 h-12 text-error" />
            <span>Domain setup incomplete</span>
          </>
        )}
      </div>
    </div>
  )
}

export default DomainDoneView
