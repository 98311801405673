import { FC } from "react"
import cx from "classnames"
import CancelIcon from "@/assets/cancel.svg"
import { BiSolidCheckCircle as CheckCircleIcon } from "react-icons/bi"
import { ImCancelCircle as CancelCircleIcon } from "react-icons/im"

type Props = {
  className?: string
  connected: boolean
  connectedTo: string | null
  onDisconnect?: () => void
}

const EmailConnectionStatus: FC<Props> = ({
  className,
  connected,
  onDisconnect,
  connectedTo,
  ...rest
}: Props) => {
  return (
    <div
      className={cx(
        "relative rounded-md border border-outline-dark bg-white tiny-shadow py-2 px-3 flex gap-2",
        className,
      )}
      {...rest}
    >
      {connected ? (
        <CheckCircleIcon className="w-6 h-6 text-positive" />
      ) : (
        <CancelCircleIcon className="w-6 h-6 text-error" />
      )}
      <span className="text-sm leading-6">
        {connectedTo === null
          ? "Connected"
          : connected
          ? "Connected to "
          : "Disconnected from "}
        <span className="font-medium break-all">{connectedTo}</span>
      </span>
      {connected && onDisconnect && (
        <CancelIcon
          onClick={onDisconnect}
          className="cursor-pointer active:scale-90 [&_path]:opacity-80 w-[18px] h-[18px] absolute -top-1 -right-1"
        />
      )}
    </div>
  )
}

export default EmailConnectionStatus
