import { FC, ReactNode, forwardRef, useState } from "react"
import cx from "classnames"
import { twMerge } from "tailwind-merge"
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker"
import { TimePicker } from "antd"
import CaretDownIcon from "@/assets/caret-down-outline.svg"
import Select from "../Select"
import dayjs from "dayjs"
import { MONTHS, YEARS } from "./utils"
import "react-datepicker/dist/react-datepicker.css"
import Button from "../Button"
import TextField, { TextFieldProps } from "../TextField"
import ArrowLeftIcon from "../icons/ArrowLeftIcon"
import ArrowRightIcon from "../icons/ArrowRightIcon"

type Props = {
  className?: string
  formatTextFieldValue?: (v: Date) => string
  suffixIcon?: ReactNode
  showTimeOnly?: boolean
  inputClassName?: string
  timeInputFormat?: string
  timeInputClassName?: string
} & ReactDatePickerProps &
  TextFieldProps

const DatePicker: FC<Props> = ({
  className,
  selected,
  formatTextFieldValue,
  suffixIcon,
  topLeftLabel,
  placeholderText,
  disabled,
  showTimeOnly = false,
  showTimeInput,
  inputClassName,
  wrapperClassName,
  timeInputFormat,
  timeInputClassName,
  ...rest
}: Props) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const TimeInput = ({ date, onChange }: any) => (
    <div className="flex gap-2">
      <TimePicker
        value={dayjs(new Date(date))}
        onSelect={(time) => onChange(`${time?.hour()}:${time?.minute()}`)}
        format={timeInputFormat ?? "hh : mm"}
        use12Hours
        showNow={false}
        getPopupContainer={(node) => node.parentNode as HTMLElement}
        showSecond={false}
        className={twMerge(
          cx(
            "bg-input border-none w-20 [&_input]:!text-base [&_input]:!leading-7 [&_input]:!font-medium flex justify-center",
            timeInputClassName,
          ),
        )}
        suffixIcon={null}
        allowClear={false}
        changeOnBlur
      />
      <div className="rounded-[9px] bg-input p-0.5 flex">
        <Button
          onClick={() => {
            const currentDate = new Date(date)
            if (currentDate.getHours() < 11) {
              return
            }
            onChange(
              `${currentDate.getHours() - 12}:${currentDate.getMinutes()}`,
            )
          }}
          className={cx("w-[51px] btn-xs", {
            "!bg-transparent !text-black font-normal":
              new Date(date).getHours() >= 12,
          })}
        >
          AM
        </Button>
        <Button
          onClick={() => {
            const currentDate = new Date(date)
            if (currentDate.getHours() > 11) {
              return
            }
            onChange(
              `${currentDate.getHours() + 12}:${currentDate.getMinutes()}`,
            )
          }}
          className={cx("w-[51px] btn-xs", {
            "!bg-transparent !text-black font-normal":
              new Date(date).getHours() < 12,
          })}
        >
          PM
        </Button>
      </div>
    </div>
  )
  const DatePickerInputField = forwardRef<HTMLInputElement>(
    ({ value, onClick }: any, ref) => (
      <TextField
        value={
          value && formatTextFieldValue ? formatTextFieldValue(value) : value
        }
        ref={ref}
        className={cx(
          "cursor-pointer input-sm caret-transparent",
          inputClassName,
        )}
        onClick={onClick}
        suffixIcon={
          <div
            className={cx({
              "cursor-pointer": !disabled,
              "cursor-text": disabled,
            })}
            onClick={disabled ? undefined : handleOpen}
          >
            {suffixIcon}
          </div>
        }
        topLeftLabel={topLeftLabel}
        placeholder={placeholderText}
        disabled={disabled}
        autoComplete="off"
        onChange={() => {}}
      />
    ),
  )
  return (
    <ReactDatePicker
      open={open}
      onCalendarOpen={handleOpen}
      onCalendarClose={handleClose}
      onClickOutside={handleClose}
      onSelect={() => handleClose()} // ! might cause select issue
      dropdownMode="select"
      renderCustomHeader={({ changeMonth, changeYear, date }) =>
        showTimeOnly ? null : (
          <div className="py-1 px-2 flex gap-0 sm:gap-4 border-b border-b-outline bg-bg-default [&_.ant-select-selection-item]:text-left [&_.ant-select-selection-item]:font-semibold">
            <div
              onClick={() => {
                const currentMonth = date.getMonth()
                changeMonth(currentMonth - 1)
              }}
              className="cursor-pointer [&:active_svg]:scale-90 flex absolute left-0 bottom-0 top-[41px] items-center w-5 bg-semi"
            >
              <ArrowLeftIcon className="opacity-60 z-10" />
            </div>
            <div
              onClick={() => {
                const currentMonth = date.getMonth()
                changeMonth(currentMonth + 1)
              }}
              className="cursor-pointer [&:active_svg]:scale-90 flex absolute right-0 bottom-0 top-[41px] items-center w-5 bg-semi"
            >
              <ArrowRightIcon className="opacity-60 z-10" />
            </div>
            <Select
              options={MONTHS}
              value={date.getMonth()}
              onChange={changeMonth}
              getPopupContainer={(node) => node.parentNode}
              containerClassName="min-w-[60px] sm:min-w-[120px] flex-1"
              popupMatchSelectWidth={150}
              className="bg-transparent"
              bordered={false}
              suffixIcon={<CaretDownIcon className="w-6 h-6 text-black" />}
            />
            <Select
              options={YEARS}
              value={date.getFullYear()}
              onChange={changeYear}
              getPopupContainer={(node) => node.parentNode}
              containerClassName="flex-1"
              bordered={false}
              className="bg-transparent"
              suffixIcon={<CaretDownIcon className="w-6 h-6 text-black" />}
            />
          </div>
        )
      }
      showTimeInput={showTimeOnly || showTimeInput}
      customTimeInput={<TimeInput date={selected} />}
      className={cx("w-80", className)}
      wrapperClassName={cx("w-full", wrapperClassName)}
      popperClassName={cx({
        "hide-main": showTimeOnly,
      })}
      showPopperArrow={false}
      calendarStartDay={0}
      selected={selected}
      dateFormat="M/d/yyyy hh:mm a"
      customInput={<DatePickerInputField />}
      disabled={disabled}
      {...rest}
    ></ReactDatePicker>
  )
}

export default DatePicker
