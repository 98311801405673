import { FC } from "react"
import cx from "classnames"
import { MdOutlineFilterAlt } from "react-icons/md"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const FilterIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return <MdOutlineFilterAlt className={cx("w-5 h-5", className)} {...rest} />
}

export default FilterIcon
