import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import { BiPlus as AddIcon } from "react-icons/bi"
import cx from "classnames"
import Select from "@/components/Select"
import AddMasterListModal from "@/pages/MasterLists/features/AddMasterListModal"
import CircleLabel from "@/components/CircleLabel"
import { getMLItemColor } from "./utils"
import { filterOption } from "@/components/Select/constants"
import { useGetSearchCriteriaMLQuery } from "@/redux/services/masterListsApi"
import type { MasterList } from "@/types/entities/master-list"
import type { SearchCriteria } from "@/types/entities/search-criteria"

type Props = {
  className?: string
  value: string | null
  onMasterListSelect: (masterListId: string) => void
  currentSearchCriteria?: SearchCriteria
}

const CriteriaMasterListSelect: FC<Props> = ({
  className,
  value,
  onMasterListSelect,
  currentSearchCriteria,
}: Props) => {
  const { id: orgId = "", criteriaId = "" } = useParams()
  const { data: masterLists = [], isFetching } = useGetSearchCriteriaMLQuery({
    org: orgId,
    searchCriteria: criteriaId,
  })

  const [openAddMasterListModal, setOpenAddMasterListModal] = useState(false)

  const handleAddRecordsSuccess = (masterList: MasterList) =>
    onMasterListSelect(masterList._id)

  return (
    <>
      <Select
        value={isFetching ? null : value}
        onChange={onMasterListSelect}
        showSearch
        label="Master List"
        bordered={false}
        className={cx("h-10 bg-input rounded-md", className)}
        placeholder="Select or add master list"
        filterOption={filterOption}
        containerClassName="[&_.label-text]:!py-2"
        dropdownRender={(menu) => (
          <div className="flex flex-col">
            <div
              onClick={() => setOpenAddMasterListModal(true)}
              className="px-3 py-2 flex gap-2 text-primary cursor-pointer border-b border-b-outline"
            >
              <AddIcon className="w-[22px] h-[22px] -ml-1.5" />
              <span className="leading-6 font-medium text-sm">Add New</span>
            </div>
            {menu}
          </div>
        )}
        optionRender={({ label, data }) => (
          <CircleLabel color={data.color} label={label} />
        )}
        options={masterLists.map(({ _id, name, searchCriteria }) => ({
          label: name,
          value: _id,
          color: getMLItemColor(searchCriteria, currentSearchCriteria),
        }))}
        loading={isFetching}
      />
      <AddMasterListModal
        open={openAddMasterListModal}
        onClose={() => setOpenAddMasterListModal(false)}
        addRecords={[]}
        onAddRecordsSuccess={handleAddRecordsSuccess}
      />
    </>
  )
}

export default CriteriaMasterListSelect
