import { createContext } from "react"
import type { FieldConfig, FieldInputProps, FormikErrors } from "formik"
import type { GsuiteAccount } from "@/types/entities/gsuite-account"

export const AdminAccountLoginContext = createContext<{
  getFieldProps: (
    nameOrOptions: string | FieldConfig<any>,
  ) => FieldInputProps<any>
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<
          | GsuiteAccount
          | {
              displayName: string
              maxDomains: number
              email: string
            }
        >
      >
  disconnectMail: (id: string) => Promise<GsuiteAccount | undefined>
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  isLoading: boolean
  title: string
  submitDisabled: boolean
  displayName: string
  maxDomains: number
  code: string
}>({
  getFieldProps: () => {
    return null as any
  },
  setFieldValue: () => {
    return null as any
  },
  disconnectMail: () => {
    return null as any
  },
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => {},
  isLoading: false,
  submitDisabled: false,
  title: "",
  displayName: "",
  maxDomains: 0,
  code: "",
})
