export const POLLING_INTERVAL = 60000 // 1 minute

export const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  month: "numeric",
  day: "numeric",
  year: "numeric",
}

export const MAX_CLASSIFICATIONS_LENGTH = 20

export const MEDIA_BREAKPOINTS = {
  sm: 600,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
}
