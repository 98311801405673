import { Organization } from "@/types/entities/organization"

export const alphabetDateSortBy = (sortBy: string, array: Organization[]) => {
  switch (sortBy) {
    case "A~Z":
      return array.sort((a, b) => a.name.localeCompare(b.name))
    case "Z~A":
      return array.sort((a, b) => b.name.localeCompare(a.name))
    case "Newest":
      return array.sort(
        (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt),
      )
    case "Oldest":
      return array.sort(
        (a, b) => +new Date(a.createdAt) - +new Date(b.createdAt),
      )
    default:
      return array
  }
}
