import React, { FC, useEffect } from "react"
import { Link } from "react-router-dom"
import { FiArrowRight } from "react-icons/fi"
import TextField from "@/components/TextField"
import { Routes } from "@/core/routing/routes"
import SearchIcon from "@/assets/search.svg"

type Props = {
  menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  searchTerm: string
  onSearch: (val: string) => void
  textFieldRef: React.RefObject<HTMLInputElement>
}

const OrganizationsSelect: FC<Props> = ({
  menu,
  searchTerm,
  onSearch,
  textFieldRef,
}: Props) => {
  useEffect(() => {
    setTimeout(() => textFieldRef.current?.focus())
  }, [textFieldRef])

  return (
    <>
      <div className="py-2 border-b border-b-outline">
        <TextField
          autoFocus
          ref={textFieldRef}
          value={searchTerm}
          onChange={({ target }) => onSearch(target.value)}
          placeholder="Search by name"
          suffixIcon={<SearchIcon className="w-5 h-5" />}
          className="!border-none !outline-none bg-white h-6 text-sm"
        />
      </div>
      <div className="max-h-72 overflow-y-auto">{menu}</div>
      <Link to={Routes.organizations}>
        <div className="border-t border-t-outline hover:text-primary cursor-pointer p-2.5 flex gap-2.5 justify-between">
          <span className="text-sm font-medium">View all organizations</span>
          <FiArrowRight className="w-5 h-5" />
        </div>
      </Link>
    </>
  )
}

export default OrganizationsSelect
