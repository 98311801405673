import { FC } from "react"
import cx from "classnames"
import ViewIcon from "../icons/ViewIcon"

type Props = {
  className?: string
}

const ViewOnlyBadge: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <span
      className={cx(
        "bg-info text-xs text-white rounded-md p-0.5 sm:px-1.5 sm:py-1",
        className,
      )}
      {...rest}
    >
      <span className="hidden sm:inline">View only</span>
      <ViewIcon className="inline-block sm:hidden" />
    </span>
  )
}

export default ViewOnlyBadge
