import React from "react"
import { Navigate } from "react-router-dom"
import { Routes } from "@/core/routing/routes"
import { useAuth } from "@/hooks/useAuth"
import Loader from "../Loader"

type Props = {
  children: React.ReactNode
}

const RequireAdminProvider = ({ children }: Props) => {
  const { user, isLoading, token } = useAuth()

  if (isLoading && user === undefined) {
    return (
      <div className="h-screen w-screen flex justify-center items-center">
        <Loader />
      </div>
    )
  }

  if (
    !token ||
    (!isLoading && user === null) ||
    (user != null && !user.isAdmin)
  ) {
    return <Navigate to={Routes.login} replace />
  }
  return children
}

export default RequireAdminProvider
