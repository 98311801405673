import { useEffect, useState } from "react"
import { useGetAdminUsersQuery } from "@/redux/services/adminUsersApi"
import { addAndEditArrayById } from "@/utils/arrays"
import type { AdminUser } from "@/types/entities/admin-user"

export const useAdminUsersInfiniteScroll = () => {
  const [skip, setSkip] = useState(0)
  const {
    currentData,
    data: { items: owners, total } = { items: [], total: 0 },
    ...rest
  } = useGetAdminUsersQuery({
    skip,
    limit: 15,
    active: true,
  })
  const [paginatedOwners, setPaginatedOwners] = useState<AdminUser[]>(owners)
  useEffect(() => {
    if (!currentData) {
      return
    }
    setPaginatedOwners((prev) =>
      addAndEditArrayById<AdminUser>({
        newItems: currentData.items,
        currentArray: prev,
      }),
    )
  }, [currentData])
  const onUsersScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLElement
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setSkip((prev) => (prev + 15 < total ? prev + 15 : prev))
    }
  }

  return { paginatedOwners, total, onUsersScroll, ...rest }
}
