import { FC, useMemo } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import { useFormik } from "formik"
import Textarea from "@/components/Textarea"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import { getCategoryStrings } from "../../utils"
import CategoryActionDropdown from "../CategoryActionDropdown"
import { useAddAiPrompt } from "../../hooks/useAddAIPrompt"
import { useEditAiPrompt } from "../../hooks/useEditAIPrompt"
import { useGetSystemValuesQuery } from "@/redux/services/generalApi"
import type { AiPrompt, AiPromptPayload } from "@/types/entities/ai-prompt"

type Props = {
  className?: string
  data?: (AiPrompt & { categoryName?: string }) | null
  textFieldLabel?: string
  isSubquery?: boolean
  editSubquery?: boolean
} & ModalProps

const AddEditPromptModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  textFieldLabel = "Query",
  isSubquery,
  editSubquery,
  ...rest
}: Props) => {
  const isEdit = Boolean(data)
  const { data: { classificationActions } = { classificationActions: [] } } =
    useGetSystemValuesQuery()
  const { addAIPrompt, response: addResponse } = useAddAiPrompt()
  const { editAIPrompt, response: editResponse } = useEditAiPrompt()
  const {
    resetForm,
    getFieldProps,
    values: { text, actions },
    setFieldValue,
    handleSubmit,
  } = useFormik<{
    text: string
    actions: { category: number; values: { label: string; value: string }[] }[]
  }>({
    enableReinitialize: true,
    initialValues: {
      text: data?.text ?? "",
      actions:
        data?.actions.map(({ category, values }) => ({
          category,
          values: values.map((v) => ({
            label:
              classificationActions.find(({ id }) => id === v)?.display ?? v,
            value: v,
          })),
        })) ?? [],
    },
    onSubmit: async (values) => {
      const body: AiPromptPayload = Object.assign(
        {
          ...values,
          actions: values.actions
            .filter((v) => v.values.length)
            .map(({ category, values }) => ({
              category,
              values: values.map(({ value }) => value),
            })),
        },
        isSubquery
          ? {
              parent: data?._id,
              parentCategory: data?.parentCategory,
            }
          : {},
      )
      try {
        if (!isEdit) {
          await addAIPrompt(body)
        } else if (isEdit && !isSubquery && !editSubquery) {
          await editAIPrompt({
            body: {
              actions: body.actions,
              text: body.text,
            },
            id: data!._id,
          })
        } else if (isEdit && editSubquery) {
          await editAIPrompt({
            body: {
              actions: body.actions,
              text: body.text,
            },
            id: data!._id,
          })
        } else if (isEdit && isSubquery && !editSubquery) {
          await addAIPrompt(body)
        }
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  const handleActionToggle = (
    action: { value: string; label: string },
    checked: boolean,
    categoryNumber: number,
  ) => {
    setFieldValue(
      "actions",
      checked
        ? actions.find(({ category }) => category === categoryNumber)
          ? actions.map(({ category, values }) =>
              category === categoryNumber
                ? {
                    category,
                    values: values.concat(action),
                  }
                : { category, values },
            )
          : actions.concat({ category: categoryNumber, values: [action] })
        : actions.map(({ category, values }) =>
            category === categoryNumber
              ? {
                  category,
                  values: values.filter((v) => v.value !== action.value),
                }
              : { category, values },
          ),
    )
  }
  const handleTextChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFieldValue("text", value)
    const categoryStrings = getCategoryStrings(value)
    const newActions = actions.filter((currAction) => {
      const categoryStringMatch = categoryStrings.find((str) => {
        const numberMatch = str.match(/\d/)
        if (numberMatch) {
          return currAction.category === parseInt(numberMatch[0])
        }
        return false
      })
      if (!categoryStringMatch) {
        return false
      }
      return true
    })
    setFieldValue("actions", newActions)
  }
  const categories = useMemo(
    () =>
      getCategoryStrings(text).map((categoryString) => {
        const contentInsideParenthesesMatch =
          categoryString.match(/\(([^)]+)\)/)
        const categoryNumberMatch = categoryString.match(/\d/)

        let categoryName = "",
          categoryNumber = 0
        if (contentInsideParenthesesMatch) {
          categoryName = contentInsideParenthesesMatch[1]
        }
        if (categoryNumberMatch) {
          categoryNumber = Number(categoryNumberMatch[0])
        }

        return {
          value: categoryString,
          label: categoryName,
          categoryNumber,
        }
      }),
    [text],
  )
  const submitBtnText = isSubquery
    ? editSubquery
      ? "Save"
      : "Add"
    : isEdit
    ? "Save"
    : "Add"
  const title = isSubquery
    ? `When classification is ${data?.categoryName ?? ""}`
    : isEdit
    ? "Edit Main AI Query"
    : "Add Main AI Query"
  return (
    <Modal
      title={title}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            type="button"
            className="btn-sm text-black min-w-[86px]"
            onClick={handleClose}
            disabled={addResponse.isLoading || editResponse.isLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={!text || addResponse.isLoading || editResponse.isLoading}
            type="submit"
            className="btn-sm min-w-[86px]"
          >
            {submitBtnText}
          </Button>
        </div>
      }
      className={cx("", className)}
      boxClassName="w-[90vw] h-[90vh] max-w-none flex flex-col [&>h3+div]:flex-1"
      contentClassName="max-h-none"
      {...rest}
    >
      <div className="flex gap-5 h-full">
        <div className="basis-2/3 [&_.label-text]:font-medium [&_.label-text]:text-base [&_.label]:pt-0 [&_.label]:pb-3">
          <Textarea
            className="min-h-full !px-3"
            topLeftLabel={textFieldLabel}
            placeholder="Enter query here"
            rootClassName="h-full [&_.relative]:flex-1"
            {...getFieldProps("text")}
            value={text}
            onChange={handleTextChange}
          />
        </div>
        <div className="border-x-[0.5px] border-x-outline"></div>
        <div className="basis-1/3">
          <h2 className="text-base font-medium leading-6">
            Categories & Prospect Actions
          </h2>
          {categories.length ? (
            <table cellPadding={8} className="w-full mt-3">
              <thead className="text-left border-b-outline-dark border-b text-sm [&_th]:font-semibold">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="[&>tr]:border-b [&>tr]:border-b-outline text-sm [&>td:first-child]font-semibold">
                {categories.map((category, i) => (
                  <tr key={i}>
                    <td>{category.categoryNumber}</td>
                    <td className="w-1/2">
                      <TextField
                        placeholder="Enter category name"
                        className="truncate input-sm read-only:bg-input read-only:border-none read-only:opacity-100 rounded-md"
                        type="text"
                        value={category.label}
                        readOnly
                      />
                    </td>
                    <td className="w-1/2">
                      <CategoryActionDropdown
                        bordered={false}
                        getPopupContainer={(node) => node.parentNode}
                        value={
                          actions
                            .find(
                              ({ category: actionCategory }) =>
                                actionCategory === category.categoryNumber,
                            )
                            ?.values.map(({ label }) => label) ?? []
                        }
                        onActionToggle={(action, checked) => {
                          handleActionToggle(
                            action,
                            checked,
                            category.categoryNumber,
                          )
                        }}
                        placeholder="Select actions"
                        containerClassName="w-full inline-block"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default AddEditPromptModal
