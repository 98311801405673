import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteAdminAccountMutation } from "@/redux/services/gsuitesApi"
import { setToast } from "@/redux/toastSlice"

export const useDeleteAdminAccount = () => {
  const dispatch = useAppDispatch()
  const [deleteAdminAccount, response] = useDeleteAdminAccountMutation()

  const handleDeleteSpamAccount = async (id: string) => {
    try {
      const response = await deleteAdminAccount({ id }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success delete admin account",
          }),
        )
      }
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteAdminAccount: handleDeleteSpamAccount, response }
}
