import { createApi } from "@reduxjs/toolkit/query/react"
import { buildQueryString } from "@/utils/strings"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import type { PaginationProps } from "./types"
import type {
  AddSpamAccountPayload,
  AdminSpamAccount,
  EditSpamAccountPayload,
} from "@/types/entities/spam-account"

export const adminSpamAccountsApi = createApi({
  reducerPath: "adminSpamAccountsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Admin Spam Accounts"],
  endpoints: (builder) => ({
    getAdminSpamAccounts: builder.query<
      { items: AdminSpamAccount[]; total: number },
      PaginationProps
    >({
      query: ({ ...keys }) => ({
        method: "GET",
        url: `admin/spam/all?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: AdminSpamAccount[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Admin Spam Accounts" as const,
                _id,
              })),
              { type: "Admin Spam Accounts", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Admin Spam Accounts", id: "PARTIAL-LIST" }],
    }),
    addAdminSpamAccount: builder.mutation<
      AdminSpamAccount,
      AddSpamAccountPayload
    >({
      query: (body) => ({
        method: "POST",
        url: "admin/spam",
        body,
      }),
      invalidatesTags: ["Admin Spam Accounts"],
      transformResponse: ({ data }: { data: AdminSpamAccount }) => data,
    }),
    editAdminSpamAccount: builder.mutation<
      AdminSpamAccount,
      {
        body: EditSpamAccountPayload
        id: string
      }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `admin/spam/${id}`,
        body,
      }),
      invalidatesTags: ["Admin Spam Accounts"],
      transformResponse: ({ data }: { data: AdminSpamAccount }) => data,
    }),
    disconnectAdminSpamAccount: builder.mutation<
      AdminSpamAccount,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `admin/spam/${id}/disconnect`,
        method: "PUT",
      }),
      invalidatesTags: ["Admin Spam Accounts"],
      transformResponse: ({ data }: { data: AdminSpamAccount }) => data,
    }),
    deleteAdminSpamAccount: builder.mutation<{ data: boolean }, { id: string }>(
      {
        query: ({ id }) => ({
          url: `admin/spam/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Admin Spam Accounts"],
      },
    ),
  }),
})

export const {
  useGetAdminSpamAccountsQuery,
  useAddAdminSpamAccountMutation,
  useEditAdminSpamAccountMutation,
  useDisconnectAdminSpamAccountMutation,
  useDeleteAdminSpamAccountMutation,
} = adminSpamAccountsApi
