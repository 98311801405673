import { FC } from "react"
import cx from "classnames"
import { Switch as AntSwitch, SwitchProps } from "antd"

type Props = {
  className?: string
} & SwitchProps

const Switch: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <AntSwitch
      className={cx("bg-outline min-w-fit w-10 transition-colors", className)}
      {...rest}
    />
  )
}

export default Switch
