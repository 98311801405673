import { useContext } from "react"
import { InboxFiltersContext } from "../context"

export const useInboxFilters = () => {
  const ctx = useContext(InboxFiltersContext)
  if (!ctx) {
    throw new Error("useInboxFilters must be used within a InboxFiltersContext")
  }
  return ctx
}
