import cx from "classnames"
import { twMerge } from "tailwind-merge"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import { MdArrowOutward as ArrowOutwardIcon } from "react-icons/md"
import Switch from "@/components/Switch"

type Props = {
  className?: string
  icon?: React.ReactNode
  name: string
  contentName?: string
  footerName: string
  enabled: boolean
  onEdit: () => void
  onDelete?: () => void
  onChangeStatus: (enabled: boolean) => void
} & React.AllHTMLAttributes<HTMLDivElement>

const OrganizationCard = ({
  className,
  icon,
  enabled,
  footerName,
  contentName,
  name,
  onEdit,
  onDelete,
  onChangeStatus,
  ...rest
}: Props) => {
  const handleChangeStatus = () => {
    onChangeStatus(!enabled)
  }

  return (
    <div
      className={twMerge(
        cx(
          "cursor-pointer flex flex-col group rounded-md border border-outline bg-white shadow-[0px_2px_8px_0px_rgba(0,0,0,0.06)]",
          className,
        ),
      )}
      {...rest}
    >
      <div className="flex-1 py-4 pl-4 pr-3 flex flex-col gap-3">
        <div className="flex justify-between">
          <h5 className="transition-colors font-medium text-base group-hover:text-primary">
            {name}
          </h5>
          <ArrowOutwardIcon className="will-change-[color_opacity] opacity-20 w-6 h-6 transition-[color_opacity] group-hover:opacity-100 group-hover:text-primary" />
        </div>
        {contentName && icon && (
          <div className="flex gap-1.5 items-center">
            <div className="w-5 h-5">{icon}</div>
            <span className="opacity-60 text-xs capitalize">{contentName}</span>
          </div>
        )}
      </div>
      <div className="border-t border-t-outline bg-bg-default flex justify-between items-center py-2 px-3">
        {footerName && (
          <span className="text-xs leading-4 opacity-60">
            <span className="font-medium">{footerName}</span>
          </span>
        )}
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex gap-4 items-center transition-opacity"
        >
          <BinIcon
            onClick={onDelete}
            className="cursor-pointer will-change-[color_opacity] opacity-0 group-hover:opacity-40 active:scale-90 transition-[opacity_color] w-5 h-6 hover:text-primary hover:!opacity-100"
          />
          {enabled && (
            <EditIcon
              className="cursor-pointer will-change-[color_opacity] active:scale-90 transition-[opacity_color] opacity-0 group-hover:opacity-40 w-6 h-6 hover:text-primary hover:!opacity-100"
              onClick={onEdit}
            />
          )}
          <Switch onChange={handleChangeStatus} checked={enabled} />
        </div>
      </div>
    </div>
  )
}

export default OrganizationCard
