import { useNavigate } from "react-router-dom"
import { useLoginUserMutation } from "@/core/api/services"
import { loginUserAction } from "@/redux/authSlice"
import { Routes } from "@/core/routing/routes"
import { LoginFormShape } from "@/pages/Login/features/LoginForm/types"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"

export const useLogin = () => {
  const dispatch = useAppDispatch()
  const [loginUser, response] = useLoginUserMutation()
  const navigate = useNavigate()

  const handleUserLogin = async ({ email, password }: LoginFormShape) => {
    try {
      const response = await loginUser({
        email,
        password,
      }).unwrap()
      if (response.data) {
        dispatch(loginUserAction(response.data))
      }
      navigate(Routes.organizations)
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { loginUser: handleUserLogin, response }
}
