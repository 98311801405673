import { createContext } from "react"
import type { SortOptions } from "@/types/shared"

export const INBOX_FILTERS_KEY = "inboxFilters"
export const INBOX_SORT_KEY = "inboxSort"

const inboxFiltersStorage = sessionStorage.getItem(INBOX_FILTERS_KEY)
const inboxSortStorage = sessionStorage.getItem(INBOX_SORT_KEY)

export const initFilters = getInitInboxFilters()
export const initSort = getInitInboxSort()

export function getInitInboxFilters() {
  const inboxFiltersStorage = sessionStorage.getItem(INBOX_FILTERS_KEY)
  return inboxFiltersStorage
    ? JSON.parse(inboxFiltersStorage)
    : {
        classifications: [
          "Immediate Interest In Meeting or Connecting",
          "Immediate Interest, Seeks More Info",
          "Future Interest",
          "Polite/Neutral Disinterest",
          "Polite/Neutral Disinterest, Requires Response",
          "Polite/Neutral Disinterest, Provides Important Information",
          "Wrong Recipient, Provides Correct Contact",
          "Wrong Recipient, Forwards to Correct Contact",
          "Wrong Recipient, Requires Response to Question",
          "Wrong Recipient, Provides Important Information",
          "Unsure of Category",
        ],
      }
}
export function getInitInboxSort() {
  const inboxSortStorage = sessionStorage.getItem(INBOX_SORT_KEY)
  return inboxSortStorage ? JSON.parse(inboxSortStorage) : -1
}

if (!inboxFiltersStorage) {
  sessionStorage.setItem(INBOX_FILTERS_KEY, JSON.stringify(initFilters))
}
if (!inboxSortStorage) {
  sessionStorage.setItem(INBOX_SORT_KEY, JSON.stringify(initSort))
}

export const InboxFiltersContext = createContext<{
  filters: Record<string, any>
  setFilters: (filters: Record<string, any>) => void
  sort: any // todo: update any
  setSort: (sort: SortOptions) => void
  skip: number
  setSkip: React.Dispatch<React.SetStateAction<number>>
}>({
  filters: initFilters,
  setFilters: () => {},
  sort: initSort,
  setSort: () => {},
  skip: 0,
  setSkip: () => {},
})
