import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import type { SequenceAPIResponse } from "@/types/entities/sequence"

type Props = {
  className?: string
  data?: SequenceAPIResponse | null
  onConfirm: (id: string) => void
} & ModalProps

const SequenceDuplicateConfirmModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  onConfirm,
  ...rest
}: Props) => {
  if (!data) {
    return null
  }
  const handleConfirm = () => onConfirm(data._id)
  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={handleConfirm}
      className={cx("", className)}
      title="Duplicate Sequence"
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={onClose}
            className="text-black btn-sm w-[86px]"
            type="button"
          >
            Cancel
          </OutlineButton>
          <Button className="btn-sm min-w-[86px]" type="submit">
            Confirm
          </Button>
        </div>
      }
      {...rest}
    >
      <span className="text-sm">
        Make a copy of "
        <span className="font-semibold">{data.name.trim()}</span>"?
      </span>
    </Modal>
  )
}

export default SequenceDuplicateConfirmModal
