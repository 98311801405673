import { FC } from "react"
import Modal from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import { useDeleteCampaign } from "../../hooks/useDeleteCampaign"
import type { ModalProps } from "@/components/Modal"
import type { Campaign } from "@/types/entities/campaign"

type Props = ModalProps & {
  data?: Campaign
}

const DeleteCampaignModal: FC<Props> = ({ open, onClose, data }: Props) => {
  const { deleteCampaign, response } = useDeleteCampaign()
  if (!data) {
    return null
  }
  const handleDelete = async () => {
    await deleteCampaign({ id: data._id })
    onClose && onClose()
  }
  return (
    <Modal
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={response.isLoading} onClick={onClose} />
          <DeleteButton tabIndex={0} disabled={response.isLoading} />
        </div>
      }
      title="Delete Campaign"
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
    >
      <span className="text-base">
        Are you sure you want to delete&nbsp;
        <span className="font-semibold">“{data.name}”</span>?
      </span>
    </Modal>
  )
}

export default DeleteCampaignModal
