import { FC } from "react"
import cx from "classnames"
import { LuDownload } from "react-icons/lu"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const DownloadIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return <LuDownload className={cx("w-5 h-5", className)} {...rest} />
}

export default DownloadIcon
