import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useInviteUserMutation } from "@/redux/services/adminUsersApi"
import { setToast } from "@/redux/toastSlice"

export const useInviteAdminUser = () => {
  const dispatch = useAppDispatch()
  const [addUser, response] = useInviteUserMutation()

  const handleInviteUser = async (body: {
    firstName: string
    lastName: string
    email: string
  }) => {
    try {
      await addUser(body).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success add user",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { inviteUser: handleInviteUser, response }
}
