import { FC } from "react"
import cx from "classnames"
import {
  selectCriteriaDomains,
  selectCriteriaFilter,
} from "@/redux/searchCriteriaSetupSlice"
import { useAppSelector } from "@/redux/hooks"
import { useCriteriaSetup } from "@/pages/AddEditSearchCriteria/hooks/useCriteriaSetup"
import TagsTextarea from "@/components/TagsTextarea"
import { URL_REGEX } from "@/utils/regex"
import { useActiveFilterContext } from "@/pages/AddEditSearchCriteria/hooks/useActiveFilterContext"
import type {
  FilterType,
  SelectionType,
} from "@/types/entities/search-criteria"

type Props = {
  className?: string
}

const CriteriaCompanyFilter: FC<Props> = ({ className, ...rest }: Props) => {
  const FILTER_TYPE = useActiveFilterContext() as FilterType
  const { selections: companySelections } = useAppSelector(
    selectCriteriaFilter(FILTER_TYPE),
  )!
  const domains = useAppSelector(selectCriteriaDomains)
  const { onTextFieldChange, onDomainsChange } = useCriteriaSetup()
  const handleSelectionTypeValuesChange =
    (type: SelectionType) => (value: string[]) =>
      onTextFieldChange({ type, value, filterType: FILTER_TYPE })

  return (
    <div className={cx("flex flex-col gap-4 flex-grow", className)} {...rest}>
      <div className="flex flex-col lg:flex-row gap-4 flex-1 [&_.relative]:h-full">
        <div className="basis-1/3 gap-y-2 flex flex-col">
          <h5 className="font-medium text-positive">Company Include</h5>
          <TagsTextarea
            value={companySelections.INCLUDED}
            onTagsChange={handleSelectionTypeValuesChange("INCLUDED")}
            placeholder="Add company include"
            className="resize-none h-full flex-grow"
          />
        </div>
        <div className="basis-1/3 gap-y-2 flex flex-col">
          <h5 className="font-medium text-positive">Company Include Website</h5>
          <TagsTextarea
            value={domains}
            onTagsChange={onDomainsChange}
            regex={URL_REGEX}
            className="resize-none h-full flex-grow"
            placeholder="Add company include website"
          />
        </div>
        <div className="basis-1/3 gap-y-2 flex flex-col">
          <h5 className="font-medium text-error">Company Exclude</h5>
          <TagsTextarea
            value={companySelections.EXCLUDED}
            onTagsChange={handleSelectionTypeValuesChange("EXCLUDED")}
            placeholder="Add company exclude"
            className="resize-none h-full flex-grow"
          />
        </div>
      </div>
    </div>
  )
}

export default CriteriaCompanyFilter
