import { useCallback, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import {
  SearchCriteriaSetupState,
  resetCriteria,
  selectCriteriaFilledFilters,
  selectCriteriaFilters,
  selectCriteriaMasterListId,
  selectCriteriaName,
  selectCriteriaTarget,
  selectCriteriaType,
  setCriteriaDescription,
  setCriteriaDomains,
  setCriteriaFilterSelectionValue,
  setCriteriaFilterStrictValue,
  setCriteriaFilterSubSelectionValue,
  setCriteriaKeywords,
  setCriteriaMasterListId,
  setCriteriaName,
  setCriteriaStatus,
  setCriteriaTarget,
  setCriteriaType,
  setGeographyType,
  setInitialState,
} from "@/redux/searchCriteriaSetupSlice"
import type {
  FilterType,
  GeographyType,
  SelectionType,
} from "@/types/entities/search-criteria"

export const useCriteriaSetup = (options?: { all: string[] }) => {
  const dispatch = useAppDispatch()

  const filters = useAppSelector(selectCriteriaFilters)
  const name = useAppSelector(selectCriteriaName)
  const masterListId = useAppSelector(selectCriteriaMasterListId)
  const buildType = useAppSelector(selectCriteriaType)
  const target = useAppSelector(selectCriteriaTarget)
  const filledFilters = useAppSelector(selectCriteriaFilledFilters)

  const resetCriteriaSetup = useCallback(
    () => dispatch(resetCriteria()),
    [dispatch],
  )
  const onNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => dispatch(setCriteriaName(value))
  const onDescriptionChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) =>
    dispatch(setCriteriaDescription(value))
  const onMasterListChange = (id: string) =>
    dispatch(setCriteriaMasterListId(id))
  const onStatusChange = (status: string) => dispatch(setCriteriaStatus(status))
  const onTypeChange = (type: string) => dispatch(setCriteriaType(type))
  const onGeographyTypeChange = (geographyType: GeographyType) =>
    dispatch(setGeographyType(geographyType))
  const onTargetChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setCriteriaTarget(value ? parseInt(value) : undefined))
  const onKeywordsChange = (value: string[]) =>
    dispatch(setCriteriaKeywords(value))
  const onDomainsChange = (value: string[]) =>
    dispatch(setCriteriaDomains(value))
  const onSingleSelectionChange =
    (type: SelectionType, filterType: FilterType) =>
    ({ target: { checked, value } }: React.ChangeEvent<HTMLInputElement>) => {
      const spotlightsValues = filters[filterType].selections[type]
      dispatch(
        setCriteriaFilterSelectionValue({
          type: filterType,
          selectionType: type,
          value: checked
            ? spotlightsValues.concat(value)
            : spotlightsValues.filter((v) => v !== value),
        }),
      )
    }
  const onStrictChange = ({
    filterType,
    checked,
  }: {
    filterType: FilterType
    checked: boolean
  }) => {
    dispatch(
      setCriteriaFilterStrictValue({
        type: filterType,
        checked,
      }),
    )
  }
  const onResetOneType = useCallback(
    ({ filterType }: { filterType: FilterType }) => {
      dispatch(
        setCriteriaFilterSelectionValue({
          type: filterType,
          selectionType: "EXCLUDED",
          value: [],
        }),
      )
      dispatch(
        setCriteriaFilterSelectionValue({
          type: filterType,
          selectionType: "INCLUDED",
          value: [],
        }),
      )
    },
    [dispatch],
  )
  const onTextFieldChange = ({
    type,
    value,
    filterType,
  }: {
    type: SelectionType
    filterType: FilterType
    value: string[]
  }) => {
    const otherType = type === "EXCLUDED" ? "INCLUDED" : "EXCLUDED"
    const otherValues = filters[filterType].selections[otherType]
    dispatch(
      setCriteriaFilterSelectionValue({
        type: filterType,
        selectionType: type,
        value: value,
      }),
    )
    // validate other field values by removing same value
    dispatch(
      setCriteriaFilterSelectionValue({
        type: filterType,
        selectionType: otherType,
        value: otherValues.filter(
          (otherV) => value.findIndex((newV) => newV === otherV) === -1,
        ),
      }),
    )
  }
  const onPostalCodeSubSelectionChange = (value: string | null) =>
    dispatch(
      setCriteriaFilterSubSelectionValue({
        type: "POSTAL_CODE",
        value: {
          id: "radius",
          value,
        },
      }),
    )
  const onFullSelectionsChange =
    (type: SelectionType, filterType: FilterType) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const otherType = type === "EXCLUDED" ? "INCLUDED" : "EXCLUDED"
      const otherValues = filters[filterType].selections[otherType]
      dispatch(
        setCriteriaFilterSelectionValue({
          type: filterType,
          selectionType: type,
          value: checked ? options!.all : [],
        }),
      )
      dispatch(
        setCriteriaFilterSelectionValue({
          type: filterType,
          selectionType: otherType,
          value: checked ? [] : otherValues,
        }),
      )
    }
  const onSelectionChange =
    (type: SelectionType, filterType: FilterType) =>
    ({ target: { value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const otherType = type === "EXCLUDED" ? "INCLUDED" : "EXCLUDED"
      const industryValues = filters[filterType].selections[type]!
      const otherIndustryValues = filters[filterType].selections[otherType]!
      dispatch(
        setCriteriaFilterSelectionValue({
          type: filterType,
          selectionType: type,
          value: checked
            ? industryValues.concat(value)
            : industryValues.filter((v) => v !== value),
        }),
      )
      dispatch(
        setCriteriaFilterSelectionValue({
          type: filterType,
          selectionType: otherType,
          value: checked
            ? otherIndustryValues.filter((v) => v !== value)
            : otherIndustryValues,
        }),
      )
    }
  const confirmDisabled = useMemo(
    () =>
      !name ||
      !masterListId ||
      (buildType === "manual" && !target) ||
      !!filledFilters.find(
        (filter) =>
          filter.type === "POSTAL_CODE" && !(filter as any).subSelection?.value,
      ),
    [buildType, filledFilters, masterListId, name, target],
  )
  const changeInitialState = useCallback(
    (payload: Omit<SearchCriteriaSetupState, "initialState">) => {
      dispatch(setInitialState(payload))
    },
    [dispatch],
  )
  const onSelectionDelete = useCallback(
    (
      removedV: string | number,
      type: SelectionType,
      filterType: FilterType,
    ) => {
      const values = filters[filterType].selections[type]
      dispatch(
        setCriteriaFilterSelectionValue({
          selectionType: type,
          type: filterType,
          value: values.filter((v) => removedV !== v),
        }),
      )
    },
    [dispatch, filters],
  )

  return {
    resetCriteriaSetup,
    onNameChange,
    onDescriptionChange,
    onMasterListChange,
    onStatusChange,
    onTypeChange,
    onTargetChange,
    onKeywordsChange,
    onDomainsChange,
    onSingleSelectionChange,
    onTextFieldChange,
    onPostalCodeSubSelectionChange,
    onSelectionChange,
    onFullSelectionsChange,
    onResetOneType,
    onStrictChange,
    onGeographyTypeChange,
    confirmDisabled,
    onSelectionDelete,
    changeInitialState,
  }
}
