import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useEditAdminUserMutation } from "@/redux/services/adminUsersApi"
import { setToast } from "@/redux/toastSlice"

export const useEditAdminUser = () => {
  const dispatch = useAppDispatch()
  const [editUser, response] = useEditAdminUserMutation()

  const handleEditUser = async ({
    body,
    id,
  }: {
    body: Partial<{
      firstName: string
      lastName: string
      email: string
      removePendingEmail?: boolean
    }>
    id: string
  }) => {
    try {
      await editUser({ body, id }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success edit user",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { editUser: handleEditUser, response }
}
