import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteSequenceMutation } from "@/redux/services/sequencesApi"
import { setToast } from "@/redux/toastSlice"
import { useParams } from "react-router-dom"

export const useDeleteSequence = () => {
  const dispatch = useAppDispatch()
  const { id: orgId = "" } = useParams()
  const [deleteSequence, response] = useDeleteSequenceMutation()

  const handleDeleteSequence = async ({ id }: { id: string }) => {
    try {
      const response = await deleteSequence({ id, orgId }).unwrap()
      if (response.success) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success delete sequence",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteSequence: handleDeleteSequence, response }
}
