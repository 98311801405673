import { FC, useState } from "react"
import cx from "classnames"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { BiPlus as AddIcon } from "react-icons/bi"
import { FiArrowLeft as ArrowLeft } from "react-icons/fi"
import { usePageTitle } from "@/hooks/usePageTitle"
import TextField from "@/components/TextField"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import EmailAccordion from "./features/EmailAccordion"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import DeleteButton from "@/components/Button/DeleteButton"
import SequenceDeleteModal from "../Sequences/features/SequenceDeleteModal"
import DiscardModal from "@/components/DiscardModal"
import ErrorMessage from "@/components/ErrorMessage"
import Tooltip from "@/components/Tooltip"
import InfoIcon from "@/components/icons/InfoIcon"
import Menu from "@/components/Menu"
import DuplicateIcon from "@/components/icons/DuplicateIcon"
import ReactRouterPrompt from "react-router-prompt"
import Loader from "@/components/Loader"
import { EmailSequenceFormContext } from "@/hooks/useEmailSequenceValues"
import { useEmailSequenceFormik } from "@/hooks/useEmailSequenceFormik"
import { useManualCopy } from "@/hooks/useManualCopy"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useSearchParamValue } from "@/hooks/useSearchParamValue"
import type { SequenceAPIResponse } from "@/types/entities/sequence"

const AddEditSequencePage: FC = () => {
  const { sequenceId = "" } = useParams()
  const duplicate = useSearchParamValue("duplicate")
  const location = useLocation()
  const isEdit = Boolean(sequenceId) && location.pathname.includes("edit")
  const navigate = useNavigate()
  const [, copy] = useManualCopy({ successMessage: "Variable copied" })
  const { pageFullAccess: sequencesUpdateFullAccess } = useUserAccess({
    pageName: "Sequences Update",
  })
  const [deleteModalData, setDeleteModalData] = useState<Pick<
    SequenceAPIResponse,
    "_id" | "name"
  > | null>(null)

  usePageTitle(() => (
    <div className="flex gap-3 items-center">
      <div
        onClick={() =>
          navigate({ pathname: isEdit ? "../.." : ".." }, { relative: "path" })
        }
        className="cursor-pointer rounded-full bg-bg-default flex justify-center items-center w-7 h-7"
      >
        <ArrowLeft className="opacity-80 w-[22px] h-[22px]" />
      </div>
      <span>{isEdit ? "Edit" : "Create"} Sequence</span>
    </div>
  ))
  const handleCopyVariable = (value: string) => async () => await copy(value)

  const {
    formik,
    isLoading,
    isError,
    addEmail,
    duplicateEmail,
    handleAddVersion,
    handleDeleteVersion,
    sequenceVariables,
    handleDuplicateVersion,
  } = useEmailSequenceFormik(isEdit)
  const {
    handleSubmit,
    getFieldProps,
    values: { emails, name },
    errors: { name: nameError },
    touched: { name: nameTouched },
    isSubmitting,
    dirty,
    isValid,
  } = formik

  const submitDisabled =
    !(Boolean(duplicate) ? true : dirty && isValid) || isSubmitting

  return (
    <EmailSequenceFormContext.Provider value={formik}>
      {isError ? (
        <ErrorMessage />
      ) : (
        <>
          {isLoading ? (
            <div className="h-full flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="flex flex-col">
              <div className="flex-1 bg-white rounded-md block-shadow p-3 sm:p-6 pb-8 z-0">
                <div
                  className={cx("flex items-center gap-2 z-50 relative", {
                    faded: !sequencesUpdateFullAccess,
                  })}
                >
                  <TextField
                    topLeftLabel="Sequence Name"
                    className="border-none input-sm"
                    placeholder="Enter sequence name"
                    rootClassName="[&_.label-text]:text-xs mb-8"
                    autoComplete="off"
                    bottomLeftLabel={
                      <div className="absolute -bottom-[.1rem]">
                        <FormFieldErrorMessage
                          error={nameError}
                          touched={nameTouched}
                        />
                      </div>
                    }
                    {...getFieldProps("name")}
                  />
                  <div className="flex gap-2 items-end -mt-2">
                    <Menu
                      items={sequenceVariables.map(({ display, value }) => (
                        <span onClick={handleCopyVariable(value)}>
                          {display}
                        </span>
                      ))}
                      className="[&_ul]:right-1 [&_label]:gap-0 outline outline-outline rounded-md"
                    >
                      <div className=" p-1.5 leading-6 text-sm pr-0">
                        Variables
                      </div>
                    </Menu>
                    <Tooltip
                      title={
                        <span>
                          For spintax, please use this formatting:
                          <p className="font-semibold">
                            {"{item1|item2|item3}."}
                          </p>
                        </span>
                      }
                      placement="topLeft"
                    >
                      <InfoIcon className="cursor-help -translate-y-2" />
                    </Tooltip>
                  </div>
                </div>
                <div className="flex flex-col gap-5 z-40 relative">
                  {emails.map((_, emailIndex) => (
                    <EmailAccordion
                      key={emailIndex}
                      emailIndex={emailIndex}
                      onVersionDelete={handleDeleteVersion}
                      onNewVersionAdd={handleAddVersion}
                      onVersionDuplicate={handleDuplicateVersion}
                    />
                  ))}
                </div>
                <div
                  className={cx(
                    "mt-4 flex flex-col items-center sm:flex-row flex-wrap gap-3",
                    {
                      faded: !sequencesUpdateFullAccess,
                    },
                  )}
                >
                  <OutlineButton
                    type="button"
                    onClick={addEmail}
                    className="border-none btn-base btn-primary"
                  >
                    <AddIcon className="w-6 h-6" />
                    <span className="font-medium">Add New Email</span>
                  </OutlineButton>
                  <div className="hidden sm:block border-l border-l-outline h-9 w-0.5"></div>
                  <OutlineButton
                    type="button"
                    onClick={duplicateEmail}
                    className="border-none btn-base btn-primary"
                  >
                    <DuplicateIcon />
                    <span className="font-medium">
                      Duplicate Email {emails.length}
                    </span>
                  </OutlineButton>
                </div>
              </div>
              {sequencesUpdateFullAccess && (
                <div className="justify-between mt-4 flex gap-5 flex-row-reverse">
                  <Button
                    disabled={submitDisabled}
                    type="submit"
                    className="btn-sm text-base"
                  >
                    {isEdit ? "Save" : "Confirm"}
                  </Button>
                  {isEdit && (
                    <DeleteButton
                      onClick={() =>
                        setDeleteModalData({ _id: sequenceId, name })
                      }
                      type="button"
                      outline
                    />
                  )}
                </div>
              )}
            </form>
          )}
        </>
      )}
      <SequenceDeleteModal
        sequence={deleteModalData}
        open={!!deleteModalData}
        onClose={() => setDeleteModalData(null)}
        onSubmit={() => navigate({ pathname: "../.." }, { relative: "path" })}
      />
      <ReactRouterPrompt when={dirty && !deleteModalData && !isSubmitting}>
        {({ isActive, onConfirm, onCancel }) => (
          <DiscardModal
            open={isActive}
            onConfirm={onConfirm}
            onClose={onCancel}
            canBeActivated
          />
        )}
      </ReactRouterPrompt>
    </EmailSequenceFormContext.Provider>
  )
}

export default AddEditSequencePage
