import { useMemo } from "react"
import { FormikErrors, useFormik } from "formik"
import { array, lazy, mixed, object, string } from "yup"
import { useAddSequence } from "@/pages/AddEditSequence/hooks/useAddSequence"
import { useNavigate, useParams } from "react-router-dom"
import {
  useGetSequenceVariablesQuery,
  useGetSequencesQuery,
} from "@/redux/services/sequencesApi"
import { useEditSequence } from "@/pages/AddEditSequence/hooks/useEditSequence"
import { useSearchParamValue } from "./useSearchParamValue"
import "@/core/validation/yupExtensions"
import type {
  ISequenceEmailVersion,
  SequenceEmail,
  SequenceForm,
} from "@/types/entities/sequence"
import type { OnVersionDeletePayload } from "@/pages/AddEditSequence/features/EmailAccordion"

function trimEmailTemplate(template: SequenceForm): SequenceForm {
  const trimString = (str: string) => str.trim()

  const trimEmail = (email: SequenceEmail): SequenceEmail => {
    const trimVersion = (
      version: ISequenceEmailVersion,
    ): ISequenceEmailVersion => {
      return {
        subjects: version.subjects?.map(trimString),
        greetings: version.greetings.map(trimString),
        body: version.body.trim(),
        unsubs: version.unsubs?.map(trimString),
        signoffs: version.signoffs.map(trimString),
      }
    }

    return {
      ...email,
      versions: email.versions.map(trimVersion),
    }
  }

  return {
    ...template,
    emails: template.emails.map(trimEmail),
  }
}
type ValidationResult = {
  isValid: boolean
  errorMessage?: string
}

function validateBody(input: string): ValidationResult {
  const stack: string[] = []
  for (let i = 0; i < input.length; i++) {
    if (input[i] === "{") {
      stack.push("{")
    } else if (input[i] === "}") {
      if (stack.length === 0) {
        return { isValid: false, errorMessage: "Missing spintax bracket" }
      }
      stack.pop()
    }
  }
  if (stack.length !== 0) {
    return { isValid: false, errorMessage: "Missing spintax bracket" }
  }

  const bracketContentPattern = /\{[^}]*\}/g
  const matches = input.match(bracketContentPattern)
  if (matches) {
    for (const match of matches) {
      if (!match.includes("|")) {
        return { isValid: false, errorMessage: "Missing | inside {}" }
      }
    }
  }

  const placeholderPattern = /^#[a-zA-Z0-9]+_[a-zA-Z0-9]+#$/
  const invalidPlaceholderPattern = /#[^#]*#/g

  const allPlaceholders = input.match(invalidPlaceholderPattern) || []
  for (const placeholder of allPlaceholders) {
    if (!placeholderPattern.test(placeholder)) {
      return {
        isValid: false,
        errorMessage: "Syntax should be #Sender_FirstName#",
      }
    }
  }

  return { isValid: true }
}

export const DEFAULT_VERSION_VALUE = {
  body: "",
  greetings: [""],
  signoffs: [""],
}

const validationSchema = object({
  name: string().required("Required"),
  emails: array()
    .of(
      object().shape({
        versions: array()
          .of(
            object({
              body: string()
                .required("Required")
                .test((value, ctx) => {
                  if (!value) return true // Skip validation if value is not provided

                  const result = validateBody(value)

                  if (result.isValid) {
                    return true
                  }
                  return ctx.createError({
                    message: result.errorMessage || "Invalid format",
                  })
                }),
              greetings: array()
                .of(string().required("Required").testSpintax())
                .min(1, "Version must have at least 1 Greeting.")
                .required(),
              signoffs: array()
                .of(string().required("Required").testSpintax())
                .min(1, "Version must have at least 1 Sign Off.")
                .required(),
              subjects: lazy((v) =>
                v?.length
                  ? array()
                      .of(string().required("Required").testSpintax())
                      .min(1, "Version must have at least 1 Subject.")
                      .required("123")
                  : mixed(),
              ),
              unsubs: lazy((v) =>
                v?.length
                  ? array()
                      .of(string().required("Required").testSpintax())
                      .min(1, "Version must have at least 1 Unsub.")
                      .required("123")
                  : mixed(),
              ),
            }),
          )
          .min(1, "Email must have at least 1 version."),
      }),
    )
    .min(1, "Sequence must have at least 1 email"),
})

export type SequenceEmailError = FormikErrors<{
  description: string
  delayUnit?: "days" | "hours" | undefined
  delay?: string | undefined
  versions: {
    subjects: string[]
    greetings: string[]
    body: string
    signoffs: string[]
    unsubs: string[]
  }[]
}>[]
export type SequenceEmailVersionError = FormikErrors<{
  subjects: string[]
  greetings: string[]
  body: string
  signoffs: string[]
  unsubs: string[]
}>[]

export const useEmailSequenceFormik = (isEdit: boolean) => {
  const { addSequence } = useAddSequence()
  const { editSequence } = useEditSequence()
  const navigate = useNavigate()

  const { sequenceId = "", id: orgId = "" } = useParams()
  const duplicate = useSearchParamValue("duplicate")

  const {
    sequence,
    isLoading: sequenceLoading,
    isError,
  } = useGetSequencesQuery(
    { orgId },
    {
      skip: !orgId,
      selectFromResult: ({ data, ...rest }) => ({
        sequence: data?.items.find(
          (item) => item._id === (duplicate ?? sequenceId),
        ),
        ...rest,
      }),
    },
  )
  const { data: sequenceVariables = [], isLoading: variablesLoading } =
    useGetSequenceVariablesQuery(undefined)
  const sequenceFormData = useMemo(() => {
    if (!sequence) {
      return null
    }
    const { name, emails } = sequence
    const formMappedSequence: SequenceForm = {
      name: duplicate ? `${name} - Copy` : name,
      emails: emails.map(({ description, delay, delayUnit, versions }) => ({
        delayUnit,
        delay,
        versions: versions.map(({ enabled, ...rest }) => ({
          ...rest,
        })),
      })),
    }
    return formMappedSequence
  }, [sequence, duplicate])

  const formik = useFormik<SequenceForm>({
    enableReinitialize: true,
    initialValues: sequenceFormData ?? {
      name: "",
      emails: [
        {
          versions: [
            { ...DEFAULT_VERSION_VALUE, subjects: [""], unsubs: [""] },
          ],
        },
      ],
    },
    validationSchema,
    onSubmit: async (vals) => {
      const body = trimEmailTemplate(vals)
      try {
        isEdit
          ? await editSequence({ body, id: sequenceId })
          : await addSequence(body)
        navigate({ pathname: isEdit ? "../.." : ".." }, { relative: "path" })
      } catch (e) {
        console.error(e)
      }
    },
  })
  const handleDeleteVersion = ({
    emailIndex,
    removedVersionIndex,
  }: OnVersionDeletePayload) => {
    formik.setValues((prev) => ({
      ...prev,
      emails: prev.emails.map((email, emailI) =>
        emailIndex === emailI
          ? {
              ...email,
              versions: email.versions.filter(
                (_, i) => i !== removedVersionIndex,
              ),
            }
          : email,
      ),
    }))
  }
  const handleAddVersion = (emailIndex: number) => {
    formik.setValues((prev) => ({
      ...prev,
      emails: prev.emails.map((email, emailI) =>
        emailIndex === emailI
          ? {
              ...email,
              versions: JSON.parse(
                JSON.stringify(
                  email.versions.concat(
                    Object.assign(
                      { ...DEFAULT_VERSION_VALUE },
                      emailIndex === 0
                        ? {
                            unsubs: [""],
                            subjects: [""],
                          }
                        : null, // first email has unsubs
                    ),
                  ),
                ),
              ),
            }
          : email,
      ),
    }))
  }
  const handleDuplicateVersion = (emailIndex: number, versionIndex: number) => {
    formik.setValues((prev) => ({
      ...prev,
      emails: prev.emails.map((email, emailI) => {
        if (emailI !== emailIndex) {
          return email
        }
        const duplicatedVersion = email.versions.find(
          (_, versionI) => versionI === versionIndex,
        )!
        return {
          ...email,
          versions: JSON.parse(
            JSON.stringify(email.versions.concat({ ...duplicatedVersion })),
          ),
        }
      }),
    }))
  }
  const duplicateEmail = () => {
    formik.setValues((prev) => {
      const lastEmail = prev.emails[prev.emails.length - 1]
      return {
        ...prev,
        emails: prev.emails.concat(
          JSON.parse(
            JSON.stringify({
              delay: lastEmail.delay ?? "4",
              delayUnit: lastEmail.delayUnit ?? "hours",
              versions: lastEmail.versions.map(
                ({ body, greetings, signoffs }) => ({
                  body,
                  greetings,
                  signoffs,
                }),
              ),
            }),
          ),
        ),
      }
    })
  }
  const addEmail = () => {
    formik.setValues((prev) => ({
      ...prev,
      emails: prev.emails.concat({
        delay: "4",
        delayUnit: "hours",
        versions: JSON.parse(JSON.stringify([{ ...DEFAULT_VERSION_VALUE }])),
      }),
    }))
  }
  const isLoading = sequenceLoading || variablesLoading

  return {
    formik,
    sequenceVariables,
    isLoading,
    addEmail,
    duplicateEmail,
    handleAddVersion,
    handleDeleteVersion,
    handleDuplicateVersion,
    isError,
  }
}
export type SequenceFormValues = ReturnType<typeof useEmailSequenceFormik>
