import classnames from "classnames"
import { twMerge } from "tailwind-merge"

export const camelCaseToWords = (camelCaseString: string) => {
  // Use a regular expression to split the string at each capital letter
  const wordsArray = camelCaseString.split(/(?=[A-Z])/)

  // Join the array of words into a sentence
  let wordsSentence = wordsArray.join(" ")

  // Capitalize the first letter of the sentence
  wordsSentence = wordsSentence.charAt(0).toUpperCase() + wordsSentence.slice(1)

  return wordsSentence
}

export const buildQueryString = (params: Record<string, any>) => {
  const queryString = Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        // Handle array values
        return params[key]
          .map(
            (value: any, index: number) =>
              `${encodeURIComponent(key)}[${index}]=${encodeURIComponent(
                value,
              )}`,
          )
          .join("&")
      } else if (
        params[key] !== "" &&
        params[key] !== null &&
        params[key] !== undefined
      ) {
        // Handle non-empty values
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      } else {
        return "" // Ignore empty values
      }
    })
    .filter(Boolean) // Remove empty strings from the array
    .join("&")

  return queryString
}

export const setOpacityToHexColor = (
  hexColor: string,
  opacity: number = 0.6,
) => {
  const hexColorRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i
  if (!hexColorRegex.test(hexColor)) {
    throw new Error("Invalid hex color code")
  }

  // If the hex color has a 3-character shorthand, expand it to 6 characters
  if (hexColor.length === 4) {
    hexColor =
      "#" +
      hexColor[1] +
      hexColor[1] +
      hexColor[2] +
      hexColor[2] +
      hexColor[3] +
      hexColor[3]
  }

  // Extract RGB values from hex color
  const r = parseInt(hexColor.slice(1, 3), 16)
  const g = parseInt(hexColor.slice(3, 5), 16)
  const b = parseInt(hexColor.slice(5, 7), 16)

  const rgbaColor = `rgba(${r},${g},${b},${opacity})`

  return rgbaColor
}

export const toCapitalize = (word: string) => {
  return word.length > 1 ? word[0].toUpperCase() + word.slice(1) : ""
}

export const replacePlaceholders = (
  template: string,
  params: Record<string, any>,
): string => {
  const regex = /{(\w+)}/g

  return template.replace(regex, (match, placeholder) => {
    if (params.hasOwnProperty(placeholder)) {
      return params[placeholder]
    }
    return match // If placeholder not found in params, keep the original placeholder
  })
}

export const wrapStrings = (strings: string[]): string[] => {
  const wrappedStrings: string[] = []

  strings.forEach((str) => {
    if (str.startsWith('"') && str.endsWith('"')) {
      wrappedStrings.push(str)
    } else {
      wrappedStrings.push(`"${str}"`)
    }
  })

  return wrappedStrings
}

export const getContentDispositionFileName = (
  response: Response,
  defaultName: string,
) =>
  response.headers
    .get("Content-Disposition")
    ?.split(";")
    .find((n) => n.includes("filename="))
    ?.replace("filename=", "")
    .trim() ?? defaultName

export const cx = (...args: classnames.ArgumentArray) => {
  return twMerge(classnames(...args))
}

export const replaceLettersForX = (text: string, X: string = "X") => {
  return text
    .split("")
    .map(() => X)
    .join("")
}

export function updateEmailParagraphs(text: string): string {
  const paragraphs = text.split("\n\n")
  const maxLineLength = 80

  function formatParagraph(paragraph: string, prefix: string): string {
    const words = paragraph.split(" ")
    let formattedParagraph = ""
    let line = prefix

    words.forEach((word) => {
      if (line.length + word.length + 1 > maxLineLength) {
        formattedParagraph += line + "\n"
        line = prefix
      }
      line += (line === prefix ? "" : " ") + word
    })

    formattedParagraph += line
    return formattedParagraph
  }

  return paragraphs
    .map((paragraph) => {
      const lines = paragraph.split("\n")
      // const maxPrefixCount = Math.max(
      //   ...lines.map((line) => (line.match(/^>+/) || [""])[0].length),
      // )
      return lines
        .map((line, index) => {
          const currentPrefix = (line.match(/^>+/) || [""])[0]
          const remainingLine = line.slice(currentPrefix.length)
          const spacePrefix = remainingLine.match(/^\s*/)![0]
          const content = remainingLine.trim()

          const prefix = ">".repeat(currentPrefix.length) + spacePrefix
          return formatParagraph(content, prefix)
        })
        .join("\n")
    })
    .join("\n\n")
}
