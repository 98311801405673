import { FC, useMemo, useState } from "react"
import cx from "classnames"
import Table from "@/components/Table"
import {
  extractClassificationsValues,
  getDateStringFromObject,
  sortDashboardDateStrings,
} from "../../utils"
import { toCapitalize } from "@/utils/strings"
import { getClassificationName } from "@/pages/ReplyClassification/utils"
import { useGetReplyClassificationsQuery } from "@/redux/services/generalApi"
import { convertDateMonthYearStringToMonthDateYear } from "@/utils/dates"
import { useDashboard } from "../../hooks/useDashboard"
import { generateLabels } from "../../hooks/utils"
import type { ColumnsType } from "antd/es/table"
import type { IdType, ReportingData } from "@/types/entities/organization"

type Props = {
  className?: string
  emails: ReportingData[]
}

type TableDataType = {
  key: string
  date: any
  prospects: number
  followUps: number
  replies: number
  leads: number
  totalEmails: number
} & { [key: string]: any }

const CampaignChartTable: FC<Props> = ({
  className,
  emails,
  ...rest
}: Props) => {
  const { data: currClassifications = [] } = useGetReplyClassificationsQuery()
  const [pageSize, setPageSize] = useState(15)
  const { from, to, grouping } = useDashboard()
  const labels = useMemo(
    () => generateLabels(from, to, grouping),
    [from, grouping, to],
  )

  const columns = useMemo<ColumnsType<TableDataType>>(() => {
    const classifications = emails.reduce((acc, email) => {
      const currEmailClassifications =
        Object.keys(
          extractClassificationsValues(email as Record<string, number>),
        ) ?? []
      const newAcc = acc.concat(currEmailClassifications)
      const accSet = new Set(newAcc)
      return [...accSet]
    }, Array<string>())

    return [
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
        sorter: (a: TableDataType, b: TableDataType) =>
          sortDashboardDateStrings(b.date, a.date),
        render: (v: string) =>
          grouping === "day" ? convertDateMonthYearStringToMonthDateYear(v) : v,
      },
      {
        title: "Prospects",
        key: "prospects",
        dataIndex: "prospects",
        sorter: (a: TableDataType, b: TableDataType) =>
          a.prospects - b.prospects,
      },
      {
        title: "Follow Ups",
        key: "followUps",
        dataIndex: "followUps",
        sorter: (a: TableDataType, b: TableDataType) =>
          a.followUps - b.followUps,
      },
      {
        title: "Replies",
        key: "replies",
        dataIndex: "replies",
        sorter: (a: TableDataType, b: TableDataType) => a.replies - b.replies,
      },
      {
        title: "Leads",
        key: "leads",
        dataIndex: "leads",
        sorter: (a: TableDataType, b: TableDataType) => a.leads - b.leads,
      },
      {
        title: "Total Emails",
        key: "totalEmails",
        dataIndex: "totalEmails",
        sorter: (a: TableDataType, b: TableDataType) =>
          a.totalEmails - b.totalEmails,
      },
    ].concat(
      classifications.map((classification) => ({
        title:
          getClassificationName(currClassifications, classification) ??
          toCapitalize(classification),
        key: classification,
        dataIndex: classification,
        sorter: (a, b) => {
          if (!a[classification]) {
            return -1
          }
          if (!b[classification]) {
            return 1
          }
          return a[classification as any] - b[classification as any]
        },
        showSorterTooltip: {
          title: () =>
            getClassificationName(currClassifications, classification),
        },
        render: (v: string | number) => (v ? v : 0) as string,
        className:
          "[&_.ant-table-column-title]:max-w-[175px] [&_.ant-table-column-title]:truncate",
      })),
    )
  }, [emails, currClassifications, grouping])
  const dataSource = useMemo<TableDataType[]>(
    () =>
      emails
        .map(({ _id, ...rest }) => ({
          date: getDateStringFromObject(_id),
          _id,
          ...rest,
        }))
        .concat(
          labels.map((date) => ({
            date,
            followup: 0,
            new: 0,
            total: 0,
            lead: 0,
            reply: 0,
            _id: {} as IdType,
          })),
        )
        .reduce((acc, curr) => {
          if (acc.find(({ date }) => date === curr.date)) {
            return acc
          }
          return acc.concat(curr as any)
        }, Array<TableDataType>())
        .sort((a, b) => {
          const aDate = a.date
          const bDate = b.date
          return sortDashboardDateStrings(aDate, bDate)
        })
        .map(
          (
            {
              _id,
              date,
              followup = 0,
              new: prospects = 0,
              total = 0,
              lead = 0,
              reply = 0,
              ...rest
            },
            i,
          ) => ({
            date,
            ...rest,
            key: i.toString(),
            followUps: followup,
            leads: lead,
            prospects,
            replies: reply,
            totalEmails: total,
          }),
        ),
    [emails, labels],
  )
  return (
    <div className="z-0 relative bg-white px-2 py-1 rounded-md block-shadow">
      <Table
        columns={columns}
        dataSource={dataSource}
        className={cx("z-0 relative", className)}
        showSizeChanger
        total={labels.length}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        truncate
        {...rest}
      />
    </div>
  )
}

export default CampaignChartTable
