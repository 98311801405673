import { FC } from "react"
import Modal, { ModalProps } from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import { useDeleteSearchCriteria } from "../../hooks/useDeleteSearchCriteria"
import type { SearchCriteria } from "@/types/entities/search-criteria"

type Props = ModalProps & {
  data?: SearchCriteria
  onDeleteSuccess?: () => void
}

const DeleteSearchCriteriaModal: FC<Props> = ({
  open,
  onClose,
  data,
  onDeleteSuccess,
}: Props) => {
  const { deleteCriteria, response } = useDeleteSearchCriteria()
  if (!data) {
    return null
  }
  const handleDelete = async () => {
    await deleteCriteria(data._id)
    onClose && onClose()
    onDeleteSuccess && onDeleteSuccess()
  }
  return (
    <Modal
      open={open}
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={response.isLoading} onClick={onClose} />
          <DeleteButton
            type="submit"
            tabIndex={0}
            className="bg-error"
            disabled={response.isLoading}
          />
        </div>
      }
      title="Delete Search Criteria"
      onClose={onClose}
      onSubmit={handleDelete}
    >
      <span className="text-base font-normal leading-6">
        Are you sure you want to delete{" "}
        <span className="font-semibold">“{data.name}”</span>?
      </span>
    </Modal>
  )
}

export default DeleteSearchCriteriaModal
