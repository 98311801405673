import { FC } from "react"
import { prospectsStatusColorMap } from "../../utils/constants"

type Props = {
  status: string
}

const ProspectsStatusColor: FC<Props> = ({ status }: Props) => {
  const color =
    prospectsStatusColorMap[status as keyof typeof prospectsStatusColorMap]
  return (
    <div
      className="rounded-full h-2.5 w-2.5"
      style={{ backgroundColor: color }}
    ></div>
  )
}

export default ProspectsStatusColor
