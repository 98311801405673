import { useMemo } from "react"
import { useStatusOrganizations } from "./useStatusOrganizations"

type RoleAccess = "no" | "view" | "full"
type Layout = "left" | "top"
type Props = { pageName: string } | void

export const useUserAccess = (props: Props) => {
  const userLayout = useMemo<Layout>(() => "left", [])
  const { currentOrgActive } = useStatusOrganizations()

  const access: RoleAccess = currentOrgActive ? "full" : "view"

  const accessConfig = useMemo<Record<string, RoleAccess>>(
    () => ({
      Dashboard: access, //

      Inbox: access, //

      Prospects: access, //

      Campaigns: access, //
      "Campaigns Update": access, //

      Sequences: access, //
      "Sequences Update": access, //

      Targeting: access, //
      "Targeting Update": access, //

      "Master Lists": access, //
      "Master Lists Update": access, //

      Senders: access, //

      "Email Addresses": access, //

      Domains: access, //
      "Domains Update": access, //

      "DNC List": access, //

      "Manage Organization": access, //
    }),
    [access],
  )

  const accessedPages = useMemo(
    () =>
      Object.entries(accessConfig)
        .filter(([, access]) => access !== "no")
        .map(([page]) => page),
    [accessConfig],
  )

  const pageAccess = useMemo(() => {
    if (!props?.pageName) {
      return null
    }
    return accessConfig[props.pageName]
  }, [props, accessConfig])

  const pageFullAccess = useMemo(() => {
    if (!props?.pageName) {
      return false
    }
    return accessConfig[props.pageName] === "full"
  }, [props, accessConfig])

  const pageAnyAccess = useMemo(() => {
    if (!props?.pageName) {
      return false
    }
    return accessConfig[props.pageName] !== "no"
  }, [props, accessConfig])

  return {
    userLayout,
    accessedPages,
    pageAccess,
    pageFullAccess,
    pageAnyAccess,
  }
}
