import { FC, useState } from "react"
import cx from "classnames"
import { useParams, useSearchParams } from "react-router-dom"
import { usePageTitle } from "@/hooks/usePageTitle"
import { usePagination } from "@/hooks/usePagination"
import TextField from "@/components/TextField"
import SearchIcon from "@/assets/search.svg"
import Button from "@/components/Button"
import AddIcon from "@/components/icons/AddIcon"
import EmptyList from "@/components/EmptyList"
import EmailAddressesTable from "./features/EmailAddressesTable"
import SendersDropdown from "./features/SendersDropdown"
import AddEmailModal from "./features/AddEmailModal"
import ErrorMessage from "@/components/ErrorMessage"
import Loader from "@/components/Loader"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useClientRect } from "@/hooks/useClientRect"
import { useGetEmailsQuery } from "@/redux/services/sendersApi"
import { POLLING_INTERVAL } from "@/constants"
import type { AddEmailAddressResponse } from "@/types/entities/email-address"

type Props = {
  className?: string
}

const EmailAddressesPage: FC<Props> = ({ className, ...rest }: Props) => {
  usePageTitle("Email Addresses")
  const { id: orgId = "" } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [rect, ref] = useClientRect(true)
  const { pageFullAccess } = useUserAccess({ pageName: "Email Addresses" })
  const senderOptionValue = searchParams.get("senderId") ?? ""
  const {
    searchTerm,
    handleSearchTermChange,
    handlePageChange,
    handlePageSizeChange,
    limit,
    skip,
    text,
  } = usePagination()
  const {
    data: { items: emails, total } = { items: [], total: 0 },
    isLoading,
    isFetching,
    isError,
  } = useGetEmailsQuery(
    { orgId, limit, senderId: senderOptionValue, skip, text },
    {
      skip: !orgId,
      pollingInterval: POLLING_INTERVAL,
      refetchOnMountOrArgChange: true,
    },
  )
  const [openAddModal, setOpenAddModal] = useState(false)
  const [addedEmail, setAddedEmail] = useState<
    AddEmailAddressResponse | null | undefined
  >(null)
  const handleChange = (v: string) =>
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      if (v) {
        newParams.set("senderId", v)
      } else {
        newParams.delete("senderId")
      }
      return newParams
    })
  const handleOpenAddModal = () => setOpenAddModal(true)

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className={cx("h-full flex flex-col", className)} {...rest}>
        <div className="flex justify-between items-center gap-1">
          <div className="flex gap-1 items-center sm:gap-3">
            <TextField
              value={searchTerm}
              onChange={handleSearchTermChange}
              placeholder="Search email address"
              startIcon={<SearchIcon className="w-6 h-6" />}
              className="text-sm outline outline-outline !h-9 !py-1.5"
              rootClassName="basis-56"
            />
            <SendersDropdown
              value={senderOptionValue}
              onChange={handleChange}
              className="min-w-[126px] sm:min-w-[176px]"
            />
          </div>
          {pageFullAccess && (
            <Button
              onClick={handleOpenAddModal}
              className="btn-primary btn-sm !h-9 !py-1.5"
            >
              <AddIcon />
              <span className="hidden sm:inline">Add Email</span>
            </Button>
          )}
        </div>
        <div
          className={cx(
            "flex-1 overflow-y-auto mt-5 flex bg-white rounded-md shadow-[0px_1px_4px_0px_rgba(0,0,0,0.06)] px-2 py-1 min-h-[380px]",
            { "justify-center items-center": !emails.length },
          )}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {emails.length ? (
                <div ref={ref} className="w-full">
                  <EmailAddressesTable
                    emails={emails}
                    page={skip}
                    pageSize={limit}
                    total={total}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    maxHeight={rect?.height ?? 0}
                    loading={isFetching}
                  />
                </div>
              ) : (
                <EmptyList message="There are no email addresses yet">
                  {pageFullAccess && (
                    <Button
                      onClick={handleOpenAddModal}
                      className="btn-sm group bg-white hover:bg-primary border !border-solid border-outline-dark hover:border-primary"
                    >
                      <AddIcon className="transition-colors text-primary group-hover:text-white" />
                      <span className="transition-colors font-medium text-primary group-hover:text-white">
                        Add Email
                      </span>
                    </Button>
                  )}
                </EmptyList>
              )}
            </>
          )}
        </div>
      </div>
      {(openAddModal || addedEmail) && (
        <AddEmailModal
          open={openAddModal}
          onClose={() => setOpenAddModal(false)}
          addedEmail={addedEmail}
          onAddedEmailChange={setAddedEmail}
        />
      )}
    </>
  )
}

export default EmailAddressesPage
