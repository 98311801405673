import { useGetSendersQuery } from "@/redux/services/sendersApi"
import { useParams } from "react-router-dom"

export const useSenders = (
  {
    refetchOnMountOrArgChange,
  }: {
    refetchOnMountOrArgChange: boolean
  } = { refetchOnMountOrArgChange: false },
) => {
  const { id: orgId = "" } = useParams()
  const { data: { items: senders, total } = { items: [], total: 0 }, ...rest } =
    useGetSendersQuery({ orgId }, { skip: !orgId, refetchOnMountOrArgChange })
  return {
    senders,
    total,
    ...rest,
  }
}
