import { useFormik } from "formik"
import validationSchema from "../validationSchema"
import type { SenderForm, Sender } from "@/types/entities/sender"

export const useSenderForm = ({
  data,
  onSubmit,
}: {
  data?: Sender
  onSubmit: (values: SenderForm) => void
}) => {
  const formik = useFormik<SenderForm>({
    initialValues: data
      ? {
          ...data,
          address: {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipCode: "",
            completeAddress: "",
            postalCode: "",
            province: "",
            ...data.address,
            countryCode: data.address.countryCode ?? "US",
          },
        }
      : {
          email: "",
          address: {
            address1: "",
            address2: "",
            city: "",
            countryCode: "",
            state: "",
            zipCode: "",
            completeAddress: "",
            postalCode: "",
            province: "",
          },
          titles: [""],
          companies: [""],
          websites: [""],
          firstName: "",
          lastName: "",
          phoneNumber: "",
        },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  })
  return formik
}
