import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useEditCampaignMutation } from "@/redux/services/campaignsApi"
import { setToast } from "@/redux/toastSlice"
import type { EditCampaignPayload } from "@/types/entities/campaign"

export const useEditCampaign = () => {
  const dispatch = useAppDispatch()
  const [editCampaign, response] = useEditCampaignMutation()

  const handleEditCampaign = async (
    {
      body,
      id,
    }: {
      id: string
      body: EditCampaignPayload
    },
    showToast: boolean,
  ) => {
    try {
      const response = await editCampaign({ body, id }).unwrap()
      if (response.data) {
        if (showToast) {
          dispatch(
            setToast({
              show: true,
              variant: "success",
              message: "Success edit campaign",
            }),
          )
        }
        return response.data
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { editCampaign: handleEditCampaign, response }
}
