import { Link } from "react-router-dom"
import { useFormik } from "formik"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import { Routes } from "@/core/routing/routes"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import validationSchema from "./validation-schema"
import { useLogin } from "@/hooks/useLogin"

const LoginForm = () => {
  const { loginUser, response } = useLogin()
  const {
    handleSubmit,
    getFieldProps,
    errors: { email: emailError, password: passwordError },
    touched: { email: emailTouched, password: passwordTouched },
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: ({ email, password }) => {
      loginUser({ email, password })
    },
  })
  return (
    <form className="flex flex-col gap-5 w-full" onSubmit={handleSubmit}>
      <TextField
        type="email"
        placeholder="Enter your email address"
        topLeftLabel="Email Address"
        tabIndex={0}
        bottomLeftLabel={
          <FormFieldErrorMessage error={emailError} touched={emailTouched} />
        }
        {...getFieldProps("email")}
      />
      <TextField
        type="password"
        placeholder="Enter your password"
        topLeftLabel="Password"
        topRightLabel={
          <Link
            tabIndex={-1}
            to={Routes.forgotPassword.form}
            className="text-xs underline sm:text-sm text-black font-normal"
          >
            Forgot Password?
          </Link>
        }
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={passwordError}
            touched={passwordTouched}
          />
        }
        tabIndex={0}
        {...getFieldProps("password")}
      />
      <Button
        fullWidth
        disabled={response.isLoading}
        className="mt-1"
        type="submit"
      >
        Login
      </Button>
    </form>
  )
}

export default LoginForm
