import { FC } from "react"
import cx from "classnames"
import Modal, { type ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import MicrosoftOAuthLogin from "@/components/MicrosoftOAuthLogin"
import EmailConnectionStatus from "@/components/EmailConnectionStatus"
import { useAdminAccountLogin } from "../hooks/useAdminAccountLogin"
import type { GsuiteAccount } from "@/types/entities/gsuite-account"

type Props = {
  className?: string
  data?: GsuiteAccount
} & ModalProps

const AddEditMicrosoftAccountModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const isEdit = Boolean(data)
  const {
    disconnectMail,
    isLoading,
    title,
    setFieldValue,
    submitDisabled,
    getFieldProps,
    handleSubmit,
  } = useAdminAccountLogin()
  const handleDisconnect = async () => {
    if (data) {
      await disconnectMail(data._id)
      setFieldValue("code", "")
    }
  }

  const submitBtnText = isEdit ? "Save" : "Add"
  const code = getFieldProps("code").value

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            type="button"
            className="btn-sm text-black min-w-[86px]"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            type="submit"
            className="btn-sm min-w-[86px]"
            disabled={submitDisabled}
          >
            {submitBtnText}
          </Button>
        </div>
      }
      className={cx("", className)}
      {...rest}
    >
      <div className="flex flex-col gap-5 [&_.label]:pt-0">
        <TextField
          className="input-sm"
          placeholder="Enter microsoft account name"
          topLeftLabel="Account Name"
          {...getFieldProps("displayName")}
        />
        {data?.email && data.connected ? (
          <EmailConnectionStatus
            connected={data.connected}
            connectedTo={data.email}
            onDisconnect={handleDisconnect}
          />
        ) : code && code !== "-" ? (
          <EmailConnectionStatus connected connectedTo={null} />
        ) : (
          <MicrosoftOAuthLogin
            onSuccess={(codeResponse) =>
              setFieldValue("code", codeResponse.code)
            }
            scope="offline_access https://graph.microsoft.com/Policy.ReadWrite.SecurityDefaults https://graph.microsoft.com/User.ReadWrite.All https://graph.microsoft.com/Directory.ReadWrite.All https://graph.microsoft.com/Domain.ReadWrite.All https://graph.microsoft.com/Application.ReadWrite.All"
          />
        )}
        <TextField
          className="input-sm"
          placeholder="10"
          topLeftLabel="Max Accounts"
          {...getFieldProps("maxDomains")}
        />
      </div>
    </Modal>
  )
}

export default AddEditMicrosoftAccountModal
