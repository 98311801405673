import { FC } from "react"
import cx from "classnames"
import { formatDateToDateTime } from "@/utils/dates"
import { getDisplayClassification } from "../../utils"
import Tooltip from "@/components/Tooltip"
import { updateEmailParagraphs } from "@/utils/strings"
import type { Inbox } from "@/types/entities/inbox"

type Props = {
  className?: string
  inbox: Inbox
}

const InboxMessage: FC<Props> = ({ className, inbox, ...rest }: Props) => {
  const displayClassification = getDisplayClassification(inbox)
  const name = `${inbox.from.firstName ?? ""} ${inbox.from.lastName ?? ""}`

  const message = inbox.reply
    ? updateEmailParagraphs(inbox.reply).split("\n")
    : []

  return (
    <div
      className={cx(
        "p-3 rounded-md bg-others border border-outline flex flex-col gap-3",
        className,
      )}
      {...rest}
    >
      <div className="pb-2 border-b border-outline-dark">
        <span className="flex flex-col justify-between md:flex-col xl:flex-row lg:items-start md:justify-between md:whitespace-nowrap">
          <div className="order-2 xl:order-1 flex flex-col xl:items-center gap-1 xl:flex-row md:gap-2 lg:max-w-xs xl:max-w-none xl:truncate 2xl:overflow-visible">
            <Tooltip title={name}>
              <span className="text-sm leading-[22px] font-semibold truncate">
                {name}
              </span>
            </Tooltip>
            <Tooltip
              title={inbox.from.email}
              rootClassName="max-w-[fit-content]"
            >
              <span className="opacity-60 text-xs truncate">
                &lt;
                {inbox.from.email}
                &gt;
              </span>
            </Tooltip>
          </div>
          <span className="order-1 xl:order-2 text-xs leading-[22px] font-medium md:whitespace-nowrap">
            {displayClassification}
          </span>
        </span>
        <div className="flex flex-col gap-1 xl:flex-row justify-between text-xs w-full opacity-60 whitespace-nowrap">
          <span>
            to: <u>{inbox.to?.map(({ email }) => email)?.join(", ")}</u>
          </span>
          <span>Received on {formatDateToDateTime(inbox.receivedAt)}</span>
        </div>
        {inbox.cc?.length ? (
          <div className="mt-1 flex md:justify-start text-xs opacity-60">
            <span>
              cc: <u>{inbox.cc?.map(({ email }) => email)?.join(", ")}</u>
            </span>
          </div>
        ) : null}
      </div>
      <div className="flex-1 space-y-3 overflow-y-auto whitespace-pre-wrap text-sm relative">
        {message.map((line, i) => {
          return (
            <div className="flex" key={i}>
              {line.split(/(>)/g).map((part, j, arr) => {
                const currentString = arr.slice(0, j)
                return part === ">" &&
                  currentString.reduce((acc, curr, i) => {
                    if (!acc && i > 0) {
                      return false
                    }
                    const str = curr.trim()
                    if (str === ">" || str === "") {
                      return true
                    }
                    return false
                  }, false) ? (
                  <span key={j} className="line-starts-with-gt"></span>
                ) : (
                  <div key={j}>{part.trim()}</div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default InboxMessage
