import type {
  SequenceEmailError as eR,
  SequenceEmailVersionError as eVR,
} from "@/hooks/useEmailSequenceFormik"
import type { SequenceEmailVersionFields } from "@/types/entities/sequence"

export const getNestedFieldErrorMessage = ({
  fieldName,
  emailsError,
  emailIndex,
  versionIndex,
  itemIndex,
}: {
  fieldName: SequenceEmailVersionFields
  emailIndex: number
  versionIndex: number
  itemIndex: number
  emailsError?: string | string[] | eR
}) => {
  try {
    return Array.isArray(emailsError)
      ? Array.isArray((emailsError as eR)[emailIndex]?.versions)
        ? Array.isArray(
            ((emailsError as eR)[emailIndex]?.versions as eVR)[versionIndex][
              fieldName
            ],
          )
          ? ((emailsError as eR)[emailIndex].versions as eVR)[versionIndex][
              fieldName
            ]![itemIndex]
          : null
        : null
      : null
  } catch (e) {
    return null
  }
}
export const checkNestedFieldTouched = ({
  emailsTouched,
  emailIndex,
  itemIndex,
  versionIndex,
  fieldName,
}: {
  fieldName: SequenceEmailVersionFields
  emailsTouched?: eR
  emailIndex: number
  versionIndex: number
  itemIndex: number
}) => {
  const a =
    emailsTouched &&
    Array.isArray(emailsTouched[emailIndex]?.versions) &&
    Array.isArray((emailsTouched[emailIndex].versions as eVR)[versionIndex]) &&
    Array.isArray(
      (emailsTouched[emailIndex].versions as eVR)[versionIndex][fieldName],
    )
      ? Boolean(
          (emailsTouched[emailIndex].versions as eVR)[versionIndex][fieldName]![
            itemIndex
          ],
        )
      : false

  return a
}
