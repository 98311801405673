import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useSetUserStatusMutation } from "@/redux/services/adminUsersApi"
import { setToast } from "@/redux/toastSlice"

export const useSetUserStatus = () => {
  const dispatch = useAppDispatch()
  const [setUserStatus, response] = useSetUserStatusMutation()

  const handleSetUserStatus = async ({
    id,
    body,
  }: {
    id: string
    body: { status: "enabled" | "disabled" }
  }) => {
    try {
      await setUserStatus({ id, body }).unwrap()
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { setUserStatus: handleSetUserStatus, response }
}
