import { Mutex } from "async-mutex"
import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query"
import { logoutUserAction } from "@/redux/authSlice"
import type { TokenInvalidResponse } from "./types"
import type { FetchArgs } from "@reduxjs/toolkit/query"

const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_BASE_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token")
    if (token) {
      headers.set("Authorization", `Bearer ${token}`)
    }
  },
})
export const baseQueryWithReauth = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          api.dispatch(logoutUserAction())
        } finally {
          // release must be called once the mutex should be released again.
          release()
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock()
        result = await baseQuery(args, api, extraOptions)
      }
      retry.fail(result.error)
    }
    if (result.error && result.error.status === 403) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          if ((result.error.data as TokenInvalidResponse).error.code === 99) {
            api.dispatch(logoutUserAction())
          }
        } finally {
          // release must be called once the mutex should be released again.
          release()
        }
      }
      retry.fail(result.error)
    }
    if (
      result.error &&
      ["POST", "PUT", "DELETE"].find(
        (method) => method === result.meta?.request.method,
      )
    ) {
      retry.fail(result.error)
    }
    return result
  },
  {
    maxRetries: 5,
  },
)
