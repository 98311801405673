import { useEffect, useState } from "react"

export const useFocusedElement = () => {
  const [active, setActive] = useState(document.activeElement as HTMLElement)

  const handleFocusIn = () => setActive(document.activeElement as HTMLElement)

  useEffect(() => {
    document.addEventListener("focusin", handleFocusIn)
    return () => {
      document.removeEventListener("focusin", handleFocusIn)
    }
  }, [])

  return active
}
