import { FC, Fragment, useMemo, useState } from "react"
import cx from "classnames"
import type { SequenceAPIResponse } from "@/types/entities/sequence"
import OutlineButton from "@/components/OutlineButton"
import EmailAccordionCard from "@/components/EmailAccordionCard"
import CampaignsTable from "../CampaignsTable"

type Props = {
  className?: string
  selectedSequence: SequenceAPIResponse
}

type SequenceViewTabs = "Details" | "Campaigns"

const SequenceView: FC<Props> = ({
  className,
  selectedSequence: sequence,
}: Props) => {
  const [activeView, setActiveView] = useState<SequenceViewTabs>("Details")
  const tabs = useMemo(() => {
    return [
      {
        label: "Details",
        value: "Details",
      },
      {
        label: `Campaigns (${sequence?.campaigns.length})`,
        value: "Campaigns",
      },
    ]
  }, [sequence])
  const updateActiveTab = (type: SequenceViewTabs) => {
    return () => {
      setActiveView(type)
    }
  }
  const emails = sequence?.emails

  return (
    <div className={cx("p-6 flex flex-col gap-5", className)}>
      <div
        className={cx("p-1 rounded-[9px] bg-input w-full flex [&>*]:flex-1")}
      >
        {tabs.map(({ label, value }, i) => (
          <Fragment key={i}>
            {activeView === value ? (
              <OutlineButton
                onClick={updateActiveTab(value as SequenceViewTabs)}
                className="btn-xs hover:bg-white !min-h-[34px] !h-auto text-sm bg-white !text-primary outline-outline outline outline-1 border-none shadow-[0px_1px_2px_0px_rgba(0,0,0,0.04)]"
              >
                {label}
              </OutlineButton>
            ) : (
              <OutlineButton
                onClick={updateActiveTab(value as SequenceViewTabs)}
                className="btn-xs !min-h-[34px] !h-auto text-sm bg-transparent border-none text-black"
              >
                {label}
              </OutlineButton>
            )}
          </Fragment>
        ))}
      </div>
      {activeView === "Details" && (
        <div className="flex flex-col gap-5">
          {emails.map((email, emailIndex) => (
            <EmailAccordionCard
              key={emailIndex}
              email={email}
              emailIndex={emailIndex}
            />
          ))}
        </div>
      )}
      {activeView === "Campaigns" && (
        <CampaignsTable campaigns={sequence.campaigns} />
      )}
    </div>
  )
}

export default SequenceView
