import { useGetCurrentUserQuery } from "@/core/api/services"

export const useProfilePicture = () => {
  const { data: user } = useGetCurrentUserQuery()

  return {
    smallPicture: user?.profileImage?.sizes?.length
      ? user?.profileImage?.sizes[2].url
      : "",
    mediumPicture: user?.profileImage?.sizes?.length
      ? user?.profileImage?.sizes[1].url
      : "",
    originalPicture: user?.profileImage?.sizes?.length
      ? user?.profileImage?.sizes[0].url
      : "",
  }
}
