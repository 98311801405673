import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useSwitchOrganizationsMutation } from "@/redux/services/adminUsersApi"
import { setToast } from "@/redux/toastSlice"

export const useSwitchOrganizations = () => {
  const dispatch = useAppDispatch()
  const [switchOrganizations, response] = useSwitchOrganizationsMutation()

  const handleSwitchOrganizations = async (body: {
    from: string[]
    to: string
  }) => {
    try {
      await switchOrganizations(body).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success transfer active accounts",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { switchOrganizations: handleSwitchOrganizations, response }
}
