import { FC, useState } from "react"
import cx from "classnames"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import Collapse from "@/components/Collapse"
import Tab from "@/components/Tab"
import Tabs from "@/components/Tabs"
import SequenceEmailVersion from "@/components/SequenceEmailVersion"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useEmailSequenceValues } from "@/hooks/useEmailSequenceValues"
import VerticalLineIcon from "@/assets/vertical-line.svg"
import Select from "@/components/Select"
import InputNumber from "@/components/InputNumber"
import DuplicateIcon from "@/components/icons/DuplicateIcon"
import AddIcon from "@/components/icons/AddIcon"
import Tooltip from "@/components/Tooltip"
import VersionDeleteModal from "../VersionDeleteModal"
import { getNumberEnding } from "@/utils/numbers"
import type { SequenceDelayUnit } from "./constants"
import type { valueType } from "antd/es/statistic/utils"

export type OnVersionDeletePayload = {
  removedVersionIndex: number
  emailIndex: number
}

type Props = {
  emailIndex: number
  onVersionDelete: (payload: OnVersionDeletePayload) => void
  onNewVersionAdd: (emailIndex: number) => void
  onVersionDuplicate: (emailIndex: number, versionIndex: number) => void
}

const EmailAccordion: FC<Props> = ({
  emailIndex,
  onVersionDelete,
  onNewVersionAdd,
  onVersionDuplicate,
}: Props) => {
  const {
    values: { emails },
    setValues,
  } = useEmailSequenceValues()
  const { pageFullAccess: sequencesUpdateFullAccess } = useUserAccess({
    pageName: "Sequences Update",
  })
  const [activeVersionIndex, setActiveVersionIndex] = useState(0)
  const [deletedVersionIndex, setDeletedVersionIndex] = useState(-1)
  const handleVersionDelete = (versionIndex: number) => {
    setActiveVersionIndex(0)
    setDeletedVersionIndex(-1)
    onVersionDelete({ emailIndex, removedVersionIndex: versionIndex })
  }
  const handleAdd = () => onNewVersionAdd(emailIndex)
  const handleDelayChange = (delay: valueType | null) => {
    if (!delay) {
      return
    }
    setValues((prev) => ({
      ...prev,
      emails: prev.emails.map((email, i) =>
        i === emailIndex
          ? {
              ...email,
              delay,
            }
          : email,
      ),
    }))
  }
  const handleDelayUnitChange = (delayUnit: SequenceDelayUnit) => {
    setValues((prev) => ({
      ...prev,
      emails: prev.emails.map((email, i) =>
        i === emailIndex
          ? {
              ...email,
              delayUnit,
            }
          : email,
      ),
    }))
  }
  const handleDeleteEmail = () => {
    let newEmails = emails
      .filter((_, i) => i !== emailIndex)
      .map((email, i) =>
        i === 0
          ? {
              ...email,
              versions: email.versions.map((version) => ({
                ...version,
                unsubs: version.unsubs ?? [""],
              })),
            }
          : email,
      )
    if (newEmails.length === 1) {
      // clear delay & delayUnit fields
      newEmails = newEmails.map(({ versions }) => ({
        versions,
      }))
    }
    setValues((prev) => ({
      ...prev,
      emails: newEmails,
    }))
  }
  const handleDuplicateVersion = () =>
    onVersionDuplicate(emailIndex, activeVersionIndex)

  const email = emails[emailIndex]

  return (
    <>
      <Collapse
        className="[&_.collapse-title]:after:hidden [&_.collapse-title]:pr-2 [&_.collapse-title]:sm:pr-12 [&_.collapse-title]:after:sm:inline [&_.collapse-content]:px-0 [&_.collapse-content]:overflow-x-auto"
        title={
          <div className="flex justify-between items-center">
            <span>
              {emailIndex + 1}
              {getNumberEnding(emailIndex + 1)}{" "}
              <span className="hidden sm:inline">Email</span>
            </span>
            <div
              className={cx(
                "flex items-center gap-2 sm:gap-[15px] z-20 relative",
                {
                  faded: !sequencesUpdateFullAccess,
                },
              )}
            >
              {emails.length > 1 && (
                <>
                  {emailIndex > 0 && (
                    <div className="flex items-center text-sm font-normal leading-6">
                      <span className="hidden sm:inline">Send </span>
                      <InputNumber
                        value={emails[emailIndex].delay}
                        onChange={handleDelayChange}
                        className="mx-1 w-32 sm:w-[172px] [&_.ant-input-number-group-addon]:!px-0 [&_.ant-input-number-group-addon]:sm:px-[11px]"
                        min={1}
                        max={365}
                        maxLength={3}
                        addonAfter={
                          <Select
                            value={emails[emailIndex].delayUnit}
                            onChange={handleDelayUnitChange}
                            style={{ width: 100 }}
                            popupMatchSelectWidth={false}
                            options={[
                              { value: "days", label: "Days" },
                              { value: "hours", label: "Hours" },
                              { value: "minutes", label: "Minutes" },
                            ]}
                          />
                        }
                      />
                      <span className="hidden sm:inline">after previous.</span>
                    </div>
                  )}
                  {emailIndex !== 0 && (
                    <>
                      <VerticalLineIcon />
                      <BinIcon
                        onClick={handleDeleteEmail}
                        className="hover:text-error h-6 w-6"
                      />
                    </>
                  )}
                  <span className="hidden sm:inline">
                    <VerticalLineIcon />
                  </span>
                </>
              )}
            </div>
          </div>
        }
      >
        <Tabs
          actions={
            <>
              <Tooltip
                className={cx({ faded: !sequencesUpdateFullAccess })}
                title="Add a new version"
              >
                <div
                  onClick={handleAdd}
                  className="group cursor-pointer py-2 px-3 border-x border-x-outline"
                >
                  <AddIcon className="group-active:scale-90 transition-colors group-hover:text-primary h-5 w-5" />
                </div>
              </Tooltip>
              <Tooltip
                className={cx({ faded: !sequencesUpdateFullAccess })}
                title={`Duplicate Version ${activeVersionIndex + 1}`}
              >
                <div
                  onClick={handleDuplicateVersion}
                  className="cursor-pointer py-2 px-3 group border-r border-r-outline"
                >
                  <DuplicateIcon className="group-active:scale-90 transition-colors group-hover:text-primary" />
                </div>
              </Tooltip>
            </>
          }
        >
          {email.versions.map((_, versionIndex) => (
            <Tab
              key={versionIndex}
              tabKey={versionIndex}
              className={cx("tab-lifted font-semibold w-[136px]", {
                "ml-5": !versionIndex,
                "cursor-default": email.versions.length === 1,
                "justify-between": versionIndex > 0,
              })}
              active={activeVersionIndex === versionIndex}
              onSelect={(tabKey) => setActiveVersionIndex(+tabKey)}
              label={<span>Version {versionIndex + 1}</span>}
              onClose={
                sequencesUpdateFullAccess && email.versions.length > 1
                  ? () => setDeletedVersionIndex(versionIndex)
                  : null
              }
            >
              <SequenceEmailVersion
                emailIndex={emailIndex}
                versionIndex={versionIndex}
              />
            </Tab>
          ))}
        </Tabs>
      </Collapse>
      <VersionDeleteModal
        open={deletedVersionIndex > -1}
        onClose={() => setDeletedVersionIndex(-1)}
        onDeleteSuccess={handleVersionDelete}
        version={deletedVersionIndex}
      />
    </>
  )
}

export default EmailAccordion
