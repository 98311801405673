import React, { forwardRef, useId, useState } from "react"
import cx from "classnames"
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md"

export type TextFieldProps = {
  className?: string
  topLeftLabel?: React.ReactNode
  topRightLabel?: React.ReactNode
  bottomLeftLabel?: React.ReactNode
  bottomRightLabel?: React.ReactNode
  startIcon?: React.ReactNode
  suffixIcon?: React.ReactNode
  rootClassName?: string
}
type Props = TextFieldProps & React.InputHTMLAttributes<HTMLInputElement>

const TextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      topLeftLabel,
      topRightLabel,
      bottomLeftLabel,
      bottomRightLabel,
      startIcon,
      type,
      rootClassName,
      suffixIcon,
      disabled,
      readOnly,
      ...rest
    }: Props,
    ref,
  ) => {
    const id = useId()
    const [showPassword, setShowPassword] = useState(false)

    const resultType = type !== "password" ? type : showPassword ? "text" : type

    const isPassword = type === "password"

    return (
      <div className={cx("form-control w-full", rootClassName)}>
        {(topLeftLabel || topRightLabel) && (
          <label htmlFor={id} className="label pl-0 pt-0">
            {topLeftLabel && (
              <span className="label-text text-black font-medium">
                {topLeftLabel}
              </span>
            )}
            {topRightLabel && (
              <span className="label-text-alt text-black">{topRightLabel}</span>
            )}
          </label>
        )}
        <div className="relative">
          {startIcon && (
            <div className="absolute left-2 top-1/2 -translate-y-1/2">
              {startIcon}
            </div>
          )}
          <input
            ref={ref}
            id={id}
            type={resultType}
            className={cx(
              "focus:bg-white read-only:cursor-text read-only:focus:bg-input read-only:outline-none transition-colors input w-full px-4 py-3 bg-input placeholder:text-black placeholder:opacity-40 disabled:border-outline disabled:border disabled:bg-semi disabled:placeholder:text-black read-only:border-outline read-only:border read-only:bg-semi read-only:opacity-60 disabled:placeholder:opacity-40 focus:outline-outline-dark focus:outline-[1.5px] focus:outline-offset-0 rounded-md",
              { "pr-10": isPassword },
              { "!pl-9": !!startIcon },
              className,
            )}
            disabled={disabled}
            readOnly={readOnly}
            autoComplete="off"
            aria-autocomplete="none"
            {...rest}
          />
          {suffixIcon && (
            <div
              className={cx("absolute right-2 top-1/2 -translate-y-1/2", {
                "opacity-60": disabled,
              })}
            >
              {suffixIcon}
            </div>
          )}
          {isPassword ? (
            <div
              onClick={() => setShowPassword(!showPassword)}
              className="absolute cursor-pointer right-3 top-1/2 -translate-y-1/2"
            >
              {showPassword ? (
                <MdOutlineVisibility />
              ) : (
                <MdOutlineVisibilityOff />
              )}
            </div>
          ) : null}
        </div>
        {(bottomLeftLabel || bottomRightLabel) && (
          <label htmlFor={id} className="label py-0">
            {bottomLeftLabel && (
              <span className="label-text text-black font-medium">
                {bottomLeftLabel}
              </span>
            )}
            {bottomRightLabel && (
              <>
                {!bottomLeftLabel && <span></span>}
                <span className="label-text-alt text-black">
                  {bottomRightLabel}
                </span>
              </>
            )}
          </label>
        )}
      </div>
    )
  },
)

export default TextField
