import { getRange } from "@/utils/numbers"

export const MONTHS: { label: string; value: number }[] = [
  { label: "January" },
  { label: "February" },
  {
    label: "March",
  },
  {
    label: "April",
  },
  {
    label: "May",
  },
  {
    label: "June",
  },
  {
    label: "July",
  },
  {
    label: "August",
  },
  {
    label: "September",
  },
  {
    label: "October",
  },
  {
    label: "November",
  },
  {
    label: "December",
  },
].map((month, value) => ({
  ...month,
  value,
}))

export const YEARS: { label: string; value: number }[] = getRange(
  1990,
  2100,
).map((value) => ({
  label: value.toString(),
  value,
}))
