import { FC } from "react"
import DeleteButton from "@/components/Button/DeleteButton"
import Modal, { ModalProps } from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import { useMasterListRecordActions } from "../../hooks/useMasterListRecordActions"
import { buildQueryString } from "@/utils/strings"
import type { MasterListRecord } from "@/types/entities/master-list"

type Props = {
  data?: MasterListRecord[] | null
  onDeleteSuccess?: () => void
} & ModalProps

const DeleteContactModal: FC<Props> = ({
  open,
  data,
  onClose,
  onDeleteSuccess,
}: Props) => {
  const { deleteRecords, isLoading } = useMasterListRecordActions()
  if (!data?.length || !onClose) {
    return null
  }
  const handleDelete = async () => {
    try {
      await deleteRecords(
        buildQueryString({
          records: data.map(({ _id }) => _id),
        }),
      )
      onClose()
      onDeleteSuccess && onDeleteSuccess()
    } catch (e) {
      console.error(e)
    }
  }
  const title =
    data.length > 1 ? `Delete ${data.length} Contacts` : "Delete Contact"
  const contactsText =
    data.length > 1
      ? `${data.length} Contacts`
      : `${data[0].firstName} ${data[0].lastName}`
  return (
    <Modal
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={isLoading} onClick={onClose} />
          <DeleteButton disabled={isLoading} />
        </div>
      }
      title={title}
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
    >
      <span className="text-base leading-6">
        Are you sure you want to delete{" "}
        <span className="font-semibold">{contactsText}</span>?
      </span>
    </Modal>
  )
}

export default DeleteContactModal
