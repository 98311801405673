import { FC, useEffect } from "react"
import cx from "classnames"
import { useGetDNSRecordsQuery } from "@/redux/services/domainsApi"
import { useAppSelector } from "@/redux/hooks"
import {
  selectDomainSetupId,
  selectDomainSetupMissing,
} from "@/redux/domainSetupSlice"
import WarningIcon from "@/components/icons/WarningIcon"
import stringify from "json-stable-stringify"
import Loader from "@/components/Loader"

type Props = {
  className?: string
  onDisabledChange: (disabled: boolean) => void
}

const DomainDNSRecordsView: FC<Props> = ({
  className,
  onDisabledChange,
  ...rest
}: Props) => {
  const domainId = useAppSelector(selectDomainSetupId)
  const missing = useAppSelector(selectDomainSetupMissing)
  const {
    data: { records } = { records: [] },
    isLoading,
    isSuccess,
  } = useGetDNSRecordsQuery({
    id: domainId,
  })
  useEffect(() => {
    if (isLoading && !isSuccess) {
      onDisabledChange(true)
    }
    if (isSuccess) {
      onDisabledChange(false)
    }
  }, [isLoading, isSuccess, onDisabledChange])

  return (
    <div
      className={cx(
        "flex flex-col gap-5",
        {
          "items-center": isLoading,
        },
        className,
      )}
      {...rest}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h2 className="text-lg font-semibold leading-7">DNS Records</h2>
          {records.length > 0 && (
            <div className="block overflow-x-auto whitespace-nowrap">
              <table cellPadding={12} className="w-full">
                <thead className="text-xs leading-[22px] font-semibold">
                  <tr className="[&>th]:text-start border-b border-outline-dark">
                    <th>Type</th>
                    <th>Host</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody className="text-sm leading-6 p-3">
                  {records.map(({ type, host, address }, i) => (
                    <tr
                      className="border-b border-outline"
                      key={`${type}-${host}-${address}-${i}`}
                    >
                      <td>
                        <span className="flex gap-2 items-center">
                          {missing.find(
                            (v) =>
                              stringify({
                                type: v.type,
                                host: v.host,
                                address: v.address,
                              }) === stringify({ type, host, address }),
                          ) ? (
                            <WarningIcon className="text-warning" />
                          ) : null}
                          <span>{type}</span>
                        </span>
                      </td>
                      <td>{host}</td>
                      <td className="break-all">{address}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default DomainDNSRecordsView
