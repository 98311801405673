import { FC, useId } from "react"
import cx from "classnames"
import Chip from "@/components/Chip"
import Tooltip from "@/components/Tooltip"
import Checkbox from "@/components/Checkbox"
import { formatDateToCurrentWeekdayOrDate } from "@/utils/dates"
import { getDisplayClassification } from "../../utils"
import { useUserAccess } from "@/hooks/useUserAccess"
import type { Inbox } from "@/types/entities/inbox"

type Props = {
  className?: string
  inbox: Inbox
  active: boolean
  selected: boolean
  onItemSelect: (inbox: Inbox) => void
  onCheckboxChange: (inbox: Inbox, checked: boolean) => void
}

const InboxListItem: FC<Props> = ({
  className,
  inbox,
  active,
  selected,
  onItemSelect,
  onCheckboxChange,
  ...rest
}: Props) => {
  const { pageFullAccess } = useUserAccess({ pageName: "Inbox" })
  const id = useId()
  const handleSelectChange = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) => onCheckboxChange(inbox, checked)

  const displayClassification = getDisplayClassification(inbox)

  return (
    <div
      className={cx(
        "transition-colors cursor-pointer rounded-md flex items-center px-3 py-2",
        {
          "bg-primary-glass": active,
        },
        className,
      )}
      {...rest}
    >
      {pageFullAccess && (
        <Checkbox
          checked={selected}
          onChange={handleSelectChange}
          id={id}
          className="checkbox-primary w-[18px] h-[18px] mr-3"
        />
      )}
      <div
        onClick={() => onItemSelect(inbox)}
        className={cx("cursor-pointer flex flex-col gap-0.5", {
          "w-[calc(100%-30px)]": pageFullAccess,
          "w-full": !pageFullAccess,
        })}
      >
        <div className="flex justify-between items-center gap-1.5">
          <div
            className={cx("text-sm leading-6 truncate flex-1", {
              "text-primary": active,
              "font-semibold": !inbox.readAt,
            })}
          >
            {inbox.from.firstName} {inbox.from.lastName}
          </div>
          {displayClassification && (
            <div className="gap-1 items-center flex md:hidden lg:flex">
              <Tooltip title={displayClassification}>
                <Chip className="text-[11px] max-w-[107px] truncate">
                  <span>{displayClassification}</span>
                </Chip>
              </Tooltip>
            </div>
          )}
        </div>
        <div className="flex text-xs leading-5 justify-between items-center gap-1">
          <div className="truncate flex-1">{inbox.reply}</div>
          <span className="opacity-60">
            {formatDateToCurrentWeekdayOrDate(inbox.receivedAt)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default InboxListItem
