import { FC, useCallback, useMemo, useState } from "react"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import Table from "@/components/Table"
import MasterListRecordStatusSelect, {
  MLStatusColorMap,
} from "../MasterListRecordStatusSelect"
import DeleteContactModal from "../DeleteContactModal"
import AddEditContactModal from "../AddEditContactModal"
import LinkedInIcon from "@/components/icons/LinkedInIcon"
import StatusText from "@/components/StatusText"
import BlurredText from "@/components/BlurredText"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { camelCaseToWords } from "@/utils/strings"
import { useMasterListRecordActions } from "../../hooks/useMasterListRecordActions"
import { ML_RECORDS_SORTED_FIELDS, sortMasterListRecords } from "./utils"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useServerSideSorting } from "@/components/Table/useServerSideSorting"
import { usePagination } from "@/hooks/usePagination"
import type {
  MasterListRecord,
  MasterListRecordStatus,
} from "@/types/entities/master-list"
import type { ColumnsType } from "antd/es/table"
import type { Key } from "antd/es/table/interface"
import type { TablePaginationProps } from "@/redux/services/types"

const ESCAPE_KEYS = [
  "status",
  "_id",
  "linkedInUrl",
  "industry",
  "employeeCount",
]

type Props = {
  records: MasterListRecord[]
  total: number
  pageSize: number
  page: number
  selectedRowKeys: Key[]
  maxHeight?: number
  onRowSelect: (keys: Key[]) => void
  onPageSizeChange: (pageSize: number) => void
  onPageChange: (page: number) => void
} & TablePaginationProps<TableDataType>

export type TableDataType = {
  key: string
  status: MasterListRecord["status"]
} & Omit<MasterListRecord, "_id">

const MasterListRecordsTable: FC<Props> = ({
  records,
  onRowSelect,
  onPageChange,
  onPageSizeChange,
  total,
  pageSize,
  page,
  selectedRowKeys,
  ...rest
}: Props) => {
  const { changeStatus } = useMasterListRecordActions()
  const { sm } = useBreakpoints()
  const { pageFullAccess } = useUserAccess({
    pageName: "Master Lists Update",
  })
  const [editedRecordId, setEditedRecordId] = useState("")
  const [deletedRecordId, setDeletedRecordId] = useState("")
  const { mapServerSortedColumns } = useServerSideSorting()
  const { handleSortFieldChange } = usePagination()
  const handleChangeStatus = useCallback(
    async (status: MasterListRecordStatus, _id: string) => {
      await changeStatus([{ _id, status }])
    },
    [changeStatus],
  )

  const dataSource = useMemo<TableDataType[]>(
    () =>
      records.map(
        ({
          email,
          firstName,
          lastName,
          status,
          _id,
          employeeCount,
          industry,
          linkedInUrl,
          ...rest
        }) => ({
          firstName,
          lastName,
          email,
          status,
          key: _id,
          linkedInUrl,
          industry,
          employeeCount,
          ...rest,
        }),
      ),
    [records],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(() => {
    const [record] = records // picking keys from first record

    let resultCols: ColumnsType<TableDataType> = [
      {
        title: "Status",
        dataIndex: "status",
        sorter: true,
        key: "status",
        fixed: "left",
        width: 148,
        render: (v, { key }) => (
          <>
            {pageFullAccess ? (
              <MasterListRecordStatusSelect
                placeholder="Select status"
                className="w-32"
                value={v}
                onChange={({ value: newStatusValue }) =>
                  handleChangeStatus(newStatusValue, key)
                }
              />
            ) : (
              <StatusText
                color={MLStatusColorMap[v as MasterListRecordStatus].color}
              >
                {MLStatusColorMap[v as MasterListRecordStatus].label}
              </StatusText>
            )}
          </>
        ),
      },
      ...Object.entries(record ?? {})
        .filter(([key]) => !ESCAPE_KEYS.find((escapeKey) => escapeKey === key))
        .map(([key]) => ({
          title: camelCaseToWords(key),
          dataIndex: key,
          sorter: ML_RECORDS_SORTED_FIELDS.find((v) => v === key)
            ? true
            : (a: TableDataType, b: TableDataType) =>
                a[key].localeCompare(b[key]),
          key,
          className: key === "email" ? "border-l border-outline" : "",
          width: 175,
          render: (v: string) => (
            <BlurredText blur={key === "email" && !pageFullAccess} text={v} />
          ),
        }))
        .sort((a, b) => sortMasterListRecords(a.key, b.key)),
      {
        title: "Headcount",
        key: "employeeCount",
        dataIndex: "employeeCount",
        sorter: true,
        width: 125,
      },
      {
        title: "Industry",
        key: "industry",
        dataIndex: "industry",
        sorter: true,
        width: 175,
      },
      {
        title: "LinkedIn",
        key: "linkedInUrl",
        dataIndex: "linkedInUrl",
        className: "text-center",
        render: (v) => (
          <>
            {v && (
              <a
                className="inline-block hover:scale-110 transition-[translate]"
                target="_blank"
                href={v}
              >
                <LinkedInIcon />
              </a>
            )}
          </>
        ),
        width: 90,
      },
    ]
    if (pageFullAccess) {
      resultCols = resultCols.concat({
        title: "",
        dataIndex: "actions",
        key: "actions",
        className: "min-w-[96px]",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            <EditIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setEditedRecordId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
            />
            <BinIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setDeletedRecordId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
            />
          </div>
        ),
        width: 96,
      })
    }
    return resultCols.map(mapServerSortedColumns)
  }, [handleChangeStatus, pageFullAccess, records, sm, mapServerSortedColumns])

  return (
    <>
      <Table
        rowSelection={
          pageFullAccess
            ? {
                type: "checkbox",
                selectedRowKeys,
                onChange: (selectedRowKeys) => onRowSelect(selectedRowKeys),
              }
            : undefined
        }
        pageSize={pageSize}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 1400 }}
        showSizeChanger
        truncate
        onPageSizeChange={onPageSizeChange}
        onSortChange={handleSortFieldChange}
        pagination={{
          onChange: (page, pageSize) => onPageChange((page - 1) * pageSize),
          current: Math.round(page / pageSize) + 1,
          total,
        }}
        {...rest}
      />
      <DeleteContactModal
        open={!!deletedRecordId}
        data={
          deletedRecordId
            ? [records.find(({ _id }) => _id === deletedRecordId)!].filter(
                Boolean,
              )
            : []
        }
        onClose={() => setDeletedRecordId("")}
      />
      {editedRecordId && (
        <AddEditContactModal
          open={!!editedRecordId}
          data={records.find(({ _id }) => _id === editedRecordId)}
          onClose={() => setEditedRecordId("")}
          blurEmail={!pageFullAccess}
        />
      )}
    </>
  )
}

export default MasterListRecordsTable
