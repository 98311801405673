import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDisconnectEmailOAuthMutation } from "@/redux/services/sendersApi"
import { setToast } from "@/redux/toastSlice"

export const useDisconnectEmailOAuth = () => {
  const dispatch = useAppDispatch()
  const [disconnectEmailAuth, response] = useDisconnectEmailOAuthMutation()

  const handleDisconnectEmailAuth = async ({
    body,
    id,
  }: {
    body: { emailId: string }
    id: string
  }) => {
    try {
      const response = await disconnectEmailAuth({ body, id }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success disconnect email address",
          }),
        )
      }
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { disconnectEmailAuth: handleDisconnectEmailAuth, response }
}
