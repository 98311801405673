import { FC, useMemo, useState } from "react"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import ExpandedModal from "@/components/ExpandedModal"
import Stepper from "@/components/Stepper"
import CopyAppIdView from "./CopyAppIdView"
import GoogleLoginView from "./GoogleLoginView"
import GoogleLoginInstructions from "./GoogleLoginInstructions"
import { useAdminAccountLogin } from "../hooks/useAdminAccountLogin"
import type { ModalProps } from "@/components/Modal"
import type { GsuiteAccount } from "@/types/entities/gsuite-account"
import type { StepProps } from "antd"

type StepProp = StepProps & { view: JSX.Element }

type Props = {
  className?: string
  data?: GsuiteAccount
} & ModalProps

const AddEditGoogleAccountModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const [expanded, setExpanded] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const { handleSubmit, title, isLoading, submitDisabled } =
    useAdminAccountLogin()
  const isEdit = Boolean(data)
  const handleClose = () => onClose && onClose()
  const steps = useMemo<StepProp[]>(
    () => [
      {
        title: "Copy App ID",
        view: <CopyAppIdView onHelpClick={() => setExpanded(true)} />,
      },
      {
        title: "Google Login",
        view: <GoogleLoginView data={data} />,
      },
    ],
    [data],
  )
  const submitBtnText = currentStep === 0 ? "Continue" : isEdit ? "Save" : "Add"

  return (
    <ExpandedModal
      title={title}
      open={open}
      onClose={handleClose}
      // boxClassName="max-h-[420px]"
      onSubmit={handleSubmit}
      expanded={expanded}
      onCollapse={() => setExpanded(false)}
      paneContent={<GoogleLoginInstructions />}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            type="button"
            className="btn-sm text-black min-w-[86px]"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </OutlineButton>
          {currentStep === 0 && (
            <Button
              onClick={() => setCurrentStep((prev) => prev + 1)}
              type="button"
              className="btn-sm min-w-[86px]"
            >
              Continue
            </Button>
          )}
          {currentStep !== 0 && (
            <Button
              disabled={submitDisabled}
              type="submit"
              className="btn-sm min-w-[86px]"
            >
              {submitBtnText}
            </Button>
          )}
        </div>
      }
      {...rest}
    >
      <div className="flex flex-col relative -left-6 right-0 w-[calc(100%+48px)] border-b border-outline pb-3">
        <Stepper
          current={currentStep}
          onChange={setCurrentStep}
          className="[&_.ant-steps-item-content]:whitespace-nowrap mx-auto w-1/2 -translate-x-4"
          items={steps}
        />
      </div>
      <div className="mt-6">{steps[currentStep].view}</div>
    </ExpandedModal>
  )
}

export default AddEditGoogleAccountModal
