import dayjs from "dayjs"
import { getWeekDateRange } from "@/utils/dates"
import type { ReplyClassification } from "@/types/entities/reply-classification"

export const findClassificationByName = (
  arr: ReplyClassification[],
  name: string,
): ReplyClassification | undefined => {
  for (const item of arr) {
    if (item.name === name) {
      return item
    }

    if (item.sub) {
      const subItem = findClassificationByName(item.sub, name)
      if (subItem) {
        return subItem
      }
    }
  }

  return undefined
}
export type DashboardGrouping = "day" | "month" | "year" | "week" | "hour"

export function generateLabels(
  from: Date,
  to: Date,
  grouping: DashboardGrouping,
): string[] {
  const labels: string[] = []
  let currentDate: Date = new Date(from)

  while (currentDate <= to) {
    if (grouping === "day") {
      labels.push(
        `${currentDate.getDate()}/${
          currentDate.getMonth() + 1
        }/${currentDate.getFullYear()}`,
      )
      currentDate.setDate(currentDate.getDate() + 1)
    } else if (grouping === "month") {
      labels.push(`${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`)
      currentDate.setMonth(currentDate.getMonth() + 1)
      // Ensure to include the end month
      if (currentDate > to) {
        labels.push(`${to.getMonth() + 1}/${to.getFullYear()}`)
      }
    } else if (grouping === "year") {
      labels.push(currentDate.getFullYear().toString())
      currentDate.setFullYear(currentDate.getFullYear() + 1)
      // Ensure to include the end year
      if (currentDate > to) {
        labels.push(to.getFullYear().toString())
      }
    } else if (grouping === "hour") {
      labels.push(
        `${currentDate.getDate()}/${
          currentDate.getMonth() + 1
        }/${currentDate.getFullYear()} ${dayjs(currentDate)
          .set("hour", currentDate.getHours())
          .format("h A")}`,
      )
      currentDate.setHours(currentDate.getHours() + 1)
      if (currentDate > to) {
        labels.push(
          `${to.getDate()}/${to.getMonth() + 1}/${to.getFullYear()} ${dayjs(to)
            .set("hour", to.getHours())
            .format("h A")}`,
        )
      }
    } else if (grouping === "week") {
      const dayjsCurrent = dayjs(currentDate)
      labels.push(
        getWeekDateRange(dayjsCurrent.year(), +dayjsCurrent.format("w") - 1),
      )
      currentDate.setDate(currentDate.getDate() + 7)
      if (currentDate > to) {
        const dayjsTo = dayjs(to)
        labels.push(getWeekDateRange(dayjsTo.year(), +dayjsTo.format("w") - 1))
      }
    }
  }

  return [...new Set(labels)]
}
