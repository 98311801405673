import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

export const useAvailableParams = <T extends string>(
  paramKeys: T[],
  onParamsMissing?: (paramKeysMissing: T[]) => void,
): Record<T, string> => {
  const [searchParams] = useSearchParams()
  const paramEntries = paramKeys.map((key) => ({
    key,
    value: searchParams.get(key),
  }))

  useEffect(() => {
    if (!onParamsMissing) {
      return
    }
    const missingParams = paramEntries.filter(({ value }) => !value)
    if (missingParams?.length) {
      onParamsMissing(missingParams.map(({ key }) => key))
    }
  }, [onParamsMissing, paramEntries])

  return paramEntries
    .filter(({ value }) => value !== null)
    .reduce(
      (acc, { key, value }) =>
        Object.assign(acc, {
          [key]: value,
        }),
      {} as Record<T, string>,
    )
}
