import { FC, useEffect, useState } from "react"
import { ModalProps } from "@/components/Modal"
import SelectPlatformModal from "@/components/SelectPlatformModal"
import AddEditGoogleAccountModal from "./AddEditGoogleAccountModal"
import AddEditMicrosoftAccountModal from "./AddEditMicrosoftAccountModal"
import { AdminAccountLoginContext } from "./context"
import { useFormik } from "formik"
import { useEditGsuiteAccount } from "../../hooks/useEditGsuiteAccount"
import { useAddGsuiteAccount } from "../../hooks/useAddGsuiteAccount"
import { useDisconnectGsuiteAccount } from "../../hooks/useDisconnectGsuiteAccount"
import type {
  AddGsuiteAccountPayload,
  EmailPlatform,
  GsuiteAccount,
} from "@/types/entities/gsuite-account"

type Props = {
  className?: string
  data?: GsuiteAccount
} & ModalProps

const AddEditGsuiteModal: FC<Props> = ({ data, open, onClose }: Props) => {
  const [selectedPlatform, setSelectedPlatform] = useState<"" | EmailPlatform>(
    "",
  )
  const { addGsuiteAccount, response: addResponse } = useAddGsuiteAccount()
  const { editGsuiteAccount, response: editResponse } = useEditGsuiteAccount()
  const { disconnectMail, response: disconnectResponse } =
    useDisconnectGsuiteAccount()
  const isEdit = Boolean(data)
  const {
    values: { displayName, maxDomains, code },
    handleSubmit,
    resetForm,
    getFieldProps,
    setFieldValue,
  } = useFormik<Omit<AddGsuiteAccountPayload, "provider">>({
    enableReinitialize: true,
    initialValues: data
      ? {
          ...data,
          code: "-",
        }
      : {
          displayName: "",
          maxDomains: 280,
          code: "",
        },
    onSubmit: async ({ displayName, code, maxDomains }) => {
      try {
        if (!selectedPlatform) {
          return
        }
        const body = {
          displayName,
          maxDomains,
          code,
          provider: selectedPlatform,
        }
        if (isEdit && data) {
          const body: Record<string, any> = {
            displayName,
            maxDomains,
          }
          if (code !== "-") {
            body.code = code
          }
          await editGsuiteAccount({
            body,
            id: data._id,
          })
        } else if (!isEdit) {
          await addGsuiteAccount(body)
        }
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  useEffect(() => {
    if (data) {
      setSelectedPlatform(data.provider)
    }
  }, [data])
  const handleClose = () => {
    setSelectedPlatform("")
    resetForm()
    onClose && onClose()
  }
  const handleSelectPlatform = (v: EmailPlatform) => {
    setSelectedPlatform(v)
    onClose && onClose()
  }
  const isLoading =
    addResponse.isLoading ||
    editResponse.isLoading ||
    disconnectResponse.isLoading
  const submitDisabled = !displayName || maxDomains <= 0 || !code || isLoading
  const title = isEdit ? "Edit Admin Account" : "Add Admin Account"
  return (
    <AdminAccountLoginContext.Provider
      value={{
        getFieldProps,
        setFieldValue,
        disconnectMail,
        handleSubmit,
        displayName,
        maxDomains,
        code,
        title,
        submitDisabled,
        isLoading,
      }}
    >
      <SelectPlatformModal
        open={open}
        onClose={handleClose}
        onPlatformSelect={handleSelectPlatform}
      />
      {selectedPlatform === "google" && (
        <AddEditGoogleAccountModal data={data} open onClose={handleClose} />
      )}
      {selectedPlatform === "microsoft" && (
        <AddEditMicrosoftAccountModal data={data} open onClose={handleClose} />
      )}
    </AdminAccountLoginContext.Provider>
  )
}

export default AddEditGsuiteModal
