import { FC, useMemo } from "react"
import cx from "classnames"
import { useSearchParams } from "react-router-dom"
import { useGetReplyClassificationsQuery } from "@/redux/services/generalApi"
import { findClassificationByName } from "@/pages/Dashboard/hooks/utils"
import TreeSelect, {
  type TreeSelectProps,
  type TreeSelectedValue,
} from "../TreeSelect"

type Props = {
  className?: string
} & TreeSelectProps

const CLASSIFICATION_PARAM_KEY = "classifications"
const MAX_CLASSIFICATIONS_LENGTH = 20

const ClassificationsDropdown: FC<Props> = ({
  className,
  value,
  onChange,
  placeholder,
  ...rest
}: Props) => {
  const { data: replyClassifications = [], isLoading } =
    useGetReplyClassificationsQuery()
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedItems: Partial<Record<string, boolean>> = useMemo(
    () =>
      Array.from(searchParams.entries())
        .filter(([key]) => key.includes(CLASSIFICATION_PARAM_KEY))
        .map(([, v]) => v)
        .reduce(
          (acc, status) =>
            Object.assign(acc, {
              [status]: true,
            }),
          {},
        ),
    [searchParams],
  )
  const itemsValues = useMemo(() => {
    if (!replyClassifications.length) {
      return []
    }
    let resultItemsValues =
      Object.entries(selectedItems)
        .filter(([, v]) => v)
        .map(([key]) => {
          const classification = findClassificationByName(
            replyClassifications,
            key,
          )!
          return {
            value: classification.name,
            label: classification.name,
          }
        }) ?? []
    return resultItemsValues
  }, [selectedItems, replyClassifications])
  const handleChange = (filterClassifications: TreeSelectedValue[]) => {
    if (filterClassifications.length > MAX_CLASSIFICATIONS_LENGTH) {
      return
    }
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      Array.from(newParams.keys()).forEach((key) => {
        if (key.includes(CLASSIFICATION_PARAM_KEY)) {
          newParams.delete(key)
        }
      })
      filterClassifications.forEach(({ value }, i) => {
        newParams.set(`${CLASSIFICATION_PARAM_KEY}[${i}]`, value)
      })
      return newParams
    })
  }

  return (
    <TreeSelect
      value={itemsValues}
      onChange={handleChange}
      treeData={replyClassifications.map(({ sub, name }) => ({
        value: name,
        label: name,
        children: sub?.map(({ name }) => ({
          value: name,
          label: name,
        })),
      }))}
      className={cx(
        "h-9 min-w-[172px] [&_.ant-select-selection-overflow_.ant-select-selection-overflow-item:first-child]:flex-1 [&_.ant-select-selection-overflow_.ant-select-selection-overflow-item:first-child]:truncate",
        className,
      )}
      multiple
      maxTagCount={1}
      showSearch={false}
      placeholder={
        <span className="opacity-100 text-black">All classifications</span>
      }
      loading={isLoading}
      treeCheckStrictly={true}
      showCheckedStrategy="SHOW_ALL"
      {...rest}
    />
  )
}

export default ClassificationsDropdown
