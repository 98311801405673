import { FC, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import cx from "classnames"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import Table from "@/components/Table"
import CampaignsStatusSelect, {
  campaignsStatusColorMap,
} from "../CampaignsStatusSelect"
import DeleteCampaignModal from "../DeleteCampaignModal"
import Tooltip from "@/components/Tooltip"
import StatusText from "@/components/StatusText"
import TimezoneDate from "@/components/TimezoneDate"
import CampaignsStatusText from "../CampaignsStatusText"
import dayjs from "dayjs"
import { useUserAccess } from "@/hooks/useUserAccess"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { useUpdateCampaignStatus } from "@/pages/AddEditCampaign/hooks/useUpdateCampaignStatus"
import { DATE_FORMAT_OPTIONS } from "@/constants"
import { countActionsWidth } from "@/components/Table/utils"
import type { ColumnsType } from "antd/es/table"
import type { Campaign, CampaignStatus } from "@/types/entities/campaign"
import type { TablePaginationProps } from "@/redux/services/types"

type Props = {
  campaigns: Campaign[]
  className?: string
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  name: string
  createdAt: string
  startDate?: string
  status: string
  dailyLimit: number
  prospects: number
  followUps: number
  replies: number
  leads: number
  totalEmails: number
  nextProcess: string
  lastProcessed: string
  timezone: string
}

const CampaignsTable: FC<Props> = ({
  campaigns,
  className,
  ...rest
}: Props) => {
  const navigate = useNavigate()
  const { sm } = useBreakpoints()
  const { pageFullAccess: campaignsAccess } = useUserAccess({
    pageName: "Campaigns",
  })
  const { pageAnyAccess: campaignsUpdateAnyAccess } = useUserAccess({
    pageName: "Campaigns Update",
  })
  const { updateCampaignStatus } = useUpdateCampaignStatus()
  const [deletedCampaignId, setDeletedCampaignId] = useState("")
  const dataSource = useMemo<TableDataType[]>(
    () =>
      campaigns.map(
        ({
          _id,
          name,
          createdAt,
          startDate,
          dailyLimit,
          status,
          followup,
          reply,
          totalEmails,
          new: prospects,
          leads = 0,
          lastProcessed,
          nextProcess,
          timezone,
        }) => ({
          key: _id,
          name,
          startDate,
          createdAt,
          status,
          followUps: followup,
          leads,
          prospects,
          replies: reply,
          totalEmails,
          lastProcessed,
          nextProcess,
          timezone,
          dailyLimit,
        }),
      ),
    [campaigns],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(() => {
    let resultCols: ColumnsType<TableDataType> = [
      {
        title: "Campaign",
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        fixed: "left",
        render: (value) => <Tooltip title={value}>{value}</Tooltip>,
        width: sm ? 225 : 125,
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: (v, { key }) => (
          <>
            {campaignsAccess ? (
              <CampaignsStatusSelect
                popupMatchSelectWidth={250}
                onChange={({ value }) =>
                  updateCampaignStatus({ body: { status: value }, id: key })
                }
                value={v}
              />
            ) : (
              <StatusText color={campaignsStatusColorMap[v as CampaignStatus]}>
                <CampaignsStatusText value={v} />
              </StatusText>
            )}
          </>
        ),
        width: 200,
      },
      {
        title: "Volume",
        key: "dailyLimit",
        dataIndex: "dailyLimit",
        sorter: (a, b) => a.dailyLimit - b.dailyLimit,
        width: 125,
      },
      {
        title: "Prospects",
        key: "prospects",
        dataIndex: "prospects",
        sorter: (a, b) => a.prospects - b.prospects,
        width: 125,
      },
      {
        title: "Follow Ups",
        key: "followUps",
        dataIndex: "followUps",
        sorter: (a, b) => a.followUps - b.followUps,
        width: 125,
      },
      {
        title: "Replies",
        key: "replies",
        dataIndex: "replies",
        sorter: (a, b) => a.replies - b.replies,
        width: 100,
      },
      {
        title: "Leads",
        key: "leads",
        dataIndex: "leads",
        sorter: (a, b) => a.leads - b.leads,
        width: 100,
      },
      {
        title: "Total Emails",
        key: "totalEmails",
        dataIndex: "totalEmails",
        sorter: (a, b) => a.totalEmails - b.totalEmails,
        width: 125,
      },
      {
        title: "Create Date",
        key: "createdAt",
        dataIndex: "createdAt",
        sorter: (a, b) =>
          Number(new Date(a.createdAt)) - Number(new Date(b.createdAt)),
        render: (value) =>
          value
            ? new Date(value).toLocaleDateString("en-US", DATE_FORMAT_OPTIONS)
            : "",
        width: 125,
      },
      {
        title: "Start Date",
        key: "startDate",
        dataIndex: "startDate",
        sorter: (a, b) =>
          +new Date(a.startDate ?? 0) - +new Date(b.startDate ?? 0),
        render: (value) =>
          value
            ? new Date(new Date(value)).toLocaleDateString(
                "en-US",
                DATE_FORMAT_OPTIONS,
              )
            : "",
        width: 125,
      },
      {
        title: "Last Processed",
        key: "lastProcessed",
        dataIndex: "lastProcessed",
        sorter: (a, b) =>
          +new Date(a.lastProcessed) - +new Date(b.lastProcessed),
        render: (value, record) =>
          value ? (
            <Tooltip
              title={
                record.timezone ? (
                  <TimezoneDate
                    date={value}
                    timezoneAbbreviation={record.timezone}
                  />
                ) : null
              }
            >
              {dayjs(value).format("M/D/YYYY HH:mm")}
            </Tooltip>
          ) : (
            ""
          ),
        width: 175,
      },
      {
        title: "Next Process",
        key: "nextProcess",
        dataIndex: "nextProcess",
        sorter: (a, b) => +new Date(a.nextProcess) - +new Date(b.nextProcess),
        render: (value, record) =>
          value ? (
            <Tooltip
              title={
                record.timezone ? (
                  <TimezoneDate
                    date={value}
                    timezoneAbbreviation={record.timezone}
                  />
                ) : null
              }
            >
              {dayjs(value).format("M/D/YYYY HH:mm")}
            </Tooltip>
          ) : (
            ""
          ),
        width: 175,
      },
    ]
    if (campaignsAccess || campaignsUpdateAnyAccess) {
      const actionsLength =
        Number(campaignsUpdateAnyAccess) + Number(campaignsAccess)
      resultCols = resultCols.concat({
        title: "",
        dataIndex: "actions",
        key: "actions",
        className: "min-w-[96px]",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            {campaignsUpdateAnyAccess && (
              <EditIcon
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  navigate(`edit/${key}`)
                }}
                className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
              />
            )}
            {campaignsAccess && (
              <BinIcon
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  setDeletedCampaignId(key)
                }}
                className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
              />
            )}
          </div>
        ),
        width: countActionsWidth(actionsLength),
      })
    }
    return resultCols
  }, [
    navigate,
    updateCampaignStatus,
    sm,
    campaignsAccess,
    campaignsUpdateAnyAccess,
  ])
  return (
    <>
      <Table
        className={cx("", className)}
        dataSource={dataSource}
        columns={columns}
        showSizeChanger
        truncate
        {...rest}
      />
      <DeleteCampaignModal
        open={Boolean(deletedCampaignId)}
        onClose={() => setDeletedCampaignId("")}
        data={campaigns.find(({ _id }) => _id === deletedCampaignId)}
      />
    </>
  )
}

export default CampaignsTable
