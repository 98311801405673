import { FC, useCallback, useMemo, useState } from "react"
import cx from "classnames"
import { useNavigate } from "react-router-dom"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import Table from "@/components/Table"
import Progress from "@/components/Progress"
import CriteriaStatusSelect, {
  searchCriteriaStatusColorMap,
} from "@/pages/AddEditSearchCriteria/features/CriteriaStatusSelect"
import DeleteSearchCriteriaModal from "../DeleteSearchCriteriaModal"
import DuplicateIcon from "@/components/icons/DuplicateIcon"
import SearchCriteriaDuplicateConfirmModal from "../SearchCriteriaDuplicateConfirmModal"
import SearchCriteriaDrawer from "../SearchCriteriaDrawer"
import StatusText from "@/components/StatusText"
import { useEditSearchCriteria } from "@/pages/AddEditSearchCriteria/hooks/useEditSearchCriteria"
import { getPercentage } from "@/utils/numbers"
import { setDuplicateFields } from "@/redux/searchCriteriaSetupSlice"
import { useAppDispatch } from "@/redux/hooks"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { useCriteriaSetup } from "@/pages/AddEditSearchCriteria/hooks/useCriteriaSetup"
import { useUserAccess } from "@/hooks/useUserAccess"
import { toCapitalize } from "@/utils/strings"
import { countActionsWidth } from "@/components/Table/utils"
import {
  searchCriteriaApi,
  useLazyGetCriteriaFiltersQuery,
  useLazyGetSearchCriteriaQuery,
} from "@/redux/services/searchCriteriaApi"
import type {
  SearchCriteria,
  SearchCriteriaStatus,
} from "@/types/entities/search-criteria"
import type { TablePaginationProps } from "@/redux/services/types"
import type { ColumnsType } from "antd/es/table"

type Props = {
  className?: string
  criterias: SearchCriteria[]
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  name: string
  status: SearchCriteriaStatus
  progress: {
    completed: number
    total: number
  }
  valid: number
  catchAll: number
  other: number
  processedEstimated: {
    retrieved: number
    total: number
  }
  campaigns: string
  masterList: string
}

const SearchCriteriaTable: FC<Props> = ({
  className,
  criterias,
  ...rest
}: Props) => {
  const dispatch = useAppDispatch()
  const { sm } = useBreakpoints()
  const { editCriteria } = useEditSearchCriteria()
  const [deletedCriteriaId, setDeletedCriteriaId] = useState("")
  const [duplicatedCriteriaId, setDuplicatedCriteriaId] = useState("")
  const [selectedItemId, setSelectedItemId] = useState("")
  const [getSearchCriteria, { isFetching: SCLoading }] =
    useLazyGetSearchCriteriaQuery()
  const [getCriteriaFilters, { isFetching: SCFiltersLoading }] =
    useLazyGetCriteriaFiltersQuery()
  const { resetCriteriaSetup } = useCriteriaSetup()
  const { pageFullAccess: targetingAccess } = useUserAccess({
    pageName: "Targeting",
  })
  const {
    pageAnyAccess: targetingUpdateAnyAccess,
    pageFullAccess: targetingUpdateFullAccess,
  } = useUserAccess({
    pageName: "Targeting Update",
  })
  const navigate = useNavigate()
  const handleChangeCriteriaStatus = useCallback(
    (id: string) => {
      return async (newStatus: string) => {
        await editCriteria({
          id,
          body: {
            status: newStatus,
          },
        })
        dispatch(searchCriteriaApi.util.invalidateTags(["Search Criteria"]))
      }
    },
    [editCriteria, dispatch],
  )
  const handleDuplicateSC = useCallback(
    async (id: string) => {
      await getCriteriaFilters().unwrap()
      const SC = await getSearchCriteria({ id }).unwrap()
      dispatch(setDuplicateFields({ name: SC.name }))
      navigate("add?mode=duplicate")
    },
    [navigate, getSearchCriteria, getCriteriaFilters, dispatch],
  )
  const handleViewSC = useCallback(
    async (id: string) => {
      setSelectedItemId(id)
      await getCriteriaFilters().unwrap()
      await getSearchCriteria({ id }).unwrap()
    },
    [getSearchCriteria, getCriteriaFilters],
  )

  const dataSource = useMemo<TableDataType[]>(
    () =>
      criterias.map(
        ({
          _id,
          name,
          masterList,
          status,
          retrieved,
          total,
          queriesStats: { completed, total: queryStatsTotal },
          leadsEmailsStats: { catchAll, other, valid },
        }) => ({
          key: _id,
          name,
          campaigns: masterList?.campaign?.name ?? "",
          masterList: masterList?.name ?? "",
          status,
          catchAll,
          other,
          valid,
          processedEstimated: { retrieved, total }, // `${retrieved}/${total}`}
          progress: {
            completed,
            total: queryStatsTotal,
          },
        }),
      ),
    [criterias],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(() => {
    let resultCols: ColumnsType<TableDataType> = [
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        width: 176,
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: (v, { key }) => (
          <>
            {targetingAccess ? (
              <CriteriaStatusSelect
                value={v}
                onCriteriaStatusChange={handleChangeCriteriaStatus(key)}
                bordered
                className="min-w-[140px] h-8"
              />
            ) : (
              <StatusText
                color={searchCriteriaStatusColorMap[v as SearchCriteriaStatus]}
              >
                {toCapitalize(v)}
              </StatusText>
            )}
          </>
        ),
        width: 160,
      },
      {
        title: "Progress",
        key: "processedEstimated",
        dataIndex: "processedEstimated",
        sorter: (a, b) =>
          a.processedEstimated.total - b.processedEstimated.total,
        render: ({ retrieved, total }) => {
          const percentage = getPercentage(retrieved, total)
          return (
            <div className="flex flex-col gap-1">
              <div className="flex justify-between text-xs leading-[18px] opacity-60">
                <span>{percentage}%</span>
                <span>
                  {retrieved}/{total}
                </span>
              </div>
              <Progress value={percentage} />
            </div>
          )
        },
        width: 208,
      },
      {
        title: "Valid",
        key: "valid",
        dataIndex: "valid",
        sorter: (a, b) => a.valid - b.valid,
        render: (v, { processedEstimated: { retrieved } }) => (
          <span>
            {v} ({getPercentage(v, retrieved, 0)}%)
          </span>
        ),
        width: 124,
      },
      {
        title: "Catch-all",
        key: "catchAll",
        dataIndex: "catchAll",
        width: 124,
        sorter: (a, b) => a.valid - b.valid,
      },
      {
        title: "Other",
        key: "other",
        dataIndex: "other",
        width: 124,
        sorter: (a, b) => a.valid - b.valid,
      },
      {
        title: "Requests",
        key: "progress",
        dataIndex: "progress",
        sorter: (a, b) =>
          a.processedEstimated.total - b.processedEstimated.total,
        render: ({ completed, total }) => {
          return (
            <span className="text-xs leading-[18px] opacity-60">
              {completed}/{total}
            </span>
          )
        },
        width: 180,
      },
      {
        title: "Campaigns",
        key: "campaigns",
        dataIndex: "campaigns",
        width: 156,
        sorter: (a, b) => a.campaigns.localeCompare(b.campaigns),
        render: (v) =>
          v ? v : <span className="text-sm leading-6 opacity-40">None</span>,
      },
      {
        title: "Master List",
        key: "masterList",
        dataIndex: "masterList",
        width: 156,
        sorter: (a, b) => a.masterList.localeCompare(b.masterList),
        render: (v) =>
          v ? v : <span className="text-sm leading-6 opacity-40">None</span>,
      },
    ]
    if (targetingAccess || targetingUpdateAnyAccess) {
      const actionsLength =
        Number(targetingUpdateAnyAccess) +
        Number(targetingUpdateFullAccess) +
        Number(targetingAccess)
      resultCols = resultCols.concat({
        title: "",
        dataIndex: "actions",
        key: "actions",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            {targetingUpdateAnyAccess && (
              <EditIcon
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  navigate(`edit/${key}`)
                }}
                className="w-6 h-6 opacity-60 active:scale-90 cursor-pointer hover:text-primary transition-colors"
              />
            )}
            {targetingUpdateFullAccess && (
              <DuplicateIcon
                onClick={(e) => {
                  e.stopPropagation()
                  setDuplicatedCriteriaId(key)
                }}
                className="w-6 h-6 opacity-60 active:scale-90 cursor-pointer hover:text-primary transition-colors"
              />
            )}
            {targetingAccess && (
              <BinIcon
                onClick={(e: React.MouseEvent<HTMLOrSVGElement>) => {
                  e.stopPropagation()
                  setDeletedCriteriaId(key)
                }}
                className="w-6 h-6 opacity-60 active:scale-90 cursor-pointer hover:text-primary transition-colors"
              />
            )}
          </div>
        ),
        width: countActionsWidth(actionsLength),
      })
    }
    return resultCols
  }, [
    sm,
    navigate,
    handleChangeCriteriaStatus,
    targetingAccess,
    targetingUpdateAnyAccess,
    targetingUpdateFullAccess,
  ])
  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        className={cx("", className)}
        onRow={({ key }) => ({
          className: "cursor-pointer",
          onClick: () => handleViewSC(key),
        })}
        showSizeChanger
        scroll={{ x: "max-content" }}
        {...rest}
      ></Table>
      <DeleteSearchCriteriaModal
        data={criterias.find(({ _id }) => _id === deletedCriteriaId)}
        open={Boolean(deletedCriteriaId)}
        onClose={() => setDeletedCriteriaId("")}
      />
      <SearchCriteriaDuplicateConfirmModal
        data={criterias.find(({ _id }) => _id === duplicatedCriteriaId)}
        open={Boolean(duplicatedCriteriaId)}
        onClose={() => setDuplicatedCriteriaId("")}
        onConfirm={handleDuplicateSC}
      />
      <SearchCriteriaDrawer
        open={!!selectedItemId}
        onClose={() => {
          setSelectedItemId("")
          resetCriteriaSetup()
        }}
        loading={SCLoading || SCFiltersLoading}
        name={criterias.find(({ _id }) => _id === selectedItemId)?.name ?? ""}
      />
    </>
  )
}

export default SearchCriteriaTable
