import { FC } from "react"
import cx from "classnames"
import CancelIcon from "@/assets/cancel.svg"
import Progress from "@/components/Progress"
import Select from "@/components/Select"
import { toFixedTrimmed } from "@/utils/numbers"
import type { MasterList } from "@/types/entities/master-list"

type Props = {
  selectedList: MasterList | null
  availableMasterLists: MasterList[]
  index: number
  onMasterListSelect: (masterListId: string) => void
  onMasterListRemove: (masterListId: string | number) => void
  firstColumnWidth: number
}

const CampaignMasterListRow: FC<Props> = ({
  selectedList,
  index,
  availableMasterLists,
  onMasterListSelect,
  onMasterListRemove,
  firstColumnWidth,
}: Props) => {
  const filterOption = (input: string, option: any) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  }

  const percentage =
    selectedList && selectedList?.total > 0
      ? toFixedTrimmed(
          ((selectedList?.used + selectedList.skip) / selectedList?.total) *
            100,
          1,
        )
      : 0

  return (
    <div className="flex py-2.5 gap-3 border-b border-b-outline-dark">
      <div
        className={cx("basis-2/6 sm:basis-1/4", {
          "flex items-center": selectedList,
        })}
      >
        {selectedList ? (
          <div
            style={{ width: firstColumnWidth }}
            className="truncate text-sm leading-6 max-w-full"
          >
            {selectedList.name}
          </div>
        ) : (
          <Select
            showSearch
            options={availableMasterLists.map(({ name, _id }) => ({
              label: name,
              value: _id,
            }))}
            filterOption={filterOption}
            placeholder="Select master list"
            className="h-10 pr-0"
            style={{
              width: firstColumnWidth,
            }}
            onChange={onMasterListSelect}
          />
        )}
      </div>
      {selectedList ? (
        <div className="basis-4/6 sm:basis-3/4">
          <div className="flex gap-5 items-center">
            <div className="flex flex-col gap-1 flex-1">
              <div className="flex justify-between gap-2">
                <span className="text-sm opacity-60 leading-[18px]">
                  {percentage}% ({selectedList.used + selectedList.skip}/
                  {selectedList.total})
                </span>
                <span className="text-sm opacity-60 leading-[18px]">
                  {selectedList.available}
                </span>
              </div>
              <div className="flex">
                <Progress value={percentage} />
              </div>
            </div>
            <div className="px-[5px]">
              <CancelIcon
                onClick={() => onMasterListRemove(selectedList._id ?? index)}
                className="active:scale-90 w-5 h-5 cursor-pointer"
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CampaignMasterListRow
