import { useState } from "react"
import { useParams } from "react-router-dom"
import { useEditMasterList } from "@/pages/MasterLists/hooks/useEditMasterList"
import { useDeleteMasterList } from "@/pages/MasterLists/hooks/useDeleteMasterList"
import type { MasterListRecordStatus } from "@/types/entities/master-list"

type UpdatePayload = Array<{
  _id: string
  firstName: string
  lastName: string
  email: string
  [key: string]: string | { name: string; value: string }[]
}>

export const useMasterListRecordActions = () => {
  const { masterListId = "" } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const { editMasterList } = useEditMasterList()
  const { deleteMasterList } = useDeleteMasterList()

  const changeStatus = async (
    records: {
      _id: string
      status: MasterListRecordStatus
    }[],
  ) => {
    setIsLoading(true)
    try {
      await editMasterList({
        masterListId,
        records,
      })
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }
  const updateRecord = async (records: UpdatePayload) => {
    setIsLoading(true)
    try {
      await editMasterList({
        masterListId,
        records,
      })
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }
  const deleteRecords = async (params: string) => {
    setIsLoading(true)
    try {
      await deleteMasterList({ id: masterListId, params })
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  return { changeStatus, deleteRecords, updateRecord, isLoading }
}
