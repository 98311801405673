import dayjs from "dayjs"

export const getLocalISOString = (date: Date) => {
  const tzoffset = new Date().getTimezoneOffset() * 60000 // offset in milliseconds
  const localISOTime = new Date(Number(date) - tzoffset)
    .toISOString()
    .slice(0, 16)

  return localISOTime
}

export const formatDateToCurrentWeekdayOrDate = (
  dateString: string,
): string => {
  const currentDate = dayjs()
  const parsedDate = dayjs(dateString)
  if (parsedDate.isSame(currentDate, "day")) {
    return parsedDate.format("[Today], HH:mm")
  }
  if (parsedDate.isSame(currentDate.subtract(1, "day"), "day")) {
    return parsedDate.format("[Yesterday], HH:mm")
  }
  if (parsedDate.isSame(currentDate, "week")) {
    return parsedDate.format("ddd, HH:mm")
  } else {
    return parsedDate.format("M/D/YYYY")
  }
}

export const formatDateToDateTime = (dateString: string) => {
  return dayjs(dateString).format("M/D/YYYY HH:mm")
}

export const convertDateMonthYearStringToMonthDateYear = (
  inputDate: string,
): string => {
  // Split the input date string into day, month, and year
  const [day, month, year] = inputDate.split("/")

  // Create a new Date object using the input date components
  const dateObj = new Date(Number(year), Number(month) - 1, Number(day))

  // Extract month, day, and year from the date object
  const monthStr = String(dateObj.getMonth() + 1)
  const dayStr = String(dateObj.getDate())
  const yearStr = String(dateObj.getFullYear())

  // Return the date string in MM/DD/YYYY format
  return `${monthStr}/${dayStr}/${yearStr}`
}

export const toLocalISOString = function (date: Date) {
  const isoString = date.toISOString()

  const formattedDate = isoString.replace("Z", "")

  return formattedDate
}
export function getStartOrEndOfDay(date: Date, isEndOfDay: boolean): Date {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const hours = isEndOfDay ? 23 : 0
  const minutes = isEndOfDay ? 59 : 0
  const seconds = isEndOfDay ? 59 : 0
  const milliseconds = isEndOfDay ? 999 : 0

  return new Date(year, month, day, hours, minutes, seconds, milliseconds)
}

export const toUTC = (date: Date) => {
  const utcDate = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  )
  return utcDate
}
export function formatDateToISOString(
  date: Date,
  time: string = "00:00:00.000",
): string {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}T${time}`
}

export function isWeekday(date: Date) {
  const day = date.getDay()
  return day !== 0 && day !== 6
}

export function getWeekDateRange(year: number, weekNumber: number): string {
  const firstDayOfYear = dayjs().year(year).startOf("year")
  const startOfWeek = firstDayOfYear.add(weekNumber * 7, "day").startOf("week")
  const endOfWeek = startOfWeek.add(6, "day")

  const format = "MM/DD/YYYY"
  const startOfWeekFormatted = startOfWeek.format(format)
  const endOfWeekFormatted = endOfWeek.format(format)

  return `${startOfWeekFormatted} - ${endOfWeekFormatted}`
}
