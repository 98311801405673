import { FC } from "react"
import cx from "classnames"
import { FcGoogle as GoogleIcon } from "react-icons/fc"
import OutlookIcon from "@/assets/outlook.svg"
import Tile from "../Tile"
import Modal from "../Modal"
import OutlineButton from "../OutlineButton"
import type { ModalProps } from "../Modal"
import type { EmailPlatform } from "@/types/entities/gsuite-account"

type Props = {
  className?: string
  title?: string
  onPlatformSelect: (platform: EmailPlatform) => void
} & ModalProps

const SelectPlatformModal: FC<Props> = ({
  className,
  open,
  onClose,
  title = "Add Account",
  onPlatformSelect,
  ...rest
}: Props) => {
  const handlePlatformSelect =
    (platform: EmailPlatform) => (e: React.MouseEvent<HTMLDivElement>) => {
      onPlatformSelect(platform)
    }
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      className={cx("", className)}
      actions={
        <OutlineButton
          type="button"
          className="btn-sm text-black min-w-[86px]"
          onClick={onClose}
        >
          Cancel
        </OutlineButton>
      }
      {...rest}
    >
      <h3 className="leading-6 text-sm font-medium text-center">
        Select account platform
      </h3>
      <div className="mt-2 flex gap-[18px]">
        <Tile
          className="flex-1"
          icon={<GoogleIcon className="h-full w-full" />}
          label="Google"
          onClick={handlePlatformSelect("google")}
        />
        <Tile
          className="flex-1"
          icon={<OutlookIcon className="h-full w-full" />}
          label="Microsoft"
          onClick={handlePlatformSelect("microsoft")}
        />
      </div>
    </Modal>
  )
}

export default SelectPlatformModal
