import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useAddSequenceMutation } from "@/redux/services/sequencesApi"
import { setToast } from "@/redux/toastSlice"
import { useParams } from "react-router-dom"
import type { Sequence } from "@/types/entities/sequence"

export const useAddSequence = () => {
  const dispatch = useAppDispatch()
  const [addSequence, response] = useAddSequenceMutation()
  const { id } = useParams()

  const handleAddSequence = async (body: Omit<Sequence, "orgId">) => {
    try {
      const response = await addSequence({ ...body, orgId: id! }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success add sequence",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { addSequence: handleAddSequence, response }
}
