import { FC } from "react"
import { FaCheckCircle as CheckCircleIcon } from "react-icons/fa"
import { MdOutlineCircle as LoadingFillIcon } from "react-icons/md"
import { FaRegCircleXmark as ErrorIcon } from "react-icons/fa6"

export type ProgressStatus = "loading" | "done" | "waiting" | "error"

type Props = {
  className?: string
  value: ProgressStatus
}

const progressMap: Record<ProgressStatus, JSX.Element> = {
  loading: (
    <span className="loading text-primary loading-spinner w-6 h-6"></span>
  ),
  done: <CheckCircleIcon className="w-6 h-6 text-positive" />,
  waiting: <LoadingFillIcon className="w-6 h-6 text-[#E9EAF4]" />,
  error: (
    <div className="w-6 h-6 flex justify-center items-center">
      <ErrorIcon className="w-5 h-5 text-error" />
    </div>
  ),
}

const StepProgress: FC<Props> = ({ value }: Props) => progressMap[value]

export default StepProgress
