import { createApi } from "@reduxjs/toolkit/query/react"
import { UserCredentials } from "@/types/forms/user-credentials"
import { baseQueryWithReauth } from "./baseQuery"
import { organizationApi } from "@/redux/services/organizationApi"
import { buildQueryString } from "@/utils/strings"
import { NOTIFICATIONS_LIMIT } from "@/hooks/useNotifications"
import { addAndEditArrayById } from "@/utils/arrays"
import type {
  UserLoginResponse,
  SetupPasswordPayload,
  UserTokenPayload,
} from "./types"
import type {
  UpdateNotificationPayload,
  Notification,
  User,
} from "@/types/entities/user"
import type { ConfirmPasswordFormShape } from "@/types/forms/confirm-password"

export type OrgStatus = "all" | "enabled" | "disabled"

export const dreamsenderApi = createApi({
  reducerPath: "dreamsenderApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["User", "Notifications", "Unread Notifications"],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, { orgStatus: OrgStatus } | void>({
      query: (param) => `user?${param ? buildQueryString(param) : ""}`,
      transformResponse: (baseQueryReturnValue) => {
        return (baseQueryReturnValue as { data: User }).data
      },
      providesTags: ["User"],
    }),
    loginUser: builder.mutation<UserLoginResponse, UserCredentials>({
      query: ({ email, password }) => ({
        method: "POST",
        url: "user/login",
        body: {
          email,
          password,
        },
      }),
      invalidatesTags: ["User", "Unread Notifications"],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled
        if (data) {
          dispatch(organizationApi.util.invalidateTags(["Organization"]))
        }
      },
    }),
    setupPassword: builder.mutation<
      ConfirmPasswordFormShape,
      SetupPasswordPayload
    >({
      query: ({ email, password, token }) => ({
        method: "POST",
        url: "user/password",
        body: {
          email,
          token,
          password,
        },
        invalidatesTags: ["User"],
      }),
    }),
    confirmEmail: builder.mutation<{ success: boolean }, UserTokenPayload>({
      query: ({ email, token }) => ({
        method: "GET",
        url: "user/confirmEmail",
        params: {
          token,
          email,
        },
      }),
      invalidatesTags: ["User"],
      transformResponse: ({
        data,
      }: {
        data: {
          success: boolean
        }
      }) => data,
    }),
    passwordReset: builder.mutation<{ success: boolean }, { email: string }>({
      query: ({ email }) => ({
        method: "GET",
        url: "user/passwordReset",
        params: {
          email,
        },
      }),
    }),
    updateUser: builder.mutation<{ data: User }, FormData>({
      query: (body) => ({
        method: "PUT",
        url: "user",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    deleteProfilePicture: builder.mutation<void, void>({
      query: () => ({
        method: "DELETE",
        url: "user/profileImage",
      }),
      invalidatesTags: ["User"],
    }),
    changePassword: builder.mutation<
      void,
      { currentPassword: string; newPassword: string }
    >({
      query: ({ currentPassword, newPassword }) => ({
        method: "PUT",
        url: "user/password",
        body: {
          currentPassword,
          newPassword,
        },
      }),
      invalidatesTags: ["User"],
    }),
    getNotifications: builder.query<
      { items: Notification[]; total: number },
      { start?: string; refetch?: string }
    >({
      query: ({ start }) => ({
        url: `user/notifications?${buildQueryString({
          start,
          limit: NOTIFICATIONS_LIMIT,
        })}`,
        method: "GET",
      }),
      providesTags: ["Notifications"],
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: Notification[]; total: number }
      }) => ({
        items,
        total,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.start !== previousArg?.start ||
        currentArg?.refetch !== previousArg?.refetch,
      merge: (currentCacheData, responseData) => {
        currentCacheData.items = addAndEditArrayById({
          currentArray: currentCacheData.items,
          newItems: responseData.items,
        })
        currentCacheData.total = responseData.total
      },
    }),
    updateNotifications: builder.mutation<
      { success: boolean },
      UpdateNotificationPayload
    >({
      query: (body) => ({
        url: `user/notifications`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Unread Notifications"],
      transformResponse: ({ data }: { data: { success: boolean } }) => data,
      onQueryStarted: async (
        { id: ids, read },
        { dispatch, queryFulfilled },
      ) => {
        try {
          const { data: response } = await queryFulfilled
          if (response.success) {
            dispatch(
              dreamsenderApi.util.updateQueryData(
                "getNotifications",
                { start: undefined },
                (draft) => {
                  Object.assign(draft, {
                    items: draft.items.map((notification) =>
                      ids?.find((updatedId) => updatedId === notification._id)
                        ? {
                            ...notification,
                            readAt: read ? new Date().toISOString() : undefined,
                          }
                        : notification,
                    ),
                  })
                },
              ),
            )
          }
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getTotalUnreadNotifications: builder.query<number, void>({
      query: () => ({
        url: `/user/notifications/unread`,
        method: "GET",
      }),
      providesTags: ["Unread Notifications"],
      transformResponse: ({ data }: { data: number }) => data,
    }),
    deleteNotifications: builder.mutation<
      { success: boolean },
      Omit<UpdateNotificationPayload, "read">
    >({
      query: (body) => ({
        url: `user/notifications`,
        method: "DELETE",
        body,
      }),
      transformResponse: ({ data }: { data: { success: boolean } }) => data,
      onQueryStarted: async ({ id: ids }, { dispatch, queryFulfilled }) => {
        try {
          const { data: response } = await queryFulfilled
          if (response.success) {
            dispatch(
              dreamsenderApi.util.updateQueryData(
                "getNotifications",
                { start: undefined },
                (draft) => {
                  let resultTotal = draft.total - (ids?.length ?? 0)
                  if (resultTotal) {
                    resultTotal = 0
                  }
                  Object.assign(draft, {
                    items: draft.items.filter(
                      (notification) =>
                        !Boolean(
                          ids?.find(
                            (deletedId) => deletedId === notification._id,
                          ),
                        ),
                    ),
                    total: resultTotal,
                  })
                },
              ),
            )
          }
        } catch (e) {
          console.error(e)
        }
      },
    }),
  }),
})

export const {
  useLoginUserMutation,
  useConfirmEmailMutation,
  usePasswordResetMutation,
  useSetupPasswordMutation,
  useGetCurrentUserQuery,
  useUpdateUserMutation,
  useDeleteProfilePictureMutation,
  useChangePasswordMutation,
  useGetNotificationsQuery,
  useUpdateNotificationsMutation,
  useGetTotalUnreadNotificationsQuery,
  useDeleteNotificationsMutation,
} = dreamsenderApi
