import React, { FC } from "react"
import cx from "classnames"

type Props = {
  children: React.ReactNode
  className?: string
  actions?: React.ReactNode
}

const Tabs: FC<Props> = ({ className, children, actions }: Props) => {
  return (
    <div className={cx("tabs grid", className)}>
      {children}
      {actions}
    </div>
  )
}

export default Tabs
