import { FC } from "react"
import cx from "classnames"
import CriteriaSummaryItem from "./CriteriaSummaryItem"
import { useAppSelector } from "@/redux/hooks"
import {
  selectCriteriaDomains,
  selectCriteriaFilters,
  selectCriteriaKeywords,
} from "@/redux/searchCriteriaSetupSlice"
import Chip from "@/components/Chip"
import { countPresentValues } from "./utils"
import { useCriteriaSetup } from "../../hooks/useCriteriaSetup"
import type { FilterType } from "@/types/entities/search-criteria"

type Props = {
  className?: string
  name?: string
  canDelete?: boolean
}

const CriteriaSummaryPane: FC<Props> = ({
  className,
  name = "",
  canDelete = false,
  ...rest
}: Props) => {
  const filters = useAppSelector(selectCriteriaFilters)
  const keywords = useAppSelector(selectCriteriaKeywords)
  const domains = useAppSelector(selectCriteriaDomains)
  const filtersCount = countPresentValues(filters)

  const { onDomainsChange } = useCriteriaSetup()

  return (
    <>
      {filtersCount ? (
        <div
          className={cx(
            "rounded-md bg-white block-shadow p-3 lg:px-6 lg:py-4",
            className,
          )}
          {...rest}
        >
          <h3 className="text-lg font-semibold mb-4">
            Search Criteria
            {name && (
              <span>
                : <span className="underline">{name}</span>
              </span>
            )}
          </h3>
          <div className="flex flex-col gap-4">
            {keywords[0]?.length ? (
              <div className="flex flex-col gap-1.5">
                <h5 className="text-sm leading-6 font-semibold">Keywords:</h5>
                <div className="text-sm break-words whitespace-pre-line">
                  {keywords}
                </div>
              </div>
            ) : null}
            {Object.entries(filters)
              .filter(
                ([, v]) =>
                  v.selections?.INCLUDED?.length > 0 ||
                  v.selections?.EXCLUDED?.length > 0,
              )
              .toSorted(([a], [b]) => a.localeCompare(b))
              .map(([key, v]) => (
                <CriteriaSummaryItem
                  key={key}
                  filterKey={key as FilterType}
                  filterValue={v}
                  canDelete={canDelete}
                />
              ))}
            {domains.length ? (
              <div className="flex flex-col gap-1.5">
                <h5 className="text-sm leading-6 font-semibold">Domains:</h5>
                <div className="flex flex-wrap gap-2">
                  {domains.map((d) => (
                    <Chip
                      dataTagIndex={d}
                      key={d}
                      onDelete={
                        canDelete
                          ? (removedV) =>
                              onDomainsChange(
                                domains.filter((v) => v !== removedV),
                              )
                          : undefined
                      }
                    >
                      {d}
                    </Chip>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default CriteriaSummaryPane
