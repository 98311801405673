import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useForwardInboxMutation } from "@/redux/services/inboxApi"
import { setToast } from "@/redux/toastSlice"

export const useInboxForward = () => {
  const dispatch = useAppDispatch()
  const [forwardInbox, response] = useForwardInboxMutation()

  const handleForwardInbox = async ({
    id,
    emails,
  }: {
    id: string
    emails: string[]
  }) => {
    try {
      const response = await forwardInbox({
        id,
        body: {
          emails,
        },
      }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success forward inbox",
          }),
        )
      }
      return response
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
      throw new Error(error)
    }
  }

  return { forwardInbox: handleForwardInbox, response }
}
