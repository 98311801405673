import { FC, useEffect, useMemo, useState } from "react"
import cx from "classnames"
import { usePageTitle } from "@/hooks/usePageTitle"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { FiArrowLeft as ArrowLeft } from "react-icons/fi"
import {
  useExportMLMutation,
  useGetMasterListQuery,
  useLazyGetMasterListQuery,
} from "@/redux/services/masterListsApi"
import OutlineButton from "@/components/OutlineButton"
import { MdOutlineUploadFile as UploadFileIcon } from "react-icons/md"
import TextField from "@/components/TextField"
import SearchIcon from "@/assets/search.svg"
import Select from "@/components/Select"
import Checkbox from "@/components/Checkbox"
import EmptyList from "@/components/EmptyList"
import AddEditContactModal from "./features/AddEditContactModal"
import ImportContactModal from "./features/ImportContactModal"
import MasterListRecordsTable from "./features/MasterListRecordsTable"
import { Key } from "antd/es/table/interface"
import MasterListRecordStatusSelect from "./features/MasterListRecordStatusSelect"
import DeleteButton from "@/components/Button/DeleteButton"
import { useMasterListRecordActions } from "./hooks/useMasterListRecordActions"
import { camelCaseToWords } from "@/utils/strings"
import { useAppDispatch } from "@/redux/hooks"
import { v4 as createId } from "uuid"
import DownloadIcon from "@/components/icons/DownloadIcon"
import ErrorMessage from "@/components/ErrorMessage"
import DeleteContactModal from "./features/DeleteContactModal"
import Loader from "@/components/Loader"
import { setToast } from "@/redux/toastSlice"
import { useUserAccess } from "@/hooks/useUserAccess"
import { usePagination } from "@/hooks/usePagination"
import { useClientRect } from "@/hooks/useClientRect"
import type { MasterListRecordStatus } from "@/types/entities/master-list"

type RecordStatus = "none" | "used" | "skip"

const MasterListDetailsPage: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { masterListId = "" } = useParams()
  const [rect, ref] = useClientRect(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const statuses: Partial<Record<RecordStatus, boolean>> = Array.from(
    searchParams.entries(),
  )
    .filter(([key]) => key.includes("recordStatus"))
    .map(([, v]) => v)
    .reduce(
      (acc, status) =>
        Object.assign(acc, {
          [status]: true,
        }),
      {},
    )

  const limit = parseInt(searchParams.get("limit") ?? "15")
  const skip = parseInt(searchParams.get("skip") ?? "0")
  const { pageFullAccess } = useUserAccess({
    pageName: "Master Lists Update",
  })
  const { text, searchTerm, handleSearchTermChange, sort, sortOrder } =
    usePagination()

  const recordStatusArray = Object.entries(statuses)
    .filter(([, v]) => v)
    .map(([key]) => key)
  const [exportML] = useExportMLMutation()

  const { masterList, isLoading, isFetching, isError } = useGetMasterListQuery(
    {
      id: masterListId,
      limit,
      skip,
      recordStatus: recordStatusArray,
      text,
      sort,
      sortOrder,
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        masterList: data,
        ...rest,
      }),
      refetchOnMountOrArgChange: true,
    },
  )
  const [getMasterListLazy] = useLazyGetMasterListQuery()
  const { changeStatus, isLoading: changeStatusLoading } =
    useMasterListRecordActions()
  usePageTitle(() => (
    <div className="flex gap-3 items-center">
      <div
        onClick={() => navigate({ pathname: ".." }, { relative: "path" })}
        className="cursor-pointer rounded-full bg-bg-default flex justify-center items-center w-7 h-7"
      >
        <ArrowLeft className="opacity-80 w-[22px] h-[22px]" />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <span className="border-b-[1.5px] border-b-outline">
          {masterList?.name}
        </span>
      )}
    </div>
  ))
  const [openAddContactModal, setOpenAddContactModal] = useState(false)
  const [openImportContactsModal, setOpenImportContactModal] = useState(false)
  const [openDeleteContactsModal, setOpenDeleteContactModal] = useState(false)
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([])

  const handleOpenImportModal = () => setOpenImportContactModal(true)
  const handleStatusChange =
    (value: RecordStatus) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const newStatuses = {
        ...statuses,
        [value]: checked,
      }
      const filterStatuses = Object.entries(newStatuses).filter(
        ([, value]) => value,
      )
      setSearchParams(() => {
        const newParams = new URLSearchParams()
        Array.from(newParams.keys()).forEach((key) => {
          if (key.includes("recordStatus")) {
            newParams.delete(key)
          }
        })
        filterStatuses.forEach(([key], i) => {
          newParams.set(`recordStatus[${i}]`, key)
        })
        return newParams
      })
    }
  const selectedRows = masterList?.records.filter(({ _id }) =>
    selectedRowsKeys.find((key) => key === _id),
  )
  const handleSelectedRecordsStatusChange = async ({
    value: statusValue,
  }: {
    value: MasterListRecordStatus
  }) => {
    const selectedRecords = selectedRowsKeys.map((key) => ({
      _id: key.toString(),
      status: statusValue,
    }))
    await changeStatus(selectedRecords)
  }
  const statusesValue = useMemo(() => {
    if (Object.values(statuses).every((v) => !v)) {
      return "All status"
    }
    return Object.entries(statuses)
      .filter(([, v]) => v)
      .map(([key]) =>
        key === "none"
          ? "Available"
          : key.charAt(0).toUpperCase() + key.slice(1),
      )
      .join(", ")
  }, [statuses])
  const selectedRowsChangeValue = useMemo(() => {
    if (!selectedRowsKeys.length || !selectedRows) {
      return "none"
    }
    const status = selectedRows?.reduce((acc, { status }, i) => {
      if (acc === "" && i !== 0) {
        return acc
      }
      if (i === 0) {
        return status
      }
      return acc === status ? acc : ""
    }, "")
    if (!status) {
      return undefined
    }
    return status
  }, [selectedRows, selectedRowsKeys.length])
  const handleDeleteContacts = async () => {
    setSelectedRowsKeys([])
    try {
      const { records } = await getMasterListLazy({
        id: masterListId,
        recordStatus: recordStatusArray,
        limit,
        skip,
        refetch: createId(),
      }).unwrap()

      if (!records.length && skip !== 0) {
        // go previous page
        let resultSkip = skip - limit
        if (resultSkip < 0) {
          resultSkip = 0
        }
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev)
          newParams.set("skip", resultSkip.toString())
          return newParams
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  const handleExportMLRecords = async () => {
    const body: Record<string, any> = {}
    if (recordStatusArray.length > 0) {
      body.recordStatus = recordStatusArray
    }
    if (text) {
      body.text = text
    }
    const response = await exportML({ id: masterListId, body }).unwrap()
    if ((response as Response).status === 204) {
      dispatch(
        setToast({
          message: "No content available",
          show: true,
          variant: "warning",
        }),
      )
    }
  }
  useEffect(() => {
    setSelectedRowsKeys([])
  }, [skip])
  const importFields = masterList?.records[0]
    ? Object.keys(masterList?.records[0])
        .filter((key) => key !== "_id" && key !== "status")
        .map(camelCaseToWords)
    : []
  if (isLoading) {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <Loader />
      </div>
    )
  }

  if (isError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className="flex flex-col gap-5 h-full">
        <div className="flex justify-between gap-1 flex-wrap">
          <div className="flex gap-3">
            <TextField
              rootClassName="basis-56"
              className="input-sm !h-9"
              startIcon={<SearchIcon />}
              placeholder="Search contact"
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
            <Select
              optionRender={({ data, value, label }, { index }) => (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="flex items-center"
                >
                  <Checkbox
                    id={index + "status"}
                    onChange={handleStatusChange(value as RecordStatus)}
                    checked={!!statuses[value as RecordStatus]}
                    className="checkbox-primary h-[18px] w-[18px]"
                  />
                  <label
                    htmlFor={index + "status"}
                    className="flex-grow pl-2.5 flex gap-2 items-center cursor-pointer"
                  >
                    <div
                      className="rounded-full h-2.5 w-2.5"
                      style={{ backgroundColor: data.color }}
                    ></div>
                    <span className="text-sm leading-6">{label}</span>
                  </label>
                </div>
              )}
              popupMatchSelectWidth={false}
              options={[
                {
                  label: "Available",
                  value: "none",
                  color: "var(--positive)",
                },
                { label: "Used", value: "used", color: "var(--info)" },
                { label: "Skip", value: "skip", color: "var(--error)" },
              ]}
              className="w-32 lg:w-52 h-9"
              value={statusesValue}
            />
            {pageFullAccess && (
              <OutlineButton
                onClick={handleExportMLRecords}
                className="btn-base text-black"
              >
                <DownloadIcon className="opacity-80 w-5 h-5" />
                <span className="hidden sm:inline opacity-80">Export</span>
              </OutlineButton>
            )}
          </div>
          {pageFullAccess && (
            <div className="flex gap-3 items-center">
              {selectedRowsKeys.length > 0 && (
                <>
                  <MasterListRecordStatusSelect
                    placeholder="Change status"
                    className="w-36 h-9"
                    value={selectedRowsChangeValue}
                    onChange={handleSelectedRecordsStatusChange}
                    loading={changeStatusLoading}
                  />
                  <DeleteButton
                    onClick={() => setOpenDeleteContactModal(true)}
                    className="btn-base"
                  />
                </>
              )}
              <OutlineButton
                onClick={handleOpenImportModal}
                className="btn-primary btn-base"
              >
                <UploadFileIcon className="w-6 h-6" />
                <span className="hidden sm:inline">Import</span>
              </OutlineButton>
            </div>
          )}
        </div>
        <div
          className={cx(
            "flex-1 overflow-y-auto flex px-2 py-1 rounded-md block-shadow bg-white min-h-[380px]",
            { "justify-center items-center": !masterList?.records.length },
          )}
        >
          {masterList?.records.length ? (
            <div ref={ref} className="w-full">
              <MasterListRecordsTable
                records={masterList.records}
                total={masterList.total}
                pageSize={limit}
                page={skip}
                selectedRowKeys={selectedRowsKeys}
                onRowSelect={(keys) => setSelectedRowsKeys(keys)}
                onPageChange={(page) => {
                  setSearchParams((prev) => {
                    const newParams = new URLSearchParams(prev)
                    newParams.set("skip", page.toString())
                    return newParams
                  })
                }}
                onPageSizeChange={(pageSize) => {
                  setSearchParams((prev) => {
                    const newParams = new URLSearchParams(prev)
                    newParams.set("limit", pageSize.toString())
                    newParams.set("skip", "0")
                    return newParams
                  })
                }}
                showHeader={masterList?.records.length > 0}
                maxHeight={rect?.height ?? 0}
                loading={isFetching}
              />
            </div>
          ) : (
            <EmptyList message="There are no records. Try changing filters or import them.">
              {pageFullAccess && (
                <OutlineButton
                  onClick={handleOpenImportModal}
                  className="btn-primary btn-base"
                >
                  <UploadFileIcon className="w-6 h-6" />
                  <span className="hidden sm:inline">Import</span>
                </OutlineButton>
              )}
            </EmptyList>
          )}
        </div>
      </div>
      <AddEditContactModal
        open={openAddContactModal}
        onClose={() => setOpenAddContactModal(false)}
      />
      <ImportContactModal
        fields={importFields}
        open={openImportContactsModal}
        onClose={() => setOpenImportContactModal(false)}
      />
      <DeleteContactModal
        open={openDeleteContactsModal}
        data={selectedRowsKeys
          .map(String)
          .map(
            (deletedId) =>
              masterList?.records.find(({ _id }) => _id === deletedId)!,
          )
          .filter(Boolean)}
        onClose={() => setOpenDeleteContactModal(false)}
        onDeleteSuccess={handleDeleteContacts}
      />
    </>
  )
}

export default MasterListDetailsPage
