import React from "react"
import { SnackbarProvider } from "notistack"
import { ConfigProvider as AntConfigProvider } from "antd"
import ToastProvider from "./ToastProvider"
import { theme } from "./AntConfig"

type Props = {
  children: React.ReactNode
}

const AppProvider = ({ children }: Props) => {
  return (
    <SnackbarProvider
      autoHideDuration={2500}
      disableWindowBlurListener
      className="rounded-md px-5 !py-4 text-base font-medium [&_#notistack-snackbar]:py-0 [&.notistack-MuiContent-success]:bg-positive [&.notistack-MuiContent-error]:!bg-error"
    >
      <ToastProvider>
        <AntConfigProvider theme={theme}>{children}</AntConfigProvider>
      </ToastProvider>
    </SnackbarProvider>
  )
}

export default AppProvider
