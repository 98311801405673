import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteAdminOrganizationMutation } from "@/redux/services/adminOrganizationsApi"
import { setToast } from "@/redux/toastSlice"

export const useAdminDeleteOrganization = () => {
  const dispatch = useAppDispatch()
  const [deleteOrganization, response] = useDeleteAdminOrganizationMutation()

  const handleDeleteOrganization = async (id: string) => {
    try {
      await deleteOrganization({ id }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success delete organization",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteOrganization: handleDeleteOrganization, response }
}
