import { FC } from "react"
import { useFormik } from "formik"
import Modal from "@/components/Modal"
import TextField from "@/components/TextField"
import Button from "@/components/Button"
import OutlineButton from "@/components/OutlineButton"
import { useAddOrganization } from "@/pages/Organizations/hooks/useAddOrganization"
import validationSchema from "../../utils/validationSchema"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"

type Props = {
  open: boolean
  onClose: () => void
}

const AddOrganizationModal: FC<Props> = ({ open, onClose }: Props) => {
  const { addOrganization, response } = useAddOrganization()
  const {
    handleSubmit,
    getFieldProps,
    errors: { name: nameError, url: urlError, catchAll: catchAllError },
    touched: { name: nameTouched, url: urlTouched, catchAll: catchAllTouched },
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      url: "",
      catchAll: "",
    },
    validationSchema,
    onSubmit: async ({ name, url, catchAll }) => {
      try {
        await addOrganization({ name, url, catchAll })
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  return (
    <Modal
      open={open}
      title="Add Organization"
      onClose={handleClose}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={handleClose}
            className="text-black btn-sm !w-[86px]"
            type="button"
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={response.isLoading}
            className="btn-sm !w-[86px]"
            type="submit"
          >
            Add
          </Button>
        </div>
      }
      onSubmit={handleSubmit}
      contentClassName="space-y-3"
    >
      <TextField
        type="text"
        topLeftLabel="Name"
        placeholder="Enter organization name"
        rootClassName="[&_label.label]:pt-0"
        className="input-sm"
        autoComplete="off"
        bottomLeftLabel={
          <FormFieldErrorMessage error={nameError} touched={nameTouched} />
        }
        {...getFieldProps("name")}
      />
      <TextField
        type="url"
        topLeftLabel="Website"
        placeholder="Enter organization URL"
        className="input-sm"
        bottomLeftLabel={
          <FormFieldErrorMessage error={urlError} touched={urlTouched} />
        }
        {...getFieldProps("url")}
      />
      <TextField
        type="email"
        autoComplete="new-password"
        topLeftLabel="Catch-All Email"
        placeholder="Enter catch-all email"
        className="input-sm"
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={catchAllError}
            touched={catchAllTouched}
          />
        }
        {...getFieldProps("catchAll")}
      />
    </Modal>
  )
}

export default AddOrganizationModal
