import { FC, useCallback, useMemo } from "react"
import { BiPlus as AddIcon } from "react-icons/bi"
import Modal from "@/components/Modal"
import TextField from "@/components/TextField"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import CancelIcon from "@/assets/cancel.svg"
import Select from "@/components/Select"
import { useSenderForm } from "./hooks/useSenderForm"
import { useGetCountriesQuery } from "@/redux/services/generalApi"
import { useAddSender } from "../../hooks/useAddSender"
import { useEditSender } from "../../hooks/useEditSender"
import { SenderModalContext } from "./context"
import { senderCountriesMap } from "./constants"
import { getBodyByCountryCode } from "../../utils"
import type { Sender, SenderAPIResponse } from "@/types/entities/sender"

type Props = {
  open: boolean
  onClose: () => void
  data?: SenderAPIResponse
}

const AddEditSenderModal: FC<Props> = ({ data, open, onClose }: Props) => {
  const isEdit = !!data
  const { data: countries = [], isLoading: countriesLoading } =
    useGetCountriesQuery()
  const { addSender, response: addResponse } = useAddSender()
  const { editSender, response: editResponse } = useEditSender()
  const {
    getFieldProps,
    values,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty,
    errors: {
      firstName: firstNameError,
      lastName: lastNameError,
      titles: titlesError,
      companies: companiesError,
      websites: websitesError,
      email: emailError,
      phoneNumber: phoneNumberError,
      address: addressError,
    },
    touched: {
      firstName: firstNameTouched,
      lastName: lastNameTouched,
      titles: titlesTouched,
      companies: companiesTouched,
      websites: websitesTouched,
      email: emailTouched,
      phoneNumber: phoneNumberTouched,
      address: addressTouched,
    },
    resetForm,
  } = useSenderForm({
    data,
    onSubmit: async (values) => {
      try {
        const body = getBodyByCountryCode(values) as Sender
        isEdit
          ? await editSender({ body, id: data._id })
          : await addSender({ ...body })
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  const resetAddressFields = useCallback(() => {
    setFieldValue("address.address1", "")
    setFieldValue("address.city", "")
    setFieldValue("address.zipCode", "")
    setFieldValue("address.postalCode", "")
    setFieldValue("address.state", "")
    setFieldValue("address.province", "")
    setFieldValue("address.completeAddress", "")
  }, [setFieldValue])
  const handleClose = () => {
    onClose()
    resetForm()
  }
  const countryView = useMemo(
    () => senderCountriesMap[values.address.countryCode],
    [values.address.countryCode],
  )
  const title = isEdit ? "Edit Sender" : "Add Sender"
  const submitDisabled = !(dirty && isValid)
  return (
    <SenderModalContext.Provider
      value={{
        getFieldProps,
        setFieldValue,
        errors: {
          address: addressError,
        },
        touched: {
          address: addressTouched,
        },
        values,
      }}
    >
      <Modal
        title={title}
        open={open}
        onSubmit={handleSubmit}
        onClose={handleClose}
        className="z-[1000]"
        actions={
          <div className="flex gap-4">
            <div className="flex gap-4">
              <OutlineButton
                disabled={editResponse.isLoading || addResponse.isLoading}
                onClick={handleClose}
                className="text-black btn-sm !w-[86px]"
                type="button"
              >
                Cancel
              </OutlineButton>
              <Button
                disabled={
                  editResponse.isLoading ||
                  addResponse.isLoading ||
                  submitDisabled
                }
                className="btn-sm !w-[86px]"
                type="submit"
              >
                {isEdit ? "Save" : "Add"}
              </Button>
            </div>
          </div>
        }
      >
        <div className="flex flex-col gap-5">
          <div className="flex flex-col sm:flex-row gap-4 items-start">
            <TextField
              type="text"
              placeholder="Sender’s first name"
              topLeftLabel="First Name"
              className="input-sm"
              bottomLeftLabel={
                <FormFieldErrorMessage
                  error={firstNameError}
                  touched={firstNameTouched}
                />
              }
              {...getFieldProps("firstName")}
            />
            <TextField
              type="text"
              placeholder="Sender’s last name"
              topLeftLabel="Last Name"
              className="input-sm"
              bottomLeftLabel={
                <FormFieldErrorMessage
                  error={lastNameError}
                  touched={lastNameTouched}
                />
              }
              {...getFieldProps("lastName")}
            />
          </div>
          <div className="flex flex-col gap-3">
            <h4 className="label-text text-black font-medium">Title</h4>
            {values.titles.map((_, i) => (
              <div key={i} className="relative">
                {values.titles.length > 1 ? (
                  <CancelIcon
                    onClick={() =>
                      setFieldValue(
                        `titles`,
                        values.titles.filter((_, removeI) => i !== removeI),
                      )
                    }
                    className="cursor-pointer absolute hover:fill-error -top-1.5 right-0 z-10"
                  />
                ) : null}
                <TextField
                  type="text"
                  placeholder="Enter sender’s title"
                  className="input-sm"
                  bottomLeftLabel={
                    <FormFieldErrorMessage
                      error={titlesError && titlesError[i]}
                      touched={titlesTouched}
                    />
                  }
                  {...getFieldProps(`titles[${i}]`)}
                />
              </div>
            ))}
            <OutlineButton
              onClick={() => setFieldValue("titles", values.titles.concat(""))}
              className="btn-sm flex text-black w-36"
              type="button"
            >
              <AddIcon className="w-6 h-6 text-black" />
              <span>Add Another</span>
            </OutlineButton>
          </div>
          <div className="flex flex-col gap-3">
            <h4 className="label-text text-black font-medium">Company Name</h4>
            {values.companies.map((_, i) => (
              <div key={i} className="relative">
                {values.companies.length > 1 ? (
                  <CancelIcon
                    onClick={() =>
                      setFieldValue(
                        `companies`,
                        values.companies.filter((_, removeI) => i !== removeI),
                      )
                    }
                    className="cursor-pointer absolute hover:fill-error -top-1.5 right-0 z-10"
                  />
                ) : null}
                <TextField
                  type="text"
                  placeholder="Enter company name"
                  className="input-sm"
                  bottomLeftLabel={
                    <FormFieldErrorMessage
                      error={companiesError && companiesError[i]}
                      touched={companiesTouched}
                    />
                  }
                  {...getFieldProps(`companies[${i}]`)}
                />
              </div>
            ))}
            <OutlineButton
              onClick={() =>
                setFieldValue("companies", values.companies.concat(""))
              }
              className="btn-sm flex text-black w-36"
              type="button"
            >
              <AddIcon className="w-6 h-6 text-black" />
              <span>Add Another</span>
            </OutlineButton>
          </div>
          <div className="flex flex-col gap-3">
            <h4 className="label-text text-black font-medium">Website</h4>
            {values.websites.map((_, i) => (
              <div key={i} className="relative">
                {values.websites.length > 1 ? (
                  <CancelIcon
                    onClick={() =>
                      setFieldValue(
                        `websites`,
                        values.websites.filter((_, removeI) => i !== removeI),
                      )
                    }
                    className="cursor-pointer absolute hover:fill-error -top-1.5 right-0 z-10"
                  />
                ) : null}
                <TextField
                  type="text"
                  placeholder="company-website.com"
                  bottomLeftLabel={
                    <FormFieldErrorMessage
                      error={websitesError && websitesError[i]}
                      touched={websitesTouched}
                    />
                  }
                  className="input-sm"
                  {...getFieldProps(`websites[${i}]`)}
                />
              </div>
            ))}
            <OutlineButton
              onClick={() =>
                setFieldValue("websites", values.websites.concat(""))
              }
              className="btn-sm flex text-black w-36"
              type="button"
            >
              <AddIcon className="w-6 h-6 text-black" />
              <span>Add Another</span>
            </OutlineButton>
          </div>
          <TextField
            type="email"
            placeholder="sender@mail.com"
            topLeftLabel="Email Address"
            className="input-sm"
            bottomLeftLabel={
              <FormFieldErrorMessage
                error={emailError}
                touched={emailTouched}
              />
            }
            {...getFieldProps("email")}
          />
          <TextField
            type="tel"
            placeholder="123 456 7890"
            topLeftLabel="Phone Number"
            className="input-sm"
            bottomLeftLabel={
              <FormFieldErrorMessage
                error={phoneNumberError}
                touched={phoneNumberTouched}
              />
            }
            {...getFieldProps("phoneNumber")}
          />
          <Select
            options={countries.map(({ code, name }) => ({
              label: name,
              value: code,
            }))}
            getPopupContainer={(node) => node.parentNode}
            loading={countriesLoading}
            label="Country"
            bordered={false}
            value={values.address.countryCode || null}
            onChange={(v) => {
              setFieldValue("address.countryCode", v)
              resetAddressFields()
            }}
            className="h-10 bg-input rounded-md [&_.ant-select-selection-placeholder]:text-black [&_.ant-select-selection-placeholder]:opacity-60"
            placeholder="Select country"
            bottomLabel={
              <FormFieldErrorMessage
                error={addressError?.countryCode}
                touched={addressTouched?.countryCode}
              />
            }
          />
          {countryView}
        </div>
      </Modal>
    </SenderModalContext.Provider>
  )
}

export default AddEditSenderModal
