import { useParams } from "react-router-dom"
import { useAddSearchCriteriaMutation } from "@/redux/services/searchCriteriaApi"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { getErrorMessage } from "@/core/api/helpers"
import {
  selectCriteriaDescription,
  selectCriteriaDomains,
  selectCriteriaFilledFilters,
  selectCriteriaKeywords,
  selectCriteriaMasterListId,
  selectCriteriaName,
  selectCriteriaStatus,
  selectCriteriaTarget,
  selectCriteriaType,
} from "@/redux/searchCriteriaSetupSlice"
import { removeUndefinedProperties } from "@/utils/objects"
import { AddSearchCriteriaPayload } from "@/types/entities/search-criteria"

export const useAddCriteria = () => {
  const dispatch = useAppDispatch()
  const [addCriteria, response] = useAddSearchCriteriaMutation()
  const { id: orgId = "" } = useParams()
  const name = useAppSelector(selectCriteriaName)
  const description = useAppSelector(selectCriteriaDescription)
  const masterList = useAppSelector(selectCriteriaMasterListId)
  const status = useAppSelector(selectCriteriaStatus)
  const type = useAppSelector(selectCriteriaType)
  const target = useAppSelector(selectCriteriaTarget)
  const keywords = useAppSelector(selectCriteriaKeywords)
  const filters = useAppSelector(selectCriteriaFilledFilters)
  const domains = useAppSelector(selectCriteriaDomains)

  const handleAddCriteria = async () => {
    try {
      const body = removeUndefinedProperties({
        orgId,
        name,
        description,
        masterList,
        status,
        type,
        target: type === "manual" ? target : undefined,
        keywords: keywords.join(" OR "),
        filters,
        domains: domains.map((d) => d.replace("\r", "")),
      }) as AddSearchCriteriaPayload

      const newCriteria = await addCriteria(body).unwrap()
      if (newCriteria) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success add search criteria",
          }),
        )
      }
      return newCriteria
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { addCriteria: handleAddCriteria, response }
}
