import { enableMapSet } from "immer"
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import authReducer from "@/redux/authSlice"
import toastReducer from "@/redux/toastSlice"
import organizationReducer from "@/redux/organizationSlice"
import appReducer from "@/redux/appSlice"
import campaignSetupReducer from "@/redux/campaignSetupSlice"
import domainSetupReducer from "@/redux/domainSetupSlice"
import searchCriteriaSetupSlice from "@/redux/searchCriteriaSetupSlice"
import { dreamsenderApi } from "@/core/api/services"
import { organizationApi } from "./services/organizationApi"
import { sendersApi } from "./services/sendersApi"
import { generalApi } from "./services/generalApi"
import { sequencesApi } from "./services/sequencesApi"
import { masterListsApi } from "./services/masterListsApi"
import { campaignsApi } from "./services/campaignsApi"
import { domainsApi } from "./services/domainsApi"
import { prospectsApi } from "./services/prospectsApi"
import { gsuiteAccountsApi } from "./services/gsuitesApi"
import { adminUsersApi } from "./services/adminUsersApi"
import { adminSpamAccountsApi } from "./services/adminSpamAccountsApi"
import { adminDncApi } from "./services/adminDncApi"
import { userDncApi } from "./services/userDncApi"
import { aiPromptsApi } from "./services/aiPromptsApi"
import { adminOrganizationApi } from "./services/adminOrganizationsApi"
import { searchCriteriaApi } from "./services/searchCriteriaApi"
import { adminCookiesApi } from "./services/adminCookiesApi"
import { adminProxyApi } from "./services/adminProxyApi"
import { inboxApi } from "./services/inboxApi"

enableMapSet()

export const store = configureStore({
  reducer: {
    [organizationApi.reducerPath]: organizationApi.reducer,
    [dreamsenderApi.reducerPath]: dreamsenderApi.reducer,
    [sendersApi.reducerPath]: sendersApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer,
    [sequencesApi.reducerPath]: sequencesApi.reducer,
    [masterListsApi.reducerPath]: masterListsApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [domainsApi.reducerPath]: domainsApi.reducer,
    [prospectsApi.reducerPath]: prospectsApi.reducer,
    [searchCriteriaApi.reducerPath]: searchCriteriaApi.reducer,
    [gsuiteAccountsApi.reducerPath]: gsuiteAccountsApi.reducer,
    [adminUsersApi.reducerPath]: adminUsersApi.reducer,
    [adminSpamAccountsApi.reducerPath]: adminSpamAccountsApi.reducer,
    [adminDncApi.reducerPath]: adminDncApi.reducer,
    [userDncApi.reducerPath]: userDncApi.reducer,
    [aiPromptsApi.reducerPath]: aiPromptsApi.reducer,
    [inboxApi.reducerPath]: inboxApi.reducer,
    [adminOrganizationApi.reducerPath]: adminOrganizationApi.reducer,
    [adminCookiesApi.reducerPath]: adminCookiesApi.reducer,
    [adminProxyApi.reducerPath]: adminProxyApi.reducer,
    app: appReducer,
    organization: organizationReducer,
    campaignSetup: campaignSetupReducer,
    domainSetup: domainSetupReducer,
    searchCriteriaSetup: searchCriteriaSetupSlice,
    auth: authReducer,
    toast: toastReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      dreamsenderApi.middleware,
      organizationApi.middleware,
      sendersApi.middleware,
      generalApi.middleware,
      sequencesApi.middleware,
      masterListsApi.middleware,
      campaignsApi.middleware,
      domainsApi.middleware,
      prospectsApi.middleware,
      gsuiteAccountsApi.middleware,
      adminUsersApi.middleware,
      adminSpamAccountsApi.middleware,
      adminDncApi.middleware,
      userDncApi.middleware,
      aiPromptsApi.middleware,
      inboxApi.middleware,
      adminOrganizationApi.middleware,
      searchCriteriaApi.middleware,
      adminCookiesApi.middleware,
      adminProxyApi.middleware,
    ]),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
