import { FC } from "react"
import cx from "classnames"
import Modal from "@/components/Modal"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import OutlineButton from "@/components/OutlineButton"
import MicrosoftOAuthLogin from "@/components/MicrosoftOAuthLogin"
import EmailConnectionStatus from "@/components/EmailConnectionStatus"
import { useFormik } from "formik"
import { useDisconnectSpamAccount } from "@/pages/AdminSpamTesting/hooks/useDisconnectSpamAccount"
import { useSpamAccountLogin } from "../hooks/useSpamAccountLogin"
import { useAddSpamAccount } from "@/pages/AdminSpamTesting/hooks/useAddSpamAccount"
import { useEditSpamAccount } from "@/pages/AdminSpamTesting/hooks/useEditSpamAccount"
import type { ModalProps } from "@/components/Modal"
import type { AdminSpamAccount } from "@/types/entities/spam-account"

type Props = {
  className?: string
  data?: AdminSpamAccount
} & ModalProps

const AddEditMicrosoftSpamAccountModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const isEdit = Boolean(data)
  const {
    addSpamAccount,
    response: { isLoading: addLoading },
  } = useAddSpamAccount()
  const {
    editSpamAccount,
    response: { isLoading: editLoading },
  } = useEditSpamAccount()
  const {
    disconnectMail,
    response: { isLoading: disconnectLoading },
  } = useDisconnectSpamAccount()
  const { submitBtnText, title } = useSpamAccountLogin()
  const {
    dirty,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
    values: { code, displayName },
  } = useFormik({
    enableReinitialize: true,
    initialValues: data
      ? {
          displayName: data.displayName,
          code: "-",
        }
      : {
          displayName: "",
          code: "",
        },
    onSubmit: async ({ code, displayName }) => {
      try {
        if (isEdit && data) {
          const body: Record<string, any> = {
            displayName,
          }
          if (code !== "-") {
            body.code = code
          }
          await editSpamAccount({
            id: data._id,
            body,
          })
        } else {
          await addSpamAccount({ provider: "microsoft", code, displayName })
        }
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  const handleDisconnect = async () => {
    if (data) {
      await disconnectMail(data._id)
      setFieldValue("code", "")
    }
  }
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }
  const isLoading = addLoading || editLoading || disconnectLoading
  const submitDisabled = !dirty || isLoading || !displayName || !code
  return (
    <Modal
      title={title}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            type="button"
            className="btn-sm text-black min-w-[86px]"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            type="submit"
            className="btn-sm min-w-[86px]"
            disabled={submitDisabled}
          >
            {submitBtnText}
          </Button>
        </div>
      }
      {...rest}
    >
      <div className="flex flex-col gap-5">
        <TextField
          className="input-sm"
          placeholder="Enter spam test account name"
          topLeftLabel="Account Name"
          {...getFieldProps("displayName")}
        />
        {data?.email && data.connected ? (
          <EmailConnectionStatus
            connected={data.connected}
            connectedTo={data.email}
            onDisconnect={handleDisconnect}
          />
        ) : code && code !== "-" ? (
          <EmailConnectionStatus connected connectedTo={null} />
        ) : (
          <MicrosoftOAuthLogin
            onSuccess={(response) => {
              const code = response.code
              setFieldValue("code", code)
            }}
            scope="offline_access https://graph.microsoft.com/User.Read https://graph.microsoft.com/Mail.ReadWrite"
          />
        )}
      </div>
    </Modal>
  )
}

export default AddEditMicrosoftSpamAccountModal
