import { FC } from "react"
import { Link } from "react-router-dom"
import { cx } from "@/utils/strings"
import { MdLogout } from "react-icons/md"
import Notifications from "../Notifications"
import Menu from "../Menu"
import Avatar from "../Avatar"
import AccountCircleIcon from "@/assets/account-circle.svg"
import { Routes } from "@/core/routing/routes"
import { useLogout } from "@/hooks/useLogout"
import { useProfilePicture } from "@/hooks/useProfilePicture"
import { useGetCurrentUserQuery } from "@/core/api/services"

type Props = {
  className?: string
}

const UserInfo: FC<Props> = ({ className }: Props) => {
  const { data: user } = useGetCurrentUserQuery()
  const logout = useLogout()
  const { smallPicture } = useProfilePicture()

  return (
    <>
      {user && (
        <div className={cx("gap-3 flex md:gap-6 items-center", className)}>
          <Notifications />
          <Menu
            className="[&_ul]:right-4 !bg-white z-40"
            items={[
              <Link
                to={Routes.userSettings}
                className="flex items-center gap-1.5"
              >
                <AccountCircleIcon className="w-5 h-5" />
                <span>User Settings</span>
              </Link>,
              <div onClick={logout} className="text-error">
                <MdLogout className="w-5 h-5" />
                <span>Logout</span>
              </div>,
            ]}
          >
            <div className="flex gap-2.5 items-center">
              <Avatar src={smallPicture}>
                <span className="text-xs opacity-60">
                  {user.firstName.slice(0, 1)}
                  {user.lastName.slice(0, 1)}
                </span>
              </Avatar>
              <div className="hidden md:flex flex-col gap-0.5">
                <span className="text-sm font-medium">
                  {user.firstName} {user.lastName}
                </span>
                {user.isAdmin && (
                  <span className="text-xs text-primary">Admin</span>
                )}
              </div>
            </div>
          </Menu>
        </div>
      )}
    </>
  )
}

export default UserInfo
