import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteAdminUserMutation } from "@/redux/services/adminUsersApi"
import { setToast } from "@/redux/toastSlice"

export const useDeleteAdminUser = () => {
  const dispatch = useAppDispatch()
  const [deleteUser, response] = useDeleteAdminUserMutation()

  const handleDeleteUser = async (id: string) => {
    try {
      await deleteUser({ id }).unwrap()
      dispatch(
        setToast({
          show: true,
          variant: "success",
          message: "Success delete user",
        }),
      )
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteUser: handleDeleteUser, response }
}
