import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"

export const useSearchParamValue = (paramKey: string) => {
  const [searchParams] = useSearchParams()
  const paramValue = useMemo(
    () => searchParams.get(paramKey),
    [searchParams, paramKey],
  )
  return paramValue
}
