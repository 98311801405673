import { createApi } from "@reduxjs/toolkit/query/react"
import { buildQueryString } from "@/utils/strings"
import { baseQueryWithReauth } from "@/core/api/baseQuery"
import type { PaginationProps } from "./types"
import type {
  AddGsuiteAccountPayload,
  EditGsuiteAccountPayload,
  GsuiteAccount,
} from "@/types/entities/gsuite-account"

export const gsuiteAccountsApi = createApi({
  reducerPath: "gsuiteAccountsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Gsuite Accounts"],
  endpoints: (builder) => ({
    getGsuiteAccounts: builder.query<
      { items: GsuiteAccount[]; total: number },
      PaginationProps
    >({
      query: ({ ...keys }) => ({
        method: "GET",
        url: `admin/domainAdmin/all?${buildQueryString(keys)}`,
      }),
      transformResponse: ({
        data: { items, total },
      }: {
        data: { items: GsuiteAccount[]; total: number }
      }) => ({
        items,
        total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ _id }) => ({
                type: "Gsuite Accounts" as const,
                _id,
              })),
              { type: "Gsuite Accounts", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Gsuite Accounts", id: "PARTIAL-LIST" }],
    }),
    addGsuiteAccount: builder.mutation<GsuiteAccount, AddGsuiteAccountPayload>({
      query: (body) => ({
        method: "POST",
        url: "admin/domainAdmin",
        body,
      }),
      invalidatesTags: ["Gsuite Accounts"],
      transformResponse: ({ data }: { data: GsuiteAccount }) => data,
    }),
    editGsuiteAccount: builder.mutation<
      GsuiteAccount,
      {
        body: EditGsuiteAccountPayload
        id: string
      }
    >({
      query: ({ body, id }) => ({
        method: "PUT",
        url: `admin/domainAdmin/${id}`,
        body,
      }),
      invalidatesTags: ["Gsuite Accounts"],
      transformResponse: ({ data }: { data: GsuiteAccount }) => data,
    }),
    disconnectGsuiteAccount: builder.mutation<GsuiteAccount, { id: string }>({
      query: ({ id }) => ({
        url: `admin/domainAdmin/${id}/disconnect`,
        method: "PUT",
      }),
      invalidatesTags: ["Gsuite Accounts"],
      transformResponse: ({ data }: { data: GsuiteAccount }) => data,
    }),
    deleteAdminAccount: builder.mutation<{ data: boolean }, { id: string }>({
      query: ({ id }) => ({
        url: `admin/domainAdmin/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Gsuite Accounts"],
    }),
  }),
})

export const {
  useGetGsuiteAccountsQuery,
  useAddGsuiteAccountMutation,
  useEditGsuiteAccountMutation,
  useDisconnectGsuiteAccountMutation,
  useDeleteAdminAccountMutation,
} = gsuiteAccountsApi
