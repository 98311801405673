import { FC, useMemo, useState } from "react"
import cx from "classnames"
import Table from "@/components/Table"
import DeleteDNCModal from "../DeleteDNCModal"
import AddEditDNCModal from "../AddEditDNCModal"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { DATE_FORMAT_OPTIONS } from "@/constants"
import { useUserAccess } from "@/hooks/useUserAccess"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import type { AdminDNC } from "@/types/entities/dnc"
import type { TablePaginationProps } from "@/redux/services/types"
import type { ColumnsType } from "antd/es/table"

type Props = {
  className?: string
  dncs: AdminDNC[]
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  value: string
  reason: string
  createdAt: string
}

const UserDNCTable: FC<Props> = ({
  className,
  dncs,
  onRowSelect,
  ...rest
}: Props) => {
  const { sm } = useBreakpoints()
  const [editedUserId, setEditedUserId] = useState("")
  const [deletedUserId, setDeletedUserId] = useState("")
  const { pageFullAccess } = useUserAccess({ pageName: "DNC List" })
  const dataSource = useMemo<TableDataType[]>(
    () =>
      dncs.map(({ _id, value, reason, createdAt }) => ({
        key: _id,
        value,
        reason,
        createdAt: new Date(createdAt).toLocaleDateString(
          "en-US",
          DATE_FORMAT_OPTIONS,
        ),
      })),
    [dncs],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(() => {
    let resultCols: ColumnsType<TableDataType> = [
      {
        title: "Value",
        key: "value",
        dataIndex: "value",
        sorter: (a, b) => a.value.localeCompare(b.value),
      },
      {
        title: "Reason",
        key: "reason",
        dataIndex: "reason",
        sorter: (a, b) => a.reason.localeCompare(b.reason),
        render: (v: string) => (v.length ? v[0].toUpperCase() + v.slice(1) : v),
      },
      {
        title: "Created At",
        key: "createdAt",
        dataIndex: "createdAt",
        sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      },
    ]
    if (pageFullAccess) {
      resultCols = resultCols.concat({
        title: "",
        dataIndex: "actions",
        key: "actions",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            <EditIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setEditedUserId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
            />
            <BinIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setDeletedUserId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
            />
          </div>
        ),
        width: 96,
      })
    }
    return resultCols
  }, [pageFullAccess, sm])
  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        rootClassName="w-full"
        className={cx("", className)}
        showSizeChanger
        {...rest}
      ></Table>
      <AddEditDNCModal
        data={dncs.find(({ _id }) => _id === editedUserId)}
        open={!!editedUserId}
        onClose={() => setEditedUserId("")}
      />
      <DeleteDNCModal
        open={!!deletedUserId}
        data={dncs.find(({ _id }) => _id === deletedUserId)}
        onClose={() => setDeletedUserId("")}
      />
    </>
  )
}

export default UserDNCTable
