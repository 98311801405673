import { FC } from "react"
import Modal, { ModalProps } from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import { useDeleteMasterList } from "../../hooks/useDeleteMasterList"
import type { MasterList } from "@/types/entities/master-list"

type Props = ModalProps & {
  data?: MasterList | null
}

const DeleteMasterListModal: FC<Props> = ({ data, open, onClose }: Props) => {
  const { deleteMasterList, response } = useDeleteMasterList()
  if (!data) {
    return null
  }
  const handleDelete = async () => {
    await deleteMasterList({ id: data._id })
    onClose && onClose()
  }
  return (
    <Modal
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={response.isLoading} onClick={onClose} />
          <DeleteButton tabIndex={0} disabled={response.isLoading} />
        </div>
      }
      title="Delete Master Lists"
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
    >
      <span className="text-base leading-6">
        Are you sure you want to delete{" "}
        <span className="font-semibold">“{data.name}”</span>?
      </span>
    </Modal>
  )
}

export default DeleteMasterListModal
