import { createContext } from "react"
import type {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from "formik"
import type { Sender, SenderForm } from "@/types/entities/sender"

type SenderModalCtx = {
  getFieldProps: (
    nameOrOptions: string | FieldConfig<any>,
  ) => FieldInputProps<any>
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<Omit<Sender, "orgId">>>
  errors: FormikErrors<Pick<Sender, "address">>
  touched: FormikTouched<Pick<Sender, "address">>
  values: SenderForm
}

export const SenderModalContext = createContext<SenderModalCtx>({
  getFieldProps: () => {
    return {} as FieldInputProps<any>
  },
  setFieldValue: async (field, value) => {
    return {}
  },
  errors: {},
  touched: {},
  values: {} as SenderForm,
})
