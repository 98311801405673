import { FC } from "react"
import Modal from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import type { ModalProps } from "@/components/Modal"
import type { SenderAPIResponse } from "@/types/entities/sender"
import { useDeleteSender } from "../../hooks/useDeleteSender"

type Props = ModalProps & {
  data?: SenderAPIResponse
}

const DeleteSenderModal: FC<Props> = ({ open, onClose, data }: Props) => {
  const { deleteSender, response } = useDeleteSender()
  if (!data) {
    return null
  }
  const handleDelete = async () => {
    await deleteSender({ id: data._id })
    onClose && onClose()
  }
  return (
    <Modal
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={response.isLoading} onClick={onClose} />
          <DeleteButton tabIndex={0} disabled={response.isLoading} />
        </div>
      }
      title="Delete Sender"
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
    >
      <span className="text-base">
        Are you sure you want to delete&nbsp;
        <span className="font-semibold">
          “{data.firstName} {data.lastName}”
        </span>
        ?
      </span>
    </Modal>
  )
}

export default DeleteSenderModal
