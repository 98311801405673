import { FC } from "react"
import cx from "classnames"
import { CgAttachment } from "react-icons/cg"

type Props = {
  className?: string
} & React.HTMLAttributes<HTMLOrSVGElement>

const AttachmentsIcon: FC<Props> = ({ className, ...rest }: Props) => {
  return (
    <CgAttachment
      className={cx("w-5 h-5 active:scale-90", className)}
      {...rest}
    />
  )
}

export default AttachmentsIcon
