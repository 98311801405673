export const DEFAULT_SENDERS_CLASSIFICATIONS = [
  "Expresses Interest, Asks Polite Question or Books Meeting",
  "Immediate Interest In Meeting or Connecting",
  "Immediate Interest, Seeks More Info",
  "Future Interest",
  "Expresses Disinterest",
  "Polite/Neutral Disinterest",
  "Incorrect Contact",
  "Wrong Recipient, Provides Correct Contact",
  "Wrong Recipient, Forwards to Correct Contact",
]
