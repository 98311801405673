import { FC, useMemo, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import Checkbox from "@/components/Checkbox"
import SearchSelect from "@/components/SearchSelect"
import { useGetCampaignsQuery } from "@/redux/services/campaignsApi"
import type { DefaultOptionType } from "antd/es/select"

type Props = {
  className?: string
}

const ProspectCampaignsDropdown: FC<Props> = ({
  className,
  ...rest
}: Props) => {
  const { id: orgId = "" } = useParams()
  const [searchTerm, setSearchTerm] = useState("")
  const { data: { items: campaigns } = { items: [] }, isLoading } =
    useGetCampaignsQuery({ orgId }, { skip: !orgId })
  const [searchParams, setSearchParams] = useSearchParams()
  const filterOption = (input: string, option: DefaultOptionType | undefined) =>
    ((option?.label ?? "") as string)
      .toLowerCase()
      .includes(input.toLowerCase())
  const handleStatusChange =
    (value: string) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const newStatuses = {
        ...statuses,
        [value]: checked,
      }
      const filterStatuses = Object.entries(newStatuses).filter(
        ([, value]) => value,
      )
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        Array.from(newParams.keys()).forEach((key) => {
          if (key.includes("campaign")) {
            newParams.delete(key)
          }
        })
        filterStatuses.forEach(([key], i) => {
          newParams.set(`campaign[${i}]`, key)
        })
        return newParams
      })
    }
  const statuses: Partial<Record<string, boolean>> = Array.from(
    searchParams.entries(),
  )
    .filter(([key]) => key.includes("campaign"))
    .map(([, v]) => v)
    .reduce(
      (acc, status) =>
        Object.assign(acc, {
          [status]: true,
        }),
      {},
    )
  const value = useMemo(() => {
    if (Object.values(statuses).every((v) => !v)) {
      return "All campaigns"
    }
    return campaigns.length
      ? Object.entries(statuses)
          .filter(([, v]) => v)
          .map(([key]) => campaigns.find(({ _id }) => _id === key)?.name)
          .join(", ")
      : ""
  }, [campaigns, statuses])
  return (
    <SearchSelect
      optionRender={({ value, label }) => (
        <div onClick={(e) => e.stopPropagation()} className="flex items-center">
          <Checkbox
            id={value as string}
            onChange={handleStatusChange(value as string)}
            checked={!!statuses[value as string]}
            className="checkbox-primary h-[18px] w-[18px]"
          />
          <label
            htmlFor={value as string}
            className="flex-grow pl-2.5 flex gap-2 items-center cursor-pointer"
          >
            <span className="text-sm leading-6">{label}</span>
          </label>
        </div>
      )}
      options={campaigns.map(({ _id, name }) => ({
        label: name,
        value: _id,
      }))}
      loading={isLoading}
      className="w-52 h-9"
      value={value}
      popupMatchSelectWidth={false}
      filterOption={filterOption}
      searchTerm={searchTerm}
      onSearch={setSearchTerm}
      placeholder="Search by name"
      {...rest}
    />
  )
}

export default ProspectCampaignsDropdown
