import { FC } from "react"
import { Spin } from "antd"
import cx from "classnames"

type Props = {
  className?: string
}

const Loader: FC<Props> = ({ className, ...rest }: Props) => {
  return <Spin className={cx("w-12", className)} {...rest}></Spin>
}

export default Loader
