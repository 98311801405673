import { FC } from "react"
import cx from "classnames"
import { useFormik } from "formik"
import Modal, { ModalProps } from "@/components/Modal"
import OutlineButton from "@/components/OutlineButton"
import Button from "@/components/Button"
import TextField from "@/components/TextField"
import RadioGroup from "@/components/RadioGroup"
import Radio from "@/components/Radio"
import validationSchema from "./validation-schema"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import AlertMessage from "@/components/AlertMessage"
import WarningIcon from "@/components/icons/WarningIcon"
import Tooltip from "@/components/Tooltip"
import { usePasswordResetMutation } from "@/core/api/services"
import { useAppDispatch } from "@/redux/hooks"
import { setToast } from "@/redux/toastSlice"
import { useInviteAdminUser } from "../../hooks/useInviteAdminUser"
import { useEditAdminUser } from "../../hooks/useEditAdminUser"
import type { AdminUser } from "@/types/entities/admin-user"

type Props = {
  className?: string
  data?: AdminUser
} & ModalProps

const InviteEditUserModal: FC<Props> = ({
  className,
  open,
  onClose,
  data,
  ...rest
}: Props) => {
  const isEdit = !!data
  const dispatch = useAppDispatch()
  const { inviteUser, response: inviteResponse } = useInviteAdminUser()
  const { editUser, response: editResponse } = useEditAdminUser()
  const [passwordReset] = usePasswordResetMutation()
  const {
    errors: { email: emailError },
    touched: { email: emailTouched },
    getFieldProps,
    handleSubmit,
    resetForm,
    isValid,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: data?.firstName ?? "",
      lastName: data?.lastName ?? "",
      email: data?.email ?? "",
      admin: data?.admin ?? false,
    },
    validationSchema,
    onSubmit: async (body) => {
      try {
        isEdit
          ? await editUser({
              id: data._id,
              body,
            })
          : await inviteUser(body)
        handleClose()
      } catch (e) {
        console.error(e)
      }
    },
  })
  const sendResetPasswordMail = async () => {
    if (data?.email) {
      const passwordResetResponse = await passwordReset({
        email: data.email,
      }).unwrap()
      if (passwordResetResponse.success) {
        dispatch(
          setToast({
            show: true,
            message: "Success sent reset password mail",
            variant: "success",
          }),
        )
      }
    }
  }
  const cancelPendingEmail = async () => {
    if (data) {
      await editUser({ id: data._id, body: { removePendingEmail: true } })
    }
  }
  const handleClose = () => {
    resetForm()
    onClose && onClose()
  }

  const submitBtnText = isEdit ? "Save" : "Invite"
  const title = isEdit ? "Edit User" : "Invite User"
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      className={cx("", className)}
      actions={
        <div className="flex gap-4">
          <OutlineButton
            onClick={onClose}
            className="text-black btn-sm min-w-[86px]"
            type="button"
            disabled={inviteResponse.isLoading || editResponse.isLoading}
          >
            Cancel
          </OutlineButton>
          <Button
            disabled={
              !(isValid && dirty) ||
              inviteResponse.isLoading ||
              editResponse.isLoading
            }
            className="btn-sm min-w-[86px]"
            type="submit"
          >
            {submitBtnText}
          </Button>
        </div>
      }
      {...rest}
    >
      <div className="flex flex-col gap-5 [&_label]:pl-0 [&_label]:pt-0">
        <div className="flex flex-col sm:flex-row gap-4">
          <TextField
            placeholder="Enter First Name"
            className="flex-1 input-sm"
            topLeftLabel="First Name"
            {...getFieldProps("firstName")}
          />
          <TextField
            placeholder="Enter Last Name"
            className="flex-1 input-sm"
            topLeftLabel="Last Name"
            {...getFieldProps("lastName")}
          />
        </div>
        <TextField
          className="input-sm rounded-md border-none"
          placeholder="Enter user email"
          topLeftLabel="Email Address"
          bottomLeftLabel={
            <FormFieldErrorMessage error={emailError} touched={emailTouched} />
          }
          {...getFieldProps("email")}
        />
        {data?.pendingEmail && (
          <AlertMessage className="-mt-2 inline-block w-fit bg-warning">
            <WarningIcon className="w-4 h-4" />
            Pending confirmation:
            <span className="italic">{data.pendingEmail}</span>
            <span>-</span>
            <span className="flex items-center">
              <Tooltip
                getPopupContainer={(node) => node.parentElement as HTMLElement}
                title="Cancel Email Update"
              >
                <span
                  className="hover:underline cursor-pointer font-medium"
                  onClick={cancelPendingEmail}
                >
                  Cancel
                </span>
              </Tooltip>
            </span>
          </AlertMessage>
        )}
        <div className="flex flex-col gap-1.5">
          <span className="text-xs font-medium leading-normal">User Type</span>
          <div className="flex gap-1.5 [&_label]:cursor-pointer">
            <RadioGroup {...getFieldProps("admin")}>
              <Radio value={false}>
                <span className="text-sm leading-6">User</span>
              </Radio>
              <Radio value={true}>
                <span className="text-sm leading-6">Admin</span>
              </Radio>
            </RadioGroup>
          </div>
        </div>
        {isEdit && (
          <div className="space-y-1.5">
            <div className="text-sm font-medium leading-5">Reset Password</div>
            <OutlineButton
              onClick={sendResetPasswordMail}
              className="text-black btn-sm"
            >
              Send Password Reset Email
            </OutlineButton>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default InviteEditUserModal
