import { FC } from "react"
import Checkbox from "@/components/Checkbox"
import type { ReplyClassification } from "@/types/entities/reply-classification"

type Props = {
  className?: string
  data: ReplyClassification
  categories: string[]
  onTopLevelToggle: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubToggle: (
    parent: string,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
}

const ClassificationCheckbox: FC<Props> = ({
  data,
  categories,
  onSubToggle,
  onTopLevelToggle,
  name: checkboxName,
}: Props) => {
  const { sub, name } = data
  const selectedSubcategoriesLength = sub?.length
    ? sub?.filter(({ name: subName }) => categories.find((v) => v === subName))
        .length
    : 0
  return (
    <>
      <span className="flex items-center text-sm leading-6">
        <Checkbox
          className="checkbox-primary w-[18px] h-[18px]"
          id={name}
          name={checkboxName}
          value={name}
          indeterminate={Boolean(
            sub?.length &&
              selectedSubcategoriesLength > 0 &&
              selectedSubcategoriesLength < sub.length,
          )}
          onChange={onTopLevelToggle}
          checked={Boolean(
            selectedSubcategoriesLength === sub?.length ||
              (!selectedSubcategoriesLength &&
                categories.find((v) => v === name)),
          )}
        />
        <label htmlFor={name} className="pl-3 cursor-pointer">
          {name}
        </label>
      </span>
      {sub?.length ? (
        <div className="pl-5 flex flex-col gap-2.5">
          {sub.map(({ name: subName }) => (
            <span key={subName} className="flex items-center text-sm leading-6">
              <Checkbox
                className="checkbox-primary w-[18px] h-[18px]"
                id={subName}
                value={subName}
                name={checkboxName}
                checked={Boolean(categories.find((v) => v === subName))}
                onChange={onSubToggle(name)}
              />
              <label htmlFor={subName} className="pl-3 cursor-pointer">
                {subName}
              </label>
            </span>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default ClassificationCheckbox
