import { FC } from "react"
import cx from "classnames"
import Modal, { ModalProps } from "@/components/Modal"
import CancelButton from "@/components/OutlineButton/CancelButton"
import DeleteButton from "@/components/Button/DeleteButton"
import type { AdminUser } from "@/types/entities/admin-user"
import { useDeleteAdminUser } from "../../hooks/useDeleteAdminUser"

type Props = {
  className?: string
  data: AdminUser[]
  onDelete?: () => void
} & ModalProps

const DeleteAdminUserModal: FC<Props> = ({
  className,
  open,
  data,
  onClose,
  onDelete,
  ...rest
}: Props) => {
  const {
    deleteUser,
    response: { isLoading },
  } = useDeleteAdminUser()
  if (!data.length) {
    return null
  }
  const handleDelete = async () => {
    await Promise.all(data.map(({ _id }) => deleteUser(_id)))
    onClose && onClose()
    onDelete && onDelete()
  }
  const title = data.length > 1 ? `Delete ${data.length} Users` : "Delete User"
  const usersText =
    data.length > 1
      ? `${data.length} users`
      : `“${data[0].firstName} ${data[0].lastName}”`
  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      className={cx("", className)}
      onSubmit={handleDelete}
      actions={
        <div className="flex gap-4">
          <CancelButton disabled={isLoading} onClick={onClose} />
          <DeleteButton disabled={isLoading} />
        </div>
      }
      {...rest}
    >
      <span className="leading-6 text-base">
        Are you sure you want to delete{" "}
        <span className="font-semibold">{usersText}</span>?
      </span>
    </Modal>
  )
}

export default DeleteAdminUserModal
