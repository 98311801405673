import { FC, Fragment } from "react"
import Select from "@/components/Select"
import TextField from "@/components/TextField"
import FormFieldErrorMessage from "@/components/FormFieldErrorMessage"
import { useGetUsStatesQuery } from "@/redux/services/generalApi"
import { useSenderModalContext } from "../hooks/useSenderModalContext"
import type { USSender } from "@/types/entities/sender"
import type { FormikErrors, FormikTouched } from "formik"

const USSenderAddressForm: FC = () => {
  const { data: usStates = [], isLoading: statesLoading } =
    useGetUsStatesQuery()
  const { getFieldProps, setFieldValue, errors, touched, values } =
    useSenderModalContext()
  const usAddress = values as USSender
  const addressError = errors.address as FormikErrors<USSender["address"]>
  const addressTouched = touched.address as FormikTouched<USSender["address"]>

  return (
    <Fragment>
      <TextField
        type="text"
        placeholder="Enter street name"
        topLeftLabel="Street"
        className="input-sm"
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={addressError?.address1}
            touched={addressTouched?.address1}
          />
        }
        {...getFieldProps("address.address1")}
      />
      <Select
        options={usStates.map(({ abbr, name }) => ({
          label: name,
          value: abbr,
        }))}
        getPopupContainer={(node) => node.parentNode}
        loading={statesLoading}
        label="State"
        bordered={false}
        value={usAddress.address.state || null}
        onChange={(v) => setFieldValue("address.state", v)}
        className="h-10 bg-input rounded-md"
        placeholder="Select state"
        bottomLabel={
          <FormFieldErrorMessage
            error={addressError?.state}
            touched={addressTouched?.state}
          />
        }
      />
      <TextField
        type="text"
        placeholder="Enter city"
        topLeftLabel="City"
        className="input-sm"
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={addressError?.city}
            touched={addressTouched?.city}
          />
        }
        {...getFieldProps("address.city")}
      />
      <TextField
        type="number"
        placeholder="Enter ZIP Code"
        topLeftLabel="ZIP Code"
        className="input-sm"
        maxLength={10}
        bottomLeftLabel={
          <FormFieldErrorMessage
            error={addressError?.zipCode}
            touched={addressTouched?.zipCode}
          />
        }
        {...getFieldProps("address.zipCode")}
      />
    </Fragment>
  )
}

export default USSenderAddressForm
