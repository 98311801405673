import { FC, useMemo, useState } from "react"
import cx from "classnames"
import { MdLogin as LoginIcon } from "react-icons/md"
import { RiDeleteBin6Line as BinIcon } from "react-icons/ri"
import { MdOutlineModeEdit as EditIcon } from "react-icons/md"
import Table from "@/components/Table"
import LoginEmailModal from "../LoginEmailModal"
import EditEmailModal from "../EditEmailModal"
import DeleteEmailModal from "../DeleteEmailModal"
import EmailAddressDetailsDrawer from "../EmailAddressDetailsDrawer"
import Chip from "@/components/Chip"
import EmailPlatformColumnValue from "@/components/EmailPlatformColumnValue"
import { toCapitalize } from "@/utils/strings"
import { useBreakpoints } from "@/hooks/useBreakpoints"
import { useUserAccess } from "@/hooks/useUserAccess"
import type { ColumnsType } from "antd/es/table"
import type { TablePaginationProps } from "@/redux/services/types"
import type { Campaign } from "@/types/entities/campaign"
import type { EmailAddress } from "@/types/entities/email-address"

type Props = {
  className?: string
  emails: EmailAddress[]
} & TablePaginationProps<TableDataType>

type TableDataType = {
  key: string
  email: string
  provider: string
  sender: string
  followUpQueue: number
  campaigns: Campaign[]
}

const EmailAddressesTable: FC<Props> = ({
  className,
  emails,
  ...rest
}: Props) => {
  const { sm } = useBreakpoints()
  const [reloginMailId, setReloginMailId] = useState("")
  const [editedMailId, setEdittedMailId] = useState("")
  const [deletedMailId, setDeletedMailId] = useState("")
  const [selectedItemId, setSelectedItemId] = useState("")
  const { pageFullAccess } = useUserAccess({ pageName: "Email Addresses" })
  const dataSource = useMemo<TableDataType[]>(
    () =>
      emails.map(
        ({ _id, sender, email, followUpQueue, campaigns, provider }) => ({
          key: _id,
          provider,
          sender: `${sender.firstName} ${sender.lastName}`,
          followUpQueue,
          email,
          campaigns,
        }),
      ),
    [emails],
  )
  const columns = useMemo<ColumnsType<TableDataType>>(() => {
    let resultCols: ColumnsType<TableDataType> = [
      {
        title: "Email Address",
        key: "email",
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        width: 300,
      },
      {
        title: "Provider",
        key: "provider",
        dataIndex: "provider",
        sorter: (a, b) => a.provider.localeCompare(b.provider),
        render: (value, { provider }) => (
          <EmailPlatformColumnValue
            provider={value}
            value={toCapitalize(provider)}
          />
        ),
        width: 150,
      },
      {
        title: "Sender",
        key: "sender",
        dataIndex: "sender",
        sorter: (a, b) => a.sender.localeCompare(b.sender),
        width: 225,
      },
      {
        title: "Follow Up Queue",
        key: "followUpQueue",
        dataIndex: "followUpQueue",
        sorter: (a, b) => a.followUpQueue - b.followUpQueue,
        width: 175,
      },
      {
        title: "Campaigns",
        key: "campaigns",
        dataIndex: "campaigns",
        sorter: (a, b) => a.campaigns.length - b.campaigns.length,
        render: (_, { campaigns }) =>
          campaigns?.filter(Boolean).length ? (
            <div className="flex gap-1.5">
              {campaigns.slice(0, 1).map((campaign, i) => (
                <Chip className="hover:text-primary whitespace-nowrap" key={i}>
                  {campaign.name ?? JSON.stringify(campaign)}
                </Chip>
              ))}
              {campaigns.length > 1 && (
                <span className="text-sm leading-6 opacity-60">
                  +{campaigns.length - 1}
                </span>
              )}
            </div>
          ) : (
            <span className="text-sm leading-6 opacity-40">None</span>
          ),
        width: 150,
      },
    ]
    if (pageFullAccess) {
      resultCols = resultCols.concat({
        title: "",
        dataIndex: "actions",
        key: "actions",
        fixed: sm ? "right" : false,
        render: (_, { key }) => (
          <div className="actions hidden gap-4">
            <EditIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setEdittedMailId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
            />
            <LoginIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setReloginMailId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
            />
            <BinIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setDeletedMailId(key)
              }}
              className="w-6 h-6 opacity-60 cursor-pointer hover:text-primary transition-colors"
            />
          </div>
        ),
        width: 136,
      })
    }
    return resultCols
  }, [pageFullAccess, sm])
  const reloginEmail = useMemo(() => {
    const mail = emails.find(({ _id }) => _id === reloginMailId)
    if (!mail) {
      return null
    }
    return {
      ...mail,
      sender: mail.sender._id,
    }
  }, [emails, reloginMailId])
  return (
    <>
      <Table
        className={cx(className)}
        dataSource={dataSource}
        columns={columns}
        showSizeChanger
        onRow={({ key }) => ({
          className: "cursor-pointer",
          onClick: () => setSelectedItemId(key),
        })}
        truncate
        {...rest}
      />
      <LoginEmailModal
        data={reloginEmail}
        open={!!reloginMailId}
        onClose={() => setReloginMailId("")}
      />
      <EditEmailModal
        data={emails.find(({ _id }) => _id === editedMailId)}
        open={!!editedMailId}
        onClose={() => setEdittedMailId("")}
      />
      <DeleteEmailModal
        data={emails.find(({ _id }) => _id === deletedMailId)}
        open={!!deletedMailId}
        onClose={() => setDeletedMailId("")}
      />
      <EmailAddressDetailsDrawer
        open={!!selectedItemId}
        onClose={() => setSelectedItemId("")}
        data={emails.find(({ _id }) => _id === selectedItemId)}
        onEdit={(id) => setEdittedMailId(id)}
        showEdit={pageFullAccess}
      />
    </>
  )
}

export default EmailAddressesTable
