import { FC } from "react"
import cx from "classnames"
import { TreeSelect as TreeSelectAnt, TreeSelectProps as AntProps } from "antd"
import CaretDownIcon from "@/assets/caret-down.svg"

export type TreeSelectProps = {
  className?: string
} & AntProps<any>

export type TreeSelectedValue = {
  disabled: boolean
  halfChecked: boolean
  label: string
  value: string
}

const TreeSelect: FC<TreeSelectProps> = ({
  className,
  loading,
  ...rest
}: TreeSelectProps) => {
  return (
    <TreeSelectAnt
      treeDefaultExpandAll
      treeCheckable
      treeCheckStrictly
      tagRender={({ label }) => <span>{label}</span>}
      popupMatchSelectWidth={false}
      className={cx(
        "[&_.ant-select-selector]:pl-3 [&_.ant-select-selection-overflow]:gap-1",
        className,
      )}
      suffixIcon={
        loading ? (
          <span className="loading text-primary loading-spinner w-6 h-6"></span>
        ) : (
          <CaretDownIcon />
        )
      }
      {...rest}
    />
  )
}

export default TreeSelect
