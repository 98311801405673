import { getErrorMessage } from "@/core/api/helpers"
import { useAppDispatch } from "@/redux/hooks"
import { useDeleteCampaignMutation } from "@/redux/services/campaignsApi"
import { setToast } from "@/redux/toastSlice"

export const useDeleteCampaign = () => {
  const dispatch = useAppDispatch()
  const [deleteCampaign, response] = useDeleteCampaignMutation()

  const handleDeleteCampaign = async ({ id }: { id: string }) => {
    try {
      const response = await deleteCampaign({ id }).unwrap()
      if (response) {
        dispatch(
          setToast({
            show: true,
            variant: "success",
            message: "Success delete campaign",
          }),
        )
      }
    } catch (error: any) {
      const message = getErrorMessage(error)
      dispatch(
        setToast({
          show: true,
          variant: "error",
          message,
        }),
      )
    }
  }

  return { deleteCampaign: handleDeleteCampaign, response }
}
