import LockClockIcon from "@/assets/lock-clock.svg"
import { useDelayedNavigate } from "./useDelayedNavigate"
import { Routes } from "@/core/routing/routes"

const ForgotPasswordDone = () => {
  useDelayedNavigate(Routes.login)

  return (
    <div className="flex flex-col items-center gap-5">
      <LockClockIcon className="w-20 h-20" />
      <div className="flex flex-col gap-2.5">
        <h4 className="text-xl sm:text-2xl font-semibold text-center">
          Your Password <br /> Changed Successfully
        </h4>
        <div className="text-sm text-center">
          Please wait a moment, you will be automatically redirected to the
          login page.
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordDone
